/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';

export interface IconProps {
  className?: string;
  color?: string;
  height?: number;
  seasonal?: boolean;
  title?: string;
  width?: number;
}

abstract class Icon extends React.PureComponent<IconProps, {}> {
  static defaultProps: IconProps = {
    title: 'MFB Icon',
    color: '#78a12e',
    seasonal: false,
  };

  get transform() {
    const {height, width} = this.props;
    const icon = this.constructor as typeof Icon;

    const defaultWidth = icon.defaultProps.width ? icon.defaultProps.width : 0;
    const defaultHeight = icon.defaultProps.height
      ? icon.defaultProps.height
      : 0;
    const propWidth = width ? width : 0;
    const propHeight = height ? height : 0;

    const scale = {
      x: propWidth / defaultWidth,
      y: propHeight / defaultHeight,
    };

    if (scale.x !== 1 || scale.y !== 1) {
      return `scale(${scale.x} ${scale.y})`;
    }

    return '';
  }

  get viewBox() {
    const {height, width} = this.props;

    return `0 0 ${width} ${height}`;
  }

  render() {
    const {className, height, width} = this.props;
    const viewBox = this.viewBox;
    const transform = this.transform;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        className={className}
      >
        <g transform={transform}>{this.renderIcon()}</g>
      </svg>
    );
  }

  abstract renderIcon(): JSX.Element;
}

export default Icon;
