import React from 'react';
import {ReactDatePickerCustomHeaderProps} from 'react-datepicker';
import {format} from 'date-fns';
import {SvgArrowLeftIcon, SvgArrowRightIcon} from '@mfb/lego';
export const CustomHeader: React.FC<ReactDatePickerCustomHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
}) => {
  return (
    <>
      <div className="d-flex justify-content-between m-1">
        <h5 className="ml-2">{format(date, 'MMMM yyyy')}</h5>
        <div className="d-flex mr-2">
          <div className="mx-1" onClick={decreaseMonth}>
            <SvgArrowLeftIcon height="1rem" width="1rem" />
          </div>

          <div className="mx-1" onClick={increaseMonth}>
            <SvgArrowRightIcon height="1rem" width="1rem" />
          </div>
        </div>
      </div>
    </>
  );
};
