import React from 'react';
import {ClickableSection, Modal} from '@mfb/lego';
import {DeviceOS, useGetDeviceOS} from '../hooks/useGetDeviceOS';
import styled from 'styled-components';
import {
  setDownloadIOSPopupExpiry,
  shouldShowDownloadIOSPopup,
  IOS_DOWNLOAD_NEVER_SHOW_AGAIN,
} from './shouldShowDownloadIOSPopup';
import {getIsIOSWebview} from '../getIsIOSWebview';

const IMAGE_SRC = `https://mfbstatic-cdn.mfb.nz/static/download-ios-app-pop-up.png`;
const DOWNLOAD_LINK = `https://apps.apple.com/app/apple-store/id1111029382?pt=118149100&ct=web-account-download-prompt&mt=8`;

const ButtonsContainer = styled.div`
  transform: translate(-50%, -50%);
  bottom: 0;
  left: 50%;
  position: absolute;
`;
const DownloadButton = styled.a`
  color: white;
  background-color: #471424;
  border-radius: 100px;
`;

export const DownloadiOsAppModal: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(true);
  const closeModal = () => setIsModalOpen(false);

  const deviceOS = useGetDeviceOS();
  const isNotIOS = deviceOS !== DeviceOS.ios;
  const isIOSWebviewFromApp = getIsIOSWebview();
  const shouldNotShowPopup = !shouldShowDownloadIOSPopup();

  if (isNotIOS || isIOSWebviewFromApp || shouldNotShowPopup) {
    return null;
  }

  const handleDownload = () => {
    closeModal();
    setDownloadIOSPopupExpiry(IOS_DOWNLOAD_NEVER_SHOW_AGAIN);
  };

  const handleMaybeLater = () => {
    closeModal();
    setDownloadIOSPopupExpiry(new Date().toISOString());
  };

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={handleMaybeLater}
      maxWidth="725px"
      bodyClassName="p-0 m-0"
      body={
        <div>
          <img
            className="w-100 h-100 position-relative"
            alt="Download iOS app from App Store"
            src={IMAGE_SRC}
          />
          <ButtonsContainer className="d-flex flex-column justify-content-center">
            <DownloadButton
              className="d-block btn"
              onClick={handleDownload}
              href={DOWNLOAD_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <span className="text-nowrap">Download app</span>
            </DownloadButton>
            <ClickableSection
              className="d-flex justify-content-center mt-2"
              onClick={handleMaybeLater}
            >
              Maybe later
            </ClickableSection>
          </ButtonsContainer>
        </div>
      }
    />
  );
};
