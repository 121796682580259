import {ActionPanelModal, Modal} from '@mfb/lego';
import React from 'react';
import {CampaignParticipationStatus} from '../../shared/CoreClient';
import { CampaignGroupDto, CampaignTheme } from '@mfb/account-api-client';
import {navman} from '../../../navigator';
import CampaignSignUpForm from './CampaignSignUpForm';
import {PerformJoinCampaign} from '../../deliveries/index';
import InvalidSubscription from './InvalidSubscription';
import NoActiveSubscription from './NoActiveSubscription';
import AlreadyInCampaign from './AlreadyInCampaign';
import NotEnrollable from './CampaignNotEnrollable';
import { TickerTapeCampaignSignUpForm } from './TickerTapeCampaignSignUpForm';

interface Props {
  campaignGroupResponse: CampaignGroupDto;
  onSubmitCallback: PerformJoinCampaign;
}

export const CampaignModal: React.FunctionComponent<Props> = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(true);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    navman.yourAccount();
  };

  const {campaignGroupResponse} = props;

  let component;
  switch (campaignGroupResponse.campaignParticipationStatus) {
    case CampaignParticipationStatus.CampaignIsJoinable:
    case CampaignParticipationStatus.CustomerIsInAnotherCampaign:
      if (campaignGroupResponse.theme === CampaignTheme.TickerTape) {
        component = <TickerTapeCampaignSignUpForm {...{...props}}/>
      } else {
        component = <CampaignSignUpForm {...props} />;
      }
      break;
    case CampaignParticipationStatus.ProductOptionIsNotWhitelisted:
      component = <InvalidSubscription {...props} />;
      break;
    case CampaignParticipationStatus.CustomerDoesNotHaveActiveSubscription:
      component = (
        <NoActiveSubscription campaignGroupResponse={campaignGroupResponse} />
      );
      break;
    case CampaignParticipationStatus.CustomerIsInThisCampaign:
    case CampaignParticipationStatus.CustomerIsInSiblingCampaign:
      component = (
        <AlreadyInCampaign
          campaignParticipationStatus={
            campaignGroupResponse.campaignParticipationStatus
          }
        />
      );
      break;
    case CampaignParticipationStatus.CampaignHasNotStarted:
    case CampaignParticipationStatus.CampaignHasEnded:
      component = <NotEnrollable {...props} />;
      break;
    default:
      return null;
  }
  
  if (campaignGroupResponse.theme === CampaignTheme.TickerTape) {
    return (
      <ActionPanelModal
        isPanelOpen={isModalOpen}
        setIsPanelOpen={toggleModal}
        body={component}
      />)
  } else {
    return isModalOpen ? (
      <Modal
        isOpen={true}
        toggle={toggleModal}
        maxWidth="800px"
        body={component}
      />
    ) : null;
  }

};
