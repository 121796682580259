import ordinal from 'ordinal';
import {Day, Week} from '../app/shared/CoreClient';

const radix = 10;

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const format = (dateString: Day | Week | string): string => {
  const value =
    dateString.startsWith('W', 0) || dateString.startsWith('D', 0)
      ? dateString.substring(1)
      : dateString;
  const [year, month, day] = value.split('-').map(n => parseInt(n, radix));
  const date = new Date(year, month - 1, day);

  return `${days[date.getDay()]} ${ordinal(day)} ${months[month - 1]}`;
};

export default format;
