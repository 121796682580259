import React from 'react';

const ManageSubIcon: React.FunctionComponent<
  React.SVGAttributes<SVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 13"
    width="16"
    height="16"
    {...props}
  >
    <path d="M6.3 8.51c1.244 0 2.275-1.005 2.275-2.217 0-1.211-1.031-2.216-2.275-2.216-1.243 0-2.274 1.005-2.274 2.216 0 1.212 1.03 2.216 2.274 2.216zm4.822-1.597l1.364 1.035c.122.089.152.266.061.413l-1.304 2.187c-.091.147-.243.177-.394.118l-1.607-.62c-.334.236-.698.473-1.092.62l-.243 1.654c-.03.148-.151.266-.303.266H4.996c-.151 0-.273-.118-.303-.266l-.243-1.654a4.001 4.001 0 0 1-1.092-.62l-1.607.62c-.151.06-.303.03-.394-.118L.053 8.36c-.091-.147-.06-.325.06-.413l1.365-1.035c-.03-.206-.03-.413-.03-.62 0-.206 0-.413.03-.62L.114 4.639c-.122-.09-.152-.266-.061-.414L1.357 2.04c.091-.148.243-.177.394-.119l1.607.62c.334-.236.698-.472 1.092-.62L4.693.266C4.723.118 4.844 0 4.996 0h2.608c.151 0 .273.118.303.266L8.15 1.92c.394.148.758.355 1.092.62l1.607-.62c.151-.059.303-.03.394.119l1.304 2.186c.091.148.06.326-.06.414l-1.365 1.034c.03.207.03.414.03.62 0 .207 0 .414-.03.62z" />
  </svg>
);

export default ManageSubIcon;
