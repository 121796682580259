import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props {
  location?: string;
}

// See https://reacttraining.com/react-router/web/guides/scroll-restoration for
// a description of why we have this component.
class ScrollToTop extends React.Component<
  React.PropsWithChildren<RouteComponentProps<any> & Props>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {}
> {
  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withRouter(ScrollToTop) as React.ComponentClass<any>;
