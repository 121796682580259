import React from 'react';
import classNames from 'classnames';
import {CSSProperties} from 'react';

export interface Props {
  isValid: boolean;
  placeholder?: string;
  errorMessage: string;
  value?: string;
  name: string;
  type?: 'text' | 'password' | 'tel';
  label: string;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  title?: string;
  onChange(event: any): void;
  onBlur?(event: any): void;
  style?: CSSProperties;
  defaultValue?: string;
  disabled?: boolean;
}

const FormField: React.FunctionComponent<Props> = ({
  isValid,
  name,
  value,
  label,
  type = 'text',
  onChange,
  errorMessage,
  minLength = null,
  onBlur = null,
  maxLength,
  defaultValue,
  pattern,
  title,
  placeholder,
  style,
  disabled,
}: Props) => {
  const classes = classNames('form-group', {'has-danger': !isValid});

  return (
    <div className={classes}>
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        name={name}
        minLength={minLength}
        maxLength={maxLength}
        pattern={pattern}
        defaultValue={defaultValue}
        title={title}
        className="form-control"
        value={value}
        style={style}
        onChange={onChange}
      />
      {!isValid && (
        <span className="d-block mt-2 form-control-feedback text-danger">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default FormField;
