import {Frequency2, ProductSummaryResponse} from './CoreClient';
import createUserManager from './UserManager';

declare let dataLayer: Array<any>;

export enum ReactivateSubscriptionEvent {
  REACTIVATION_SUBSCRIPTION_STEP_1 = 'reactivation_step_1',
  REACTIVATION_SUBSCRIPTION_STEP_2 = 'reactivation_step_2',
  PURCHASE = 'purchase',
}

export enum ReactivateSubscriptionAction {
  REACTIVATE_SUBSCRIPTION_STEP_1 = 'reactivation step 1',
  REACTIVATE_SUBSCRIPTION_STEP_2 = 'reactivation step 2',
  PURCHASE = 'Purchase',
}

export const trackReactivateSubscriptionWithDefaultProduct = async (
  productSummary: ProductSummaryResponse,
  brandName: string,
  rafCode: string,
  promoCode: string,
  totalDiscount: number
) => {
  const userMgr = await createUserManager();
  const customerNumber = await userMgr.getCustomerNumber();
  const couponCode =
    promoCode && promoCode.length > 0 ? `coupon:${promoCode}` : '';
  const referralCode =
    rafCode && rafCode.length > 0 ? `referral:${rafCode}` : '';
  const referralCodeExists = couponCode && referralCode ? '|' : '';
  dataLayer.push({
    event: ReactivateSubscriptionEvent.REACTIVATION_SUBSCRIPTION_STEP_1,
    customerStage: 'Existing',
    eventDetails: {
      category: 'Ecommerce',
      action: ReactivateSubscriptionAction.REACTIVATE_SUBSCRIPTION_STEP_1,
    },
    userId: `${customerNumber}`,
    ecommerce: {
      currencyCode: 'NZD',
      checkout: {
        actionField: {
          step: 1,
          action: 'checkout',
          revenue: `${productSummary.totalPrice}`,
          purchase_type: 'restart_subscription',
          coupon: couponCode + referralCodeExists + referralCode,
        },
        products: [
          {
            name: `${productSummary.name}`,
            id: `${productSummary.sku}`,
            price: `${productSummary.totalPrice}`,
            brand: brandName ? `${brandName}` : '',
            category: 'Bags',
            quantity: 1,
            dimension6: Frequency2[Frequency2.Weekly],
            dimension13: 'restart v1',
          },
          {
            name: couponCode ? 'Coupon' : referralCode ? 'Referral' : null,
            id: couponCode + referralCodeExists + referralCode,
            price: `${totalDiscount.toFixed(2)}`,
            brand: 'Coupon',
            category: 'Discount',
            quantity: 1,
            dimension6: Frequency2[Frequency2.Weekly],
            dimension13: 'restart v1',
          },
        ],
      },
    },
  });
  return;
};

export const trackReactivateSubscriptionWithSku = async (
  productSummary: ProductSummaryResponse,
  rafCode: string,
  promoCode: string
) => {
  const userMgr = await createUserManager();
  const customerNumber = await userMgr.getCustomerNumber();
  const couponCode =
    promoCode && promoCode.length > 0 ? `coupon:${promoCode}` : '';
  const referralCode =
    rafCode && rafCode.length > 0 ? `referral:${rafCode}` : '';
  const referralCodeExists = couponCode && referralCode ? '|' : '';
  dataLayer.push({
    event: ReactivateSubscriptionEvent.REACTIVATION_SUBSCRIPTION_STEP_1,
    customerStage: 'Existing',
    eventDetails: {
      category: 'Ecommerce',
      action: ReactivateSubscriptionAction.REACTIVATE_SUBSCRIPTION_STEP_1,
    },
    userId: `${customerNumber}`,
    ecommerce: {
      currencyCode: 'NZD',
      checkout: {
        actionField: {
          step: 1,
          action: 'checkout',
          revenue: `${productSummary.totalPrice}`,
          purchase_type: 'restart_subscription',
          coupon: couponCode + referralCodeExists + referralCode,
        },
      },
    },
  });
  return;
};

export const trackReactivateSubscription = async (
  step: ReactivateSubscriptionEvent,
  productSummary: ProductSummaryResponse,
  brandName: string,
  rafCode: string,
  promoCode: string,
  totalDiscount: number
) => {
  const userMgr = await createUserManager();
  const customerNumber = await userMgr.getCustomerNumber();
  const couponCode =
    promoCode && promoCode.length > 0 ? `coupon:${promoCode}` : '';
  const referralCode =
    rafCode && rafCode.length > 0 ? `referral:${rafCode}` : '';
  const referralCodeExists = couponCode && referralCode ? '|' : '';
  switch (step) {
    case ReactivateSubscriptionEvent.REACTIVATION_SUBSCRIPTION_STEP_2:
      dataLayer.push({
        event: ReactivateSubscriptionEvent.REACTIVATION_SUBSCRIPTION_STEP_2,
        customerStage: 'Existing',
        eventDetails: {
          category: 'Ecommerce',
          action: ReactivateSubscriptionAction.REACTIVATE_SUBSCRIPTION_STEP_2,
        },
        userId: `${customerNumber}`,
        ecommerce: {
          currencyCode: 'NZD',
          checkout: {
            actionField: {
              step: 2,
              action: 'checkout',
              revenue: `${productSummary.totalPrice}`,
              purchase_type: 'restart_subscription',
              coupon: couponCode + referralCodeExists + referralCode,
            },
            products: [
              {
                name: `${productSummary.name}`,
                id: `${productSummary.sku}`,
                price: `${productSummary.totalPrice}`,
                brand: brandName ? `${brandName}` : '',
                category: 'Bags',
                quantity: 1,
                dimension6: Frequency2[Frequency2.Weekly],
                dimension13: 'restart v1',
              },
              {
                name: couponCode ? 'Coupon' : referralCode ? 'Referral' : null,
                id: couponCode + referralCodeExists + referralCode,
                price: `${totalDiscount.toFixed(2)}`,
                brand: 'Coupon',
                category: 'Discount',
                quantity: 1,
                dimension6: Frequency2[Frequency2.Weekly],
                dimension13: 'restart v1',
              },
            ],
          },
        },
      });

      return;
    case ReactivateSubscriptionEvent.PURCHASE:
      dataLayer.push({
        event: ReactivateSubscriptionEvent.PURCHASE,
        customerStage: 'Existing',
        eventDetails: {
          category: 'Ecommerce',
          action: ReactivateSubscriptionAction.PURCHASE,
        },
        userId: `${customerNumber}`,
        ecommerce: {
          currencyCode: 'NZD',
          purchase: {
            actionField: {
              id: `${customerNumber}`,
              revenue: `${productSummary.totalPrice}`,
              purchase_type: 'restart_subscription',
              coupon: couponCode + referralCodeExists + referralCode,
            },
            products: [
              {
                name: `${productSummary.name}`,
                id: `${productSummary.sku}`,
                price: `${productSummary.totalPrice}`,
                brand: brandName ? `${brandName}` : '',
                category: 'Bags',
                quantity: 1,
                dimension6: Frequency2[Frequency2.Weekly],
                dimension13: 'restart v1',
              },
              {
                name: couponCode ? 'Coupon' : referralCode ? 'Referral' : null,
                id: couponCode + referralCodeExists + referralCode,
                price: `${totalDiscount.toFixed(2)}`,
                brand: 'Coupon',
                category: 'Discount',
                quantity: 1,
                dimension6: Frequency2[Frequency2.Weekly],
                dimension13: 'restart v1',
              },
            ],
          },
        },
      });

      return;
    default:
      break;
  }
};
