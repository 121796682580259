import * as React from 'react';
import {
  deliveriesPath,
  manageAccountPath,
  rateRecipesPath,
} from '../../account-settings/components/Nav/paths';
import {BaseMenu, MenuItemType} from '@mfb/lego';
import routeHistory from '../../../routerHistory';

export interface FooterProps {
  currentPathName?: string;
}

const myDealsPath = `${manageAccountPath}/refer-a-friend`;

const Footer = ({currentPathName}: FooterProps) => {
  return (
    <BaseMenu
      baseMenuContext={[
        {item: MenuItemType.Deliveries, url: deliveriesPath},
        {
          item: MenuItemType.Account,
          url: manageAccountPath,
          showActiveRelative: true,
        },
        {item: MenuItemType.MyDeals, url: myDealsPath},
        {item: MenuItemType.RateRecipes, url: rateRecipesPath},
      ]}
      currentPath={currentPathName}
      onSelect={url => routeHistory.history.push(url)}
    />
  );
};

export default Footer;
