/**
 * As IE11 doesn't support `form.reportValidity()` to trigger the display of HTML validation messages, the easiest way
 * to display those messages is to attempt to submit the form. This function submits the form without needing to
 * care about where the submit function is (or whether there is one)
 */
const submitForm = (form: HTMLFormElement) => {
  const submit = document.createElement('button');
  form.appendChild(submit);
  submit.click();
  setTimeout(() => form.removeChild(submit), 0);
};

export default submitForm;
