import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {GroupedCampaignMarketingDto} from '../../shared/CoreClient';

const DivWithBorder = styled.div`
  border-radius: 15px;
  border: 4px solid;
  min-width: 13.5rem;
  cursor: pointer;
  height: inherit !important;
`;

export interface ComparisonTileProps {
  campaignCode: string;
  campaignName: string;
  isSelected: boolean;
  marketing: GroupedCampaignMarketingDto;
  onClick(selectedCampaignCode: string): void;
}

export const ComparisonTile: React.FC<ComparisonTileProps> = ({
  campaignCode,
  campaignName,
  isSelected,
  marketing,
  onClick,
}) => {
  const {description, claims} = marketing;

  return (
    <DivWithBorder
      className={classnames(
        'py-3 pl-3 pr-2 m-0 mx-2 h-100 w-100',
        `${isSelected ? 'border-primary' : 'border-secondary'}`
      )}
      onClick={() => onClick(campaignCode)}
    >
      <label className="custom-control custom-radio">
        <input
          checked={isSelected}
          type="radio"
          className="custom-control-input"
        />
        <label className="custom-control-label" htmlFor="customCheck" />
      </label>
      <h4>{campaignName}</h4>
      <p>{description}</p>
      <ul className="pl-3">
        {claims &&
          claims.map((claim, index) => (
            <li key={`${campaignCode}-claim-${index}`}>{claim}</li>
          ))}
      </ul>
    </DivWithBorder>
  );
};
