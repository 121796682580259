import React from 'react';
import {
  DeliveryStatus,
  RecipeResponse,
  RecipeSelectionMode,
} from '../../shared/CoreClient';
import { OrderItemGrid } from './OrderItemGrid';
import { CarouselItemModel } from '@mfb/lego';
import { CookbookStrategyContext } from '@mfb/cookbook';
import { mapRecipesToRecipeItem } from './mapRecipesToRecipeItems';
import { AccountCookbook } from '../../shared/AccountCookbook';

// Used to add an empty item in the carousel so that the alignment of items
// is correct when there is only one item in the carousel.
export const carouselItemPlaceholder: CarouselItemModel = {
  key: `carousel-item-placeholder`,
  component: <React.Fragment />,
};

interface OrderRecipesProps {
  recipes: Array<RecipeResponse>;
  recipeSelectionMode?: RecipeSelectionMode;
  className?: string;
  deliveryStatus?: DeliveryStatus;
}

export const OrderRecipes: React.FC<OrderRecipesProps> = ({
  recipes,
  recipeSelectionMode,
  className,
  deliveryStatus,
}) => {
  const recipeItems = React.useMemo(
    () =>
      recipes.length > 0 &&
      recipes.map((recipe) =>
        mapRecipesToRecipeItem({
          recipe,
          deliveryStatus,
          recipeSelectionMode,
        })
      ),
    [recipes]
  );

  return (
    recipeItems &&
    recipeItems.length > 0 && (
      <CookbookStrategyContext.Provider value={new AccountCookbook()}>
        <div className={className}>
          <OrderItemGrid orderItems={recipeItems} />
        </div>
      </CookbookStrategyContext.Provider>
    )
  );
};
