import React from 'react';

interface Props {
  termsHref?: string;
  privacyHref?: string;
}

export const TermsAndConditionsMessage: React.FC<Props> = ({
  termsHref = 'https://www.myfoodbag.co.nz/terms',
  privacyHref = 'https://www.myfoodbag.co.nz/privacy',
}) => {
  return (
    <small className="d-block pt-2">
      By continuing I agree to the{' '}
      <a href={termsHref} target="_blank" rel="noopener noreferrer">
        Terms &amp; Conditions
      </a>{' '}
      and{' '}
      <a href={privacyHref} target="_blank" rel="noopener noreferrer">
        Privacy policy
      </a>
      .
    </small>
  );
};
