import { globalSettings as settings } from 'config';

export const RECIPE_URL = `${settings.webURL}/recipes/details/`;

export const HELP_REQUEST_URL =
  'https://help.myfoodbag.co.nz/hc/en-nz/requests/new/';

export const DELIVERY_HOLIDAY_URL = 'https://www.myfoodbag.co.nz/holidays';

export const CURRENCY_DIGITS = 2;

// FIXME: this is only here temporarily - pull from BE
export const CONTACT_PHONE = settings.isMyFoodBag
  ? '0800 469 366'
  : '0800 227 424';

export const MIN_PASSWORD_LENGTH = 8;

export const COLOR_CODE = {
  WHITE: '#ffffff',
  ERROR: '#e12a4f',
  DARK_BROWN: '#867566',
  LIGHT_BROWN: '#BBB1A7',
  BARELY_THERE_BEIGE: '#EEECE8',
  MFB_GREEN: '#77a222',
  TRANSLUCENT_GREEN: '#1c00ff00',
  DARK_YELLOW: '#f5a623',
  FAUX_GUYABANO: '#FAF9F8',
  GREY: '#BEBEBE',
  DARK_RED: '#EE3453',
  DISCOUNT_BLUE: '#006eda',
};

export enum BrandName {
  BargainBox = 'Bargain Box Choice',
  ReadyMadeMeals = 'MADE',
  FreshStartChoice = 'Fresh Start Choice',
  MyFoodBag = 'My Food Bag',
  MyClassic = 'My Classic',
  MyFamily = 'My Family',
}

export enum BrandProductOptions {
  ReadyMadeMealsFourMeals = '4 meals',
  ReadyMadeMealsSixMeals = '6 meals',
  ReadyMadeMealsNineMeals = '9 meals',
  ReadyMadeMealsTwelveMeals = '12 meals',
}
export enum TriggerCode {
  SKIP = 'skip',
  CANCEL = 'cancel',
  PYW_EXIT_KITCHEN = 'pywContinueFromKitchen',
  PYW_EXIT_MEALS = 'pywContinueFromMeals',
  PYW_ENTRY_KITCHEN = 'pywEntryToKitchen',
  PYW_ENTRY_MEALS = 'pywEntryToMeals',
  CONTENT_SLOT = 'ContentSlot'
}

export const BootStrapBreakPoint = {
  large: window.innerWidth >= 768,
};

export enum ProductCollectionCode {
  THE_BUTCHER = 'BUTCHER',
  THE_SUMMER_BUTCHER = 'SUMMERBBQ'
}

export enum DeliveryLine {
  product= 'PRODUCT',
}

export const platform = "Web Account";
