export const rootPath = '/my-account';

// base paths
export const manageAccountPath = `${rootPath}/manage`;
export const deliveriesPath = `${rootPath}/deliveries`;
export const rateRecipesPath = `${rootPath}/rate-recipes`;
export const campaignPath = `${rootPath}/campaign`;
export const mealSelectorPath = `${rootPath}/meal-selector`;

// manage account sub paths
export const subscriptionsPath = `${rootPath}/manage/subscriptions`;
export const subscriptionsViewPathLegacy = `${rootPath}/manage/subscriptions/viewLegacy`;
export const subscriptionsViewPath = `${rootPath}/manage/subscriptions/view`;
export const subscriptionsAddPath = `${rootPath}/manage/subscriptions/add`;
export const subscriptionsRestartPath = `${rootPath}/manage/subscriptions/restart`;
export const subscriptionsCancelPath = `${rootPath}/manage/subscriptions/cancel`;
export const subscriptionsCancelPathV1 = `${rootPath}/manage/subscriptions/cancelV1`;
export const personalDetailsPath = `${rootPath}/manage/personal-details`;
// manage extras
export const personalDetailsEditPath = `${rootPath}/manage/personal-details/edit`;
// -- WARNING -- changing this route 'payment-details' will require a change in the BFF -> PaymentController.cs
export const paymentDetailsPath = `${rootPath}/manage/payment-details`;
export const orderHistoryPath = `${rootPath}/manage/order-history`;
export const vouchersPath = `${rootPath}/manage/vouchers`;
export const referAFriendPath = `${rootPath}/manage/refer-a-friend`;
export const holidaysPath = `${rootPath}/manage/holidays`;

// deliveries sub paths
export const calendarPath = `${rootPath}/deliveries/calendar`;

// generic paths
export const notFoundPath = `${rootPath}/not-found`;
export const deliveryNotFoundPath = `${rootPath}/deliveries/not-found`;
export const unauthorizedPath = `${rootPath}/unauthorized`;
export const errorPath = `${rootPath}/error`;

// validate customerNumber path from order form
export const customerRedirectPath = `${rootPath}/customerRedirect`;

// redirect paths from identity
//
// !! IMPORTANT !!
// Each path must be unique and cannot be a subset of another path
// for example /signin and /signin2 is not allowed
export const redirectRootPath = `${rootPath}/redirect`;
export const silentRefreshTokenRedirectPath = `${redirectRootPath}/silentTokenRefresh`;
export const signinIS3RedirectPath = `${redirectRootPath}/identityServer3`;
export const is3ConversionRedirectPath = `${redirectRootPath}/conversion`;
export const signinRedirectPath = `${redirectRootPath}/signin`;
export const signinNoPromptRedirectPath = `${redirectRootPath}/noPrompt`;
export const passwordResetRedirectPath = `${redirectRootPath}/passwordReset`;
export const logoutCustomerRedirectPath = `${redirectRootPath}/customerRedirectLogout`;
export const logoutIS3RedirectPath = `${redirectRootPath}/logoutLegacy`;
export const logoutRedirectPath = `${redirectRootPath}/logout`;
