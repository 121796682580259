import MsalUserManager  from './MsalUserManager';
import OidcUserManager from './OidcUserManager';
import { globalSettings as settings } from 'config';
import mixpanel from 'mixpanel-browser';

/*
 * Responsible for authentication actions on the current user
 */
export interface UserManager {
  getCustomerNumber(): Promise<string>;
  getBearerToken(): Promise<string>;
  authenticate(): Promise<MFBUser>;
  logout(): Promise<void>;
  PasswordReset(email: string): Promise<void>;
  handleCustomerRedirectLogout(): Promise<void>;
}

export interface MFBUser {
  customerNumber: string;
}

const createUserManager = async (): Promise<UserManager> => {
  const userManager =   settings.adb2cUserManager === 'msal'
    ? await MsalUserManager.getInstance()
    : new OidcUserManager();

  mixpanel.identify(await userManager.getCustomerNumber() ?? 'unknown');
  return userManager;
}

export default createUserManager;
