// From https://en.wikipedia.org/wiki/Telephone_numbers_in_New_Zealand#Mobile_phones
export const validPrefixes: ReadonlyArray<string> = [
  '0201',
  '0202',
  '0203',
  '0204',
  '0205',
  '0206',
  '0207',
  '0208',
  '0209',
  '021',
  '022',
  '026',
  '027',
  '0280',
  '028',
  '0284',
  '02885',
  '02886',
  '02889',
  '02896',
  '029',
];

const mobileNumber = (value?: string) => {
  if (!value) {
    return false;
  }

  if (value.startsWith('+64')) {
    return false;
  }

  if (value.length < 9 || value.length > 11) {
    return false;
  }

  if (!/^\d*$/.test(value)) {
    return false;
  }

  return validPrefixes.reduce(
    (result: boolean, prefix: string) => result || value.startsWith(prefix),
    false
  );
};

export const validateMobileNumber = (mobile: string) => {
  const isRequired = !mobile;

  if (isRequired) {
    return 'A mobile number is required to send delivery updates.';
  }

  const valid = mobileNumber(mobile);

  if (!valid) {
    return 'Mobile must be a valid NZ mobile number. e.g. 0213456789';
  }

  return undefined;
};

export default validateMobileNumber;
