import React from 'react';
import {
  ProfileMenu,
  ManageAccountNavigationMenu,
  ProfileMenuProps,
  MenuItemProps,
} from '@mfb/lego';
import createUserManager from '../UserManager';

interface Props {
  profileInfo?: ProfileMenuProps;
  menuItems: Array<MenuItemProps>;
}

const NavMobile: React.FunctionComponent<Props> = ({
  menuItems,
  profileInfo={firstName: '', lastName: '', productName: '', deliveryDate: '', deliveryTime: ''},
}: Props) => {
  const logout = async () => {
    const userMgr = await createUserManager();
    await userMgr.logout();
  };

  const profileMenuState: ProfileMenuProps = {
    ...profileInfo,
    signOutCallback: profileInfo.signOutCallback ?? logout,
  };

  return (
    <nav className="mt-4">
      <ProfileMenu {...profileMenuState}>
        <ManageAccountNavigationMenu menuItems={menuItems} />
      </ProfileMenu>
    </nav>
  );
};

export default NavMobile;
