import React from 'react';
import {TabButton} from './TabButton';
import {CarouselItemModel} from '@mfb/lego';
import TrackingProps from '../../../shared/TrackingProps';
import {monthStringMapping} from '../../../../lib/date/dateFormat';

export interface DeliveryDateTabProps {
  deliveryDate: string;
  isSelected: boolean;
  isSkipped: boolean;
  hasOverrideDeliveryDate: boolean;
  tracking?: TrackingProps;
  isOrderDelivered?: boolean;
  onClick?(): void;
}

export const getFormattedDeliveryDate = (deliveryDate: string) => {
  const dateString = deliveryDate.substring(1);
  const month = dateString.split('-')[1];
  const day = dateString.split('-')[2];
  return {dayOfMonth: day, month: monthStringMapping[month]};
};

export const DeliveryDateTab: React.FC<DeliveryDateTabProps> = ({
  deliveryDate,
  isSelected,
  isSkipped,
  hasOverrideDeliveryDate,
  tracking,
  isOrderDelivered,
  onClick,
}) => {
  const {dayOfMonth, month} = React.useMemo(
    () => getFormattedDeliveryDate(deliveryDate),
    [deliveryDate]
  );
  
  return (
    <TabButton
      isSelected={isSelected}
      isSkipped={isSkipped}
      tracking={tracking}
      onClick={onClick}
      deliveryDate={deliveryDate}
      dayOfMonth={dayOfMonth}
      isOrderDelivered={isOrderDelivered}
      hasOverrideDeliveryDate={hasOverrideDeliveryDate}
    >
      <span style={{lineHeight: '1rem'}}>
        <h3 className="mb-0">{dayOfMonth}</h3>
        <div>{month}</div>
      </span>
    </TabButton>
  );
};

export const mapDeliveryDateToCarouselTab = (
  props: DeliveryDateTabProps
): CarouselItemModel => ({
  key: `ckey-${props.deliveryDate}`,
  component: <DeliveryDateTab {...props} />,
});
