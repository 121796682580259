import React from 'react';
import {
  Day,
  DeliveryStatus,
  Order,
  OrderAction,
  OrderWithRecipesAndExtras,
  SkipReason,
} from '../../../shared/CoreClient';
import { BagManagementButtons } from './BagManagementButtons';
import { BagInteractionHandlers } from '../DeliveryContainerWithTabs';
import {
  DeliveryMessageProps,
  DeliveryState,
  OrderedMessage,
} from '../getDeliveryMessage';
import { HolidayNotifications } from './HolidayNotifications';
import styled from 'styled-components';
import { TimeLeftToChooseMeals } from '../TimeLeftToChooseMeals';
import { FlexPanel, PillBadge, AccountColors } from '@mfb/lego';
import { useTranslation } from 'react-i18next';
import { OrderWithRecipesAndExtrasExtended } from 'app/shared/sculleryToCoreResponseMapper';
import { isOrderValidForActions } from 'app/shared/helper';

export enum OrderStatus {
  /** @value for an order that was skipped and the delivery date is in the past */
  SKIPPED_PAST_DUE = 'SKIPPED_PAST_DUE',
  SKIPPED = 'Skipped',
  DELIVERED = 'Delivered',
  ORDERED = 'Ordered',
  CONFIRMED = 'Confirmed',
  COMING_UP = 'Coming Up',
  CREATING = 'Processing'
}

const HeaderContainer = styled.div`
  h6 {
    height: 28px;
    color: ${c => c.theme.colors.neutral["900"]};
    font-size: ${c => c.theme.typography.fontSize["500"]};
    font-weight: ${c => c.theme.typography.fontWeight["900"]};
    line-height: 1.3;
    margin: 0 8px 0 0;
    align-self: flex-start;
  }
`;

const Container = styled(FlexPanel)`
  margin-top: 24px;
  margin-bottom: 32px;
  @media ${(c) => c.theme.layout.deviceMediaQueries.max.sm} {
    flex-direction: column;
  }

  .__status-pill-badge {
    width: fit-content;
    white-space: nowrap;
  }

  @media ${(c) => c.theme.layout.deviceMediaQueries.max.md} {
    .__header-badge-container {
      flex-direction: column;
    }
  }

  @media ${(c) => c.theme.layout.deviceMediaQueries.max.sm} {
    .__header-badge-container {
      flex-direction: row;
    }
  }
`;

export interface OrderHeaderProps {
  /** Prefer OrderWithRecipesAndExtras
   * Todo: Deprecate Order and use OrderWithRecipesAndExtras
   */
  orderLegacy?: Order;
  currentOrder?: OrderWithRecipesAndExtrasExtended;
  day?: Day;
  hasValidationError?: boolean;
  bagInteractionHandlers?: BagInteractionHandlers;
  fetchYourAccountState?(): void;
  isSingleSubscription?: boolean;
  className?: string;
}

export const OrderHeader: React.FC<OrderHeaderProps> = ({
  orderLegacy,
  currentOrder,
  day,
  hasValidationError,
  bagInteractionHandlers,
  fetchYourAccountState,
  isSingleSubscription,
}) => {
  const { isLocked, deliveryAddress, isProductSameAsSubscription } =
    orderLegacy;

  const {
    deliveryId,
    orderActions,
    subscriptionId,
    deliveryStatus,
    deliveryDate,
    productOptionId,
    availableFrequency,
    skipReason,
  } = currentOrder;

  const { t } = useTranslation();

  const { deliverySlotId } = deliveryDate;

  const isHolidaySkipReason = skipReason == SkipReason.HolidaySkip;

  const deliveryInstructions =
    currentOrder.deliveryAddress.deliveryInstructions;

  const addressModalState = React.useState<boolean>(false);

  const primaryLine = currentOrder.deliveryLines.find(
    (dl) => dl.deliveryLineType === 'PRODUCT'
  );

  const message = React.useMemo(() => {
    let deliveryState = orderLegacy.isOrdered
      ? DeliveryState.Ordered
      : isLocked
      ? DeliveryState.Locked
      : undefined;
    const deliveryMessageProps: DeliveryMessageProps = {
      subscriptionId,
      deliveryDate: (day || '').toString(),
      deliveryId,
      deliveryState,
      deliveryAddress,
      productOptionId,
      deliverySlotId,
      deliveryInstructions,
      isProductSameAsSubscription,
      availableFrequency,
      actions: orderActions.filter((a) => a !== OrderAction.Skip),
      fetchYourAccountState,
      addressModalState,
    };
    return OrderedMessage(deliveryMessageProps);
  }, [
    orderActions,
    deliveryId,
    orderLegacy.isOrdered,
    isLocked,
    addressModalState[0],
  ]);

  let orderStatus: OrderStatus = null;

  const isLockedAndAddressTimeEditable =
    orderActions.length === 2 &&
    orderActions.includes(OrderAction.ChangeDeliveryAddress) &&
    orderActions.includes(OrderAction.ChangeDeliverySlot);

  if (currentOrder.isSkipped && orderActions.length === 0) {
    orderStatus = OrderStatus.SKIPPED_PAST_DUE;
  } else if (
    currentOrder.isSkipped &&
    deliveryStatus !== DeliveryStatus.Delivered
  ) {
    orderStatus = OrderStatus.SKIPPED;
  } else if (currentOrder.isCreating){
    orderStatus = OrderStatus.CREATING;
  } else if (deliveryStatus === DeliveryStatus.Delivered) {
    orderStatus = OrderStatus.DELIVERED;
  } else if (orderLegacy.isOrdered && isLockedAndAddressTimeEditable) {
    orderStatus = OrderStatus.ORDERED;
  } else if (orderLegacy.recipes.length > 0 && orderActions.length === 0) {
    orderStatus = OrderStatus.CONFIRMED;
  } else if (orderLegacy.recipes.length > 0 && orderActions.length > 0) {
    orderStatus = OrderStatus.COMING_UP;
  }

  const getStatusPillBadge = () => {
    switch (orderStatus) {
      case OrderStatus.SKIPPED: {
        return {
          label: OrderStatus.SKIPPED,
          bgColour: AccountColors.alert.danger[100],
          fgColour: AccountColors.alert.danger[400],
        };
      }
      case OrderStatus.DELIVERED: {
        return {
          label: t('deliveries.heading.title', { context: 'past' }),
          bgColour: AccountColors.neutral[300],
          fgColour: AccountColors.neutral[900],
        };
      }
      case OrderStatus.ORDERED: {
        return {
          label: orderStatus,
          bgColour: AccountColors.alert.info[100],
          fgColour: AccountColors.alert.info[400],
        };
      }
      case OrderStatus.CONFIRMED: {
        return {
          label: orderStatus,
          bgColour: AccountColors.alert.success[100],
          fgColour: AccountColors.alert.success[400],
        };
      }
      case OrderStatus.COMING_UP: {
        return {
          label: t('deliveries.heading.title', { context: 'future' }),
          bgColour: AccountColors.alert.info[100],
          fgColour: AccountColors.alert.info[400],
        };
      }
      case OrderStatus.CREATING: {
        return {
          label: orderStatus,
          bgColour: AccountColors.alert.success[100],
          fgColour: AccountColors.alert.success[400],
        };
      }
      default:
        return null;
    }
  };

  const getHeader = () => {
    const primaryLine = currentOrder.deliveryLines.find(
      (dl) => dl.deliveryLineType === 'PRODUCT'
    );
    const bagDescription = primaryLine.lineDescription;

    if (orderStatus === OrderStatus.SKIPPED_PAST_DUE) {
      return `You've skipped this week`;
    }

    if (orderStatus === OrderStatus.CREATING) {
      return `Processing your order`;
    }

    if (!isSingleSubscription) {
      return bagDescription;
    }
    return t('deliveries.recipeSelection.title');
  };

  const statusBadge = getStatusPillBadge();

  const isCanOnlyAddExtras =
  isOrderValidForActions(currentOrder, ['canAddExtras']) &&
  !isOrderValidForActions(currentOrder, ['canChangeMeals']) &&
  currentOrder.availableRecipes.length > 0;

  return (
    <div>
      <Container>
        <HeaderContainer>
          <FlexPanel className="__header-badge-container">
            <h6>{getHeader()}</h6>
            {statusBadge && (
              <PillBadge
                className="__status-pill-badge"
                size="small"
                {...statusBadge}
              />
            )}
          </FlexPanel>
          <TimeLeftToChooseMeals
            currentOrder={currentOrder}
            orderStatus={orderStatus}
            isCanOnlyAddExtras={isCanOnlyAddExtras}
          />
        </HeaderContainer>
        <BagManagementButtons
          {...{
            order: orderLegacy,
            currentOrder: currentOrder,
            deliveryDateId: deliveryDate.deliveryDateId,
            day,
            hasValidationError,
            bagInteractionHandlers,
            isHolidaySkipReason,
            bagSku: primaryLine.sku,
            addressModalState,
          }}
        />
      </Container>
      {message}
      {currentOrder.notifications &&
        currentOrder.notifications.map((notification, index) => {
          return (
            <HolidayNotifications key={index} notification={notification} />
          );
        })}
    </div>
  );
};
