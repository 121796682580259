import {
  addCreditCard,
  oneOffPayment,
  setActiveCreditCard,
} from '../actions/accountSettingsActions/paymentsAction';
import {Middleware, MiddlewareAPI, Dispatch} from 'redux';
import {MessageAction} from '../../app/shared/CoreClient';
import 'redux-thunk'; // Extends Middleware and Dispatch to support function actions

// FIXME: See https://github.com/MyFoodBag/repository/issues/1560 for a discussion on simplifying messages
const messageActionMiddleWare: Middleware = <S>({
  dispatch,
  getState,
}: MiddlewareAPI<S>) => (next: Dispatch<S>) => async (action: any) => {
  switch (action.type) {
    case MessageAction.AddCreditCard:
      await dispatch(addCreditCard());
      break;
    case MessageAction.PayAndSaveCard:
      await dispatch(oneOffPayment(action.attribute as number, true, false));
      break;
    case MessageAction.PayWithoutSavingCard:
      await dispatch(oneOffPayment(action.attribute as number, false, false));
      break;
    case MessageAction.PayWithDefaultCard:
      await dispatch(oneOffPayment(action.attribute as number, false, true));
      break;
  }

  return next(action);
};

export default messageActionMiddleWare;
