import React from 'react';
import {COLOR_CODE} from '../constants';
import Icon, {IconProps} from './Icon';

class CrossIcon extends Icon {
  static defaultProps: Partial<IconProps> = {
    color: COLOR_CODE.ERROR,
    height: 13,
    seasonal: false,
    title: '',
    width: 13,
  };

  renderIcon() {
    const {color} = this.props;

    return (
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4615 6.23077C12.4615 9.6728 9.6728 12.4615 6.23077 12.4615C2.78874 12.4615 0 9.6728 0 6.23077C0 2.78874 2.78874 0 6.23077 0C9.6728 0 12.4615 2.78874 12.4615 6.23077ZM3.62354 3.62348C3.91643 3.33059 4.39131 3.33059 4.6842 3.62348L6.23081 5.17009L7.77739 3.62352C8.07028 3.33062 8.54515 3.33062 8.83805 3.62352C9.13094 3.91641 9.13094 4.39128 8.83805 4.68418L7.29147 6.23075L8.83805 7.77733C9.13094 8.07022 9.13094 8.54509 8.83805 8.83799C8.54515 9.13088 8.07028 9.13088 7.77739 8.83799L6.23081 7.29141L4.6842 8.83802C4.39131 9.13092 3.91643 9.13092 3.62354 8.83802C3.33065 8.54513 3.33065 8.07026 3.62354 7.77736L5.17015 6.23075L3.62354 4.68414C3.33065 4.39125 3.33065 3.91637 3.62354 3.62348Z"
        fill={color}
      />
    );
  }
}

export default CrossIcon;
