import Spinner from 'app/shared/components/Spinner';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  AnalyticParams,
  LegoScopesProvider,
  TranslationProvider,
} from '@mfb/lego';
import App from './App';
import {
  DispatcherProps,
  PageProperties,
  mapDispatchToProps,
  mapStateToProps,
} from './app/account-settings/containers/accountSettingsContainer';
import { AppState } from './redux/state';
import { AlertToastProvider } from 'app/shared/hooks/editDeliveryDetails';
import { fetchTracking } from './redux/actions/sharedActions/trackingAction';

interface AppShellProps extends PageProperties, AppState {
  history?: History;
}

class AppShell extends React.Component<AppShellProps> {
  async componentDidMount(): Promise<void> {
    const { fetchFeaturesThunk, fetchSubscriptions, initI18nThunk, fetchTracking } =
      this.props;

    // Important Global State Thunks / APIs should fire here.
    await Promise.all([
      fetchTracking(),
      fetchFeaturesThunk(),
      fetchSubscriptions(),
      initI18nThunk()
    ])
  }

  render() {
    const scopeData: AnalyticParams = {
      userId: this.props.customerProfileState.customerNumber,
    };

    if (!this.props.translations.i18nInitOptions) {
      return <Spinner />;
    }

    return (
      <LegoScopesProvider replaceScope scopeData={scopeData}>
        <TranslationProvider
          fallback={<Spinner />}
          options={{
            initOptions: this.props.translations.i18nInitOptions,
          }}
        >
          <AlertToastProvider>
            <App history={this.props.history} />
          </AlertToastProvider>
        </TranslationProvider>
      </LegoScopesProvider>
    );
  }
}

export default connect<AppState, DispatcherProps, AppShellProps>(
  mapStateToProps,
  mapDispatchToProps
)(AppShell) as any;
