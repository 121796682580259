import {
  CustomerMessageContextState,
  CustomerMessageContextType,
  CustomerMessageGATrackingData, TrackingMetaData,
} from '@mfb/lego';
import { CustomerMessageBulkGroupState, CustomerMessageBulkState, TrackingState } from 'redux/state';
import { TriggerCode } from './constants';
import {
  SessionStorageKey,
  readFromSessionStorage,
  writeToSessionStorage,
} from 'redux/sessionStorage';
import { navman } from 'navigator';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

export enum CustomerMessageProxyDeepLink {
  SKIP = 'my-account/skip-delivery',
  CLOSE_MODAL = 'my-account/close-modal',
  CANCEL = 'my-account/manage/cancel',
  //TODO : remove this proxy link once it is removed from DB - this is a duplicate of cancel
  CONTINUE_TO_CANCEL = 'my-account/manage/continueToCancellation',
  DO_NOTHING = '#',
  EDIT_DELIVERY_DETAILS = 'my-account/manage/edit-delivery-details',
}

export const customerMessageDefaultContext: CustomerMessageContextType = {
  triggerContext: {
    triggers: [],
    defaultActions: { primary: { label: '' }, secondary: { label: '' } },
  },
  messages: [],
  onNavigate: () => undefined,
  closeUrl: CustomerMessageProxyDeepLink.CLOSE_MODAL,
  gATrackingData: {},
};

type CustomerMessageSessionStorage = {
  hasSeen: boolean;
};

export type DisplayCustomerMessageType = {
  customerMessageContext: {
    context: MutableRefObject<CustomerMessageContextType>;
    state: [
      CustomerMessageContextState,
      Dispatch<SetStateAction<CustomerMessageContextState>>
    ];
  };
  customerMessageBulkGroupState: Array<CustomerMessageBulkGroupState>;
  triggerCode: TriggerCode;
  subscriptionNumber: string;
  deliveryDateId: number;
  onNavigate: (url: string, triggerId?: number) => Promise<any>;
  ignoreTriggers?: boolean;
  /**
   * @property if true, the seen state of the message will be stored in the session storage.
   * The message will not be displayed again when `displayCustomerMessage` is invoked,
   * unless it has expired or cleared.
   */
  considerSeen?: boolean;
  hideTriggersWithProxyDeepLink?: CustomerMessageProxyDeepLink[];
  gATrackingData?: Partial<CustomerMessageGATrackingData>;
  trackingMetaData?: TrackingMetaData
};

export const displayCustomerMessage = (
  displayContext: DisplayCustomerMessageType,
) => {
  const {
    customerMessageContext,
    triggerCode,
    subscriptionNumber,
    deliveryDateId,
    onNavigate,
    ignoreTriggers,
    gATrackingData,
    considerSeen,
    trackingMetaData
  } = displayContext;

  const {
    context,
    state: [, setState],
  } = customerMessageContext;

  const customerMessageState = getCustomerMessageFromGroup(displayContext);

  if (customerMessageState) {
    const { customerMessageResult } = customerMessageState;
    let { triggers } = customerMessageResult;
    const { customerMessages } = customerMessageResult;


    context.current = {
      ...customerMessageDefaultContext,
      triggerContext: {
        header: triggers.title,
        triggers: triggers.customerMessageTriggers,
        defaultActions: {
          primary: { label: 'Continue', disabled: true },
          secondary: { label: 'Cancel' },
        },
      },
      messages: customerMessages,
      onNavigate,
      gATrackingData: {
        ...gATrackingData,
        message_journey: gATrackingData.message_journey || triggerCode,
      },
      trackingMetaData
    };

    const hasTriggersOrMessages = customerMessageResult?.triggers.customerMessageTriggers?.length > 0 || customerMessageResult?.customerMessages?.length > 0;

    setState((prev) => ({
      ...prev,
      isPanelOpen: hasTriggersOrMessages,
      activeMessage:
        triggers.customerMessageTriggers.length === 0 ||
        (ignoreTriggers && customerMessageState.customerMessageResult.customerMessages.length > 0)
          ? customerMessageState.customerMessageResult.customerMessages[0]
          : undefined,
    }));

    //write to session storage
    if (considerSeen) {
      const storageKey = {
        storage: SessionStorageKey.CUSTOMER_MESSAGE,
        triggerCode,
        subscriptionNumber,
        deliveryDateId,
      };

      const cmpStorageData: CustomerMessageSessionStorage = {
        hasSeen: true,
      };

      writeToSessionStorage(storageKey, cmpStorageData);
    }
  }
};

export const displayContentSlotMessages = (
  displayContext: DisplayCustomerMessageType,
) => {
  const { customerMessageContext, triggerCode, onNavigate, gATrackingData } =
    displayContext;

  const {
    context,
    state: [, setState],
  } = customerMessageContext;

  const customerMessageState = getCustomerMessageFromGroup(displayContext);

  if (customerMessageState) {
    const { customerMessageResult } = customerMessageState;
    const { customerMessages } = customerMessageResult;

    context.current = {
      ...customerMessageDefaultContext,
      messages: customerMessages,
      onNavigate,
      gATrackingData: {
        ...gATrackingData,
        message_journey: gATrackingData.message_journey || triggerCode,
      },
    };

    setState((prev) => ({
      ...prev,
    }));

    //display order for ga-tracking
    return customerMessages.map((c, i) => ({
      content_slot: `Slot ${i + 1}`,
      promo_customer_message_id: c.customerMessageId.toString(),
    }));
  }
};

export const getCustomerMessageFromGroup = (
  context: Pick<
    DisplayCustomerMessageType,
    | 'customerMessageBulkGroupState'
    | 'triggerCode'
    | 'subscriptionNumber'
    | 'deliveryDateId'
    | 'considerSeen'
    | 'hideTriggersWithProxyDeepLink'
  >,
): CustomerMessageBulkState | null => {
  const {
    customerMessageBulkGroupState,
    triggerCode,
    subscriptionNumber,
    deliveryDateId,
    considerSeen,
    hideTriggersWithProxyDeepLink
  } = context;

  const storageKey = {
    storage: SessionStorageKey.CUSTOMER_MESSAGE,
    triggerCode,
    subscriptionNumber,
    deliveryDateId,
  };

  //check if message is already seen
  const cmpStorageData = readFromSessionStorage(
    storageKey,
  ) as CustomerMessageSessionStorage;
  if (considerSeen && cmpStorageData && cmpStorageData.hasSeen) {
    return null;
  }

  const customerMessageGroup = customerMessageBulkGroupState.find(
    (c) => c.triggerCode === triggerCode,
  )?.group;

  const customerMessageBulkState = customerMessageGroup?.find(
    (c) =>
      c.subscriptionNumber === subscriptionNumber &&
      c.deliveryDateId === deliveryDateId,
  );

  const customerMessageResult = customerMessageBulkState?.customerMessageResult;

  if(!customerMessageResult) return null;

  if (!customerMessageResult.triggers) {
    customerMessageResult.triggers = {
      init: () => {
      },
      toJSON: () => ({}),
      customerMessageTriggers: [],
      title: '',
    };
  }

  const customerMessageTriggers = customerMessageBulkState?.customerMessageResult?.triggers?.customerMessageTriggers ?? [];
  const customerMessages = customerMessageBulkState?.customerMessageResult?.customerMessages ?? [];

  if(hideTriggersWithProxyDeepLink){
   const hiddenTriggerIds: number[] = [];
   const hiddenMessageIds: number[] = [];
   customerMessages.forEach(c=> {
      const shouldHide = hideTriggersWithProxyDeepLink.find(o=> c?.primaryCta?.url?.startsWith(o));
      if(shouldHide) {
        hiddenTriggerIds.push(...c.eligibleCustomerMessageTriggerIds);
        hiddenMessageIds.push(c.customerMessageId);
      }
    });

    customerMessageBulkState.customerMessageResult.triggers.customerMessageTriggers = customerMessageBulkState.customerMessageResult.triggers.customerMessageTriggers.filter(c=> !hiddenTriggerIds.includes(c.customerMessageTriggerId));
    customerMessageBulkState.customerMessageResult.customerMessages = customerMessageBulkState.customerMessageResult.customerMessages.filter(c=> !hiddenMessageIds.includes(c.customerMessageId));
  }

  const hasTriggers =  customerMessageTriggers.length > 0;
  const hasMessages = customerMessages.length > 0;

  return hasMessages || hasTriggers
    ? customerMessageBulkState
    : null;
};

/**
 * @function navigates to external or relative url, if external - new tab will be opened.
 */
export const cmpNavigateToUrl = (url?: string) => {
  if (!url || url === CustomerMessageProxyDeepLink.DO_NOTHING) {
    return;
  }
  try {
    //test for absolute url
    new URL(url);
    navman.openExternal(url);
  } catch {
    navman.relativePath(url);
  }
};
