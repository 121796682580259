import _ from 'lodash';
import { useAnalyticsTracking } from '@mfb/lego';
import { ProductSummaryResponse } from 'app/shared/CoreClient';
import { BaseEventDataParams, buildBaseEventData } from './common/buildBaseEventData';

enum ReactivationTrackingEvent {
  completeProductSelection = 'Reactivation - Complete Product Selection',
  completeShippingInformation = 'Reactivation - Complete Shipping Information',
}

interface Params {
  selectedBrandName: string;
  selectedProduct: ProductSummaryResponse;
  promoGroupCode: string;
  isDeliveryAddressModified: boolean;
  isDeliveryInstructionsModified: boolean;
  deliverySlot: string;
}

export const useReactivationTracking = () => {
  const { trackDataLayerEvent } = useAnalyticsTracking();

  const trackOnCompleteProductSelection = (params: Partial<Params>) => {
    const eventData = buildBaseEventData(
      {
        simpleProductName: params.selectedProduct.simpleName,
        productSku: params.selectedProduct.sku,
        productName: params.selectedProduct.name,
        productBrand: params.selectedBrandName,
        people: params.selectedProduct.people,
        nights: params.selectedProduct.nights,
      } as BaseEventDataParams
    );

    trackDataLayerEvent(
      ReactivationTrackingEvent.completeProductSelection,
      eventData
    );
  };

  const trackOnCompleteShippingInformation = (params: Partial<Params>) => {
    const eventData = {
      ...buildBaseEventData(      {
        simpleProductName: params.selectedProduct.simpleName,
        productSku: params.selectedProduct.sku,
        productName: params.selectedProduct.name,
        productBrand: params.selectedBrandName,
        people: params.selectedProduct.people,
        nights: params.selectedProduct.nights,
      } as BaseEventDataParams),
      promoGroupCode: params.promoGroupCode.trim() || undefined,
      isDeliveryAddressModified: params.isDeliveryAddressModified ?? false,
      isDeliveryInstructionsModified:
        params.isDeliveryInstructionsModified ?? false,
      deliverySlot: params.deliverySlot,
    };

    trackDataLayerEvent(
      ReactivationTrackingEvent.completeShippingInformation,
      eventData
    );
  };

  return {
    trackOnCompleteProductSelection,
    trackOnCompleteShippingInformation,
  };
};
