import React from 'react';
import {
  DeliveryDaySelector,
  DeliveryDaySelectorProps,
} from './DeliveryDaySelector';
import {DeliveryCalendar} from './DeliveryCalendar';
import classnames from 'classnames';
import styled from 'styled-components';
import { TickerTapeCampaignButton } from '../campaigns/TickerTapeCampaignButton';


interface Props {
  className?: string;
  setIsTickerTapePanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledDeliveryDayCarouselContainer = styled.div`
  @media (max-width: ${c => c.theme.layout.breakpoints.sm}) {
    padding-left: 0;
    padding-right: 0;
  }
`

const StyledDeliveryDayCarousel = styled.div`
  background-color: #EEECE8;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TickerTapeCampaignButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  @media ${c => c.theme.layout.deviceMediaQueries.max.lg} {
    display: none;
  }
`

export const DeliveryDayCarousel: React.FC<
  DeliveryDaySelectorProps & Props
> = ({className, deliveryDays, selectedDate, hasTickerTapeCampaign, ...props}) => {
  return (
    <StyledDeliveryDayCarouselContainer className={classnames(className)}>
      <StyledDeliveryDayCarousel
      >
        <DeliveryDaySelector
          hasTickerTapeCampaign={hasTickerTapeCampaign}
          deliveryDays={deliveryDays}
          selectedDate={selectedDate}
          {...props}
        />
        {!hasTickerTapeCampaign && <DeliveryCalendar className="flex-end pl-3" />}
        {hasTickerTapeCampaign && <TickerTapeCampaignButtonContainer>
          <TickerTapeCampaignButton {...{setIsPanelOpen: props.setIsTickerTapePanelOpen}}/>
        </TickerTapeCampaignButtonContainer>}
      </StyledDeliveryDayCarousel>
    </StyledDeliveryDayCarouselContainer>
  );
};
