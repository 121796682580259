/* eslint-disable no-unused-expressions */
import { globalSettings, isConfigValueDefined } from 'config';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const loadApplicationInsightsTag = () => {
  try {
    if (!isConfigValueDefined(globalSettings.applicationInsightsKey)) {
      return;
    }

    const appInsights = new ApplicationInsights({
      config: {
        // connectionString: 'YOUR_CONNECTION_STRING_GOES_HERE',
        instrumentationKey: globalSettings.applicationInsightsKey,
        /* ...Other Configuration Options... */
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  } catch (e) {
    console.error(e);
  }
};

export default loadApplicationInsightsTag;
