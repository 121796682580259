import React from 'react';
import Icon, {IconProps} from './Icon';
import classNames from 'classnames';

class Pencil extends Icon {
  static defaultProps: Partial<IconProps> = {
    color: '#78a12e',
    seasonal: true,
    title: 'My Food Bag phone',
    height: 20,
    width: 20,
  };

  renderIcon() {
    const {seasonal} = this.props;

    return (
      <g className={classNames({seasonal})}>
        <g
          id="Cancelling"
          transform="translate(-386.000000, -319.000000)"
          fillRule="nonzero"
        >
          <path
            d="M403.719,323.03125 L401.891,324.85925 L398.141,321.10925 L399.969,319.28125 C400.344,318.90625 401,318.90625 401.375,319.28125 L403.719,321.62525 C404.094,322.00025 404.094,322.65625 403.719,323.03125 Z M386,333.25025 L397.063,322.18725 L400.813,325.93725 L389.75,337.00025 L386,337.00025 L386,333.25025 Z"
            id="Shape"
          />
        </g>
      </g>
    );
  }
}

export default Pencil;
