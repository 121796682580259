export const getPromoCodeQueryParam = (): string => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('promoCode');
};

export const getBrandQueryParam = (): string => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('Brand');
};

export const getSkuQueryParam = (): string => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('sku');
};

export const getCampaignQueryParam = (): string => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('campaignCode');
};
