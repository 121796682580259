import React from 'react';
import {Tile} from './Tile';
import {
  SvgSolidDeliveryTruckIcon,
  SvgSunIcon,
  SvgHouseIcon,
  Bold,
} from '@mfb/lego';
import {COLOR_CODE} from '../../../../shared/constants';
import classnames from 'classnames';
import {isNil} from 'lodash';
import {dateFormat} from '../../../../../lib/date/dateFormat';
import { globalSettings as settings } from 'config';

const DATE_FORMAT = 'EEE dd MMM, yyyy';
const ICON_DIMENSIONS = {
  width: '30px',
  height: '30px',
};

export interface HolidaySummaryProps {
  defaultAddress: string;
  firstHolidayDeliveryDate: Date;
  lastHolidayDeliveryDate: Date;
  lastDeliveryDateToOriginalAddress: Date;
  resumingDeliveryDateToDefaultAddress: Date;
  holidayDeliveryPeriodMessage: string;
  holidayAddress: string;
  onDisclaimerChange?(): void;
  checkedDisclaimer?: boolean;
}

export const HolidaySummary: React.FC<HolidaySummaryProps> = props => {
  const {
    defaultAddress,
    firstHolidayDeliveryDate,
    lastHolidayDeliveryDate,
    lastDeliveryDateToOriginalAddress,
    resumingDeliveryDateToDefaultAddress,
    holidayDeliveryPeriodMessage,
    holidayAddress,
    onDisclaimerChange,
    checkedDisclaimer,
  } = props;

  const formattedLastDeliveryDateToOriginalAddress = dateFormat(
    lastDeliveryDateToOriginalAddress,
    DATE_FORMAT
  );
  const formattedFirstHolidayDeliveryDate = dateFormat(
    firstHolidayDeliveryDate,
    DATE_FORMAT
  );
  const formattedLastHolidayDeliveryDate = dateFormat(
    lastHolidayDeliveryDate,
    DATE_FORMAT
  );
  const formattedResumingDeliveryDateToDefaultAddress = dateFormat(
    resumingDeliveryDateToDefaultAddress,
    DATE_FORMAT
  );

  return (
    <>
      <div
        className={classnames(
          'd-flex flex-column',
          'bg-secondary',
          'px-3 py-4 px-md-5',
          isNil(onDisclaimerChange) ? 'my-5' : 'mt-5'
        )}
      >
        <Tile
          isFirst
          iconProps={{
            icon: (
              <SvgHouseIcon fill={COLOR_CODE.DARK_BROWN} {...ICON_DIMENSIONS} />
            ),
            bgFill: COLOR_CODE.LIGHT_BROWN,
          }}
          title={formattedLastDeliveryDateToOriginalAddress}
          description={`The last weekend you'll be at home at ${defaultAddress}`}
        />
        <Tile
          iconProps={{
            icon: (
              <SvgSolidDeliveryTruckIcon
                fill={COLOR_CODE.WHITE}
                {...ICON_DIMENSIONS}
              />
            ),
            bgFill: COLOR_CODE.MFB_GREEN,
          }}
          title={formattedFirstHolidayDeliveryDate}
          description={`The first weekend you'll be away at ${holidayAddress}`}
        />
        <Tile
          iconProps={{
            icon: <SvgSunIcon fill={COLOR_CODE.WHITE} {...ICON_DIMENSIONS} />,
            bgFill: COLOR_CODE.MFB_GREEN,
          }}
          title={holidayDeliveryPeriodMessage}
          description={
            <>
              {`We’ll temporarily update your deliveries to be made to ${holidayAddress} on ${dateFormat(
                firstHolidayDeliveryDate,
                'EEEE'
              )} while you’re on holiday. `}
              <Bold>
                We will get in touch with you if this address is impacted by
                skips or changes in delivery days due to upcoming holidays.{' '}
              </Bold>
              <a
                href={`${settings.myfoodbagWebsiteURL}/holidays`}
                target="_blank"
                rel="noreferrer"
              >
                Click here{' '}
              </a>
              to review the areas that will be impacted by upcoming public
              holidays.
            </>
          }
        />
        <Tile
          iconProps={{
            icon: (
              <SvgSolidDeliveryTruckIcon
                fill={COLOR_CODE.WHITE}
                {...ICON_DIMENSIONS}
              />
            ),
            bgFill: COLOR_CODE.MFB_GREEN,
          }}
          title={formattedLastHolidayDeliveryDate}
          description={`The last weekend you'll be at away at ${holidayAddress}`}
        />
        <Tile
          isLast
          iconProps={{
            icon: (
              <SvgHouseIcon fill={COLOR_CODE.DARK_BROWN} {...ICON_DIMENSIONS} />
            ),
            bgFill: COLOR_CODE.LIGHT_BROWN,
          }}
          title={formattedResumingDeliveryDateToDefaultAddress}
          description={`The first weekend you'll be home at ${defaultAddress}`}
        />
      </div>
      {onDisclaimerChange && (
        <div
          className="text-white p-4"
          style={{backgroundColor: COLOR_CODE.DARK_BROWN}}
        >
          <label
            className="custom-control custom-checkbox"
            onClick={onDisclaimerChange}
          >
            <input
              type="checkbox"
              checked={checkedDisclaimer}
              className="custom-control-input"
            />
            <label className="custom-control-label">
              {`I understand and agree that these changes apply to my recurring subscription only,
                and are not applicable to any one-off Christmas Bag deliveries.
                The last delivery before my holiday will be made to ${defaultAddress} on ${formattedLastDeliveryDateToOriginalAddress}.
                This address will be reinstated again on ${formattedResumingDeliveryDateToDefaultAddress}.
              `}
            </label>
          </label>
        </div>
      )}
    </>
  );
};
