const HOLIDAY_ADDRESS_QUERY_PARAM = 'address';
const HOLIDAY_SKIP_QUERY_PARAM = 'skip';

export interface HolidaysQueryParams {
  changeAddress: boolean;
  skipDeliveries: boolean;
}

export const getQueryParams = (): HolidaysQueryParams => {
  const queryParams = new URLSearchParams(window.location.search);
  const action = queryParams.get('action');

  return {
    changeAddress: action
      ? action.toLowerCase() === HOLIDAY_ADDRESS_QUERY_PARAM
      : false,
    skipDeliveries: action
      ? action.toLowerCase() === HOLIDAY_SKIP_QUERY_PARAM
      : false,
  };
};
