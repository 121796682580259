import React from 'react';
import {Option} from 'react-select';
import {BagDisplay, Brand} from '../CoreClient';
import {formatMoney} from '../../../format/money';
import {isUndefined} from 'lodash';
import BrandLogo from '../svgs/BrandLogo';

export interface BagOption extends Option {
  brand: Brand;
}

export const renderBagOption = (option: Option) => (
  <span className="d-flex align-items-center">
    <BrandLogo size={25} brand={(option as BagOption).brand} />
    <span className="align-left ml-2">{option.label}</span>
  </span>
);

export const buildBagOptions = (bagDisplay: Array<BagDisplay>): Array<Option> =>
  bagDisplay.map(bag => ({
    label: `${bag.name} (${formatMoney(bag.price)})`,
    value: bag.id,
    feeds: bag.numberOfPeopleToFeed,
    brand: bag.brand,
  }));

export const doesBagExist = (
  bagDisplay: Array<BagDisplay>,
  selectedBagId: number
) => !isUndefined(bagDisplay.find(bd => bd.id === selectedBagId));

export const findSelectedBag = (
  bagDisplay: Array<BagDisplay>,
  selectedBagId: number
) => bagDisplay.find(bd => bd.id === selectedBagId);
