import { ThunkAction } from 'redux-thunk';
import { globalSettings as settings } from 'config';
import { Action } from 'redux';
import { AppState, CustomerMessageState } from '../../state';
import { SubscriptionClient } from 'app/shared/CoreClient';

export const RECEIVE_CUSTOMER_MESSAGE = 'RECEIVE_CUSTOMER_MESSAGE';

export interface ReceiveCustomerMessageAction extends Action {
  type: typeof RECEIVE_CUSTOMER_MESSAGE;
  payload: CustomerMessageState;
}

const receiveCustomerMessageActionCreator = (
  customerMessage: CustomerMessageState
): ReceiveCustomerMessageAction => ({
  type: RECEIVE_CUSTOMER_MESSAGE,
  payload: customerMessage,
});

export const fetchCustomerMessage = (
  triggerCode: string,
  subscriptionNumber: string,
  deliveryDateId: string
): ThunkAction<Promise<ReceiveCustomerMessageAction>, AppState, never> => {
  return async (dispatch) => {
    const client = new SubscriptionClient(settings.bffHost);
    const customerMessage = (await client.getCustomerMessages(
      triggerCode,
      subscriptionNumber,
      deliveryDateId
    )) as CustomerMessageState;

    customerMessage.subscriptionNumber = subscriptionNumber;
    customerMessage.deliveryDateId = Number(deliveryDateId);
    customerMessage.triggerCode = triggerCode;

    try {
      return dispatch(
        receiveCustomerMessageActionCreator(
          customerMessage as CustomerMessageState
        )
      );
    } catch (err) {
      throw err;
    }
  };
};

export type CustomerMessageStateAction = ReceiveCustomerMessageAction | Action;
