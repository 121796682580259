/* eslint-disable react/no-deprecated */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {StickyHeaderProps, stickyHeaderWormhole} from './MobileStickyHeader';

class MobileStickyHeaderProxy extends React.PureComponent<
  StickyHeaderProps,
  {}
> {
  componentDidMount() {
    stickyHeaderWormhole.set(this.props);
  }

  componentWillUpdate(nextProps: StickyHeaderProps) {
    stickyHeaderWormhole.set(nextProps);
  }

  componentWillUnmount() {
    stickyHeaderWormhole.set({});
  }

  render(): React.ReactNode {
    return null;
  }
}

export default MobileStickyHeaderProxy;
