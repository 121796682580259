import {Week} from './NSwagClassExtensions';

const buildKey = (week: Week, subscriptionId: number | null): string => {
  if (subscriptionId != null) {
    return `${week}-sub-${subscriptionId}`;
  }

  return `${week}`;
};

export default buildKey;
