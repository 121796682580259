import React from 'react';

interface Props {
  text: string;
  children?: React.ReactNode;
  displayStar?: boolean;
  textWidth?: number;
}

interface State {
  display: boolean;
}

export default class Tooltip extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  toggle = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    this.setState({
      display: !this.state.display,
    });
  };

  off = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    this.setState({
      display: false,
    });
  };

  on = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    this.setState({
      display: true,
    });
  };

  render() {
    return (
      <span
        role="button"
        onClick={this.toggle}
        onMouseEnter={this.on}
        onMouseLeave={this.off}
      >
        {this.props.children}
        {this.props.displayStar && (
          <span>
            <strong>*</strong>
          </span>
        )}
        {this.state.display && (
          <div className="mfb-Tooltip--outer mt-1">
            <div
              className="mfb-Tooltip--inner rounded"
              style={this.props.textWidth && {width: this.props.textWidth}}
            >
              {this.props.text}
            </div>
          </div>
        )}
      </span>
    );
  }
}
