import createUserManager from 'app/shared/UserManager';
import { accountApiClient } from 'app/shared/api';
import { ProductCollectionCode } from 'app/shared/constants';
import format from 'format/time';
import mixpanel from 'mixpanel-browser';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DayOfWeek2 } from '../../app/shared/CoreClient';
import { AppState, CustomerProfileState } from '../state';
import { fetchTracking } from './sharedActions/trackingAction';
import {
  UiComponentName,
  performUpdateUiComponentAction,
} from './sharedActions/updateUiComponent';
import { ActionCreator, Store } from 'react-redux';

export const RECEIVE_CUSTOMER_PROFILE = 'RECIEVE_CUSTOMER_PROFILE';

export interface ReceiveCustomerProfileAction extends Action {
  type: typeof RECEIVE_CUSTOMER_PROFILE;
  payload: CustomerProfileState;
}

const receiveCustomerProfileActionCreator = (
  customerProfile: CustomerProfileState
): ReceiveCustomerProfileAction => ({
  type: RECEIVE_CUSTOMER_PROFILE,
  payload: customerProfile,
});

export const fetchCustomerProfileThunk = (): ThunkAction<
  Promise<ReceiveCustomerProfileAction>,
  AppState,
  never
> => {
  return async (dispatch) => {
    const customerProfile = await accountApiClient.customersMe();

    const userMgr = await createUserManager();
    const customerNumber = await userMgr.getCustomerNumber();

    try {
      const displaySubscription = customerProfile.subscriptions[0] || undefined;
      let productName = undefined;
      let deliveryTime = undefined;
      let deliveryDate = undefined;
      let iconUrl = undefined;
      let iconSvgUrl = undefined;
      let code = undefined;

      if (displaySubscription) {
        productName = displaySubscription.productName;
        deliveryTime = `${format(
          displaySubscription.deliverySlot.actualDeliveryStartTime
        )} - ${format(displaySubscription.deliverySlot.actualDeliveryEndTime)}`;
        deliveryDate = DayOfWeek2[displaySubscription.deliverySlot.deliveryDay];
        iconUrl = displaySubscription.iconUrl;
        iconSvgUrl = displaySubscription.iconSvgUrl;
        code = displaySubscription.productCollectionCode;
      }

      const availableCredit =
        customerProfile.customerDetails.customerCredit.reduce(
          (prev, curr) => prev + curr.amount,
          0
        );

      const customerProfileState = {
        customerNumber: customerNumber,
        address: customerProfile.customerDetails.address,
        firstName: customerProfile.customerDetails.firstName,
        lastName: customerProfile.customerDetails.lastName,
        productName,
        deliveryTime,
        deliveryDate,
        iconUrl,
        availableCredit: `$${availableCredit.toFixed(2)}`,
        iconSvgUrl,
        code,
        subscriptions: customerProfile.subscriptions,
        optOutRecipeCards: customerProfile.customerDetails.optOutRecipeCards
      } as CustomerProfileState;

      hideEditSubscription(customerProfileState, dispatch);
      mixpanel.people.set({
        $name: `${customerProfile.customerDetails.customerNumber}`,
      });

      return dispatch(
        receiveCustomerProfileActionCreator(customerProfileState)
      );
    } catch (err) {
      console.error(err);
    }
  };
};

//TODO: move this to get subscriptions action once product collection is available in the response.
const hideEditSubscription = (
  customerProfileState: CustomerProfileState,
  dispatch
) => {
  // Hide edit subscription CTA for specific product collections
  const excludeEditSubscriptionCtaForProductCollection: ProductCollectionCode[] =
    [ProductCollectionCode.THE_BUTCHER];

  for (const subscription of customerProfileState.subscriptions) {
    if (
      excludeEditSubscriptionCtaForProductCollection.includes(
        subscription.productCollectionCode as ProductCollectionCode
      )
    ) {
      dispatch(
        performUpdateUiComponentAction(
          {
            name: UiComponentName.EDIT_SUBSCRIPTION,
            subscriptionNumber: subscription.subscriptionNumber,
          },
          {
            isHidden: true,
          }
        )
      );
    }
  }
};

export type CustomerProfileStateAction = ReceiveCustomerProfileAction | Action;
