import React from 'react';
import classNames from 'classnames';

interface Props {
  placeholder?: string;
  error?: string;
  value?: string;
  name: string;
  label: string;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  title?: string;
  onChange(event: any): void;
  onBlur?(event: any): void;
  description?: string;
}

const FormTextArea: React.FunctionComponent<Props> = props => {
  const {name, label, error, description, ...otherProps} = props;
  const classes = classNames('form-group', {'has-danger': error != null});

  return (
    <div className={classes}>
      <label htmlFor={name}>{label}</label>
      <textarea
        id={name}
        name={name}
        className="form-control"
        {...otherProps}
      />
      {description && <small className="text-muted">{description}</small>}
      {error != null && <span className="form-control-feedback">{error}</span>}
    </div>
  );
};

export default FormTextArea;
