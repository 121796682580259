import logger from 'app/shared/Logger';
import {
  UiComponentKey,
  UiComponentKeyStabilizer,
} from 'redux/actions/sharedActions/updateUiComponent';
import { UiComponentAttributes, UiComponentRecord } from 'redux/state';

/**
 * @selector Retrieves the UI component's attributes from the given record based on the provided key.
 * @key The key is stringified to match the dictionary structure. If the type is an array - attributes will merge & last key's value will take override priority 
 * @Returns the corresponding UI component attributes if found, or undefined otherwise.
 */
export const selectUiComponentAttributes = (
  key: UiComponentKey | UiComponentKey[],
  record: UiComponentRecord
): UiComponentAttributes => {
  try {
    const allAttributes:UiComponentAttributes[] = [];

    const keysToSearch = Array.isArray(key) ? key : [key];

    for(const key of keysToSearch){
       const attributes = record[JSON.stringify(UiComponentKeyStabilizer(key))];
       allAttributes.push(attributes);
    }

    return Object.assign({}, ...allAttributes);
  } catch (e) {
    if (e instanceof Error) {
      logger.error(e, {});
    }
  }
};
