import {
  BagSelector,
  BagSelectorProductGroup,
  BagSelectorProductResult,
  BagSelectorStateService,
  ProductPriceDisplayContext,
  RecipeCarouselContext,
  RecipeDetailModelLegacy,
  SkuSelectorContext,
  HideDisabledSku
} from '@mfb/cookbook';
import React from 'react';
import { useRef } from 'react';
import {
  AccountSettings,
  AvailableBag,
  CodeValidationResponse,
  CostPreviewResponse,
  ProductAndRecipeDetailsResponse,
  ProductGroupResponse,
  ProductSummaryResponse,
  SubscriptionClient,
} from '../../CoreClient';
import { getPromoCodeQueryParam } from './common/QueryParam';
import { setPriceDisplay, validatePromoCode } from './common/ValidatePromoCode';
import {
  CachedCostPreview,
  CachedPromoValidation,
  CachedRecipeCarousel,
  cacheRecipeCarousels,
  getCachedCostPreview,
  getCachedPromoValidation,
  getCachedRecipeCarousel,
} from './common/Cache';
import { Colours } from '@mfb/lego';
import { globalSettings as settings } from 'config';
export interface IBagSelectorContainer {
  onLoad?: () => void;
  externalProductState: { name: string; sku: string; skuOverride?: string };
  here?: (name: string) => void;
  upcomingWeek: Date;
  productGroupResponse: ProductGroupResponse[];
  accountSettings: AccountSettings;
  getCostPreview: (sku: string) => Promise<CostPreviewResponse>;
  getValidatePromoCode: (
    sku: string,
    promo: string
  ) => Promise<CodeValidationResponse>;
  getProductDetailsForSkuAndWeek: (
    sku: string,
    promoCode: string
  ) => Promise<ProductAndRecipeDetailsResponse>;
  getRecipeDetails: (
    recipeNumber: string,
    recipeVersion: string,
    partition: string,
    recipeId: number
  ) => Promise<RecipeDetailModelLegacy>;
  onContinue: (
    selectedOptions: IBagSelectorContainerSelectedOptions
  ) => Promise<void>;
  showLargeLoadSpinner?: boolean;
  hideDisabled?: HideDisabledSku;
  onSkuChange?: (sku: string) => void;
}

export interface IBagSelectorContainerSelectedOptions {
  sku?: string;
  night?: number;
  serves?: number;
  price?: { perWeek?: number };
  fullAddress?: string;
  deliveryInstructions?: string;
  promoCode?: string;
  blurb?: string;
  bag: { name: string; sku: string };
  availableBags?: AvailableBag[];
  productPriceDisplayContext?: ProductPriceDisplayContext;
  deliveryDate?: Date;
  upcomingWeek?: Date;
  referAFriendCode?: string;
}

export const BagSelectorContainer = ({
  onLoad,
  externalProductState,
  productGroupResponse,
  accountSettings,
  getCostPreview,
  getValidatePromoCode,
  upcomingWeek,
  getProductDetailsForSkuAndWeek,
  getRecipeDetails,
  showLargeLoadSpinner,
  hideDisabled,
  onContinue,
  onSkuChange,
}: IBagSelectorContainer) => {
  const [bagSelectorMapped, setBagSelectorMapped] = React.useState(true);
  const [productsGroupResponse, setProductsGroupResponse] = React.useState<
    ProductGroupResponse[]
  >([]);

  const skuOverride = React.useRef<string>(externalProductState.skuOverride);

  const cachedCosts = React.useRef<Array<CachedCostPreview>>([]);
  const cachedPromoValidations = React.useRef<Array<CachedPromoValidation>>([]);
  const cachedRecipeCarousels = React.useRef<Array<CachedRecipeCarousel>>([]);

  const [bagSelectorProductGroups, setBagSelectorProductGroups] =
    React.useState<BagSelectorProductGroup[]>([]);
  const [allProductOptions, setProductOptions] = React.useState<
    Array<ProductSummaryResponse>
  >([]);

  const [priceContext, setPriceContext] =
    React.useState<ProductPriceDisplayContext>({ primaryButtonDisabled: true });

  const mapProductOptions = React.useCallback(
    (
      _productGroupResponse: Array<ProductGroupResponse>
    ): ProductSummaryResponse[] => {
      return _productGroupResponse.flatMap((pg) => pg.productOptions || []);
    },
    []
  );

  const selectedOptions = useRef<IBagSelectorContainerSelectedOptions>();

  React.useEffect(() => {
    if (selectedOptions.current && selectedOptions.current.sku){
      onSkuChange && onSkuChange(selectedOptions.current.sku);
    }
  }, [selectedOptions.current]);

  const [carouselContext, setCarouselContext] =
    React.useState<RecipeCarouselContext>({
      recipeCollections: [],
      showDateSelector: false,
    });

  const mapForBagSelector = React.useCallback(
    (
      productGroupResponse: Array<ProductGroupResponse>
    ): BagSelectorProductGroup[] => {
      return productGroupResponse.reduce((final, curr) => {
        return [
          ...final,
          {
            productGroupName: curr.name,
            products: [
              ...(final[curr.name] || []),
              ...curr.productOptions.map((x) => ({
                sku: x.sku,
                night: x.nights,
                serves: x.people,
                recipes: [],
              })),
            ],
          },
        ];
      }, []);
    },
    []
  );

  React.useEffect(() => {
    getCachedRecipeCarousel(
      cachedRecipeCarousels.current,
      setCarouselContext,
      externalProductState.sku
    );
    selectedOptions.current = {
      ...selectedOptions.current,
      bag: externalProductState,
    };
  }, [externalProductState]);

  React.useEffect(() => {
    (async () => {
      try {
        setBagSelectorMapped(true);

        const { customer, availableBags } = accountSettings;

        selectedOptions.current = {
          ...selectedOptions.current,
          bag: externalProductState,
          referAFriendCode: customer.referAFriendCode,
          availableBags: availableBags,
          upcomingWeek: upcomingWeek,
          fullAddress: customer.address.value,
          deliveryInstructions: customer.deliveryInstructions.value,
          night: 1,
        };

        const filteredProductGroupResponse = productGroupResponse;

        await cacheRecipeCarousels(
          filteredProductGroupResponse,
          getProductDetailsForSkuAndWeek,
          cachedRecipeCarousels.current,
          setCarouselContext,
          externalProductState
        );

        const bagProductGroups = mapForBagSelector(
          filteredProductGroupResponse
        );

        const productOptions = mapProductOptions(filteredProductGroupResponse);
        setProductsGroupResponse(filteredProductGroupResponse);
        setBagSelectorProductGroups(bagProductGroups);
        setProductOptions(productOptions);
      } catch (error) {
        console.error('Error', error);
      } finally {
        setBagSelectorMapped(false);
        onLoad && onLoad();
      }
    })();
  }, []);

  const skuSelectorContext: SkuSelectorContext = {
    skuSelectorService: new BagSelectorStateService(bagSelectorProductGroups, []),
    nightsLabel:
      externalProductState.name === 'MADE'
        ? 'Number of Meals?'
        : 'How Many Nights?',
    servingsLabel: 'Number of People?',
    feedLabel: `{feed} {serve} {adult}`,
    productOverride: externalProductState.name,
    hideProductSelector: true,
    isReadyMadeMeal: externalProductState.name === 'MADE' ? true : false,
    skuOverride: skuOverride.current,
    hideDisabled: {nights: hideDisabled.nights, servings: hideDisabled.servings},
  };

  const getBlurb = () => {
    return productsGroupResponse.filter((product) => {
      if (externalProductState.name == product.name) {
        selectedOptions.current = {
          ...selectedOptions.current,
          blurb: product.blurb,
        };
        return true;
      }
      return false;
    })[0].blurb;
  };

  const onResult = React.useCallback(
    async (newValue: BagSelectorProductResult | undefined) => {
      if (skuOverride.current) {
        skuOverride.current = undefined;
        return;
      }

      const subscriptionClient = new SubscriptionClient(settings.bffHost);
      const promoCode = getPromoCodeQueryParam() || '';
      setPriceContext((prevState) => ({
        ...prevState,
        primaryButtonDisabled: true,
      }));

      selectedOptions.current = {
        ...selectedOptions.current,
        ...newValue,
        promoCode: promoCode,
      };

      setPriceContext((prevState) => ({ ...prevState, isLoading: true }));

      const SelectedProduct = allProductOptions.find(
        (product) => product.sku === newValue.sku
      );
      if (!SelectedProduct) {
        return;
      }
      const sku = newValue.sku;

      const costPreviewResponse = await getCachedCostPreview(
        sku,
        newValue.night,
        newValue.serves,
        promoCode,
        setPriceContext,
        cachedCosts.current,
        getCostPreview
      );

      if (promoCode) {
        const promoCodeResponse = await getCachedPromoValidation(
          subscriptionClient,
          newValue.sku,
          promoCode,
          cachedPromoValidations.current,
          getValidatePromoCode
        );
        validatePromoCode(
          promoCodeResponse,
          costPreviewResponse,
          selectedOptions.current.sku,
          setPriceContext,
          newValue
        );
      } else {
        setPriceDisplay(setPriceContext, costPreviewResponse, newValue);
      }
      setPriceContext((prevState) => ({
        ...prevState,
        primaryButtonDisabled: false,
      }));
    },

    [productsGroupResponse, bagSelectorProductGroups, allProductOptions]
  );

  return (
    <div>
      {bagSelectorMapped ? (
        <div className="mt-5 d-flex flex-column flex-fill">
          <div
            className="spinner-border align-self-center"
            style={{
              color: Colours.PRIMARY_GREEN_BRAND_1,
              width: `${showLargeLoadSpinner ? '72px' : '40px'}`,
              height: `${showLargeLoadSpinner ? '72px' : '40px'}`,
            }}
          />
        </div>
      ) 
      : 
      (
        <div className="container">
          <div
            style={{ width: '100%', maxWidth: '63em' }}
            className={'mb-lg-0 d-block mb-5'}
          >
            <BagSelector
              title={externalProductState.name}
              overrideMargin={true}
              blurb={getBlurb()}
              className="ml-0 py-2"
              showProductPriceDisplay={true}
              skuSelectorContext={skuSelectorContext}
              productPriceDisplayContext={priceContext}
              recipeCarouselContext={carouselContext}
              recipeCarouselOnRecipeFetch={getRecipeDetails}
              skuSelectorOnResult={onResult}
              productPricePrimaryOnClickAsync={() =>
                onContinue(selectedOptions.current)
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};
