import { Reducer } from 'redux';
import { defaultTracking, TrackingState } from 'redux/state';
import { RECEIVE_TRACKING, ReceiveTrackingAction, TrackingStateAction } from '../actions/sharedActions/trackingAction';

const reducer: Reducer<TrackingState> = (
  state = defaultTracking,
  action: TrackingStateAction,
): TrackingState => {
  switch (action.type) {
    case RECEIVE_TRACKING: {
      return (action as ReceiveTrackingAction).payload;
    }
    default:
      return state;
  }
};

export default reducer;
