import React from 'react';
import {
  PageProperties,
  mapStateToProps,
  mapDispatchToProps,
  DispatcherProps,
} from '../../containers/accountSettingsContainer';
import {
  AccountSettings,
  CreateSubscriptionRequest,
  Frequency,
  BagDisplay,
  EnrollableCampaign,
} from '../../../shared/CoreClient';
import Select from 'react-select';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {navman} from '../../../../navigator';
import { globalSettings as settings } from 'config';
import classNames from 'classnames';
import AddressFinder from '../AddressFinder';
import {AddressFinderState} from '../AddressFinder';
import FrequencySelect from '../../../shared/components/FrequencySelect';
import {default as FormField} from '../FormField';
import Dialog from '../../../shared/components/Dialog';
import {
  updateFrequency,
  frequencyReducer,
} from '../../../../redux/reducers/frequencyReducer';
import {
  UPDATE_ALL_FREQUENCIES,
  UPDATE_SUBSCRIPTION_FREQUENCY,
} from '../../../../redux/actions/accountSettingsActions/frequencyAction';
import SubmitOrBack from '../../../shared/components/SubmitOrBack';
import BagChooser from '../../../shared/components/BagRecipes/BagChooser';
import MobileStickyHeaderProxy from '../../../shared/components/MobileStickyHeader/MobileStickyHeaderProxy';
import submitForm from '../../../shared/submitForm';
import {AppState} from '../../../../redux/state';
import {
  productsFilteredByFeeds,
  getFeedsSelection,
  Feeds,
} from './ProductFilter';
import {paymentDetailsPath} from '../Nav/paths';
import DeliveryDateSelect from '../DeliveryDateSelect';
import {some} from 'lodash';
import readDiscountCode from '../../../shared/components/ReadDiscountCode';
import {showSubscriptionAddedToast} from '../../../toast/ToastMessages';
import moment from 'moment';
import TrackingProps from '../../../shared/TrackingProps';
import {getQueryStringParams} from '../../../shared/getQueryString';
import {ResponsiveImage} from '@mfb/lego';
import {
  CampaignSubscriptionEvent,
  trackAddEditSubForCampaignEvent,
} from '../../../shared/campaignTracker';
import {getSubmitStage} from '../../../shared/components/SubmitStage';

const TEXT_AREA_ROWS = 3;

interface RouteParams {
  primaryBagId?: string;
}

export interface MyAddSubscriptionProps
  extends RouteComponentProps<
      RouteParams,
      any,
      { isRestartFlow?: boolean; promoCode?: string }
    >,
    PageProperties {
  pageState: AccountSettings;
  isRestartFlow?: boolean;
  promoCode?: string;
}

interface State extends CreateSubscriptionRequest {
  isFreqDialogOpen: boolean;
  primaryBagName?: string;
  selectedProductFilterValue: Feeds;
  firstDeliveryWeekStarting?: Date;
  firstDeliveryDate?: Date;
  campaignCode?: string;
  enrollableCampaign?: EnrollableCampaign;
  availableBags: Array<BagDisplay>;
}

class MySubscriptionsAdd extends React.PureComponent<
  MyAddSubscriptionProps,
  State
> {
  form?: HTMLFormElement;

  constructor(props: MyAddSubscriptionProps) {
    super(props);

    const lastSubscription = props.pageState.lastCancelledSubscription;
    if (
      props.location.state &&
      props.location.state.isRestartFlow &&
      lastSubscription
    ) {
      const {promoCode} = props.location.state;

      const {
        primaryBagId,
        primaryFrequency,
        productOptionSku,
      } = lastSubscription;

      const bag = this.findBag(props, primaryBagId);
      this.state = {
        primaryBagId,
        primaryFrequency,
        productOptionSku,
        firstDeliveryDateId: null,
        firstDeliveryWeekStarting: null,
        firstDeliveryDate: null,
        isFreqDialogOpen: false,
        campaignCode: '',
        promoCode,
        selectedProductFilterValue: getFeedsSelection(
          bag ? bag.numberOfPeopleToFeed : null
        ),
        availableBags: props.pageState.bagSelection,
      };
    } else {
      const {primaryBagId} = props.match.params;
      const bag = this.findBag(props, primaryBagId);
      const promoCode = readDiscountCode();

      this.state = {
        primaryBagId: bag && bag.id && Number(bag.id),
        primaryBagName: bag && bag.name,
        primaryFrequency: Frequency.Weekly,
        productOptionSku: null,
        firstDeliveryDateId: null,
        firstDeliveryWeekStarting: null,
        firstDeliveryDate: null,
        isFreqDialogOpen: false,
        campaignCode: '',
        promoCode,
        selectedProductFilterValue: getFeedsSelection(
          bag ? bag.numberOfPeopleToFeed : null
        ),
        availableBags: props.pageState.bagSelection,
      };
    }
  }

  findBag = (
    props: MyAddSubscriptionProps,
    primaryBagId: number | string
  ): BagDisplay => {
    const primaryBagIsSpecified = primaryBagId;
    const primaryBagIsSku = isNaN(Number(primaryBagId));

    let bag: BagDisplay;
    if (primaryBagIsSpecified) {
      if (primaryBagIsSku) {
        bag = props.pageState.bagSelection.find(bs => bs.sku === primaryBagId);
      } else {
        bag = props.pageState.bagSelection.find(
          bs => bs.id === Number(primaryBagId)
        );
      }
    }

    return bag;
  };

  handleChangeBags = (value: number): void => {
    const bags = this.state.availableBags;

    const selectedBag = bags.find(b => b.id === value);

    this.setState({
      primaryBagId: selectedBag.id,
      primaryBagName: selectedBag.name,
      productOptionSku: selectedBag.sku,
    });
  };

  isRestartPage = (props: MyAddSubscriptionProps): boolean =>
    Boolean(props.history.location.state);

  handleChange = (
    value: string | number | boolean,
    propertyName: string
  ): void => {
    this.setState({
      ...this.state,
      [propertyName]: value,
    });
  };

  handleChangeDate = (
    deliveryDateId: number,
    weekStarting: Date,
    deliveryDate: Date
  ): void => {
    this.setState({
      ...this.state,
      firstDeliveryDateId: deliveryDateId,
      firstDeliveryWeekStarting: weekStarting,
      firstDeliveryDate: deliveryDate,
    });
  };

  handleSubmitForm = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    const {
      primaryBagName,
      enrollableCampaign,
      campaignCode,
      ...payload
    } = this.state;

    try {
      await this.props.createSubscription(payload);

      showSubscriptionAddedToast(primaryBagName);

      if (campaignCode || enrollableCampaign) {
        navman.campaignModal(
          campaignCode || enrollableCampaign.campaignCodes[0]
        );
      } else {
        navman.yourAccount();
      }
    } catch (err) {
      /* Validation errors handled in redux */
    }
  };

  onAddressChange = (address: AddressFinderState): void => {
    this.setState({
      ...this.state,
      pafId: address.pafId,
    });

    this.props.validateSuggestedAddress({
      pafId: address.pafId,
    });
  };

  handleSubFrequencyChange = (selected: Select.Option): void => {
    const nextState = frequencyReducer(this.state, selected);

    this.setState(nextState);
  };

  handleFrequencyChange = (action: string): void => {
    const nextState = updateFrequency(this.state, {
      type: action,
      frequency: Frequency.Weekly,
    });

    this.setState(nextState);
  };

  handleDialogClose = (): void => {
    this.setState({
      ...this.state,
      isFreqDialogOpen: !this.state.isFreqDialogOpen,
    });
  };

  setFormRef = (ref: HTMLFormElement): void => {
    this.form = ref;
  };

  handleProductFilterChange = (selected: Select.Option): void => {
    const bags = this.state.availableBags;
    const value = Number(selected.value);

    const defaultSelection = productsFilteredByFeeds(bags, value);

    if (defaultSelection.length > 0) {
      this.setState({
        primaryBagId: defaultSelection[0].id,
        selectedProductFilterValue: getFeedsSelection(value),
      });
    }
  };

  async componentDidMount(): Promise<void> {
    const campaignCode = getQueryStringParams().campaignCode;

    if (campaignCode) {
      this.setState({campaignCode});

      trackAddEditSubForCampaignEvent(
        CampaignSubscriptionEvent.ADD_SUBSCRIPTION
      );
    }

    let primaryBagId = this.state.primaryBagId;
    let bagSelection = this.state.availableBags;
    const options = getQueryStringParams().options;
    if (options) {
      const optionIds = options.split(',').map(o => parseInt(o, 10));
      bagSelection = bagSelection.filter(bs =>
        some(optionIds, o => o === bs.id)
      );

      const isSelectedValueAvailable = bagSelection.some(
        bs => bs.numberOfPeopleToFeed === this.state.selectedProductFilterValue
      );

      if (!isSelectedValueAvailable) {
        const defaultFeeds = getFeedsSelection();
        const filteredProducts = productsFilteredByFeeds(
          bagSelection,
          defaultFeeds
        );

        primaryBagId = filteredProducts[0].id;
      } else {
        const filteredProducts = productsFilteredByFeeds(
          bagSelection,
          this.state.selectedProductFilterValue
        );
        primaryBagId = filteredProducts[0].id;
      }
    }

    this.setState({
      primaryBagId,
      availableBags: bagSelection,
    });
  }

  // Use !! to coerce result to a boolean
  formIsValid = (): boolean =>
    !!(this.state.productOptionSku && this.state.firstDeliveryDate);

  // TODO: refactor this container
  render(): React.ReactNode {
    const pageState = this.props.pageState as AccountSettings;

    if (pageState.hasWrittenOffDeliveries) {
      navman.accountSettings();
    }

    const updateFrequencyDialogActions = [
      {
        text: 'Yes, update Extras',
        className: 'btn-primary',
        handler: () => this.handleFrequencyChange(UPDATE_ALL_FREQUENCIES),
      },
      {
        text: 'No thanks, continue',
        handler: () =>
          this.handleFrequencyChange(UPDATE_SUBSCRIPTION_FREQUENCY),
      },
    ];

    const subscription = {
      preferences: {valid: true, message: ''},
      primaryFrequency: {valid: true, message: ''},
      firstDeliveryDateId: {valid: true, message: ''},
      address: {
        valid: true,
        message: '',
        value: pageState.customer.address.value,
      },
      promoCode: {valid: true, message: ''},
      deliveryInstructions: {
        valid: true,
        message: '',
        value: pageState.customer.deliveryInstructions.value,
      },
      hasCreditCards: {
        valid: true,
        message: '',
        value: pageState.cards.length > 0,
      },
      ...pageState.createSubscription,
    };

    const submitTracking: TrackingProps = {
      category: 'subscription',
      action: 'click',
      label: this.isRestartPage(this.props)
        ? 'submit-restart-subscription'
        : 'submit-add-subscription',
    };

    const backTracking: TrackingProps = {
      category: 'subscription',
      action: 'click',
      label: this.isRestartPage(this.props)
        ? 'back-restart-subscription'
        : 'back-add-subscription',
    };

    const enrollableCampaign =
      pageState &&
      pageState.enrollableCampaigns &&
      pageState.enrollableCampaigns.find(
        ec =>
          ec.optionIdWhitelist.includes(this.state.primaryBagId) &&
          (ec.isPromoted ||
            ec.campaignCodes
              .map(cc => cc.toLowerCase())
              .includes(this.state.campaignCode.toLowerCase()))
      );

    const {bannerUrlSmall, bannerUrlMedium, bannerUrlLarge, campaignGroupName} =
      enrollableCampaign || {};

    this.setState({enrollableCampaign});

    return (
      <div className="row fs-u-pb-80" data-test="order-add-form">
        <MobileStickyHeaderProxy
          hiddenUp="md"
          actionLabel={this.isRestartPage(this.props) ? 'Restart' : 'Add'}
          actionSubmitting={this.props.ui.isSubmitting}
          actionTracking={submitTracking}
          backTracking={backTracking}
          onBack={this.props.features.dashboardV2 ? undefined : navman.myOrders}
          onAction={() => submitForm(this.form)}
        />
        {this.state.isFreqDialogOpen && (
          <Dialog
            title="Update extras delivery period"
            actions={updateFrequencyDialogActions}
            onCancel={this.handleDialogClose}
          >
            <p>Would you also like your extras to be delivered weekly?</p>
          </Dialog>
        )}
        <div className="col-12">
          <form onSubmit={this.handleSubmitForm} ref={this.setFormRef}>
            <h3 className="h3 mt-2">
              {this.isRestartPage(this.props) ? 'Restart' : 'Add'} Subscription
            </h3>
            <BagChooser
              selectedProductFilterValue={this.state.selectedProductFilterValue}
              onProductFilterChange={(option: Select.Option) =>
                this.handleProductFilterChange(option)
              }
              bagSelection={this.state.availableBags || []}
              optionId={this.state.primaryBagId}
              onSelectBag={this.handleChangeBags}
              promotedCampaignName={campaignGroupName}
            />
            <FormField
              label="Promo Code"
              onChange={e =>
                this.handleChange(e.currentTarget.value, e.currentTarget.name)
              }
              placeholder={'Enter a promo code...'}
              value={this.state.promoCode}
              name="promoCode"
              isValid={subscription.promoCode.valid}
              errorMessage={subscription.promoCode.message}
            />

            <FrequencySelect
              value={this.state.primaryFrequency}
              onChange={this.handleSubFrequencyChange}
              isValid={subscription.primaryFrequency.valid}
              validationMessage={subscription.primaryFrequency.message}
              defaultIfNotExist={false}
            />

            {settings.changeAddressOnSubscriptionEnabled &&
              pageState.subscriptions.length === 0 && (
                <div>
                  <div
                    className={classNames('form-group', {
                      'has-danger': !subscription.address.valid,
                    })}
                  >
                    <label>Address</label>
                    <AddressFinder
                      onAddressChange={this.onAddressChange}
                      fullAddress={subscription.address.value}
                    />
                    {!subscription.address.valid && (
                      <span className="form-control-feedback">
                        {subscription.address.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="deliveryInstructions">
                      Delivery Instructions
                    </label>
                    <textarea
                      onChange={e =>
                        this.handleChange(
                          e.currentTarget.value,
                          e.currentTarget.name
                        )
                      }
                      name="deliveryInstructions"
                      className="form-control"
                      id="deliveryInstructions"
                      rows={TEXT_AREA_ROWS}
                      maxLength={250}
                      placeholder={
                        subscription.deliveryInstructions.value ||
                        'Enter some delivery instructions here...'
                      }
                    >
                      {this.state.deliveryInstructions}
                    </textarea>
                  </div>
                </div>
              )}

            <DeliveryDateSelect
              onChange={(id, weekStarting, deliveryDate) =>
                this.handleChangeDate(id, weekStarting, deliveryDate)
              }
              bagId={this.state.primaryBagId}
              selectedDeliveryDateId={this.state.firstDeliveryDateId}
              selectedDeliveryWeekStarting={
                this.state.firstDeliveryWeekStarting
              }
            />

            {!subscription.hasCreditCards.valid && (
              <p className="text-danger">
                {`Sorry, you need to have at least one credit card
             attached to your account before creating a subscription. `}
                <a href={paymentDetailsPath}>Click here to add one!</a>
              </p>
            )}

            <SubmitOrBack
              submitLabel={
                this.isRestartPage(this.props)
                  ? 'Restart Subscription'
                  : 'Add Subscription'
              }
              submitStage={getSubmitStage(
                this.formIsValid(),
                this.props.ui.isSubmitting
              )}
              submitTracking={submitTracking}
              backTracking={backTracking}
              onBack={navman.myOrders}
            />
            {enrollableCampaign && (
              <ResponsiveImage
                data-test="campaign-banner"
                className="pt-4"
                src={bannerUrlSmall}
                mdSrc={bannerUrlMedium}
                lgSrc={bannerUrlLarge}
              />
            )}
          </form>
        </div>
      </div>
    );
  }
}

export const UnconnectedAddSubscriptionComponent = MySubscriptionsAdd;
// prettier-ignore
export const SubscriptionsAdd = connect<AppState, DispatcherProps, RouteComponentProps<RouteParams>>(
  mapStateToProps,
  mapDispatchToProps
)
// theres something super wrong with the interfacing here
// i don't understand why this even worked before
// @TODO fix the interfaces here
(MySubscriptionsAdd as any);
