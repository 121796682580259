import configJson from './config.json';

type ConfigJsonType = typeof configJson;

interface AccountConfiguration extends ConfigJsonType {
  ADB2C_USERMANAGER: 'msal' | 'oidc';
  BRAND: 'My Food Bag' | 'Bargain Box';
}

const overrideConfigWithDotEnv = (): AccountConfiguration => {
  let config = { ...configJson } as AccountConfiguration;

  for (const [key, value] of Object.entries(configJson)) {
    config = {
      ...config,
      [key]: process.env[`REACT_APP_${key}`] || value,
    };
  }

  return config;
};

export const AccountConfig = overrideConfigWithDotEnv();
