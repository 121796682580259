import React from 'react';
import Select from 'react-select';
import {BagDisplay} from '../../../shared/CoreClient';

export enum Feeds {
  ONE = 1,
  TWO,
  THREEORMORE,
}

interface ProductFilterOptions {
  label: string;
  value: Feeds;
}

export const productFilterOptions: Array<ProductFilterOptions> = [
  {
    label: '1',
    value: Feeds.ONE,
  },
  {
    label: '2',
    value: Feeds.TWO,
  },
  {
    label: '3+',
    value: Feeds.THREEORMORE,
  },
];

/**
 * Given a number, return an enum feed
 *
 *  1 => Enum.ONE,
 *  2 => Enum.TWO,
 *  3 => Enum.THREEORMORE,
 *  4 => Enum.THREEORMORE,
 *  -1 => throws Exception
 */
export const parseNumberAsFeed = (n: number): Feeds => {
  if (n < 1) {
    throw new Error(
      `${parseNumberAsFeed.name} accepts number range between 1 and +∞`
    );
  }

  if (n in Feeds) {
    return Feeds[Feeds[n]];
  } else {
    return Feeds.THREEORMORE;
  }
};

export const getFeedsSelection = (filterValue?: Feeds) => {
  if (filterValue) {
    const filterOption = productFilterOptions.find(
      pfo => pfo.value === filterValue
    );

    if (filterOption) {
      return filterOption.value;
    }
  }

  return Feeds.THREEORMORE;
};

const maxFeedValue = Math.max(...productFilterOptions.map(pfa => pfa.value));

export const productsFilteredByFeeds = (
  bags: Array<BagDisplay>,
  selectedValue: number
) =>
  bags.filter(bs =>
    selectedValue >= maxFeedValue
      ? bs.numberOfPeopleToFeed >= maxFeedValue
      : bs.numberOfPeopleToFeed === selectedValue
  );

export const availableOptions = (
  bags: Array<BagDisplay>
): Array<ProductFilterOptions> =>
  productFilterOptions.filter(
    pfo => productsFilteredByFeeds(bags, pfo.value).length > 0
  );

interface Props {
  selectedValue: number;
  bags: Array<BagDisplay>;
  onChange(option: Select.Option): void;
}

const ProductFilter: React.FunctionComponent<Props> = (props: Props) => (
  <Select
    searchable={false}
    clearable={false}
    options={availableOptions(props.bags)}
    value={props.selectedValue}
    name="productFilter"
    onChange={option => props.onChange(option as Select.Option)}
  />
);

export default ProductFilter;
