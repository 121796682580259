import {Dispatch, Store} from 'react-redux';
import {Action} from 'redux';
import {AppState, BagState} from '../../state';

export const PROMO_VALIDATION_MESSAGES = 'PROMO_VALIDATION_MESSAGES';

export interface SetPromoValidationMessageAction extends Action {
  type: 'PROMO_VALIDATION_MESSAGES';
  validationMessages: Array<string>;
}

type PromoValidationMessagesFunc = (
  validationMessages: Array<string>
) => SetPromoValidationMessageAction;

export const setValidationMessages: PromoValidationMessagesFunc = (
  validationMessages: Array<string>
) => ({
  type: PROMO_VALIDATION_MESSAGES,
  validationMessages,
});

export interface SetIsValidPromoAction extends Action {
  type: 'IS_VALID_PROMO';
  isValidPromo: boolean;
}

type IsPromoValidFunc = (isValidPromo: boolean) => SetIsValidPromoAction;
export const setIsValidPromo: IsPromoValidFunc = (isValidPromo: boolean) => ({
  type: 'IS_VALID_PROMO',
  isValidPromo,
});
