import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { GetCustomerMessageBulkRequest } from '@mfb/account-api-client';
import { accountApiClient } from '../../../app/shared/api';
import { CustomerMessageBulkState } from '../../state';
import { AppState } from '../../state';

export const RECEIVE_CUSTOMER_MESSAGE_BULK = 'RECEIVE_CUSTOMER_MESSAGE_BULK';

export interface ReceiveCustomerMessageBulkAction extends Action {
  type: typeof RECEIVE_CUSTOMER_MESSAGE_BULK;
  payload: CustomerMessageBulkState[];
}

const receiveCustomerMessageBulkActionCreator = (
  customerMessageBulk: CustomerMessageBulkState[]
): ReceiveCustomerMessageBulkAction => ({
  type: RECEIVE_CUSTOMER_MESSAGE_BULK,
  payload: customerMessageBulk,
});

export const fetchCustomerMessageBulk = (
  subscriptionNumber: string,
  triggerCodes: string[],
  deliveryDateIds: number[]
): ThunkAction<Promise<ReceiveCustomerMessageBulkAction>, AppState, never> => {
  return async (dispatch) => {
    try {
      const customersMessagesRequestBody = new GetCustomerMessageBulkRequest();
      customersMessagesRequestBody.deliveryDateIds = deliveryDateIds;
      customersMessagesRequestBody.subscriptionNumber = subscriptionNumber;
      customersMessagesRequestBody.triggerCodes = triggerCodes;

      const customerMessagesResponse =
        (await accountApiClient.customerMessagesBulk(
          customersMessagesRequestBody
        )) as CustomerMessageBulkState[];

      const customerMessageBulkResult = customerMessagesResponse.map(
        (customerMessage) => {
          customerMessage.subscriptionNumber = subscriptionNumber;
          return customerMessage;
        }
      );

      return dispatch(
        receiveCustomerMessageBulkActionCreator(
          customerMessageBulkResult as CustomerMessageBulkState[]
        )
      );
    } catch (err) {
      throw err;
    }
  };
};

export type CustomerMessageBulkStateAction =
  | ReceiveCustomerMessageBulkAction
  | Action;
