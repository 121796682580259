import {Action} from 'redux';
import {PageState} from '../../state';

export const VALIDATION_ERRORS = 'shared/VALIDATION_ERRORS';

export interface ValidationErrorsAction extends Action {
  type: 'shared/VALIDATION_ERRORS';
  payload: PageState;
}

type ValidationErrorsFunc = (payload: PageState) => ValidationErrorsAction;

export const validationErrors: ValidationErrorsFunc = payload => ({
  type: VALIDATION_ERRORS,
  payload,
});
