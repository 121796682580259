import React from 'react';
import { Button, ButtonStyles } from '@mfb/lego';
import { UiState } from '../../../../redux/state';
import {
  AccountSettings,
  ApplyPromoCodeRequest,
} from '../../../shared/CoreClient';

export interface Props {
  subscriptionId: number;
  primaryBagId: number;
  productOptionSku: string;
  dataAction: string;
  ui: UiState;
  applyPromoCode(
    request: ApplyPromoCodeRequest
  ): Promise<AccountSettings | null>;
}

interface State {
  promoCode: string;
  promoIsSuccess: boolean;
  currentPromoInput: number;
  errorMessage: string;
}

export const hasAlreadyUsedMessage = 'This promo code has already been used';
export const successMessage = 'Promo code has been applied';

class Promo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      promoCode: '',
      promoIsSuccess: false,
      currentPromoInput: null,
      errorMessage: null,
    };
  }

  handleChange = (
    event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({
      promoCode: event.currentTarget.value,
    });
  };

  submitHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const { subscriptionId, primaryBagId, productOptionSku } = this.props;
    const { promoCode } = this.state;

    if (promoCode.trim().length === 0) {
      this.setState({
        currentPromoInput: subscriptionId,
        errorMessage: 'Please enter a valid promo code',
      });

      return;
    }

    this.setState({
      currentPromoInput: subscriptionId,
      errorMessage: '',
    });

    try {
      const response = await this.props.applyPromoCode({
        id: subscriptionId,
        primaryBagId,
        productOptionSku,
        promoCode: promoCode.trim(),
      });

      this.setState({
        promoIsSuccess: response != null,
        currentPromoInput: null,
        errorMessage: '',
      });
    } catch (e) {
      const json = JSON.parse(e.response);
      const errorMessage =
        json.promoCode !== undefined
          ? json.promoCode.message
          : 'Something went wrong';

      this.setState({
        promoIsSuccess: false,
        errorMessage,
        currentPromoInput: null,
      });
    }
  };

  render() {
    const { dataAction } = this.props;
    const { promoIsSuccess, errorMessage, promoCode } = this.state;

    return (
      <div className="my-3">
        <strong className="mb-md-0 d-block mb-2">
          Do you have a promo code?
        </strong>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '5fr 1fr',
            gap: '16px',
            marginTop: '6px',
          }}
        >
          <div>
            <input
              type="text"
              value={this.state.promoCode}
              onChange={this.handleChange}
              name="promoCode"
              id="promoCode"
              className="form-control w-100"
              style={{ borderRadius: '8px', height: '42px' }}
            />
          </div>
          <div>
            <Button
              data-test="apply-promo"
              data-category="subscription"
              data-action={dataAction}
              data-label={promoCode.trim()}
              variant={'default'}
              label={'Apply'}
              onClick={this.submitHandler}
              buttonStyle={ButtonStyles.Primary}
            />
          </div>
        </div>
        {errorMessage != null && (
          <div className="text-danger mt-2">{errorMessage}</div>
        )}
        {promoIsSuccess && (
          <div className="text-success mt-2">{successMessage}</div>
        )}
      </div>
    );
  }
}

export default Promo;
