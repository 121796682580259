import {matchPath} from 'react-router-dom';
import ManageSubIcon from '../../../shared/svgs/ManageSubIcon';
import PaymentIcon from '../../../shared/svgs/PaymentIcon';
import PersonalDetailsIcon from '../../../shared/svgs/PersonalDetailsIcon';
import OrderHistoryIcon from '../../../shared/svgs/OrderHistoryIcon';
import VouchersIcon from '../../../shared/svgs/VouchersIcon';
import {calendarPath, holidaysPath, manageAccountPath, referAFriendPath} from './paths';
import MenuItem from '../../../shared/MenuItem';
import {SvgRafGiftIcon as RafGiftIcon, SvgCalendarIcon} from '@mfb/lego';

import {
  subscriptionsPath,
  paymentDetailsPath,
  personalDetailsPath,
  orderHistoryPath,
  vouchersPath,
} from './paths';
import {SvgSunIcon} from '@mfb/lego';

export const MenuItemsLegacy: Array<MenuItem> = [
  {
    title: 'Subscriptions',
    path: subscriptionsPath,
    icon: ManageSubIcon,
    description: 'Change Product, Delivery Time, Extras.',
    // Also match the root of Account Settings, as My Subscriptions is shown by default
    isActive: (match, location) =>
      match != null ||
      matchPath(location.pathname, {path: manageAccountPath, exact: true}) !=
        null,
  },
  {
    title: 'Payment Details',
    path: paymentDetailsPath,
    icon: PaymentIcon,
    description: 'Credit cards for payment.',
  },
  {
    title: 'Personal Details',
    path: personalDetailsPath,
    icon: PersonalDetailsIcon,
    description: 'Name, Email, Address, Delivery Instructions, Password.',
  },
  {
    title: 'Order History',
    path: orderHistoryPath,
    icon: OrderHistoryIcon,
    description: 'View previous delivery history.',
  },
  {
    title: 'Vouchers',
    path: vouchersPath,
    icon: VouchersIcon,
    description: 'Redeem gift vouchers.',
  },
  {
    title: 'My Deals',
    path: referAFriendPath,
    icon: RafGiftIcon,
    description: 'My Deals.',
  },
  {
    title: 'Delivery Calendar',
    path: calendarPath,
    icon: SvgCalendarIcon,
    description: 'Manage deliveries using calendar.',
  }
];

export const HolidaysMenuItemLegacy: MenuItem = {
  title: 'Holidays',
  path: holidaysPath,
  icon: SvgSunIcon,
  description: 'Manage deliveries for a Holiday.',
};

export default MenuItemsLegacy;
