import { Brand } from '../CoreClient';
import { Brand as BrandName } from '@mfb/lego';

export const useBrandFromBrandId = (brandId: Brand): BrandName | undefined => {
  try {
    switch (brandId) {
      case 1:
        return BrandName.MFB;
      case 2:
        return BrandName.BB;
      case 4:
        return BrandName.FS;
      case 8:
        return BrandName.RMM;
      default:
        return undefined;
    }
  } catch (err) {
    //Selected bag doesn't exist for some reason.
    return undefined;
  }
};
