import React from 'react';
import styled from 'styled-components';
import {COLOR_CODE} from '../constants';

const Banner = styled.div`
  top: 0px;
  z-index: 0;
  color: ${COLOR_CODE.WHITE};
`;
const InvalidPromoBanner = styled(Banner)`
  background: ${COLOR_CODE.DARK_RED};
`;
const ValidPromoBanner = styled(Banner)`
  background: ${COLOR_CODE.DISCOUNT_BLUE};
`;

export interface PromoBannerProps {
  validationErrors: string[];
  isValidPromo: boolean;
}

export const PromoBanner: React.FC<PromoBannerProps> = ({
  validationErrors,
  isValidPromo,
}) => {
  return (
    <>
      {isValidPromo && validationErrors.length == 0 && (
        <ValidPromoBanner className="d-flex align-items-center justify-content-center text-center sticky-top">
          <span
            style={{backgroundColor: COLOR_CODE.DISCOUNT_BLUE}}
            className="p-2"
          >
            {' '}
            Discount is applied to the subscription
          </span>
        </ValidPromoBanner>
      )}
      {validationErrors.length > 0 && (
        <InvalidPromoBanner
          //key={index}
          className="d-flex align-items-center justify-content-center text-center sticky-top"
        >
          <span
            style={{
              backgroundColor: COLOR_CODE.DARK_RED,
              color: COLOR_CODE.WHITE,
            }}
            className="p-2"
          >
            {' '}
            {validationErrors[0]}
          </span>
        </InvalidPromoBanner>
      )}
    </>
  );
};

export default PromoBanner;
