import React from 'react';
import {fromPairs, isFunction} from 'lodash';
import PromiseQueue from 'promise-queue';
import {BaseClient} from './BaseClient';
import getAllPropertyNames from './getAllPropertyNames';

interface State<M> {
  model: M;
  modelUpdating: boolean;
}

type ModelFetcher<M> = (
  ...args: Array<any>
) => Promise<M | undefined | null | void>;

export const getComponentName = (
  component: React.ComponentClass<any> | React.FunctionComponent<any>
) => component.displayName || component.name || 'Component';

/**
 * P - Props passed from parent to connector
 * S - State of connector
 * M - Model
 */
export abstract class BaseApiConnector<P, S, M> extends React.PureComponent<
  P,
  S & State<M>
> {
  state = {
    model: null,
    modelUpdating: false,
  } as Readonly<S & State<M>>;
  /**
   * Mutex for ensuring only one modelFetcher can run at the same time.
   */
  modelFetchQueue = new PromiseQueue(1);

  /**
   * Hook for subclasses to react to the model updating (i.e. for hacky interop with redux)
   */
  modelDidUpdate(): void {
    return null;
  }

  /**
   * Wraps an CoreClient API operation so that no more than 1 operation can run at once, and if the server returns an
   * updated model, this.state.model is updated. Remember to .bind() the function, because the generated clients don't
   * autobind.
   * @param {T} fetchFunc  the function to wrap
   */
  protected wrapModelFetcher<T extends ModelFetcher<M>>(fetchFunc: T): T {
    const wrappedFunc = async (...args: Array<any>): Promise<M | null> => {
      try {
        this.setState({modelUpdating: true} as Pick<
          S & State<M>,
          'modelUpdating'
        >);
        const model = await this.modelFetchQueue.add(
          async () => (fetchFunc as any)(...args) as Promise<M>
        );
        if (model != null) {
          this.setState({model} as Pick<S & State<M>, 'model'>, () =>
            this.modelDidUpdate()
          );
        }

        return model;
      } finally {
        this.setState({
          modelUpdating: this.modelFetchQueue.getPendingLength() > 0,
        } as Pick<S & State<M>, 'modelUpdating'>);
      }
    };

    return wrappedFunc as T;
  }

  /**
   * Wraps every method on a CoreClient instance using wrapModelFetcher. When using this function, ensure that
   * only a partial view of the client is exposed, which contains only functions that fetch models of type M.
   * If an API call that returns something other than a model is accessed through the wrapped client, it will
   * overwrite `model` with that other value, which would cause a lot of problems.
   * TODO: Make a mapped type to exclude functions that don't return models when TS implements this:
   * https://github.com/Microsoft/TypeScript/issues/12424
   * @param {BaseClient} client
   */
  protected wrapClient<C extends BaseClient>(client: C): C {
    const operationPairs = getAllPropertyNames(client, BaseClient)
      .map(key => [key, (client as any)[key]])
      .filter(([, operation]) => isFunction(operation))
      .map(([k, operation]) => [
        k,
        this.wrapModelFetcher(operation.bind(client)),
      ]);

    return fromPairs(operationPairs) as C;
  }
}
