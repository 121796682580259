import React from 'react';
import Observer from 'react-intersection-observer';

interface Props {
  // any because https://github.com/Microsoft/TypeScript/issues/6471#issuecomment-171456118
  children?: any;
  placeholderClassName?: string;
}

const isIntersectionObserverSupported =
  typeof (window as any)['IntersectionObserver'] === 'function';

// Instead of relying on a polyfill simply don't use InteractionObserver if it
// is not supported by the browser.
//
// This has several advantages:
// - doesn't break our unit tests (aka "works" in jsdom)
// - doesn't increase the number of polyfills needed so doesn't change page weight
// - doesn't require a polyfill that might introduce tradeoffs
// - "progressive enhancement" (i.e. leverage better capabilities when they're supported)
const ShowIfOnScreen: React.FunctionComponent<Props> = ({
  children,
  placeholderClassName,
}: Props) => {
  if (isIntersectionObserverSupported) {
    return (
      <Observer triggerOnce={true}>
        {inView =>
          inView ? (
            children
          ) : (
            <div className={placeholderClassName} role="presentation" />
          )
        }
      </Observer>
    );
  }

  return children;
};

export default ShowIfOnScreen;
