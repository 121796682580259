import {AccountSubscription} from './CoreClient';

declare let dataLayer: Array<any>;

export enum ModifySubscriptionEvent {
  FREQUENCY = 'modify_subscription_frequency',
  NIGHTS = 'modify_subscription_nights',
  PEOPLE = 'modify_subscription_people',
  TIME = 'modify_subscription_time',
  ADDRESS = 'modify_subscription_address',
  BAG = 'change_bag',
}

export enum ModifySubscriptionAction {
  FREQUENCY = 'Frequency',
  NIGHTS = 'Nights',
  PEOPLE = 'People',
  DAY = 'Day',
  ADDRESS = 'Address',
  BAG = 'Change Bag',
}

export const modifySubscriptionFrequencyTracker = async (
  last_subscription_frequency: string,
  subscription_frequency: string,
  bag_sku: string,
  subscription_number: string
) => {
  dataLayer.push({
    event: ModifySubscriptionEvent.FREQUENCY,
    subscription_number: `${subscription_number}`,
    bag_sku: `${bag_sku}`,
    last_subscription_frequency: `${last_subscription_frequency}`,
    subscription_frequency: `${subscription_frequency}`,
    eventDetails: {
      category: 'Modify Subscription',
      action: ModifySubscriptionAction.FREQUENCY,
      label: `${subscription_frequency} from ${last_subscription_frequency}`,
    },
  });
  return;
};
export const modifySubscriptionNightsTracker = async (
  subscriptionNumber: string,
  bagSku: string,
  subscription_nights: number
) => {
  dataLayer.push({
    event: ModifySubscriptionEvent.NIGHTS,
    subscription_number: `${subscriptionNumber}`,
    bag_sku: `${bagSku}`,
    subscription_nights: `${subscription_nights}`,
    eventDetails: {
      category: 'Modify Subscription',
      action: ModifySubscriptionAction.NIGHTS,
      label: `Subscription updated to ${subscription_nights} Nights`,
    },
  });
  return;
};
export const modifySubscriptionPeopleTracker = async (
  subscriptionNumber: string,
  bagSku: string,
  last_subscription_people: number,
  subscription_people: number
) => {
  dataLayer.push({
    event: ModifySubscriptionEvent.PEOPLE,
    subscription_number: `${subscriptionNumber}`,
    bag_sku: `${bagSku}`,
    last_subscription_people: `${last_subscription_people}`,
    subscription_people: `${subscription_people}`,
    eventDetails: {
      category: 'Modify Subscription',
      action: ModifySubscriptionAction.PEOPLE,
      label: `${subscription_people} from ${last_subscription_people}`,
    },
  });
  return;
};
export const modifySubscriptionTimeTracker = async (
  subscriptionNumber: string,
  bagSku: string,
  last_subscription_time: string,
  subscription_time: string,
  last_subscription_day: string,
  subscription_day: string
) => {
  dataLayer.push({
    event: ModifySubscriptionEvent.TIME,
    subscription_number: `${subscriptionNumber}`,
    bag_sku: `${bagSku}`,
    last_subscription_day: `${last_subscription_day}`,
    last_subscription_time: `${last_subscription_time}`,
    subscription_day: `${subscription_day}`,
    subscription_time: `${subscription_time}`,
    eventDetails: {
      category: 'Modify Subscription',
      action: ModifySubscriptionAction.DAY,
      label: `${subscription_time} from ${last_subscription_time}`,
    },
  });
  return;
};
export const modifySubscriptionAddressTracker = async (
  subscriptionNumber: string,
  bagSku: string,
  fullAddress: string
) => {
  dataLayer.push({
    event: ModifySubscriptionEvent.ADDRESS,
    subscription_number: `${subscriptionNumber}`,
    bag_sku: `${bagSku}`,
    eventDetails: {
      category: 'Modify Subscription',
      action: ModifySubscriptionAction.ADDRESS,
      label: `Address Updated to ${fullAddress}`,
    },
  });
  return;
};
export const modifySubscriptionBagTracker = async (
  subscription: AccountSubscription,
  bagSku: string,
  bagName: string
) => {
  dataLayer.push({
    event: ModifySubscriptionEvent.BAG,
    subscription_number: `${subscription.subscriptionNumber}`,
    bag_sku: `${bagSku}`,
    bag_name: `${bagName}`,
    previous_bag_name: `${subscription.primaryBagName}`,
    previous_bag_sku: `${subscription.productOptionSku}`,
    eventDetails: {
      category: 'Modify Subscription',
      action: ModifySubscriptionAction.BAG,
      label: `${bagSku} from ${subscription.productOptionSku}`,
    },
  });
  return;
};
