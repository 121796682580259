/* eslint-disable @typescript-eslint/ban-types */
import { hasBaseActiveSubscription } from 'app/shared/Subscriptions';
import { globalSettings as settings } from 'config';
import { first } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { UiComponentName } from 'redux/actions/sharedActions/updateUiComponent';
import { selectUiComponentAttributes } from 'redux/selectors/uiComponentSelector';
import routeHistory from 'routerHistory';
import styled from 'styled-components';
import {
  AlertStatus,
  Brand,
  Button,
  FlexPanel,
  SubscriptionSummaryCard,
  frequencyLabelLookup, useAnalyticsTracking,
} from '@mfb/lego';
import { navman } from '../../../../navigator';
import { AppState } from '../../../../redux/state';
import {
  AccountPromo,
  Brand as CoreClientBrand,
  Frequency,
  SubscriptionClient,
} from '../../../shared/CoreClient';
import {
  AccountSettings,
  AccountSubscription,
  SubscriptionActions,
} from '../../../shared/CoreClient';
import { cancelSubscriptionStepOneTracker } from '../../../shared/cancelSubscriptionTracker';
import MobileStickyHeaderProxy from '../../../shared/components/MobileStickyHeader/MobileStickyHeaderProxy';
import {
  showBadToast,
  showSubscriptionCancelledToast,
} from '../../../toast/ToastMessages';
import {
  PageProperties,
  mapDispatchToProps,
} from '../../containers/accountSettingsContainer';
import { subscriptionsViewPath } from '../Nav/paths';
import Promo from './Promo';
import { UpcomingPromos } from './UpcomingPromos';
import { PrintedRecipeCardsToggle } from './PrintedRecipeCardsToggle';
import { accountApiClient } from 'app/shared/api';
import { ApiAccountException, UpdateRecipeCardPreferenceBody } from '@mfb/account-api-client';
import { useAlertToastContext } from 'app/shared/hooks';

type Props = PageProperties;

export interface RouteParams {
  subscriptionId: string;
  extras?: string;
  subscriptionNumber: string;
}

interface State {
  cancellingSubscriptionId?: number;
  cancellingBagName?: string;
  cancellingSubscriptionNumber?: string;
}

const NoActiveSubscriptionMessage = styled(FlexPanel)`
  flex-direction: column;
  width: 100%;
  padding: 40px;

  border-radius: 16px;
  border: 1px solid ${(c) => c.theme.colors.neutral[100]};
  box-shadow:
    0px 4px 6px -2px rgba(0, 0, 0, 0.03),
    0px 8px 16px -4px rgba(0, 0, 0, 0.08);

  h6 {
    margin-bottom: ${(c) => c.theme.spacing.small};

    font-size: 25px;
    font-weight: ${(c) => c.theme.typography.fontWeight[700]};
    color: ${(c) => c.theme.colors.neutral[900]};
    line-height: 130%;
  }

  span {
    margin-bottom: 32px;

    font-size: ${(c) => c.theme.typography.fontSize[400]};
    font-weight: ${(c) => c.theme.typography.fontWeight[400]};
    line-height: 150%;
  }

  @media ${(c) => c.theme.layout.deviceMediaQueries.min.lg} {
    max-width: 700px;
  }
`;

const StyledSummaryCardsContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export const MySubscriptionsUnconnected = (props) => {

  var {updateToast} = useAlertToastContext();
  const {trackDataLayerEvent} = useAnalyticsTracking();
  const [optOutRecipeCards, setOptOutRecipeCards] = React.useState<boolean>(props.customerProfileState.optOutRecipeCards);

  var [cancelState, setState] = React.useState({
    cancellingSubscriptionId: undefined,
    cancellingBagName: undefined,
    cancellingSubscriptionNumber: undefined,
  } as State)
  const onClickCancelSub = (
    cancellingSubscriptionId: number,
    cancellingBagName: string,
    cancellingSubscriptionNumber: string
  ) => {
    const pageState = props.pageState as AccountSettings;
    const hasMultipleActiveSubscriptions =
      pageState.subscriptions &&
      pageState.subscriptions.filter((sub) =>
        sub.actions.includes(SubscriptionActions.CancelSubscription)
      ).length > 1;

    const { overrideLegacyCancelSubscriptionFlow } = props.features;

    if (hasMultipleActiveSubscriptions) {
      overrideLegacyCancelSubscriptionFlow
        ? navman.cancelSubscriptionV1(
            cancellingSubscriptionId,
            cancellingSubscriptionNumber
          )
        : setState({ cancellingSubscriptionNumber, cancellingBagName, cancellingSubscriptionId: cancelState.cancellingSubscriptionId });
    } else {
      //determines which cancel flow to show
      overrideLegacyCancelSubscriptionFlow
        ? navman.cancelSubscriptionV1(
            cancellingSubscriptionId,
            cancellingSubscriptionNumber
          )
        : navman.cancelSubscription(cancellingSubscriptionId);
    }
  };

  const toggleEditModeHandler = (subId: number, subNumber: string) => {
    navman.editSubscription(subId, subNumber);
  };

  const updateOptOutRecipeCards = async (optOut: boolean, subscriptionNumber: string) => {
    setOptOutRecipeCards(optOut)
    var updateRequest = {optOutRecipeCards: optOut, subscriptionNumber: subscriptionNumber} as UpdateRecipeCardPreferenceBody;
    
    try {
      await accountApiClient.customersUpdateRecipeCardPreference(updateRequest);
      updateToast({
        status: AlertStatus.success,
        isOpen: true,
        text: optOut ? 
          'You will no longer receive recipe cards with your upcoming orders.' : 
          'You will now receive recipe cards with your upcoming orders.',
      });
     } catch {
        setOptOutRecipeCards(!optOut);
        updateToast({
          status: AlertStatus.error,
          isOpen: true,
          text: "Oops! We've scrambled our eggs. 🍳"
        });
      }
  }

  const pageState = props.pageState as AccountSettings;
    const { bagSelection } = pageState;
    const client = new SubscriptionClient(settings.bffHost);

    const editableSubscriptions = pageState.subscriptions.filter(
      (c) =>
        c.actions.includes(SubscriptionActions.EditSubscription) ||
        c.actions.length === 0
    );

    const canAddNewSubscription = !hasBaseActiveSubscription(
      pageState.subscriptions
    );

    const hasCreditCards = pageState.cards && pageState.cards.length > 0;

    return (
      <div data-test="my-subscriptions">
        <MobileStickyHeaderProxy
          hiddenUp="md"
          onBack={
            props.features.dashboardV2 ? undefined : navman.accountSettings
          }
        />
        {!editableSubscriptions &&
          !hasCreditCards &&
          `You do not have any active subscriptions.`}
        {!editableSubscriptions &&
          pageState.hasWrittenOffDeliveries &&
          `Please resolve outstanding payments before adding a subscription.`}
        {!getIsUserOnlyHasOneOff(editableSubscriptions) && (
          <StyledSummaryCardsContainer>
            {editableSubscriptions.map((sub: AccountSubscription) => {
              const [bagName, bagDescription] = sub.primaryBagName
                .replace(')', '')
                .split('(');

              var brand = null;
              
              switch (sub.brand) {
                case CoreClientBrand.BargainBox:
                  brand = Brand.BB;
                  break;

                case CoreClientBrand.FreshStart:
                  brand = Brand.FS;
                  break;

                default:
                  brand = Brand.MFB;
                  break;
              }

              if (sub.primaryFrequency.value === Frequency.OneOff) {
                return <></>;
              } else {
                return (
                  <SubscriptionSummaryCard
                    variant={'default'}
                    bagDetails={{
                      variant: 'default',
                      title: bagName,
                      bagDescription: bagDescription.trim(),
                      frequency: `${frequencyLabelLookup[sub.primaryFrequency.value]} order`,
                      buttonText: 'Edit',
                      onEdit: () =>
                        toggleEditModeHandler(
                          sub.id,
                          sub.subscriptionNumber
                        ),
                    }}
                    deliveryDetails={{
                      variant: 'default',
                      title: 'Delivery Details',
                      address: pageState.customer.address.value,
                      time: sub.deliverySlotDescription,
                      buttonText: 'Edit',
                      onEdit: () => {
                        routeHistory.history.push(
                          `${subscriptionsViewPath}/${sub.subscriptionNumber}`,
                          { showDetailsInit: true }
                        );
                      },
                    }}
                    brandForLogo={brand}
                    onCancelSubscription={async () => {
                      onClickCancelSub(
                        sub.id,
                        sub.primaryBagName,
                        sub.subscriptionNumber
                      );

                      const trackingMetaData = props?.tracking?.subscriptions?.find(c=>c.subscriptionNumber===sub.subscriptionNumber)?.metadata;
                      trackDataLayerEvent('Subscription - Start Cancellation',trackingMetaData);

                      const numberOfPeopleToFeed = bagSelection.find(
                        (bag) => bag.id === sub.primaryBagId
                      ).numberOfPeopleToFeed;
                      const subscriptions = await client.getSubscriptions();
                      const numberOfNights = subscriptions.find(
                        (subscription) => {
                          return subscription.subscriptionId === sub.id;
                        }
                      ).primarySkuNumberOfNights;
                      cancelSubscriptionStepOneTracker(
                        sub.subscriptionNumber,
                        sub.productOptionSku,
                        sub.primaryBagName,
                        sub.deliverySlotDescription,
                        numberOfPeopleToFeed,
                        numberOfNights
                      );
                    }}
                    cancelSubscriptionAboveChildren={true}
                  >
                    {sub.actions.includes(
                      SubscriptionActions.EditSubscription
                    ) && (
                      <React.Fragment>
                        <Promo
                          subscriptionId={sub.id}
                          primaryBagId={sub.primaryBagId}
                          productOptionSku={sub.productOptionSku}
                          applyPromoCode={props.applyPromoCode}
                          ui={props.ui}
                          dataAction="add-promo-on-update"
                        />
                      </React.Fragment>
                    )}
                    <UpcomingPromos promos={sub.promos} />
                  <PrintedRecipeCardsToggle 
                    optOutCallback={(optOut) => updateOptOutRecipeCards(optOut, sub.subscriptionNumber)}
                    optOutRecipeCards={optOutRecipeCards}
                  />
                  </SubscriptionSummaryCard>
                );
              }
            })}
          </StyledSummaryCardsContainer>
        )}

        {canAddNewSubscription && (
          <NoActiveSubscriptionMessage>
            <h6>{`Welcome back, ${props.customerProfileState.firstName}!`}</h6>
            <span>
              You have an existing account with us, click ‘Add a Subscription’
              to check out our delicious recipes.
            </span>
            <Button
              label="Add a Subscription"
              size="lg"
              onClick={() => navman.reactivateSubscription()}
              data-testid={'add-subscription'}
            />
          </NoActiveSubscriptionMessage>
        )}
      </div>
    );
}

const mapStateToProps = (state: AppState, ownProps: Props) => {
  return {
    ...state,
    ...ownProps,
  };
};

export const SubscriptionsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(MySubscriptionsUnconnected);

export const getIsUserOnlyHasOneOff = (
  subscriptions: AccountSubscription[]
) => {
  if (
    subscriptions
      .map((s) => s.primaryFrequency.value === Frequency.OneOff)
      .includes(false)
  ) {
    return false;
  }

  return true;
};
