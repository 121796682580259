import logger from 'app/shared/Logger';
import { readFromSessionStorage } from 'redux/sessionStorage';
import { CartMetaExtra, CartMetaRecipe } from '@mfb/cookbook';
import { getQueryParams } from './PlanYourWeekQueryParams';

type Selection = {
  recipes: CartMetaRecipe[];
  extras: CartMetaExtra[];
};

export const addRecipesFromQueryParam = (
  availableRecipes: CartMetaRecipe[],
  selectedRecipes: CartMetaRecipe[]
) => {
  try {
    const { recipes } = getQueryParams();

    const validQueryParamRecipes = availableRecipes.filter((c) =>
      recipes.map((u) => u.recipeNumber).includes(c.recipeNumber)
    );

    validQueryParamRecipes.map((c) => {
      const recipe = recipes.find((u) => u.recipeNumber === c.recipeNumber);
      c.quantity = recipe.quantity;
      selectedRecipes.push(c);
    });
  } catch (e) {
    if (e instanceof Error) {
      logger.error(e, {});
    }
  }
};

export const addExtrasFromQueryParam = (
  availableExtras: CartMetaExtra[],
  selectedRecipes: CartMetaExtra[]
) => {
  try {
    const { extras } = getQueryParams();

    const isSoldOut = (extra: CartMetaExtra): boolean => {
      const isAlreadyOnDelivery =
        extra.initialQuantity && extra.initialQuantity > 0;
      return Boolean(
        extra.isDemandLocked && !extra.isPromotional && !isAlreadyOnDelivery
      );
    };

    const validQueryParamExtras = availableExtras.filter((c) =>
      extras.map((u) => u.sku).includes(c.sku)
    );

    validQueryParamExtras.map((c) => {
      const extra = extras.find((u) => u.sku === c.sku && !isSoldOut(c));
      c.quantity = c.quantity + extra.quantity;
      selectedRecipes.push(c);
    });
  } catch (e) {
    if (e instanceof Error) {
      logger.error(e, {});
    }
  }
};

export const addExtrasPromoExtras = (
  promoExtras: CartMetaExtra[],
  selectedExtras: CartMetaExtra[]
) => {
  for (var extra of promoExtras) {
    const extraAlreadyExists = selectedExtras.find((selectedExtra) => selectedExtra.sku === extra.sku && selectedExtra.isPromotional);
    if (!extraAlreadyExists) {
      selectedExtras.push(extra);
    }
  }
};

export const replaceSelectedRecipesWithSessionStorage = (
  key: object,
  availableRecipes: CartMetaRecipe[],
  selectedRecipes: CartMetaRecipe[]
) => {
  try {
    const selection = readFromSessionStorage(key) as Selection;

    if (!selection || !selection.recipes || selection.recipes.length === 0) {
      return;
    }

    const selectedRecipesFromSessionStorage = availableRecipes.filter((c) =>
      selection.recipes.map((u) => u.recipeNumber).includes(c.recipeNumber)
    );

    if (selectedRecipesFromSessionStorage.length > 0) {
      selectedRecipes.length = 0;
      selectedRecipesFromSessionStorage.map((c) => {
        const recipe = selection.recipes.find(
          (u) => u.recipeNumber === c.recipeNumber
        );

        if (selectedRecipes) c.quantity = recipe.quantity;
        selectedRecipes.push(c);
      });
    }
  } catch (e) {
    if (e instanceof Error) {
      logger.error(e, {});
    }
  }
};

export const replaceSelectedExtrasWithSessionStorage = (
  key: object,
  availableExtras: CartMetaExtra[],
  selectedExtras: CartMetaExtra[]
) => {
  try {
    const selection = readFromSessionStorage(key) as Selection;

    if (!selection || !selection.extras || selection.extras.length === 0) {
      return;
    }

    if (selection.extras && selection.extras.length > 0) {
      const selectedExtrasFromSessionStorage = availableExtras.filter((c) =>
        selection.extras.map((u) => u.sku).includes(c.sku)
      );

      if (selectedExtrasFromSessionStorage.length > 0) {
        selectedExtras.length = 0;
        selectedExtrasFromSessionStorage.map((c) => {
          const a = selection.extras.find((u) => u.sku === c.sku);
          c.quantity = a.quantity;
          selectedExtras.push(c);
        });
      }
    }
  } catch (e) {
    if (e instanceof Error) {
      logger.error(e, {});
    }
  }
};
