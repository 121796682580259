import {
  Carousel,
  mapRecipeCarouselItem,
  RecipeCarouselItemProps,
  RecipeCarouselItemVariants,
} from '@mfb/lego';
import React from 'react';
import {RecipeResponse2} from '../../../shared/CoreClient';
import { globalSettings as settings } from 'config';
import {sortBy} from 'lodash';

interface Props {
  bagSku: string;
  addresId: number;
  recipes: Array<RecipeResponse2>;
  defaultRecipeCategories?: Array<string>;
}

const mapRecipeToCarouselItem = (
  recipe: RecipeResponse2
): RecipeCarouselItemProps => ({
  id: recipe.recipeNumber,
  src: recipe.imageUrl,
  alt: `${recipe.title} ${recipe.subtitle}`.trim(),
  showShadow: true,
});

export const BufferPromoSection: React.FC<Props> = props => {
  const carouselItems = React.useMemo(() => {
    const filteredRecipes =
      props.defaultRecipeCategories && props.defaultRecipeCategories.length > 0
        ? props.recipes.filter(r =>
            props.defaultRecipeCategories.includes(r.categoryCode)
          )
        : props.recipes;
    return sortBy(filteredRecipes, 'sortOrder')
      .map(mapRecipeToCarouselItem)
      .map(recipe => mapRecipeCarouselItem(recipe));
  }, [props.recipes]);

  const params = new URLSearchParams();
  params.set('sku', props.bagSku);
  params.set('AddressId', props.addresId.toString());

  return (
    <div className="mt-4 mt-md-5">
      <div className="d-flex flex-column flex-md-row">
        <div className="col px-0">
          <p className="mb-1" style={{fontSize: '1.6rem'}}>
            Delivery Skipped
          </p>
          <h3 className="display-4">
            You can still get a delivery though, it&apos;s not too late.
          </h3>
          <a
            className="btn text-white rounded-lg mt-4"
            style={{backgroundColor: '#AB278E'}}
            href={`${
              settings.myfoodbagWebsiteURL
            }/late-sales?pr=&${params.toString()}`}
            target="_blank"
            rel="noreferrer"
            id={`skipped-delivery_late-sales-cta`}
          >
            See Available Bags
          </a>
        </div>
        <div className="col col-md-7 px-0 mt-4 mt-md-0">
          <Carousel
            items={carouselItems}
            itemClassName="px-2"
            responsiveSlideCount={{
              small: 1.5,
              medium: 2,
              large: 3,
              extraLarge: 3,
            }}
            showArrows
          />
        </div>
      </div>
    </div>
  );
};
