/* eslint-disable @typescript-eslint/ban-types */
import { Action } from 'redux';
import { ComponentClass, connect, MapDispatchToProps } from 'react-redux';
import DeliveryLoadingWrapper from './components/DeliveryLoadingWrapper';
import { AppState, OrdersPageState } from '../../redux/state';
import { performDaySelectionAction } from '../../redux/actions/sharedActions/daySelectionAction';
import { fetchOrderPageThunk } from '../../redux/thunks/fetchOrderPageThunk';
import {
  skipActionThunk,
  SkipPayload,
} from '../../redux/thunks/skipActionThunk';

export type FetchPageStateFunc = () => void;
export type SkipFunc = (payload: SkipPayload) => Promise<void>;
export type SelectDayFunc = (DeliveryDay: string) => void;
export type PerformJoinCampaign = () => void;

export interface DispatchProps {
  fetchYourAccountState: FetchPageStateFunc;
  performSkipAction: SkipFunc;
  updateSelectedDay: SelectDayFunc;
  performJoinCampaign: PerformJoinCampaign;
}

export type PageProperties<TProps> = AppState<TProps> & DispatchProps;

const mapStateToProps = (
  state: PageProperties<OrdersPageState>
): AppState<OrdersPageState> => state;
const action = async (dispatch) => dispatch(fetchOrderPageThunk());

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch
) => ({
  fetchYourAccountState: async () => action(dispatch),
  performSkipAction: async (payload) => {
    /*
      we cast this because the interfaces here don't
      match (we are on an older version of Redux and Thunk
      @TODO bump version of both and remove this cast when upgrading

      as of writing we have
        "redux": "3.6.0",
        "redux-thunk": "2.2.0",
     */
    await dispatch(skipActionThunk(payload) as unknown as Action);
  },
  updateSelectedDay: (deliveryDay) =>
    performDaySelectionAction(deliveryDay)(dispatch, () => {}, null),
  performJoinCampaign: async () => action(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryLoadingWrapper) as ComponentClass<any>;
