import React from 'react';
import {
  AccountInformationClient,
  AccountSettings,
  AddressSuggestion,
  DayOfWeek,
  Day,
  SwaggerException,
  HolidayClient,
  GetCustomerAddressHolidayPreviewResponse,
  PreviewCustomerAddressHolidayQuery,
  CreateCustomerAddressHolidayCommand,
} from '../../../shared/CoreClient';
import AddressFinder from '../AddressFinder';
import DeliverySlotSelect from '../DeliverySlotSelect';
import {AsyncButton, SvgInfoIcon} from '@mfb/lego';
import { globalSettings as settings } from 'config';
import HttpStatus from 'http-status-codes';
import {navman} from '../../../../navigator';
import {DateRangePicker} from './DatePicker/DateRangePicker';
import {HolidaySummary} from './HolidaySummary/HolidaySummary';
import classnames from 'classnames';
import Spinner from '../../../shared/components/Spinner';
import {showBadToast, showGoodToast} from '../../../toast/ToastMessages';
import {isNil} from 'lodash';
import {sendInvalidAddressEvent} from './holidaysTracker';

const HOLIDAY_CLIENT = new HolidayClient(settings.bffHost);
interface Props {
  subProductOptionId: number;
}

export const HolidayAddress: React.FC<Props> = ({subProductOptionId}) => {
  const [
    selectedAddress,
    setSelectedAddress,
  ] = React.useState<AddressSuggestion>();
  const [
    isSelectedAddressValid,
    setIsSelectedAddressValid,
  ] = React.useState<boolean>(false);
  const [addressErrorMsg, setAddressErrorMsg] = React.useState<string>();

  const [selectedDeliverySlotId, setDeliverySlotId] = React.useState<number>();
  const [
    selectedDeliverySlotDayOfWeek,
    setDeliverySlotDayOfWeek,
  ] = React.useState<number>();

  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();

  const [
    hasCheckedDisclaimer,
    setHasCheckedDisclaimer,
  ] = React.useState<boolean>(false);

  const [
    holidaySummaryResponse,
    setHolidaySummaryResponse,
  ] = React.useState<GetCustomerAddressHolidayPreviewResponse>();

  const [isLoadingSummary, setIsLoadingSummary] = React.useState<boolean>();
  React.useEffect(() => {
    if (!startDate || !endDate || !selectedDeliverySlotId) {
      return;
    }

    const request: PreviewCustomerAddressHolidayQuery = {
      preferredDeliverySlotId: selectedDeliverySlotId,
      selectedStartDeliveryDate: startDate,
      selectedEndDeliveryDate: endDate,
    };

    setIsLoadingSummary(true);

    HOLIDAY_CLIENT.previewCustomerAddressHoliday(request)
      .then(r => setHolidaySummaryResponse(r))
      .finally(() => setIsLoadingSummary(false));
  }, [startDate, endDate, selectedDeliverySlotId]);

  const [upcomingLockDate, setUpcomingLockDateForSlot] = React.useState<Date>();

  const onAddressChange = async (address: AddressSuggestion) => {
    setSelectedAddress(address);
    setAddressErrorMsg(undefined);
    setDeliverySlotId(undefined);
    setDeliverySlotDayOfWeek(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    setHasCheckedDisclaimer(undefined);
    setHolidaySummaryResponse(undefined);

    const client = new AccountInformationClient(settings.bffHost);
    try {
      setIsSelectedAddressValid(false);
      await client.validateAddress({
        pafId: address.pafId,
      });
      setIsSelectedAddressValid(true);
    } catch (err) {
      const error = err as SwaggerException;
      if (error.status !== HttpStatus.BAD_REQUEST) {
        throw err;
      }

      const errorDetails = error.result as AccountSettings;
      setAddressErrorMsg(errorDetails.customer.address.message);

      sendInvalidAddressEvent(address.fullAddress);
      setIsSelectedAddressValid(false);
    }
  };

  const handleSlotChange = (
    slotId: number,
    nextVirtualdeliveryDay: Day,
    dayOfWeek: DayOfWeek
  ) => {
    setDeliverySlotId(slotId);
    setDeliverySlotDayOfWeek(dayOfWeek);
    setStartDate(undefined);
    setEndDate(undefined);
    setHasCheckedDisclaimer(undefined);
    setHolidaySummaryResponse(undefined);

    setUpcomingLockDateForSlot(undefined);
    HOLIDAY_CLIENT.getNextForecastDeliveryDateForSlot(slotId).then(
      setUpcomingLockDateForSlot
    );
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    setHolidaySummaryResponse(undefined);
    setHasCheckedDisclaimer(undefined);
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    setHolidaySummaryResponse(undefined);
    setHasCheckedDisclaimer(undefined);
  };

  const onSubmitAsync = async () => {
    const request: CreateCustomerAddressHolidayCommand = {
      preferredDeliverySlotId: selectedDeliverySlotId,
      startDeliveryDate: startDate,
      endDeliveryDate: endDate,
      addressSuggestionId: selectedAddress.pafId,
    };

    try {
      const response = await HOLIDAY_CLIENT.createCustomerAddressHoliday(
        request
      );
      if (response.status === 200) {
        showGoodToast('Changes saved.');
      }
    } catch (e) {
      showBadToast('Something went wrong. Please try again');
    } finally {
      window.location.reload();
    }
  };

  const dayOfWeekFilter = (date: Date) =>
    date.getDay() === selectedDeliverySlotDayOfWeek;

  const canSubmit =
    isSelectedAddressValid &&
    selectedDeliverySlotId &&
    hasCheckedDisclaimer &&
    startDate &&
    endDate;

  return (
    <div className="mt-5">
      <h4>Where are you going?</h4>
      <p>
        Let us know where you’re spending your summer holidays so we can deliver
        the goodness to your door.{' '}
      </p>
      <div className="mt-4">
        <div className="mb-2">Address</div>
        <AddressFinder
          onAddressChange={onAddressChange}
          fullAddress={'Type to search'}
        />
        {isSelectedAddressValid && (
          <div className="pt-2 text-primary">
            Great! Your holiday address is in our delivery network! Please note
            that due to public holidays, some areas will not be able to receive
            deliveries on the weekends of 26&nbsp;December&nbsp;2021 and
            2&nbsp;January&nbsp;2022. We will let you know in advance if your
            delivery is impacted.
          </div>
        )}
        {addressErrorMsg && (
          <p className="mt-3 text-danger">{addressErrorMsg}</p>
        )}
      </div>

      {isSelectedAddressValid && (
        <>
          <hr className="my-5" />
          <h4>What is your preferred holiday delivery slot?</h4>
          <div className="mt-4">
            <DeliverySlotSelect
              onChange={handleSlotChange}
              bagId={subProductOptionId}
              pafId={selectedAddress.pafId}
              selectedDeliverySlotId={selectedDeliverySlotId}
              noSlotMessage="Sorry, we don't currently deliver to this area."
            />
            <div className="d-flex flex-nowrap small text-light">
              <div className="d-flex mt-1 mr-2">
                <SvgInfoIcon />
              </div>
              <p>
                Delivery days are subject to change based on public holidays. We
                will let you know of any changes in advance.
              </p>
            </div>
          </div>
        </>
      )}
      {isSelectedAddressValid && selectedDeliverySlotId && (
        <div className="mt-5">
          <h4>Which deliveries would you like made to your holiday address?</h4>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            minDate={upcomingLockDate}
            filterDate={dayOfWeekFilter}
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
            disabled={isNil(upcomingLockDate)}
          />
          {isLoadingSummary && <Spinner className="py-5 my-5" />}
        </div>
      )}
      {holidaySummaryResponse && selectedAddress && (
        <HolidaySummary
          defaultAddress={holidaySummaryResponse.defaultAddress}
          firstHolidayDeliveryDate={
            holidaySummaryResponse.firstHolidayDeliveryDate
          }
          lastHolidayDeliveryDate={
            holidaySummaryResponse.lastHolidayDeliveryDate
          }
          lastDeliveryDateToOriginalAddress={
            holidaySummaryResponse.lastDeliveryDateToOriginalAddress
          }
          resumingDeliveryDateToDefaultAddress={
            holidaySummaryResponse.resumingDeliveryDateToDefaultAddress
          }
          holidayDeliveryPeriodMessage={
            holidaySummaryResponse.holidayDeliveryPeriodMessage
          }
          holidayAddress={selectedAddress.fullAddress}
          checkedDisclaimer={hasCheckedDisclaimer}
          onDisclaimerChange={() =>
            setHasCheckedDisclaimer(!hasCheckedDisclaimer)
          }
        />
      )}
      <div className="w-100 mt-5">
        <div className="d-flex-grow d-md-inline-flex">
          <AsyncButton
            className={classnames(
              'btn btn-primary btn-block rounded-lg',
              'mt-3 mr-3',
              !canSubmit && 'disabled'
            )}
            disabled={!canSubmit}
            onClickAsync={onSubmitAsync}
            data-category="holiday-address-changes"
            data-label="confirm-address-change"
            data-action="click"
          >
            Confirm My Holiday Deliveries
          </AsyncButton>
        </div>
        <div className="d-flex-grow d-md-inline-flex">
          <button
            className={classnames(
              'btn btn-secondary btn-block rounded-lg',
              'mt-3 px-5'
            )}
            onClick={navman.yourAccount}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
