import React from 'react';

const RightChevronIcon: React.FunctionComponent<
  React.SVGAttributes<SVGElement>
> = props => (
  <svg
    fill="#78a12e"
    color="#78a12e"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="8.59 6 7.41 12"
    width="16"
    height="16"
    {...props}
  >
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
  </svg>
);

export default RightChevronIcon;
