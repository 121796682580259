import React from 'react';
import {connect} from 'react-redux';
import {AppState, OrdersPageState} from '../../redux/state';
import {PlanYourWeekPage} from './PlanYourWeekPage';
import Spinner from '../shared/components/Spinner';
import {Action} from 'redux';
import {setSelectedDayThunk} from '../../redux/thunks/setSelectedDayThunk';
import {getQueryParams} from './PlanYourWeekQueryParams';
import {format} from 'date-fns';
import {
  Frequency,
  OrderAccountClient, OrderWithRecipesAndExtras,
  SubscriptionClient,
  SubscriptionStatus,
} from '../shared/CoreClient';
import SubscriptionSelect, {
  BagInformation,
} from '../shared/components/SubscriptionSelect';
import {fetchOrderPageThunk} from '../../redux/thunks/fetchOrderPageThunk';
import { globalSettings as settings } from 'config';
import {navman} from '../../navigator';
import {selectCurrentOrder} from '../../redux/selectors/orderPageSelectors';
import {showBadToast} from '../toast/ToastMessages';
import {isEmpty} from 'lodash';
import { formatToNzTimeZone } from 'lib/date/dateFormat';
import { SessionStorageKey, removeFromSessionStorage } from 'redux/sessionStorage';
import { fetchTracking, ReceiveTrackingAction } from '../../redux/actions/sharedActions/trackingAction';
import { useAlertToastContext } from 'app/shared/hooks';

interface StoreProps {
  subscriptions: Array<BagInformation>;
  nextAvailableDeliveryWeek: Date;
  hasDeliveriesLoaded: boolean;
  isDemandLocked: boolean;
  order?: OrderWithRecipesAndExtras;
}

interface DispatchProps {
  fetchDeliveries(targetWeek?: string, subNumber?: string): Promise<void>;
  fetchTracking: ()=>Promise<ReceiveTrackingAction>
}

type ContainerProps = StoreProps & DispatchProps;

const getNextAvailableDeliveryDate = async (): Promise<Date> => {
  const deliveriesClient = new OrderAccountClient(settings.bffHost);
  return await deliveriesClient
    .getLegacySubscriptionState()
    .then(s => s.nextAvailableDeliveryDate);
};


const getActiveSubscriptions = async (): Promise<Array<BagInformation>> => {
  const subscriptionClient = new SubscriptionClient(settings.bffHost);
  return await subscriptionClient.getSubscriptions().then(subscriptions => {
    return subscriptions
      .filter(s => s.status === SubscriptionStatus.Active)
      .map(s => ({
        id: s.subscriptionId,
        subscriptionNumber: s.subscriptionNumber,
        frequency: s.frequency,
        name: s.primaryOptionName,
      }));
  });
};

export const PlanYourWeekPageLoaderUnconnected: React.FC<ContainerProps> = ({
  subscriptions,
  nextAvailableDeliveryWeek,
  hasDeliveriesLoaded,
  fetchDeliveries,
  isDemandLocked,
  order,
  fetchTracking,
}) => {
  const queryParams = getQueryParams();

  if (queryParams.week && isDemandLocked) {
    showBadToast(
      `Due to high demand you won't be able to make changes to your delivery for this week`
    );
    navman.yourAccount();
  }

  const [week, setWeek] = React.useState<string>(queryParams.week);
  const [subNumber, setSubNumber] = React.useState<string>(
    queryParams.subscriptionNumber
  );

  const [activeSubscriptions, setActiveSubscriptions] = React.useState<
    Array<BagInformation>
  >(subscriptions);

  const [
    showSubscriptionSelection,
    setShowSubscriptionSelection,
  ] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { closeToast } = useAlertToastContext();


  const processSubscriptions = (subscriptions: Array<BagInformation>) => {
    if (subscriptions.length === 1) {
      setSubNumber(subscriptions[0].subscriptionNumber);
    } else {
      setShowSubscriptionSelection(true);
    }
  };

  React.useEffect(() => {
    if (!week) {
      if (nextAvailableDeliveryWeek) {
        setWeek(formatToNzTimeZone(nextAvailableDeliveryWeek, 'W'));
      } else {
        getNextAvailableDeliveryDate().then(date => {
          setWeek(formatToNzTimeZone(date, 'W'));
        });
      }
    }

    if (!subNumber) {
      if (activeSubscriptions) {
        processSubscriptions(activeSubscriptions);
      } else {
        getActiveSubscriptions().then(subscriptions => {
          const filteredSubs = subscriptions.filter(
            s => s.frequency !== Frequency.OneOff
          );
          setActiveSubscriptions(filteredSubs);
          if (!isEmpty(filteredSubs)) {
            processSubscriptions(filteredSubs);
          } else {
            // go to deliveries tab if customer has no subs
            navman.yourAccount();
          }
        });
      }
    }

    closeToast();
  }, []);

  React.useEffect(() => {
    if (!isLoading && week && subNumber) {
      setIsLoading(true);
      fetchDeliveries(week, subNumber).then(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [week, subNumber, window.location.search]);

  React.useEffect(() => {
    //on component dismount clear selection's session storage data
    return () => {
      const sessionStorageSelectionKey = {
        storage: SessionStorageKey.PLAN_YOUR_WEEK_SELECTION,
      };
      const addressPreviewKey = {orderKey: order?.orderKey, type: 'addressReFetch'};
      removeFromSessionStorage(sessionStorageSelectionKey);
      removeFromSessionStorage(addressPreviewKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (activeSubscriptions && showSubscriptionSelection) {
    return (
      <SubscriptionSelect
        subscriptions={activeSubscriptions}
        onClick={subId => {
          setShowSubscriptionSelection(false);
          setSubNumber(
            activeSubscriptions.find(s => s.id === subId).subscriptionNumber
          );
        }}
      />
    );
  }

  if (hasDeliveriesLoaded && !isLoading) {
    return <PlanYourWeekPage targetWeek={week} subNumber={subNumber} order={order} fetchTracking={fetchTracking}/>;
  } else {
    return <Spinner />;
  }
};

const mapStateToProps = (
  appState: AppState<OrdersPageState>,
  props
): ContainerProps => {
  const {pageState} = appState;
  const subscriptions: Array<BagInformation> =
    pageState &&
    pageState.subscriptions &&
    pageState.subscriptions
      .filter(s => s.status === SubscriptionStatus.Active)
      .map(s => ({
        id: s.subscriptionId,
        subscriptionNumber: s.subscriptionNumber,
        frequency: s.frequency,
        name: s.primaryOptionName,
      }));

  const currentOrder = selectCurrentOrder(appState);
  return {
    ...props,
    subscriptions,
    nextAvailableDeliveryWeek: appState.pageState.nextAvailableDeliveryDate,
    hasDeliveriesLoaded: appState.pageState.orders != null,
    isDemandLocked: currentOrder && currentOrder.isDemandLocked,
    order: currentOrder,
  };
};

export const PlanYourWeekPageLoader = connect(mapStateToProps, dispatch => {
  return {
    fetchTracking: async ()=> dispatch((fetchTracking as unknown) as Action),
    fetchDeliveries: async (targetWeek?: string, subNumber?: string) => {
      /*
        we cast this to any because the interfaces here don't
        match (we are on an older version of Redux and Thunk
        @TODO bump version of both and remove this cast when upgrading

        as of writing we have
          "redux": "3.6.0",
          "redux-thunk": "2.2.0",
       */
      const queryParams = new URLSearchParams(window.location.search);
      const paramPromoCode = queryParams.get('promoCode');

      let week: Date;
      try {
        if (targetWeek) {
          if (targetWeek[0] !== 'W') {
            throw new Error(
              `Expected a date in WEEK form e.g. W2021-07-11 received ${targetWeek} instead`
            );
          } else {
            week = new Date(targetWeek.substring(1));
          }
        } else {
          week = null;
        }
      } catch (e) {
        console.log(e);
        navman.yourAccount();
      }

      await dispatch(
        (fetchOrderPageThunk(
          week,
          subNumber,
          paramPromoCode
        ) as unknown) as Action
      );

      if (subNumber && targetWeek) {
        dispatch(
          (setSelectedDayThunk(subNumber, targetWeek) as unknown) as Action
        );
      }

      return;
    },
  };
})(PlanYourWeekPageLoaderUnconnected);
