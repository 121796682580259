import React from 'react';
import Prize from './Prize';
import ordinal from 'ordinal';
import classnames from 'classnames';
import {SvgDeliveryTruckIcon} from '@mfb/lego';
import {CampaignTierDto} from '../../shared/CoreClient';

interface TierProps {
  selectedPrizeId: number;
  tier: CampaignTierDto;
  everyTierOnlyHasOnePrize: boolean;
  isSingleTierCampaign: boolean;
  campaignName: string;
  onClick(e: React.MouseEvent<HTMLDivElement>, t: number, p: number): void;
}

const Tier: React.FunctionComponent<TierProps> = ({
  tier,
  selectedPrizeId,
  everyTierOnlyHasOnePrize,
  isSingleTierCampaign,
  campaignName,
  onClick,
}) => {
  const tierOnlyHasOnePrize = tier.campaignPrizes.length === 1;
  const tierHasMoreThanOnePrize = tier.campaignPrizes.length > 1;

  return (
    <>
      <div className="ml-2 ml-sm-2 d-flex align-items-center">
        <SvgDeliveryTruckIcon color="#77a222" className="mr-1" width="2rem" />
        <h4 className="mb-0">
          {`${ordinal(tier.deliveriesRequired)} delivery reward`}
        </h4>
      </div>
      <div
        className={classnames(
          'd-flex',
          tierHasMoreThanOnePrize && 'justify-content-center flex-nowrap'
        )}
      >
        {tier.campaignPrizes.map(prize => (
          <Prize
            prize={prize}
            tierId={tier.campaignTierId}
            onClick={!everyTierOnlyHasOnePrize && onClick}
            tierOnlyHasOnePrize={tierOnlyHasOnePrize}
            isSingleTierCampaign={isSingleTierCampaign}
            isSelected={selectedPrizeId === prize.campaignPrizeId}
            campaignName={campaignName}
            key={`prizeId-${prize.campaignPrizeId}`}
          />
        ))}
      </div>
    </>
  );
};

export default Tier;
