import { PYWStep } from '@mfb/cookbook';
import {
  CustomerMessageContextState,
  CustomerMessageContextType, TrackingMetaData,
} from '@mfb/lego';
import { DeliveryDateResponse } from 'app/shared/CoreClient';
import {
  DisplayCustomerMessageType,
  displayCustomerMessage,
  getCustomerMessageFromGroup,
} from 'app/shared/CustomerMessageService';
import logger from 'app/shared/Logger';
import { TriggerCode } from 'app/shared/constants';
import { CustomerMessageBulkGroupState } from 'redux/state';
import routeHistory from 'routerHistory';
import { format } from 'date-fns';
import { asUtcString } from '../shared/helper';

const navigate = async (url: string) => {
  if (url) {
    try {
      //since there're no relative paths for pyw we only care for query params
      const _url = new URL(`${window.location.origin}/${url}`);
      routeHistory.history.push(_url.search);
    } catch (e) {
      if (e instanceof Error) {
        logger.error(e, {});
      }
    }
  }
};

/**
 * @default
 * handles displaying CMP for PYW
 * overrides exit events
 */
export const handlePlanYourWeekCustomerMessage = (
  step: PYWStep,
  customerMessageContext: {
    context: React.MutableRefObject<CustomerMessageContextType>;
    state: [
      CustomerMessageContextState,
      React.Dispatch<React.SetStateAction<CustomerMessageContextState>>
    ];
  },
  customerMessageBulkGroupState: Array<CustomerMessageBulkGroupState>,
  overrideExits: React.MutableRefObject<{
    meals: () => void;
    kitchen: () => void;
  }>,
  subscriptionNumber: string,
  deliveryDate: DeliveryDateResponse,
  sku: string,
  trackingMetaData: TrackingMetaData,
) => {
  if(trackingMetaData){
    trackingMetaData.trigger_code = 'mealkit';
    trackingMetaData.customer_message_type = 'Mealkit Planning';
    trackingMetaData.delivery_week = asUtcString(deliveryDate?.weekStarting);
  }

  const displayContext: Omit<DisplayCustomerMessageType, 'triggerCode'> = {
    customerMessageContext,
    customerMessageBulkGroupState,
    subscriptionNumber,
    deliveryDateId: deliveryDate.deliveryDateId,
    onNavigate: navigate,
    gATrackingData: {
      customer_message_trigger: true,
      week: `W${format(new Date(deliveryDate.weekStarting), 'yyyy-MM-dd')}`,
      bag_sku: sku,
      message_journey: 'PlanYourWeek',
    },
    ignoreTriggers: true,
    considerSeen: true,
    trackingMetaData: trackingMetaData,
  };

  if (step === PYWStep.meals) {
    displayContext.gATrackingData.selected_response =
      TriggerCode.PYW_ENTRY_MEALS;
    displayCustomerMessage({
      ...displayContext,
      triggerCode: TriggerCode.PYW_ENTRY_MEALS,
    });

    const isExitMealsCustomerMessageAvailable = getCustomerMessageFromGroup({
      ...displayContext,
      triggerCode: TriggerCode.PYW_EXIT_MEALS,
    });

    if (isExitMealsCustomerMessageAvailable) {
      displayContext.gATrackingData.selected_response =
        TriggerCode.PYW_EXIT_MEALS;
      //set override exit meals callback
      overrideExits.current.meals = () => {
        displayCustomerMessage({
          ...displayContext,
          triggerCode: TriggerCode.PYW_EXIT_MEALS,
        });
        overrideExits.current.meals = undefined;
      };
    }
  } else if (step === PYWStep.kitchen) {
    displayContext.gATrackingData.selected_response =
      TriggerCode.PYW_ENTRY_KITCHEN;
    displayCustomerMessage({
      ...displayContext,
      triggerCode: TriggerCode.PYW_ENTRY_KITCHEN,
    });

    const isExitKitchenCustomerMessageAvailable = getCustomerMessageFromGroup({
      ...displayContext,
      triggerCode: TriggerCode.PYW_EXIT_KITCHEN,
    });

    if (isExitKitchenCustomerMessageAvailable) {
      displayContext.gATrackingData.selected_response =
        TriggerCode.PYW_EXIT_KITCHEN;
      //set override exit kitchen callback
      overrideExits.current.kitchen = () => {
        displayCustomerMessage({
          ...displayContext,
          triggerCode: TriggerCode.PYW_EXIT_KITCHEN,
        });
        overrideExits.current.kitchen = undefined;
      };
    }
  }
};
