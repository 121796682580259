import {format as dateFnsFormat, isDate, isValid} from 'date-fns';

/**
 * Whats the point of this to date format? The dates we have
 * in the front end are either strings (casted as Dates) or actual dates.
 * You have our nswag setup to thank for that.
 *
 * Because of this polymorphic property it is much easier to do this check
 * as a bandaid solution as we try to transition away from this weirdness.
 *
 * @param dateArg
 * @param format
 */
export const dateFormat = (
  dateArg: Date | string,
  format = 'yyyy-MM-dd'
): string => {
  const date = isDate(dateArg) ? (dateArg as Date) : new Date(dateArg);
  if (isValid(date)) {
    return dateFnsFormat(date, format);
  } else {
    throw new Error('Provided invalid date ' + dateArg);
  }
};

export const monthStringMapping = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
}

//Converts a date to a string in the format yyyy-DD-mm with NZ time zone applied.
//Adds included prefix to string;
export const formatToNzTimeZone = 
(dateArg: Date | string, prefix = ''): string => 
{
  return `${prefix}${new Date(dateArg).toLocaleDateString('fr-CA', {
    timeZone: 'Pacific/Auckland',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })}`;
}
