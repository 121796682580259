import React from 'react';
import {COLOR_CODE} from '../../../shared/constants';
import {SvgCalendarIcon} from '@mfb/lego';
import {NotificationResponse} from '../../../shared/CoreClient';
interface HolidayNotificationsProps {
  notification?: NotificationResponse;
}
export const HolidayNotifications: React.FC<HolidayNotificationsProps> = ({
  notification,
}) => (
  <div className="media text-left alert alert-warning pt-4 pr-4 pl-4 pb-2 test-delivery-message">
    <SvgCalendarIcon
      color={COLOR_CODE.DARK_YELLOW}
      width={21}
      height={21}
      className="mr-2"
    />
    <div className="media-body ml-2">
      <h4>{notification.title}</h4>
      <p>{notification.message}</p>
    </div>
  </div>
);
