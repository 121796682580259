import {
  OrderWithRecipesAndExtras,
  RecipeSelectionMode,
} from 'app/shared/CoreClient';
import logger from 'app/shared/Logger';
import { formatDistanceToNowStrict } from 'date-fns';
import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { OrderStatus } from './deliveryWeeks/OrderHeader';

const Container = styled.p`
  margin: 0;
  margin-top: 8px;
  font-size: ${(c) => c.theme.typography.fontSize[400]};
  font-weight: ${(c) => c.theme.typography.fontWeight[400]};
  line-height: 1.5;

  color: ${(c) => c.theme.colors.neutral[900]};

  span {
    font-weight: ${(c) => c.theme.typography.fontWeight[700]};
  }
`;

interface Props {
  currentOrder: OrderWithRecipesAndExtras;
  orderStatus: OrderStatus;
  isCanOnlyAddExtras?: boolean;
}

export const TimeLeftToChooseMeals: React.FC<Props> = ({
  currentOrder,
  orderStatus,
  isCanOnlyAddExtras,
}) => {
  const timeLeft = formatDistanceToNowStrict(
    new Date(currentOrder.deliveryOrderDate),
    {
      roundingMethod: 'floor',
    }
  );

  const getTimeLeftToEditAddress = () => {
    try {
      if (
        currentOrder.orderState &&
        currentOrder.orderState.canChangeDeliveryShipping
      ) {
        const timeLeftToEditAddress =
          currentOrder.orderState.canChangeDeliveryShipping.effectiveUntilDate;
        return formatDistanceToNowStrict(new Date(timeLeftToEditAddress), {
          roundingMethod: 'floor',
        });
      } else {
        return '';
      }
    } catch (ex) {
      logger.error(ex, {});
    }
  };

  switch (orderStatus) {
    case OrderStatus.SKIPPED_PAST_DUE: {
      return (
        <Container>
          {`Don't worry, we have some other delicious recipes coming up that we think you'll love.`}
        </Container>
      );
    }
    case OrderStatus.SKIPPED: {
      return (
        <Container>
          <span data-testid={'skipped-label'}>{timeLeft}</span>&nbsp;left to
          unskip.
        </Container>
      );
    }
    case OrderStatus.DELIVERED: {
      return (
        <Container data-testid={'delivered-label'}>
          Your delivery has been received.
        </Container>
      );
    }
    case OrderStatus.ORDERED: {
      return (
        <Container data-testid={'ordered-label'}>
          <span>{getTimeLeftToEditAddress()}</span>&nbsp;left to edit your
          delivery details under 'My Delivery'.
        </Container>
      );
    }
    case OrderStatus.CONFIRMED: {
      return (
        <Container data-testid={'confirmed-label'}>
          Your delivery is on the way.
        </Container>
      );
    }
    case OrderStatus.COMING_UP: {
      const key = isCanOnlyAddExtras
        ? 'deliveries.extraSelection.choiceDuration'
        : 'deliveries.recipeSelection.choiceDuration';
      return (
        <Container data-testid={'coming-up-label'}>
          <Trans
            i18nKey={key}
            values={{ days: timeLeft }}
            components={{ 1: <strong /> }}
          />
        </Container>
      );
    }
    case OrderStatus.CREATING: {
      return (
        <Container data-testid={'creating-label'}>
          Please check back shortly.
        </Container>
      );
    }
    default:
      return (
        <Container data-testid={'no-recipes-label'}>
          We're still working on the meals for this week..
        </Container>
      );
  }
};
