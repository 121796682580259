/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {
  CancelSubscriptionClient,
  CancelSubscriptionModel,
  ICancelSubscriptionClient,
  ISubscriptionClient,
  SubscriptionClient,
} from '../../shared/CoreClient';
import { globalSettings as settings } from 'config';
import Spinner from '../../shared/components/Spinner';
import {
  BaseApiConnector,
  getComponentName,
} from '../../shared/BaseApiConnector';

export interface CancelSubscriptionApi extends ICancelSubscriptionClient {
  /**
   * NOTE: cancelSubscription and updateSubscription return an AccountSettings model as the user should be
   * returned to the account settings page after calling it.
   */
  cancelSubscription: ISubscriptionClient['cancelSubscription'];
  updateSubscription: ISubscriptionClient['updateSubscription'];
}

export interface CancelSubscriptionProps {
  cancelSubscriptionModel: CancelSubscriptionModel;
  cancelSubscriptionApi: CancelSubscriptionApi;
  isRefreshingAccountSettings: boolean;
}

type AnyReactComponent<T> =
  | React.ComponentClass<T>
  | React.FunctionComponent<T>;

/**
 * Higher-Order Component to connect a component to the CancelSubscription page model.
 *
 * @param   selectSubscriptionId      selector function to get the id of the subscription to connect to
 * @example
 * // To connect MyComponent, getting the subscriptionId from a route param
 * connectCancelSubscription(props => Number(props.match.params.subscriptionId))(MyComponent)
 */
const connectCancelSubscription = <TProps extends {}>(
  selectSubscriptionId: (props: TProps) => number
) => (
  WrappedComponent: AnyReactComponent<TProps & CancelSubscriptionProps>,
  LoadingComponent: AnyReactComponent<any> = Spinner
) =>
  class extends BaseApiConnector<TProps, {}, CancelSubscriptionModel> {
    static displayName = `connectCancelSubscription(${getComponentName(
      WrappedComponent
    )})`;

    cancelSubscriptionClient = new CancelSubscriptionClient(settings.bffHost);
    subscriptionClient = new SubscriptionClient(settings.bffHost);

    cancelSubscriptionApi: CancelSubscriptionApi;

    constructor(props: TProps) {
      super(props);

      const wrappedClient: ICancelSubscriptionClient = this.wrapClient(
        this.cancelSubscriptionClient
      );

      this.cancelSubscriptionApi = {
        ...wrappedClient,
        // these methods aren't wrapped because they don't return the page model
        // typically methods will return a page model and are already correctly defined in wrappedClient
        cancelSubscription: this.subscriptionClient.cancelSubscription.bind(
          this.subscriptionClient
        ),
        updateSubscription: this.subscriptionClient.updateSubscription.bind(
          this.subscriptionClient
        ),
      };
    }

    componentWillMount() {
      this.refreshModel(this.props);
    }

    componentWillReceiveProps(nextProps: TProps) {
      if (
        selectSubscriptionId(this.props) !== selectSubscriptionId(nextProps)
      ) {
        this.refreshModel(nextProps);
      }
    }

    refreshModel(props: TProps) {
      const subscriptionId = selectSubscriptionId(this.props);

      this.cancelSubscriptionApi.getPageModel(subscriptionId);
    }

    render() {
      if (this.state.model == null) {
        return <LoadingComponent />;
      }

      return (
        <WrappedComponent
          {...this.props}
          cancelSubscriptionModel={this.state.model}
          cancelSubscriptionApi={this.cancelSubscriptionApi}
          isRefreshingAccountSettings={this.state.modelUpdating}
        />
      );
    }
  };

export default connectCancelSubscription;
