import React from 'react';
import Select from 'react-select';
import { RouteComponentProps } from 'react-router-dom';
import connectCancelSubscription, {
  CancelSubscriptionProps,
} from '../../connectors/connectCancelSubscription';
import {
  AccountSettings,
  AccountSubscription,
  AccountUpdateSubscriptionRequestBody,
  AccountValidateProductChangeRequestBody,
  AccountValidateProductChangeResponse,
  BagDisplay,
  CancelSubscriptionRequest,
  DeliveryDate,
  EnrollableCampaign,
  Frequency2,
  OrderAccountClient,
  ProductGroupResponse,
  SubscriptionClient,
} from '../../../shared/CoreClient';
import { otherReason, primaryCancellationReasons } from './cancellationReasons';
import CancelForm from './CancelForm';
import CancelHeader from './CancelHeader';
import { navman } from '../../../../navigator';
import { globalSettings as settings } from 'config';
import Submit, { SubmitProps } from '../../../shared/components/Submit';
import BagChooser from '../../../shared/components/BagRecipes/BagChooser';
import {
  Feeds,
  parseNumberAsFeed,
  productsFilteredByFeeds,
} from '../MySubscriptions/ProductFilter';
import { findSelectedBag } from '../../../shared/components/bagOptions';
import DeliverySlotSelect from '../DeliverySlotSelect';
import {
  showChangeToSelectedBagToast,
  showSubscriptionCancelledToast,
} from '../../../toast/ToastMessages';
import { connect } from 'react-redux';
import {
  DispatcherProps,
  mapDispatchToProps,
} from '../../containers/accountSettingsContainer';
import { ReceivePageStateAction } from '../../../../redux/actions/sharedActions/pageStateAction';
import { find } from 'lodash';
import TrackingProps from '../../../shared/TrackingProps';
import { ResponsiveImage, usePromiseConfirmationModal } from '@mfb/lego';
import { getSubmitStage } from '../../../shared/components/SubmitStage';
import { getIsIOSWebview } from '../../../shared/getIsIOSWebview';
import { AppState } from '../../../../redux/state';
import MobileStickyHeaderProxy from 'app/shared/components/MobileStickyHeader/MobileStickyHeaderProxy';

interface RouteParams {
  subscriptionId: string;
}

interface Props
  extends RouteComponentProps<RouteParams>,
    CancelSubscriptionProps {
  pageState: AccountSettings;
  appState: AppState;
}

export interface FormState {
  reason?: string;
  reasonError?: string;
  cancellationComments?: string;
  cancellationCommentsError?: string;
}

interface State {
  form: FormState;
  galleryBagId: number;
  isCancelling: boolean;
  isChangingBag: boolean;
  selectedProductFilterValue: Feeds;
  deliverySlotId: number;
  hasUpdatedDeliverySlot: boolean;
  enrollableCampaign?: EnrollableCampaign;

  subscription: AccountSubscription;
  /**
   * Currently just an array of invalid extras set
   * when you choose a bag
   */
  invalidExtras: Array<string>;
  nextBagName: string;
  nextBagItemNumber: string;
  incompatibleSubscriptionOverrides: boolean;
}

interface ConfirmModalProps {
  invalidExtras: Array<string>;
  bagName: string;
  incompatibleSubscriptionOverrides: boolean;
}

const action = 'click';
const category = 'subscription';

const submitTrackingWithNoReasonSelected: TrackingProps = {
  category,
  action,
  label: 'click-cancel-subscription-with-no-reason',
};

const submitTracking: TrackingProps = {
  category,
  action,
  label: 'submit-cancel-subscription',
};

const backTracking: TrackingProps = {
  category,
  action,
  label: 'back-cancel-subscription',
};

export const getUpdatedProductResponse = async (
  subscriptionNumber: string,
  newSku: string
): Promise<AccountValidateProductChangeResponse> => {
  const payload: AccountValidateProductChangeRequestBody = { newSku };
  const client = new SubscriptionClient(settings.bffHost);
  const result = await client.validateSubscriptionChange(
    subscriptionNumber,
    payload
  );
  return result;
};

const getProductsGroups = async (): Promise<Array<ProductGroupResponse>> => {
  const deliveriesClient = new OrderAccountClient(settings.bffHost);
  const result = await deliveriesClient.getProductsGroupedForMarketing();
  return result;
};

class CancelContainer extends React.PureComponent<
  Props & DispatcherProps,
  State
> {
  constructor(props: Props & DispatcherProps) {
    super(props);

    const { cancelSubscriptionModel, match, pageState } = props;
    const { bagSelection, suggestedBagId, bagName } = cancelSubscriptionModel;

    const subscription = pageState.subscriptions.find(
      (s) => s.id === Number(match.params.subscriptionId)
    );

    const bagDisplay = bagSelection;
    const numberOfPeopleToFeed = parseNumberAsFeed(
      cancelSubscriptionModel.numberOfPeopleToFeed
    );
    const defaultSelection = productsFilteredByFeeds(
      bagDisplay,
      numberOfPeopleToFeed
    ).filter((a) => a.name !== bagName);

    let hasValidSuggestion = false;
    if (suggestedBagId != null) {
      const validBag = find(bagSelection, { id: suggestedBagId });
      if (validBag) {
        hasValidSuggestion = true;
      } else {
        const err = new Error(
          `Bag(${bagName}) was suggested an invalid ProductOptionId(${suggestedBagId}).`
        );

        console.warn(err);
      }
    }

    this.state = {
      form: {},
      galleryBagId: hasValidSuggestion
        ? suggestedBagId
        : defaultSelection.length && defaultSelection[0].id,
      isCancelling: false,
      isChangingBag: false,
      selectedProductFilterValue: numberOfPeopleToFeed,
      deliverySlotId: null,
      hasUpdatedDeliverySlot: false,
      subscription: subscription,
      invalidExtras: [],
      nextBagName: '',
      nextBagItemNumber: '',
      incompatibleSubscriptionOverrides: false,
    };
  }

  getDeliveryDates = async (): Promise<Array<DeliveryDate>> => {
    const subscriptionClient = new SubscriptionClient(settings.bffHost);
    return await subscriptionClient.deliveryDates(
      this.state.subscription.primaryBagId
    );
  };

  checkForProductChangeValidation = async (bagId: number): Promise<void> => {
    const { bagSelection } = this.props.cancelSubscriptionModel;
    const subNumber = this.state.subscription.subscriptionNumber;
    const nextBag = bagSelection.find((b) => b.id == bagId);
    const productChangeValidationResponse = await getUpdatedProductResponse(
      subNumber,
      nextBag.itemNumber
    );
    const incompatibleExtras =
      productChangeValidationResponse &&
      productChangeValidationResponse.incompatibleExtras.map(
        (e) => e.productName
      );

    const incompatibleSubscriptionOverrides =
      productChangeValidationResponse &&
      productChangeValidationResponse.incompatibleSubscriptionOverrides;

    this.setInvalidExtras(incompatibleExtras);
    this.setIncompatibleSubOverrides(incompatibleSubscriptionOverrides);
    this.setNextBagName(nextBag.name);
    this.setNextBagItemNumber(nextBag.itemNumber);
  };

  componentDidMount = async (): Promise<void> => {
    this.state.galleryBagId &&
      (await this.checkForProductChangeValidation(this.state.galleryBagId));
  };

  handleBack = (): void => {
    navman.myOrders();
  };

  handleEditSubscription = (): void => {
    navman.editSubscription(this.props.cancelSubscriptionModel.subscriptionId);
  };

  handleChangeFormField = <K extends keyof FormState>(
    field: K,
    value: FormState[K]
  ): void => {
    this.setState((state) => {
      const newForm: FormState = {
        ...state.form,
        // Redundant "as keyof FormState" needed due to https://github.com/Microsoft/TypeScript/issues/14473
        [field as keyof FormState]: value,
      };

      if (newForm.reason) {
        newForm.reasonError = undefined;
      }

      if (newForm.cancellationComments) {
        newForm.cancellationCommentsError = undefined;
      }

      return {
        form: newForm,
      };
    });
  };

  handleSelectBag = (galleryBagId: number): void => {
    this.setState({ galleryBagId });
  };

  handleDeliverySlot = (deliverySlotId: number): void => {
    this.setState({ deliverySlotId });
  };

  handleChangeToSelectedBag = async (): Promise<void> => {
    const { isCancelling, isChangingBag, enrollableCampaign } = this.state;
    const bags = this.props.cancelSubscriptionModel.bagSelection;
    const bag = bags.find((b) => {
      return b.id === this.state.galleryBagId;
    });

    if (isCancelling || isChangingBag) {
      return;
    }
    const request: AccountUpdateSubscriptionRequestBody = {
      sku:
        this.state.nextBagItemNumber ||
        this.state.subscription.productOptionSku,
      deliverySlotId: this.state.deliverySlotId,
      frequency: this.state.subscription.primaryFrequency
        .value as unknown as Frequency2,
    };

    this.setState({ isChangingBag: true });
    try {
      const client = new SubscriptionClient(settings.bffHost);
      await client.updateSubscriptionV2(
        this.state.subscription.subscriptionNumber,
        request
      );

      if (enrollableCampaign) {
        navman.campaignModal(enrollableCampaign.campaignCodes[0]);
      } else {
        navman.yourAccount();
      }
      showChangeToSelectedBagToast();
    } catch (err) {
      this.setState({ isChangingBag: false });
      throw err;
    }
  };

  handleSubmit = async (): Promise<void> => {
    const { cancelSubscriptionModel } = this.props;
    const { form, isCancelling, isChangingBag } = this.state;

    if (isCancelling || isChangingBag) {
      return;
    }

    if (!form.reason) {
      this.setState({
        form: {
          ...this.state.form,
          reasonError: 'Please select a reason.',
        },
      });

      return;
    }

    if (
      form.reason &&
      form.reason === otherReason &&
      !form.cancellationComments
    ) {
      this.setState({
        form: {
          ...this.state.form,
          cancellationCommentsError: 'Please tell us why.',
        },
      });

      return;
    }

    const payload: CancelSubscriptionRequest = {
      subscriptionId: cancelSubscriptionModel.subscriptionId,
      lastDeliveryDateId: undefined, // TODO
      cancellationReason: form.reason,
      cancellationComments: form.cancellationComments,
    };

    this.setState({ isCancelling: true });

    try {
      const result = (await this.props.cancelSubscription(
        payload
      )) as ReceivePageStateAction;
      const accountSettingsModel = result.payload as AccountSettings;
      if (getIsIOSWebview()) {
        navman.backToApp();
      } else {
        navman.myOrders(accountSettingsModel);
      }
      showSubscriptionCancelledToast(
        this.props.cancelSubscriptionModel.bagName
      );
    } catch (err) {
      this.setState({ isCancelling: false });
      throw err;
    }
  } 

  shouldShowComments = (form: FormState): boolean =>
    form.reason === otherReason;

  handleProductFilterChange = (selected: Select.Option): void => {
    const bags = this.props.cancelSubscriptionModel.bagSelection;
    const value = Number(selected.value);
    const defaultSelection = productsFilteredByFeeds(bags, value);

    if (defaultSelection.length > 0) {
      this.setState({
        galleryBagId: defaultSelection[0].id,
        selectedProductFilterValue: parseNumberAsFeed(value),
      });
    }
  };

  setInvalidExtras = (invalidExtras: string[]): void => {
    this.setState({
      invalidExtras: invalidExtras,
    });
  };

  setNextBagName = (nextBagName: string): void => {
    this.setState({ nextBagName });
  };

  setNextBagItemNumber = (nextBagItemNumber: string): void => {
    this.setState({ nextBagItemNumber });
  };

  setIncompatibleSubOverrides = (
    incompatibleSubscriptionOverrides: boolean
  ): void => {
    this.setState({
      incompatibleSubscriptionOverrides: incompatibleSubscriptionOverrides,
    });
  };

  getBagSuggestionList = (): Array<BagDisplay> => {
    const { bagSelection, bagName } = this.props.cancelSubscriptionModel;

    return bagSelection.filter((b) => b.name !== bagName);
  };

  render(): React.ReactNode {
    const { cancelSubscriptionModel, pageState } = this.props;
    const {
      form,
      isCancelling,
      isChangingBag,
      galleryBagId,
      selectedProductFilterValue,
      deliverySlotId,
    } = this.state;

    const showSuggestedBag = settings.isMyFoodBag && galleryBagId != null;
    const selectedBag = findSelectedBag(
      cancelSubscriptionModel.bagSelection,
      galleryBagId
    );

    const enrollableCampaign =
      pageState &&
      pageState.enrollableCampaigns &&
      pageState.enrollableCampaigns.find(
        (ec) => ec.optionIdWhitelist.includes(galleryBagId) && ec.isPromoted
      );

    const {
      bannerUrlSmall,
      bannerUrlMedium,
      bannerUrlLarge,
      campaignGroupName,
    } = enrollableCampaign || {};

    this.setState({ enrollableCampaign });

    const onSubmitCb = (e) => {
      e.preventDefault();
      this.handleChangeToSelectedBag();
    };

    return (
      <div data-test="cancel-sub-survey">
        <MobileStickyHeaderProxy
          onBack={
            this.props.appState.features.dashboardV2
              ? undefined
              : navman.accountSettings
          }
          hiddenUp="md"
          backTracking={backTracking}
        />
        <CancelHeader
          showEditPrompt={!showSuggestedBag}
          onEditSubscription={this.handleEditSubscription}
        />
        {showSuggestedBag && (
          <form className="mb-3" onSubmit={onSubmitCb}>
            <div className="form-group">
              <h3 className="mt-4 mb-4">This might suit you better</h3>
            </div>
            <div className="form-group">
              <BagChooser
                selectedProductFilterValue={selectedProductFilterValue}
                onProductFilterChange={(option: Select.Option) =>
                  this.handleProductFilterChange(option)
                }
                bagSelection={this.getBagSuggestionList()}
                optionId={galleryBagId}
                onSelectBag={async (bagId) => {
                  await this.checkForProductChangeValidation(bagId);
                  this.handleSelectBag(bagId);
                }}
                promotedCampaignName={campaignGroupName}
              />
            </div>
            <DeliverySlotSelect
              onChange={this.handleDeliverySlot}
              bagId={galleryBagId}
              selectedDeliverySlotId={deliverySlotId}
            />
            <div className="form-group mt-4 mb-0">
              {selectedBag && (
                <ChangeToBagWithConfirmationModal
                  tracking={{
                    category: 'subscription',
                    action: 'change-bag-instead-of-cancel',
                    label: `${cancelSubscriptionModel.bagName} -> ${selectedBag.name}`,
                  }}
                  label={
                    <span>
                      Change to <wbr />
                      {selectedBag.name}
                    </span>
                  }
                  dataTest="change-bag-instead-of-cancel-button"
                  stage={getSubmitStage(true, isChangingBag)}
                  isSecondary={false}
                  disabled={false}
                  bagName={this.state.nextBagName}
                  invalidExtras={this.state.invalidExtras}
                  onClickAsync={this.handleChangeToSelectedBag}
                  incompatibleSubscriptionOverrides={
                    this.state.incompatibleSubscriptionOverrides
                  }
                />
              )}
              {selectedBag && enrollableCampaign && (
                <ResponsiveImage
                  data-test="campaign-banner"
                  className="pt-4"
                  src={bannerUrlSmall}
                  mdSrc={bannerUrlMedium}
                  lgSrc={bannerUrlLarge}
                />
              )}
            </div>
          </form>
        )}
        <h3 className="mt-4 mb-4">Are you sure you want to cancel?</h3>
        <CancelForm
          form={form}
          reasonOptions={primaryCancellationReasons}
          showComments={this.shouldShowComments(form)}
          onChangeFormField={this.handleChangeFormField}
        />
        <div className="pb-5 mb-5">
          <Submit
            label="Complete Cancellation"
            stage={getSubmitStage(true, isCancelling)}
            tracking={
              form.reason ? submitTracking : submitTrackingWithNoReasonSelected
            }
            dataTest="cancel-subscription-link"
            isSecondary={true}
            className="btn-link text-danger px-0"
            onClick={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}

/**
 * @TODO This really should belong inside the Edit.tsx but thats a class component and icbf to convert it to fc component
 */
const ChangeToBagWithConfirmationModal = (
  props: SubmitProps & ConfirmModalProps
) => {
  const [getConfirmation, confirmationModal] = usePromiseConfirmationModal({
    title: <div style={{ textAlign: 'center' }}>Things you need to know</div>,
    content: (
      <ul>
        {props.invalidExtras.length > 0 && (
          <li>
            These extras are not yet available for {props.bagName}, and will be
            removed
            <ul>
              {props.invalidExtras.map((name) => (
                <li key={name}>{name}</li>
              ))}
            </ul>
          </li>
        )}
        {props.incompatibleSubscriptionOverrides && (
          <li>
            You have made changes to the number of meals you will receive in
            your upcoming delivery, which will be removed by changing bags
          </li>
        )}
      </ul>
    ),
  });
  const wrappedOnSubmit = async () => {
    if (props.incompatibleSubscriptionOverrides || props.invalidExtras.length) {
      if (await getConfirmation()) {
        props.onClickAsync && props.onClickAsync();
      }
      // else do nothing but close modal
    } else {
      props.onClickAsync && props.onClickAsync();
    }
  };
  return (
    <>
      <Submit
        tracking={props.tracking}
        label={props.label}
        dataTest={props.dataTest}
        stage={props.stage}
        onClickAsync={wrappedOnSubmit}
      />
      {confirmationModal}
    </>
  );
};

const mapStateToProps = (state) => state;

export const UnconnectedCancelContainer = CancelContainer;

export const ConnectedToReduxCancelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedCancelContainer);

// The WrappedContainers (e.g. connectCancelSubscription are not connected to the redux store
export const subscriptionsCancel = connectCancelSubscription(
  (props: RouteComponentProps<RouteParams>) =>
    Number(props.match.params.subscriptionId)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)(ConnectedToReduxCancelContainer as any);
