import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { AlertStatus } from '@mfb/lego';

export interface AlertToastContextProps {
  status: AlertStatus;
  isOpen: boolean;
  text: React.ReactNode;
}

export const alertToastMessage = {
  success: 'You have successfully updated your delivery details for',
  warning: 'You are updating your delivery details on public holiday',
  error: 'Lost connection',
  info: 'Your delivery time has been updated',
};

const AlertToastContext =
  createContext<
    [AlertToastContextProps, Dispatch<SetStateAction<AlertToastContextProps>>]
  >(null);

export const AlertToastProvider = (props: React.PropsWithChildren) => {
  const state = useState<AlertToastContextProps>({
    status: AlertStatus.success,
    isOpen: false,
    text: <></>,
  });

  return (
    <AlertToastContext.Provider value={state}>
      {props.children}
    </AlertToastContext.Provider>
  );
};

export const useAlertToastContext = () => {
  const [toast, updateToast] = useContext(AlertToastContext);

  const closeToast = () => {
    updateToast({
      status: AlertStatus.success,
      isOpen: false,
      text: <></>,
    });
  };

  if (!AlertToastContext) {
    throw new Error('Failed to use AlertToastContext');
  }
  return {
    toast,
    updateToast,
    closeToast,
  };
};
