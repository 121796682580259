import React from 'react';

const VouchersIcon: React.FunctionComponent<
  React.SVGAttributes<SVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    width="16"
    height="16"
    {...props}
  >
    <path d="M12.62 8.398v-4.2H9.038l1.478 1.97-1.15.82C8.576 5.918 7.778 4.855 7 3.773 6.22 4.855 5.424 5.917 4.633 6.988l-1.15-.82 1.479-1.97H1.38v4.2h11.239zm0 3.476v-1.378H1.38v1.378h11.24zM4.896 1.377c-.394 0-.69.328-.69.722 0 .393.296.688.69.688.394 0 .69-.295.69-.688 0-.394-.296-.722-.69-.722zm4.207 0c-.395 0-.69.328-.69.722 0 .393.296.688.69.688.394 0 .69-.295.69-.688 0-.394-.296-.722-.69-.722zm3.517 1.411c.788 0 1.38.624 1.38 1.41v7.677c0 .787-.592 1.41-1.38 1.41H1.38c-.788 0-1.38-.623-1.38-1.41V4.199c0-.787.592-1.411 1.38-1.411h1.545c-.066-.23-.132-.46-.132-.689C2.793.951 3.746 0 4.896 0a2.12 2.12 0 0 1 1.742.919L7 1.41 7.36.919A2.12 2.12 0 0 1 9.103 0c1.15 0 2.103.951 2.103 2.1 0 .229-.066.458-.132.688h1.545z" />
  </svg>
);

export default VouchersIcon;
