import React from 'react';
import Icon, {IconProps} from './Icon';
import { globalSettings as settings } from 'config';

class MadeLogo extends Icon {
  static defaultProps: Partial<IconProps> = {
    color: '#78a12e',
    height: 30,
    className: 'mfb-HeaderLogo',
    seasonal: true,
    title: settings.brand,
    width: 33,
  };

  renderIcon() {
    const {className} = this.props;

    return (
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        className={className}
      >
        <g id="Group-33" transform="translate(0.000000, -1.000000)">
          <path
            d="M10.2218,6.781 C7.7278,6.781 6.1158,8.645 6.1158,11.529 L6.1158,23.656 L7.5668,23.656 L7.5668,11.529 C7.5668,9.433 8.5348,8.232 10.2218,8.232 C12.1008,8.232 12.3028,9.97 12.3028,10.718 L12.3028,23.656 L13.7538,23.656 L13.7538,10.718 C13.7538,8.326 12.3678,6.781 10.2218,6.781"
            id="Fill-1"
            fill="#78A23F"
          />
          <path
            d="M22.6944,6.781 C20.2164,6.781 18.4864,8.747 18.4864,11.563 L18.4864,23.656 L19.9374,23.656 L19.9374,11.563 C19.9374,9.539 21.0194,8.232 22.6944,8.232 C24.4424,8.232 24.8084,9.62 24.8084,10.785 L24.8084,23.723 L26.2594,23.723 L26.2594,10.785 C26.2594,8.316 24.8934,6.781 22.6944,6.781"
            id="Fill-3"
            fill="#78A23F"
          />
          <g id="Group-7" transform="translate(5.000000, 0.733900)">
            <g id="Clip-6" />
            <path
              d="M19.461,0.2658 C16.698,0.2658 14.26,1.1848 12.619,2.8158 C11.279,1.2038 9.311,0.2658 7.191,0.2658 C5.39,0.2658 3.795,0.6888 2.432,1.5248 L2.432,0.6878 L0.981,0.6878 L0.981,4.0918 C2.759,2.3588 4.445,1.7168 7.02,1.7168 C9.131,1.7168 10.905,2.7038 11.95,4.3588 L12.503,5.2338 L13.137,4.4158 C14.466,2.7008 16.772,1.7168 19.461,1.7168 C23.58,1.7168 26.13,5.3278 26.13,8.6748 L26.13,22.9888 L27.581,22.9888 L27.581,8.6748 C27.581,4.6308 24.477,0.2658 19.461,0.2658"
              id="Fill-5"
              fill="#78A23F"
              mask="url(#mask-2)"
            />
          </g>
          <g id="Clip-9" />
          <polygon
            id="Fill-8"
            fill="#78A23F"
            mask="url(#mask-4)"
            points="0 23.6549 1.451 23.6549 1.451 1.4219 0 1.4219"
          />
          <path
            d="M16.7979,7.8405 C16.7989,7.8395 16.8009,7.8385 16.8019,7.8375 C17.0549,7.5195 17.0019,7.0555 16.6849,6.8025 C16.6379,6.7655 16.5879,6.7365 16.5369,6.7135 C16.5789,6.4335 16.4789,6.1395 16.2409,5.9505 C16.0039,5.7615 15.6939,5.7285 15.4319,5.8325 C15.3969,5.7875 15.3579,5.7455 15.3119,5.7085 C14.9929,5.4555 14.5299,5.5075 14.2769,5.8255 C14.2769,5.8265 14.2759,5.8275 14.2749,5.8285 C13.7529,6.4725 14.3229,7.2985 14.1359,8.3755 C14.1149,8.4945 14.2319,8.5925 14.3439,8.5465 C15.3449,8.1295 16.2509,8.5385 16.7979,7.8405"
            id="Fill-10"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M8.1534,5.1232 C8.4564,5.7382 9.2734,5.5972 10.0154,6.1092 C10.0974,6.1662 10.2124,6.1132 10.2164,6.0132 C10.2504,5.1182 10.8704,4.5792 10.5364,3.9272 C10.5364,3.9262 10.5364,3.9252 10.5354,3.9242 C10.3844,3.6232 10.0184,3.5022 9.7184,3.6532 C9.6744,3.6752 9.6364,3.7032 9.6004,3.7332 C9.4054,3.6042 9.1494,3.5782 8.9254,3.6902 C8.7024,3.8032 8.5704,4.0232 8.5564,4.2572 C8.5114,4.2682 8.4654,4.2822 8.4234,4.3042 C8.1224,4.4552 8.0014,4.8202 8.1524,5.1212 C8.1524,5.1222 8.1534,5.1222 8.1534,5.1232"
            id="Fill-11"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M6.4161,7.1823 L6.4181,7.1823 C6.9181,7.3873 7.3261,6.8763 8.0311,6.7903 C8.1101,6.7813 8.1491,6.6903 8.1001,6.6293 C7.6511,6.0833 7.7361,5.4413 7.1981,5.2323 L7.1951,5.2303 C6.9501,5.1323 6.6711,5.2523 6.5721,5.4983 C6.5591,5.5343 6.5501,5.5703 6.5451,5.6063 C6.3621,5.6333 6.1981,5.7513 6.1251,5.9343 C6.0521,6.1183 6.0891,6.3163 6.2031,6.4613 C6.1831,6.4913 6.1631,6.5233 6.1481,6.5593 C6.0511,6.8053 6.1711,7.0843 6.4161,7.1823"
            id="Fill-12"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M4.9991,2.591 C5.0721,2.318 5.0061,2.014 4.7911,1.8 C4.5761,1.585 4.2731,1.518 4.0001,1.591 C3.9701,1.543 3.9371,1.497 3.8951,1.455 C3.6061,1.168 3.1411,1.168 2.8531,1.455 C2.8531,1.456 2.8521,1.457 2.8511,1.458 C2.2601,2.039 2.7331,2.924 2.4271,3.973 C2.3931,4.089 2.4971,4.2 2.6131,4.166 C3.6551,3.864 4.5111,4.372 5.1321,3.74 C5.1341,3.739 5.1351,3.738 5.1361,3.737 C5.4231,3.45 5.4231,2.984 5.1361,2.696 C5.0941,2.654 5.0481,2.621 4.9991,2.591"
            id="Fill-13"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M4.9991,6.5036 C5.0721,6.2306 5.0061,5.9266 4.7911,5.7116 C4.5761,5.4976 4.2731,5.4306 4.0001,5.5036 C3.9701,5.4556 3.9371,5.4096 3.8951,5.3676 C3.6061,5.0796 3.1411,5.0796 2.8531,5.3676 C2.8531,5.3686 2.8521,5.3696 2.8511,5.3696 C2.2601,5.9516 2.7331,6.8366 2.4271,7.8846 C2.3931,8.0016 2.4971,8.1116 2.6131,8.0786 C3.6551,7.7766 4.5111,8.2846 5.1321,7.6526 C5.1341,7.6516 5.1351,7.6506 5.1361,7.6496 C5.4231,7.3626 5.4231,6.8966 5.1361,6.6086 C5.0941,6.5666 5.0481,6.5326 4.9991,6.5036"
            id="Fill-14"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M4.9991,10.4157 C5.0721,10.1427 5.0061,9.8387 4.7911,9.6237 C4.5761,9.4097 4.2731,9.3427 4.0001,9.4157 C3.9701,9.3677 3.9371,9.3217 3.8951,9.2797 C3.6061,8.9927 3.1411,8.9927 2.8531,9.2797 L2.8511,9.2827 C2.2601,9.8637 2.7331,10.7487 2.4271,11.7977 C2.3931,11.9137 2.4971,12.0237 2.6131,11.9907 C3.6551,11.6887 4.5111,12.1967 5.1321,11.5647 C5.1341,11.5637 5.1351,11.5627 5.1361,11.5617 C5.4231,11.2747 5.4231,10.8087 5.1361,10.5207 C5.0941,10.4787 5.0481,10.4457 4.9991,10.4157"
            id="Fill-15"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M4.9991,14.3278 C5.0721,14.0548 5.0061,13.7508 4.7911,13.5368 C4.5761,13.3218 4.2731,13.2548 4.0001,13.3278 C3.9701,13.2808 3.9371,13.2338 3.8951,13.1918 C3.6061,12.9048 3.1411,12.9048 2.8531,13.1918 C2.8531,13.1928 2.8521,13.1938 2.8511,13.1948 C2.2601,13.7758 2.7331,14.6608 2.4271,15.7098 C2.3931,15.8258 2.4971,15.9368 2.6131,15.9028 C3.6551,15.6008 4.5111,16.1098 5.1321,15.4768 C5.1341,15.4758 5.1351,15.4758 5.1361,15.4748 C5.4231,15.1868 5.4231,14.7208 5.1361,14.4328 C5.0941,14.3918 5.0481,14.3578 4.9991,14.3278"
            id="Fill-16"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M4.9991,18.2404 C5.0721,17.9674 5.0061,17.6634 4.7911,17.4484 C4.5761,17.2344 4.2731,17.1674 4.0001,17.2404 C3.9701,17.1924 3.9371,17.1464 3.8951,17.1044 C3.6061,16.8164 3.1411,16.8164 2.8531,17.1044 L2.8511,17.1074 C2.2601,17.6884 2.7331,18.5734 2.4271,19.6214 C2.3931,19.7384 2.4971,19.8484 2.6131,19.8154 C3.6551,19.5134 4.5111,20.0214 5.1321,19.3894 C5.1341,19.3884 5.1351,19.3874 5.1361,19.3864 C5.4231,19.0994 5.4231,18.6334 5.1361,18.3454 C5.0941,18.3034 5.0481,18.2704 4.9991,18.2404"
            id="Fill-17"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M4.9991,22.1525 C5.0721,21.8795 5.0061,21.5755 4.7911,21.3615 C4.5761,21.1465 4.2731,21.0795 4.0001,21.1525 C3.9701,21.1045 3.9371,21.0585 3.8951,21.0165 C3.6061,20.7295 3.1411,20.7295 2.8531,21.0165 C2.8531,21.0175 2.8521,21.0185 2.8511,21.0195 C2.2601,21.6005 2.7331,22.4855 2.4271,23.5345 C2.3931,23.6505 2.4971,23.7615 2.6131,23.7275 C3.6551,23.4255 4.5111,23.9335 5.1321,23.3015 C5.1341,23.3005 5.1351,23.3005 5.1361,23.2985 C5.4231,23.0115 5.4231,22.5455 5.1361,22.2575 C5.0941,22.2155 5.0481,22.1825 4.9991,22.1525"
            id="Fill-18"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M17.3145,10.4157 C17.3875,10.1427 17.3205,9.8387 17.1065,9.6237 C16.8915,9.4097 16.5875,9.3427 16.3145,9.4157 C16.2855,9.3677 16.2515,9.3217 16.2095,9.2797 C15.9215,8.9927 15.4565,8.9927 15.1685,9.2797 C15.1675,9.2807 15.1675,9.2817 15.1665,9.2827 C14.5755,9.8637 15.0485,10.7487 14.7425,11.7977 C14.7085,11.9137 14.8125,12.0237 14.9285,11.9907 C15.9705,11.6887 16.8255,12.1967 17.4475,11.5647 C17.4485,11.5637 17.4505,11.5627 17.4515,11.5617 C17.7385,11.2747 17.7385,10.8087 17.4515,10.5207 C17.4095,10.4787 17.3625,10.4457 17.3145,10.4157"
            id="Fill-19"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M17.3145,14.3278 C17.3875,14.0548 17.3205,13.7508 17.1065,13.5368 C16.8915,13.3218 16.5875,13.2548 16.3145,13.3278 C16.2855,13.2808 16.2515,13.2338 16.2095,13.1918 C15.9215,12.9048 15.4565,12.9048 15.1685,13.1918 C15.1675,13.1928 15.1675,13.1938 15.1665,13.1948 C14.5755,13.7758 15.0485,14.6608 14.7425,15.7098 C14.7085,15.8258 14.8125,15.9368 14.9285,15.9028 C15.9705,15.6008 16.8255,16.1088 17.4475,15.4778 C17.4485,15.4758 17.4505,15.4758 17.4515,15.4748 C17.7385,15.1868 17.7385,14.7208 17.4515,14.4328 C17.4095,14.3918 17.3625,14.3578 17.3145,14.3278"
            id="Fill-20"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M17.3145,18.2404 C17.3875,17.9674 17.3205,17.6634 17.1065,17.4484 C16.8915,17.2344 16.5875,17.1674 16.3145,17.2404 C16.2855,17.1924 16.2515,17.1464 16.2095,17.1044 C15.9215,16.8164 15.4565,16.8164 15.1685,17.1044 C15.1675,17.1054 15.1675,17.1064 15.1665,17.1074 C14.5755,17.6884 15.0485,18.5734 14.7425,19.6214 C14.7085,19.7384 14.8125,19.8484 14.9285,19.8154 C15.9705,19.5134 16.8255,20.0214 17.4475,19.3894 C17.4485,19.3884 17.4505,19.3874 17.4515,19.3864 C17.7385,19.0994 17.7385,18.6334 17.4515,18.3454 C17.4095,18.3034 17.3625,18.2704 17.3145,18.2404"
            id="Fill-21"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M17.3145,22.1525 C17.3875,21.8795 17.3205,21.5755 17.1065,21.3615 C16.8915,21.1465 16.5875,21.0795 16.3145,21.1525 C16.2855,21.1045 16.2515,21.0585 16.2095,21.0165 C15.9215,20.7295 15.4565,20.7295 15.1685,21.0165 C15.1675,21.0175 15.1675,21.0185 15.1665,21.0195 C14.5755,21.6005 15.0485,22.4855 14.7425,23.5345 C14.7085,23.6505 14.8125,23.7615 14.9285,23.7275 C15.9705,23.4255 16.8255,23.9335 17.4475,23.3015 C17.4485,23.3005 17.4505,23.3005 17.4515,23.2985 C17.7385,23.0115 17.7385,22.5455 17.4515,22.2575 C17.4095,22.2155 17.3625,22.1825 17.3145,22.1525"
            id="Fill-22"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M29.4698,7.8298 C29.4708,7.8288 29.4728,7.8278 29.4738,7.8268 C29.7268,7.5078 29.6738,7.0448 29.3568,6.7918 C29.3098,6.7548 29.2598,6.7258 29.2088,6.7028 C29.2508,6.4228 29.1508,6.1288 28.9128,5.9388 C28.6758,5.7498 28.3668,5.7178 28.1038,5.8218 C28.0688,5.7768 28.0298,5.7348 27.9838,5.6978 C27.6648,5.4438 27.2018,5.4968 26.9488,5.8148 C26.9488,5.8158 26.9478,5.8168 26.9478,5.8168 C26.4248,6.4608 26.9948,7.2878 26.8078,8.3638 C26.7868,8.4828 26.9038,8.5818 27.0158,8.5358 C28.0168,8.1188 28.9228,8.5268 29.4698,7.8298"
            id="Fill-23"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M26.836,4.8894 C26.933,4.5154 26.708,4.1344 26.334,4.0374 C26.28,4.0234 26.226,4.0184 26.172,4.0174 C26.101,3.7584 25.899,3.5424 25.621,3.4714 C25.342,3.3984 25.062,3.4904 24.873,3.6814 C24.826,3.6564 24.776,3.6344 24.722,3.6204 C24.348,3.5244 23.967,3.7484 23.87,4.1214 L23.87,4.1254 C23.666,4.8854 24.481,5.3814 24.734,6.3864 C24.762,6.4974 24.901,6.5374 24.98,6.4534 C25.687,5.7054 26.63,5.7094 26.834,4.8934 C26.835,4.8914 26.835,4.8904 26.836,4.8894"
            id="Fill-24"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M14.256,4.8313 C14.353,4.4573 14.129,4.0763 13.755,3.9793 C13.7,3.9663 13.647,3.9603 13.593,3.9593 C13.522,3.7003 13.32,3.4843 13.041,3.4133 C12.763,3.3403 12.483,3.4323 12.294,3.6233 C12.247,3.5983 12.196,3.5773 12.143,3.5623 C11.769,3.4663 11.388,3.6903 11.291,4.0643 C11.291,4.0653 11.291,4.0663 11.29,4.0673 C11.087,4.8273 11.901,5.3233 12.154,6.3283 C12.183,6.4393 12.321,6.4793 12.401,6.3953 C13.107,5.6473 14.051,5.6513 14.255,4.8353 C14.255,4.8333 14.256,4.8323 14.256,4.8313"
            id="Fill-25"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M20.6046,5.1589 C20.9076,5.7739 21.7246,5.6319 22.4666,6.1449 C22.5486,6.2019 22.6636,6.1489 22.6676,6.0489 C22.7016,5.1529 23.3216,4.6149 22.9886,3.9629 C22.9876,3.9619 22.9876,3.9609 22.9866,3.9589 C22.8356,3.6589 22.4696,3.5379 22.1696,3.6889 C22.1256,3.7109 22.0876,3.7389 22.0516,3.7689 C21.8566,3.6399 21.6016,3.6139 21.3766,3.7259 C21.1536,3.8389 21.0216,4.0589 21.0086,4.2929 C20.9626,4.3039 20.9176,4.3179 20.8746,4.3399 C20.5736,4.4909 20.4526,4.8559 20.6036,5.1569 C20.6036,5.1579 20.6046,5.1579 20.6046,5.1589"
            id="Fill-26"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M19.6495,5.384 C19.6485,5.384 19.6475,5.383 19.6475,5.383 C19.4015,5.284 19.1225,5.404 19.0245,5.65 C19.0095,5.685 19.0025,5.722 18.9965,5.759 C18.8135,5.785 18.6495,5.903 18.5765,6.086 C18.5035,6.27 18.5415,6.468 18.6555,6.613 C18.6335,6.643 18.6145,6.676 18.6005,6.711 C18.5025,6.957 18.6225,7.236 18.8675,7.334 C18.8685,7.334 18.8695,7.334 18.8705,7.335 C19.3695,7.539 19.7775,7.028 20.4835,6.943 C20.5615,6.933 20.6005,6.842 20.5505,6.781 C20.1025,6.236 20.1875,5.593 19.6495,5.384"
            id="Fill-27"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M18.8741,8.426 C18.9271,8.397 18.9281,8.321 18.8751,8.292 C18.4311,8.056 18.2861,7.63 17.9121,7.579 C17.8841,7.573 17.8551,7.569 17.8261,7.57 C17.6241,7.572 17.4621,7.739 17.4651,7.941 C17.4661,7.97 17.4701,7.999 17.4781,8.026 C17.3561,8.098 17.2741,8.23 17.2761,8.381 C17.2791,8.532 17.3641,8.661 17.4871,8.73 C17.4801,8.757 17.4771,8.785 17.4781,8.815 C17.4791,9.017 17.6461,9.179 17.8491,9.176 C17.8791,9.176 17.9091,9.171 17.9371,9.163 C18.2831,9.097 18.4371,8.673 18.8741,8.426"
            id="Fill-28"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M29.9063,10.4157 C29.9793,10.1427 29.9123,9.8387 29.6983,9.6237 C29.4833,9.4097 29.1793,9.3427 28.9063,9.4157 C28.8773,9.3677 28.8433,9.3217 28.8013,9.2797 C28.5133,8.9927 28.0483,8.9927 27.7603,9.2797 C27.7593,9.2807 27.7593,9.2817 27.7583,9.2827 C27.1673,9.8637 27.6403,10.7487 27.3343,11.7977 C27.3003,11.9137 27.4043,12.0237 27.5203,11.9907 C28.5623,11.6887 29.4173,12.1967 30.0393,11.5647 C30.0403,11.5637 30.0413,11.5627 30.0433,11.5617 C30.3303,11.2747 30.3303,10.8087 30.0433,10.5207 C30.0013,10.4787 29.9543,10.4457 29.9063,10.4157"
            id="Fill-29"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M29.9063,14.3278 C29.9793,14.0548 29.9123,13.7508 29.6983,13.5368 C29.4833,13.3218 29.1793,13.2548 28.9063,13.3278 C28.8773,13.2808 28.8433,13.2338 28.8013,13.1918 C28.5133,12.9048 28.0483,12.9048 27.7603,13.1918 C27.7593,13.1928 27.7593,13.1938 27.7583,13.1948 C27.1673,13.7758 27.6403,14.6608 27.3343,15.7098 C27.3003,15.8258 27.4043,15.9368 27.5203,15.9028 C28.5623,15.6008 29.4173,16.1088 30.0393,15.4778 C30.0403,15.4758 30.0413,15.4758 30.0433,15.4748 C30.3303,15.1868 30.3303,14.7208 30.0433,14.4328 C30.0013,14.3918 29.9543,14.3578 29.9063,14.3278"
            id="Fill-30"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M29.9063,18.2404 C29.9793,17.9674 29.9123,17.6634 29.6983,17.4484 C29.4833,17.2344 29.1793,17.1674 28.9063,17.2404 C28.8773,17.1924 28.8433,17.1464 28.8013,17.1044 C28.5133,16.8164 28.0483,16.8164 27.7603,17.1044 C27.7593,17.1054 27.7593,17.1064 27.7583,17.1074 C27.1673,17.6884 27.6403,18.5734 27.3343,19.6214 C27.3003,19.7384 27.4043,19.8484 27.5203,19.8154 C28.5623,19.5134 29.4173,20.0214 30.0393,19.3894 C30.0403,19.3884 30.0413,19.3874 30.0433,19.3864 C30.3303,19.0994 30.3303,18.6334 30.0433,18.3454 C30.0013,18.3034 29.9543,18.2704 29.9063,18.2404"
            id="Fill-31"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
          <path
            d="M29.9063,22.1525 C29.9793,21.8795 29.9123,21.5755 29.6983,21.3615 C29.4833,21.1465 29.1793,21.0795 28.9063,21.1525 C28.8773,21.1045 28.8433,21.0585 28.8013,21.0165 C28.5133,20.7295 28.0483,20.7295 27.7603,21.0165 C27.7593,21.0175 27.7593,21.0185 27.7583,21.0195 C27.1673,21.6005 27.6403,22.4855 27.3343,23.5345 C27.3003,23.6505 27.4043,23.7615 27.5203,23.7275 C28.5623,23.4255 29.4173,23.9335 30.0393,23.3015 C30.0403,23.3005 30.0413,23.3005 30.0433,23.2985 C30.3303,23.0115 30.3303,22.5455 30.0433,22.2575 C30.0013,22.2155 29.9543,22.1825 29.9063,22.1525"
            id="Fill-32"
            fill="#78A23F"
            mask="url(#mask-4)"
          />
        </g>
      </g>
    );
  }
}

export default MadeLogo;
