import { UserManager, MFBUser } from './UserManager';
import { globalSettings as settings } from 'config';
import {
  AccountInfo,
  AuthError,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';

/**
 * Safely retrieves claims from the AccountInfo object.
 * Automatically matches on known prefixes i.e. `extension_` `extention_` etc.
 * @param claim Identity Claim to return
 * @param accountInfo AccountInfo object to extract claims from.
 * @returns claim value or undefined if not exist.
 */
function getTokenClaims<TClaim>(
  claim: string,
  { idTokenClaims }: Pick<AccountInfo, 'idTokenClaims'>
) {
  if (!idTokenClaims) {
    return undefined;
  }

  const claimPrefixes = ['extention_', 'extension_', ''].map(
    (prefix) => `${prefix}${claim}`
  );

  const claims = Object.entries(idTokenClaims);
  const [, claimEntry] = claims.find(([claimKey]) =>
    claimPrefixes.includes(claimKey)
  ) ?? [undefined, undefined];
  return claimEntry as TClaim;
}

class MsalUserManager implements UserManager {
  msalConfig = {
    auth: {
      clientId: `${settings.adb2cUserFlowAccountAppAppId}`, // This is the ONLY mandatory field that you need to supply.
      authority: `https://${settings.adb2cTenantName}.b2clogin.com/${settings.adb2cTenantName}.onmicrosoft.com/${settings.adb2cUserFlowSigninPolicy}`, // Use a sign-up/sign-in user-flow as a default authority
      knownAuthorities: [`${settings.adb2cTenantName}.b2clogin.com`], // Mark your B2C tenant's domain as trusted.
      redirectUri: `${settings.accountWebURL}`, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
      postLogoutRedirectUri: `${settings.adb2cPostLogoutRedirectUri}`, // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            // case LogLevel.Info:
            //   console.info(message);
            //   return;
            // case LogLevel.Verbose:
            //   console.debug(message);
            //   return;
            // case LogLevel.Warning:
            //   console.warn(message);
            //   return;
          }
        },
      },
    },
  };

  loginRequest = {
    scopes: [settings.adb2cAccountAppScopes],
  };

  private msalInstance: PublicClientApplication;
  private static instance: MsalUserManager;

  private constructor() {
    this.msalInstance = new PublicClientApplication(this.msalConfig);
    this.msalInstance.addEventCallback(async (message: EventMessage) => {
      if (message.eventType === EventType.LOGIN_FAILURE) {
        console.log('Error LOGIN_FAILURE');
        console.log(message);
      }
    });
  }

  async handleCustomerRedirectLogout(): Promise<void> {
    await this.msalInstance.logoutRedirect({
      postLogoutRedirectUri: '/#customerRedirect',
    });
  }

  public static async getInstance(): Promise<MsalUserManager> {
    if (!MsalUserManager.instance) {
      MsalUserManager.instance = new MsalUserManager();
      try {
        await this.instance.msalInstance.handleRedirectPromise();
      } catch (error) {
        console.log(error);
        if (error instanceof AuthError) {
          if (error.errorMessage) {
            if (error.errorMessage.indexOf('AADB2C90118') > -1) {
              await this.instance.PasswordReset('');
            }
            //cancel password reset flow
            if (error.errorMessage.indexOf('AADB2C90091') > -1) {
              window.location.href = `${settings.accountWebURL}`;
            }
          }
        }
      }
    } else {
      await this.instance.msalInstance.handleRedirectPromise();
    }
    return MsalUserManager.instance;
  }

  private getAccount(): AccountInfo | null {
    const currentAccounts = this.msalInstance.getAllAccounts();
    if (currentAccounts === null) {
      console.log('No accounts detected');
      return null;
    }

    if (currentAccounts.length > 1) {
      // Add choose account code here
      // console.log(
      //   'Multiple accounts detected, need to add choose account code.'
      // );
      // console.log(currentAccounts);
      //this is not required when switch to default resetpassword flow
      const account = this.getOriginalAccount(currentAccounts);
      // console.log(account);
      return account;
    } else if (currentAccounts.length === 1) {
      // console.log(currentAccounts[0]);
      return currentAccounts[0];
    }

    return null;
  }

  //when returning from custom resetpassword flow, there are 2 accounts in the cookie
  //1.account from B2C_1A_PasswordReset
  //2.account from B2C_1_ACCOUNT_SIGNIN
  //we need keep using the one from B2C_1_ACCOUNT_SIGNIN to get token and communicate with backend
  //this function is to choose the one or the oldest one if cannot find it.
  private getOriginalAccount(accounts: AccountInfo[]): AccountInfo {
    let account = accounts.find(
      (val) =>
        val.homeAccountId.indexOf(
          settings.adb2cUserFlowSigninPolicy.toLowerCase()
        ) > -1
    );
    if (account == undefined) {
      accounts.sort((a, b) =>
        a.idTokenClaims.exp < b.idTokenClaims.exp ? -1 : 1
      );
      account = accounts[0];
    }
    return account;
  }

  private async getTokenRedirect(
    silentRequest: SilentRequest,
    interactiveRequest: RedirectRequest
  ): Promise<string | null> {
    try {
      const response = await this.msalInstance.acquireTokenSilent(
        silentRequest
      );
      return response.accessToken;
    } catch (e) {
      // console.log('silent token acquisition fails.');
      if (e instanceof InteractionRequiredAuthError) {
        // console.log('acquiring token using redirect');
        this.msalInstance
          .acquireTokenRedirect(interactiveRequest)
          .catch(console.error);
      } else {
        console.error(e);
      }
    }

    return null;
  }

  async getCustomerNumber(): Promise<string> {
    const account = this.getAccount();
    if (account !== null) {
      const customerNumber = getTokenClaims('CustomerNumber', account);
      if (customerNumber) return String(customerNumber);
    }
    return '';
  }

  async getBearerToken(): Promise<string> {
    const token = await this.getTokenRedirect(
      {
        ...this.loginRequest,
        account: this.getAccount(),
        forceRefresh: false,
      },
      {
        ...this.loginRequest,
      }
    );
    return token;
  }

  async authenticate(): Promise<MFBUser> {
    try {
      await this.msalInstance.ssoSilent(this.loginRequest);
    } catch (e) {
      // console.log('ssoSilent fails.');
      if (e instanceof InteractionRequiredAuthError) {
        // console.log('acquiring token using redirect');
        const isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (isSafari) {
          await this.handleLoginForSafari();
        } else {
          if (this.getAccount() == null) {
            await this.msalInstance.loginRedirect(this.loginRequest);
          } else {
            // Do nothing, we have an MSAL account now.
          }
        }
      } else {
        console.error(e);
        //this is for migrating from oidc to msal
        await MsalUserManager.instance.logoutToClearSSOCookie();
      }
    }
    return {
      customerNumber: await this.getCustomerNumber(),
    };
  }

  async handleLoginForSafari(): Promise<void> {
    if (window.location.href.indexOf('iosapp=true') > -1) {
      // alert('iOS app');
      //ios app
      //https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-prompt-behavior#interactive-requests-with-promptnone
      if (this.getAccount() == null) {
        // alert('iOS app, getAccount is null');
        await this.msalInstance.loginRedirect({
          ...this.loginRequest,
          prompt: 'none',
        });
      } else {
        const homeId = this.getAccount().homeAccountId;
        // alert(`'iOS app, getAccount has something.  "${homeId}" Whoops.`);
      }
    } else {
      //desktop safari
      // alert('desktop app');
      if (this.getAccount() == null) {
        // alert('desktop app, getAccount is null');
        await this.msalInstance.loginRedirect(this.loginRequest);
      } else {
        // alert('desktop app, getAccount has something.  Whoops.');
      }
    }
  }

  async logoutToClearSSOCookie(): Promise<void> {
    // alert('logging out');
    await this.msalInstance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin + '#redirect',
    });
  }

  async logout(): Promise<void> {
    await this.msalInstance.logoutRedirect({
      postLogoutRedirectUri: settings.adb2cPostLogoutRedirectUri,
    });
  }

  async PasswordReset(email: string): Promise<void> {
    await MsalUserManager.instance.msalInstance.loginRedirect({
      scopes: [settings.adb2cAccountAppScopes],
      authority: `https://${settings.adb2cTenantName}.b2clogin.com/${settings.adb2cTenantName}.onmicrosoft.com/${settings.adb2cPasswordResetPolicy}`,
      loginHint: email,
    });
  }
}

export default MsalUserManager;
