import React from 'react';
import {PageProperties} from '..';
import {LegacyOrdersResponse} from '../../shared/CoreClient';
import Spinner from '../../shared/components/Spinner';
import ConnectedDeliveryContainerWithTabs from './DeliveryContainerWithTabs';
import {getQueryStringParams} from '../../shared/getQueryString';
import {CampaignSignUpModalContainer} from '../../campaign/components/CampaignSignUpModalContainer';

class DeliveryLoadingWrapper extends React.PureComponent<
  PageProperties<LegacyOrdersResponse>
> {
  constructor(props: PageProperties<LegacyOrdersResponse>) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchYourAccountState();
  }

  render() {
    const {ui} = this.props;

    return (
      <>
        {ui.isLoading ? (
          <Spinner />
        ) : (
          <ConnectedDeliveryContainerWithTabs {...this.props} />
        )}
      </>
    );
  }
}

export default DeliveryLoadingWrapper;
