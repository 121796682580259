import React from 'react';
import {ResponsiveImageSet} from '../shared/CoreClient';
import {SingleMerchSlot} from './SingleMerchSlot';
import TrackingProps from '../shared/TrackingProps';

export interface DoubleMerchSlotProps {
  imageSet?: ResponsiveImageSet;
  slotName?: string;
  hyperlink: string;
  imageSet2: ResponsiveImageSet;
  slotName2: string;
  hyperlink2: string;
}

export const DoubleMerchSlot: React.FC<DoubleMerchSlotProps> = ({
  imageSet,
  slotName,
  hyperlink,
  imageSet2,
  slotName2,
  hyperlink2,
}: DoubleMerchSlotProps) => {
  const tracking: TrackingProps = {
    category: 'merchandising',
    action: 'click-double-merch-slot',
    label: `${slotName} - first-double-merch-slot`,
  };

  const tracking2: TrackingProps = {
    category: 'merchandising',
    action: 'click-double-merch-slot',
    label: `${slotName2} - second-double-merch-slot`,
  };

  return (
    <div className="d-flex flex-wrap flex-lg-nowrap align-content-center">
      <div className="pr-lg-3">
        <SingleMerchSlot {...{imageSet, slotName, hyperlink, tracking}} />
      </div>
      <div className="pl-lg-3">
        <SingleMerchSlot
          imageSet={imageSet2}
          hyperlink={hyperlink2}
          tracking={tracking2}
        />
      </div>
    </div>
  );
};
