import {
  CodeValidationResponse,
  CostPreviewResponse,
  ISubscriptionClient,
  ProductAndRecipeDetailsResponse,
  ProductGroupResponse,
} from '../../../CoreClient';
import {RecipeCarouselContext, RecipeModel} from '@mfb/cookbook';

export interface CachedCostPreview {
  input: {sku: string; promo: string; nights: number; people: number};
  cachedPreview: CostPreviewResponse;
}

export interface CachedPromoValidation {
  input: {sku: string; promo: string};
  codeValidationResponse: CodeValidationResponse;
}

export interface CachedRecipeCarousel {
  input: {sku: string; name: string};
  data: RecipeModel[];
}

export const getCachedPromoValidation = async (
  subClient: ISubscriptionClient,
  sku: string,
  promo: string,
  cachedPromoValidations: Array<CachedPromoValidation>,
  getValidatePromoCode: (
    sku: string,
    promo: string
  ) => Promise<CodeValidationResponse>
) => {
  if (cachedPromoValidations.length > 0) {
    //find cached cost preview with current inputs
    const cached = cachedPromoValidations.find(
      x => x.input.sku === sku && x.input.promo === promo
    );

    if (cached) {
      return cached.codeValidationResponse;
    }
  }
  const codeValidationResponse = await getValidatePromoCode(sku, promo);
  cachedPromoValidations.push({
    input: {sku, promo},
    codeValidationResponse: codeValidationResponse,
  });
  return codeValidationResponse;
};

export const getCachedCostPreview = async (
  sku: string,
  nights: number,
  people: number,
  promo: string,
  setPriceContext,
  cachedCosts,
  getCostPreview: (sku: string) => Promise<CostPreviewResponse>
) => {
  setPriceContext(prevState => ({
    ...prevState,
    primaryButtonDisabled: true,
  }));
  if (cachedCosts.length > 0) {
    const cached = cachedCosts.find(
      x =>
        x.input.sku === sku &&
        x.input.nights === nights &&
        x.input.people === people
    );

    if (cached) {
      setPriceContext(prevState => ({
        ...prevState,
        primaryButtonDisabled: false,
      }));
      return cached.cachedPreview;
    }
  }
  const preview = await getCostPreview(sku);
  cachedCosts.push({
    input: {sku, nights, people, promo},
    cachedPreview: preview,
  });
  setPriceContext(prevState => ({
    ...prevState,
    primaryButtonDisabled: false,
  }));
  return preview;
};

let intervalId = undefined;

const onBrandRecipeImagesNotReady = (
  cachedRecipeCarousels,
  setCarouselContext: React.Dispatch<
    React.SetStateAction<RecipeCarouselContext>
  >,
  productSelected: string,
  intervalId
) => {
  cachedRecipeCarousels.find(c => {
    if (c.input.sku == productSelected) {
      setCarouselContext({
        showDateSelector: false,
        recipeCollections: [
          {
            day: '0',
            id: '100',
            recipes: c.data,
          },
        ],
      });
      clearInterval(intervalId);
    }
  });
};

export const getCachedRecipeCarousel = (
  cachedRecipeCarousels,
  setCarouselContext: React.Dispatch<
    React.SetStateAction<RecipeCarouselContext>
  >,
  productSelected: string
) => {
  let cached = undefined;
  cachedRecipeCarousels.find(c => {
    if (c.input.sku == productSelected) {
      cached = c;
      setCarouselContext({
        showDateSelector: false,
        recipeCollections: [
          {
            day: '0',
            id: '100',
            recipes: c.data,
          },
        ],
      });
    }
  });

  if (!cached) {
    clearInterval(intervalId);
    intervalId = setInterval(
      () =>
        onBrandRecipeImagesNotReady(
          cachedRecipeCarousels,
          setCarouselContext,
          productSelected,
          intervalId
        ),
      600
    );
  }
};

const cacheRecipeCarousel = async (
  sku: string,
  name: string,
  init: boolean,
  promoCode: string,
  getProductDetailsForSkuAndWeek: (
    sku: string,
    promoCode: string
  ) => Promise<ProductAndRecipeDetailsResponse>,
  cachedRecipeCarousels: CachedRecipeCarousel[],
  setCarouselContext: React.Dispatch<
    React.SetStateAction<RecipeCarouselContext>
  >
) => {
  const recipes: RecipeModel[] = [];

  (await getProductDetailsForSkuAndWeek(sku, promoCode)).recipes.map(
    (r, idx) => {
      const recipe: RecipeModel = {
        src: r.imageUrl,
        name: r.title,
        id: r.recipeId,
        rnumber: r.recipeNumber,
        rversion: r.version,
        partition: r.recipePartition,
        rid: idx,
      };
      recipes.push(recipe);
    }
  );

  cachedRecipeCarousels.push({
    input: {sku: sku, name},
    data: recipes,
  });

  init &&
    setCarouselContext({
      showDateSelector: false,
      recipeCollections: [
        {
          day: '0',
          id: '100',
          recipes: recipes,
        },
      ],
    });
};

export const cacheRecipeCarousels = async (
  filteredProductGroupResponse: ProductGroupResponse[],
  getProductDetailsForSkuAndWeek: (
    sku: string,
    promoCode: string
  ) => Promise<ProductAndRecipeDetailsResponse>,
  cachedRecipeCarousels: CachedRecipeCarousel[],
  setCarouselContext: React.Dispatch<
    React.SetStateAction<RecipeCarouselContext>
  >,
  selectedBrand: {name: string; sku: string}
) => {
  for (let i = 0; i < filteredProductGroupResponse.length; i++) {
    const currentProductGroup = filteredProductGroupResponse[i];
    for (let u = 0; u < currentProductGroup.productOptions.length; u++) {
      const {sku, name} = currentProductGroup.productOptions[u];
      if (sku === selectedBrand.sku) {
        //call and cache the initial selected brand in awaited call
        await cacheRecipeCarousel(
          sku,
          name,
          true,
          null,
          getProductDetailsForSkuAndWeek,
          cachedRecipeCarousels,
          setCarouselContext
        );
        //call and cache the rest afterwards in the background
        filteredProductGroupResponse.map(c => {
          c.productOptions.map((u, idx) => {
            if (u.sku != selectedBrand.sku && idx === 0) {
              cacheRecipeCarousel(
                u.sku,
                u.name,
                false,
                null,
                getProductDetailsForSkuAndWeek,
                cachedRecipeCarousels,
                setCarouselContext
              );
            }
          });
        });
      }
    }
  }
};
