import { Reducer } from 'redux';
import {
  REQUEST_PAGE_STATE,
  RequestPageStateAction,
} from '../actions/sharedActions/pageStateAction';
import {
  VALIDATION_ERRORS,
  ValidationErrorsAction,
} from '../actions/sharedActions/validationErrorsAction';
import {
  FetchBeginAction,
  FetchEndAction,
  FetchErrorAction,
  FETCH_BEGIN,
  FETCH_END,
  FETCH_ERROR,
} from '../actions/sharedActions/saveAction';
import {
  ReceivePageStateAction,
  RECEIVE_PAGE_STATE,
} from '../actions/sharedActions/pageStateAction';
import { UiState, defaultUiState } from '../state';
import {
  SetSelectedDayAction,
  SET_SELECTED_DAY,
  SetDeliveryDayPayload,
} from '../actions/sharedActions/daySelectionAction';
import {
  UPDATE_UI_COMPONENT,
  UpdateUiComponentAction,
  UpdateUiComponentPayload,
} from 'redux/actions/sharedActions/updateUiComponent';

const defaultState: UiState = defaultUiState;

type Action =
  | FetchBeginAction
  | FetchEndAction
  | FetchErrorAction
  | ReceivePageStateAction
  | RequestPageStateAction
  | SetSelectedDayAction
  | ValidationErrorsAction
  | UpdateUiComponentAction;

const reducer: Reducer<UiState> = (
  state: UiState = defaultState,
  action: Action
): UiState => {
  switch (action.type) {
    case FETCH_BEGIN:
      return {
        ...state,
        isLoading: true,
        isSubmitting: true,
        wasSuccessful: false,
      };
    case FETCH_END:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        wasSuccessful: true,
      };
    case FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        wasSuccessful: false,
      };
    case RECEIVE_PAGE_STATE:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        wasSuccessful: true,
      };
    case REQUEST_PAGE_STATE:
      return {
        ...state,
        isLoading: true,
        isSubmitting: false,
        wasSuccessful: true,
      };
    case SET_SELECTED_DAY:
      const payload = action.payload as SetDeliveryDayPayload;
      return { ...state, selectedDay: payload.deliveryDay };
    case VALIDATION_ERRORS:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        wasSuccessful: false,
      };
    case UPDATE_UI_COMPONENT: {
      const payload = action.payload as UpdateUiComponentPayload[];
      const uiComponents = state.uiComponents;

      payload.map(c=>{
        uiComponents[c.key] = c.attributes;
      });

      return {...state, uiComponents };
    }
    default:
      return state;
  }
};

export default reducer;
