import React from 'react';
import classnames from 'classnames';
import Submit from './Submit';
import { MessageVariant } from '../CoreClient';
import Markdown from 'react-remarkable';
import TrackingProps from '../TrackingProps';
import { Alert, AlertVariant } from '@mfb/lego';

interface Button {
  label: string;
  className?: string;
  tracking?: TrackingProps;
  onClick?(): void;
  onClickAsync?(): Promise<void>;
}

interface AlertMessageProps {
  variant?: MessageVariant;
  title: string;
  text?: string;
  button?: Button;
  secondaryButton?: Button;
  tertiaryButton?: Button;
}

const getVariantName = (variant: MessageVariant): AlertVariant => {
  switch (variant) {
    case MessageVariant.Danger:
      return AlertVariant.Danger;
    case MessageVariant.Success:
      return AlertVariant.Success;
    case MessageVariant.Warning:
      return AlertVariant.Warning;
    default:
      return AlertVariant.Info;
  }
};

const AlertMessage: React.FunctionComponent<AlertMessageProps> = ({
  variant = MessageVariant.Info,
  title,
  text,
  button,
  secondaryButton,
  tertiaryButton,
}: AlertMessageProps) => {
  const variantName = getVariantName(variant);
  const btnClassNames = classnames([
    'btn mb-3',
    `btn-${variantName}`,
    button && button.className,
  ]);
  const secondaryBtnClassNames = classnames([
    'btn mb-3',
    `btn-outline-${variantName}`,
    secondaryButton && secondaryButton.className,
  ]);
  const tertiaryBtnClassNames = classnames([
    'btn mb-3',
    `btn-outline-${variantName}`,
    tertiaryButton && tertiaryButton.className,
  ]);

  return (
    <Alert variant={variantName}>
      <h4 data-test="alert-title">{title}</h4>
      {text && <Markdown source={text} />}
      {button && (
        <Submit
          tracking={button.tracking}
          label={button.label}
          onClick={button.onClick && (() => button.onClick())}
          onClickAsync={
            button.onClickAsync && (async () => await button.onClickAsync())
          }
          className={btnClassNames}
        />
      )}
      {secondaryButton && (
        <Submit
          isSecondary={true}
          tracking={secondaryButton.tracking}
          label={secondaryButton.label}
          onClick={secondaryButton.onClick && (() => secondaryButton.onClick())}
          onClickAsync={
            secondaryButton.onClickAsync &&
            (async () => await secondaryButton.onClickAsync())
          }
          className={classnames(secondaryBtnClassNames, 'ml-md-4')}
        />
      )}
      {tertiaryButton && (
        <Submit
          isSecondary={true}
          tracking={tertiaryButton.tracking}
          label={tertiaryButton.label}
          onClick={tertiaryButton.onClick && (() => tertiaryButton.onClick())}
          onClickAsync={
            tertiaryButton.onClickAsync &&
            (async () => await tertiaryButton.onClickAsync())
          }
          className={classnames(tertiaryBtnClassNames, 'ml-md-4')}
        />
      )}
    </Alert>
  );
};

export default AlertMessage;
