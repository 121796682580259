import React from 'react';
import { Modal, AsyncButton, AlertStatus } from '@mfb/lego';
import {
  AddressSuggestion,
  DeliveryClient,
  AvailableFrequency,
} from '../../../shared/CoreClient';
import { globalSettings as settings } from 'config';
import {AddressDetails} from './AddressDetails';
import {isUndefined} from 'lodash';
import { useAlertToastContext } from 'app/shared/hooks';

interface Props {
  deliveryId: number;
  availableFrequency: AvailableFrequency;
  deliveryAddress: string;
  productOptionId: number;
  deliverySlotId: number;
  isProductSameAsSubscription: boolean;
  deliveryInstructions?: string;
  toggleModal(): void;
  fetchYourAccountState?(): void;
}

export const AddressChangeModal: React.FunctionComponent<Props> = ({
  deliveryAddress,
  productOptionId,
  deliverySlotId,
  deliveryInstructions,
  deliveryId,
  isProductSameAsSubscription,
  availableFrequency,
  toggleModal,
  fetchYourAccountState,
}) => {
  const isOneOff = availableFrequency === AvailableFrequency.OneOff;

  const [selectedPafId, setAddress] = React.useState<number>();
  const [selectedDeliverySlotId, setDeliverySlot] = React.useState<number>();
  const [instructions, setInstructions] = React.useState<string>(
    deliveryInstructions
  );
  const [addressError, setAddressError] = React.useState<string>();
  const [
    deliveryLockedError,
    setDeliveryLockedError,
  ] = React.useState<string>();
  const [newDeliveryAddress, setNewDeliveryAddress] = React.useState<string>();
  const [
    isConfirmingFutureSubscription,
    setIsConfirmingFutureSubscription,
  ] = React.useState<boolean>();

  const { updateToast } = useAlertToastContext();

  const deliveryClient = new DeliveryClient(settings.bffHost);

  const onAddressChange = (selectedAddress: AddressSuggestion) => {
    setAddressError(undefined);
    setAddress(selectedAddress.pafId);
  };

  const onModalClose = () => {
    // If address or delivery slot has changed, reload the page.
    if (newDeliveryAddress || deliveryLockedError) {
      fetchYourAccountState();
    } else {
      toggleModal();
    }
  };

  const onSubmitUpdateDelivery = async () => {
    try {
      const response = await deliveryClient.updateDelivery({
        deliveryId,
        deliverySlotId: selectedDeliverySlotId,
        pafId: selectedPafId,
        deliveryInstructions: instructions,
      });

      if (response.hasDeliveryIsLockedError) {
        setDeliveryLockedError(
          `Sorry we are no longer able to update your delivery address as all of our
            delivery information locks in at 7pm Tuesday prior to delivery.`
        );
      } else if (response.hasDeliveryAddressError) {
        setAddressError(
          'We could not change your address for this delivery because there is no stock available at your new address.'
        );
      } else {
        setNewDeliveryAddress(response.newAddress);
        setIsConfirmingFutureSubscription(true);

        if (isOneOff || !isProductSameAsSubscription) {
          fetchYourAccountState();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitUpdateSubscription = async () => {
    try {
      await deliveryClient.updateSubscription({
        deliveryId,
        deliverySlotId: selectedDeliverySlotId,
        pafId: selectedPafId,
        deliveryInstructions: instructions,
      });

      updateToast({
        status: AlertStatus.success,
        isOpen: true,
        text: 'You have successfully updated your subscription',
      });
      fetchYourAccountState();
    } catch (error) {
      console.error(error);
    }
  };

  const isSaveDisabled =
    selectedDeliverySlotId === 0 || !isUndefined(deliveryLockedError);

  return (
    <Modal
      isOpen={true}
      toggle={onModalClose}
      maxWidth="550px"
      header={
        <h2>
          {!isConfirmingFutureSubscription
            ? 'Change Address'
            : 'Future Deliveries'}
        </h2>
      }
      body={
        <>
          {deliveryLockedError ? (
            <div>
              <p>{deliveryLockedError}</p>
              <div className="w-100 text-center">
                <div className="d-flex-grow d-md-inline-flex">
                  <button
                    className="btn btn-primary btn-block mt-3 m-2"
                    onClick={onModalClose}
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          ) : !isConfirmingFutureSubscription ? (
            <AddressDetails
              deliveryId={deliveryId}
              productOptionId={productOptionId}
              currentDeliveryAddress={deliveryAddress}
              selectedPafId={selectedPafId}
              selectedDeliverySlotId={selectedDeliverySlotId}
              currentDeliverySlotId={deliverySlotId}
              currentInstructions={instructions}
              addressError={addressError}
              isSaveDisabled={isSaveDisabled}
              onAddressChange={onAddressChange}
              onDeliverySlotChange={setDeliverySlot}
              onInstructionsChange={e => setInstructions(e.currentTarget.value)}
              toggleModal={onModalClose}
              onSubmit={onSubmitUpdateDelivery}
            />
          ) : (
            <div>
              <p>
                Would you also like to update all future deliveries to{' '}
                {newDeliveryAddress}
              </p>
              <div className="w-100 text-center">
                <div className="d-flex-grow d-md-inline-flex">
                  <button
                    className="btn btn-secondary btn-block mt-3 m-2"
                    disabled={isSaveDisabled}
                    onClick={onModalClose}
                  >
                    No thanks
                  </button>
                </div>
                <div className="d-flex-grow d-md-inline-flex">
                  <AsyncButton
                    className="btn btn-primary btn-block mt-3 m-2"
                    disabled={isSaveDisabled}
                    onClickAsync={onSubmitUpdateSubscription}
                  >
                    Yes please
                  </AsyncButton>
                </div>
              </div>
            </div>
          )}
        </>
      }
    />
  );
};
