import React from 'react';
import {Brand} from '../CoreClient';
import Logo from '../components/Logo';
import BargainBoxLogo from '../components/BargainBoxLogo';
import FreshStartLogo from '../components/FreshStartLogo';
import MadeLogo from '../components/MadeLogo';

export type Size = 25 | 100;

export interface Props {
  size?: Size;
  brand: Brand;
}

export const mfbClassIdentifier = 'mfb-logo';
export const bbClassIdentifier = 'bb-logo';
export const fsClassIdentifier = 'fs-logo';
export const rmmClassIdentifier = 'rmm-logo';

const BrandLogo: React.FunctionComponent<Props> = ({size, brand}: Props) => {
  const dimension = size ? size : 100;

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-center">
        {brand === Brand.MyFoodBag && (
          <Logo
            width={dimension}
            height={dimension}
            className={mfbClassIdentifier}
          />
        )}
        {brand === Brand.BargainBox && (
          <BargainBoxLogo
            width={dimension}
            height={dimension}
            className={bbClassIdentifier}
          />
        )}
        {brand === Brand.FreshStart && (
          <FreshStartLogo
            width={dimension}
            height={dimension}
            className={fsClassIdentifier}
          />
        )}
        {brand === Brand.ReadyMadeMeals && (
          <MadeLogo
            width={dimension}
            height={dimension}
            className={rmmClassIdentifier}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default BrandLogo;
