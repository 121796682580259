import React from 'react';
import AddressFinder from '../../../account-settings/components/AddressFinder';
import DeliverySlotSelect from '../../../account-settings/components/DeliverySlotSelect';
import FormTextArea from '../../../account-settings/components/FormTextArea';
import {AddressSuggestion} from '../../../shared/CoreClient';
import {AsyncButton} from '@mfb/lego';

interface Props {
  deliveryId: number;
  productOptionId: number;
  currentDeliveryAddress: string;
  selectedPafId: number;
  selectedDeliverySlotId: number;
  currentDeliverySlotId: number;
  currentInstructions?: string;
  addressError?: string;
  isSaveDisabled?: boolean;
  onAddressChange(selectedAddress: AddressSuggestion): void;
  onDeliverySlotChange(selectedSlotId: number): void;
  onInstructionsChange(event: React.FormEvent<HTMLTextAreaElement>): void;
  toggleModal(): void;
  onSubmit(): Promise<void>;
}

export const AddressDetails: React.FunctionComponent<Props> = ({
  deliveryId,
  productOptionId,
  currentDeliveryAddress,
  selectedPafId,
  selectedDeliverySlotId,
  currentDeliverySlotId,
  currentInstructions,
  addressError,
  isSaveDisabled,
  onAddressChange,
  onDeliverySlotChange,
  onInstructionsChange,
  toggleModal,
  onSubmit,
}) => (
  <>
    <div className="form-group">
      <label>Enter Delivery Address</label>
      <AddressFinder
        onAddressChange={onAddressChange}
        fullAddress={currentDeliveryAddress}
      />
      <span className="text-danger">{addressError}</span>
    </div>
    <div className="form-group">
      <DeliverySlotSelect
        onChange={onDeliverySlotChange}
        bagId={productOptionId}
        deliveryId={deliveryId}
        pafId={selectedPafId}
        selectedDeliverySlotId={currentDeliverySlotId || selectedDeliverySlotId}
        noSlotMessage="Sorry, we don't currently deliver to this area."
      />
    </div>
    <FormTextArea
      label="Delivery Instructions"
      onChange={onInstructionsChange}
      value={currentInstructions || ''}
      name="deliveryInstructions"
      maxLength={250}
    />
    <div className="w-100 text-center">
      <div className="d-flex-grow d-md-inline-flex">
        <button
          className="btn btn-secondary btn-block mt-3 m-2 btn"
          onClick={toggleModal}
        >
          Cancel
        </button>
      </div>
      <div className="d-flex-grow d-md-inline-flex">
        <AsyncButton
          className="btn btn-primary btn-block mt-3 m-2"
          disabled={isSaveDisabled}
          onClickAsync={onSubmit}
        >
          Change Address
        </AsyncButton>
      </div>
    </div>
  </>
);
