import {
  Frequency,
  KeyValuePairOfInt32AndExtraRequest,
} from '../../../shared/CoreClient';

export const hasExtras = (extras: Array<KeyValuePairOfInt32AndExtraRequest>) =>
  extras && extras.length > 0;

export const extraContainsFortnightly = (
  extras: Array<KeyValuePairOfInt32AndExtraRequest>
) => {
  if (!hasExtras(extras)) {
    return undefined;
  }

  return extras.some(c => c.value.frequency === Frequency.Fortnightly);
};

export const handleCloseDialog = (state: any) => ({
  ...state,
  sFreqDialogOpen: !state.isFreqDialogOpen,
});
