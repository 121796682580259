import React from 'react';
import {navman} from '../../../navigator';
import {CampaignParticipationStatus} from '../../shared/CoreClient';

interface Props {
  campaignParticipationStatus: CampaignParticipationStatus;
}

const AlreadyInCampaign: React.FunctionComponent<Props> = (props: Props) => {
  const {campaignParticipationStatus} = props;

  let title;
  let body;
  switch (campaignParticipationStatus) {
    case CampaignParticipationStatus.CustomerIsInThisCampaign:
      title = `You've already joined this programme`;
      body = `We hope you're enjoying it! If you have feedback or questions, get in touch with our Customer Love team.`;
      break;
    case CampaignParticipationStatus.CustomerIsInSiblingCampaign:
      title = `You're already part of another programme`;
      body = `You can only join one programme at a time.
        If you have any questions, please get in touch with our Customer Love team.`;
      break;
    default:
      break;
  }

  return (
    <div className="mx-md-4 text-center">
      <h3 className="my-4 w-100">{title}</h3>
      <p className="mb-4 mb-sm-5 w-100">{body}</p>
      <button onClick={navman.yourAccount} className="btn btn-primary mb-5">
        Got it
      </button>
    </div>
  );
};

export default AlreadyInCampaign;
