/* eslint-disable array-callback-return */
import { ThunkAction } from 'redux-thunk';
import { globalSettings as settings } from 'config';
import { Action } from 'redux';
import { AppState } from 'redux/state';
import {
  OrderAccountClient,
  ProductGroupResponse,
} from 'app/shared/CoreClient';
import {
  UiComponentName,
  performUpdateUiComponentActionBulk,
} from './updateUiComponent';

export const RECEIVE_PRODUCT_GROUP_MARKETING =
  'RECEIVE_PRODUCT_GROUP_MARKETING';

export interface ReceiveProductGroupMarketingAction extends Action {
  type: typeof RECEIVE_PRODUCT_GROUP_MARKETING;
  payload: ProductGroupResponse[];
}

const receiveProductGroupMarketingActionCreator = (
  response: ProductGroupResponse[]
): ReceiveProductGroupMarketingAction => ({
  type: RECEIVE_PRODUCT_GROUP_MARKETING,
  payload: response,
});

export const fetchProductGroupMarketing = (): ThunkAction<
  Promise<ReceiveProductGroupMarketingAction>,
  AppState,
  never
> => {
  return async (dispatch) => {
    const deliveriesClient = new OrderAccountClient(settings.bffHost);
    const productGroupResult =
      (await deliveriesClient.getProductsGroupedForMarketing()) as ProductGroupResponse[];

    setBagSelectorUiAttributes(productGroupResult, dispatch);

    try {
      return dispatch(
        receiveProductGroupMarketingActionCreator(
          productGroupResult as ProductGroupResponse[]
        )
      );
    } catch (err) {
      throw err;
    }
  };
};

/**
 * @function control enabling/disabling bag selectors in edit/reactivation forms
 */
const setBagSelectorUiAttributes = (
  productGroupResponse: ProductGroupResponse[],
  dispatch
) => {
  const uiUpdateQueue = { edit: [], reactivate: [] };

  productGroupResponse.forEach((productGroup) => {
    productGroup.productOptions?.forEach((productOptions) => {
      const settings = productOptions.settings;
      uiUpdateQueue.edit.push({
        key: {
          name: UiComponentName.EDIT_BAG_SELECTOR,
          productCollectionCode: productOptions.settings?.productCollectionCode,
        },
        attributes: {
          isHidden: settings ? !settings.canEditFromSubscription : false,
        },
      });
      uiUpdateQueue.reactivate.push({
        key: {
          name: UiComponentName.REACTIVATE_BAG_SELECTOR,
          productCollectionCode: productOptions.settings?.productCollectionCode,
        },
        attributes: {
          isHidden: settings ? !settings.canPurchaseFromSubscription : false,
        },
      });
    });
  });

  dispatch(
    performUpdateUiComponentActionBulk([
      ...uiUpdateQueue.edit,
      ...uiUpdateQueue.reactivate,
    ])
  );
};

export type ProductGroupMarketingStateAction =
  | ReceiveProductGroupMarketingAction
  | Action;
