import React from 'react';
import {RecipeRating} from '../shared/CoreClient';
import ShowIfOnScreen from '../shared/components/ShowIfOnScreen';
import {ResponsiveSquare, ImageNotFoundPlaceholder} from '@mfb/lego';
import Rating from './Rating';
import {RateFunc} from './RateRecipesShell';
import {RecipeModal, RecipeDetailModelLegacy} from '@mfb/cookbook';
import {getRecipeDetailModelLegacy} from '../shared/GetRecipeDetailModel';

interface Props {
  recipe: RecipeRating;
  deliveryWeek: Date;
  deliveryWeeksBack: number;
  performRating: RateFunc;
}

export const RecipeWithRating: React.FC<Props> = ({
  recipe,
  deliveryWeeksBack,
  deliveryWeek,
  performRating,
}) => {
  const handleRateRecipe = (guid: string) => async (rating: number) => {
    await performRating({
      recipeGroupRecipeId: guid,
      rating,
      deliveryWeeksBack,
    });
  };

  const handleSaveComment = (guid: string) => async (comments: string) => {
    await performRating({
      recipeGroupRecipeId: guid,
      comments,
      deliveryWeeksBack,
    });
  };

  const [isModalOpen, setModalOpen] = React.useState(false);
  const [
    recipeDetailModel,
    setRecipeDetailModel,
  ] = React.useState<RecipeDetailModelLegacy>(undefined);

  const recipeDetailModelCallback = React.useCallback(() => {
    const tryGetRecipeDetailModel = async () => {
      try {
        const response = await getRecipeDetailModelLegacy(
          recipe.recipeNumber,
          recipe.recipeVersion,
          recipe.recipePartition,
          recipe.recipeId,
          deliveryWeek
        );
        setRecipeDetailModel(response);
      } catch (e) {
        // @mfb/cookbook/recipe-modal has default values for empty objects
        setRecipeDetailModel({});
      }
    };

    void tryGetRecipeDetailModel();
  }, []);

  const toggleModal = () => {
    recipeDetailModelCallback();
    setModalOpen(!isModalOpen);
  };

  return (
    <>
      <RecipeModal
        recipe={recipeDetailModel}
        isOpen={isModalOpen}
        toggle={toggleModal}
      />
      <div
        className="mfb-DeliveryItem mfb-DeliveryItem-large pb-2"
        data-test="recipe-rating-container"
      >
        <ShowIfOnScreen placeholderClassName="mfb-DeliveryItem--placeholder">
          <a
            data-category="recipe"
            data-action="view-recipe-modal"
            data-label={recipe.recipeId}
            data-test="recipe-modal-button"
            onClick={e => {
              e.preventDefault();
              toggleModal();
            }}
          >
            <ResponsiveSquare
              id={recipe.id}
              paddingSizes={{small: '100%'}}
              className="bg-secondary"
            >
              {recipe.imageUrl ? (
                <img
                  className="mfb-DeliveryItemImage w-100 h-100"
                  style={{objectFit: 'cover', objectPosition: 'center'}}
                  src={recipe.imageUrl}
                  alt={recipe.name}
                  data-test="recipe-image"
                />
              ) : (
                <ImageNotFoundPlaceholder />
              )}
            </ResponsiveSquare>
          </a>
        </ShowIfOnScreen>
        <div className="text-center">
          <div className="mfb-DeliveryItem--title pt-2" data-test="recipe-name">
            {recipe.name}
          </div>
          <Rating
            rating={recipe.rating}
            comments={recipe.comment}
            onSaveComment={handleSaveComment(recipe.id)}
            onClickRating={handleRateRecipe(recipe.id)}
          />
        </div>
      </div>
    </>
  );
};
