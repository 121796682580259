import { ActionCreator } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import { IResult, ISkipDeliveryRequest, SkipDeliveryRequest, UnSkipDeliveryRequest } from '@mfb/account-api-client';
import { SkipAction } from '../../app/deliveries/components/SkipAction';
import { Week } from '../../app/shared/NSwagClassExtensions';
import { accountApiClient } from '../../app/shared/api';
import { DeliveryWeek, firstOrDefault } from '../../app/shared/helper';
import { AppState } from '../state';
import { fetchOrderPageThunk } from './fetchOrderPageThunk';

interface SkipV2Payload {
  subscriptionNumber: string;
  skipWeek: string;
  triggerId: number;
  isSkippedFromCalendar: boolean;
}

export interface SkipPayload extends Partial<SkipV2Payload> {
  subscriptionId: number;
  week: Week;
  action: SkipAction;
}

/**
 * Temporary solution to slave lag
 * @TODO revisit this issue
 * @param ms
 */
const sleep = (ms = 1500) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

/**
 * Performs the skip action. Logic here is ripped from the legacy perform skip action from this commit + file
 * https://github.com/MyFoodBag/repository/blob/e6f2704041e2b16e2b0fdfe5d25a1aa4d7388fd3/src/account.myfoodbag.co.nz/client/redux/actions/deliveriesActions/deliveriesAction.ts
 * @param payload
 */
export const skipActionThunk: ActionCreator<
  ThunkAction<Promise<void>, AppState, null>
> = (payload: SkipPayload) => {
  return async (dispatch) => {
    let response: IResult;

    try {
      const request: ISkipDeliveryRequest= {
        week: new DeliveryWeek(payload.skipWeek),
        triggerId: payload.triggerId,
        isSkippedFromCalendar: payload.isSkippedFromCalendar ?? false,
      };

      if (payload.action === SkipAction.Skip) {
        response = await accountApiClient.subscriptionsSkip(
          payload.subscriptionNumber,
          new SkipDeliveryRequest({
            ...request,
          })
        );
      } else if (payload.action === SkipAction.UnSkip) {
        response = await accountApiClient.subscriptionsUnSkip(
          payload.subscriptionNumber,
          new UnSkipDeliveryRequest({
            ...request,
          })
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (!response?.succeeded) {
        throw new Error(firstOrDefault(response.errors));
      } else {
        // @TODO do we really need to reload the page for this?
        await sleep(1500); // @TODO temporary fix for slave lag
        await dispatch(fetchOrderPageThunk());
      }
    }
  };
};
