import React from 'react';

export const CHANGE_HOLIDAY_ADDRESS = 'CHANGE_HOLIDAY_ADDRESS';
export const SKIP_HOLIDAY_DELIVERIES = 'SKIP_HOLIDAY_DELIVERIES';

interface Props {
  selectedOption: string;
  setSelectedOption(option: string): void;
}

export const HolidayActionSelector: React.FC<Props> = ({
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <div>
      <h4>I would like to...</h4>
      <div className="mt-3">
        <label
          className="custom-control custom-radio mt-3"
          onClick={() => setSelectedOption(CHANGE_HOLIDAY_ADDRESS)}
          data-category="holiday-address-changes"
          data-label="update-address-radio-button"
          data-action="click"
        >
          <input
            type="radio"
            checked={selectedOption === CHANGE_HOLIDAY_ADDRESS}
            value={CHANGE_HOLIDAY_ADDRESS}
            className="custom-control-input"
          />
          <label className="custom-control-label" htmlFor="form-radio-true">
            Update My Address
          </label>
        </label>

        <label
          className="custom-control custom-radio mt-3"
          onClick={() => setSelectedOption(SKIP_HOLIDAY_DELIVERIES)}
          data-category="holiday-address-changes"
          data-label="skip-deliveries-radio-button"
          data-action="click"
        >
          <input
            type="radio"
            checked={selectedOption === SKIP_HOLIDAY_DELIVERIES}
            value={SKIP_HOLIDAY_DELIVERIES}
            className="custom-control-input"
          />
          <label className="custom-control-label" htmlFor="form-radio-true">
            Skip My Deliveries
          </label>
        </label>
      </div>
    </div>
  );
};
