/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {navman} from '../../../navigator';
import {getQueryStringParams} from '../../shared/getQueryString';
import { CampaignGroupDto } from '@mfb/account-api-client';

interface Props {
  campaignGroupResponse: CampaignGroupDto;
}

const InvalidSubscription: React.FunctionComponent<Props> = (props: Props) => {
  const {
    groupTitle,
    campaignGroupProductOptionIds,
    editableSubscriptions,
  } = props.campaignGroupResponse;
  const campaignCode = getQueryStringParams().campaignCode;

  const [defaultSub] = editableSubscriptions
  const subscriptionNumber = defaultSub?.subscriptionNumber ?? "";
  const onClick = () =>
    navman.editSubscriptionWithOptions(
      campaignGroupProductOptionIds || [],
      campaignCode,
      subscriptionNumber
    );

  return (
    <div className="mx-md-4 text-center">
      <h3 className="my-4 w-100">Great, let's get you sorted!</h3>
      <p className="mb-4 mb-sm-5 w-100">
        To join the {groupTitle}, you'll need to change bag first.
      </p>
      <button onClick={onClick} className="btn btn-primary mb-5">
        Change Bag
      </button>
    </div>
  );
};

export default InvalidSubscription;
