import {AccountFeaturesState, defaultFeaturesState} from '../state';
import {
  ReceiveFeatureAction,
  RECIEVE_FEATURES,
} from '../actions/featuresAction';
import {Action, Reducer} from 'redux';
import {AccountFeatures} from '../../app/shared/CoreClient';

const defaultState: AccountFeatures = defaultFeaturesState;

type FeaturesStateAction = ReceiveFeatureAction | Action;

const reducer: Reducer<AccountFeatures> = (
  state: AccountFeatures = defaultState,
  action: FeaturesStateAction
): AccountFeaturesState => {
  switch (action.type) {
    case RECIEVE_FEATURES:
      return (action as ReceiveFeatureAction).payload;
    default:
      return state;
  }
};

export default reducer;
