/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {
  PageProperties,
  mapStateToProps,
  mapDispatchToProps,
  DispatcherProps,
} from '../containers/accountSettingsContainer';
import {
  AccountOrder,
  AccountOrderProduct,
  AccountSettings,
} from '../../shared/CoreClient';
import { formatDay } from '../../../format/day';
import { connect, ComponentClass } from 'react-redux';
import { formatMoney } from '../../../format/money';
import { navman } from '../../../navigator';
import MobileStickyHeaderProxy from '../../shared/components/MobileStickyHeader/MobileStickyHeaderProxy';
import { AppState } from '../../../redux/state';

class OrderHistory extends React.Component<PageProperties, {}> {
  render() {
    const accountSettings = this.props.pageState as AccountSettings;

    return (
      <div>
        <MobileStickyHeaderProxy
          hiddenUp="md"
          onBack={
            this.props.features.dashboardV2 ? undefined : navman.accountSettings
          }
        />
        <h3 className="mt-2 mb-4">My Order History</h3>
        {accountSettings.history.length === 0 && (
          <div>
            <p>There is currently no order history available.</p>
          </div>
        )}

        {accountSettings.history &&
          accountSettings.history.map((item: AccountOrder, index: number) => (
            <div key={index} className="row mb-3">
              <div className="col-12 col-md-4">
                <table className="table-sm w-100">
                  <tbody>
                    <tr>
                      <td>
                        <strong>{formatDay(item.deliveryDate)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{item.deliverySlot}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="font-italic text-muted">
                          {item.paid ? 'Payment Received' : 'Not Paid'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {item.paid && item.paidOn && (
                          <span className="font-italic text-muted">
                            {formatDay(item.paidOn)}
                          </span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-12 col-md-8">
                <table className="table-sm w-100">
                  <tbody>
                    {item.products.map(
                      (product: AccountOrderProduct, productIndex: number) => (
                        <tr key={productIndex}>
                          <td>{product.name}</td>
                          <td className="text-right">
                            {formatMoney(product.price)}
                            <span>&nbsp;</span>
                          </td>
                        </tr>
                      )
                    )}
                    {item.discounts === 0 ? (
                      ''
                    ) : (
                      <tr>
                        <td>Less Discounts</td>
                        <td className="text-right">
                          <span>(</span>
                          {formatMoney(item.discounts)}
                          <span>)</span>
                        </td>
                      </tr>
                    )}
                    {item.voucherAmount === 0 ? (
                      ''
                    ) : (
                      <tr>
                        <td>Less Voucher</td>
                        <td className="text-right">
                          <span>(</span>
                          {formatMoney(item.voucherAmount)}
                          <span>)</span>
                        </td>
                      </tr>
                    )}
                    {item.deliveryCost === 0 ? (
                      ''
                    ) : (
                      <tr>
                        <td>Delivery Cost</td>
                        <td className="text-right">
                          {formatMoney(item.deliveryCost)}
                          <span>&nbsp;</span>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <strong className="text-upper">Total</strong>
                      </td>
                      <td className="text-right">
                        <strong>
                          {formatMoney(item.total)}
                          <span>&nbsp;</span>
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr className="col-12" />
            </div>
          ))}
      </div>
    );
  }
}

export const orderHistory = connect<AppState, DispatcherProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(OrderHistory) as ComponentClass<any>;
