import { RecipeDetailModel, RecipeDetailModelLegacy } from '@mfb/cookbook';
import {store} from '../..';
import {selectCurrentOrder} from '../../redux/selectors/orderPageSelectors';
import {AppState, OrdersPageState} from '../../redux/state';
import { accountApiClient } from './api';
import { globalSettings as settings } from 'config';
import { RecipesClient } from './CoreClient';

export const getRecipeDetailModel = async (
  recipeNumber?: string,
  recipeVersion?: string,
  partition?: string,
  deliveryWeek?: Date
) => {
  let weekStarting;
  if (deliveryWeek != null) {
    weekStarting = new Date(deliveryWeek);
  } else {
    const ordersPageState = store.getState() as AppState<OrdersPageState>;
    const currentOrder = selectCurrentOrder(ordersPageState);
    weekStarting = new Date(currentOrder.deliveryDate.weekStarting);
  }

  const response = await accountApiClient.recipe(partition, recipeNumber, recipeVersion, weekStarting);

  return response as RecipeDetailModel; 
};

export const getRecipeDetailModelLegacy = async (
  recipeNumber?: string,
  recipeVersion?: string,
  partition?: string,
  recipeId?: number,
  deliveryWeek?: Date
) => {
  let weekStarting;
  if (deliveryWeek != null) {
    weekStarting = new Date(deliveryWeek);
  } else {
    const ordersPageState = store.getState() as AppState<OrdersPageState>;
    const currentOrder = selectCurrentOrder(ordersPageState);
    weekStarting = new Date(currentOrder.deliveryDate.weekStarting);
  }

  const response = await new RecipesClient(settings.bffHost).loadRecipeDetails(
    recipeNumber || '-',
    recipeVersion || '-',
    partition || '-',
    weekStarting,
    recipeId
  );

  return response as RecipeDetailModelLegacy; 
};
