import {TabButton} from './TabButton';
import {SvgCalendarTabIcon, CarouselItemModel} from '@mfb/lego';
import {navman} from '../../../../navigator';
import TrackingProps from '../../../shared/TrackingProps';
import classNames from 'classnames';

const tracking: TrackingProps = {
  category: 'delivery',
  action: 'view-delivery-calendar',
};

export interface CalendarTabProps {
}

export const CalendarTab: React.FC<CalendarTabProps> = (props) => (
  // Only shows when viewport is smaller than lg
  <TabButton
    isSelected={false}
    isSkipped={false}
    hasOverrideDeliveryDate={false}
    className={classNames("text-center h-100 pt-2 d-lg-none")}
    tracking={tracking}
    onClick={navman.calendar}
  >
    <SvgCalendarTabIcon fontSize="36px" className="" />
  </TabButton>
);

export const mapCalendarCarouselTab = (): CarouselItemModel => ({
  key: `ckey-calendar`,
  component: <CalendarTab/>,
});
