import React from 'react';
import {NavLink} from 'react-router-dom';
import {matchPath} from 'react-router';
import {Location} from 'history';
import classNames from 'classnames';
import MenuItem from '../../../shared/MenuItem';
import {kebabCase} from 'lodash';

const isPathActive = (path: string, location: Location) => {
  // TODO: need to handle default
  if (path && location) {
    const result = matchPath(location.pathname, {path});

    return result != null;
  }

  return false;
};

interface Props {
  location: Location;
  menuItems: Array<MenuItem>;
}

const NavLegacy: React.FunctionComponent<Props> = ({location, menuItems}) => (
  <nav className="nav flex-column">
    {menuItems.map(item => {
      const IconComponent = item.icon;
      const isActive = isPathActive(item.path, location);
      const className = classNames(
        {
          'text-white': isActive,
          'text-dark': !isActive,
        },
        'sub-nav-link p-3  d-flex align-items-center'
      );

      return (
        <span
          key={item.title}
          className={classNames({'bg-primary': isActive}, 'rounded')}
          data-category="manage-account-nav-item"
          data-label={kebabCase(item.title)}
          data-action="click"
        >
          <NavLink
            className={className}
            to={item.path}
            isActive={item.isActive}
            style={{textDecoration: 'none'}}
          >
            <IconComponent
              style={{fill: isActive ? '#FFF' : '#000'}}
              width="16"
              height="16"
              className="mr-3"
            />
            {item.title}
          </NavLink>
        </span>
      );
    })}
  </nav>
);

export default NavLegacy;
