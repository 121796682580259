import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useAnalyticsTracking } from '@mfb/lego';
import { platform } from '../../constants';

enum CampaignTrackingEvent {
  joinScreenViewed = 'Campaign - Join Screen Viewed',
  detailScreenViewed = 'Campaign - Detail Screen Viewed'
}

interface Params {
  campaignName: string;
  campaignButtonText: string;
  rewardName: string;
  isCampaignCelebrationTriggered: boolean;
  progressStatus: string;
}

export const useCampaignTracking = () => {
  const { trackDataLayerEvent } = useAnalyticsTracking();

  const trackOnDisplayJoinCampaignModal = (metadata: any, params: Partial<Params>) => {
    const eventData = {
      customerNumber: mixpanel.get_distinct_id(),
      platform: platform,
      ...metadata,
      campaignName: params.campaignName,
    };

    trackDataLayerEvent(
      CampaignTrackingEvent.joinScreenViewed,
      eventData
    );
  };

  const trackOnDisplayCampaignDetailsModal = (metadata: any, params: Partial<Params>) => {
    const eventData = {
      customerNumber: mixpanel.get_distinct_id(),
      platform: platform,
      ...metadata,
      campaignName: params.campaignName,
      campaignButtonText: params.campaignButtonText,
      rewardName: params.rewardName,
      progressStatus: params.progressStatus,
    };

    trackDataLayerEvent(
      CampaignTrackingEvent.detailScreenViewed,
      eventData
    );
  };

  return {
    trackOnDisplayJoinCampaignModal,
    trackOnDisplayCampaignDetailsModal
  };
};