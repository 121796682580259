/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import classnames from 'classnames';

type onClickRatingFunc = (rating: number) => Promise<void>;
type onSaveCommentFunc = (comment: string) => void;

interface Props {
  rating: number;
  onClickRating: onClickRatingFunc;
  onSaveComment: onSaveCommentFunc;
  comments: string;
}

interface State {
  isOpen: boolean;
  comments: string;
  isDisabled: boolean;
}

export default class RatingShell extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      comments: this.props && this.props.comments,
      isDisabled: false,
    };
  }

  toggleComments = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement>
  ) => {
    e.preventDefault();
    this.setState({
      comments: this.props.comments,
      isOpen: !this.state.isOpen,
    });
  };

  cn = (value: number, rating: number) =>
    classnames(['mfb-DeliveryItem--star p-0 seasonal bg-white border-0'], {
      'is-selected': value <= rating,
    });

  handleClick = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLSpanElement>,
    r: number
  ) => {
    e.preventDefault();

    this.setState({isDisabled: true});

    await this.props.onClickRating(r);

    this.setState({isDisabled: false});
  };

  handleChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({comments: event.target.value});
  };

  saveComment = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLSpanElement>
  ) => {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen,
    });
    this.props.onSaveComment(this.state.comments);
  };

  render() {
    const {isDisabled} = this.state;

    return (
      <div>
        <div className="d-flex justify-content-center flex-nowrap">
          {[1, 2, 3, 4, 5].map(n => (
            <div key={n}>
              <button
                type="button"
                data-category="recipe"
                data-action="rate"
                data-value={n}
                data-test={`recipe-rate-${n}`}
                disabled={isDisabled}
                className={this.cn(n, this.props.rating)}
                onClick={async (e: React.MouseEvent<HTMLSpanElement>) =>
                  this.handleClick(e, n)
                }
              />
            </div>
          ))}
        </div>
        <div className="mt-2">
          {!this.state.isOpen && (
            <div>
              {this.props.comments && (
                <div className="mfb-RateRecipe--comment">
                  <a
                    onClick={this.toggleComments}
                    href="#"
                    data-test="recipe-comment-button"
                  >
                    "{this.props.comments}"
                  </a>
                </div>
              )}
              {!this.props.comments && (
                <a
                  href="#"
                  data-category="recipe"
                  data-action="open-add-comment"
                  onClick={this.toggleComments}
                  data-test="recipe-comment"
                >
                  Add comment
                </a>
              )}
            </div>
          )}
          {this.state.isOpen && (
            <div>
              <textarea
                maxLength={3000}
                className="form-control my-3"
                placeholder="Add a comment here"
                value={this.state.comments}
                onChange={this.handleChangeComment}
              />
              <div className="d-flex justify-content-start">
                <button
                  data-category="recipe"
                  data-action="add-comment"
                  className="btn btn-primary m-1"
                  onClick={this.saveComment}
                >
                  Save comment
                </button>
                <button
                  data-category="recipe"
                  data-action="close-add-comment"
                  className="btn btn-secondary m-1 ml-auto"
                  onClick={this.toggleComments}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
