import { Button, ButtonStyles, SvgGiftColoured, useThemeScreenSizes } from '@mfb/lego';
import { mapDispatchToProps } from 'app/account-settings/containers/accountSettingsContainer';
import { connect } from 'react-redux';
import { AppState } from 'redux/state';
import { CampaignTheme} from '@mfb/account-api-client';
import React from 'react';
import routeHistory from 'routerHistory';
import { deliveriesPath } from 'app/account-settings/components/Nav/paths';
import styled from 'styled-components';

export interface Props {
  setIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledButton = styled(Button)`
  white-space: nowrap;
  overflow: hidden;
  margin-top: 7px;
  @media ${c => c.theme.layout.deviceMediaQueries.max['lg']} {
    margin-top: -24px;
  }
`

const TickerTapeCampaignButtonUnconnected = (props) => {

  const isEnrolled = props.campaign.enrolledCampaignDto;
  const isEligible = props.campaign.eligibleCampaignDto && !isEnrolled;

  const {isScreenSize} = useThemeScreenSizes();

  const shouldDisplayComponent = (isEnrolled && props.campaign.enrolledCampaignDto.theme === CampaignTheme.TickerTape) || (isEligible && props.campaign.eligibleCampaignDto.theme === CampaignTheme.TickerTape);

  var label;
  if (isEnrolled) {
    label = props.campaign.enrolledCampaignDto.campaignButtonText;
  } else if (isEligible) {
    label = props.campaign.eligibleCampaignDto.joinTitle;
  }

  const onJoin = React.useCallback(() => {
    var campaign = props.campaign.eligibleCampaignDto;
    // @TODO Please use navman.campaignModal() when we rebase.
    routeHistory.history.push(
      `${deliveriesPath}/?campaignCode=${campaign.campaignCode}`
    );
    // @TODO John: GA Tracking
  }, [props.campaign.eligibleCampaignDto]);

  const onClick = () => {
    if (isEligible) {
      onJoin();
    } else {
      props.setIsPanelOpen(true);
    }
  }

  return (
    (shouldDisplayComponent) && (
      <StyledButton 
        animateIcon={isEligible}
        size='lg'
        variant={isScreenSize.max['lg'] ? 'full' : 'default'}
        onClick={e => onClick()}
        leftIcon={<SvgGiftColoured height={'24px'} width={'24px'}/>} 
        label={label} 
        buttonStyle={ButtonStyles.Secondary}
      />
    )
  )
};

const mapStateToProps = (state: AppState, props: Props) => {
  return {
    campaign: state.campaign,
    ...props
  };
};

export const TickerTapeCampaignButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(TickerTapeCampaignButtonUnconnected);
