declare let dataLayer: Array<any>;

export enum CancelSubscriptionEvent {
  CANCEL_STEP_1 = 'cancellation_step_1',
  CANCEL_COMPLETED = 'cancellation_completed',
}

export enum CancelSubscriptionLabel {
  CANCELLATION_STEP_1_LABEL = 'Cancellation Step 1',
  CANCELLATION_COMPLETION_LABEL = 'Cancellation Completed',
}

export const cancelSubscriptionStepOneTracker = async (
  subscriptionNumber: string,
  bag_name: string,
  bag_sku: string,
  subscription_time: string,
  subscription_people: number,
  subscription_nights: number
) => {
  dataLayer.push({
    event: CancelSubscriptionEvent.CANCEL_STEP_1,
    subscription_number: subscriptionNumber,
    bag_sku: bag_sku,
    bag_name: bag_name,
    subscription_people: subscription_people,
    subscription_nights: subscription_nights,
    subscription_time: subscription_time,
    eventDetails: {
      category: 'Modify Subscription',
      action: 'Cancellation',
      label: CancelSubscriptionLabel.CANCELLATION_STEP_1_LABEL,
    },
  });
  return;
};
export const cancelSubscriptionCompletedTracker = async (
  subscriptionNumber: string,
  bag_name: string,
  bag_sku: string,
  subscription_time: string,
  cancellation_reason: string,
  subscription_nights: number,
  subscription_people: number
) => {
  dataLayer.push({
    event: CancelSubscriptionEvent.CANCEL_COMPLETED,
    subscription_number: subscriptionNumber,
    bag_sku: bag_sku,
    bag_name: bag_name,
    subscription_people: subscription_people,
    subscription_nights: subscription_nights,
    subscription_time: subscription_time,
    cancellation_reason: cancellation_reason,
    eventDetails: {
      category: 'Modify Subscription',
      action: 'Cancellation',
      label: CancelSubscriptionLabel.CANCELLATION_COMPLETION_LABEL,
    },
  });
  return;
};
