/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import {
  AccountInformationClient,
  AccountSettingClient,
  AccountSettings,
  PaymentClient,
  SubscriptionClient,
  VoucherClient,
} from '../../shared/CoreClient';
import { globalSettings as settings } from 'config';
import Spinner from '../../shared/components/Spinner';
import {
  BaseApiConnector,
  getComponentName,
} from '../../shared/BaseApiConnector';

export interface AccountSettingsApi {
  fetchAccountSettingsState: AccountSettingClient['getPageModel'];
  submitAccountInfo: AccountInformationClient['updateAccountInfo'];
  validateSuggestedAddress: AccountInformationClient['validateAddress'];
  // Not used yet, uncomment as needed as other parts of Account Settings are migrated:
  // submitSubscriptionChange: SubscriptionClient['updateSubscription'],
  // removeCreditCard: PaymentClient['removeCreditCard'],
  // setActiveCreditCard: PaymentClient['setCreditCardAsDefault'],
  // cancelSubscription: SubscriptionClient['cancelSubscription'],
  // restartSubscription: SubscriptionClient['restartSubscription'],
  createSubscription: SubscriptionClient['createSubscription'];
  // redeemVoucher: VoucherClient['redeemVoucherCode'],
}

export interface AccountSettingsProps {
  accountSettings: AccountSettings;
  accountSettingsApi: AccountSettingsApi;
  isRefreshingAccountSettings: boolean;
}

type AnyReactComponent<T> =
  | React.ComponentClass<T>
  | React.FunctionComponent<T>;

const connectAccountSettings = <T extends {}>(
  WrappedComponent: AnyReactComponent<T & Partial<AccountSettingsProps>>,
  LoadingComponent: AnyReactComponent<any> = Spinner
) =>
  class extends BaseApiConnector<T, {}, AccountSettings> {
    static displayName = `connectAccountSettings(${getComponentName(
      WrappedComponent
    )})`;

    accountSettingsClient = new AccountSettingClient(settings.bffHost);
    accountInformationClient = new AccountInformationClient(settings.bffHost);
    subscriptionClient = new SubscriptionClient(settings.bffHost);
    paymentClient = new PaymentClient(settings.bffHost);
    voucherClient = new VoucherClient(settings.bffHost);
    accountSettingsApi: AccountSettingsApi;

    constructor(props: T) {
      super(props);
      const accountSettingsClient = this.wrapClient(this.accountSettingsClient);
      const accountInformationClient = this.wrapClient(
        this.accountInformationClient
      );
      // const subscriptionClient = this.wrapClient(this.subscriptionClient);
      // const paymentClient = this.wrapClient(this.paymentClient);
      // const voucherClient = this.wrapClient(this.voucherClient);

      this.accountSettingsApi = {
        fetchAccountSettingsState: accountSettingsClient.getPageModel.bind(
          accountSettingsClient
        ),
        submitAccountInfo: accountInformationClient.updateAccountInfo.bind(
          accountSettingsClient
        ),
        // validateAddress is unwrapped because it does not return a model
        validateSuggestedAddress: this.accountInformationClient.validateAddress.bind(
          this.accountInformationClient
        ),
        // Not used yet, uncomment as needed as other parts of Account Settings are migrated:
        // submitSubscriptionChange: subscriptionClient.updateSubscription,
        // removeCreditCard: paymentClient.removeCreditCard,
        // setActiveCreditCard: paymentClient.setCreditCardAsDefault,
        // cancelSubscription: subscriptionClient.cancelSubscription,
        // restartSubscription: this.subscriptionClient.restartSubscription.bind(accountSettingsClient),
        createSubscription: this.subscriptionClient.createSubscription.bind(
          accountSettingsClient
        ),
        // redeemVoucher: voucherClient.redeemVoucherCode,
      };
    }

    componentWillMount() {
      this.accountSettingsApi.fetchAccountSettingsState();
    }

    render() {
      if (this.state.model == null) {
        return <LoadingComponent />;
      }

      return (
        <WrappedComponent
          {...this.props}
          accountSettings={this.state.model}
          accountSettingsApi={this.accountSettingsApi}
          isRefreshingAccountSettings={this.state.modelUpdating}
        />
      );
    }
  };
//
export default connectAccountSettings;
