import { globalSettings as settings } from 'config';
import createUserManager, { UserManager } from '../UserManager';
import {
  AccountApiClient as BaseAccountApiClient,
  ApimClientConfiguration,
} from '@mfb/account-api-client';

class UserAuthenticatedConfiguration extends ApimClientConfiguration {
  private _bearerToken: string;

  setAuthentication(bearerToken: string): void {
    this._bearerToken = `Bearer ${bearerToken}`;
  }

  applyTransformToRequest(options: RequestInit): void {
    super.applyTransformToRequest(options);
    options.headers['Authorization'] = this._bearerToken;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      options.headers['x-mfb-customernumber'] = this.parseJwt(
        this._bearerToken
      )?.CustomerNumber;
    }
    options.credentials = 'omit';
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}

class AccountApiClient extends BaseAccountApiClient {
  private userManager: UserManager;

  constructor(apiKey?: string, baseUrl?: string) {
    const config = new UserAuthenticatedConfiguration();
    config.setApiKey(apiKey ?? settings.publicApiKey);

    super(config, baseUrl ?? settings.accountHost);

    this.transformOptions = async (options) => {
      this.userManager ??= await createUserManager();
      const token = await this.userManager.getBearerToken();
      config.setAuthentication(token);
      config.applyTransformToRequest(options);

      return options;
    };
  }
}

export const accountApiClient = new AccountApiClient();
