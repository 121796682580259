import { useCallback, useEffect, useState } from 'react';
import {
  DeliveryDateResponse,
  IOrderWithRecipesAndExtras,
} from '@mfb/account-api-client';
import { writeToSessionStorage } from '../../redux/sessionStorage';
import { AddressResponse } from '../shared/CoreClient';
import { accountApiClient } from '../shared/api';
import { getFormattedTimeSlot } from '../shared/hooks';

type AddressPreviewState = {
  timeslot: string;
  address: string;
  isLoading: boolean;
};
export const useAddressPreview = (order?: IOrderWithRecipesAndExtras) => {
  const [addressPreviewState, setAddressPreviewState] =
    useState<AddressPreviewState>({
      timeslot: '',
      address: '',
      isLoading: false,
    });
  const fetchAddressPreview = async (reFetch?: boolean) => {
    try {
      setAddressPreviewState((p) => ({ ...p, isLoading: true }));
      let addressResponse: AddressResponse;
      let timeslotResponse: DeliveryDateResponse;
      let _order: IOrderWithRecipesAndExtras = reFetch ? undefined : order;

      if (reFetch) {
        //We call the Pyw order API again to get updated address & timeslot without syncing with Redux. Not ideal, but the alternative would be to reload the whole Pyw.
        _order = (
          await accountApiClient.orderWeekSubscriptionNumber(
            order.deliveryDate.weekStarting,
            order.subscriptionNumber,
            undefined
          )
        ).orderWithRecipesAndExtras;
        addressResponse = _order.deliveryAddress;
        timeslotResponse = _order.overrideDeliveryDate ?? _order.deliveryDate;

        // Save the latest order response in session so that the `useDeliveryDetailsService can access the updated details if the user decides to reopen the form.
        // Note: Data is cleared upon PywLoader dismount.
        writeToSessionStorage(
          { orderKey: order.orderKey, type: 'addressReFetch' },
          _order
        );
      } else {
        addressResponse = order?.deliveryAddress;
        timeslotResponse = order?.overrideDeliveryDate ?? order.deliveryDate;
      }

      const address = `${addressResponse.street}, ${addressResponse.suburb}, ${addressResponse.city}, ${addressResponse.postcode}`;
      const timeslot = getFormattedTimeSlot(
        DeliveryDateResponse.fromJS(timeslotResponse)
      );
      setAddressPreviewState((p) => ({ ...p, timeslot, address }));
    } catch (e) {
      console.error(e);
    } finally {
      setAddressPreviewState((p) => ({ ...p, isLoading: false }));
    }
  };

  useEffect(() => {
    (async () => await fetchAddressPreview())();
  }, []);

  return { fetchAddressPreview, addressPreviewState };
};
