import React from 'react';
import {navman} from '../../../navigator';
import {
  CampaignParticipationStatus,
  CampaignGroupDto,
} from '../../shared/CoreClient';

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

interface Props {
  campaignGroupResponse: CampaignGroupDto;
}

const NotEnrollable: React.FunctionComponent<Props> = (props: Props) => {
  const {campaignGroupResponse} = props;

  const enrolmentStartFormatted = new Date(
    campaignGroupResponse.enrolmentStart
  ).toLocaleDateString('en-NZ', dateFormatOptions);

  const enrolmentEndFormatted = new Date(
    campaignGroupResponse.enrolmentEnd
  ).toLocaleDateString('en-NZ', dateFormatOptions);

  let title;
  let body;
  switch (campaignGroupResponse.campaignParticipationStatus) {
    case CampaignParticipationStatus.CampaignHasNotStarted:
      title = `This programme will start soon!`;
      body = `The first day to join is ${enrolmentStartFormatted}. We are excited to get started too.`;
      break;
    case CampaignParticipationStatus.CampaignHasEnded:
      title = `Sorry, you've just missed out on joining this time.`;
      body = `The last day to join was ${enrolmentEndFormatted}. Stay tuned for the next one.`;
      break;
    default:
      break;
  }

  return (
    <div className="mx-md-4 text-center">
      <h3 className="my-4 w-100">{title}</h3>
      <p className="mb-4 mb-sm-5 w-100">{body}</p>
      <button onClick={navman.yourAccount} className="btn btn-primary mb-5">
        Got it
      </button>
    </div>
  );
};

export default NotEnrollable;
