import {forEach} from 'lodash';
import React from 'react';

export const useMaxHeightByClassName = (className: string): number => {
  const [maxHeight, setMaxHeight] = React.useState(0);

  React.useLayoutEffect(() => {
    const siblings = window.document.getElementsByClassName(className);

    if (!siblings) {
      return;
    }

    let temp = 0;
    forEach(siblings, element => {
      if (element instanceof HTMLElement && element.offsetHeight >= temp) {
        temp = element.offsetHeight;
      }
    });

    setMaxHeight(temp);
  });

  return maxHeight;
};
