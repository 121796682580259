import React from 'react';
import Cookies from 'js-cookie';
import {Message, MessageActionDto} from '../CoreClient';
import AlertMessage from './AlertMessage';
import {Dispatch, Action} from 'redux';
import PropTypes from 'prop-types';
import {store} from '../../../index';

const reviver = (key: any, value: any): any =>
  typeof value === 'string' ? value.replace(/\+/g, ' ') : value;

const messageOf = (cookieValue: string): Message =>
  cookieValue != null
    ? (JSON.parse(decodeURIComponent(cookieValue), reviver) as Message)
    : undefined;

export interface MessageListProps {
  messages: Array<Message>;
}

const toButton = (action: MessageActionDto, dispatch: Dispatch<Action>) =>
  action
    ? {
        label: action.label,
        onClick: () => {
          dispatch({
            type: action.identifier,
            attribute: action.attribute,
          });
        },
      }
    : undefined;

// `MessageList` uses `Context` to get access to `store.dispatch`.
//
// A `Message` can contain a `MessageAction` which will dispatch an `Action`
// when invoked.
//
// `messageActionMiddleware` dispatch specific actions, e.g.
// `MessageAction.AddCreditCard` will `dispatch(addCreditCard)`.

const MessageList: React.FunctionComponent<MessageListProps> = ({
  messages,
}: MessageListProps) => {
  const cookieValue = Cookies.get('MFB.Message');
  const messagesFromCookies: Array<Message> = [messageOf(cookieValue)];
  const allMessages = messages.concat(
    messagesFromCookies.filter(v => v != null)
  );

  Cookies.remove('MFB.Message', {path: ''});

  return (
    <>
      {allMessages &&
        allMessages.length > 0 &&
        allMessages.map((message, index) => (
          <AlertMessage
            key={index}
            variant={message.variant}
            title={message.title}
            text={message.text}
            button={toButton(message.action, store.dispatch)}
            secondaryButton={toButton(message.secondaryAction, store.dispatch)}
            tertiaryButton={toButton(message.tertiaryAction, store.dispatch)}
          />
        ))}
    </>
  );
};

MessageList.contextTypes = {
  store: PropTypes.object,
};

export default MessageList;
