import {kebabCase} from 'lodash';
import React from 'react';
import {Link} from 'react-router-dom';
import MenuItem from '../MenuItem';
import RightChevronIcon from '../svgs/RightChevronIcon';

interface Props {
  menuItems: Array<MenuItem>;
}

const NavMobileLegacy: React.FunctionComponent<Props> = ({menuItems}: Props) => (
  <nav className="nav pt-4 flex-column">
    {menuItems.map(item => {
      const IconComponent = item.icon;

      return (
        <Link
          key={item.title}
          className="nav-item text-dark d-flex mb-2"
          to={item.path}
          data-category="manage-account-nav-item"
          data-label={kebabCase(item.title)}
          data-action="click"
        >
          <div className="pt-1 pr-3">
            <IconComponent width="16" height="16" />
          </div>
          <div className="mr-auto">
            <h2 className="h3 mb-0">{item.title}</h2>
            <p>{item.description}</p>
          </div>
          <div className="align-self-center pl-3">
            <RightChevronIcon width="12" height="12" />
          </div>
        </Link>
      );
    })}
  </nav>
);

export default NavMobileLegacy;
