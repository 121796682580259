import React from 'react';
import {GroupedCampaignDto} from '../../shared/CoreClient';
import {ComparisonTile} from './ComparisonTile';
import styled from 'styled-components';

interface Props {
  campaigns: Array<GroupedCampaignDto>;
  selectedCampaignCode: string;
  onClick(selectedCampaignCode: string): void;
}

const ScrollableDiv = styled.div`
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow-x: scroll;
  overflow-y: hidden;
`;

export const CampaignSelector: React.FC<Props> = ({
  campaigns,
  selectedCampaignCode,
  onClick,
}) => (
  <ScrollableDiv className="d-flex flex-nowrap row mb-4">
    {campaigns.map(c => (
      <ComparisonTile
        campaignCode={c.campaignCode}
        campaignName={c.campaignName}
        isSelected={c.campaignCode === selectedCampaignCode}
        marketing={c.marketing}
        onClick={onClick}
        key={`${c.campaignName}-${c.campaignCode}`}
      />
    ))}
  </ScrollableDiv>
);
