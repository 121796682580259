import { globalSettings as settings } from 'config';


export enum ZendeskAction {
  Open = 'open',
  Close = 'close',
  UnreadMessages = 'unreadMessages',
}

export const onZendeskScriptReady = (
  callBack: () => void,
  intervalMs = 1500,
  maxRunTimeMs = 240000, //4 mins
  gtmContainerId = settings.gtmContainerId
): boolean => {
  //check if script is available in the dom
  let elapsedTimeMs = 0;
  const intervalId = setInterval(() => {
    elapsedTimeMs += intervalMs;
    const gtmScript = document.querySelector(
      `head script[gtm="${gtmContainerId}"]`
    );
    if (gtmScript) {
      clearInterval(intervalId);
      callBack();
      return true;
    } else if (elapsedTimeMs >= maxRunTimeMs) {
      clearInterval(intervalId);
      console.error(`onZendeskScriptReady timed out after ${maxRunTimeMs}ms.`);
      return false;
    }
  }, intervalMs);
  return false;
};

export const addZendeskUnreadMessageListener = (): boolean => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    zE(
      'messenger:on',
      ZendeskAction.UnreadMessages,
      (unreadMessageCount: number) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        unreadMessageCount > 0 ? zE('messenger', ZendeskAction.Open) : null;
      }
    );
    return true;
  } catch (e) {
    console.log(`zendesk error :${e}`);
    return false;
  }
};

export const openZendesk = (): void => {
  const messagingWindowIframe = document.querySelector<HTMLIFrameElement>(
    'iframe[name="Messaging window"]'
  );
  let action = ZendeskAction.Close;
  if (!messagingWindowIframe || messagingWindowIframe.tabIndex === -1) {
    action = ZendeskAction.Open;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  zE('messenger', action);
};
