import React from 'react';
import {
  BagDisplay,
  RecipeBagDisplayPayload,
  RecipesForWeek,
} from '../../CoreClient';
import {BagTypeSelect} from './BagTypeSelection';
import BagRecipesContainer from './BagRecipesContainer';
import connectRecipes from '../../connectors/connectRecipes';
import Select from 'react-select';
import ProductFilter, {
  productsFilteredByFeeds,
} from '../../../account-settings/components/MySubscriptions/ProductFilter';
import {SvgTickIcon} from '@mfb/lego';

export interface Props {
  bagSelection: Array<BagDisplay>;
  recipesModel?: RecipesForWeek;
  optionId?: number;
  selectedProductFilterValue: number;
  promotedCampaignName: string;
  onSelectBag(bagId: number): void;
  onProductFilterChange(option: Select.Option): void;
}

const BagChooser: React.FunctionComponent<Props> = props => {
  const {
    bagSelection,
    recipesModel,
    optionId,
    selectedProductFilterValue,
    onProductFilterChange,
    onSelectBag,
    promotedCampaignName,
  } = props;

  return (
    <div>
      <div className="form-group">
        <label htmlFor="primaryBagId">Number of People</label>
        <ProductFilter
          selectedValue={selectedProductFilterValue}
          onChange={onProductFilterChange}
          bags={bagSelection}
        />
      </div>
      <div className="form-group">
        <label htmlFor="primaryBagId">Choose a Bag</label>
        <BagTypeSelect
          className="test-choose-bag"
          bags={productsFilteredByFeeds(
            bagSelection,
            selectedProductFilterValue
          )}
          selectedBagId={optionId}
          onChange={onSelectBag}
        />
        {promotedCampaignName && (
          <p
            className="text-success d-flex align-items-center pt-1"
            data-test="campaign-joinable-text"
          >
            <SvgTickIcon className="mr-2" />
            {`You'll be able to join the ${promotedCampaignName} with this bag.`}
          </p>
        )}
        <BagRecipesContainer recipesModel={recipesModel} optionId={optionId} />
      </div>
    </div>
  );
};

export const UnconnectedBagChooser = BagChooser;

export type ConnectedBagChooserProps = Props & RecipeBagDisplayPayload;
export default connectRecipes(BagChooser);
