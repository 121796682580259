import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AccountFeatures, FeaturesClient } from '../../app/shared/CoreClient';
import { globalSettings as settings } from 'config';
import { AccountFeaturesState, AppState } from '../state';

export const RECIEVE_FEATURES = 'RECIEVE_FEATURES';

export interface ReceiveFeatureAction extends Action {
  type: string;
  payload: AccountFeaturesState;
}

const receiveFeatureActionCreator = (
  features: AccountFeatures,
  lastFetchedAt: Date
): ReceiveFeatureAction => ({
  type: RECIEVE_FEATURES,
  payload: {
    ...features,
    requestTime: lastFetchedAt,
    requestSource: '',
  },
});

export const fetchFeaturesThunk = (): ThunkAction<
  Promise<ReceiveFeatureAction>,
  AppState,
  never
> => {
  return async (dispatch) => {
    const client = new FeaturesClient(settings.bffHost);
    const accountFeatures = await client.getAccountFeatures();

    return dispatch(
      receiveFeatureActionCreator(
        accountFeatures as AccountFeatures,
        new Date()
      )
    );
  };
};
