import React from 'react';
import PrizeImage from './PrizeImage';
import {kebabCase} from 'lodash';
import classnames from 'classnames';
import {CampaignPrizeDto} from '../../shared/CoreClient';

interface Props {
  prize: CampaignPrizeDto;
  tierId: number;
  isSelected: boolean;
  tierOnlyHasOnePrize: boolean;
  isSingleTierCampaign: boolean;
  campaignName: string;
  onClick?(
    e: React.MouseEvent<HTMLDivElement>,
    tierId: number,
    prizeId: number
  ): void;
}

const Prize: React.FC<Props> = ({
  prize,
  tierId,
  isSelected,
  tierOnlyHasOnePrize,
  isSingleTierCampaign,
  onClick,
}) => {
  const shouldLimitImageHeight = tierOnlyHasOnePrize && isSingleTierCampaign;
  const paddingSizes = {
    small: '75%',
    medium: shouldLimitImageHeight ? '45%' : '',
  };

  return (
    <div
      className={classnames(
        'my-2 mb-sm-4 w-100',
        tierOnlyHasOnePrize ? 'pl-1 px-sm-0' : 'px-1 px-sm-2'
      )}
      style={{
        cursor: onClick ? 'pointer' : 'default',
        pointerEvents: prize.isSoldOut ? 'none' : 'all',
      }}
      onClick={e => onClick && onClick(e, tierId, prize.campaignPrizeId)}
      // TODO: Confirm requirements with Vini
      // data-category="campaign"
      // data-action={`campaign-modal-click-${isSelected ? 'selected-prize' : 'not-selected-prize'}`}
      // data-label={`${kebabCase(campaignName)} -  ${kebabCase(prize.name)}`}
      data-test={`select-prize - ${kebabCase(prize.name)}`}
    >
      <PrizeImage
        prize={prize}
        isSelected={isSelected && !tierOnlyHasOnePrize}
        paddingSizes={paddingSizes}
      />
      <p className="text-center mt-2 px-2">{prize.name}</p>
    </div>
  );
};

export default Prize;
