import React, { useEffect, useState } from 'react';
import {
  CustomerProfileState,
  OrdersPageState,
  TrackingState,
} from 'redux/state';
import styled from 'styled-components';
import { DeliveryDateResponse } from '@mfb/account-api-client';
import { PriceSummary } from '@mfb/cookbook';
import { Button, CoreColors, PreviewMessage } from '@mfb/lego';
import { OrderWithRecipesAndExtras } from '../../shared/CoreClient';
import { getFormattedTimeSlot } from '../../shared/hooks/editDeliveryDetails/timeslotSelector';
import { useEditDeliveryDetailsService } from '../../shared/hooks/editDeliveryDetails/useEditDeliveryDetailsService';
import { QueryParameter } from '../../../queryParameter';

const Details = styled.section<{
  canEditDeliveryDetails: boolean;
}>`
  display: flex;
  margin-top: 56px;
  width: 100%;
  max-width: 680px;

  .__hidden-box,
  .__button {
    display: ${(c) => (c.canEditDeliveryDetails ? 'flex' : 'none')};
  }

  .__price-summary-container {
    width: 100%;

    h6 {
      display: none;
    }

    .__hidden-box {
      width: 1px;
      height: 42px;
    }
  }

  .__delivery-details-container {
    margin-left: 20px;
    width: 100%;

    .__preview-message {
      border: 1px solid ${CoreColors.neutral['300']};
      padding: 16px;
      animation: none;

      .__header-container {
        margin-bottom: 12px;
      }
    }
  }

  .__header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;

    h6 {
      display: block;
      font-size: 19px;
      font-weight: 900;
      padding: 0;
      margin: 0;
    }

    align-items: center;
  }

  @media ${(c) => c.theme.layout.deviceMediaQueries.max.md} {
    flex-direction: column-reverse;

    .__delivery-details-container {
      margin-left: 0;
      margin-bottom: 32px;
    }
  }
`;

interface Props {
  order: OrderWithRecipesAndExtras;

  handleEditSubscription?(id: number, subNumber: string): void;
  customerProfileState: CustomerProfileState;
  fetchOrderPageThunk: () => Promise<OrdersPageState>;
  tracking?: TrackingState;
}

export const OrderFooter: React.FC<Props> = ({
  order,
  handleEditSubscription,
  tracking,
  fetchOrderPageThunk,
}) => {
  const [isEditFormDetailsLoading, setIsEditFormDetailsLoading] =
    useState<boolean>(false);

  const { displayEditDelivery } = useEditDeliveryDetailsService({
    currentOrder: order,
    deliveryWeek: order.deliveryDate?.weekStarting?.toString(),
    tracking,
    postSuccessAction: fetchOrderPageThunk,
  });

  useEffect(() => {
    (async () => {
      await openEditDeliveryDetailsByQueryParam();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAddressPreview = () => {
    const details = { address: '', timeslot: '' };

    try {
      const addressResponse = order.deliveryAddress;
      const timeslotResponse = order.overrideDeliveryDate ?? order.deliveryDate;

      details.address = `${addressResponse.street}, ${addressResponse.suburb}, ${addressResponse.city}, ${addressResponse.postcode}`;
      details.timeslot = getFormattedTimeSlot(
        DeliveryDateResponse.fromJS(timeslotResponse)
      );
    } catch (e) {
      console.error(e);
    }
    return details;
  };

  const canEditDeliveryDetails = () => {
    return !!order.orderState?.canChangeDeliveryShipping.isValid;
  };

  const openEditDeliveryDetailsByQueryParam = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const shouldOpenEditDeliveryDetailsForm =
        queryParams.get(QueryParameter.showEditDeliveryDetails) === 'true';

      if (shouldOpenEditDeliveryDetailsForm) {
        await onEditDeliveryDetails();
        const currentURL = new URL(window.location.href);
        currentURL.searchParams.set(QueryParameter.showEditDeliveryDetails, 'false');
        window.history.pushState({}, '', currentURL.href);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onEditDeliveryDetails = async () => {
    setIsEditFormDetailsLoading(true);
    await displayEditDelivery();
    setIsEditFormDetailsLoading(false);
  };

  return (
    <>
      <Details canEditDeliveryDetails={canEditDeliveryDetails()}>
        <div className="__price-summary-container">
          <div className={'__header-container'}>
            <h6>Order summary</h6>
            <span className={'__hidden-box'} />
          </div>
          <PriceSummary
            isLoading={false}
            deliveryTotalCost={order.pricing.overallTotal}
            discountSourceType={order.pricing.discounts}
            pricingLineSummary={order.pricing.summary}
            shippingCost={order.pricing.shipping}
          />
        </div>
        <div className={'__delivery-details-container'}>
          <div className={'__header-container'}>
            <h6>Delivery details</h6>
            <Button
              data-test={'delivery-details-edit-button'}
              className={'__button'}
              label={'Edit'}
              onClick={onEditDeliveryDetails}
              isLoading={isEditFormDetailsLoading}
            />
          </div>
          <PreviewMessage
            dataTestId="delivery-details-preview-message"
            dataTestIdBody="delivery-details-preview-message-body"
            dataTestIdTitle="delivery-details-preview-message-title"
            dataTestIdExpandableText="delivery-details-preview-message-expandable-text"
            className={'__preview-message'}
            heading={getAddressPreview().timeslot}
            body={getAddressPreview().address}
            variant={'border'}
          />
        </div>
      </Details>
    </>
  );
};
