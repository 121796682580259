import {
  MenuItemProps,
  SvgCalendarClock,
  SvgCreditCard,
  SvgDocument,
  SvgFingerprint,
  SvgTicket,
  SvgSunIcon,
  SvgSubscriptionPlanGift,
  SvgCalendarIcon,
} from '@mfb/lego';
import {
  subscriptionsPath,
  paymentDetailsPath,
  personalDetailsPath,
  orderHistoryPath,
  vouchersPath,
  referAFriendPath,
  holidaysPath,
  calendarPath
} from './paths';
import routeHistory from 'routerHistory';

export const MenuItems: Array<MenuItemProps> = [
  {
    title: 'Subscriptions',
    path: subscriptionsPath,
    icon: <SvgCalendarClock />,
    description: 'Change Product, Delivery Time, Extras.',
    isActive: false,
    onClick: () => routeHistory.history.push(subscriptionsPath),
  },
  {
    title: 'Payment Details',
    path: paymentDetailsPath,
    icon: <SvgCreditCard />,
    description: 'Credit cards for payment.',
    isActive: false,
    onClick: () => routeHistory.history.push(paymentDetailsPath),
  },
  {
    title: 'Personal Details',
    path: personalDetailsPath,
    icon: <SvgFingerprint />,
    description: 'Name, Email, Address, Delivery Instructions, Password.',
    isActive: false,
    onClick: () => routeHistory.history.push(personalDetailsPath),
  },
  {
    title: 'Order History',
    path: orderHistoryPath,
    icon: <SvgDocument />,
    description: 'View previous delivery history.',
    isActive: false,
    onClick: () => routeHistory.history.push(orderHistoryPath),
  },
  {
    title: 'Vouchers',
    path: vouchersPath,
    icon: <SvgTicket />,
    description: 'Redeem gift vouchers.',
    isActive: false,
    onClick: () => routeHistory.history.push(vouchersPath),
  },
  {
    title: 'My Deals',
    path: referAFriendPath,
    icon: <SvgSubscriptionPlanGift />,
    description: 'My Deals.',
    isActive: false,
    onClick: () => routeHistory.history.push(referAFriendPath),
  },
  {
    title: 'Delivery Calendar',
    path: calendarPath,
    icon: <SvgCalendarIcon/>,
    description: 'Manage deliveries using calendar',
    isActive: false,
    onClick: () => routeHistory.history.push(calendarPath),
  }
];

export const HolidaysMenuItem: MenuItemProps = {
  title: 'Holidays',
  path: holidaysPath,
  icon: <SvgSunIcon />,
  description: 'Manage deliveries for a Holiday.',
  isActive: false,
  onClick: () => routeHistory.history.push(holidaysPath),
};
