import { format } from 'date-fns';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import {
  DeliveryDateResponse,
  DeliveryDatesResponse, GetSalesShippingDeliveryDatesRequestBody,
  IDeliveryDateResponse,
  ValidateDeliveryAddressDto,
} from '@mfb/account-api-client';
import {
  DropdownSearchInputState,
  EditDeliveryFormContextProps,
  EditDeliveryFormInputValues,
  PillBadgeProps,
  SelectorItem,
} from '@mfb/lego';
import { OrderWithRecipesAndExtras } from '../../CoreClient';
import { accountApiClient } from '../../api';
import { DeliveryLine } from '../../constants';
import { offsetTimeZone } from '../../helper';
import { AddressIdentifier, validateAddress } from './addressFinder';
import {
  addressSelectedMessage,
  leavingDisabledTimeSlotMessage,
  nonDeliverableAddressPreviewMessage, noTimeSlotsAvailablePreviewMessage,
  timeSlotChangedWarningMessage,
} from './feedbackMessages';
import { FormDefaults } from './useEditDeliveryDetailsService';

export type TimeSlotIdentifier = {
  timeSlotId?: number;
  deliveryDateId?: number;
};

export const serializeTimeslotIdentifier = (
  timeSlotIdentifier: TimeSlotIdentifier,
) => {
  return JSON.stringify(timeSlotIdentifier);
};

export const deserializeTimeslotIdentifier = (id?: string) => {
  try {
    return JSON.parse(id) as TimeSlotIdentifier;
  } catch (e) {
    console.error(e);
  }
};

export const fetchTimeSlots = async ({
                                       state,
                                       formDefaults,
                                       formInputValues,
                                       currentOrder,
                                       addressId,
                                       pafId,
                                     }: AddressIdentifier & {
  state: [
    EditDeliveryFormContextProps,
    Dispatch<SetStateAction<EditDeliveryFormContextProps>>,
  ];
  formDefaults: MutableRefObject<FormDefaults>;
  formInputValues: MutableRefObject<Partial<EditDeliveryFormInputValues>>;
  currentOrder: OrderWithRecipesAndExtras;
}) => {
  if (!pafId && !addressId) return;
  const [, setState] = state;
  setState((p) => ({
    ...p,
    primaryButton: { ...p.primaryButton, disabled: true },
    timeSlot: { ...p.timeSlot, variant: 'loading' },
  }));

  let slots: DeliveryDatesResponse;
  let validateAddressResponse: ValidateDeliveryAddressDto;
  try {
    if (pafId) {
      validateAddressResponse = await validateAddress(pafId, formDefaults);
      addressId = validateAddressResponse.addressId;
    }

    const sku = currentOrder.deliveryLines.find(
      (c) =>
        c.deliveryLineType.toLowerCase() === DeliveryLine.product.toLowerCase(),
    ).sku;

    const payload = new GetSalesShippingDeliveryDatesRequestBody({
      sku,
      addressId,
      deliveryId: formDefaults.current.deliveryId,
      weekStarting: offsetTimeZone(currentOrder.deliveryDate.weekStarting),
    });

    try {
      slots = await accountApiClient.salesOrderShippingDeliverydates(payload);
    } catch (err) {
      slots = { deliveryDates: [] } as DeliveryDatesResponse;
    }
    formDefaults.current.fetchedTimeslots?.push(...slots.deliveryDates);

    const items = slots.deliveryDates.map((c) => {
      return {
        id: serializeTimeslotIdentifier({
          timeSlotId: c.deliverySlotId,
          deliveryDateId: c.deliveryDateId,
        }),
        text: getFormattedTimeSlot(c),
        disabled: c.isDisabled,
        pillBadgeProps: c.marketingLabel ? {
          size: 'small',
          label: c.marketingLabel,
          bgColour: c.isDisabled ? '#FCE5E5' : '#FBECC4',
          fgColour: c.isDisabled ? '#B31212' : '#543F06',
        } as PillBadgeProps : null,
      } as SelectorItem;
    });

    //Defaulting behaviour for new time slots
    const currentSavedId = formDefaults.current.deliveryDateId;

    let selectedIndex = -1;

    const foundWithDeliveryDateId = slots.deliveryDates.findIndex(
      (c) => c.deliveryDateId === currentSavedId,
    );
    selectedIndex = foundWithDeliveryDateId;

    //If found a slot to default to, set that as selected.
    if (selectedIndex === -1) {
      const foundWithSlotId = slots.deliveryDates.findIndex(
        (c) => c.deliverySlotId === currentSavedId && !c.isDisabled,
      );
      selectedIndex = foundWithSlotId;
    }

    //If all that fails, check to see if there are any enabled slots at all.
    if (selectedIndex === -1) {
      selectedIndex = slots.deliveryDates.findIndex(c => !c.isDisabled);
    }

    //Set that one as selected
    if (selectedIndex !== -1) {
      items[selectedIndex].selected = true;
      items[selectedIndex].disabled = false;
      items[selectedIndex].pillBadgeProps = null;
    }

    formInputValues.current.timeslot = selectedIndex !== -1 ? items[selectedIndex] : null;

    setState((p) => {
      return {
        ...p,
        timeSlot: {
          ...p.timeSlot,
          defaultItem: selectedIndex !== -1 ? items[selectedIndex] : null,
          items,
        },
      };
    });
  } catch (e) {
    console.error(e);
  } finally {
    setPreviewMessages(
      state,
      formDefaults,
      formInputValues,
      slots?.deliveryDates,
      validateAddressResponse,
    );
  }

  return { isValidAddress: validateAddressResponse?.isValid ?? false };
};

const setPreviewMessages = (
  state: [
    EditDeliveryFormContextProps,
    Dispatch<SetStateAction<EditDeliveryFormContextProps>>,
  ],
  formDefaults: MutableRefObject<FormDefaults>,
  formInputValues: MutableRefObject<Partial<EditDeliveryFormInputValues>>,
  timeslots?: DeliveryDateResponse[],
  validateAddressResponse?: ValidateDeliveryAddressDto,
) => {
  const [, setState] = state;
  const addressError = validateAddressResponse && !validateAddressResponse.isValid;
  const timeslotError = !timeslots || timeslots.length === 0 || formInputValues.current.timeslot == null;

  // Set error message for invalid address
  if (addressError) {
    setState((p) => ({
      ...p,
      address: {
        ...p.address,
        componentState: DropdownSearchInputState.critical,
      },
      addressPreviewMessage: nonDeliverableAddressPreviewMessage(state, validateAddressResponse?.errorType),
      timeSlot: { ...p.timeSlot, variant: 'default', defaultItem: { text: '' } },
    }));
  }

  // Set  message for valid address
  if (!addressError && formInputValues.current.address) {
    setState((p) => ({
      ...p,
      primaryButton: { ...p.primaryButton, disabled: false },
      address: {
        ...p.address,
        componentState: DropdownSearchInputState.default,
      },
      addressPreviewMessage: addressSelectedMessage(
        formInputValues.current.address?.text,
      ),
    }));
  }

  // Set error message for invalid timeslot
  if (timeslotError && !addressError) {
    setState((p) => ({
      ...p,
      timeSlot: { ...p.timeSlot, defaultItem: { text: '' }, variant: 'critical' },
      timeslotPreviewMessage: noTimeSlotsAvailablePreviewMessage(state),
    }));
  } else if (!isSelectedTimeSlotSameAsDefault(formDefaults, formInputValues) && !timeslotError) {
    setState((p) => ({
      ...p,
      timeSlot: { ...p.timeSlot, variant: 'warning' },
      timeslotPreviewMessage: timeSlotChangedWarningMessage(
        formInputValues.current?.timeslot?.text,
        formDefaults,
        state,
      ),
    }));
  } else {
    setState((p) => ({
      ...p,
      timeSlot: { ...p.timeSlot, variant: 'default' },
      timeslotPreviewMessage: undefined,
    }));
  }
};

export const isSelectedTimeSlotSameAsDefault = (
  formDefaults: MutableRefObject<FormDefaults>,
  formInputValues: MutableRefObject<Partial<EditDeliveryFormInputValues>>,
) => {
  if (!formInputValues.current.timeslot) {
    return false;
  }
  const timeslotIdentifier = deserializeTimeslotIdentifier(
    formInputValues.current.timeslot.id,
  );
  return formDefaults.current.timeSlotId === timeslotIdentifier.timeSlotId ||
    formDefaults.current.deliveryDateId === timeslotIdentifier.deliveryDateId;
};

export const onTimeSlotSelected = (
  selectorItem: SelectorItem,
  formInputValues: MutableRefObject<Partial<EditDeliveryFormInputValues>>,
  formDefaults: MutableRefObject<FormDefaults>,
  state: [
    EditDeliveryFormContextProps,
    Dispatch<SetStateAction<EditDeliveryFormContextProps>>,
  ],
) => {
  let defaultSlot = formDefaults.current.fetchedTimeslots.find(slot => slot.deliveryDateId === formDefaults.current.deliveryDateId);
  let newDeliveryDateId = deserializeTimeslotIdentifier(
    selectorItem.id,
  ).deliveryDateId;
  const [, setState] = state;
  //If user is moving from a locked delivery slot, display warning.
  if (defaultSlot?.isDisabled && newDeliveryDateId !== defaultSlot?.deliveryDateId) {
    setState((p) => ({
      ...p,
      timeslotPreviewMessage: leavingDisabledTimeSlotMessage(formDefaults),
    }));
  }

  formInputValues.current.timeslot = selectorItem;

  setState((p) => {
    const timeSlots = p.timeSlot.items;

    const currentIndex = timeSlots.findIndex(ts => ts.selected);
    const newIndex = timeSlots.findIndex(ts => selectorItem.id === ts.id);

    if (currentIndex !== -1) {
      timeSlots[currentIndex].selected = false;
    }

    timeSlots[newIndex].selected = true;
    return {
      ...p,
      timeSlot: {
        ...p.timeSlot,
        items: timeSlots,
      },
    };
  });
};

export const getFormattedTimeSlot = (
  date: Pick<
    IDeliveryDateResponse,
    'actualDeliveryStartTime' | 'actualDeliveryEndTime' | 'actualDeliveryDate'
  >,
) => {
  const format24Hr = (time24hr: string) => {
    const [hours, minutes, seconds] = time24hr.split(':').map(Number);
    const _date = new Date();
    _date.setHours(hours, minutes, seconds);

    return format(_date, 'h:mm aaaa').replace(' ', '').replaceAll('.', '');
  };

  try {
    return `${format(date.actualDeliveryDate, 'eee')} ${format24Hr(
      date.actualDeliveryStartTime,
    )} - ${format24Hr(date.actualDeliveryEndTime)}`;
  } catch (e) {
    console.error(e);
  }
};
