import React from 'react';
import {
  HolidayClient,
  GetCustomerAddressHolidayInformationResponse,
} from '../../../shared/CoreClient';
import {AsyncButton, usePromiseConfirmationModal} from '@mfb/lego';
import { globalSettings as settings } from 'config';
import {HolidaySummary} from './HolidaySummary/HolidaySummary';
import {showBadToast, showGoodToast} from '../../../toast/ToastMessages';
import {TermsAndConditions} from './TermsAndConditions';
import Spinner from '../../../shared/components/Spinner';
import {dateFormat} from '../../../../lib/date/dateFormat';

const DATE_FORMAT = 'EEE dd MMM, yyyy';
const HOLIDAY_CLIENT = new HolidayClient(settings.bffHost);

export const HolidayAddressReadOnly: React.FC<GetCustomerAddressHolidayInformationResponse> = props => {
  const {
    holidayAddress,
    defaultAddress,
    holidayDeliverySlot,
    firstHolidayDeliveryDate,
    lastHolidayDeliveryDate,
    lastDeliveryDateToOriginalAddress,
    resumingDeliveryDateToDefaultAddress,
    holidayDeliveryPeriodMessage,
  } = props;

  const [
    nextDeliveryDateForDefaultSlot,
    setNextDeliveryDateForDefaultSlot,
  ] = React.useState<Date>();

  const [getConfirmation, confirmationModal] = usePromiseConfirmationModal({
    title: `Are you sure you want to cancel your holiday address change?`,
    content: (
      <>
        {nextDeliveryDateForDefaultSlot ? (
          <p>
            Cancelling will remove your address change from the next available
            week going forward. We&apos;ll restart deliveries to your default
            address on {dateFormat(nextDeliveryDateForDefaultSlot, 'd MMMM')}.
          </p>
        ) : (
          <Spinner />
        )}
      </>
    ),
    dataCategory: 'holiday-address-changes',
    dataLabel: 'cancel-holiday-changes',
    cancelText: 'Go Back',
    maxWidth: '680px',
  });

  const handleCancelClick = async () => {
    HOLIDAY_CLIENT.getNextForecastDeliveryOnCancellation().then(
      setNextDeliveryDateForDefaultSlot
    );
    try {
      if (await getConfirmation()) {
        await HOLIDAY_CLIENT.cancelCustomerHolidayAddress();
        showGoodToast('Changes saved.');
        window.location.reload();
      }
    } catch (e) {
      showBadToast('Something went wrong. Please try again');
    }
  };

  return (
    <>
      <h3 className="mt-2">Your dinner is deliciously sorted this summer! </h3>
      <p>
        We’ve got you covered these summer holidays. We’ll make the below
        changes to your delivery during your holiday.
      </p>

      <hr className="my-5" />
      <div className="mt-4">
        <h4 className="mb-2">Your holiday address</h4>
        <p>{holidayAddress}</p>
      </div>

      <div className="mt-5">
        <h4 className="mb-2">Your preferred holiday delivery slot</h4>
        <p>{holidayDeliverySlot}</p>
      </div>

      <div className="mt-5">
        <h4 className="mb-2">Your holiday delivery dates</h4>
        <div className="d-flex mt-3">
          <div className="d-flex flex-column mr-5">
            <span className="small mb-1">First Holiday Delivery</span>
            {dateFormat(firstHolidayDeliveryDate, DATE_FORMAT)}
          </div>
          <div className="d-flex flex-column">
            <span className="small mb-1">Last Holiday Delivery</span>
            {dateFormat(lastHolidayDeliveryDate, DATE_FORMAT)}
          </div>
        </div>
      </div>

      <div className="mt-5">
        <HolidaySummary
          holidayAddress={holidayAddress}
          defaultAddress={defaultAddress}
          firstHolidayDeliveryDate={new Date(firstHolidayDeliveryDate)}
          lastHolidayDeliveryDate={new Date(lastHolidayDeliveryDate)}
          lastDeliveryDateToOriginalAddress={
            new Date(lastDeliveryDateToOriginalAddress)
          }
          resumingDeliveryDateToDefaultAddress={
            new Date(resumingDeliveryDateToDefaultAddress)
          }
          holidayDeliveryPeriodMessage={holidayDeliveryPeriodMessage}
        />
      </div>
      <div className="d-flex-grow d-md-inline-flex">
        <AsyncButton
          className="btn btn-secondary btn-block rounded-lg"
          onClick={handleCancelClick}
          data-category="holiday-address-changes"
          data-label="cancel-holiday-changes"
          data-action="click"
        >
          Cancel My Holiday Changes
        </AsyncButton>
      </div>
      {confirmationModal}
      <TermsAndConditions className="mt-4" />
    </>
  );
};
