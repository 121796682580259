/* eslint-disable @typescript-eslint/ban-types */
/**
 * Gets all property names on an object, including non-enumerable inherited properties. This can be useful to get ES6
 * classes' static members, as they are all non-enumerable.
 *
 * If a `stopAt` is provided, this will only traverse up the prototype chain until it finds a prototype matching the
 * `stopAt` constructor. This can be used for filtering out `Object` or a base class's prototype keys.
 */
const getAllPropertyNames = (obj: object, stopAt?: object): Array<string> => {
  const props: {[key: string]: null} = {};
  let currentObj = obj;

  while (
    currentObj != null &&
    (stopAt == null || currentObj.constructor !== stopAt)
  ) {
    Object.getOwnPropertyNames(currentObj).forEach(key => (props[key] = null));
    currentObj = Object.getPrototypeOf(currentObj);
  }

  return Object.keys(props);
};

export default getAllPropertyNames;
