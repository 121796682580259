import { Action, ActionCreator, Dispatch, Store } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../state';

export const SET_SELECTED_DAY = 'deliveries/SET_SELECTED_DAY';
export interface SetSelectedDayAction extends Action {
  type: 'deliveries/SET_SELECTED_DAY';
  payload: SetDeliveryDayPayload;
}
export interface SetDeliveryDayPayload {
  deliveryDay: string;
}

export const performDaySelectionAction: ActionCreator<
  ThunkAction<Promise<void>, unknown, null>
> = (deliveryDay: string) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_DAY,
    payload: { deliveryDay },
  });
};
