import { Reducer } from 'redux';
import { CampaignState } from 'redux/state';
import {
  CampaignProgressStateAction,
  RECEIVE_CAMPAIGN_STATE,
  ReceiveCampaignStateAction,
} from 'redux/actions/sharedActions/campaignProgressAction';

const reducer: Reducer<CampaignState> = (
  state = null,
  action: CampaignProgressStateAction
): CampaignState => {
  switch (action.type) {
    case RECEIVE_CAMPAIGN_STATE: {
      state = (action as ReceiveCampaignStateAction).payload;
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
