import {
  FETCH_TRANSLATION,
  FetchTranslationsAction,
  INIT_I18N,
  Initi18nAction,
} from 'redux/actions/translationActions';
import { Reducer, Action } from 'redux';
import { InitOptions } from 'i18next';
import { HttpBackendOptions } from 'i18next-http-backend';

export type TranslationActions =
  | FetchTranslationsAction
  | Initi18nAction
  | Action;

export interface TranslationsState {
  translations: Record<string, string | {}>;
  i18nInitOptions?: InitOptions<HttpBackendOptions>;
}

export const defaultTranslationState: TranslationsState = {
  translations: {},
  i18nInitOptions: undefined,
};

const reducer: Reducer<TranslationsState> = (
  state = defaultTranslationState,
  action: TranslationActions
): TranslationsState => {
  switch (action.type) {
    case INIT_I18N:
      const initAction = action as Initi18nAction;
      return {
        ...state,
        i18nInitOptions: initAction.payload,
      };
    case FETCH_TRANSLATION:
      const translationAction = action as FetchTranslationsAction;
      return {
        ...state,
        translations: {
          [translationAction.locale]: {
            ...(state[translationAction.locale] ?? {}),
            [translationAction.namespace]: translationAction.payload ?? {},
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
