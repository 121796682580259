import { globalSettings, isConfigValueDefined } from 'config';

const loadFacebookTag = () => {
  try {
    if (!isConfigValueDefined(globalSettings.facebookAppId)) return;

    window.fbAsyncInit = function () {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: globalSettings.facebookAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v3.2',
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  } catch (e) {
    console.error(e);
  }
};

export default loadFacebookTag;
