/**
 * There's no clean way to tell if a sticky element is "stuck" to the top of the screen. This makes a best guess by
 * trying to find whether a non-sticky reference point (based on the the bottom of the previous sibling element or
 * the top of the parent element) is off-screen.
 *
 * Does not work if the previous sibling element is `position: fixed`, `position: sticky`, `display: none` or
 * a non-element (i.e. text node).
 * @param {HTMLElement} el
 */
const isStickyElementStuck = (el: HTMLElement) =>
  (el.previousElementSibling != null
    ? el.previousElementSibling.getBoundingClientRect().bottom < 1
    : el.parentElement != null &&
      el.parentElement.getBoundingClientRect().top < 1) &&
  // Also ensure that the element is sticky and the browser supports it
  ['sticky', '-webkit-sticky'].includes(window.getComputedStyle(el).position);

export default isStickyElementStuck;
