/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CookbookStrategy, RecipeDetailModelLegacy } from '@mfb/cookbook';
import {
  BenefitDto,
  ChoiceSelectionsRequestBody,
  CostPreviewResponse,
  OrderAccountClient,
} from './CoreClient';
import { getRecipeDetailModel, getRecipeDetailModelLegacy } from './GetRecipeDetailModel';
import { globalSettings as settings } from 'config';
import { store } from '../../index';
import { getExtraDetail, getExtraDetailLegacy } from './GetExtraDetail';
import { AppState, OrdersPageState } from '../../redux/state';
import { selectCurrentOrder } from '../../redux/selectors/orderPageSelectors';
import {
  CostPreviewRequestBody as AccountCostPreviewRequestBody,
  ChoiceSelectionsRequestBody as AcccountChoiceSelectionsRequestBody,
  ExtrasItemRequestBody as AcccountExtrasItemRequestBody,
  ProductGroupResponse,
  AccountApiClient,
  PromoOverride,
} from '@mfb/account-api-client';
import { accountApiClient } from './api';
import { ExtrasItemRequestBody } from '@mfb/lego';

export class AccountCookbook implements CookbookStrategy {
  public async getRecipeDetail(
    recipeNumber?: string,
    recipeVersion?: string,
    partition?: string,
  ) {
    return getRecipeDetailModel(
      recipeNumber,
      recipeVersion,
      partition,
    );
  }

  public async getRecipeDetailLegacy(
    recipeNumber?: string, 
    recipeVersion?: string, 
    partition?: string, 
    recipeId?: number): Promise<RecipeDetailModelLegacy> {
    return getRecipeDetailModelLegacy(
      recipeNumber, 
      recipeVersion, 
      partition, 
      recipeId);
  }

  public async getProductDetail(sku?: string, promoCode?: string) {
    const ordersPageState = store.getState() as AppState<OrdersPageState>;
    const currentOrder = selectCurrentOrder(ordersPageState);

    return getExtraDetail(
      sku,
      currentOrder.deliveryDate.weekStarting,
      promoCode
    );
  }

  public async getProductDetailLegacy(sku?: string, promoCode?: string) {
    const ordersPageState = store.getState() as AppState<OrdersPageState>;
    const currentOrder = selectCurrentOrder(ordersPageState);

    return getExtraDetailLegacy(
      sku,
      currentOrder.deliveryDate.weekStarting,
      promoCode
    );
  }

  public async getSubscriptionCostPreview(
    sku: string,
    baseSku?: string,
    extras?: Array<ExtrasItemRequestBody>,
    selections?: Array<ChoiceSelectionsRequestBody>,
    promoCode?: string,
    subscriptionNumber?: string
  ): Promise<CostPreviewResponse> {
    const ordersPageState = store.getState() as AppState<OrdersPageState>;
    const currentOrder = selectCurrentOrder(ordersPageState);
    const week = currentOrder.deliveryDate.weekStarting;

    const request = new AccountCostPreviewRequestBody({
      sku,
      baseSku,
      extras: extras.map(
        (e) =>
          new AcccountExtrasItemRequestBody({
            ...e,
            frequency: e.frequency
          })
      ),
      promoCode,
      selections: selections.map(
        (s) => new AcccountChoiceSelectionsRequestBody(s)
      ),
      subscriptionNumber: subscriptionNumber
    });

    var result = await accountApiClient.subscriptionsCostCalculate(
      new Date(week),
      new AccountCostPreviewRequestBody(request)
    );

    return result;
  }

  public async validatePromoCode(
    targetWeek: Date,
    subscriptionNumber: string,
    promoCode: string
  ): Promise<PromoOverride> {
    const promo = await accountApiClient.salesPromoValidateWeekSubscription(targetWeek, promoCode, subscriptionNumber);
    return promo;
  }
  
  public async getProductGroups(): Promise<Array<ProductGroupResponse>> {
    const productGroupResponse =
      await accountApiClient.productsGroupedMarketing();

    return productGroupResponse;
  }
}
