import React from 'react';
import {showToast} from './showToast';

export interface Toast {
  content: React.ReactNode;
  type: ToastType;
}

export enum ToastType {
  Success,
  Error,
}

export const showGoodToast = content =>
  showToast({content, type: ToastType.Success});

export const showBadToast = content =>
  showToast({content, type: ToastType.Error});

export const showChangesSavedToast = () => {
  const toast: Toast = {
    content: `Changes Saved`,
    type: ToastType.Success,
  };

  return showToast(toast);
};

export const showSkippedToast = () => {
  const toast: Toast = {
    content: `Delivery Skipped`,
    type: ToastType.Success,
  };

  return showToast(toast);
};

export const showUnSkippedToast = () => {
  const toast: Toast = {
    content: `Delivery Unskipped`,
    type: ToastType.Success,
  };

  return showToast(toast);
};

export const showSubscriptionAddedToast = (bagName: string) => {
  const toast: Toast = {
    content: `${bagName || 'Subscription'} Added`,
    type: ToastType.Success,
  };

  return showToast(toast);
};

export const showSubscriptionCancelledToast = (bagName: string) => {
  const toast: Toast = {
    content: `${bagName || 'Subscription'} Cancelled`,
    type: ToastType.Success,
  };

  return showToast(toast);
};

export const showAddCardSuccessToast = () => {
  const toast: Toast = {
    content: `Your card has been successfully added`,
    type: ToastType.Success,
  };

  return showToast(toast);
};

export const showAddCardFailureToast = () => {
  const toast: Toast = {
    content: `There was an issue adding your card. Please try again.`,
    type: ToastType.Error,
  };

  return showToast(toast);
};

export const showRemoveCardSuccessToast = () => {
  const toast: Toast = {
    content: `Your card has been successfully removed`,
    type: ToastType.Success,
  };

  return showToast(toast);
};

export const showChangeToSelectedBagToast = () => {
  const toast: Toast = {
    content: `Bag Changed`,
    type: ToastType.Success,
  };

  return showToast(toast);
};
