import React from 'react';

const SkippedBoxIcon: React.FunctionComponent<
  React.SVGAttributes<SVGElement>
> = props => (
  <svg
    width="133"
    height="115"
    viewBox="0 0 133 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.4233 29.0051L10.712 21.1649"
      stroke="#F5F3F0"
      strokeWidth="2.52403"
      strokeLinecap="round"
    />
    <path
      d="M46.8633 10.7113V2"
      stroke="#F5F3F0"
      strokeWidth="2.52403"
      strokeLinecap="round"
    />
    <path
      d="M90.8555 10.7113L94.34 2"
      stroke="#F5F3F0"
      strokeWidth="2.52403"
      strokeLinecap="round"
    />
    <path
      d="M120.474 35.103L128.314 29.0051"
      stroke="#F5F3F0"
      strokeWidth="2.52403"
      strokeLinecap="round"
    />
    <path
      d="M122.216 93.4687L130.927 96.9532"
      stroke="#F5F3F0"
      strokeWidth="2.52403"
      strokeLinecap="round"
    />
    <path
      d="M96.2004 103.051L98.8138 113.505"
      stroke="#F5F3F0"
      strokeWidth="2.52403"
      strokeLinecap="round"
    />
    <path
      d="M40.4477 102.18L33.4787 112.634"
      stroke="#F5F3F0"
      strokeWidth="2.52403"
      strokeLinecap="round"
    />
    <path
      d="M10.7113 76.9172L2.00002 80.4018"
      stroke="#F5F3F0"
      strokeWidth="2.52403"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.7174 60.9175L79.701 72.7423L104.533 60.5504L94.9616 49.0927L104.533 37.4655L79.701 25.5205L69.7174 37.0601L60.0265 25.5205L35.2209 37.4655L44.5689 49.0927L35.2209 60.5504L60.0265 72.7423L69.7174 60.9175Z"
      stroke="#BF6614"
      strokeWidth="2.01922"
    />
    <path
      d="M44.5147 65.6249V83.6898L69.7183 95.3403L95.4514 83.6898V65.6249"
      stroke="#BF6614"
      strokeWidth="2.01922"
    />
    <path
      d="M69.7176 95.3403L69.9824 61.1476"
      stroke="#BF6614"
      strokeWidth="2.01922"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.5147 49.1314L69.7183 60.5504L94.889 49.1314L69.8777 37.0712L44.5147 49.1314Z"
      stroke="#BF6614"
      strokeWidth="2.01922"
      strokeLinejoin="round"
    />
    <circle cx="39.5" cy="53.5" r="10.5" fill="#AB273A" />
    <path d="M36 50L43 57" stroke="white" strokeWidth="2.1" />
    <path d="M36 57L43 50" stroke="white" strokeWidth="2.1" />
  </svg>
);

export default SkippedBoxIcon;
