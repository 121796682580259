import logger from 'app/shared/Logger';

export enum SessionStorageKey {
  CUSTOMER_MESSAGE = 'CUSTOMER_MESSAGE',
  PLAN_YOUR_WEEK_SELECTION = 'PLAN_YOUR_WEEK_SELECTION',
}

export const writeToSessionStorage = (
  key: object,
  data: any,
  expireInMinutes?: number
): void => {
  try {
    const currentTime = new Date().getTime();
    const expirationTime = expireInMinutes
      ? currentTime + expireInMinutes * 1000 * 60
      : null;
    const item = {
      data: data,
      expiry: expirationTime,
    };
    sessionStorage.setItem(JSON.stringify(key), JSON.stringify(item));
  } catch (e) {
    if (e instanceof Error) {
      logger.error(e, {});
    }
  }
};

export const readFromSessionStorage = (key: object): any | null => {
  try {
    const _key = JSON.stringify(key);
    const item = sessionStorage.getItem(_key);
    if (item) {
      const parsedItem = JSON.parse(item);
      const currentTime = new Date().getTime();
      if (parsedItem.expiry && parsedItem.expiry < currentTime) {
        sessionStorage.removeItem(_key);
        return null;
      }
      return parsedItem.data;
    }
    return null;
  } catch (e) {
    if (e instanceof Error) {
      logger.error(e, {});
    }
  }
};

export const removeFromSessionStorage = (key: object) => {
  try {
    const _key = JSON.stringify(key);
    const item = sessionStorage.getItem(_key);
    if (item) {
      sessionStorage.removeItem(_key);
    }
  } catch (e) {
    if (e instanceof Error) {
      logger.error(e, {});
    }
  }
};
