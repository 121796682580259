import { CampaignProgressIndicator } from './CampaignProgressIndicator';
import styled from 'styled-components';
import { SvgV2CheckCircleFill } from '@mfb/lego';
import { mapDispatchToProps } from 'app/account-settings/containers/accountSettingsContainer';
import { connect } from 'react-redux';
import { AppState } from 'redux/state';
import { CampaignTheme, EnrolledCampaignDto } from '@mfb/account-api-client';

const StyledCampaignBanner = styled.div`
  display: flex;
  background-color: ${(c) => c.theme.colors.brand[100]};
  border-bottom: solid 1px ${(c) => c.theme.colors.brand[200]};
  color: ${(c) => c.theme.colors.brand[800]};
  padding: ${(c) => c.theme.spacing.large} ${(c) => c.theme.spacing.xxlarge};
  place-content: center;
  text-justify: center;
  display: flex;
  @media ${(p) => p.theme.layout.deviceMediaQueries.max.sm} {
    padding: ${(c) => c.theme.spacing.xxlarge} ${(c) => c.theme.spacing.large};
  }
`;

const StyledDescriptionSection = styled.div`
  margin-left: ${(c) => c.theme.spacing.xxlarge};
  text-align: start;
  place-self: center;
`;

const StyledTitle = styled.span`
  font-weight: ${(c) => c.theme.typography.fontWeight[700]};
`;

const StyledProgressIndicatorContainer = styled.div`
  position: relative;
  place-self: center;
`;

const CampaignBannerUnconnected = ({
  campaignProgress,
}: {
  campaignProgress: EnrolledCampaignDto;
}) => {
  const isIndicatorHidden =
  campaignProgress?.progressionTowardsNextCampaignPrize
      ?.isProgressIndicatorHidden;

  return (
    campaignProgress && (campaignProgress.theme === CampaignTheme.Standard) && (
      <StyledCampaignBanner>
        <StyledProgressIndicatorContainer>
          {!isIndicatorHidden && (
            <CampaignProgressIndicator
              variant="ringWhite"
              campaignProgression={campaignProgress}
            />
          )}
          {isIndicatorHidden && <SvgV2CheckCircleFill width={40} height={40} />}
        </StyledProgressIndicatorContainer>
        <StyledDescriptionSection>
          <StyledTitle>{campaignProgress.campaignName}. </StyledTitle>
          {campaignProgress.progressionText}
        </StyledDescriptionSection>
      </StyledCampaignBanner>
    )
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    campaignProgress: state.campaign.enrolledCampaignDto,
  };
};

export const CampaignBanner = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignBannerUnconnected);
