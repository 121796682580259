import {addWeeks} from 'date-fns';

const KEY = 'mfb_download_ios_app_popup';
export const IOS_DOWNLOAD_NEVER_SHOW_AGAIN = 'never';

export const setDownloadIOSPopupExpiry = (value: string): void =>
  localStorage.setItem(KEY, value);

export const shouldShowDownloadIOSPopup = (): boolean => {
  const value = localStorage.getItem(KEY) || '';

  if (!value) {
    return true;
  }

  if (value === IOS_DOWNLOAD_NEVER_SHOW_AGAIN) {
    return false;
  }

  const storedDate = new Date(value);
  const lastWeek = addWeeks(new Date(), -1);

  if (storedDate <= lastWeek) {
    return true;
  }

  return false;
};
