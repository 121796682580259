import React from 'react';

const OrderHistoryIcon: React.FunctionComponent<
  React.SVGAttributes<SVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 14"
    width="16"
    height="16"
    {...props}
  >
    <path d="M11.189 12.606V4.892H1.41v7.714h9.778zm0-11.227c.754 0 1.411.657 1.411 1.412v9.815c0 .755-.657 1.379-1.411 1.379H1.41C.624 13.985 0 13.361 0 12.606V2.791c0-.755.624-1.412 1.411-1.412H2.1V0H3.51v1.379h5.578V0H10.5v1.379h.689zM9.81 6.303v1.38h-1.41v-1.38h1.41zm-2.822 0v1.38H5.611v-1.38h1.378zm-2.789 0v1.38H2.79v-1.38H4.2z" />
  </svg>
);

export default OrderHistoryIcon;
