import { customerMessageDefaultContext } from 'app/shared/CustomerMessageService';
import classNames from 'classnames';
import { globalSettings as settings } from 'config';
import isWebview from 'is-ua-webview';
import Cookies from 'js-cookie';
import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import loadApplicationInsightsTag from 'utilities/analytics/applicationInsights';
import loadFacebookTag from 'utilities/analytics/facebook';
import loadGtmTag from 'utilities/analytics/gtm';
import loadSentryTag from 'utilities/analytics/sentry';
import loadVersionTag from 'utilities/analytics/version';
import loadVwoTag from 'utilities/analytics/vwo';
import {
  AccountTheme, Button,
  CustomerMessageProvider,
  CustomerMessageV2,
  EditDeliveryForm,
  EditDeliveryFormContextProvider,
} from '@mfb/lego';
import objectFitImages from '@mfb/object-fit-images-polyfil';
import ErrorAlert from './ErrorAlert';
import createUserManager, { MFBUser } from './app/shared/UserManager';
import configureStore from './redux/store';
import routeHistory from './routerHistory';
import { loadMixPanel } from './utilities/analytics/mixPanel';
import { navman } from 'navigator';
import { getIsIOSWebview } from './app/shared/getIsIOSWebview';
import { getIsReturnToApp } from './app/shared/getIsReturnToApp';


// Preflight Conflicts with Bootstrap / will need to sort this out before re-enabling.
// import '@mfb/onion-ui/theme/base';


const html = document.getElementsByTagName('html')[0];
const rootEl = document.getElementById('react-root');

/**
 * Load up Analytics before page mount
 */
loadGtmTag();
loadApplicationInsightsTag();
loadVwoTag();
loadFacebookTag();
loadSentryTag();
loadVersionTag();
loadMixPanel();

html.className = classNames(
  'no-js',
  'no-webview',
  settings.isBargainBox ? 'bargain-box' : 'my-food-bag season-spring',
);

rootEl?.classList.remove('d-none');

if (objectFitImages) {
  objectFitImages();
}

declare let Modernizr: { mutationobserver: boolean };
declare let dataLayer: Array<any>;

html.classList.remove('no-js');

if (isWebview(navigator.userAgent)) {
  html.classList.remove('no-webview');
  html.classList.add('webview');
}

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { whyDidYouUpdate } = require('why-did-you-update');
  whyDidYouUpdate(React);
}

Modal.setAppElement(rootEl);

const unsupportedEl = document.getElementById('unsupported');

if (
  unsupportedEl?.parentElement &&
  Modernizr != null &&
  Modernizr.mutationobserver
) {
  unsupportedEl.parentElement.removeChild(unsupportedEl);
}

routeHistory.init();

// We export the store here so that places that want to grab it (that aren't connected components)
// can get access to it. We could do this in store.ts but we want to delay initilisation of it
// so this works.
export const store = configureStore();

// Expose the ExperimentState branch of the store globally so that Catchi/VWO can base experiment logic and reporting
// off the contents
// TODO: Will need to rethink this if we want to use VWO for experiments as this data needs to be loaded, and the
//   variable accessible, before the VWO smart code script is run.
// declare global {
//   interface Window {
//     experimentState(): ExperimentState
//   }
// }

// window.experimentState = () => (store.getState() as AppState).experimentState;

const compose = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const NextApp = require('./AppShell').default;
  // This cookie is killing the app bundle as Hapi cannot parse its value.
  // Explicitly removing here but should be replaced with a solution that
  // kills off invalid cookies, dynamically.
  Cookies.remove('_gw');

  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={{ ...AccountTheme }}>
          <CustomerMessageProvider context={customerMessageDefaultContext}>
            <EditDeliveryFormContextProvider>
              <Helmet>
                {settings.isBargainBox && (
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="https://www.bargainbox.co.nz/static/images/favicon.png"
                  />
                )}
                {settings.isMyFoodBag && (
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="https://www.myfoodbag.co.nz/favicon.spring.ico"
                  />
                )}
                <meta name="application-name" content={settings.brand} />
                <meta name="apple-mobile-web-app-title" content={settings.brand} />
                <meta name="theme-color" content={settings.themeColour} />
              </Helmet>
              <NextApp history={routeHistory.history} />
              <CustomerMessageV2
                body={
                  <CustomerMessageV2.Panel>
                    <CustomerMessageV2.Triggers />
                    <CustomerMessageV2.Message />
                  </CustomerMessageV2.Panel>
                }
              />

              <EditDeliveryForm
                body={
                  <>
                    <EditDeliveryForm.Panel>
                      <EditDeliveryForm.Body />
                    </EditDeliveryForm.Panel>
                  </>
                }
              />
              <TakeMeBackToV2 $display={!!getIsIOSWebview() || !!getIsReturnToApp()}>
                <Button size={'md'} label={'Take me back'} onClick={() => navman.routeToApp()} />
              </TakeMeBackToV2>
            </EditDeliveryFormContextProvider>
          </CustomerMessageProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
};

const mount = () => {
  rootEl.style.height = 'auto';
  ReactDOM.render(compose(), rootEl);
};

const addUserToGtm = (user: MFBUser) => {
  try {
    const customerNumber = user.customerNumber;
    if (customerNumber) {
      dataLayer.push({
        event: 'userIdEvent',
        userId: customerNumber,
      });
    }
  } catch {
    // no op - if we can't add the UserID do not take down the page.
  }
};

(async () => {
  try {
    const user = await (await createUserManager()).authenticate();

    if (!user) {
      return;
    }

    if (settings.gtmContainerId != null) {
      addUserToGtm(user);
    }
    mount();
  } catch (err) {
    console.log(err.message, err);

    const errorMessage = `The following error has occurred: "${err.message}".
    The application has crashed in an unrecoverable way. Please try again in a few minutes.
    If the issue persists please contact Customer Love.`;
    ReactDOM.render(<ErrorAlert message={errorMessage} />, rootEl);
  }
})();


const TakeMeBackToV2 = styled.div<{$display: boolean}>`
    display: ${c => c.$display ? 'flex': 'none'};
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    padding: 16px;
    width: fit-content;
    background-color: white;
`;
