import { ProductGroupResponse } from 'app/shared/CoreClient';
import { Reducer } from 'redux';
import {
  ProductGroupMarketingStateAction,
  RECEIVE_PRODUCT_GROUP_MARKETING,
  ReceiveProductGroupMarketingAction,
} from '../actions/sharedActions/productGroupMarketingAction';

const reducer: Reducer<Array<ProductGroupResponse>> = (
  state = [],
  action: ProductGroupMarketingStateAction
): Array<ProductGroupResponse> => {
  switch (action.type) {
    case RECEIVE_PRODUCT_GROUP_MARKETING:
      return (action as ReceiveProductGroupMarketingAction).payload;
    default:
      return state;
  }
};

export default reducer;
