import React from 'react';
import { CampaignGroupDto } from '@mfb/account-api-client';
import { accountApiClient } from '../../../app/shared/api';
import {CampaignModal} from './CampaignModal';
import {PerformJoinCampaign} from '../../deliveries';
import {trackCampaignModalLandingEvent} from '../../shared/campaignTracker';
import { useCampaignTracking } from 'app/shared/components/Tracking/useCampaignTracking';
import { AppState } from 'redux/state';
import { connect } from 'react-redux';
import { mapDispatchToProps } from 'app/account-settings/containers/accountSettingsContainer';

interface Props {
  productSku: string,
  productName: string,
  productBrand: string,
  people: number,
  nights: number,
  subscriptionNumber: string,
  subscriptionFrequency: string,
  campaignCode: string;
  onSubmitCallback: PerformJoinCampaign;
}

const loadCampaignInfo = async (campaignCode: string) => {
  try {
    const response = await accountApiClient.campaignGet(campaignCode)

    return response;
  } catch (err) {
    return undefined;
  }
};

export const UnconnectedCampaignSignUpModalContainer: React.FunctionComponent<any> = (props) => {
  const {trackOnDisplayJoinCampaignModal} = useCampaignTracking();
  const [
    campaignGroupResponse,
    setCampaignGroupResponse,
  ] = React.useState<CampaignGroupDto>();

  React.useEffect(() => {
    loadCampaignInfo(props.campaignCode).then(resp => {
      trackCampaignModalLandingEvent(resp);
      trackOnDisplayJoinCampaignModal(props.metadata, {campaignName: resp.groupTitle})
      setCampaignGroupResponse(resp);
    });
  }, []);

  return campaignGroupResponse ? (
    <CampaignModal
      campaignGroupResponse={campaignGroupResponse}
      onSubmitCallback={props.onSubmitCallback}
    />
  ) : null;
};


const mapStateToProps = (state: AppState, ownProps: Props) => {
  return {
    metadata: state.tracking.subscriptions.find(c=>c.subscriptionNumber === ownProps.subscriptionNumber)?.metadata,
    ...ownProps
  };
};

export const CampaignSignUpModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedCampaignSignUpModalContainer);
