import React from 'react';
import {Dictionary, ResponsiveSquare, SvgSolidTickIcon} from '@mfb/lego';
import classnames from 'classnames';
import styled from 'styled-components';
import {CampaignPrizeDto} from '../../shared/CoreClient';

const borderStyles = `
  border-radius: 15px;
  max-height: 350px;
`;

interface StyledProps {
  isSelected: boolean;
}

interface CrossOutProps {
  className?: string;
  backgroundImage?: string;
}

const PlaceholderWithBorder = styled.div<StyledProps>`
  ${borderStyles}
  ${props =>
    props.isSelected ? 'border: 5px solid;' : 'border: 5px solid transparent;'}
`;
PlaceholderWithBorder.displayName = 'PlaceholderWithBorder';

const PrizeImageWithBorder = styled.img<StyledProps>`
  ${borderStyles}
  ${props =>
    props.isSelected ? 'border: 5px solid;' : 'border: 5px solid transparent;'}
`;
PrizeImageWithBorder.displayName = 'PrizeImageWithBorder';

const SoldOutPrizeImage = styled.div<CrossOutProps>`
  ${borderStyles}
  background: ${props =>
    props.backgroundImage ? 'url(' + `'${props.backgroundImage}'` + ')' : ''};
  opacity: ${props => (props.backgroundImage ? '0.2' : '1')};
  background-size: ${props => (props.backgroundImage ? 'cover' : '')};
`;
SoldOutPrizeImage.displayName = 'SoldOutPrizeImage';

interface Props {
  prize: CampaignPrizeDto;
  isSelected: boolean;
  paddingSizes: Dictionary<string>;
}

const PrizeImage: React.FC<Props> = ({prize, isSelected, paddingSizes}) => {
  const imageClassNames = classnames(
    'w-100 h-100',
    isSelected && 'border-primary'
  );

  return (
    <ResponsiveSquare
      id={prize.campaignPrizeId.toString()}
      paddingSizes={paddingSizes}
      style={{
        background: prize.isSoldOut
          ? 'linear-gradient(to bottom right, #fff calc(50% - 1px), lightgrey , #fff calc(50% + 1px) )'
          : '',
        borderRadius: prize.isSoldOut ? '18px' : '15px',
      }}
    >
      {isSelected && !prize.isSoldOut && (
        <div className="w-100" style={{top: 0, left: 0, position: 'absolute'}}>
          <SvgSolidTickIcon
            width={24}
            height={24}
            className="float-right mr-3 mt-3"
          />
        </div>
      )}
      {prize.imageUrl ? (
        prize.isSoldOut ? (
          <span>
            <SoldOutPrizeImage
              backgroundImage={prize.imageUrl}
              className={classnames(
                'bg-secondary d-flex align-items-center justify-content-center text-center',
                imageClassNames
              )}
            ></SoldOutPrizeImage>
            <span style={{position: 'absolute', top: '40%', right: '40%'}}>
              Sold Out
            </span>
          </span>
        ) : (
          <PrizeImageWithBorder
            isSelected={isSelected}
            className={imageClassNames}
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            src={prize.imageUrl}
          />
        )
      ) : (
        <PlaceholderWithBorder
          isSelected={isSelected}
          className={classnames(
            'bg-secondary d-flex align-items-center justify-content-center text-center',
            imageClassNames
          )}
        >
          Sorry, image not available.
        </PlaceholderWithBorder>
      )}
    </ResponsiveSquare>
  );
};

export default PrizeImage;
