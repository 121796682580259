import React from 'react';
import {navman} from '../../../../navigator';
import Spinner from '../../../shared/components/Spinner';
import {HolidayClient} from '../../../shared/CoreClient';
import { globalSettings as settings } from 'config';

const getHolidayAddress = async () => {
  try {
    const deliveriesClient = new HolidayClient(settings.bffHost);
    const response = await deliveriesClient.getCustomerAddressHolidayInformation();

    return response && response.holidayAddress;
  } catch (err) {
    //don't want to break the page if this this can't be loaded
    console.error(err);
  }
};

export const HolidayAddressBanner: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [holidayAddress, setHolidayAddress] = React.useState<string>();

  React.useEffect(() => {
    setIsLoading(true);
    getHolidayAddress()
      .then(setHolidayAddress)
      .then(() => setIsLoading(false));
  }, []);

  return isLoading ? (
    <Spinner className="mt-5" />
  ) : (
    <div className="bg-info rounded px-4 py-3 mb-2 mt-5">
      {holidayAddress ? (
        <>
          <h3 className="mt-2">
            Your dinner is deliciously sorted this summer! 🏖
          </h3>
          <p>
            We’ll temporarily deliver to {holidayAddress} while you’re on a
            holiday.&nbsp;
            <a
              href="#"
              data-category="summer-holiday-address-changes"
              data-label="review-from-personal-details"
              data-action="click"
              onClick={navman.holidays}
            >
              Review.
            </a>
          </p>
        </>
      ) : (
        <>
          <h3 className="mt-2">Get dinner deliciously sorted this summer 🏖</h3>
          <p>
            Don’t stress about dinner these summer holidays. Take My Food Bag
            with you and get deliciously sorted, wherever you are! Tell us where
            you’re heading and for how long and let us take care of the
            rest.&nbsp;
            <a
              href="#"
              data-category="summer-holiday-address-changes"
              data-label="entry-from-personal-details"
              data-action="click"
              onClick={navman.holidays}
            >
              Click here to get started.
            </a>
          </p>
        </>
      )}
    </div>
  );
};
