import {CaptureCallback, Client} from 'raven';

/*
 * Logger for oidc-client
 */
export const logger = Object.assign({}, console, {
  info: (message: string) => {
    const Raven: Client = (window as any).Raven;

    if (Raven != null) {
      Raven.captureMessage(message, {level: 'info'});
    } else {
      console.info(message);
    }
  },

  warn: (message: string) => {
    const Raven: Client = (window as any).Raven;

    if (Raven != null) {
      Raven.captureMessage(message, {level: 'warning'});
    } else {
      console.warn(message);
    }
  },

  error: (err: Error, options: CaptureCallback = undefined) => {
    const Raven: Client = (window as any).Raven;

    if (Raven != null) {
      Raven.captureException(err, options);
    } else {
      console.error(err, options);
    }
  },
});


export default logger;