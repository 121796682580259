import { ActionCreator } from 'redux';
import { globalSettings as settings } from 'config';
import { fetchStart, fetchError } from '../sharedActions/saveAction';
import { PaymentClient } from '../../../app/shared/CoreClient';
import { tryPerformAccountSettingsRequest } from './accountSettingsAction';
import { ThunkAction } from 'redux-thunk';

export const removeCreditCard = (creditCardId: number) => {
  const accountSettingsClient = new PaymentClient(settings.bffHost);

  return tryPerformAccountSettingsRequest(async () =>
    accountSettingsClient.removeCreditCard(creditCardId)
  );
};

export const setActiveCreditCard = (creditCardId: number) => {
  const accountSettingsClient = new PaymentClient(settings.bffHost);

  return tryPerformAccountSettingsRequest(async () =>
    accountSettingsClient.setCreditCardAsDefault(creditCardId)
  );
};

export const addCreditCard: ActionCreator<
  ThunkAction<Promise<void>, unknown, null>
> = () => async (dispatch) => {
  dispatch(fetchStart());
  const accountSettingsClient = new PaymentClient(settings.bffHost);
  let response;
  try {
    response = await accountSettingsClient.addNewCard();
    window.location.href = response;
  } catch (err) {
    // TODO: error handling
    dispatch(fetchError());
  }
};

export const oneOffPayment: ActionCreator<
  ThunkAction<Promise<void>, unknown, null>
> =
  (deliveryId: number, saveCard: boolean, useDefaultCard: boolean) =>
  async (dispatch) => {
    dispatch(fetchStart());

    try {
      const accountSettingsClient = new PaymentClient(settings.bffHost);

      const response = await accountSettingsClient.payNow(
        deliveryId,
        saveCard,
        useDefaultCard
      );

      window.location.href = response;
    } catch (err) {
      // TODO: error handling
      dispatch(fetchError());
    }
  };
