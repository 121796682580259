/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { globalSettings as settings } from 'config';
import {
  SubscriptionClient,
  UpdateSubscriptionRequest,
} from '../../../app/shared/CoreClient';
import {
  CancelSubscriptionRequest,
  CreateSubscriptionRequest,
} from '../../../app/shared/CoreClient';
import {
  tryPerformAccountSettingsRequest,
  tryPerformAccountSettingsRequestWithErrors,
} from './accountSettingsAction';

export const submitSubscriptionChange = (
  subscription: UpdateSubscriptionRequest
) => {
  const accountSettingsClient = new SubscriptionClient(settings.bffHost);

  return tryPerformAccountSettingsRequestWithErrors(async () =>
    accountSettingsClient.updateSubscription(subscription)
  );
};

export const createSubscription = (request: CreateSubscriptionRequest) => {
  const client = new SubscriptionClient(settings.bffHost);

  return tryPerformAccountSettingsRequestWithErrors(async () =>
    client.createSubscription(request)
  );
};

export const cancelSubscription = (payload: CancelSubscriptionRequest) => {
  const accountSettingsClient = new SubscriptionClient(settings.bffHost);

  return tryPerformAccountSettingsRequest(async () =>
    accountSettingsClient.cancelSubscription(payload)
  );
};

export const restartSubscription = (id: number) => {
  const subscriptionClient = new SubscriptionClient(settings.bffHost);

  return tryPerformAccountSettingsRequest(async () =>
    subscriptionClient.restartSubscription(id)
  );
};
