import React from 'react';
import {RecipesForWeek} from '../../CoreClient';
import connectRecipes from '../../connectors/connectRecipes';
import format from '../../../../format/date';
import {Carousel} from '@mfb/lego';
import {
  mapRecipeToCarouselItemPropsLegacy,
  mapCarouselItemPropsToCarouselItemModel,
} from '../../../deliveries/components/mapRecipesToCarouselItems';

export interface Props {
  recipesModel?: RecipesForWeek;
  optionId?: number;
}

const BagRecipesContainer: React.FunctionComponent<Props> = props => {
  const {recipesModel} = props;

  const carouselItems = React.useMemo(() => {
    if (
      !recipesModel ||
      !recipesModel.recipes ||
      !recipesModel.recipes.length
    ) {
      return undefined;
    }

    const recipes = recipesModel.recipes;

    const carouselItemProps = recipes.map(recipe =>
      mapRecipeToCarouselItemPropsLegacy({recipe})
    );

    return mapCarouselItemPropsToCarouselItemModel(carouselItemProps);
  }, [recipesModel]);

  if (!carouselItems) {
    return null;
  }

  return (
    <div>
      <div className="d-flex justify-content-center mt-4 mb-2">
        <strong>Recipes for {`${format(recipesModel.deliveryDate)}`}</strong>
      </div>
      <Carousel
        items={carouselItems}
        itemClassName="px-2"
        responsiveSlideCount={{
          small: 1.5,
          medium: 1.5,
          large: 3,
          extraLarge: 3,
        }}
        showArrows={true}
        arrowProps={{
          arrowDistanceFromTop: '38%',
          arrowOpacity: 0.6,
          arrowClassName: 'd-none d-lg-block',
        }}
      />
    </div>
  );
};

export default BagRecipesContainer;
export const ConnectedBagRecipesContainer = connectRecipes(BagRecipesContainer);
