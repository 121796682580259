import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { ValidateDeliveryAddressErrorType } from '@mfb/account-api-client';
import {
  EditDeliveryFormContextProps,
  PreviewMessageProps,
  SvgCheckCircleFill,
  SvgCrossSmall,
  SvgExclamationCircleFill,
  SvgExclamationTriangleFill,
} from '@mfb/lego';
import { openZendesk } from '../../Zendesk';
import { getFormattedTimeSlot } from './timeslotSelector';
import { FormDefaults } from './useEditDeliveryDetailsService';

export const timeSlotChangedWarningMessage = (
  value: string,
  formDefaults: MutableRefObject<FormDefaults>,
  state: [
    EditDeliveryFormContextProps,
    Dispatch<SetStateAction<EditDeliveryFormContextProps>>,
  ]
): PreviewMessageProps => {
  const [, setState] = state;

  const defaultSlotId = formDefaults.current.timeSlotId;
  const defaultDeliveryDateId = formDefaults.current.deliveryDateId;

  const defaultSlot = getFormattedTimeSlot(
    formDefaults.current.fetchedTimeslots?.find(
      (c) =>
        c.deliverySlotId === defaultSlotId ||
        c.deliveryDateId === defaultDeliveryDateId
    )
  );

  const bodyWithDefault = defaultSlot ? `${defaultSlot} is not available for this address.`: '';

  return {
    headingIcon: <SvgExclamationTriangleFill width={20} height={20} />,
    heading: 'Your delivery time has changed.',
    body: `${bodyWithDefault} We have changed your delivery time to ${value}`,
    topRightSlot: {
      icon: <SvgCrossSmall width={20} height={20} />,
      onClick: () =>
        setState((c) => ({
          ...c,
          timeslotPreviewMessage: undefined,
          timeSlot: { ...c.timeSlot, variant: 'default' },
        })),
    },
    variant: 'warning',
  };
};

export const leavingDisabledTimeSlotMessage = (
  formDefaults: MutableRefObject<FormDefaults>,
): PreviewMessageProps => {
    const defaultSlotId = formDefaults.current.timeSlotId;
    const defaultDeliveryDateId = formDefaults.current.deliveryDateId;

    const defaultSlot = getFormattedTimeSlot(
      formDefaults.current.fetchedTimeslots?.find(
        (c) =>
          c.deliverySlotId === defaultSlotId ||
          c.deliveryDateId === defaultDeliveryDateId
      )
    );
    return {
      headingIcon: <SvgExclamationTriangleFill width={20} height={20} />,
      heading: `${defaultSlot} is full`,
      body: "If you move to another slot, you won’t be able to switch back.",
      isAlert: true,
      variant: 'warning',
    };
}

export const addressSelectedMessage = (
  address: string
): PreviewMessageProps => {
  return {
    body: address.replace(/<b>/gi, '').replace(/<\/b>/gi, ''),
    isAlert: true,
    topRightSlot: {
      icon: <SvgCheckCircleFill width={24} height={24} role={'button'} />,
    },
  };
};


export const noTimeSlotsAvailablePreviewMessage = (
  state: [
    EditDeliveryFormContextProps,
    Dispatch<SetStateAction<EditDeliveryFormContextProps>>,
  ],
): PreviewMessageProps => {
  const [, setState] = state;
  return {
    headingIcon: <SvgExclamationCircleFill width={20} height={20} />,
    heading: "Sorry, there are no available times.",
    body: "We are not able to find any delivery times for your bag.",
    button: {
      isInline: false,
      label: 'Need help? Chat with us.',
      onClick: () => {
        setState((p)=>({...p, isPanelOpen: false}));
        openZendesk();
      },
    },
    variant: 'critical',
  };
}

export const nonDeliverableAddressPreviewMessage = (
  state: [
    EditDeliveryFormContextProps,
    Dispatch<SetStateAction<EditDeliveryFormContextProps>>,
  ],
  errorType?: ValidateDeliveryAddressErrorType,

): PreviewMessageProps => {
  const [, setState] = state;
  let message = '';

  switch (errorType) {
    case ValidateDeliveryAddressErrorType.IslandsRestriction: {
      message = `Choose a different delivery address. Changes are restricted within the Islands between Tues 12am - 7pm.`;
      break;
    }
    case ValidateDeliveryAddressErrorType.AddressChangeNotAvailable: {
      message = `We do not deliver to this address at this time. Please try another address or choose an existing address. `;
      break;
    }
    case ValidateDeliveryAddressErrorType.CapacityLimits: {
      message = `We are unable to deliver to your address due to capacity limits with our delivery partners.`;
      break;
    }
    default: {
      message = `Please try again or choose an existing delivery address.`;
      break;
    }
  }

  return {
    headingIcon: <SvgExclamationCircleFill width={20} height={20} />,
    heading: "Sorry, we can't deliver to that address.",
    body: message,
    button: {
      isInline: false,
      label: 'Need help? Chat with us.',
      onClick: () => {
        setState((p)=>({...p, isPanelOpen: false}));
        openZendesk();
      },
    },
    variant: 'critical',
  };
};
