import React from 'react';
import Select from 'react-select';
import {buildBagOptions, renderBagOption} from '../bagOptions';
import {BagDisplay} from '../../CoreClient';
import TrackingProps from '../../TrackingProps';

export interface Props {
  bags: Array<BagDisplay>;
  selectedBagId: number;
  className?: string;
  tracking?: TrackingProps;
  onChange(bagId: number): void;
}

const buildInputProps = (tracking?: TrackingProps): any => {
  const inputProps: Record<string, string> = {};

  if (tracking != null) {
    if (tracking.category != null) {
      inputProps['data-category'] = tracking.category;
    }
    if (tracking.action != null) {
      inputProps['data-action'] = tracking.action;
    }
    if (tracking.label != null) {
      inputProps['data-label'] = tracking.label;
    }
  }

  return inputProps;
};

export const BagTypeSelect = (props: Props) => (
  <Select
    inputProps={buildInputProps(props.tracking)}
    options={buildBagOptions(props.bags)}
    value={props.selectedBagId}
    name="primaryBagId"
    placeholder="Select an option..."
    clearable={false}
    searchable={false}
    className={props.className}
    required={true}
    optionRenderer={renderBagOption}
    valueRenderer={renderBagOption}
    onChange={opt => props.onChange((opt as Select.Option).value as number)}
  />
);
