import React from 'react';
import {AccountPromo} from '../../../shared/CoreClient';

export interface Props {
  promos: Array<AccountPromo>;
}

export const UpcomingPromos: React.FunctionComponent<Props> = (props: Props) =>
  props.promos &&
  props.promos.length > 0 && (
    <div className="mb-2 test-Pricing">
      <p>
        <strong>Upcoming Promos</strong>
      </p>
      {props.promos.map((p, index) => (
        <p key={index}>{p.description}</p>
      ))}
    </div>
  );
