import {
  OrderWithRecipesAndExtras, SubscriptionDTO,
} from 'app/shared/CoreClient';
import React, { useState } from 'react';
import { CustomerProfileState, OrdersPageState, TrackingState, UiState } from 'redux/state';
import { DeliveryDateResponse } from '@mfb/account-api-client';
import {
  Button, CoreColors, CoreSpacing, CoreTypography,
  MultiAction, PreviewMessage,
  SvgCalendarCrossed,
} from '@mfb/lego';
import { ButtonV2Props } from '@mfb/lego';
import {
  useEditDeliveryDetailsService,
} from '../../../../shared/hooks/editDeliveryDetails/useEditDeliveryDetailsService';
import { skipDeliveryButtonBuilder } from './SkipDeliveryButtonBuilder';
import { getFormattedTimeSlot } from '../../../../shared/hooks/editDeliveryDetails/timeslotSelector';
import styled from 'styled-components';

const StyledMultiAction = styled(MultiAction)`
    span {
        display: flex
    }
`;

const EditDeliveryDetails = styled.div`
    padding: 0 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    white-space: pre-wrap;

    .__header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        h6 {
            font-size: 19px;
            font-weight: ${CoreTypography.fontWeight[900]};
            line-height: 1.3;
            color: ${CoreColors.neutral[900]};
            letter-spacing: -0.2px;

            @media ${(c) => c.theme.layout.deviceMediaQueries.max.sm} {
                font-size: 23px;
            }
        }
    }

    @media ${(c) => c.theme.layout.deviceMediaQueries.max.sm} {
        padding: 0;
    }
    
`;

//GA tracking
export enum EDIT_DELIVERY_OPTION {
  edit_delivery_selected = 'edit_delivery_selected',
  edit_delivery_edit_subscription_selected = 'edit_delivery_edit_subscription_selected',
  edit_delivery_update_delivery_selected = 'edit_delivery_update_delivery_selected',
}

export type SkipDeliveryContext = {
  isSkipped: boolean;
  bagDescription: string;
  date: string;
  onClickAsync?(
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): Promise<any>;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export interface EditDeliveryProps {
  customer: CustomerProfileState;
  subscription?: SubscriptionDTO;
  currentOrder?: OrderWithRecipesAndExtras;
  uiState: UiState;
  deliveryWeek: string;
  productOptionId: number;
  subscriptionId: number;
  subscriptionNumber: string;
  deliveryDateId: number;
  skipContext: SkipDeliveryContext;

  fetchOrderPageThunk(): Promise<OrdersPageState>;

  addressModalState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  bagName: string;
  tracking?: TrackingState,
  className?: string;
}

const getActions = (
  skipButton: ButtonV2Props,
  bagName: string,
  editDeliveryFormLoading: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  displayEditDelivery: () => Promise<void>,
  currentOrder?: OrderWithRecipesAndExtras,
) => {
  const [isLoading, setLoading] = editDeliveryFormLoading;

  const getAddressPreview = () => {
    const details = { address: '', timeslot: '' };
    try {
      const addressResponse = currentOrder.deliveryAddress;
      const timeslotResponse = currentOrder.overrideDeliveryDate ?? currentOrder.deliveryDate;

      details.address = `${addressResponse.street}, ${addressResponse.suburb}, ${addressResponse.city}, ${addressResponse.postcode}`;
      details.timeslot = getFormattedTimeSlot(
        DeliveryDateResponse.fromJS(timeslotResponse),
      );
    } catch (e) {
      console.error(e);
    }
    return details;
  };

  const updateDeliveryDetails = <EditDeliveryDetails>
    <div className={'__header-container'} data-testid={'edit-delivery-update-delivery-multi-action-button'}>
      <h6>Delivery details</h6>
      <Button
        data-test={'my-delivery-edit-button'}
        className={'__button'}
        label={'Edit'}
        onClick={async () => {
          setLoading(true);
          await displayEditDelivery();
          setLoading(false);
        }}
        isLoading={isLoading}
      />
    </div>
    <PreviewMessage
      dataTestId={'my-delivery-preview-message'}
      dataTestIdBody={'my-delivery-preview-message-body'}
      dataTestIdTitle={'my-delivery-preview-message-title'}
      dataTestIdExpandableText="my-delivery-preview-message-expandable-text"
      className={'__preview-message'}
      heading={getAddressPreview().timeslot}
      body={getAddressPreview().address}
      variant={'border'}
    />
  </EditDeliveryDetails>;


  const skip = {
    name: 'skip',
    title: '',
    button: {
      ...skipButton,
      leftIcon: <SvgCalendarCrossed />,
      eventTracking: {
        ...skipButton.eventTracking,
        data: {
          ...skipButton.eventTracking.data,
          bag_name: bagName,
        },
      },
    },
  };

  return {
    skip: skip,
    updateDeliveryDetails: updateDeliveryDetails,
  };
};

export const EditDelivery = ({
                               fetchOrderPageThunk,
                               customer,
                               currentOrder,
                               deliveryWeek,
                               subscriptionId,
                               subscription,
                               skipContext,
                               bagName,
                               tracking,
                               className,
                             }: EditDeliveryProps) => {
  const editDeliveryFormLoading = useState<boolean>();
  const skipButton = skipDeliveryButtonBuilder(subscriptionId, skipContext);
  const { displayEditDelivery } = useEditDeliveryDetailsService({
    currentOrder,
    deliveryWeek,
    tracking,
    postSuccessAction: fetchOrderPageThunk,
  });
  const availableActions = getActions(
    skipButton,
    bagName,
    editDeliveryFormLoading,
    displayEditDelivery,
    currentOrder,
  );

  const canUnSkip = currentOrder.orderState.canUnskip.isValid;
  const canSkip = currentOrder.orderState.canSkip.isValid;
  const canChangeShipping = currentOrder.orderState.canChangeDeliveryShipping.isValid;

  if (canUnSkip) {
    return (
      <Button
        {...availableActions.skip.button}
        size="lg"
        className="unskip"
      />
    );
  } else if (canSkip || canChangeShipping) {
    return <StyledMultiAction
      dataTestId="edit-delivery"
      context={{
        actionSections: canSkip ? [availableActions.skip] : [],
        anchorButton: {
          size: 'lg',
          label: 'My Delivery',
          eventTracking: {
            eventId: EDIT_DELIVERY_OPTION.edit_delivery_selected,
            data: {
              bag_name: bagName,
            },
          },
        },
      }}
      className={className}
    >
      <>
        <MultiAction.Panel>
          <MultiAction.Header />
          {canChangeShipping && availableActions.updateDeliveryDetails}
          {canSkip && <MultiAction.Section name="skip" />}
        </MultiAction.Panel>
      </>
    </StyledMultiAction>;
  }
  return null;
};
