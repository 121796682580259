import {
  RedeemVoucherRequest,
  VoucherClient,
} from '../../../app/shared/CoreClient';
import {tryPerformAccountSettingsRequestWithErrors} from './accountSettingsAction';
import { globalSettings as settings } from 'config';

export const redeemVoucher = (request: RedeemVoucherRequest) => {
  const voucherClient = new VoucherClient(settings.bffHost);

  return tryPerformAccountSettingsRequestWithErrors(
    async () => await voucherClient.redeemVoucherCode(request)
  );
};
