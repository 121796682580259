import {Dispatch, Store} from 'redux';
import {AppState} from '../../state';
import {RECEIVE_PAGE_STATE} from '../sharedActions/pageStateAction';

export const performJoinCampaign = (deliveryDay: string) => (
  dispatch: Dispatch<Store<AppState>>
) => {
  dispatch({
    type: RECEIVE_PAGE_STATE,
    payload: {deliveryDay},
  });
};
