export interface EmailMessage {
  subject: string;
  body: string;
}

export interface ReferAFriendMessages {
  whatsApp: string;
  facebook: string;
  email: EmailMessage;
}

const referAFriendMessages = (
  name: string,
  code: string,
  valueOff: number | string,
  url: string
): ReferAFriendMessages => {
  const whatappText = `Hi! Here's ${valueOff}% off your first My Food Bag, Fresh Start or Bargain Box order. Follow this ${url} to get the deliciousness!`;
  const facebookText = `Hi! Here's ${valueOff}% off your first My Food Bag, Fresh Start or Bargain Box order. Follow this ${url} to get the deliciousness!`;
  const email = {
    subject: ` Hi! Here's ${valueOff}% off your first My Food Bag, Fresh Start or Bargain Box order.`,
    body: `Delicious dinners are just a click away! Whether you're looking for foodie inspiration, healthy solutions, or dinners the whole family will love, we've got plenty of options to suit. We develop mouth-watering recipes, using the best kiwi produce and free range or free farmed meats, and deliver it straight to your door.
    \%0D%0A
    \%0D%0A
Follow this ${url} to order deliciousness!`,
  };

  return {
    whatsApp: whatappText,
    facebook: facebookText,
    email,
  };
};

export default referAFriendMessages;
