import React from 'react';
import {isEqual, pick} from 'lodash';
import {
  RecipeBagDisplayPayload,
  RecipesForWeek,
  SubscriptionClient,
} from '../CoreClient';
import { globalSettings as settings } from 'config';
import {BaseApiConnector, getComponentName} from '../BaseApiConnector';

export interface ConnectRecipesProps {
  recipesModel: RecipesForWeek | null;
  isRefreshingRecipesModel: boolean;
}

type AnyReactComponent<T> =
  | React.ComponentClass<T>
  | React.FunctionComponent<T>;

const selectRequestParams = (props: Readonly<RecipeBagDisplayPayload>) =>
  pick(props, 'optionId', 'subscriptionId');

/**
 * Higher-Order Component to connect a component to the RecipesForWeek model.
 *
 * @example
 * // To connect MyComponent, getting the subscriptionId from a route param
 * connectRecipes(MyComponent)
 */
const connectRecipes = <TProps extends Partial<ConnectRecipesProps>>(
  WrappedComponent: AnyReactComponent<TProps & Partial<ConnectRecipesProps>>
) =>
  class extends BaseApiConnector<
    TProps & RecipeBagDisplayPayload,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {},
    RecipesForWeek
  > {
    static displayName = `connectCancelSubscription(${getComponentName(
      WrappedComponent
    )})`;

    subscriptionClient = new SubscriptionClient(settings.bffHost);
    fetchRecipes = this.wrapModelFetcher(
      async (payload: RecipeBagDisplayPayload) =>
        await this.subscriptionClient.recipes(payload)
    );

    componentWillMount() {
      const requestParams = selectRequestParams(this.props);

      if (requestParams.optionId != null) {
        this.fetchRecipes(requestParams);
      }
    }

    componentWillReceiveProps(
      nextProps: Readonly<TProps & RecipeBagDisplayPayload>
    ) {
      const lastRequestParams = selectRequestParams(this.props);
      const nextRequestParams = selectRequestParams(
        (nextProps as any) as RecipeBagDisplayPayload
      );
      if (
        !isEqual(lastRequestParams, nextRequestParams) &&
        nextRequestParams.optionId != null
      ) {
        this.fetchRecipes(nextRequestParams);
      }
    }

    render() {
      const {model, modelUpdating} = this.state;

      return (
        <WrappedComponent
          {...this.props}
          recipesModel={model}
          isRefreshingRecipesModel={modelUpdating}
        />
      );
    }
  };

export default connectRecipes;
