import {CustomerProfileState, defaultCustomerProfileState} from '../state';
import {Reducer} from 'redux';
import { CustomerProfileStateAction, RECEIVE_CUSTOMER_PROFILE, ReceiveCustomerProfileAction } from 'redux/actions/customerProfileActions';

const reducer: Reducer<CustomerProfileState> = (
  state = defaultCustomerProfileState,
  action: CustomerProfileStateAction
): CustomerProfileState => {
  switch (action.type) {
    case RECEIVE_CUSTOMER_PROFILE:
      return {...state, ...(action as ReceiveCustomerProfileAction).payload};
    default:
      return state;
  }
};

export default reducer;
