import { ThunkAction } from 'redux-thunk';
import { ActionCreator } from 'react-redux';
import { AppState, OrdersPageState } from '../state';
import { isEmpty } from 'lodash';
import {
  AddressResponse,
  BenefitDto,
  CustomerClient,
  CustomerResponse,
  DeliveryDay,
  DeliveryStatus,
  Extra,
  LegacyOrdersPageStates,
  OrderAccountClient,
  OrderAction,
  OrderWithRecipesAndExtras,
  Recipe,
  RecipeResponse2,
  RecipeSelectionMode,
  SubscriptionActions,
  SubscriptionClient,
  SubscriptionDTO,
} from '../../app/shared/CoreClient';
import {
  receivePageState,
  requestPageState,
} from '../actions/sharedActions/pageStateAction';
import { globalSettings as settings } from 'config';
import { performDaySelectionAction } from '../actions/sharedActions/daySelectionAction';
import { isSameDay } from 'date-fns';
import { navman } from '../../navigator';
import { deliveriesPath } from '../../app/account-settings/components/Nav/paths';
import _ from 'lodash';
import { formatToNzTimeZone } from 'lib/date/dateFormat';
import { fetchCustomerMessage } from 'redux/actions/sharedActions/CustomerMessageAction';
import { TriggerCode } from 'app/shared/constants';
import { fetchSubscriptions } from '../actions/sharedActions/subscriptionsAction';
import { accountApiClient } from 'app/shared/api';
import { OrderWithRecipesAndExtrasExtended, mapSculleryOrderWithRecipesAndExtrasToCore } from '../../app/shared/sculleryToCoreResponseMapper';
import { fetchCustomerMessageBulk } from 'redux/actions/sharedActions/CustomerMessageBulkAction';
import { GetCustomerMessageBulkRequest } from '@mfb/account-api-client';
import { QueryParameter } from '../../queryParameter';

/**
 * This thunk action creator uses different fetches from different endpoints.
 * Moving forward we want to avoid referencing the gigantic pagestate.
 */
export const fetchOrderPageStateThunk: ActionCreator<
  ThunkAction<Promise<OrdersPageState>, AppState, null>
> = (targetWeek?: Date, subscriptionNumber?: string, promoCode?: string) => {
  return async (dispatch, getState) => {
    const requestTime = new Date();
    dispatch(fetchSubscriptions());
    dispatch(requestPageState(requestTime));

    const customerClient = new CustomerClient(settings.bffHost);
    const deliveriesClient = new OrderAccountClient(settings.bffHost);
    const subscriptionClient = new SubscriptionClient(settings.bffHost);

    try {
      let promo: BenefitDto;
      let orders: OrderWithRecipesAndExtrasExtended[];
      if (targetWeek && subscriptionNumber) {
        const singularOrder = await accountApiClient.orderWeekSubscriptionNumber(
          targetWeek,
          subscriptionNumber,
          promoCode
        );
        if (!_.isEmpty(singularOrder.errors)) {
          navman.yourAccount();
          return;
        }

        const planYourWeekCustomerMessageCodes = [
          TriggerCode.PYW_ENTRY_KITCHEN,
          TriggerCode.PYW_EXIT_KITCHEN,
          TriggerCode.PYW_ENTRY_MEALS,
          TriggerCode.PYW_EXIT_MEALS,
        ];

        //pyw CMP
         await dispatch(
          fetchCustomerMessageBulk(
            subscriptionNumber,
            planYourWeekCustomerMessageCodes,
            [singularOrder.orderWithRecipesAndExtras.deliveryDate.deliveryDateId],
          ));

        orders = [mapSculleryOrderWithRecipesAndExtrasToCore(singularOrder.orderWithRecipesAndExtras)];
        promo = singularOrder.promo;
      } else {
        orders = (await accountApiClient.orders()).map(c=>mapSculleryOrderWithRecipesAndExtrasToCore(c));
        const allPromises = [];
        // @TODO: implement CMP bulk api

        const groupBySubscriptionNumber: Record<string, OrderWithRecipesAndExtras[]> = _.groupBy(orders, 'subscriptionNumber');

        for (const subscriptionNumber in groupBySubscriptionNumber) {
          const orders = groupBySubscriptionNumber[subscriptionNumber];
          const triggerCodes = [];
          const deliveryDateIds = [];

          for (const order of orders) {
            if (
              (order.orderActions.includes(OrderAction.Skip) ||
                order.orderActions.includes(OrderAction.Unskip)) &&
              getState()?.features?.triggerCodes?.includes(TriggerCode.SKIP)
            ) {
              if (!triggerCodes.includes(TriggerCode.SKIP)) {
                triggerCodes.push(TriggerCode.SKIP);
              }

              if (!deliveryDateIds.includes(order.deliveryDate.deliveryDateId.toString())){
                deliveryDateIds.push(order.deliveryDate.deliveryDateId.toString());
              }
            }

            if (
              order.deliveryStatus === DeliveryStatus.Ordered &&
              getState()?.features?.triggerCodes?.includes(TriggerCode.CONTENT_SLOT)
            ) {
              if (!triggerCodes.includes(TriggerCode.CONTENT_SLOT)){
                triggerCodes.push(TriggerCode.CONTENT_SLOT);
              }

              if (!deliveryDateIds.includes(order.deliveryDate.deliveryDateId.toString())){
                deliveryDateIds.push(order.deliveryDate.deliveryDateId.toString());
              }
            }
          }

          const customersMessagesRequestBody = new GetCustomerMessageBulkRequest();
          customersMessagesRequestBody.deliveryDateIds = deliveryDateIds;
          customersMessagesRequestBody.subscriptionNumber = subscriptionNumber;
          customersMessagesRequestBody.triggerCodes = triggerCodes;

          if (triggerCodes.length > 0 && deliveryDateIds.length > 0) {
            const promise = dispatch(
              fetchCustomerMessageBulk(
                subscriptionNumber,
                triggerCodes,
                deliveryDateIds,
              )
            );
            allPromises.push(promise);
          }
        }

        await Promise.all(allPromises);
      }

      const [others, customer, subscriptions] = await Promise.all([
        deliveriesClient.getLegacySubscriptionState(),
        // @TODO customer info shouldn't be fetched every single time
        customerClient.customerInformation(),
        // @TODO subscription info shouldn't be fetched every single time
        subscriptionClient.getSubscriptions(),
      ]);

      const orderResponse: OrdersPageState = await buildMrPotatoHead(
        others,
        customer,
        orders,
        subscriptions,
        promo
      );

      /*
        Some context to the selected day code below.
        When moving to plan your week (which belongs to a different route) and pressing
        back, the delivery tab components remount.

        The component (DeliveryContainerWithTabs) afaik, fetches the
        orders everytime it is remounted.

        @TODO improve this
        Ideal solution would be to not fetch everytime the component is remounted.
       */
      const selectDayByQueryParam = getOrderKeyByQueryParam(orders);

      if (selectDayByQueryParam) {
        dispatch(performDaySelectionAction(selectDayByQueryParam));
      } else {
        if (orders && orders.length) {
          // this ensures that if the order key is faulty (which it can be)
          // that we gracefully reselect a new tab
          let preselectedOrder = orders.find(
            (o) => o.orderKey == getState().ui.selectedDay
          );

          if (!preselectedOrder) {
            // preselect upcoming delivery week
            const upcomingDelivery = new Date(orderResponse.upcomingDeliveryWeek);
            preselectedOrder = getDefaultSelectedOrder(upcomingDelivery, orders);
          }
          if (preselectedOrder) {
            dispatch(performDaySelectionAction(preselectedOrder.orderKey));
          }
        }
      }

      dispatch(
        receivePageState({
          ...orderResponse,
          requestTime: new Date(),
          requestSource: deliveriesPath,
        })
      );

      return orderResponse;
    } catch (err) {
      /*
        ideally, we should fail gracefully by reverting to the old behavior.
        But to do that we'd need to recode the BaseClient. So /shrug
       */
      console.error(err);
      navman.error();
    }
  };
};

/**
 * Given a list of orders, decide which among them
 * is the default selected order.
 *
 * @param upcomingDeliveryWeek
 * @param orders
 */
export const getDefaultSelectedOrder = (
  upcomingDeliveryWeek: Date,
  orders: OrderWithRecipesAndExtras[]
) => {
  if (orders.length == 0) {
    throw new Error('Received empty orders.');
  }

  const upcomingDeliveries = orders.filter((o) =>
    isSameDay(new Date(o.deliveryDate.weekStarting), upcomingDeliveryWeek)
  );

  // if there are multiple upcoming deliveries
  // select the first unskipped else just select whichever
  if (upcomingDeliveries.length) {
    return (
      upcomingDeliveries.find((o) => !o.isSkipped) || upcomingDeliveries[0]
    );
  }

  const deliveredOrder = orders.find(
    (o) => o.deliveryStatus == DeliveryStatus.Delivered
  );

  if (deliveredOrder) {
    return deliveredOrder;
  }

  return orders[0];
};

export const fetchOrderPageThunk: ActionCreator<
  ThunkAction<Promise<OrdersPageState>, AppState, never>
> = (targetWeek?: Date, subscriptionNumber?: string, promoCode?: string) => {
  return async (dispatch, getState) => {
    return fetchOrderPageStateThunk(targetWeek, subscriptionNumber, promoCode)(
      dispatch,
      getState,
      null
    );
  };
};

/**
 * Rips apart the different organs and extremities of different responses and reassembles them
 * into a hot potato.
 *
 * @param others OrderPageState
 * @param customer CustomerResponse
 * @param orders OrderWithRecipesAndExtras[]
 * @param subscriptions SubscriptionDTO[]
 * @param promo BenefitDto
 * @constructor
 */
async function buildMrPotatoHead(
  others: LegacyOrdersPageStates,
  customer: CustomerResponse,
  orders: Array<OrderWithRecipesAndExtras>,
  subscriptions: Array<SubscriptionDTO>,
  promo?: BenefitDto
) {
  const hasActiveSubscriptions = orders.length > 0; // if there are no active subs BE wont returning anything
  const hasCancelledSub = others.lastCancelledSubscription != null;

  const lastProductIsActive = others.lastCancelledProductIsAvailable;

  const customerRemap = {
    customerAddress: customer.address,
    customerName: customer.name,
    customerDeliveryInstructions: customer.deliveryInstructions,
  };

  const legacyCodeRemap = {
    accountMerchSection: others.accountMerchSection,
    bagSelection: others.bagSelection,
    hasWrittenOffDeliveries: others.hasWrittenOffDeliveries,
    isNewCampaignJourneyEnabled: others.isNewCampaignJourneyEnabled,
    lastCancelledSubscription: others.lastCancelledSubscription,
    messages: others.messages,
    referAFriendAmountOff: others.referAFriendAmountOff,
    subscriptionAction:
      !hasActiveSubscriptions && hasCancelledSub
        ? lastProductIsActive
          ? SubscriptionActions.RestartSubscription
          : SubscriptionActions.StartNewSubscription
        : SubscriptionActions.Default,
    upcomingDeliveryWeek: others.upcomingDeliveryWeek,
    nextAvailableDeliveryDate: others.nextAvailableDeliveryDate,
  };

  const deliveryDaysRemap = orders.map((order): DeliveryDay => {
    const product = order.deliveryLines.find(
      (d) => d.deliveryLineType == 'PRODUCT'
    );
    const deliveryDate = order.deliveryDate;

    const subscription = subscriptions.find(
      (s) => s.subscriptionNumber == order.subscriptionNumber
    );

    return {
      date: formatToNzTimeZone(deliveryDate.actualDeliveryDate, 'D'),
      order: {
        subscriptionId: order.subscriptionId,
        productOptionId: order.productOptionId,
        week: formatToNzTimeZone(deliveryDate.weekStarting, 'W'),
        orderKey: order.orderKey,
        bagDescription: product.lineDescription,
        deliveryAddress: toStringAddress(order.deliveryAddress),
        isSkipped: order.isSkipped,
        isOrdered:
          order.deliveryId != null &&
          order.deliveryStatus == DeliveryStatus.Ordered,
        isLocked:
          order.deliveryId != null &&
          order.deliveryStatus == DeliveryStatus.Locked,
        cost: order.totalCost,
        actions: order.orderActions,
        recipes: order.selectedRecipes.map(recipeResponseToRecipe),
        deliverySlotId: order.deliveryDate.deliverySlotId,
        timeRangeStart: order.deliveryDate.actualDeliveryStartTime,
        timeRangeEnd: order.deliveryDate.actualDeliveryEndTime,
        extras:
          order.selectedExtras &&
          order.selectedExtras.map(extrasResponseToExtras),
        deliveryId: order.deliveryId,
        skipValidation: undefined, // @TODO wtf is this for
        recipeSelectionMode:
          product.recipeSelectionMode as number as RecipeSelectionMode,
        deliveryOrderDate: order.deliveryOrderDate,
        availableFrequency: order.availableFrequency,
        deliveryInstructions: order.deliveryAddress.deliveryInstructions,
        isProductSameAsSubscription:
          subscription.primaryOptionSku == product.sku,
        hasOverrideDeliveryDate: !isEmpty(order.overrideDeliveryDate),
      },
    };
  });

  const orderResponse: OrdersPageState = {
    ...customerRemap,
    ...legacyCodeRemap,
    orders,
    subscriptions,
    deliveryDays: deliveryDaysRemap,
    promo,
  };

  return orderResponse;
}

const toStringAddress = (address: AddressResponse) => {
  return [address.street, address.suburb, address.city].join(', ');
};

const extrasResponseToExtras = (line): Extra => {
  return {
    key: line.sku,
    sku: line.sku,
    name: line.lineDescription,
    quantity: line.quantity,
    recipes: line.recipes && line.recipes.map(recipeResponseToRecipe),
    imageSet: {
      small: line.imageUrl,
      medium: line.imageUrl,
      large: line.imageUrl,
    },
  };
};

const recipeResponseToRecipe = (recipesResponse: RecipeResponse2): Recipe => {
  return {
    ...recipesResponse,
    name: `${recipesResponse.title} ${recipesResponse.subtitle}`.trim(),
    // based on usage id can be anything as long as its unique
    id: [
      recipesResponse.recipeNumber,
      recipesResponse.recipeVersion,
      recipesResponse.recipePartition,
    ].join(''),
  };
};

export const getOrderKeyByQueryParam = (
  orders: OrderWithRecipesAndExtras[]
): string | null => {
  const queryParams = new URLSearchParams(window.location.search);
  let matchedOrderKey: string | null = '';

  try {
    const subscriptionNumber = queryParams.get(QueryParameter.subscriptionNumber);
    const weekQuery = queryParams.get(QueryParameter.week);

    // Partial match for subscription number or week
    if(weekQuery && !subscriptionNumber){
      matchedOrderKey = orders?.find(c=> c.orderKey.toLowerCase().includes(weekQuery.toLowerCase()))?.orderKey;
    }
    else if(!weekQuery && subscriptionNumber){
      matchedOrderKey = orders?.find(c=> c.orderKey.toLowerCase().includes(subscriptionNumber.toLowerCase()))?.orderKey;
    }
    // Full match.
    else if(weekQuery && subscriptionNumber) {
      const orderKey = `${subscriptionNumber}_${weekQuery.replace('W', '')}`;
      matchedOrderKey = orders?.find(
        (c) => c.orderKey.toLowerCase() === orderKey.toLowerCase()
      )?.orderKey;
    }
    return matchedOrderKey ?? null;
  } catch (e) {
    console.error(e);
    return null;
  }
};
