import React from 'react';

const LeftChevronIcon: React.FunctionComponent<
  React.SVGAttributes<SVGElement>
> = props => (
  <svg
    fill="#78a12e"
    color="#78a12e"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="8 6 7.41 12"
    width="16"
    height="16"
    {...props}
  >
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
  </svg>
);

export default LeftChevronIcon;
