/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import Select from 'react-select';
import {memoize} from 'lodash';
import {FormState} from './CancelContainer';
import HandleChangeProperty from '../../../shared/HandleChangeProperty';
import classnames from 'classnames';

export interface Props {
  form: FormState;
  reasonOptions: Array<Select.Option>;
  showComments: boolean;
  onChangeFormField<K extends keyof FormState>(
    field: K,
    value: FormState[K]
  ): void;
}

export default class CancelForm extends React.PureComponent<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.makeHandleChangeFormField = memoize(this.makeHandleChangeFormField);
    this.makeHandleChangeOption = memoize(this.makeHandleChangeOption);
  }

  makeHandleChangeFormField = <K extends keyof FormState>(field: K) => (
    event: React.SyntheticEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    this.props.onChangeFormField(field, event.currentTarget.value);
  };

  makeHandleChangeFormRadio = <K extends keyof FormState>(field: K) => (
    event: HandleChangeProperty
  ) => {
    this.props.onChangeFormField(field, event.value);
  };

  makeHandleChangeOption = <K extends keyof FormState>(field: K) => (
    option: Select.Option | Array<Select.Option>
  ) => {
    this.props.onChangeFormField(
      field,
      (option as Select.Option).value as string
    );
  };

  render() {
    const {form, reasonOptions, showComments} = this.props;

    return (
      <>
        <div
          className={classnames('form-group mb-3', {
            'has-danger': form.reasonError,
          })}
        >
          <label htmlFor="reasons">Why are you cancelling?</label>
          <div data-test="reasons-dropdown">
            <Select
              className="test-reason"
              placeholder="Select a reason"
              options={reasonOptions}
              required={true}
              value={form.reason}
              clearable={false}
              searchable={false}
              onChange={this.makeHandleChangeOption('reason')}
            />
          </div>
          {form.reasonError && (
            <div className="mt-2 mb-2">
              <span className="text-danger ">{form.reasonError}</span>
            </div>
          )}
        </div>
        {showComments && (
          <div
            className={classnames('form-group', {
              'has-danger': form.cancellationCommentsError,
            })}
          >
            <label>Please tell us more</label>
            <textarea
              className="form-control Select-control test-cancellationComments"
              value={form.cancellationComments}
              onChange={this.makeHandleChangeFormField('cancellationComments')}
              maxLength={1000}
            />
            {form.cancellationCommentsError && (
              <div className="mt-2 mb-2">
                <span className="text-danger">
                  {form.cancellationCommentsError}
                </span>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
