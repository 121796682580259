/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ApplyPromoCodeRequest,
  SubscriptionClient,
} from '../../../app/shared/CoreClient';
import {tryPerformAccountSettingsRequestWithErrors} from './accountSettingsAction';
import { globalSettings as settings } from 'config';

export const applyPromoCode = (request: ApplyPromoCodeRequest) => {
  const subscriptionClient = new SubscriptionClient(settings.bffHost);

  return tryPerformAccountSettingsRequestWithErrors(
    async () => await subscriptionClient.applyPromoCode(request)
  );
};

export default applyPromoCode;
