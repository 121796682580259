import React from 'react';
import {CampaignTierDto, GroupedCampaignDto} from '../../shared/CoreClient';
import classnames from 'classnames';
import {every} from 'lodash';
import Tier from './Tier';

const isTierReachable = (
  tier: CampaignTierDto,
  remainingDeliveryWeeks: number | null
) =>
  remainingDeliveryWeeks == null ||
  tier.deliveriesRequired <= remainingDeliveryWeeks;

interface Props {
  campaign: GroupedCampaignDto;
  selectedPrizes: Record<number, number>;
  onClick(
    e: React.MouseEvent<HTMLDivElement>,
    tierId: number,
    prizeId: number
  ): void;
}

export const CampaignPrizeSelector: React.FC<Props> = ({
  campaign,
  selectedPrizes,
  onClick,
}) => {
  const {campaignName, campaignTiers, remainingDeliveryWeeks} = campaign;

  const tiersThatCanBeReached = campaignTiers.filter(tier =>
    isTierReachable(tier, remainingDeliveryWeeks)
  );

  const everyTierOnlyHasOnePrize = every(
    tiersThatCanBeReached,
    tier => tier.campaignPrizes.length === 1
  );

  const hasMoreThanTwoTiers =
    tiersThatCanBeReached && tiersThatCanBeReached.length > 2;
  const isSingleTierCampaign =
    tiersThatCanBeReached && tiersThatCanBeReached.length === 1;

  return (
    <div
      className={
        everyTierOnlyHasOnePrize && hasMoreThanTwoTiers
          ? 'd-flex flex-wrap row'
          : 'd-flex flex-wrap row'
      }
    >
      {tiersThatCanBeReached &&
        tiersThatCanBeReached.map(t => (
          <div
            key={`tierId-${t.campaignTierId}`}
            className={classnames(
              'px-2 px-sm-1',
              t.campaignPrizes.length === 1 && !isSingleTierCampaign
                ? 'col-sm-6'
                : 'w-100'
            )}
          >
            <Tier
              tier={t}
              selectedPrizeId={selectedPrizes[t.campaignTierId]}
              onClick={onClick}
              everyTierOnlyHasOnePrize={everyTierOnlyHasOnePrize}
              isSingleTierCampaign={campaignTiers.length === 1}
              campaignName={campaignName}
            />
          </div>
        ))}
    </div>
  );
};
