import {
  RecipeSelectionMode,
  Recipe,
  RecipeResponse,
} from '../../shared/CoreClient';
import {
  RecipeCarouselItemProps,
  Alignment,
  CarouselItemModel,
  mapRecipeCarouselItem,
} from '@mfb/lego';

interface PropsLegacy {
  recipe: Recipe;
  recipeSelectionMode?: RecipeSelectionMode;
  showCardTag?: boolean;
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
}

interface Props {
  recipe: RecipeResponse;
  recipeSelectionMode?: RecipeSelectionMode;
  showCardTag?: boolean;
  onClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
}

export const mapRecipeToCarouselItemProps = (
  props: Props
): RecipeCarouselItemProps => {
  const {recipe, recipeSelectionMode, showCardTag, onClick} = props;

  return {
    id: `${recipe.recipeNumber}-${recipe.recipeVersion}-${recipe.recipePartition}`,
    src: recipe.imageUrl,
    alt: recipe.title,
    caption: recipe.title,
    showCaption: true,
    showShadow: true,
    quantity:
      (recipeSelectionMode === RecipeSelectionMode.Multiple &&
        recipe.quantity) ||
      undefined,
    captionAlignment: Alignment.Left,
    cardTag: showCardTag && {text: 'IN YOUR BAG', color: '#324B17'},
    onClick: onClick,
  };
};

export const mapRecipeToCarouselItemPropsLegacy = (
  props: PropsLegacy
): RecipeCarouselItemProps => {
  const recipeLegacy = props.recipe;
  const recipe: RecipeResponse = {
    title: recipeLegacy.name,
    subtitle: recipeLegacy.name,
    quantity: recipeLegacy.quantity,
    recipeNumber: recipeLegacy.recipeNumber,
    recipePartition: recipeLegacy.recipePartition,
    recipeVersion: recipeLegacy.recipeVersion,
    imageUrl: recipeLegacy.imageUrl,
    maximumQuantity: 1,
    servingSize: 1,
    isDemandLocked: false
  };

  return {
    ...mapRecipeToCarouselItemProps({...props, recipe}),
    id: recipeLegacy.id,
    src: recipe.imageUrl,
    alt: recipeLegacy.name,
    caption: recipeLegacy.name,
    surcharge: recipeLegacy.unitSurcharge,
  };
};

export const mapCarouselItemPropsToCarouselItemModel = (
  carouselItemProps: Array<RecipeCarouselItemProps>
): Array<CarouselItemModel> =>
  carouselItemProps.map(itemProp => mapRecipeCarouselItem(itemProp));
