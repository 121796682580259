import React from 'react';

const PersonalDetailsIcon: React.FunctionComponent<
  React.SVGAttributes<SVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    width="16"
    height="16"
    {...props}
  >
    <path d="M5.6 7c1.866 0 5.6.917 5.6 2.781v1.407H0V9.781c0-1.865 3.734-2.78 5.6-2.78zm0-1.406a2.78 2.78 0 0 1-2.784-2.78C2.816 1.275 4.061 0 5.6 0c1.539 0 2.783 1.276 2.783 2.813A2.78 2.78 0 0 1 5.6 5.593z" />
  </svg>
);

export default PersonalDetailsIcon;
