import createUserManager, {UserManager} from './UserManager';
import HttpStatus from 'http-status-codes';
import {navman} from '../../navigator';

/*
 * Responsible for before and after lifecycle hooks
 * for every request to the BFF
 */
export class BaseClient {
  /*
   * Invoked before a request is sent to the BFF. This is where base headers
   * and the bearer token are attached.
   */
  private userManager: UserManager;

  protected async transformOptions(options: RequestInit): Promise<RequestInit> {
    this.userManager = await createUserManager();
    const token = await this.userManager.getBearerToken();
    options.headers = new Headers({
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${token}`,
    });

    return options;
  }

  /*
   * Invoked after a request is sent to the BFF. This is where we can
   * deal with specific response codes or prompt re-authentication.
   */
  protected async transformResult<T>(
    url: string,
    resp: Response,
    processor: (r: Response) => Promise<T>
  ): Promise<T> {
    switch (resp.status) {
      case HttpStatus.UNAUTHORIZED:
        // This needs to run sync because we need make the swagger client happy.
        // It will hit the identity server and prompt re-auth
        this.userManager = await createUserManager();
        await this.userManager.authenticate();
        throw new Error('User is not authorized');
      case HttpStatus.FORBIDDEN:
        navman.unauthorized();

        return undefined;
      case HttpStatus.INTERNAL_SERVER_ERROR:
        navman.error();

        return undefined;
    }

    return processor(resp);
  }
}
