import {getQueryStringParams} from './getQueryString';

const storageKey = 'isReturnToApp';
const isReturnToApp = getQueryStringParams().returntoapp;

if (isReturnToApp) {
  sessionStorage.setItem(storageKey, `${isReturnToApp}`);
}

export const getIsReturnToApp = ():boolean => {
  return sessionStorage.getItem(storageKey) === 'true';
};

export const clearIsReturnToApp = () => {
  return sessionStorage.removeItem(storageKey);
};
