import React from 'react';
import Select from 'react-select';
import {AccountInformationClient} from '../../shared/CoreClient';
import { globalSettings as settings } from 'config';
import {debounce, isPlainObject} from 'lodash';
import {CSSProperties} from 'react';
const DEBOUNCE_DELAY = 800;
const MIN_SEARCH_LENGTH = 5;

interface Props {
  fullAddress: string;
  style?: CSSProperties;
  onAddressChange(state: AddressFinderState): void;
  initializePafId?: boolean;
}

export interface AddressFinderState extends Select.Option {
  fullAddress: string;
  pafId: number;
  isFocused: boolean;
}

type Callback = (error: any, result: any) => void;

export class AddressFinder extends React.Component<Props, AddressFinderState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fullAddress: props.fullAddress,
      pafId: -1,
      isFocused: false,
    };
  }

  onChangeAddress = (address: AddressFinderState) => {
    // Workaround: If you clear an address field by pressing backspace, this gets called with `[]` as an argument.
    // `isPlainObject` is used here to detect valid calls
    if (address != null && isPlainObject(address)) {
      this.setState({...address, isFocused: false});
      this.props.onAddressChange(address);
    }
  };

  render() {
    // debounce with this version of react-select (1.0.47) would create unreliable outcomes
    // see https://github.com/JedWatson/react-select/issues/1718
    const debouncedFetch = debounce(
      async (searchTerm: string, callback: Callback) => {
        const client = new AccountInformationClient(settings.bffHost);
        const suggestions = await client.suggest(searchTerm);
        callback(null, {options: suggestions});
      },
      DEBOUNCE_DELAY
    );

    // support for `mimimumInput` in `Select.Async` was removed in 1.0.0-rc.2
    // see https://github.com/JedWatson/react-select/issues/1227#issuecomment-248026395
    const searchAddress = (searchTerm: string, callback: Callback) => {
      if (searchTerm.length < MIN_SEARCH_LENGTH) {
        callback(null, {
          options: [],
        });
      } else {
        debouncedFetch(searchTerm, callback);
      }
    };

    if (this.props.initializePafId) {
      if (this.state.fullAddress && this.state.pafId === -1) {
        searchAddress(this.state.fullAddress, (res, options) => {
          if (this.state.fullAddress == options.options[0].fullAddress) {
            this.setState({...this.state, pafId: options.options[0].pafId});
            this.onChangeAddress(this.state);
          }
        });
      }
    }

    return (
      <Select.Async
        onChange={opt => this.onChangeAddress(opt as AddressFinderState)}
        onFocus={() => this.setState({isFocused: true})}
        onBlur={() => this.setState({isFocused: false})}
        name="address"
        style={this.props.style}
        clearable={false}
        value={this.state.isFocused ? '' : this.state}
        loadOptions={searchAddress}
        autoload={false}
        // https://github.com/JedWatson/react-select/issues/1708#issuecomment-328583142
        filterOptions={options => options}
        labelKey="fullAddress"
        valueKey="pafId"
        searchPromptText=""
        placeholder="Type to search"
        options={[]}
      />
    );
  }
}

export default AddressFinder;
