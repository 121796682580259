import {
  AccountSubscription,
  Frequency,
  SubscriptionActions,
  SubscriptionDTO,
  SubscriptionStatus,
} from './CoreClient';

/**
 * @returns true if an active subscription with frequency weekly/fortnightly exits
 */
export function hasBaseActiveSubscription(
  subscriptions: SubscriptionDTO[] | AccountSubscription[]
): boolean {
  if (subscriptions.length === 0) {
    return false;
  }

  const isAccountSubscription = (subscriptions[0] as AccountSubscription)
    .primaryFrequency;

  if (isAccountSubscription) {
    return (subscriptions as AccountSubscription[]).some(
      (c) =>
        c.primaryFrequency.value !== Frequency.OneOff &&
        c.primaryFrequency.value !== Frequency.FourWeekly &&
        c.actions.includes(SubscriptionActions.CancelSubscription)
    );
  } else {
    return (subscriptions as SubscriptionDTO[]).some(
      (c) =>
        c.frequency !== Frequency.OneOff &&
        c.frequency !== Frequency.FourWeekly &&
        c.status === SubscriptionStatus.Active
    );
  }
}

/**
 * @returns true if an active one-off subscription exits
 */
export function hasActiveOneOffSubscription(
  subscriptions: SubscriptionDTO[] | AccountSubscription[]
): boolean {
  if (subscriptions.length === 0) {
    return false;
  }

  const isAccountSubscription = (subscriptions[0] as AccountSubscription)
    .primaryFrequency;

  if (isAccountSubscription) {
    return (subscriptions as AccountSubscription[]).some(
      (c) => c.primaryFrequency.value === Frequency.OneOff
    );
  } else {
    return (subscriptions as SubscriptionDTO[]).some(
      (c) =>
        c.frequency === Frequency.OneOff &&
        c.status === SubscriptionStatus.Active
    );
  }
}


/**
 * @returns true if active recurring subscription exits
 */
export function hasActiveRecurringSubscription(
  subscriptions: SubscriptionDTO[] | AccountSubscription[]
): boolean {
  if (subscriptions.length === 0) {
    return false;
  }

  const isAccountSubscription = (subscriptions[0] as AccountSubscription)
    .primaryFrequency;

  if (isAccountSubscription) {
    return (subscriptions as AccountSubscription[]).some(
      (c) =>
        c.actions.includes(SubscriptionActions.CancelSubscription)
    );
  } else {
    return (subscriptions as SubscriptionDTO[]).some(
      (c) =>
        c.status === SubscriptionStatus.Active
    );
  }
}
