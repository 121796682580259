import {Dispatch, Store} from 'redux';
import { globalSettings as settings } from 'config';
import {
  AccountInformationClient,
  AccountSettings,
  ValidateAddressRequest,
  SwaggerException,
} from '../../../app/shared/CoreClient';
import HttpStatus from 'http-status-codes';
import {validationErrors} from '../sharedActions/validationErrorsAction';

export const validateSuggestedAddress = (
  selectedAddress: ValidateAddressRequest
) => async (dispatch: Dispatch<Store<AccountSettings>>) => {
  const accountSettingsClient = new AccountInformationClient(settings.bffHost);
  try {
    await accountSettingsClient.validateAddress({pafId: selectedAddress.pafId});
  } catch (err) {
    /*
        The server will return the validation errors in the result property.
        The swagger client will throw an exception which we need to catch and deal with.
        We need to send this back to the component so then the errors can be displayed.
      */
    const error = err as SwaggerException;
    if (error.status !== HttpStatus.BAD_REQUEST) {
      throw err;
    }

    return dispatch(validationErrors(error.result as AccountSettings));
  }
};
