import React from 'react';
import Icon, {IconProps} from './Icon';

class UnknownCard extends Icon {
  static defaultProps: Partial<IconProps> = {
    color: '#303030',
    height: 250,
    seasonal: false,
    title: 'Visa',
    width: 380,
  };

  renderIcon() {
    return (
      <g id="Shape_1">
        <g>
          <path
            fill="#434A54"
            d="M347.479-0.006H27.53c-15.201,0-27.524,12.273-27.524,27.413v22.082h374.997V27.407C375.003,12.268,362.68-0.006,347.479-0.006z M0.006,206.588c0,15.139,12.323,27.413,27.524,27.413h319.949c15.201,0,27.524-12.274,27.524-27.413V95.785H0.006V206.588z M278.669,132.945h54.743v17.361h-54.743V132.945z M42.209,132.945h121.26v17.361H42.209V132.945z M42.209,169.038h65.753v17.361H42.209V169.038z"
          />
        </g>
      </g>
    );
  }
}

export default UnknownCard;
