import React from 'react';
import {navman} from '../../../../navigator';

export const HolidaySkip: React.FC = () => {
  return (
    <div className="w-100 mt-5">
      <div className="d-flex-grow d-md-inline-flex">
        <button
          className="btn btn-primary btn-block rounded-lg mt-3 mr-3"
          onClick={navman.calendar}
          data-category="holiday-address-changes"
          data-label="skip-my-deliveries"
          data-action="click"
        >
          Skip My Deliveries
        </button>
      </div>
      <div className="d-flex-grow d-md-inline-flex">
        <button
          className="btn btn-secondary btn-block rounded-lg mt-3 px-5"
          onClick={navman.yourAccount}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
