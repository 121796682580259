import { SubscriptionDTO } from 'app/shared/CoreClient';
import {Reducer} from 'redux';
import { RECEIVE_SUBSCRIPTIONS, ReceiveSubscriptionsAction, SubscriptionsStateAction } from 'redux/actions/sharedActions/subscriptionsAction';

const reducer: Reducer<Array<SubscriptionDTO>> = (
  state = [],
  action: SubscriptionsStateAction
): Array<SubscriptionDTO> => {
  switch (action.type) {
    case RECEIVE_SUBSCRIPTIONS:
      return (action as ReceiveSubscriptionsAction).payload;
    default:
      return state;
  }
};

export default reducer;
