import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { ChangeBag } from '../../../../shared/components/ChangeBag';
import DeliverySlotSelect from '../../DeliverySlotSelect';
import { format } from 'date-fns';
import {
  AccountInformationClient,
  AccountSettings,
  AccountSubscription,
  AccountUpdateSubscriptionRequestBody,
  CostPreviewResponse,
  EnrollableCampaign,
  Frequency,
  Frequency2,
  ProductAndRecipeDetailsResponse,
  SubscriptionClient,
  ValidateAddressResponseDto,
} from '../../../../shared/CoreClient';
import AddressFinder from '../../AddressFinder';
import { ProductPriceDisplay, ProductPriceDisplayContext } from '@mfb/cookbook';
import { IBagSelectorContainerSelectedOptions } from '../../../../shared/components/BagSelector/BagSelectorContainer';
import { setPriceDisplay } from '../../../../shared/components/BagSelector/common/ValidatePromoCode';
import SubmitOrBack from '../../../../shared/components/SubmitOrBack';
import { navman } from '../../../../../navigator';
import { SubmitStage } from '../../../../shared/components/SubmitStage';
import { globalSettings as settings } from 'config';
import { FrequencyChanger, TFrequencyInfo } from '../../../../shared/components/FrequencyChanger';
import { AlertStatus, PreviewMessage, SvgExclamationTriangleFill } from '@mfb/lego';
import {
  modifySubscriptionAddressTracker,
  modifySubscriptionBagTracker,
  modifySubscriptionFrequencyTracker,
  modifySubscriptionNightsTracker,
  modifySubscriptionPeopleTracker,
  modifySubscriptionTimeTracker,
} from '../../../../shared/modifySubscriptionTracker';
import { useAlertToastContext } from 'app/shared/hooks/editDeliveryDetails';

export interface IEditSubscriptionDetails
  extends IBagSelectorContainerSelectedOptions {
  subscriptionNumber: string;
  getCostPreview: (sku: string) => Promise<CostPreviewResponse>;
  fieldStyles?: CSSProperties;
  onChangeBag?: () => void;
  accountSettings: AccountSettings;
  lastSubscription: AccountSubscription;
  campaignCode?: string;
  availableCampaigns?: EnrollableCampaign;
  hasFlexedAddress?: boolean;
  originalDeliverySlotId?: number;
  originalDeliverySlotDescription?: string;
  isSubscriptionChanged?: boolean;
  getProductDetailsForSkuAndWeek: (
    sku: string,
    promoCode: string
  ) => Promise<ProductAndRecipeDetailsResponse>;
}
interface IEditSubscriptionFormFields {
  addressId?: number;
  deliveryInstructions?: string;
  deliveryDateId?: number;
  frequency?: Frequency2;
}

interface IEditSubscriptionFormFieldErrors {
  address?: string;
  deliveryInstructions?: string;
  deliverySlot?: string;
  submitStage?: SubmitStage;
}
export const EditSubscriptionDetails = ({
  subscriptionNumber,
  bag,
  night,
  serves,
  sku,
  blurb,
  fullAddress,
  deliveryInstructions,
  availableBags,
  fieldStyles,
  onChangeBag,
  getCostPreview,
  accountSettings,
  lastSubscription,
  campaignCode,
  promoCode,
  availableCampaigns,
  hasFlexedAddress,
  upcomingWeek,
  originalDeliverySlotId,
  originalDeliverySlotDescription,
  isSubscriptionChanged,
  getProductDetailsForSkuAndWeek
}: IEditSubscriptionDetails) => {
  const [
    priceContext,
    setPriceContext,
  ] = React.useState<ProductPriceDisplayContext>({
    secondaryButtonHidden: true,
    primaryButtonHidden: true,
  });

  const formFields = useRef<IEditSubscriptionFormFields>({});
  const [
    formErrors,
    setFormErrors,
  ] = useState<IEditSubscriptionFormFieldErrors>({
    address: undefined,
    deliveryInstructions: undefined,
    deliverySlot: undefined,
    submitStage: SubmitStage.Invalid,
  });
  const [selectedAddress, setSelectedAddress] = useState<{
    pafId: number;
    fullAddress: string;
  }>({
    pafId: undefined,
    fullAddress: fullAddress,
  });

  const [frequencyInfo, setFrequencyInfo] = useState<TFrequencyInfo>({
    availableFrequency: [],
    defaultFrequency: undefined,
  });

  const [isDeliveryChanged, setIsDeliveryChanged] = useState(false);

  const subChangedAlert = useMemo(() => {
    // Only shows up when a customer changes SKU (bag)
    if(isSubscriptionChanged) {
      return {
        title: "Recipe preference selections will be reset",
        body: "Please note, this will reset your menu to our default selections so you will need to re-plan your recipes after submitting your changes"
      }
    }
  },[isSubscriptionChanged])

  const clearOverridesAlert = useMemo(() => {
    // Only shows up when a customer changes their address when they have flexed delivery
    if (hasFlexedAddress && isDeliveryChanged) {
      return {
        title: 'Updates to your delivery information',
        body: `All future deliveries for this subscription, including any delivery address or time changes will be updated from ${format(upcomingWeek, 'EEE dd MMM')}`
      }
    } 
  },[isDeliveryChanged, hasFlexedAddress, upcomingWeek])

  const { updateToast } = useAlertToastContext();

  useEffect(() => {
    const init = async () => {
      const productDetailResult = (await getProductDetailsForSkuAndWeek(sku, promoCode));
      const defaultFrequency = productDetailResult.defaultFrequency;
      const productAvailableFrequency = productDetailResult.availableFrequencies;

      setFrequencyInfo({
        availableFrequency: productAvailableFrequency,
        defaultFrequency: defaultFrequency,
      });

      await setPrice();
    };
    init();
  }, []);

  const getBagId = () => {
    return availableBags.filter(r => r.itemNumber == sku)[0].bagId;
  };

  const getAddressId = async () => {
    const res: ValidateAddressResponseDto = await new AccountInformationClient(
      settings.bffHost
    ).validateAddress2({addressSuggestionId: selectedAddress.pafId});

    setFormValue({addressId: res.addressId});
    return res.addressId;
  };

  const onDeliveryInstructionsChange = (deliveryInstructions: string) => {
    if (deliveryInstructions.length > 100) {
      setFormError({
        deliveryInstructions: `We're sorry, delivery instructions must be 100 characters or less.`,
      });
    } else {
      setFormError({
        deliveryInstructions: undefined,
      });
    }
    deliveryInstructions && setFormValue({deliveryInstructions});
  };

  const onDeliverySlotChange = (deliverySlotId: number) => {
    if (deliverySlotId === 0) {
      setFormError({
        address: `We're sorry, we don't deliver to this address yet.`,
      });
      return;
    }

    setFormError({
      address: undefined,
      deliverySlot: undefined,
    });
  };

  const setPrice = async () => {
    setPriceContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    const costPreviewResponse = await getCostPreview(sku);
    setPriceDisplay(setPriceContext, costPreviewResponse, {
      night: night,
      serves: serves,
      sku: sku,
      itemNumber: undefined,
    });
    setPriceContext(prevState => ({
      ...prevState,
      primaryButtonHidden: true,
      isLoading: false,
    }));
  };

  const setFormValue = (field: IEditSubscriptionFormFields) => {
    formFields.current = {...formFields.current, ...field};
  };

  const setFormError = (error: IEditSubscriptionFormFieldErrors) => {
    error = {...formErrors, ...error};
    let submitStage = null;
    if (error.address || error.deliveryInstructions || error.deliverySlot) {
      submitStage = SubmitStage.Invalid;
    } else {
      submitStage = SubmitStage.Valid;
    }
    setFormErrors(prevErrors => ({
      ...prevErrors,
      ...error,
      submitStage: submitStage,
    }));
  };

  const onUpdateSubscription = async () => {
    setFormErrors(prev => ({...prev, submitStage: SubmitStage.Saving}));
    const subClient = new SubscriptionClient(settings.bffHost);
    const payload: AccountUpdateSubscriptionRequestBody = {
      sku: sku,
      addressId: selectedAddress.pafId ? await getAddressId() : undefined,
      deliverySlotId: formFields.current.deliveryDateId,
      deliveryInstructions: formFields.current.deliveryInstructions ?? deliveryInstructions ?? "",
      frequency: formFields.current.frequency,
    };

    const res = await subClient.updateSubscriptionV2(
      subscriptionNumber,
      payload
    );

    setFormErrors(prev => ({...prev, submitStage: SubmitStage.Valid}));

    if (res) {
      if (navman.routeToApp()) {
      }  else if (campaignCode) {
        navman.campaignModal(campaignCode);
      } else {
        navman.yourAccount();
      }
      updateToast({
        status: AlertStatus.success,
        isOpen: true,
        text: 'You have successfully updated your subscription',
      });
    }

    try {
      const currentBagId = getBagId();
      const dates = await subClient.deliveryDates(currentBagId);
      const deliveryDate = dates.find(date => {
        return date.deliverySlotId === lastSubscription.deliverySlotId.value;
      });
      const lastSubscriptionDay =
        deliveryDate && format(new Date(deliveryDate.date), 'EEEE');
      const currentDeliveryDate = dates.find(date => {
        return date.deliverySlotId == payload.deliverySlotId;
      });
      const subscriptionDay =
        currentDeliveryDate &&
        format(new Date(currentDeliveryDate.date), 'EEEE');
      const subscriptionFrequency =
        Frequency[formFields.current.frequency] ||
        Frequency[lastSubscription.primaryFrequency.value];
      const lastSubscriptionFrequency =
        Frequency[lastSubscription.primaryFrequency.value];

      currentBagId !== lastSubscription.primaryBagId &&
        modifySubscriptionNightsTracker(subscriptionNumber, bag.sku, night);
      const numberOfPeopleToFeed = availableBags.find(
        a => a.bagId === currentBagId
      ).numberOfPeopleToFeed;
      if (currentBagId !== lastSubscription.primaryBagId) {
        modifySubscriptionBagTracker(lastSubscription, bag.sku, bag.name);

        // Tracks people's info when bag is changed
        if (serves === numberOfPeopleToFeed) {
          modifySubscriptionPeopleTracker(
            subscriptionNumber,
            bag.sku,
            numberOfPeopleToFeed,
            serves
          );
        }
      }

      serves !== numberOfPeopleToFeed &&
        modifySubscriptionPeopleTracker(
          subscriptionNumber,
          bag.sku,
          numberOfPeopleToFeed,
          serves
        );

      selectedAddress.fullAddress != accountSettings.customer.address.value &&
        modifySubscriptionAddressTracker(
          subscriptionNumber,
          bag.sku,
          selectedAddress.fullAddress
        );

      payload.deliverySlotId !== lastSubscription.deliverySlotId.value &&
        modifySubscriptionTimeTracker(
          subscriptionNumber,
          bag.sku,
          currentDeliveryDate && currentDeliveryDate.description,
          deliveryDate.description,
          lastSubscriptionDay,
          subscriptionDay
        );

      subscriptionFrequency !== lastSubscriptionFrequency &&
        modifySubscriptionFrequencyTracker(
          lastSubscriptionFrequency,
          subscriptionFrequency,
          bag.sku,
          subscriptionNumber
        );
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <div className="col-12 col-lg-8">
        <ChangeBag bagName={bag.name} onClick={onChangeBag} label={blurb} />
      </div>
      {subChangedAlert && (
         <div className="form-group col-lg-8 col-12 mt-3 p-0" data-test='message-container'>
         <PreviewMessage
           heading={subChangedAlert.title} 
           body={subChangedAlert.body}
           headingIcon={<SvgExclamationTriangleFill width={20} height={20} />}
           variant={'warning'}
         />
       </div>
      )}
      {clearOverridesAlert && (
        <div className="form-group col-lg-8 col-12 mt-3 p-0" data-test='message-container'>
          <PreviewMessage
            heading={clearOverridesAlert.title} 
            body={clearOverridesAlert.body}
            headingIcon={<SvgExclamationTriangleFill width={20} height={20} />}
            variant={'warning'}
          />
        </div>
      )}
      {/*TODO implement cookbooks address finder*/}
      <div className="form-group mt-3 col-lg-8 col-12 p-0">
        <label>Delivery Address</label>
        <AddressFinder
          onAddressChange={async addressState => {
            setSelectedAddress({
              pafId: addressState.pafId,
              fullAddress: addressState.fullAddress,
            });
            setIsDeliveryChanged(true);
          }}
          style={{...fieldStyles}}
          fullAddress={fullAddress}
        />
        {formErrors.address && (
          <span className="d-block mt-2 form-control-feedback text-danger">
            {`We're sorry, we don't delivery to this address this time.`}
          </span>
        )}
      </div>
      <div className="form-group col-lg-8 col-12 p-0">
        <label>Delivery Instructions</label>
        <textarea
          name="deliveryInstructions"
          placeholder="e.g. Leave under carport"
          className="form-control w-100 py-1 bg-transparent"
          maxLength={101}
          defaultValue={deliveryInstructions}
          onChange={e => onDeliveryInstructionsChange(e.target.value)}
          spellCheck="false"
          style={{...fieldStyles}}
        />
        <small className="text-muted">
          Instructions must be 100 characters or less.
        </small>
        {formErrors.deliveryInstructions && (
          <span className="d-block mt-2 form-control-feedback text-danger">
            {formErrors.deliveryInstructions}
          </span>
        )}
      </div>
      <div className="col-lg-8 col-12 p-0">
        <DeliverySlotSelect
          bagId={getBagId()}
          selectedDeliverySlotId={originalDeliverySlotId}
          pafId={selectedAddress.pafId}
          label={'Delivery Schedule Day and Time'}
          style={{...fieldStyles}}
          noSlotMessage={`We're sorry, no delivery slots available for this address at this time.`}
          onChange={deliveryId => {
            setFormValue({deliveryDateId: deliveryId});
            onDeliverySlotChange(deliveryId);
          }}
        />
      </div>
      <div>
        <FrequencyChanger
          frequencyInfo= {frequencyInfo}
          onFrequencyChange={c => setFormValue({frequency: c})}
        />
      </div>

      <div className="mt-4">
        <ProductPriceDisplay context={priceContext} />
      </div>
      <div className="row">
        <SubmitOrBack
          submitLabel={'Update Subscription'}
          backLabel={'Keep my current subscription'}
          containerClassName={'col-md-12 col-lg-9 mb-2 flex-row-reverse'}
          classNameSpacer={'order-lg-2'}
          wrapperClassName={'d-flex justify-content-start flex-wrap'}
          backClassName={
            'btn btn-secondary text-nowrap col-lg-6 mt-3 order-lg-1 mb-lg-0 mb-4'
          }
          submitClassName={
            'btn btn-primary text-nowrap col-lg-4 mt-3 order-lg-3'
          }
          submitStage={formErrors.submitStage}
          onSubmit={onUpdateSubscription}
          onBack={() => {
            if (navman.routeToApp()) {
            } else {
              navman.accountSettings();
            }
          }}
        />
      </div>
    </>
  );
};
