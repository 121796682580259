export enum SubmitStage {
  Invalid,
  Valid,
  Saving,
}

export const getSubmitStage = (
  isValid: boolean,
  isSubmitting: boolean
): SubmitStage => {
  if (isSubmitting) return SubmitStage.Saving;
  else if (isValid) return SubmitStage.Valid;
  else return SubmitStage.Invalid;
};
