import { CampaignSignUpModalContainer } from 'app/campaign/components/CampaignSignUpModalContainer';
import {
  CustomerMessageProxyDeepLink,
  cmpNavigateToUrl,
  customerMessageDefaultContext,
} from 'app/shared/CustomerMessageService';
import {
  hasActiveOneOffSubscription,
  hasActiveRecurringSubscription,
} from 'app/shared/Subscriptions';
import { useAlertToastContext } from 'app/shared/hooks/editDeliveryDetails';
import classnames from 'classnames';
import { isAfter, isSameDay } from 'date-fns';
import { find } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { UiComponentName } from 'redux/actions/sharedActions/updateUiComponent';
import styled from 'styled-components';
import {
  AlertStatus,
  AlertToast,
  Colours,
  CustomerMessageProvider,
} from '@mfb/lego';
import { DispatchProps, PageProperties } from '..';
import { dateFormat } from '../../../lib/date/dateFormat';
import { PlanYourWeekNavConfig, navman } from '../../../navigator';
import {
  selectCurrentDelivery,
  selectCurrentOrder,
  selectCurrentOrderExtras,
  selectCurrentPrimaryOption,
} from '../../../redux/selectors/orderPageSelectors';
import {
  AppState,
  OrdersPageState,
  UiState,
} from '../../../redux/state';
import { SkipPayload } from '../../../redux/thunks/skipActionThunk';
import {
  DispatcherProps,
  mapDispatchToProps,
} from '../../account-settings/containers/accountSettingsContainer';
import { MerchandisingSlots } from '../../merchandising/MerchandisingSlots';
import {
  AccountFeatures,
  AvailableFrequency,
  Brand,
  DeliveryDay,
  DeliveryLineResponse,
  Order,
  OrderAction,
  OrderWithRecipesAndExtras,
  SubscriptionActions,
  SubscriptionDTO,
  SubscriptionStatus,
} from '../../shared/CoreClient';
import MessageList from '../../shared/components/MessageList';
import buildKey from '../../shared/orderKeyGenerator';
import { CampaignBanner } from './campaigns/CampaignBanner';
import { CampaignPromotionBanner } from './campaigns/CampaignPromotionBanner';
import { OrderExtras } from './OrderExtras';
import { OrderFooter } from './OrderFooter';
import { OrderRecipes } from './OrderRecipes';
import { SkipAction } from './SkipAction';
import { BufferPromoSection } from './bufferPromoBanner/BufferPromoSection';
import { DeliveryDayCarousel } from './deliveryWeeks/DeliveryDayCarousel';
import { NoRecipesBlurb } from './deliveryWeeks/NoRecipesBlurb';
import { OrderHeader } from './deliveryWeeks/OrderHeader';
import { COLOR_CODE, ProductCollectionCode } from '../../shared/constants';
import SkippedBoxIcon from '../../shared/svgs/SkippedBoxIcon';
import { getQueryStringParams } from '../../shared/getQueryString';
import { ContentSlotCustomerMessages } from './customerMessageBanner/ContentSlotCustomerMessages';
import { useEditDeliveryDetailsService } from 'app/shared/hooks/editDeliveryDetails/useEditDeliveryDetailsService';
import { deliveriesPath } from 'app/account-settings/components/Nav/paths';
import { OrderWithRecipesAndExtrasExtended } from 'app/shared/sculleryToCoreResponseMapper';
import { TickerTapeCampaignWrapper } from './campaigns/TickerTapeCampaignWrapper';
import { TickerTapeCampaignButton } from './campaigns/TickerTapeCampaignButton';
import { CampaignTheme } from '@mfb/account-api-client';

const Container = styled.div`
  .__content-slot-cmp-top {
    background-color: ${Colours.SECONDARY_CREAM};
    .__inner-container {
      padding: 16px 0px;

      @media ${(c) => c.theme.layout.deviceMediaQueries.max.lg} {
        padding: 16px;
      }
    }
  }
`;

const AlertToastContainer = styled.div`
  @media ${(p) => p.theme.layout.deviceMediaQueries.max.sm} {
    width: 100%;
    bottom: 60px; /* offset the nav bar */
    margin-bottom: 16px;
  }

  position: fixed;
  margin-top: 16px;
  max-width: 448px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 5060;
`;

const TickerTapeCampaignButtonContainer = styled.div`
   @media ${c => c.theme.layout.deviceMediaQueries.min.lg} {
    display: none;
   }
`

export interface BagInteractionHandlers {
  skip(order: Order, payLoad?: Partial<SkipPayload>): Promise<void>;

  editSub(subscriptionId: number, subscriptionNumber: string): void;

  editExtras(configuration: PlanYourWeekNavConfig): void;
}

/**
 * We really shouldn't be sharing entire states like these.
 * This interface is an effort to cut that down.
 */
export interface DeliveryContainerWithTabsDerivedProps {
  currentDelivery?: DeliveryDay;
  currentOrder?: OrderWithRecipesAndExtrasExtended;
  currentPrimaryOption?: DeliveryLineResponse;
  currentExtras?: DeliveryLineResponse[];
  features: AccountFeatures;
  uiState: UiState;
  subscriptions: SubscriptionDTO[];
}

type StoreProps = AppState<OrdersPageState> &
  DeliveryContainerWithTabsDerivedProps;
type OwnDispatcherProps = DispatcherProps & DispatchProps;
export type DeliveryContainerWithTabsProps = StoreProps & OwnDispatcherProps;

export const UnconnectedDeliveryContainerWithTabs: React.FC<
  DeliveryContainerWithTabsProps
> = ({
  pageState,
  uiState,
  features,
  performSkipAction,
  updateSelectedDay,
  fetchYourAccountState,
  fetchOrderPageThunk,
  fetchCampaignState,
  currentDelivery,
  currentOrder,
  currentPrimaryOption,
  currentExtras,
  customerProfileState,
  subscriptions,
  tracking,
  campaign,
  ...props
}) => {
  const [hasValidationError, setHasValidationError] = React.useState(false);
  const { i18n } = useTranslation();

  const { toast, closeToast } = useAlertToastContext();

  const {
    subscriptionAction,
    deliveryDays,
    orders,
    messages,
    accountMerchSection,
    bagSelection,
  } = pageState;

  const canStartSub =
    subscriptionAction === SubscriptionActions.StartNewSubscription;
  const canRestartSub =
    subscriptionAction === SubscriptionActions.RestartSubscription;
  const canStartOrRestartSubscription = canStartSub || canRestartSub;

  const hasNoUpcomingDeliveries = React.useMemo(
    () =>
      deliveryDays &&
      deliveryDays.length === 0 &&
      !canStartOrRestartSubscription &&
      pageState.subscriptions.length === 0,
    [deliveryDays, subscriptionAction]
  );

  // trying to avoid an extra redirect when customer tries to join campaign
  const campaignCode = getQueryStringParams().campaignCode;
  const queryWeek = getQueryStringParams().editWeek;

  const updateEditSubscriptionComponentForCurrentOrder = () => {
    if (!currentOrder) {
      return;
    }

    const subscription = customerProfileState.subscriptions.find(
      (x) => x.subscriptionNumber === currentOrder.subscriptionNumber
    );
    i18n.changeLanguage(
      subscription?.productCollectionCode === ProductCollectionCode.THE_BUTCHER
        ? 'butcher'
        : 'default'
    );

    const isSubscriptionEditable = currentOrder.orderActions.includes(
      OrderAction.CanChangeBag
    );

    if (!isSubscriptionEditable) {
      props.performUpdateUiComponentAction(
        {
          name: UiComponentName.EDIT_SUBSCRIPTION,
          subscriptionNumber: currentOrder.subscriptionNumber,
          deliveryDateId: currentOrder.deliveryDate.deliveryDateId,
        },
        {
          isHidden: true,
        }
      );
    }
  };

  const redirectToReactivateSubscription = () => {
    if (!subscriptions || !orders) {
      return;
    }

    if (
      !hasActiveRecurringSubscription(subscriptions) &&
      !hasActiveOneOffSubscription(subscriptions) &&
      orders.length === 0
    ) {
      navman.reactivateSubscription();
    }
  };

  useEffect(() => {
    redirectToReactivateSubscription();
    fetchCampaignState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateEditSubscriptionComponentForCurrentOrder();
  }, [currentDelivery]);

  const { updateToast } = useAlertToastContext();

  const handleSkipUnSkip = React.useCallback(
    async (order: Order, payload?: Partial<SkipPayload>) => {
      const { subscriptionId, week, isSkipped } = order;

      const _payload = {
        subscriptionId,
        week,
        action: isSkipped ? SkipAction.UnSkip : SkipAction.Skip,
        ...payload,
      };

      try {
        await performSkipAction(_payload);
        updateToast({
          status: AlertStatus.success,
          isOpen: true,
          text: `You have successfully ${isSkipped ? 'unskipped' : 'skipped'} your delivery`,
        });
      } catch (err) {
        setHasValidationError(true);
        updateToast({
          status: AlertStatus.error,
          isOpen: true,
          text: `${isSkipped ? 'Unskip' : 'Skip'} delivery was unsuccessful. Please try again!`,
        });
      }
    },
    []
  );

  const handleEditSubscription = React.useCallback(
    (subscriptionId: number, subscriptionNumber: string = null) => {
      navman.editSubscription(subscriptionId, subscriptionNumber);
    },
    [navman]
  );

  const handleEditExtras = React.useCallback(
    (configuration: PlanYourWeekNavConfig) => {
      navman.planYourWeek(configuration);
    },
    [navman]
  );

  const bagInteractionHandlers: BagInteractionHandlers = React.useMemo(
    () => ({
      skip: handleSkipUnSkip,
      editSub: handleEditSubscription,
      editExtras: handleEditExtras,
    }),
    [handleSkipUnSkip, handleEditSubscription, handleEditExtras]
  );

  const date = currentDelivery && currentDelivery.order.orderKey;
  const orderLegacy = currentDelivery && currentDelivery.order;

  const week =
    currentOrder && dateFormat(currentOrder.deliveryDate.weekStarting);

  const { displayEditDelivery } = useEditDeliveryDetailsService({
    currentOrder,
    deliveryWeek: week,
    postSuccessAction: fetchOrderPageThunk,
    tracking,
  });

  React.useEffect(() => {
    if (queryWeek) {
      const formattedWeek = new Date(queryWeek.replace('W', ''));
      formattedWeek.setHours(0, 0, 0, 0);

      var relevantDeliveryDay = orders.find(o => o.deliveryDate.weekStarting.valueOf() === formattedWeek.valueOf());

      if (relevantDeliveryDay) {
        if (currentOrder.orderKey !== relevantDeliveryDay.orderKey) {
          updateSelectedDay(relevantDeliveryDay.orderKey);
        } else {
          displayEditDelivery();
          window.history.pushState({}, '', deliveriesPath);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryWeek, currentOrder])

  const isSkippedAndNotEditable =
    currentOrder &&
    currentOrder.isSkipped &&
    /*
      Meaning if we can't do anything to the delivery. We don't show
      kitchen information
     */
    !currentOrder.orderActions.some(
      (a) => a === OrderAction.Skip || a === OrderAction.Unskip
    );

  /*
    Only show buffer promo for upcoming delivery week dates
  */
  const orderIsForUpcomingDeliveryWeek =
    currentOrder &&
    isSameDay(
      new Date(currentOrder.deliveryDate.weekStarting),
      new Date(pageState.upcomingDeliveryWeek)
    );
  /*
    Dont show buffer promo if there is a delivery for
    the upcoming delivery week
  */
  const noDeliveryForUpcomingWeek =
    orders &&
    !orders.find(
      (o) =>
        isSameDay(
          new Date(o.deliveryDate.weekStarting),
          new Date(pageState.upcomingDeliveryWeek)
        ) && o.deliveryId
    );

  const isDeliveryForFutureWeek =
    currentOrder &&
    isAfter(
      new Date(currentOrder.deliveryDate.weekStarting),
      new Date(pageState.upcomingDeliveryWeek)
    ) &&
    !isSameDay(
      new Date(currentOrder.deliveryDate.weekStarting),
      new Date(pageState.upcomingDeliveryWeek)
    );

  const shouldShowBufferPromo =
    features.enableBufferInAccount &&
    currentOrder &&
    isSkippedAndNotEditable &&
    !currentOrder.deliveryId &&
    currentOrder.hasAvailableBufferDates &&
    orderIsForUpcomingDeliveryWeek &&
    noDeliveryForUpcomingWeek &&
    currentOrder &&
    currentOrder.availableRecipes;

  const shouldShowDemandLockMessage =
    isSkippedAndNotEditable &&
    isDeliveryForFutureWeek &&
    currentOrder &&
    currentOrder.isDemandLocked;

  // TODO: late sales page and order form still use SKU which is why we can't use the "sku" from currentPrimaryOption
  const currentBag =
    bagSelection &&
    currentPrimaryOption &&
    find(bagSelection, (b) => b.itemNumber === currentPrimaryOption.sku);

  const appliedPromoCode =
    currentOrder && currentOrder.pricing && currentOrder.pricing.appliedDiscount
      ? currentOrder.pricing.appliedDiscount.promoGroupCode
      : null;

  const onContentSlotCustomerMessageNavigate = async (url?: string) => {
    if(url.startsWith(CustomerMessageProxyDeepLink.EDIT_DELIVERY_DETAILS)) {
      await displayEditDelivery();
    } else {
      cmpNavigateToUrl(url);
    }
  };

  const [isTickerTapePanelOpen, setIsTickerTapePanelOpen] = React.useState<boolean>(false);
  const useTickerTapeCampaignLayout = 
  (!campaign.enrolledCampaignDto && campaign.eligibleCampaignDto && campaign.eligibleCampaignDto.theme === CampaignTheme.TickerTape) || 
  (campaign.enrolledCampaignDto && campaign.enrolledCampaignDto.theme === CampaignTheme.TickerTape);

  return (
    <Container className="bg-white">
      <TickerTapeCampaignWrapper {...{
        subscriptionNumber: currentOrder && currentOrder.subscriptionNumber,
        isPanelOpen: isTickerTapePanelOpen, 
        setIsPanelOpen: setIsTickerTapePanelOpen
      }} />
      <AlertToastContainer>
        {toast && toast?.isOpen && (
          <AlertToast
            isOpen={toast?.isOpen}
            placement="top"
            millisecondsToDismiss={5000}
            status={toast?.status}
            variant="default"
            fullSize={true}
            onClose={() => closeToast()}
          >
            {toast?.text}
          </AlertToast>
        )}
      </AlertToastContainer>

      {campaignCode && (
        <CampaignSignUpModalContainer
          {...{
            subscriptionNumber: currentOrder && currentOrder.subscriptionNumber,
            campaignCode: campaignCode,
            onSubmitCallback: () => {
              props.performJoinCampaign();
              setIsTickerTapePanelOpen(true);
            }}
          }
        />
      )}
      <div style={{ backgroundColor: COLOR_CODE.BARELY_THERE_BEIGE }}>
        <div className="mb-lg-1 mb-5">
          {subscriptionAction === SubscriptionActions.Default &&
            deliveryDays &&
            deliveryDays.length > 0 && (
              <>
                <CampaignBanner />
                <CampaignPromotionBanner />
                {Boolean(messages.length) && (
                  <div
                    className={classnames(
                      'container',
                      'pt-3',
                      deliveryDays && deliveryDays.length === 0 ? 'pb-1' : ''
                    )}
                  >
                    <MessageList messages={messages} />
                  </div>
                )}
                <CustomerMessageProvider
                  context={customerMessageDefaultContext}
                >
                  <ContentSlotCustomerMessages
                    order={currentOrder}
                    customerMessageBulkGroupState={
                      props.customerMessageBulkGroupState
                    }
                    onNavigate={onContentSlotCustomerMessageNavigate}
                    bagSku={currentPrimaryOption?.sku}
                    className="__content-slot-cmp-top"
                  />
                </CustomerMessageProvider>
                <DeliveryDayCarousel
                  hasTickerTapeCampaign={useTickerTapeCampaignLayout}
                  setIsTickerTapePanelOpen={setIsTickerTapePanelOpen}
                  deliveryDays={deliveryDays}
                  selectedDate={date}
                  updateSelectedDay={updateSelectedDay}
                  className="mb-lg-0 container"
                />
              </>
            )}
        </div>
      </div>
      <div className="container">
        {hasNoUpcomingDeliveries && (
          <p className="mt-3 pl-4 pt-4">You have no upcoming deliveries.</p>
        )}
      </div>
      <div className={classnames('container', 'mb-lg-1 mb-5')}></div>
      <div className="container">
        {subscriptionAction === SubscriptionActions.Default &&
          deliveryDays &&
          deliveryDays.length > 0 && (
            <>
              <div>
                <div
                  className="mb-5"
                  key={buildKey(orderLegacy.week, orderLegacy.subscriptionId)}
                >
                  <TickerTapeCampaignButtonContainer>
                    <TickerTapeCampaignButton {...{setIsPanelOpen: setIsTickerTapePanelOpen}}/>
                  </TickerTapeCampaignButtonContainer>
                  {!shouldShowBufferPromo && (
                    <OrderHeader
                      orderLegacy={orderLegacy}
                      currentOrder={currentOrder}
                      day={date}
                      hasValidationError={hasValidationError}
                      bagInteractionHandlers={bagInteractionHandlers}
                      fetchYourAccountState={fetchYourAccountState}
                      isSingleSubscription={
                        pageState.subscriptions.filter(
                          (c) => c.status === SubscriptionStatus.Active
                        ).length === 1
                      }
                      className="mt-4"
                    />
                  )}
                  {!isSkippedAndNotEditable && (
                    <>
                      {orderLegacy.recipes && orderLegacy.recipes.length > 0 ? (
                        <>
                          <OrderRecipes
                            recipes={currentOrder.selectedRecipes}
                            deliveryStatus={currentOrder.deliveryStatus}
                            recipeSelectionMode={
                              orderLegacy.recipeSelectionMode
                            }
                            className="mb-5 mt-2"
                          />
                          <OrderExtras
                            primaryProductSku={currentPrimaryOption.sku}
                            day={date}
                            extras={currentExtras}
                            isVirtualDelivery={
                              currentOrder && currentOrder.isVirtual && !currentOrder.isCreating
                            }
                            week={week}
                            subscriptionNumber={
                              currentOrder && currentOrder.subscriptionNumber
                            }
                            bagInteractionHandlers={bagInteractionHandlers}
                            appliedPromoCode={appliedPromoCode}
                          />
                        </>
                      ) : (
                        <NoRecipesBlurb />
                      )}
                    </>
                  )}
                  {shouldShowBufferPromo ? (
                    <BufferPromoSection
                      bagSku={
                        currentBag ? currentBag.sku : currentPrimaryOption.sku
                      }
                      addresId={currentOrder.deliveryAddress.addressId}
                      recipes={currentOrder.availableRecipes}
                      defaultRecipeCategories={
                        currentOrder.defaultRecipeCategories
                      }
                    />
                  ) : shouldShowDemandLockMessage ? (
                    <div className="d-flex justify-content-center flex-col flex-wrap">
                      <div className="w-100 d-flex justify-content-center">
                        <SkippedBoxIcon />
                      </div>
                      <h4 className="w-100 d-flex justify-content-center mb-1">
                        Delivery&nbsp;Skipped.
                      </h4>
                      <p className="w-100 col-10 col-md-5 col-lg-4 text-center">
                        Due to high demand this delivery can&apos;t be unskipped
                        at the moment.
                      </p>
                    </div>
                  ) : null}
                  {!isSkippedAndNotEditable && (
                    <OrderFooter
                      {...{
                        order: currentOrder,
                        handleEditSubscription,
                        tracking: tracking,
                        customerProfileState,
                        fetchOrderPageThunk
                      }}
                    />
                  )}
                </div>
              </div>
              <MerchandisingSlots {...accountMerchSection} />
            </>
          )}
      </div>
    </Container>
  );
};

const ConnectedDeliveryContainerWithTabs = connect<
  StoreProps,
  DispatcherProps,
  PageProperties<OrdersPageState>
>((state: AppState<OrdersPageState>): StoreProps => {
  return {
    ...state,
    currentOrder: selectCurrentOrder(state),
    currentDelivery: selectCurrentDelivery(state),
    currentPrimaryOption: selectCurrentPrimaryOption(state),
    currentExtras: selectCurrentOrderExtras(state),
    features: state.features,
    subscriptions: state.subscriptions,
    campaign: state.campaign,
    uiState: state.ui,
  };
}, mapDispatchToProps)(UnconnectedDeliveryContainerWithTabs);

export default ConnectedDeliveryContainerWithTabs;
