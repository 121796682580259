/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Select from 'react-select';
import {SubscriptionClient, DeliveryDate} from '../../shared/CoreClient';
import { globalSettings as settings } from 'config';
import {first, isUndefined} from 'lodash';
import {CSSProperties} from "react";

interface State {
  options: Array<Select.Option>;
  selectedDeliveryDateId?: number;
  selectedDeliveryWeekStarting?: Date;
  selectedDeliveryDate?: Date;
  hasUpdatedDeliveryDate: boolean;
  showInitialEmptyValue?:boolean;
}

export interface DeliveryDateSelectProps {
  bagId?: number;
  selectedDeliveryDateId?: number;
  selectedDeliveryWeekStarting?: Date;
  selectedDeliveryDate?: Date;
  errorMessage?: string;
  onChange(deliveryId: number, weekStarting: Date, deliveryDate: Date): void;
  label?:string;
  style?:CSSProperties;
  defaultNoValue?:boolean;
  placeholder?:string;
}

interface DeliveryDateOption extends Select.Option {
  weekStarting?: Date;
  deliveryDate?: Date;
}

export default class DeliveryDateSelect extends React.PureComponent<
  DeliveryDateSelectProps,
  State
  > {
  subscriptionApiClient: SubscriptionClient;

  constructor(props: DeliveryDateSelectProps) {
    super(props);

    this.subscriptionApiClient = new SubscriptionClient(settings.bffHost);

    this.state = {
      options: [],
      selectedDeliveryDateId: props.selectedDeliveryDateId,
      selectedDeliveryWeekStarting: props.selectedDeliveryWeekStarting,
      selectedDeliveryDate: props.selectedDeliveryDate,
      hasUpdatedDeliveryDate: false,
    };
  }

  fetchDates = async (): Promise<Array<DeliveryDateOption>> => {
    if (isUndefined(this.props.bagId) || this.props.bagId === null) {
      return [];
    }

    const dates = await this.subscriptionApiClient.deliveryDates(
      this.props.bagId
    );
    const options = dates.map((d: DeliveryDate) => ({
      value: d.id,
      label: d.description,
      weekStarting: d.weekStarting,
      deliveryDate: d.date,
    }));

    return options;
  };

  async componentDidMount() {
    const options = await this.fetchDates();
    this.tryGetSameSlotOnBagChange(options);

    if(this.props.defaultNoValue){
      this.setState({...this.state, selectedDeliveryDateId:undefined})
    }
  }

  async componentDidUpdate(
    prevProps: DeliveryDateSelectProps,
    prevState: State
  ) {
    if (this.props.bagId !== prevProps.bagId) {
      const options = await this.fetchDates();
      this.tryGetSameSlotOnBagChange(options);
    }
  }

  tryGetSameSlotOnBagChange = (options: Array<DeliveryDateOption>) => {
    const {
      selectedDeliveryDateId,
      selectedDeliveryWeekStarting,
      selectedDeliveryDate,
    } = this.state;

    const hasOptions = options.length;
    const nextSelectedOption = hasOptions
      ? first(options)
      : {
        value: selectedDeliveryDateId,
        weekStarting: selectedDeliveryWeekStarting,
        deliveryDate: selectedDeliveryDate,
      };

    let hasUpdatedDeliveryDate = this.state.hasUpdatedDeliveryDate;

    if (hasOptions) {
      if (options.some(d => d.value === selectedDeliveryDateId)) {
        // same slot found in selection, retaining selection
        nextSelectedOption.value = selectedDeliveryDateId;
        nextSelectedOption.weekStarting = selectedDeliveryWeekStarting;
        nextSelectedOption.deliveryDate = selectedDeliveryDate;
        hasUpdatedDeliveryDate = false;
      } else {
        // defaulting to the first so letting the parent know of the change
        this.props.onChange(
          nextSelectedOption.value as number,
          nextSelectedOption.weekStarting,
          nextSelectedOption.deliveryDate
        );
        hasUpdatedDeliveryDate = selectedDeliveryDateId != null ? true : false;
      }
    }

    this.setState({
      options,
      selectedDeliveryDateId: nextSelectedOption.value as number,
      selectedDeliveryWeekStarting: nextSelectedOption.weekStarting,
      selectedDeliveryDate: nextSelectedOption.deliveryDate,
      hasUpdatedDeliveryDate,
    });
  };

  handleChange = (option: DeliveryDateOption | Array<DeliveryDateOption>) => {
    const opt = option as DeliveryDateOption;
    this.setState({selectedDeliveryDateId: opt.value as number});
    this.props.onChange(
      opt.value as number,
      opt.weekStarting,
      opt.deliveryDate
    );
  };

  render() {
    const {errorMessage, bagId} = this.props;
    const {
      hasUpdatedDeliveryDate,
      options,
      selectedDeliveryDateId,
    } = this.state;

    const hasOptions = options.length > 0;

    return (
      <div className="form-group">
        <label htmlFor="deliveryDateId">{this.props.label? this.props.label: "First Delivery"}</label>
        {hasOptions && (
          <React.Fragment>
            <Select
              placeholder={this.props.placeholder || "Select your prefered delivery date and time..."}
              className="test-choose-delivery-date"
              clearable={false}
              searchable={false}
              required={true}
              style={this.props.style}
              options={options}
              value={selectedDeliveryDateId}
              name="deliveryDateId"
              onChange={this.handleChange}
            />
            {hasUpdatedDeliveryDate && (
              <div className="test-delivery-slot-warning">
                <p className="pt-2">
                  We've updated your delivery slot to the next available slot
                </p>
              </div>
            )}
            {this.props.errorMessage && (
              <div className="mt-2 mb-2">
                <span className="form-control-feedback text-danger">{errorMessage}</span>
              </div>
            )}
          </React.Fragment>
        )}
        {!hasOptions && (
          <div className="mt-2 mb-2">
            <span className="form-control-feedback test-no-slots">
              {bagId ? (
                <span className="test-no-slots-with-product">
                  We're sorry, no delivery dates are available for the selected
                  product
                </span>
              ) : (
                <span className="test-no-slots-no-product">
                  You must select a product before choosing a delivery date
                </span>
              )}
            </span>
          </div>
        )}
      </div>
    );
  }
}