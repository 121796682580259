import logger from 'app/shared/Logger';
import { Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { UiComponentAttributes } from 'redux/state';

export const UPDATE_UI_COMPONENT = 'ui/UPDATE_UI_COMPONENT';

export interface UpdateUiComponentAction extends Action {
  type: 'ui/UPDATE_UI_COMPONENT';
  payload: UpdateUiComponentPayload[];
}

export type UpdateUiComponentPayload = {
  key: string;
  attributes: UiComponentAttributes;
};

export enum UiComponentName {
  EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION',
  EDIT_BAG_SELECTOR = 'EDIT_BAG_SELECTOR',
  REACTIVATE_BAG_SELECTOR = 'REACTIVATE_BAG_SELECTOR',
}

type KeyCombination = {
  subscriptionNumber: string;
  deliveryDateId: number;
  productCollectionCode: string;
};

export type UiComponentKey = {
  name: UiComponentName;
} & Partial<KeyCombination>;

type input = {
  key: UiComponentKey;
  attributes: UiComponentAttributes;
};

export const performUpdateUiComponentAction: ActionCreator<
  ThunkAction<Promise<void>, unknown, null>
> =
  (key: UiComponentKey, attributes: UiComponentAttributes) =>
  async (dispatch) => {
    key = UiComponentKeyStabilizer(key);

    dispatch({
      type: UPDATE_UI_COMPONENT,
      payload: [{ key: JSON.stringify(key), attributes }],
    });
  };

export const performUpdateUiComponentActionBulk: ActionCreator<
  ThunkAction<Promise<void>, unknown, null>
> = (inputs: Array<input>) => async (dispatch) => {
  try {
    const payloads = inputs.map((c) => {
      const key = UiComponentKeyStabilizer(c.key);
      return { key: JSON.stringify(key), attributes: c.attributes };
    });

    dispatch({
      type: UPDATE_UI_COMPONENT,
      payload: payloads,
    });
  } catch (ex: any) {
    if (ex instanceof Error) {
      logger.error(ex, inputs, {});
    }
  }
};

/**
 *@function stabilizes the order for serializing/deserializing
 */
export const UiComponentKeyStabilizer = (key: UiComponentKey) => {
  const combination: KeyCombination = {
    subscriptionNumber: key.subscriptionNumber,
    deliveryDateId: key.deliveryDateId,
    productCollectionCode: key.productCollectionCode,
  };

  return { name: key.name, ...combination };
};
