import {ActionCreator} from 'react-redux';
import {AppState, OrdersPageState} from '../state';
import {ThunkAction} from 'redux-thunk';
import {format as dateFormat} from 'date-fns';
import {performDaySelectionAction} from '../actions/sharedActions/daySelectionAction';
import { formatToNzTimeZone } from 'lib/date/dateFormat';

/**
 *
 * @param subscriptionNumber
 * @param weekStarting This is a string in Week form (W2021-07-11)
 */
export const setSelectedDayThunk: ActionCreator<
  ThunkAction<boolean, AppState<OrdersPageState>, null>
> = (subscriptionNumber: string, weekStarting: string) => {

  if (weekStarting[0] !== 'W') {
    throw new Error(
      `Expected a date in WEEK form e.g. W2021-07-11 received ${weekStarting} instead`
    );
  }

  return (dispatch, getState) => {
    const orders = getState().pageState.orders;
    const currentDelivery = orders?.find(order => {
      const orderWeekStarting = formatToNzTimeZone(order.deliveryDate.weekStarting, 'W');
      return (
        orderWeekStarting == weekStarting &&
        order.subscriptionNumber == subscriptionNumber
      );
    });

    if (currentDelivery) {
      dispatch(performDaySelectionAction(currentDelivery.orderKey));
      return true;
    } else {
      return false;
    }
  };
};
