/* eslint-disable @typescript-eslint/ban-types */
import { fetchAccountSettingsState } from '../../../redux/actions/accountSettingsActions/accountSettingsAction';
import { AppState, OrdersPageState, UiComponentAttributes } from '../../../redux/state';
import { validateSuggestedAddress } from '../../../redux/actions/accountSettingsActions/addressValidationAction';
import {
  submitSubscriptionChange,
  cancelSubscription,
  restartSubscription,
  createSubscription,
} from '../../../redux/actions/accountSettingsActions/subscriptionAction';
import {
  removeCreditCard,
  setActiveCreditCard,
  addCreditCard,
} from '../../../redux/actions/accountSettingsActions/paymentsAction';
import {
  receivePageState,
  ReceivePageStateAction,
} from '../../../redux/actions/sharedActions/pageStateAction';
import { redeemVoucher } from '../../../redux/actions/accountSettingsActions/voucherAction';
import { applyPromoCode } from '../../../redux/actions/accountSettingsActions/promoAction';
import {
  AccountSettings,
  CancelSubscriptionRequest,
  CreateSubscriptionRequest,
  RedeemVoucherRequest,
  UpdateSubscriptionRequest,
  ValidateAddressRequest,
  ApplyPromoCodeRequest,
  SubscriptionDTO,
} from '../../shared/CoreClient';
import { restorePageState } from '../../../redux/actions/sharedActions/pageStateBackupAction';
import { MapDispatchToProps } from 'react-redux';
import { performDaySelectionAction } from '../../../redux/actions/sharedActions/daySelectionAction';
import { fetchFeaturesThunk } from '../../../redux/actions/featuresAction';
import { fetchCustomerProfileThunk } from '../../../redux/actions/customerProfileActions';
import { fetchSubscriptions } from '../../../redux/actions/sharedActions/subscriptionsAction';
import {
  ReceiveCustomerMessageAction,
  fetchCustomerMessage,
} from '../../../redux/actions/sharedActions/CustomerMessageAction';
import {
  UiComponentKey,
  performUpdateUiComponentAction,
} from 'redux/actions/sharedActions/updateUiComponent';
import {
  setIsValidPromo,
  setValidationMessages,
} from 'redux/actions/sharedActions/bagStateAction';
import { performJoinCampaign } from 'redux/actions/deliveriesActions/campaignAction';
import {
  ReceiveProductGroupMarketingAction,
  fetchProductGroupMarketing,
} from 'redux/actions/sharedActions/productGroupMarketingAction';
import {
  FetchTranslationsAction,
  Initi18nAction,
  fetchTranslationsThunk,
  initI18nThunk,
} from 'redux/actions/translationActions';
import { ReceiveCampaignStateAction, fetchCampaignState } from 'redux/actions/sharedActions/campaignProgressAction';
import { fetchTracking, ReceiveTrackingAction } from '../../../redux/actions/sharedActions/trackingAction';
import { fetchOrderPageThunk } from '../../../redux/thunks/fetchOrderPageThunk';

export const mapStateToProps = (state: AppState): AppState => state;

// Unfortunately there's no automated way to unwrap the eventual return value of thunks due to
// https://github.com/Microsoft/TypeScript/issues/6606
// so these have to be manually maintained.
export interface DispatcherProps {
  fetchAccountSettingsState(): Promise<void>;
  setAccountSettingsState(accountSettings: AccountSettings): void;
  restorePageState(): void;
  validateSuggestedAddress(
    selectedAddress: ValidateAddressRequest
  ): Promise<void>;
  submitSubscriptionChange(
    subscription: UpdateSubscriptionRequest
  ): Promise<AccountSettings | null>;
  addCreditCard(): Promise<void>;
  removeCreditCard(creditCardId: number): Promise<AccountSettings | null>;
  setActiveCreditCard(creditCardId: number): Promise<AccountSettings | null>;
  cancelSubscription(
    payload: CancelSubscriptionRequest
  ): Promise<ReceivePageStateAction | null>;
  restartSubscription(id: number): Promise<AccountSettings | null>;
  createSubscription(
    request: CreateSubscriptionRequest
  ): Promise<AccountSettings | null>;
  redeemVoucher(request: RedeemVoucherRequest): Promise<AccountSettings | null>;
  applyPromoCode(
    request: ApplyPromoCodeRequest
  ): Promise<AccountSettings | null>;
  performDaySelectionAction(deliveryDay: string): void;
  fetchFeaturesThunk(): Promise<void>;
  fetchCustomerProfileThunk(): Promise<void>;
  fetchSubscriptions(): Promise<SubscriptionDTO[]>;
  fetchTranslationsThunk(
    namespace?: string,
    productCollectionCode?: string
  ): Promise<FetchTranslationsAction>;
  initI18nThunk(): Promise<Initi18nAction>;
  fetchCustomerMessage(
    triggerCode: string,
    subscriptionNumber: string,
    deliveryDateId: string
  ): Promise<ReceiveCustomerMessageAction>;
  fetchTracking(
  ): Promise<ReceiveTrackingAction>;
  performUpdateUiComponentAction(
    key: UiComponentKey,
    attributes: UiComponentAttributes
  ): void;
  fetchProductGroupMarketing(): Promise<ReceiveProductGroupMarketingAction>;
  setValidationMessages(validationMessages: Array<string>): void;
  setIsValidPromo(isValidPromo: boolean): void;
  performJoinCampaign(deliveryDay: string): void;
  fetchCampaignState(): Promise<ReceiveCampaignStateAction>;
  fetchOrderPageThunk():Promise<OrdersPageState>;
}

export const mapDispatchToProps: MapDispatchToProps<DispatcherProps, {}> = {
  fetchAccountSettingsState,
  setAccountSettingsState: receivePageState,
  restorePageState,
  validateSuggestedAddress,
  submitSubscriptionChange,
  addCreditCard,
  removeCreditCard,
  setActiveCreditCard,
  cancelSubscription,
  restartSubscription,
  createSubscription,
  redeemVoucher,
  applyPromoCode,
  performDaySelectionAction,
  fetchFeaturesThunk,
  fetchCustomerProfileThunk,
  fetchSubscriptions,
  fetchTranslationsThunk,
  initI18nThunk,
  fetchCustomerMessage,
  fetchTracking,
  performUpdateUiComponentAction,
  fetchProductGroupMarketing,
  setValidationMessages,
  setIsValidPromo,
  performJoinCampaign,
  fetchCampaignState,
  fetchOrderPageThunk
};

export interface PageProperties extends DispatcherProps, AppState {}
