import { Reducer } from 'redux';
import {
  CustomerMessageBulkStateAction,
  RECEIVE_CUSTOMER_MESSAGE_BULK,
  ReceiveCustomerMessageBulkAction,
} from 'redux/actions/sharedActions/CustomerMessageBulkAction';
import { CustomerMessageBulkGroupState } from 'redux/state';

const reducer: Reducer<Array<CustomerMessageBulkGroupState>> = (
  state = [],
  action: CustomerMessageBulkStateAction
): Array<CustomerMessageBulkGroupState> => {
  switch (action.type) {
    case RECEIVE_CUSTOMER_MESSAGE_BULK: {
      const cmpBulk = (action as ReceiveCustomerMessageBulkAction).payload;

      cmpBulk.forEach((cmpResponse) => {
        let cmpGroupInState = state.find(
          (c) => c?.triggerCode === cmpResponse?.triggerCode
        )?.group;

        if (cmpGroupInState) {
          // Remove dirty cmp in state
           const indexToRemove= cmpGroupInState.findIndex(
            (c) =>
              c.subscriptionNumber === cmpResponse.subscriptionNumber &&
              c.deliveryDateId === cmpResponse.deliveryDateId &&
              c.triggerCode === cmpResponse.triggerCode
          );

          if (indexToRemove !== -1) {
            cmpGroupInState.splice(indexToRemove, 1);
          }
          // Insert new
          cmpGroupInState.push(cmpResponse);
        } else {
          state.push({
            triggerCode: cmpResponse.triggerCode,
            group: [cmpResponse],
          });
        }
      });

      return state;
    }
    default:
      return state;
  }
};

export default reducer;
