import { ButtonStyles, ButtonV2Props } from '@mfb/lego';
import { SkipDeliveryContext } from './EditDelivery';
import { triggerHotjar } from 'app/shared/triggerHotjar';
import { lowerCase } from 'lodash';

export const skipDeliveryButtonBuilder = (
  subscriptionId: number,
  skipContext: SkipDeliveryContext
): ButtonV2Props => {
  let skipButtonLabel = '';
  let skipType = '';

  switch (skipContext.isSkipped) {
    case true:
      skipButtonLabel = 'Unskip delivery';
      skipType = 'unskip';
      break;

    case false:
      skipButtonLabel = 'Skip delivery';
      skipType = 'skip';
      break;
  }

  const handleClickAsync = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!skipContext.isLoading) {
      await skipContext.onClickAsync(e);
      if (!skipContext.isSkipped) {
        triggerHotjar('skip-delivery-survery');
      }
    }
  };

  return {
    label: skipButtonLabel,
    onClick: handleClickAsync,
    isLoading: skipContext.isLoading,
    buttonStyle: ButtonStyles.Secondary,
    disabled: skipContext.isDisabled,
    //@ts-ignore
    'data-category': 'delivery',
    'data-action': skipType,
    'data-label': `${lowerCase(skipContext.bagDescription)} - ${
      skipContext.date
    }`,
    'data-test': `${skipType}-button`,
    'data-subscription-id': subscriptionId,
    eventTracking: {
      eventId: skipType,
      data: {},
    },
  };
};
