import { OrderWithRecipesAndExtras } from '../../CoreClient';
import { differenceInDays, format } from 'date-fns';
import {
  EditDeliveryDetailsFormTrackingData,
} from '@mfb/lego';
import { TrackingState } from '../../../../redux/state';
import { selectSubscriptionTracking } from '../../../../redux/selectors/subscriptionTrackingSelector';
import { asIsoNzstString, asUtcString, getDifferenceInDays } from '../../helper';

export const initTrackingData = async (
  order: OrderWithRecipesAndExtras,
  tracking?: TrackingState,
  functionalArea?: 'Deliveries' | 'Choose Meals',
): Promise<Partial<EditDeliveryDetailsFormTrackingData>> => {
  const subscriptionTrackingMetaData = await selectSubscriptionTracking(tracking)(order.subscriptionNumber);

  return {
    ...subscriptionTrackingMetaData,
    delivery_week: asUtcString(order.deliveryDate.weekStarting),
    actual_or_virtual_delivery: !!order.deliveryId ? 'Actual' : 'Virtual',
    days_until_customer_delivery: getDifferenceInDays(order.deliveryDate.actualDeliveryDate),
    days_until_delivery_lock: getDifferenceInDays(order.orderState?.canSkip?.effectiveUntilActual ?? order.orderState?.canUnskip?.effectiveUntilActual),
    functional_area: functionalArea ?? 'Deliveries',
  };
};


