import * as React from 'react';
import {Colours} from '@mfb/lego';

interface SpinnerProps {
  className?: string;
  width?: number;
  height?: number;
}
const Spinner = ({className, width, height}: SpinnerProps) => (
  <div className="mt-5 d-flex flex-column flex-fill">
    <div
      className={`spinner-border align-self-center ${className}`}
      style={{
        color: Colours.PRIMARY_GREEN_BRAND_1,
        width: width || '72px',
        height: height || '72px',
      }}
    />
  </div>
);

export default Spinner;
