import {defaultBagState, BagState} from '../state';
import {Action, Reducer} from 'redux';

interface PromoValidationMessagesAction extends Action {
  type: 'PROMO_VALIDATION_MESSAGES';
  validationMessages?: string[];
}

interface IsValidPromoAction extends Action {
  type: 'IS_VALID_PROMO';
  isValidPromo?: boolean;
}

export type BagStateAction = PromoValidationMessagesAction | IsValidPromoAction;

const reducer: Reducer<BagState> = (
  state = defaultBagState,
  action: BagStateAction
): BagState => {
  switch (action.type) {
    case 'PROMO_VALIDATION_MESSAGES':
      return {...state, validationErrors: action.validationMessages};
    case 'IS_VALID_PROMO':
      return {...state, isValidPromo: action.isValidPromo};
    default:
      return state;
  }
};

export default reducer;
