/*
* Response mappers to be used to make Scullery response objects compatible with the existing codebase. Once we are fully migrated to Scullery, everything here will be obsolete.
*/
import {
    DeliveryLineResponse,
  Frequency,
  Frequency2,
  OrderAction,
  OrderActionsResponse,
  OrderWithRecipesAndExtras,
  RecipeSelectionMode2,
} from './CoreClient';
import logger from './Logger';
import {
  IOrderWithRecipesAndExtras,
  IDeliveryLineResponse,
} from '@mfb/account-api-client';

/**
 * @function normalize frequency between core and scullery 
 */
export const normalizeFrequency = (frequency: Frequency): Frequency2 => {
  try {
    const frequencyToFrequency2Map: { [key in Frequency]: Frequency2 } = {
      [Frequency.Weekly]: Frequency2.Weekly,
      [Frequency.Fortnightly]: Frequency2.Fortnightly,
      [Frequency.OneOff]: Frequency2.OneOff,
      [Frequency.FourWeekly]: Frequency2.FourWeekly,
    };
    if (frequencyToFrequency2Map.hasOwnProperty(frequency)) {
      return frequencyToFrequency2Map[frequency];
    }
  } catch (ex: any) {
    if (ex instanceof Error) {
      logger.error(ex, {});
    }
  }
};


/**
 * @function normalize order actions between core and scullery 
 */
export const normalizeOrderActions = (
  orderState: OrderActionsResponse
): OrderAction[] => {
  const orderActions: OrderAction[] = [];

  if (orderState) {
    if (orderState.canChangeBag?.isValid) {
      orderActions.push(OrderAction.CanChangeBag);
    }
    if (orderState.canChangeDeliveryShipping?.isValid) {
      orderActions.push(OrderAction.ChangeDeliverySlot);
      orderActions.push(OrderAction.ChangeDeliveryAddress);
    }
    if (orderState.canChangeMeals?.isValid) {
      orderActions.push(OrderAction.SelectMeals);
    }
    if (orderState.canSkip?.isValid) {
      orderActions.push(OrderAction.Skip);
    }
    if (orderState.canUnskip?.isValid) {
      orderActions.push(OrderAction.Unskip);
    }
  }

  return orderActions;
};

export const mapSculleryOrderWithRecipesAndExtrasToCore = (
  order: IOrderWithRecipesAndExtras
): OrderWithRecipesAndExtrasExtended => {
  const mapDeliveryLines = (dls: IDeliveryLineResponse[]): DeliveryLineResponse[] => {
    return  dls.map((c)=>{
        const bar: DeliveryLineResponse = {
            ...c,
            recipeSelectionMode: c.recipeSelectionMode as unknown as RecipeSelectionMode2,
            extraFrequency: c.extraFrequency as unknown as Frequency2,
        }
        return bar;
    })
  };

  return  {
    ...order,
    deliveryLines: mapDeliveryLines(order.deliveryLines),
    selectedExtras: mapDeliveryLines(order.selectedExtras),
    /**
     * @deprecated Use the `orderState` property instead.
     */
    orderActions: normalizeOrderActions(order.orderActions),
    orderState: order.orderActions
  };
};

export type OrderWithRecipesAndExtrasExtended = OrderWithRecipesAndExtras & {
  isCreating?: boolean;
};
