import { useEffect } from 'react';
import { useRef, useState } from 'react';
import {
  AccountCancelSubscriptionRequestBody,
  AccountSubscription,
  BagDisplay,
  GetCustomerMessageResponse,
  SubscriptionClient,
} from '../../../../shared/CoreClient';
import { AppState, CustomerMessageState } from '../../../../../redux/state';
import { connect } from 'react-redux';
import {
  mapDispatchToProps,
  PageProperties,
} from '../../../containers/accountSettingsContainer';
import { RouteComponentProps } from 'react-router';
import { globalSettings as settings } from 'config';
import { logger } from '../../../../shared/Logger';
import { navman } from '../../../../../navigator';
import { RouteParams } from '../View';
import { CustomerMessageProxyDeepLink, cmpNavigateToUrl } from 'app/shared/CustomerMessageService';
import { TriggerCode } from 'app/shared/constants';
import { ReceiveCustomerMessageAction } from 'redux/actions/sharedActions/CustomerMessageAction';
import Spinner from 'app/shared/components/Spinner';
import { CancelSubscriptionEvent } from 'app/shared/cancelSubscriptionTracker';
import {
  CancelFeedback,
  CustomerMessageProvider,
  CustomerMessageV2,
  useAnalyticsTracking,
} from '@mfb/lego';
import { useAlertToastContext } from 'app/shared/hooks';
import { AlertStatus } from '@mfb/lego';

interface ICancelSubscriptionContainer
  extends AppState,
    RouteComponentProps<RouteParams>,
    PageProperties {
  subscription: AccountSubscription;
  cancelSubscriptionContext?: {
    customerMessages?: GetCustomerMessageResponse;
    upcomingWeek?: Date;
  };
  bagSelection?: BagDisplay[];
}

export const CancelSubscriptionContainerUnconnected = ({
                                                         subscription,
                                                         fetchAccountSettingsState,
                                                         fetchCustomerMessage,
                                                         bagSelection,
                                                       }: ICancelSubscriptionContainer) => {
  const [showCancelFeedback, setShowCancelFeedback] = useState<boolean>(false);
  const [cancelCmp, setCancelCmp] = useState<CustomerMessageState>(undefined);
  const { trackDataLayerEvent } = useAnalyticsTracking();

  const { updateToast } = useAlertToastContext();

  const client = new SubscriptionClient(settings.bffHost);
  const cancelTriggerId = useRef<number>(undefined);

  const cancelSubscription = async (cancelFeedback: string) => {
    try {
      const subs = await client.getSubscriptions();
      const numberOfNights = subs.find((sub) => {
        return sub.subscriptionNumber === subscription.subscriptionNumber;
      }).primarySkuNumberOfNights;
      const numberOfPeopleToFeed = bagSelection.find(
        (b) => b.id === subscription.primaryBagId,
      )?.numberOfPeopleToFeed;

      const payload: AccountCancelSubscriptionRequestBody = {
        customerMessageTriggerId: cancelTriggerId.current,
        cancellationComments: cancelFeedback,
        cancellationReason: cancelFeedback,
      };

      trackDataLayerEvent(CancelSubscriptionEvent.CANCEL_COMPLETED, {
        subscription_number: subscription.subscriptionNumber,
        bag_sku: subscription.productOptionSku,
        bag_name: subscription.primaryBagName,
        subscription_people: numberOfPeopleToFeed?.toString(),
        subscription_nights: numberOfNights.toString(),
        subscription_time: subscription.deliverySlotDescription,
        cancellation_reason: payload.cancellationReason,
      });

      const res = await client.cancelSubscriptionAsyncV2(
        subscription.subscriptionNumber,
        payload,
      );

      if (res) {
        updateToast({
          status: AlertStatus.success,
          isOpen: true,
          text: `You have successfully cancelled ${subscription.primaryBagName}`,
        });

        if (navman.routeToApp()) {
        } else {
          navman.subscriptions();
          await fetchAccountSettingsState();
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        logger.error(e, {});
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const deliveryDates = await client.deliveryDates(
          subscription.primaryBagId,
        );
        const action = await fetchCustomerMessage(
          TriggerCode.CANCEL,
          subscription.subscriptionNumber,
          deliveryDates[0].id.toString(),
        );
        const cmp = (action as ReceiveCustomerMessageAction).payload;
        cmp.deliveryWeek = `W${deliveryDates[0].weekStarting}`;

        setCancelCmp(cmp);
      } catch (e) {
        if (e instanceof Error) {
          logger.error(e, {});
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancelCustomerMessageNavigate = async (
    url: string,
    triggerId: number,
  ) => {
    if (
      url === CustomerMessageProxyDeepLink.CANCEL ||
      url === CustomerMessageProxyDeepLink.CONTINUE_TO_CANCEL
    ) {
      cancelTriggerId.current = triggerId;
      return setShowCancelFeedback(true);
    }
    cmpNavigateToUrl(url);
  };

  if (showCancelFeedback) {
    return <CancelFeedback onClickAsync={cancelSubscription} />;
  }

  if (cancelCmp) {
    const { customerMessages, triggers } = cancelCmp;

    return (
      <div className="mt-3 mt-md-0">
        <CustomerMessageProvider
          context={{
            onNavigate: onCancelCustomerMessageNavigate,
            closeUrl: CustomerMessageProxyDeepLink.CLOSE_MODAL,
            triggerContext: {
              header: triggers.title,
              defaultActions: {
                primary: { label: 'Continue', disabled: true },
                secondary: { label: 'Cancel' },
              },
              triggers: triggers.customerMessageTriggers,
            },
            messages: customerMessages,
            onClose: () => {
              if (navman.routeToApp()) {
              } else {
                navman.accountSettings();
              }
            },
            gATrackingData: {
              customer_message_trigger: true,
              message_journey: TriggerCode.CANCEL,
              week: cancelCmp.deliveryWeek.split('T')[0],
              bag_sku: subscription.productOptionSku,
            },
          }}
        >
          <CustomerMessageV2
            body={
              <>
                <CustomerMessageV2.Triggers />
                <CustomerMessageV2.Message />
              </>
            }
          />
        </CustomerMessageProvider>
      </div>
    );
  }

  return <Spinner />;
};

const mapStateToProps = (
  state: AppState,
  ownProps: ICancelSubscriptionContainer,
) => {
  const subscriptionNumber = ownProps.match.params.subscriptionNumber;
  const subscriptionId = Number(ownProps.match.params.subscriptionId);

  const subscription = state.pageState['subscriptions'].find(
    (s) =>
      s.subscriptionNumber === subscriptionNumber || s.id === subscriptionId,
  );

  const bagSelection = state.pageState.bagSelection;
  return {
    ...state,
    ...ownProps,
    subscription: subscription,
    bagSelection: bagSelection,
  };
};

export const CancelSubscriptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancelSubscriptionContainerUnconnected);
