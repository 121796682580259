/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {forEach} from 'lodash';

interface Props {
  xs?: JSX.Element;
  sm?: JSX.Element;
  md?: JSX.Element;
  lg?: JSX.Element;
  xl?: JSX.Element;
}

/**
 * Chooses which of the supplied components to render based on the same media queries as our css.
 * @example
 * // Renders "Foo" at `xs` widths, nothing at `sm` widths, and "Bar" at `md`, `lg`, and `xl` widths
 * <ResponsiveRender xs="Foo" sm={null} md="Bar" />
 */
export default class ResponsiveRender extends React.PureComponent<Props, {}> {
  matchers: Array<MediaQueryList> = [
    window.matchMedia('(min-width: 1200px)'), //  xl
    window.matchMedia('(min-width: 992px)'), // lg
    window.matchMedia('(min-width: 768px)'), // md
    window.matchMedia('(min-width: 576px)'), // sm
  ];
  boundForceUpdate = () => this.forceUpdate();

  componentDidMount() {
    // https://github.com/microsoft/TypeScript/issues/32210

    forEach(this.matchers, matcher =>
      matcher.addListener(this.boundForceUpdate)
    );
  }
  componentWillUnmount() {
    // https://github.com/microsoft/TypeScript/issues/32210

    forEach(this.matchers, matcher =>
      matcher.removeListener(this.boundForceUpdate)
    );
  }

  render() {
    const {xs, sm, md, lg, xl} = this.props;

    let matchIdx = this.matchers.findIndex(matcher => matcher.matches);
    if (matchIdx === -1) {
      matchIdx = 4;
    }

    const component = [xl, lg, md, sm, xs]
      .slice(matchIdx)
      .find(node => node !== undefined);

    return component || null;
  }
}
