import React from 'react';
import {memoize} from 'lodash';
import {Day, Week} from '../app/shared/CoreClient';
import format from './date';

const THREE = 3;

const responsiveDayOfWeek = memoize(
  (dayOfWeek: string): JSX.Element => (
    <span>
      {dayOfWeek.substring(0, THREE)}
      <span className="hidden-sm-down">{dayOfWeek.substring(THREE)}</span>{' '}
    </span>
  )
);

const responsiveDayOfMonth = memoize(
  (dayOfMonth: string): JSX.Element => {
    const split = dayOfMonth.split(/((st)|(nd)|(rd)|(th))$/);

    return (
      <span>
        {split[0]}
        <span className="hidden-sm-down">{split[1]}</span>{' '}
      </span>
    );
  }
);

const responsiveMonth = memoize(
  (month: string): JSX.Element => (
    <span>
      {month.substring(0, THREE)}
      <span className="hidden-xs-down">{month.substring(THREE)}</span>
    </span>
  )
);

interface Props {
  date: Day | Week | string;
}

const responsiveDate: React.FunctionComponent<Props> = ({date}) => {
  const formattedValue = format(date);
  const split = formattedValue.split(' ');
  const dayOfWeek = split[0];
  const dayOfMonth = split[1];

  const month = split[2];

  return (
    <span>
      {responsiveDayOfWeek(dayOfWeek)}
      {responsiveDayOfMonth(dayOfMonth)}
      {responsiveMonth(month)}
    </span>
  );
};

export default responsiveDate;
