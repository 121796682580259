/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {Dispatch, Store} from 'redux';
import {connect, ComponentClass, MapDispatchToProps} from 'react-redux';
import {AppState} from '../../redux/state';
import {fetchPageState, rateRecipe} from './rateRecipeAction';
import {RateRecipeRequest, RateRecipeResponse} from '../shared/CoreClient';
import {RateRecipes} from './RateRecipes';
import MessageList from '../shared/components/MessageList';
import Spinner from '../shared/components/Spinner';
import {ReceivePageStateAction} from '../../redux/actions/sharedActions/pageStateAction';

export type FetchPageStateFunc = (numbersBack?: number) => void;
export type RateFunc = (
  payload: RateRecipeRequest
) => Promise<ReceivePageStateAction>;

export interface DispatchProps {
  fetchPageState: FetchPageStateFunc;
  rate: RateFunc;
}

const mapStateToProps = (state: AppState): AppState => state;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: Dispatch<Store<AppState>>
): DispatchProps => ({
  fetchPageState: async numberOfPeriodsBack =>
    dispatch(fetchPageState(numberOfPeriodsBack)),
  rate: async payload => dispatch(rateRecipe(payload)),
});

interface OwnProps {
  deliveryWeeksBack: number;
}

type Props = AppState & DispatchProps & OwnProps;

class RateRecipesShell extends React.PureComponent<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const weeksBack = (this.props && this.props.deliveryWeeksBack) || 0;

    await this.props.fetchPageState(weeksBack);
  }

  back = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.fetchPageState(this.getWeeksBack() + 1);
  };

  forward = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.fetchPageState(Math.max(this.getWeeksBack() - 1, 0));
  };

  render() {
    const rateRecipeResponse = this.props.pageState as RateRecipeResponse;
    const messages = rateRecipeResponse.messages;

    return !this.props.ui.isLoading ? (
      <div className="fs-u-pb-80 pt-4 container mfb-u-overflow-hide">
        {messages && <MessageList messages={messages} />}
        {rateRecipeResponse && (
          <RateRecipes
            deliveryWeeksBack={this.getWeeksBack()}
            count={rateRecipeResponse.count}
            days={rateRecipeResponse.deliveryRatingDays}
            performRating={this.props.rate}
            forward={this.forward}
            back={this.back}
          />
        )}
      </div>
    ) : (
      <Spinner />
    );
  }

  private readonly getWeeksBack = () => {
    const rateRecipeResponse = this.props.pageState as RateRecipeResponse;

    return (rateRecipeResponse && rateRecipeResponse.deliveryWeeksBack) || 0;
  };
}

export default connect<AppState, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(RateRecipesShell) as ComponentClass<any>;
