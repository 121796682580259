import Oidc from 'oidc-client';
import { globalSettings as settings } from 'config';
import {navman} from '../../navigator';
import routeHistory from '../../routerHistory';
import {
  passwordResetRedirectPath,
  is3ConversionRedirectPath,
  signinNoPromptRedirectPath,
  silentRefreshTokenRedirectPath,
  signinRedirectPath,
  signinIS3RedirectPath,
  logoutRedirectPath,
  logoutIS3RedirectPath,
  logoutCustomerRedirectPath,
  customerRedirectPath,
} from '../account-settings/components/Nav/paths';
import {each} from 'lodash';
import {
  ParamDictionary,
  extractParametersFromCallbackUrl,
} from './IdentityHelper';
import {rootPath} from '../account-settings/components/Nav/paths';
import {UserManager, MFBUser} from './UserManager';
import {logger} from './Logger';
import mixpanel from 'mixpanel-browser';

/*
 * Responsible for authentication actions on the current user
 */
class OidcUserManager implements UserManager {
  private readonly userManager: Oidc.UserManager;
  private readonly userManagerSigninIS3: Oidc.UserManager;
  private readonly userManagerPasswordReset: Oidc.UserManager;
  private readonly userManagerIS3Conversion: Oidc.UserManager;
  private readonly userManagerCustomerRedirect: Oidc.UserManager;
  private readonly redirectUri: string = `${navman.resolveOrigin()}${signinRedirectPath}`;
  private readonly signinIS3RedirectUri: string = `${navman.resolveOrigin()}${signinIS3RedirectPath}`;
  private readonly is3ConversionRedirectUri: string = `${navman.resolveOrigin()}${is3ConversionRedirectPath}`;
  private readonly passwordResetRedirectUri: string = `${navman.resolveOrigin()}${passwordResetRedirectPath}`;
  private readonly silentRefreshRedirectUri: string = `${navman.resolveOrigin()}${silentRefreshTokenRedirectPath}`;
  private readonly signinNoPromptRedirectUri: string = `${navman.resolveOrigin()}${signinNoPromptRedirectPath}`;
  private readonly logoutRedirectUri: string = `${navman.resolveOrigin()}${logoutRedirectPath}`;
  private readonly logoutIS3RedirectUri: string = `${navman.resolveOrigin()}${logoutIS3RedirectPath}`;
  private readonly logoutCustomerRedirectUri: string = `${navman.resolveOrigin()}${logoutCustomerRedirectPath}`;
  private readonly logoutEvent: string = 'logout-event';

  constructor() {
    const identityServer3Config: Oidc.UserManagerSettings = settings.idenityServerEnabled && {
      authority: settings.identityAuthority,
      client_id: 'MFB-Account',
      redirect_uri: this.redirectUri,
      response_type: 'id_token token',
      scope: 'openid profile MFB-API roles',
      //post logout url is send as redirect uri after the logout is executed
      //when adb2c ON, if we need to logout from IS3 as well we will redirect them to the LegacyLogoutRedirect
      post_logout_redirect_uri: this.logoutIS3RedirectUri,
      monitorSession: false,
      clockSkew: Infinity,
    };

    localStorage.removeItem(this.logoutEvent);
    window.addEventListener('storage', this.handleTabLogout.bind(this));

    const adb2cBaseConfig: Oidc.UserManagerSettings = {
      client_id: settings.adb2cAccountAppAppId,
      response_type: 'id_token token',
      scope: settings.adb2cAccountAppScopes,
      post_logout_redirect_uri: this.logoutRedirectUri,
      loadUserInfo: false,
      monitorSession: false,
      clockSkew: Infinity,
    };

    // customer redirect from order form
    this.userManagerCustomerRedirect = new Oidc.UserManager({
      ...adb2cBaseConfig,
      post_logout_redirect_uri: this.logoutCustomerRedirectUri,
      authority:
        `https://${settings.adb2cTenantName}.b2clogin.com/` +
        `${settings.adb2cTenantId}/${settings.adb2cSigninPolicy}/v2.0`,
      redirect_uri: this.redirectUri,
      automaticSilentRenew: true,
      silent_redirect_uri: this.silentRefreshRedirectUri,
    });

    // normal signin
    this.userManager = new Oidc.UserManager({
      ...adb2cBaseConfig,
      authority:
        `https://${settings.adb2cTenantName}.b2clogin.com/` +
        `${settings.adb2cTenantId}/${settings.adb2cSigninPolicy}/v2.0`,
      redirect_uri: this.redirectUri,
      automaticSilentRenew: true,
      silent_redirect_uri: this.silentRefreshRedirectUri,
    });
    // no prompt IS3 signin
    this.userManagerSigninIS3 = settings.idenityServerEnabled && new Oidc.UserManager({
      ...identityServer3Config,
      redirect_uri: this.signinIS3RedirectUri,
      prompt: 'none',
    });
    // password reset
    this.userManagerPasswordReset = new Oidc.UserManager({
      ...adb2cBaseConfig,
      authority:
        `https://${settings.adb2cTenantName}.b2clogin.com/` +
        `${settings.adb2cTenantId}/${settings.adb2cPasswordResetPolicy}/v2.0`,
      redirect_uri: this.passwordResetRedirectUri,
    });
    // convert IS3 token for ADB2C token
    this.userManagerIS3Conversion = settings.idenityServerEnabled && new Oidc.UserManager({
      ...adb2cBaseConfig,
      authority:
        `https://${settings.adb2cTenantName}.b2clogin.com/` +
        `${settings.adb2cTenantId}/${settings.adb2cSigninIS3MobilePolicy}/v2.0`,
      redirect_uri: this.is3ConversionRedirectUri,
    });
  }

  public async handleTabLogout(event) {
    if (event.key == this.logoutEvent && !event.oldValue) {
      mixpanel.reset();
      window.removeEventListener('storage', this.handleTabLogout);
      return this.userManager.signoutRedirect();
    }
  }

  /*
   * Pulls the customer number from the user
   */
  public async getCustomerNumber(): Promise<string> {
    const user = await this.userManager.getUser();
    return user != null ? user.profile.CustomerNumber : '';
  }

  /*
   * Pulls the bearer token from the user
   * if the login hash is still present, it is wiped.
   */
  public async getBearerToken(): Promise<string> {
    const user = await this.userManager.getUser();

    return user != null ? user.access_token : '';
  }

  /*
   * Authenticate is called during page load to login user or get the authenticated user
   */
  public async authenticate(): Promise<MFBUser> {
    if (window.location.href.indexOf(this.logoutIS3RedirectUri) > -1 && settings.idenityServerEnabled) {
      await this.handleLegacyLogoutRedirect();
      return null;
    }

    if (window.location.href.indexOf(this.logoutCustomerRedirectUri) > -1) {
      await this.handleLogoutRootPathRedirect();
      return null;
    }

    if (window.location.href.indexOf(this.logoutRedirectUri) > -1) {
      await this.handleLogoutRedirect();
      return null;
    }

    if (window.location.href.indexOf(this.silentRefreshRedirectUri) > -1) {
      await this.userManager.signinSilentCallback();
      return null;
    }

    if (window.location.href.indexOf(this.signinIS3RedirectUri) > -1 && settings.idenityServerEnabled) {
      await this.handleSigninIS3Redirect();
      return null;
    }

    if (window.location.href.indexOf(this.is3ConversionRedirectUri) > -1 && settings.idenityServerEnabled) {
      await this.handleIS3ConversionRedirect();
      return null;
    }

    if (window.location.href.indexOf(this.passwordResetRedirectUri) > -1) {
      await this.handlePasswordResetRedirect();
      return null;
    }

    let user: Oidc.User | null = null;

    if (window.location.href.indexOf(this.signinNoPromptRedirectUri) > -1 && settings.idenityServerEnabled) {
      user = await this.handleSigninNoPromptRedirect();
      if (!user) {
        return null;
      }
    }

    if (window.location.href.indexOf(this.redirectUri) > -1) {
      const result = await this.handleSigninRedirect();

      if (result === 'password-reset') {
        return null;
      }

      user = result;
    }

    if (user == null) {
      user = await this.userManager.getUser();
    }

    // if the user is still null or has expired we need them to login.
    if (user == null || user.expired) {
      await this.login();
      return null;
    }

    // have user
    this.setupRaven(user);

    return {
      customerNumber: user.profile.CustomerNumber,
    };
  }

  /*
   * Logs the user out and sends them to the post logout url specified
   * in the config
   */
  public async logout(): Promise<void> {
    //Logout click acton performed by the user
    //verify if user also has a valid IdentityServer3 authentication
    //this is a result of the authentication through token migration flow
    if(settings.idenityServerEnabled){
      const user = await this.userManagerSigninIS3.getUser();
      if (user && user.id_token) {
        //Perform logout in Identity Server 3 and at the end redirect user
        //to the redirect logout url setup in the identityServer3Config
        return this.userManagerSigninIS3.signoutRedirect();
    }
  }
    return this.ExecuteStandardLogout();
  }

  private ExecuteStandardLogout() {
    window.removeEventListener('storage', this.handleTabLogout);
    localStorage.setItem(this.logoutEvent, 'logout' + Math.random());
    return this.userManager.signoutRedirect();
  }

  public async handleCustomerRedirectLogout(): Promise<void> {
    //Logout redirect result from customerRedirectPath
    //verify if user also has a valid IdentityServer3 authentication
    //this is a result of the authentication through token migration flow
    if(settings.idenityServerEnabled){
      const user = await this.userManagerSigninIS3.getUser();
      if (user && user.id_token) {
        //Perform logout in Identity Server 3 and at the end redirect user
        //to the redirect logout url setup in the identityServer3Config
        return this.userManagerSigninIS3.signoutRedirect();
      }
    }
    return this.executeStandardCustomerRedirectLogout();
  }

  private executeStandardCustomerRedirectLogout() {
    // Setting a new unique keyValue to logout-event key in local storage using Math.random().
    // The logout event will be triggered when this keyValue is modified.
    localStorage.setItem(this.logoutEvent, 'logout' + Math.random());
    return this.userManagerCustomerRedirect.signoutRedirect();
  }

  /*
   * Do password reset
   */
  public async PasswordReset(email: string): Promise<void> {
    const args: any = {
      state: {
        path: window.location.pathname,
        search: window.location.search,
      },
      login_hint: email,
    };

    return this.userManagerPasswordReset.signinRedirect(args);
  }

  /*
   * login
   */
  private async login(): Promise<void> {
    const args: any = {
      state: {
        path: window.location.pathname,
        search: window.location.search,
      },
    };
    if(settings.idenityServerEnabled){
      args.prompt = 'none';
      args.redirect_uri = this.signinNoPromptRedirectUri;
    }
    await this.userManager.signinRedirect(args);
  }

  /*
   * Register the user ID with page monitors
   */
  private setupRaven(user: Oidc.User): void {
    if (user.profile != null) {
      const Raven = (window as any).Raven;
      const customerNumber = user.profile.CustomerNumber;

      mixpanel.identify(customerNumber ?? 'unknown');

      const email =
        user.profile[
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
        ];

      if (Raven != null && Raven.setUserContext != null) {
        Raven.setUserContext({id: customerNumber, email});
      }
    }
  }

  /*
   * Handle redirect from signin
   */
  private async handleSigninRedirect(): Promise<Oidc.User | 'password-reset'> {
    if (await this.handleAdb2cErrors()) {
      return 'password-reset';
    }

    // successfully signed in
    return await this.handleSuccessfulLogin();
  }

  /*
   * Handle redirect from signin with no prompt
   */
  private async handleSigninNoPromptRedirect(): Promise<Oidc.User> {
    const params: ParamDictionary = extractParametersFromCallbackUrl();

    if (params.error) {
      // have error then try IS3
      const state = JSON.parse(
        await this.userManager.settings.stateStore.get(params.state)
      );

      let statePath;
      let stateSearch;

      // If the user is not authenticated, we don't want to save the customerRedirectPath to the user's path history.
      // Otherwise, this will cause the user to be redirected to the customerRedirectPath after the user has been authenticated,
      // and potentially log them out if the customerRedirectPath has a customerNumber query param that is different from the authenticated user.
      if (state.data.path.indexOf(customerRedirectPath) > -1) {
        statePath = '';
        stateSearch = '';
      } else {
        statePath = state.data.path;
        stateSearch = state.data.search;
      }
      await this.userManagerSigninIS3.signinRedirect({
        state: {
          path: statePath,
          search: stateSearch,
        },
      });

      return null;
    }

    // successfully signed in
    return await this.handleSuccessfulLogin();
  }

  /*
   * Handle redirect from password reset
   */
  private async handlePasswordResetRedirect(): Promise<void> {
    const params: ParamDictionary = extractParametersFromCallbackUrl();
    const state = JSON.parse(
      await this.userManagerPasswordReset.settings.stateStore.get(params.state)
    );

    await this.userManager.signinRedirect({
      state: {
        path: state.data.path,
        search: state.data.search,
      },
    });
  }

  /*
   * Handle redirect from IS3 conversion
   */
  private async handleIS3ConversionRedirect(): Promise<void> {
    const params: ParamDictionary = extractParametersFromCallbackUrl();
    const state = JSON.parse(
      await this.userManagerIS3Conversion.settings.stateStore.get(params.state)
    );

    await this.userManager.signinRedirect({
      state: {
        path: state.data.path,
        search: state.data.search,
      },
    });
  }

  /*
   * Handle redirect from IS3 with no prompt
   */
  private async handleSigninIS3Redirect(): Promise<void> {
    const params: ParamDictionary = extractParametersFromCallbackUrl();
    const state = JSON.parse(
      await this.userManagerSigninIS3.settings.stateStore.get(params.state)
    );

    if (!params.error) {
      // successful login into IS3, extract token and exchange for ADB2C token
      const user = await this.userManagerSigninIS3.signinRedirectCallback();
      const token = user.access_token;

      await this.userManagerIS3Conversion.signinRedirect({
        state: {
          path: user.state.path,
          search: user.state.search,
        },
        login_hint: token,
      });

      return null;
    }

    // have error then prompt
    return this.userManager.signinRedirect({
      state: {
        path: state.data.path,
        search: state.data.search,
      },
    });
  }

  /*
   * Handle logout
   */
  private async handleLogoutRedirect(): Promise<void> {
    window.location.href = settings.adb2cPostLogoutRedirectUri;
  }

  private async handleLogoutRootPathRedirect(): Promise<void> {
    //Redirect to the root path (the login page)
    window.location.href = rootPath;
  }

  private async handleLegacyLogoutRedirect(): Promise<void> {
    //Landing here means adb2c is ON and a logout for IS3 was executed
    //Now we need to logout from adb2c
    this.ExecuteStandardLogout();
  }

  /*
   * Handle errors returned from AD B2C
   */
  private async handleAdb2cErrors(): Promise<boolean> {
    const params: ParamDictionary = extractParametersFromCallbackUrl();
    const error = params.error;
    const errorDescription = params.error_description;

    if (
      error === 'access_denied' &&
      errorDescription &&
      errorDescription.startsWith('AADB2C90118')
    ) {
      // This is to handle the case where the user clicks forgot password in the sign in page.
      // We receive a specific error and need to perform the reset password flow.

      // create args
      const args: any = {
        prompt: 'login',
      };

      // extract state
      const state = JSON.parse(
        await this.userManager.settings.stateStore.get(params.state)
      );
      if (state) {
        args.state = {
          path: state.data.path,
          search: state.data.search,
        };
      }

      // extract email from error description
      const errorParameters: ParamDictionary = {};
      each(errorDescription.split('\r\n'), value => {
        const parts = value.split(': ');
        errorParameters[parts[0]] = parts[1];
      });

      const email = errorParameters.login_hint;
      if (email) {
        args.login_hint = email;
      }

      await this.userManagerPasswordReset.signinRedirect(args);

      return true;
    }

    return false;
  }

  /*
   * Handle successful login
   */
  private async handleSuccessfulLogin(): Promise<Oidc.User | null> {
    try {
      const user = await this.userManager.signinRedirectCallback();

      if (user) {
        if (user.state.path.indexOf(logoutCustomerRedirectPath) > -1) {
          // If Account is logged out from a redirect Uri (i.e. https://account.myfoodbag.co.nz/customerRedirect). We don't want to save that Uri to our route history
          routeHistory.history.push('');
        } else {
          routeHistory.history.push(`${user.state.path}${user.state.search}`);
        }

        return user;
      }
    } catch (err) {
      if (err && err.message === 'No matching state found in storage') {
        logger.warn(
          'No matching state found in storage found so clearing stale state and trying again.'
        );
        await this.userManager.clearStaleState();
      } else {
        throw err;
      }
    }

    return null;
  }
}

export default OidcUserManager;
