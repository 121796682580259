import { AddressDto, ProductGroupResponse } from "../app/shared/CoreClient";
import {
  AccountFeatures,
  AccountSettings,
  BenefitDto,
  DeliveryDay,
  DeliveryResponse,
  GetCustomerMessageResponse,
  LegacyOrdersResponse,
  OrderWithRecipesAndExtras,
  RateRecipeResponse,
  SubscriptionDTO,
} from '../app/shared/CoreClient';
import {
  campaignPath,
  deliveriesPath,
  manageAccountPath,
  mealSelectorPath,
  rateRecipesPath,
} from '../app/account-settings/components/Nav/paths';
import {
  TranslationsState,
  defaultTranslationState,
} from './reducers/translationsReducer';
import {
  EligibleCampaignDto,
  EnrolledCampaignDto,
  GetCustomerMessageBulkResponse, ICustomerProfileSubscriptionDto,
  ISubscriptionTrackingMetadata,
} from '@mfb/account-api-client';

/*
  ATTENTION: if you're getting errors around PageState not being assignable to {} then
  you may have non-nullable primatives sitting on one of the contracts below (LegacyOrdersResponse,
  AccountSettings ...etc). Since page state is hydrated after the page loads, we need to
  create an empty object so react can initialise. If you try and create an empty object
  with required (no nullable) types then you're gonna have a bad time. This can report errors
  in different areas of the app but they're just the ripple effect of this error.
*/

export type PageStateSources =
  | '' // blank string signifies to replace no matter what
  | typeof manageAccountPath
  | typeof deliveriesPath
  | typeof rateRecipesPath
  | typeof campaignPath
  | typeof mealSelectorPath;

export interface RequestState {
  requestTime?: Date;
  requestSource?: PageStateSources;
}

export interface OrdersPageState extends LegacyOrdersResponse {
  /**
   * This is the preference for loading orders. Use this in place of
   * LegacyOrdersResponse.DeliveryDays
   */
  orders?: Array<OrderWithRecipesAndExtras>;
  /**
   * @deprecated Prefer orders if it exists.
   */
  deliveryDays: Array<DeliveryDay>;
  upcomingDeliveryWeek?: Date;
  subscriptions?: SubscriptionDTO[];
  promo?: BenefitDto;
}

export type PageState = (
  | OrdersPageState
  | AccountSettings
  | RateRecipeResponse
  | DeliveryResponse
  ) &
  RequestState;

export const defaultPageState: PageState = {
  requestTime: new Date(0),
  requestSource: '',
};

export const defaultTracking: TrackingState = {
  subscriptions: [],
};

export const defaultCampaignState: CampaignState = {
  enrolledCampaignDto: null,
  eligibleCampaignDto: null,
}

export type UiComponentAttributes = {
  isHidden?: boolean;
};

export type UiComponentRecord = Record<string, UiComponentAttributes>;

export interface UiState {
  isLoading: boolean;
  isSubmitting: boolean;
  wasSuccessful?: boolean;
  selectedDay?: string;
  uiComponents: UiComponentRecord;
}

export const defaultUiState: UiState = {
  isLoading: true,
  isSubmitting: false,
  uiComponents: {} as UiComponentRecord,
};

export interface ExperimentState {
  variation?: string;
  isLoading: boolean;
}

export interface BagState {
  validationErrors?: string[];
  isValidPromo?: boolean;
}

export interface CustomerProfileState {
  subscriptions: ICustomerProfileSubscriptionDto[];
  address?: AddressDto;
  customerNumber?: string;
  firstName?: string;
  lastName?: string;
  productName?: string;
  deliveryTime?: string;
  deliveryDate?: string;
  availableCredit?: string;
  iconUrl?: string;
  iconSvgUrl?: string;
  code?: string;
  optOutRecipeCards: boolean;
}

export interface CustomerMessageState extends GetCustomerMessageResponse {
  subscriptionNumber: string;
  deliveryDateId: number;
  deliveryWeek: string;
  triggerCode: string;
}

export interface CustomerMessageBulkState extends GetCustomerMessageBulkResponse {
  subscriptionNumber: string;
}

export interface TrackingState {
  subscriptions: ISubscriptionTrackingMetadata[];
}

export interface CampaignState {
  enrolledCampaignDto?: EnrolledCampaignDto;
  eligibleCampaignDto?: EligibleCampaignDto;
}

export interface CustomerMessageGroupState {
  triggerCode: string;
  group: Array<CustomerMessageState>;
}

export interface CustomerMessageBulkGroupState {
  triggerCode: string;
  group: Array<CustomerMessageBulkState>;
}

export type AccountFeaturesState = AccountFeatures & RequestState;

export const defaultExperimentState: ExperimentState = {
  isLoading: false,
};

export const defaultBagState: BagState = {
  validationErrors: [],
  isValidPromo: false,
};

export const defaultCustomerProfileState: CustomerProfileState = {
  subscriptions: [],
  firstName: undefined,
  lastName: undefined,
  productName: undefined,
  deliveryTime: undefined,
  deliveryDate: undefined,
  availableCredit: undefined,
  iconUrl: undefined,
  iconSvgUrl: undefined,
  code: undefined,
  optOutRecipeCards: false,
};

export interface AppState<TState extends PageState = PageState> {
  pageState: TState;
  experimentState: ExperimentState;
  ui: UiState;
  features: AccountFeaturesState;
  bagState: BagState;
  customerProfileState: CustomerProfileState;
  subscriptions: SubscriptionDTO[];
  customerMessageBulkGroupState: Array<CustomerMessageBulkGroupState>;
  productGroupMarketing: Array<ProductGroupResponse>;
  translations: TranslationsState;
  campaign: CampaignState,
  tracking: TrackingState;
}

export const defaultFeaturesState: AccountFeaturesState = {
  canUnskipDemandLockedDeliveries: false,
  enableBufferInAccount: false,
  enableSkipPreventionFlexDown: false,
  enableCustomerAddressHoliday: false,
  overrideLegacyReactivationFlow: false,
  overrideLegacyCancelSubscriptionFlow: false,
  enableFlexUp: false,
  enableFlexDown: false,
  requestTime: undefined,
  requestSource: '',
  dashboardV2: undefined,
  displayUxOptinBanner: false,
};

const defaultAppState: AppState = {
  pageState: defaultPageState,
  experimentState: defaultExperimentState,
  ui: defaultUiState,
  features: defaultFeaturesState,
  bagState: defaultBagState,
  customerProfileState: defaultCustomerProfileState,
  subscriptions: [],
  customerMessageBulkGroupState: [],
  productGroupMarketing: [],
  translations: defaultTranslationState,
  campaign: defaultCampaignState,
  tracking: defaultTracking,
};

export default defaultAppState;
