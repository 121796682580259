import { platform } from "app/shared/constants";
import mixpanel from "mixpanel-browser";

export interface BaseEventDataParams {
    simpleProductName: string,
    productSku: string,
    productName: string,
    productBrand: string,
    people: number,
    nights: number,
    subscriptionNumber?: string,
    subscriptionFrequency?: string,
}

export const buildBaseEventData = (
    baseEventData: BaseEventDataParams,
  ) => {
    return {
      customerNumber: mixpanel.get_distinct_id(),
      platform: platform,
      subscriptionSimpleProductName: baseEventData.simpleProductName,
      subscriptionProductSku: baseEventData.productSku,
      subscriptionProductName: baseEventData.productName,
      subscriptionProductBrand: baseEventData.productBrand,
      subscriptionPeople: baseEventData.people,
      subscriptionNights: baseEventData.nights,
      subscriptionNumber: baseEventData.subscriptionNumber,
      subscriptionFrequency: baseEventData.subscriptionFrequency,
    };
  };