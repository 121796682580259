/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import classnames from 'classnames';
import TrackingProps, {
  trackingAction,
  trackingLabel,
  trackingCategory,
} from '../TrackingProps';
import {CSSProperties} from 'react';

interface ButtonProps {
  label?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  submitting?: boolean;
  inline?: boolean;
  primary?: boolean;
  cancel?: boolean;
  tracking?: TrackingProps;
  dataTest?: string;
  onClick?(): void;
  style?: CSSProperties;
}

class Button extends React.PureComponent<ButtonProps, {}> {
  handleClick = (e: React.SyntheticEvent<HTMLElement>) => {
    if (this.props.onClick != null) {
      e.preventDefault();
      if (!this.props.submitting) {
        this.props.onClick();
      }
    }
  };

  render() {
    const {
      children,
      label,
      className,
      disabled,
      submitting,
      inline,
      primary,
      cancel,
      tracking,
      dataTest,
      style,
    } = this.props;

    const cn = classnames(className, 'btn', {
      'is-saving': submitting,
      'd-inline': inline,
      'btn-primary': primary,
      'btn-secondary': cancel,
    });

    return (
      <button
        type="button"
        className={cn}
        disabled={disabled || submitting}
        onClick={this.handleClick}
        data-action={trackingAction(tracking)}
        data-label={trackingLabel(tracking)}
        data-category={trackingCategory(tracking)}
        data-test={dataTest}
        style={style}
      >
        <span className="mfb-Button--text">{label || children}</span>
        <span className="mfb-Button--status" />
      </button>
    );
  }
}

export default Button;