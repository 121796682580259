/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { ComponentClass, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Colours, PillBadge } from '@mfb/lego';
import { navman } from '../../../navigator';
import { AppState } from '../../../redux/state';
import { AccountCreditCard, AccountSettings } from '../../shared/CoreClient';
import Amex from '../../shared/components/Amex';
import { ConfirmationModal } from '../../shared/components/ConfirmationModal';
import MasterCard from '../../shared/components/MasterCard';
import MobileStickyHeaderProxy from '../../shared/components/MobileStickyHeader/MobileStickyHeaderProxy';
import UnknownCard from '../../shared/components/UnknownCard';
import Visa from '../../shared/components/Visa';
import { getIsIOSWebview } from '../../shared/getIsIOSWebview';
import {
  showAddCardFailureToast,
  showAddCardSuccessToast,
  showRemoveCardSuccessToast,
} from '../../toast/ToastMessages';
import {
  DispatcherProps,
  PageProperties,
  mapDispatchToProps,
  mapStateToProps,
} from '../containers/accountSettingsContainer';

export enum PaymentRoute {
  ADD_CARD = 'add-card-success',
  ADD_CARD_FAIL = 'add-card-failure',
  MANUAL_PAYMENT = 'payment-success',
}

interface RouteParams {
  outcome?: PaymentRoute;
}

export interface Props
  extends PageProperties,
    RouteComponentProps<RouteParams> {}

interface State {
  path: string;
  selectedCardId: number;
}

const DefaultPillBadge = styled.div<{
  $show: boolean;
}>`
  width: 100%;
  height: 100%;
  display: ${(c) => (c.$show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

const CardDetails = styled.div`
`

export const addCardSuccessMessage = 'Your card has been successfully added!';
export const addCardFailureMessage = 'There was an issue adding your card';

class Payments extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const path = props.match.params.outcome;
    const isValidPath = Object.values(PaymentRoute).includes(path);

    this.state = {
      path: isValidPath ? path : undefined,
      selectedCardId: undefined,
    };
  }

  componentDidMount() {
    const { path } = this.state;
    if (path === PaymentRoute.ADD_CARD) {
      showAddCardSuccessToast();
      if (getIsIOSWebview()) {
        navman.backToApp();
      }
    } else if (path === PaymentRoute.ADD_CARD_FAIL) {
      showAddCardFailureToast();
    }
  }

  handleRemoveCard = async (cardId: number) => {
    await this.props.removeCreditCard(cardId);
    this.toggleConfirmationModal();
    showRemoveCardSuccessToast();
  };

  handleSetActiveCard = (
    event: React.FormEvent<HTMLDivElement>,
    cardId: number
  ) => {
    event.preventDefault();

    this.props.setActiveCreditCard(cardId);
  };

  toggleConfirmationModal = (e?: any, selectedCardId?: number) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      selectedCardId,
    });
  };

  getCard = (cardType: string) => {
    const defaultDimensions = {
      width: 60,
      height: 42,
    };

    switch (cardType) {
      case 'Visa':
        return <Visa {...defaultDimensions} />;
      case 'MasterCard':
        return <MasterCard {...defaultDimensions} />;
      case 'Amex':
        return <Amex {...defaultDimensions} />;
      default:
        return <UnknownCard {...defaultDimensions} />;
    }
  };

  formatExpireDate(expire?: string) {
    try {
      if (!expire) return;

      const month = expire.slice(0, 2);
      const year = expire.slice(2, 4);

      return `${month}/${year}`;
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const accountSettings = this.props.pageState as AccountSettings;
    const activeCard =
      this.state.selectedCardId &&
      accountSettings.cards.find((c) => c.id === this.state.selectedCardId);

    return (
      <div>
        <MobileStickyHeaderProxy
          hiddenUp="md"
          onBack={
            this.props.features.dashboardV2 ? undefined : navman.accountSettings
          }
        />
        <h3 className="mb-4 mt-2" data-test="payments-title">
          My Wallet
        </h3>

        <div className="row col-12">
          <p className="font-italic text-muted">
            Add, remove or change your payment method. If, for any reason, your
            primary method of payment is declined, we'll try the same card again
            during our next billing run.
          </p>
        </div>
        <div className="row">
          {accountSettings.cards.length > 0 &&
            accountSettings.cards.map(
              (card: AccountCreditCard, index: number) => {
                const defaultClasses = 'card bg-info';

                return (
                  <div
                    key={`card-id-${card.id}`}
                    className="col-12 pb-3"
                    data-test="credit-card"
                    data-category="payment-method"
                    data-action="click"
                    data-label="toggle-active-card"
                    role="button"
                    onClick={(e) => this.handleSetActiveCard(e, card.id)}
                  >
                    <div
                      className={!card.isDefault ? 'card' : defaultClasses}
                      data-test={
                        !card.isDefault ? 'inactive-card' : 'active-card'
                      }
                    >
                      <div className="card-block p-1">
                        <div className="row pb-1 pt-2">
                          <div className="col-5 col-lg-4 col-xl-3 row">
                            <div className="col-6 mt-3">
                              <label className="custom-control custom-radio ml-4">
                                <input
                                  readOnly
                                  type="radio"
                                  checked={card.isDefault}
                                  className="custom-control-input"
                                />
                                <span className="custom-control-indicator" />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck"
                                />
                              </label>
                            </div>

                            <div className="col-6 mt-2">
                              {this.getCard(card.cardType)}
                            </div>
                          </div>
                          <CardDetails className="col-5 col-lg-6 col-xl-7">
                            <h5>{card.cardNumber}</h5>
                            <span>{this.formatExpireDate(card.cardExpiry)}</span>
                          </CardDetails>
                          <div className="col-2">
                            <DefaultPillBadge $show={card.isDefault}>
                              <PillBadge
                                label={'Default'}
                                bgColour={Colours.ACCOUNTV2_TERTIARY_BLUE_100}
                                fgColour={Colours.ACCOUNTV2_TERTIARY_BLUE_500}
                              />
                            </DefaultPillBadge>
                            {!card.isDefault && (
                              <div className="row">
                                <div className="col-12 p-0">
                                  <button
                                    disabled={this.props.ui.isSubmitting}
                                    data-category="payment-method"
                                    data-action="remove"
                                    type="button"
                                    className="close w-50 mb-1 mt-1 p-2"
                                    data-test="remove-card-button"
                                    onClick={(e) =>
                                      this.toggleConfirmationModal(e, card.id)
                                    }
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          <div
            onClick={this.props.addCreditCard}
            className="col-12"
            role="button"
            data-category="payment-method"
            data-action="click"
            data-label="add-new-card"
          >
            <div
              className="card fs-Payments--add-card p-2"
              data-test="add-new-card-button"
            >
              <div className="card-block p-3 text-center">
                <div>
                  <span>+ Add a new credit card</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {activeCard && (
          <ConfirmationModal
            title="Remove Credit Card"
            text={`Are you sure you wish to remove
                              your ${activeCard.cardType.toLowerCase()} card with the number
                              ${activeCard.cardNumber}?`}
            trackingClassPrefix="track-Button-removeCard"
            onConfirm={async () => this.handleRemoveCard(activeCard.id)}
            onCancel={this.toggleConfirmationModal}
            submitClassName="btn-danger text-nowrap"
          />
        )}
      </div>
    );
  }
}

export default Payments;
export const payments = connect<
  AppState,
  DispatcherProps,
  RouteComponentProps<RouteParams>
>(
  mapStateToProps,
  mapDispatchToProps
)(Payments) as ComponentClass<any>;
