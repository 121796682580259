import React from 'react';
import classnames from 'classnames';
import Button from './Button';
import TrackingProps from '../TrackingProps';
import Submit from './Submit';
import {SubmitStage} from './SubmitStage';
import {CSSProperties} from 'react';

export interface SubmitOrBackProps {
  submitLabel?: string;
  backLabel?: string;
  // This component is desktopOnly by default as it is most commonly used to complement MobileStickyHeader
  desktopOnly?: boolean;
  submitClassName?: string;
  backClassName?: string;
  submitStage?: SubmitStage;
  submitTracking?: TrackingProps;
  backTracking?: TrackingProps;
  containerClassName?: string;
  wrapperClassName?: string;
  onSubmit?(): void;
  onBack?(): void;
  classNameSpacer?: string;
  btnStyle?: CSSProperties;
}

/** Includes `buttonStyleClass` unless a custom button style class is found in `customClass`  */
const defaultBtnClass = (
  buttonStyleClass: string,
  customClass: string | undefined
) =>
  classnames(
    customClass,
    !customClass || !customClass.includes('btn-') ? buttonStyleClass : null
  );

const SubmitOrBack: React.FunctionComponent<SubmitOrBackProps> = ({
  submitLabel = 'Save',
  backLabel = 'Back',
  submitClassName,
  backClassName,
  submitStage,
  submitTracking,
  backTracking,
  onSubmit,
  onBack,
  wrapperClassName,
  containerClassName,
  classNameSpacer,
  btnStyle,
}) => (
  <div className={classnames({ 'row d-none d-md-block': !containerClassName}, containerClassName)}>
    <div className={classnames({'d-flex col-12': !wrapperClassName}, wrapperClassName)}>
      <Submit
        style={btnStyle}
        className={defaultBtnClass('', submitClassName)}
        label={submitLabel}
        stage={submitStage}
        tracking={submitTracking}
        onClick={onSubmit}
      />
      {classNameSpacer && <div className={classNameSpacer} style={{width: '20px'}}></div>}
      <Button
        style={btnStyle}
        cancel
        className={classnames(
          {'ml-2': !backClassName},
          backClassName
        )}
        disabled={submitStage === SubmitStage.Saving}
        tracking={backTracking}
        onClick={onBack}
      >
        {backLabel}
      </Button>
    </div>
  </div>
);

export default SubmitOrBack;
