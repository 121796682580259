import {AsyncButton} from '@mfb/lego';
import React from 'react';
import {
  CampaignClient,
  CampaignGroupDto,
  GroupedCampaignDto,
  JoinCampaignCommandBody,
} from '../../shared/CoreClient';
import {
  convertToCampaignSelections,
  convertToSelectedPrizes,
} from '../campaignUtils';
import {CampaignSelector} from './CampaignSelector';
import {CampaignPrizeSelector} from './CampaignPrizeSelector';
import { globalSettings as settings } from 'config';
import {navman} from '../../../navigator';
import {PerformJoinCampaign} from '../../deliveries/index';
import {trackJoinCampaignEvent} from '../../shared/campaignTracker';
import { store } from 'index';
import { fetchCampaignState } from 'redux/actions/sharedActions/campaignProgressAction';

const sendJoinCampaignRequest = async (
  campaignCode: string,
  selectedPrizes: Record<number, number>
) => {
  try {
    const client = new CampaignClient(settings.bffHost);

    const request: JoinCampaignCommandBody = {
      campaignCode,
      campaignSelections: convertToCampaignSelections(selectedPrizes),
      isAutoEnrolled: false,
    };
    const response = await client.joinCampaign(request);

    return response;
  } catch (err) {
    console.error(err);
  }
};

interface Props {
  campaignGroupResponse: CampaignGroupDto;
  onSubmitCallback: PerformJoinCampaign;
}

const CampaignSignUpForm: React.FunctionComponent<Props> = ({
  campaignGroupResponse,
  onSubmitCallback,
}) => {
  const {groupTitle, groupDescription, campaigns} = campaignGroupResponse;
  const [selectedPrizes, setSelectedPrizes] = React.useState<
    Record<number, number>
  >({});
  const [
    selectedCampaign,
    setSelectedCampaign,
  ] = React.useState<GroupedCampaignDto>(campaigns[0]);
  const [submitError, setSubmitError] = React.useState<string>();
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(
    false
  );
  React.useEffect(() => {
    if (selectedCampaign.prizeSelections) {
      setSelectedPrizes(
        convertToSelectedPrizes(selectedCampaign.prizeSelections)
      );
    }

    const hasError = submitError && true;
    setIsSubmitDisabled(hasError);
  }, [selectedCampaign.prizeSelections, submitError]);

  React.useEffect(() => {
    setSubmitError(undefined);
  }, [selectedCampaign.prizeSelections]);

  const onClickCampaign = (campaignCode: string) => {
    const campaign = campaigns.find(c => c.campaignCode === campaignCode);
    setSelectedCampaign(campaign);
  };

  const onClickPrize = (
    e: React.MouseEvent<HTMLDivElement>,
    t: number,
    p: number
  ) => {
    e.preventDefault();

    const selectedPrizeKvp: Record<number, number> = {};
    selectedPrizeKvp[t] = p;

    setSelectedPrizes({
      ...selectedPrizes,
      ...selectedPrizeKvp,
    });
  };

  const onSubmitAsync = async (
    campaignCode: string,
    prizes: Record<number, number>
  ) => {
    const response = await sendJoinCampaignRequest(campaignCode, prizes);

    const {isValid, errorMessages} = response;

    trackJoinCampaignEvent(campaignCode, prizes, campaignGroupResponse);

    if (isValid) {
      store.dispatch(fetchCampaignState());
      navman.yourAccount();
      onSubmitCallback();
    }
    const errorString = errorMessages.length
      ? errorMessages.join(', ')
      : 'We were unable to process your request to join this campaign. Please try again.';

    setSubmitError(errorString);
  };

  const isSingleCampaignGroup = campaigns.length === 1;

  return (
    <div className="mx-md-4">
      <h3 className="my-4 w-100 text-center text-md-left">{groupTitle}</h3>
      <p className="mb-sm-3 w-100">
        {groupDescription}{' '}
        <a href={`${settings.webURL}/terms`} target="_blank" rel="noreferrer">
          {`Terms & Conditions apply.`}
        </a>
      </p>
      {!isSingleCampaignGroup && (
        <CampaignSelector
          campaigns={campaigns}
          selectedCampaignCode={selectedCampaign.campaignCode}
          onClick={onClickCampaign}
        />
      )}
      <CampaignPrizeSelector
        campaign={selectedCampaign}
        selectedPrizes={selectedPrizes}
        onClick={onClickPrize}
      />
      <div className="w-100 text-center mb-5">
        <div className="d-flex-grow d-md-inline-flex">
          <AsyncButton
            className="btn btn-primary btn-block"
            disabled={isSubmitDisabled}
            onClickAsync={async () =>
              onSubmitAsync(selectedCampaign.campaignCode, selectedPrizes)
            }
          >
            <span className="px-5">Join</span>
          </AsyncButton>
        </div>
        {submitError && (
          <div className="text-danger text-center mt-2">{submitError}</div>
        )}
      </div>
    </div>
  );
};

export default CampaignSignUpForm;
