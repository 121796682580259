/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import classnames from 'classnames';
import Spinner from './Spinner';
import TrackingProps from '../TrackingProps';
import {
  trackingCategory,
  trackingAction,
  trackingLabel,
} from '../TrackingProps';

interface Props {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  submitting?: boolean;
  tracking?: TrackingProps;
  onClick(): void;
}

class LinkButton extends React.PureComponent<Props, {}> {
  handleClick = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    if (!this.props.disabled && !this.props.submitting) {
      this.props.onClick();
    }
  };

  render() {
    const {tracking, children, className, disabled, submitting} = this.props;
    const cn = classnames(className, {
      disabled,
      invisible: submitting,
    });

    return (
      <span className="d-ib position-relative">
        <a
          href="#"
          data-category={trackingCategory(tracking)}
          data-action={trackingAction(tracking)}
          data-label={trackingLabel(tracking)}
          data-test={trackingAction(tracking)}
          onClick={this.handleClick}
          className={cn}
        >
          {children}
        </a>
        {submitting && (
          <Spinner
            className="mfb-u-position-absolute-centered"
            width={20}
            height={20}
          />
        )}
      </span>
    );
  }
}

export default LinkButton;
