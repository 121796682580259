import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {navItems} from './HeaderLegacy';
import {mealSelectorPath} from '../../account-settings/components/Nav/paths';
import {getIsIOSWebview} from '../getIsIOSWebview';

export interface FooterLegacyProps {
  currentPathName?: string;
}

const navItemKeys = Object.keys(navItems);

const FooterLegacy: React.FunctionComponent<FooterLegacyProps> = ({
  currentPathName,
}: FooterLegacyProps) => {
  const isIOSWebView = getIsIOSWebview();

  if (currentPathName.includes(mealSelectorPath) || isIOSWebView) {
    return null;
  }
  return (
    <footer className="mfb-Footer d-lg-none fixed-bottom pt-2">
      <div className="container d-flex justify-content-around">
        {navItemKeys.map((key, i) => {
          const navItem = navItems[key];
          const Icon = navItem.icon;

          return (
            <span
              key={i}
              data-category={navItem.event.category}
              data-label={navItem.event.label}
              data-action={navItem.event.action}
            >
              <NavLink
                key={i}
                activeClassName="is-selected"
                className="px-0 nav-link text-center"
                exact
                to={navItem.href}
              >
                <Icon seasonal="true" width={13} height={13} />
                <div>{navItem.mobileTitle}</div>
              </NavLink>
            </span>
          );
        })}
      </div>
    </footer>
  );
};

export default FooterLegacy;
