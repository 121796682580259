import React from 'react';  
import {
  AccountProductSummary,
  DeliveryLineResponse,
  ExtraClient,
  Frequency2,
} from '../../shared/CoreClient';
import { OrderItemGrid } from './OrderItemGrid';
import { CardTag, CardTagType, CarouselItemModel } from '@mfb/lego';
import { BagInteractionHandlers } from './DeliveryContainerWithTabs';
import { globalSettings as settings } from 'config';
import {
  CartMetaExtra,
  CookbookStrategyContext,
  ExtraItem,
  PYWStep,
} from '@mfb/cookbook';
import Spinner from '../../shared/components/Spinner';
import { AccountCookbook } from '../../shared/AccountCookbook';
import { PlanYourWeekNavConfig } from '../../../navigator';
import { useMaxHeightByClassName } from '../../shared/hooks/useMaxHeightByClassName';
import { COLOR_CODE } from '../../shared/constants';
import {useTranslation} from 'react-i18next';
import styled from "styled-components";

const CAROUSEL_ITEM_CLASS = 'extra-item-carousel';

const PADDING_SIZES = { small: '100%' };

const ExtrasHeader = styled.h6`
  color: ${c => c.theme.colors.neutral["900"]};
  font-size: ${c => c.theme.typography.fontSize["500"]};
  font-weight: ${c => c.theme.typography.fontWeight["900"]};
  line-height: 1.3;
`
export const carouselItemPlaceholder: CarouselItemModel = {
  key: `carousel-item-placeholder`,
  component: <React.Fragment />,
};

const mapExtraCarouselItem = (
  props: CartMetaExtra,
  hideQuantitySelector: boolean,
  appliedPromoCode?: string,
  editExtrasHandler?: (
    extra: CartMetaExtra,
    count: number,
    configuration?: PlanYourWeekNavConfig
  ) => CartMetaExtra,
  cardClassName?: string,
  maxHeight?: number
): CarouselItemModel => ({
  key: `ckey-${props.sku}`,
  component: (
    <ExtraItem
      key={props.sku}
      className="h-100 mb-4"
      cardClassName={cardClassName}
      cardHeight={maxHeight}
      extra={{
        ...props,
        quantity: props.quantity,
        addOnToRecipeNumbers: []
      }}
      hideQuantitySelector={hideQuantitySelector}
      paddingSizes={PADDING_SIZES}
      addExtra={editExtrasHandler}
      removeExtra={editExtrasHandler}
      findInitialExtraBySku={() => undefined}
      showQuantityIndicator={Boolean(props.quantity)}
      promoCode={appliedPromoCode}
    />
  ),
});

const mapExtraSuggestionToExtraItemProps = (
  extraSuggestion: AccountProductSummary
): CartMetaExtra => {
  const { name, sku, imageUrl, defaultFrequency, totalPrice } = extraSuggestion;

  return {
    sku: sku,
    name: name,
    imageUrl: imageUrl,
    frequency: defaultFrequency.valueOf(),
    price: totalPrice,
    quantity: 0,
    maximumQuantity: 0,
  };
};

const getExtraSuggestions = async (
  primaryProductSku: string,
  week: string
): Promise<AccountProductSummary[]> => {
  const deliveriesClient = new ExtraClient(settings.bffHost);
  return await deliveriesClient.getExtraSuggestionsByBagSku(
    primaryProductSku,
    week
  );
};

export const mapSelectedExtraToExtraItemProps = (
  extra: DeliveryLineResponse
): CartMetaExtra => {
  const {
    sku,
    price,
    imageUrl,
    lineDescription,
    quantity,
    extraFrequency,
    isPromotional,
    addOnToRecipeNumbers,
  } = extra;

  return {
    sku: sku,
    name: lineDescription,
    imageUrl: imageUrl,
    frequency: extraFrequency.valueOf(),
    price: isPromotional ? 0 : price,
    quantity: quantity,
    maximumQuantity: 0,
    isPromotional: isPromotional,
    addOnToRecipeNumbers: addOnToRecipeNumbers,
  };
};

interface Props {
  primaryProductSku: string;
  day: string;
  extras: Array<DeliveryLineResponse>;
  isVirtualDelivery: boolean;
  week: string;
  subscriptionNumber: string;
  appliedPromoCode?: string;
  bagInteractionHandlers?: BagInteractionHandlers;
}

export const OrderExtras: React.FC<Props> = ({
  primaryProductSku,
  extras,
  isVirtualDelivery,
  week,
  subscriptionNumber,
  appliedPromoCode,
  bagInteractionHandlers,
}) => {
  const [carouselItemProps, setCarouselItemProps] = React.useState<
    CartMetaExtra[]
  >([]);

  const extraCartIsEmpty = extras ? extras.length === 0 : true;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [disableSwipe, setDisableSwipe] = React.useState<boolean>(false);

  const {t} = useTranslation();

  const editExtrasHandler = (
    extra: CartMetaExtra,
    configuration?: PlanYourWeekNavConfig
  ) => {
    bagInteractionHandlers.editExtras(configuration);
    return extra;
  };

  const maxHeight = useMaxHeightByClassName(CAROUSEL_ITEM_CLASS);
  const shouldShowExtraSuggestions =
    extras && extras.length === 0 && primaryProductSku && isVirtualDelivery;

  React.useEffect(() => {
    if (shouldShowExtraSuggestions) {
      setIsLoading(true);
      getExtraSuggestions(primaryProductSku, week)
        .then(getExtraSuggestionItemProps)
        .then(setCarouselItemProps)
        .then(() => setIsLoading(false));
    } else if (extras && extras.length > 0) {
      const selectedExtrasItemProps = getSelectedExtraItemProps();
      setCarouselItemProps(selectedExtrasItemProps);
    }
  }, []);

  const getSelectedExtraItemProps = () => {
    const selectedExtrasItemProps = extras.map(
      mapSelectedExtraToExtraItemProps
    );

    return selectedExtrasItemProps;
  };

  const getExtraSuggestionItemProps = (
    extraSuggestions: AccountProductSummary[]
  ): CartMetaExtra[] => {
    const extraItemProps = extraSuggestions.map(
      mapExtraSuggestionToExtraItemProps
    );

    return extraItemProps;
  };

  const getSuggestedCarouselItems = (
    extraSuggestionsItemProps: CartMetaExtra[],
    isForCarousel?: boolean
  ) => {
    let items: Array<CarouselItemModel>;
    if (extraSuggestionsItemProps.length > 0) {
      items = extraSuggestionsItemProps.map((e) =>
        mapExtraCarouselItem(
          e,
          false,
          appliedPromoCode,
          () =>
            editExtrasHandler(e, {
              week: `W${week}`,
              step: PYWStep.kitchen,
              subNumber: subscriptionNumber,
              scrollTo: e.sku,
              skus: [e.sku],
            }),
          isForCarousel ? CAROUSEL_ITEM_CLASS : undefined,
          isForCarousel ? maxHeight : undefined
        )
      );
    } else if (extraSuggestionsItemProps.length === 0) {
      items = [carouselItemPlaceholder];
    }
    return items;
  };

  const getSelectedCarouselItems = (
    selectedExtrasItemProps: CartMetaExtra[],
    isForCarousel?: boolean
  ) => {
    let items: Array<CarouselItemModel>;
    items = selectedExtrasItemProps.map((e) =>
      mapExtraCarouselItem(
        e,
        true,
        appliedPromoCode,
        undefined,
        isForCarousel ? CAROUSEL_ITEM_CLASS : undefined,
        isForCarousel ? maxHeight : undefined
      )
    );
    // need to add a carousel place holder, if there's only 1 item, so it will be left aligned
    if (items.length === 1) {
      setDisableSwipe(true);
      items = items.concat(carouselItemPlaceholder);
    }
    return items;
  };

  const gridItems = React.useMemo(
    () =>
      shouldShowExtraSuggestions
        ? getSuggestedCarouselItems(carouselItemProps)
        : getSelectedCarouselItems(carouselItemProps),
    [shouldShowExtraSuggestions, carouselItemProps]
  );

  const showExtrasSection = !(
    gridItems &&
    gridItems.length === 0 &&
    !isVirtualDelivery
  );

  return (
    <CookbookStrategyContext.Provider value={new AccountCookbook()}>
      {showExtrasSection && (
        <>
          {/*show 'NEW' badge*/}
          {extraCartIsEmpty && (
            <CardTag
              className={'bs-linebreaks'}
              text={'NEW'}
              color={COLOR_CODE.DARK_RED}
              type={CardTagType.Pill}
            />
          )}

          <div className="d-block d-sm-flex">
            <ExtrasHeader className="pr-3 text-nowrap">
              {extraCartIsEmpty
                ? 'Check out our go-to favourites'
                : t('deliveries.extraSelection.title')}
            </ExtrasHeader>
            {isVirtualDelivery && (
              <p
                data-test={'browse-kitchen-link'}
                onClick={() =>
                  bagInteractionHandlers.editExtras({
                    week: `W${week}`,
                    subNumber: subscriptionNumber,
                    step: PYWStep.kitchen,
                  })
                }
                className="text-primary text-nowrap"
                role="button"
              >
                Browse our delicious extras
              </p>
            )}
          </div>
          {isLoading ? (
            <Spinner className="mb-5" />
          ) : (
            <>
              <div style={{ width: '100%' }}>
                <OrderItemGrid
                  cardGridDisplayColumns={{
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 3,
                    xl: 5,
                    xxl: 6,
                  }}
                  orderItems={gridItems}
                />
              </div>
            </>
          )}
        </>
      )}
    </CookbookStrategyContext.Provider>
  );
};
