import {Frequency} from '../../../shared/CoreClient';
import {startCase} from 'lodash';
import {Option} from 'react-select';

const exclude = Frequency.OneOff;

export const getFrequencyOptions = (): Array<Option> => {
  const options = [];
  for (const frequencyOption in Frequency) {
    if (Number(frequencyOption) && frequencyOption !== exclude.toString()) {
      const frequency = startCase(Frequency[frequencyOption]);
      options.push({
        label: frequency,
        value: frequencyOption.toString(),
      });
    }
  }

  return options;
};
