import React from 'react';
import Modal from 'react-modal';
import Submit from './Submit';
import { UiState } from '../../../redux/state';
import classnames from 'classnames';
import TrackingProps, {
  trackingCategory,
  trackingAction,
  trackingLabel,
} from '../TrackingProps';
import { getSubmitStage } from './SubmitStage';

interface Props {
  title?: string;
  text?: string;
  uiState?: UiState;
  submitTracking?: TrackingProps;
  cancelTracking?: TrackingProps;
  trackingClassPrefix?: string;
  submitClassName?: string;
  submitButtonLabel?: string;
  cancelButtonLabel?: string;
  children?: JSX.Element;
  onConfirm(): Promise<void> | void;
  onCancel(): void;
}

export const ConfirmationModal: React.FunctionComponent<Props> = ({
  title,
  text,
  children,
  submitButtonLabel,
  cancelButtonLabel,
  uiState,
  submitTracking,
  cancelTracking,
  trackingClassPrefix,
  submitClassName,
  onCancel,
  onConfirm,
}) => (
  <Modal
    isOpen={true}
    contentLabel="confirm"
    shouldCloseOnOverlayClick={true}
    className="mfb-Modal"
    overlayClassName="mfb-ModalOverlay"
    onRequestClose={onCancel}
  >
    <h3>{title ? title : 'Confirmation'}</h3>

    {text && <p>{text}</p>}

    <div className="row">
      <div className="col-12">{children}</div>
      <div className="col-12">
        <Submit
          label={
            submitButtonLabel != null ? submitButtonLabel : "Yes, I'm sure"
          }
          onClick={async () => await onConfirm()}
          stage={getSubmitStage(true, uiState && uiState.isSubmitting)}
          tracking={submitTracking}
          className={classnames(
            trackingClassPrefix != null ? `${trackingClassPrefix}Confirm` : '',
            submitClassName != null ? submitClassName : '',
            'w-100'
          )}
          isSecondary={false}
        />
      </div>
      <div className="mt-2 col-12">
        <button
          name="cancel"
          className={classnames(
            'btn btn-secondary w-100',
            `${trackingClassPrefix}Cancel`
          )}
          onClick={onCancel}
          data-category={trackingCategory(cancelTracking)}
          data-action={trackingAction(cancelTracking)}
          data-label={trackingLabel(cancelTracking)}
        >
          {cancelButtonLabel || 'No, take me back'}
        </button>
      </div>
    </div>
  </Modal>
);
