import React from 'react';
import {AccountMerchSection, MerchSlotType} from '../shared/CoreClient';
import {SingleMerchSlot} from './SingleMerchSlot';
import {DoubleMerchSlot} from './DoubleMerchSlot';
import TrackingProps from '../shared/TrackingProps';

export const MerchandisingSlots: React.FC<AccountMerchSection> = ({
  slotType,
  imageSet,
  slotName,
  hyperlink,
  imageSet2,
  slotName2,
  hyperlink2,
}: AccountMerchSection) => {
  switch (slotType) {
    case MerchSlotType.Single:
      const tracking: TrackingProps = {
        category: 'merchandising',
        action: 'click-single-merch-slot',
        label: `${slotName} - merch-slot`,
      };

      return (
        <div className="mt-3 mb-5">
          <SingleMerchSlot {...{imageSet, slotName, hyperlink, tracking}} />
        </div>
      );

    case MerchSlotType.Double:
      return (
        <div className="mt-3 mb-5">
          <DoubleMerchSlot
            {...{
              imageSet,
              slotName,
              hyperlink,
              imageSet2,
              slotName2,
              hyperlink2,
            }}
          />
        </div>
      );

    default:
      return null;
  }
};
