import React from 'react';
import { mapCalendarCarouselTab } from './CalendarTab';
import { Carousel, CarouselItemModel } from '@mfb/lego';
import { mapDeliveryDateToCarouselTab } from './DeliveryDateTab';
import date from '../../../../format/date';
import { DeliveryDay } from '../../../shared/CoreClient';
import TrackingProps from '../../../shared/TrackingProps';
import { dateFormat } from '../../../../lib/date/dateFormat';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export interface DeliveryDaySelectorProps {
  deliveryDays: Array<DeliveryDay>;
  hasTickerTapeCampaign: boolean;
  selectedDate: string;
  updateSelectedDay(selectedDay: string): void;
}

const StyledDeliveryDaySelectorContainer = styled.div`
  @media (max-width: ${(c) => c.theme.layout.breakpoints.sm}) {
    padding-left: 0;
  }
  padding-left: 8px;
  overflow: hidden;
`;

export const DeliveryDaySelector: React.FC<DeliveryDaySelectorProps> = ({
  hasTickerTapeCampaign,
  deliveryDays,
  selectedDate,
  updateSelectedDay,
}) => {
  const selectedIdx = useRef<number>(-1);

  useEffect(() => {
    handleFocusingSlide();
  }, []);

  const [slideToFocus, setSlideToFocus] = useState<number>(0);

  const getSelectedDateIdx = () => {
    deliveryDays.find((x, idx) => {
      if (x.order.orderKey === selectedDate) {
        selectedIdx.current = idx;
      }
    });
  };

  const handleFocusingSlide = () => {
    getSelectedDateIdx();
    if (selectedIdx.current != 0) {
      if (selectedIdx.current - 2 !== 0) {
        setSlideToFocus(selectedIdx.current - 2);
      } else {
        setSlideToFocus(selectedIdx.current - 1);
      }
    } else {
      setSlideToFocus(selectedIdx.current);
    }
  };

  const calendarCarouselItemModel = mapCalendarCarouselTab();

  const mapDeliveryDayToCarouselItemModel = (
    deliveryDay: DeliveryDay
  ): CarouselItemModel => {
    const deliveryDate = deliveryDay.date.toString();
    const actualDeliveryDate = deliveryDate.substring(1);
    const currentDate = dateFormat(new Date(), 'yyyy-MM-dd');
    const deliveryTabId = deliveryDay.order.orderKey;
    const order = deliveryDay.order;
    const tracking: TrackingProps = {
      category: 'delivery',
      action: 'view-upcoming-delivery',
      label: date(deliveryDate),
    };
    const tabButtonProps = {
      deliveryDate,
      isSelected: selectedDate === deliveryTabId,
      isSkipped: order.isSkipped,
      tracking,
      isOrderDelivered: actualDeliveryDate <= currentDate,
      onClick: () => updateSelectedDay(deliveryTabId),
      hasOverrideDeliveryDate: order.hasOverrideDeliveryDate,
    };

    return mapDeliveryDateToCarouselTab(tabButtonProps);
  };

  const dayCarouselItemModel = deliveryDays.map(
    mapDeliveryDayToCarouselItemModel
  );

  const carousels = hasTickerTapeCampaign ? dayCarouselItemModel : dayCarouselItemModel.concat(calendarCarouselItemModel);

  return (
    <StyledDeliveryDaySelectorContainer className="mfb-slick-flex">
      <style>{'.nooutline{outline:none}'}</style>
      <Carousel
        rememberToFocus={true}
        slideToFocus={slideToFocus}
        items={carousels}
        itemClassName="h-100 nooutline"
        variableWidth={true}
      />
    </StyledDeliveryDaySelectorContainer>
  );
};
