import * as React from 'react';
import Logo from './Logo';
import {NavLink} from 'react-router-dom';
import createUserManager from '../UserManager';
import BargainBoxLogo from './BargainBoxLogo';
import {
  deliveriesPath,
  rateRecipesPath,
  referAFriendPath,
} from '../../account-settings/components/Nav/paths';
import AccountSettingsIcon from './AccountSettingsIcon';
import RateRecipesIcon from './RateRecipesIcon';
import DeliveryIcon from './DeliveriesIcon';
import {manageAccountPath} from '../../account-settings/components/Nav/paths';
import classnames from 'classnames';
import {getIsIOSWebview} from '../getIsIOSWebview';
import {COLOR_CODE} from '../constants';
import routeHistory from '../../../routerHistory';
import {
  NavigationHeader,
  SvgRafGiftIcon as RafGiftIcon,
  SvgRafGiftIcon,
} from '@mfb/lego';
import {AccountFeatures} from '../CoreClient';
import {openZendesk} from '../Zendesk';
interface GTMEvent {
  category: string;
  label: string;
  action: string;
}

interface NavItem {
  title: string;
  mobileTitle: string;
  href: string;
  icon: any;
  desktopIcon?: any;
  event: GTMEvent;
  showActive: boolean;
}

interface NavItems {
  [key: string]: NavItem;
}

const trackingCategory = 'main-nav';
const trackingAction = 'click';
const trackingLabelMFBLogo = 'mfb-logo';
const trackingLabelBBLogo = 'bb-logo';

export const navItems: NavItems = {
  deliveries: {
    title: 'Deliveries',
    mobileTitle: 'Deliveries',
    href: deliveriesPath,
    icon: DeliveryIcon,
    event: {
      category: trackingCategory,
      action: trackingAction,
      label: 'deliveries-nav',
    },
    showActive: true,
  },
  accountSettings: {
    title: 'Manage Account',
    mobileTitle: 'Account',
    href: manageAccountPath,
    icon: AccountSettingsIcon,
    event: {
      category: trackingCategory,
      action: trackingAction,
      label: 'manage-account-nav',
    },
    showActive: true,
  },
  rateRecipes: {
    title: 'Rate Recipes',
    mobileTitle: 'Rate Recipes',
    href: rateRecipesPath,
    icon: RateRecipesIcon,
    event: {
      category: trackingCategory,
      action: trackingAction,
      label: 'rate-recipes-nav',
    },
    showActive: true,
  },
  treatFriends: {
    title: 'My Deals',
    mobileTitle: 'My Deals',
    href: referAFriendPath,
    icon: RafGiftIcon,
    desktopIcon: RafGiftIcon,
    event: {
      category: trackingCategory,
      action: trackingAction,
      label: 'treat-friends-nav',
    },
    showActive: false,
  },
};

const navItemKeys = Object.keys(navItems);

export const HeaderLegacy = () => {
  const logout = async (e: any) => {
    e.preventDefault();
    const userMgr = await createUserManager();
    await userMgr.logout();
  };

  if (getIsIOSWebview()) {
    return null;
  }

  return (
    <nav
      className="navbar navbar-expand-lg pb-2 pt-2 pb-md-2 pt-md-2"
      style={{backgroundColor: COLOR_CODE.BARELY_THERE_BEIGE}}
    >
      {/* small-only viewport header */}

      <div className="d-flex justify-content-between align-items-center d-lg-none w-100">
        <div className="d-flex align-items-center">
          <a
            data-category={trackingCategory}
            data-action={trackingAction}
            data-label={trackingLabelMFBLogo}
          >
            <Logo width={50} height={50} className="mr-2" />
          </a>
          <a
            data-category={trackingCategory}
            data-action={trackingAction}
            data-label={trackingLabelBBLogo}
          >
            <BargainBoxLogo width={55} height={35} />
          </a>
        </div>
        <a
          href="#"
          onClick={logout}
          data-test="logout-mobile"
          data-category={trackingCategory}
          data-action="click"
          data-label="logout"
        >
          Logout
        </a>
      </div>

      {/* medium-up viewport header */}

      <div className="container p-0 d-none d-lg-block">
        <div className="collapse navbar-collapse d-flex justify-content-between align-items-center">
          <div className="navbar-brand mr-auto d-flex align-items-center">
            <a
              data-category={trackingCategory}
              data-action={trackingAction}
              data-label={trackingLabelMFBLogo}
            >
              <Logo width={60} height={60} className="mr-2" />
            </a>
            <a
              data-category={trackingCategory}
              data-action={trackingAction}
              data-label={trackingLabelBBLogo}
            >
              <BargainBoxLogo width={70} height={50} />
            </a>
          </div>
          <ul className="navbar-nav my-auto">
            {navItemKeys.map((key, i) => {
              const navItem = navItems[key];
              const Icon = navItem.desktopIcon;

              return (
                <li
                  key={i}
                  className="nav-item px-4 pt-2"
                  data-category={navItem.event.category}
                  data-label={navItem.event.label}
                  data-action={navItem.event.action}
                >
                  <NavLink
                    activeClassName={classnames({
                      'is-selected': navItem.showActive,
                    })}
                    className="nav-link px-0 text-dark text-uppercase"
                    to={navItem.href}
                  >
                    <div className="d-flex flex-row justify-content-end">
                      {Icon && (
                        <div
                          style={{marginTop: '-4px'}}
                          className="mr-1 d-flex flex-row align-items-center justify-content-center"
                        >
                          <Icon fontSize={24} />
                        </div>
                      )}
                      <span className="d-none d-md-block">{navItem.title}</span>
                    </div>
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <form className="form my-2 text-center ml-auto px-0">
            <button
              onClick={logout}
              className="btn btn-link"
              type="submit"
              data-test="logout-desktop"
              data-category={trackingCategory}
              data-action="click"
              data-label="logout"
            >
              Logout
            </button>
          </form>
        </div>
      </div>
    </nav>
  );
};

export default HeaderLegacy;
