import React from 'react';
import LeftChevronIcon from '../svgs/LeftChevronIcon';
import RightChevronIcon from '../svgs/RightChevronIcon';

export enum Orientation {
  LEFT,
  RIGHT,
}

interface ChevronButtonProps {
  disabled?: boolean;
  orientation: Orientation;
  className?: string;
  noPadding?: boolean;
  svgProps?: React.SVGAttributes<SVGElement>;
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
}

const ChevronButton: React.FunctionComponent<ChevronButtonProps> = ({
  orientation,
  onClick,
  className,
  disabled,
  svgProps,
}) => {
  const fill = disabled == null || !disabled ? '#000000' : '#e0e0e0';

  return (
    <span
      className={className}
      onClick={disabled ? e => e.preventDefault() : onClick}
      role="button"
    >
      {orientation === Orientation.LEFT ? (
        <LeftChevronIcon fill={fill} height="16" width="16" {...svgProps} />
      ) : (
        <RightChevronIcon fill={fill} height="16" width="16" {...svgProps} />
      )}
    </span>
  );
};

export default ChevronButton;
