import {
  Frequency,
  KeyValuePairOfInt32AndExtraRequest,
} from '../../app/shared/CoreClient';
import {
  hasExtras,
  extraContainsFortnightly,
} from '../../app/account-settings/components/MySubscriptions/FrequencyUtils';
import {
  UPDATE_SUBSCRIPTION_FREQUENCY,
  UPDATE_ALL_FREQUENCIES,
} from '../actions/accountSettingsActions/frequencyAction';

/* This file contains reducers that deal with localised state of
 *  the edit and add subscription forms, in the attempt to reduce
 *  repetitive logic. These are NOT associated with redux.
 *  Ideally, a descision will need to be made around the how these forms are managed,
 *  where these functions are removed as utilities.
 */

export const updateAllExtraFrequencies = (
  extras: Array<KeyValuePairOfInt32AndExtraRequest>,
  nextFrequency: Frequency
) => {
  const next: Array<KeyValuePairOfInt32AndExtraRequest> = extras.map(
    element => {
      if (element.value.frequency === Frequency.OneOff) {
        return element;
      }

      return {
        key: element.key,
        value: {
          ...element.value,
          frequency: nextFrequency,
        },
      };
    }
  );

  return next;
};

export const updateFrequency = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_FREQUENCY:
      return {
        ...state,
        isFreqDialogOpen: false,
        primaryFrequency: action.frequency,
      };
    case UPDATE_ALL_FREQUENCIES:
      return {
        ...state,
        primaryFrequency: action.frequency,
        isFreqDialogOpen: false,
        extras: state.extras
          ? updateAllExtraFrequencies(state.extras, action.frequency)
          : undefined,
      };
    default:
      return state;
  }
};

export const extraReducer = (state: any, action: any) => {
  const value: Frequency = Number(action.value);

  switch (value) {
    case Frequency.Weekly:
      if (extraContainsFortnightly(state.extras)) {
        return {
          ...state,
          isFreqDialogOpen: !state.isFreqDialogOpen,
        };
      }

      return state;
    case Frequency.Fortnightly:
      if (hasExtras(state.extras)) {
        return updateFrequency(state, {
          type: UPDATE_ALL_FREQUENCIES,
          frequency: Frequency.Fortnightly,
        });
      }

      return state;
    default:
      return state;
  }
};

export const frequencyReducer = (state: any, action: any) => {
  const value: Frequency = Number(action.value);

  switch (value) {
    case Frequency.Weekly:
    case Frequency.Fortnightly:
      return {
        ...state,
        ...extraReducer(state, action),
        primaryFrequency: value,
      };
    default:
      return state;
  }
};
