import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import CrossIcon from '../../../shared/components/CrossIcon';
import TrackingProps, {
  trackingCategory,
  trackingAction,
  trackingLabel,
} from '../../../shared/TrackingProps';
import {COLOR_CODE} from '../../../shared/constants';
import CalendarIcon from '../../../shared/components/CalendarIcon';
import AlertDiamondIcon from '../../../shared/components/AlertDiamondIcon';

export interface TabButtonProps {
  isSelected: boolean;
  isSkipped: boolean;
  className?: string;
  children: React.ReactNode;
  tracking?: TrackingProps;
  deliveryDate?: string;
  dayOfMonth?: string;
  isOrderDelivered?: boolean;
  onClick?(): void;
  hasOverrideDeliveryDate: boolean;
}

const baseButtonClasses = `btn d-flex square p-1 mr-3 ml-2 justify-content-center text-align-center text-nowrap position-relative border-bottom-0 mt-2 h-100`;

const UnstyledTabButton: React.FC<TabButtonProps> = ({
  className,
  isSelected,
  isSkipped: isSkipped,
  children,
  tracking,
  onClick,
  hasOverrideDeliveryDate,
}) => {
  const crossIconColor =
    isSelected && isSkipped ? COLOR_CODE.ERROR : COLOR_CODE.DARK_BROWN;

  const alertIconColor =
    isSelected && hasOverrideDeliveryDate
      ? COLOR_CODE.DARK_YELLOW
      : COLOR_CODE.DARK_BROWN;

  const selectedButtonColor = isSelected && 'bg-white';
  return (
    <button
      data-category={trackingCategory(tracking)}
      data-action={trackingAction(tracking)}
      data-label={trackingLabel(tracking)}
      className={classnames(baseButtonClasses, className, selectedButtonColor)}
      onClick={onClick}
      id="test"
    >
      {isSkipped && (
        <div className="position-absolute d-flex flex-row-reverse w-100 pt-1">
          <CrossIcon
            color={crossIconColor}
            width={13}
            height={13}
            className="mx-auto"
          />
        </div>
      )}
      {!isSkipped && hasOverrideDeliveryDate && (
        <div className="position-absolute d-flex flex-row-reverse w-100 pt-1">
          <AlertDiamondIcon
            color={alertIconColor}
            width={13}
            height={13}
            className="mx-auto"
          />
        </div>
      )}
      <div className="px-3 mt-3 pb-2">{children}</div>
    </button>
  );
};

export const TabButton = styled(UnstyledTabButton)`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border: 1px solid ${COLOR_CODE.TRANSLUCENT_GREEN};
  color: ${COLOR_CODE.DARK_BROWN};
  ${props =>
    props.isOrderDelivered &&
    !props.isSelected &&
    `
    border: 1px dashed ${COLOR_CODE.LIGHT_BROWN};
    background: ${COLOR_CODE.BARELY_THERE_BEIGE};
         `}

  :hover {
    color: ${COLOR_CODE.DARK_BROWN};
  }

  :before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 6px;
    left: -19px;
    transform: skew(8deg);
    border-bottom-right-radius: 20px;
    ${props =>
      props.isSelected &&
      props.isOrderDelivered &&
      `box-shadow: 6px 8px 0 ${COLOR_CODE.WHITE};
      border-bottom-right-radius: 20px;`}
    ${props =>
      props.isOrderDelivered &&
      !props.isSelected &&
      `border-bottom: 1px dashed ${COLOR_CODE.DARK_BROWN};
    box-shadow :6px 8px 0 ${COLOR_CODE.BARELY_THERE_BEIGE};
`}
      ${props =>
      props.isSelected &&
      !props.isOrderDelivered &&
      `box-shadow: 6px 8px 0 ${COLOR_CODE.WHITE};`};
  }

  :after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -20px;
    bottom: 4px;
    transform: rotate(88deg);
    border-bottom-right-radius: 20px;
    ${props =>
      props.isOrderDelivered &&
      !props.isSelected &&
      `border: 1px dashed ${COLOR_CODE.DARK_BROWN};
      border-width: 0 0px 1px 0;
      box-shadow: 0 10px 0 ${COLOR_CODE.BARELY_THERE_BEIGE};`}
    ${props =>
      props.isSelected &&
      !props.isOrderDelivered &&
      `box-shadow: 0px 8px 0 ${COLOR_CODE.WHITE};`}
        ${props =>
      props.isSelected &&
      props.isOrderDelivered &&
      `box-shadow: 0 10px 0 ${COLOR_CODE.WHITE};`}
  }
`;
