import React from 'react';
import {toast, ToastOptions, Slide} from 'react-toastify';
import classnames from 'classnames';
import {SvgCrossIcon} from '@mfb/lego';
import {Toast, ToastType} from './ToastMessages';

const commonOptions: ToastOptions = {
  position: 'top-center',
  transition: Slide,
  className: 'd-flex align-items-center small text-white',
  bodyClassName: 'mx-3',
  closeButton: (
    <SvgCrossIcon className="mr-3" fontSize="15" style={{opacity: 0.3}} />
  ),
  hideProgressBar: true,
  draggablePercent: 40,
};

export const getBgClassName = (type: ToastType): string => {
  switch (type) {
    case ToastType.Success:
      return 'bg-primary';
    case ToastType.Error:
      return 'bg-danger';
  }
};

export const showToast = (toastMessage: Toast) => {
  const {content, type} = toastMessage;

  const bgClassName = getBgClassName(type);

  const options: ToastOptions = {
    ...commonOptions,
    className: classnames(commonOptions.className as string, bgClassName),
  };

  return toast(<div data-test="toast-notification">{content}</div>, options);
};
