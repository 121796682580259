import {Time} from '../app/shared/CoreClient';
import {format as dateFormat} from 'date-fns';

const DECIMAL_RADIX = 10;

/**
 * Given a time format  convert it to a human readable string.
 * "18:00:00" => 6pm
 *
 * @param time "18:00:00"
 */
const format = (time: Time): string => {
  const [hours, mins, secs] = (time as string)
    .match(/(\d+)/g)
    .map(s => parseInt(s, DECIMAL_RADIX));
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(mins);
  date.setSeconds(secs);

  if (mins) {
    return dateFormat(date, 'h:maaa');
  } else {
    return dateFormat(date, 'haaa');
  }
};

export default format;
