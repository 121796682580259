import { decode } from 'html-entities';
import { ExtraQuantity, PYWStep } from '@mfb/cookbook';

export type ProductQueryParam = {
  sku: string;
  quantity: number;
};

export type RecipeQueryParam = {
  recipeNumber: string;
  quantity: number;
};

export interface PlanYourWeekQueryParams {
  subscriptionNumber?: string;
  week?: string;
  step?: PYWStep;
  scrollTo?: string;
  shouldOpenScrollTargetModal?: boolean;
  recipes?: Array<RecipeQueryParam>;
  extras?: Array<ProductQueryParam>;
  promoCode?: string;
}

export const getQueryParams = (): PlanYourWeekQueryParams => {
  const queryParams = new URLSearchParams(window.location.search);
  const subcriptionNumber = queryParams.get('subNumber');
  const week = queryParams.get('week');
  const step = queryParams.get('step');
  const scrollTo = queryParams.get('scrollTo');
  const shouldOpenScrollTargetModal = queryParams.get('openModal');
  const recipes = queryParams.getAll('recipes');
  const extraSkus = queryParams.getAll('skus');
  const promoCode = queryParams.get('promoCode');

  return {
    subscriptionNumber: subcriptionNumber
      ? decode(subcriptionNumber)
      : undefined,
    week: week ? decode(week) : undefined,
    step: step ? PYWStep[decode(step)] : undefined,
    scrollTo: scrollTo ? decode(scrollTo) : undefined,
    shouldOpenScrollTargetModal: shouldOpenScrollTargetModal
      ? shouldOpenScrollTargetModal.toLowerCase() === 'true'
      : undefined,
    recipes: parseRecipes(recipes),
    extras: parseProduct(extraSkus),
    promoCode: promoCode ? decode(promoCode) : undefined,
  };
};

const parseProduct = (recipes: string[]): Array<ProductQueryParam> => {
  return recipes.map((c) => {
    const [sku, quantity] = c.split('qty');
    return {
      sku,
      quantity: Number(quantity) || 1,
    };
  });
};

const parseRecipes = (recipes: string[]): Array<RecipeQueryParam> => {
  return recipes.map((c) => {
    const [recipeNumber, quantity] = c.split('qty');
    return {
      recipeNumber,
      quantity: Number(quantity) || 1,
    };
  });
};
