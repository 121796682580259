import React from 'react';
import Select from 'react-select';
import {getFrequencyOptions} from '../../account-settings/components/MySubscriptions/frequencySelectOptionsBuilder';
import {isUndefined, some} from 'lodash';
import {Frequency} from '../CoreClient';

interface Props {
  /* Determines if the error message should be shown or not */
  isValid: boolean;

  /* The message to be displayed if there is an error */
  validationMessage: string;

  /* (OPTIONAL) Defaults to 'primaryFrequency' */
  fieldName?: string;

  /* (OPTIONAL) The default value to be set on the component */
  value?: number;

  /* (OPTIONAL) Placeholder text for the select field. Defaults
    to 'Select a frequency for your deliveries...'  */
  placeholder?: string;

  /* (OPTIONAL) Determines if the input field is required or not  */
  required?: boolean;

  /* (OPTIONAL) Defaults the value to Frequency[0] if the value doesnt exist in the
  option set. (for one-offs)  */
  defaultIfNotExist?: boolean;

  /* The change event on the select field. Returns the selected option and fieldName
  that has changed to the parent.   */
  onChange(selectOption: Select.Option, fieldName: string): void;
}

const doesDefaultExist = (
  availableOptions: Array<Select.Option>,
  defaultVal: number
): boolean => {
  if (defaultVal) {
    return some(
      availableOptions,
      (option: Select.Option) => option.value === defaultVal.toString()
    );
  }

  return false;
};

const FrequencySelect: React.FunctionComponent<Props> = (props: Props) => {
  const onChange = (opt: Select.Option | Array<Select.Option>) => {
    let fieldName = props.fieldName;
    if (!props.fieldName) {
      fieldName = 'primaryFrequency';
    }
    props.onChange(opt as Select.Option, fieldName);
  };

  const frequencyOptions = getFrequencyOptions();
  const defaultExists = doesDefaultExist(frequencyOptions, props.value);
  const defaultValue =
    !isUndefined(props.defaultIfNotExist) && props.defaultIfNotExist
      ? {value: Frequency.Weekly, label: Frequency[Frequency.Weekly]}
      : null;

  return (
    <div className="form-group">
      <label htmlFor={props.fieldName}>Frequency</label>
      <Select
        placeholder={
          props.placeholder || 'Select a frequency for your deliveries...'
        }
        options={frequencyOptions}
        className="test-choose-frequency"
        clearable={false}
        searchable={false}
        required={isUndefined(props.required) ? true : props.required}
        value={
          defaultExists
            ? {label: Frequency[props.value], value: props.value}
            : defaultValue
        }
        name={props.fieldName}
        onChange={onChange}
      />
      {!props.isValid && (
        <span className="form-control-feedback">{props.validationMessage}</span>
      )}
    </div>
  );
};

export default FrequencySelect;
