import {Action, Reducer} from 'redux';
import {PageState, defaultPageState} from '../state';
import {
  RECEIVE_PAGE_STATE,
  ReceivePageStateAction,
  REQUEST_PAGE_STATE,
  RequestPageStateAction,
} from '../actions/sharedActions/pageStateAction';
import {isUndefined} from 'lodash';

const defaultState: PageState = defaultPageState;

const reducer: Reducer<PageState> = (
  state: PageState = defaultState,
  action: Action
): PageState => {
  switch (action.type) {
    case REQUEST_PAGE_STATE:
      const requestTime = (action as RequestPageStateAction).requestTime;

      return {...state, requestTime};
    case RECEIVE_PAGE_STATE:
      const payload = (action as ReceivePageStateAction).payload;
      if (
        payload.requestTime >= state.requestTime ||
        isUndefined(state.requestTime)
      ) {
        return payload;
      }

      return state;
    default:
      return state;
  }
};

export default reducer;
