import React from 'react';
import routeHistory from '../../../../routerHistory';
import { deliveriesPath } from '../../../account-settings/components/Nav/paths';
import styled from 'styled-components';
import { Button } from '@mfb/lego';
import { mapDispatchToProps } from 'app/account-settings/containers/accountSettingsContainer';
import { connect } from 'react-redux';
import { AppState, CampaignState } from 'redux/state';
import { CampaignTheme } from '@mfb/account-api-client';

const StyledCampaignPromotionBanner = styled.div`
  background-color: ${(c) => c.theme.colors.brand[100]};
  color: ${(c) => c.theme.colors.brand[800]};
  place-content: center;
  text-justify: center;
  display: flex;
  padding: ${(c) => c.theme.spacing.large} ${(c) => c.theme.spacing.xxlarge};

  @media ${(p) => p.theme.layout.deviceMediaQueries.max.sm} {
    flex-direction: column;
    padding: ${(c) => c.theme.spacing.xxlarge};
  }
`;

const StyledDescriptionSection = styled.div`
  text-align: start;
  place-self: center;
  margin-right: ${(c) => c.theme.spacing.xxlarge};
  @media ${(p) => p.theme.layout.deviceMediaQueries.max.sm} {
    margin-right: ${(c) => c.theme.spacing.none};
    margin-bottom: ${(c) => c.theme.spacing.large};
  }
`;

const StyledTitle = styled.span`
  font-weight: ${(c) => c.theme.typography.fontWeight[700]};
`;

const StyledDesktopJoinButton = styled(Button)`
  place-self: center;
  @media ${(p) => p.theme.layout.deviceMediaQueries.max.sm} {
    display: none;
  }
`;

const StyledMobileJoinButton = styled.button`
  width: 100%;
  background-color: transparent;
  padding: ${(c) => c.theme.spacing.small};
  border: 1px solid ${(c) => c.theme.colors.brand[800]};
  border-radius: ${(c) => c.theme.radii.small};
  color: ${(c) => c.theme.colors.brand[800]};
  @media ${(p) => p.theme.layout.deviceMediaQueries.max.sm} {
    display: block;
  }
  display: none;
`;

const CampaignPromotionBannerUnconnected: React.FC = ({
  campaignProgress,
}: {
  campaignProgress: CampaignState;
}) => {
  const campaign = campaignProgress ? campaignProgress.eligibleCampaignDto : null;

  const ctaOnClick = React.useCallback(() => {
    // @TODO Please use navman.campaignModal() when we rebase.
    routeHistory.history.push(
      `${deliveriesPath}/?campaignCode=${campaign.campaignCode}`
    );
    // @TODO John: GA Tracking
  }, [campaign]);

  //User is already enrolled in a campaign or is incorrect theme.
  if(campaignProgress.enrolledCampaignDto != null || 
    (campaign &&
      campaignProgress.eligibleCampaignDto.theme !== CampaignTheme.Standard)){
    return null;
  }
  return (
    (campaign && campaign.joinTitle && campaign.joinDescription && campaign.campaignCode && (
      <StyledCampaignPromotionBanner>
        <StyledDescriptionSection>
          <StyledTitle>{campaign.joinTitle}. </StyledTitle>
          {campaign.joinDescription}
        </StyledDescriptionSection>

        <StyledMobileJoinButton
          onClick={ctaOnClick}
          data-testid="join_journey_banner_button_mobile"
        >
          <span>Join</span>
        </StyledMobileJoinButton>
        <StyledDesktopJoinButton
          label="Join"
          size="md"
          onClick={ctaOnClick}
          data-testid="join_journey_banner_button_desktop"
        />
      </StyledCampaignPromotionBanner>
    )) ||
    null
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    campaignProgress: state.campaign,
  };
};

export const CampaignPromotionBanner = connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignPromotionBannerUnconnected);
