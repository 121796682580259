import React from 'react';
import Modal from 'react-modal';
import {FormGroup, CustomInput} from 'reactstrap';
import {Frequency} from '../../shared/CoreClient';

export interface BagInformation {
  name: string;
  id: number;
  frequency: Frequency;
  subscriptionNumber?: string;
}

interface Props {
  subscriptions: Array<BagInformation>;
  sku?: string;
  onClick(subscriptionId: number, sku?: string): void;
}

const SubscriptionSelect: React.FunctionComponent<Props> = ({
  onClick,
  subscriptions,
  sku,
}) => (
  <Modal
    isOpen={true}
    className="mfb-Modal"
    overlayClassName="mfb-ModalOverlay"
  >
    <h2>Select a Subscription</h2>
    <FormGroup>
      <div className="list-group pt-3">
        {subscriptions.map(sub => (
          <div
            key={`list-grp-${sub.id}`}
            className="list-group-item px-md-5"
            style={{cursor: 'pointer'}}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClick(sub.id, sku);
            }}
          >
            <CustomInput
              className="py-2"
              type="radio"
              id={sub.id}
              name={`radio-${sub.id}`}
              label={`${sub.name} (${Frequency[sub.frequency]})`}
            />
          </div>
        ))}
      </div>
    </FormGroup>
  </Modal>
);

export default SubscriptionSelect;
