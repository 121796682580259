import { ThunkAction } from 'redux-thunk';
import { globalSettings as settings } from 'config';
import { Action } from 'redux';
import { AppState, CampaignState } from '../../state';
import { CampaignClient } from 'app/shared/CoreClient';
import { accountApiClient } from 'app/shared/api';
import _ from 'lodash';
import { CampaignTheme } from '@mfb/account-api-client';


export const RECEIVE_CAMPAIGN_STATE = 'RECEIVE_CAMPAIGN_STATE';

export interface ReceiveCampaignStateAction extends Action {
  type: typeof RECEIVE_CAMPAIGN_STATE;
  payload: CampaignState;
}

const receiveCampaignStateActionCreator = (
  campaignProgress: CampaignState
): ReceiveCampaignStateAction => ({
  type: RECEIVE_CAMPAIGN_STATE,
  payload: campaignProgress,
});

export const fetchCampaignState = (): ThunkAction<
  Promise<ReceiveCampaignStateAction>,
  AppState,
  never
> => {
  return async (dispatch) => {
    const campaignProgression = await accountApiClient.campaignEnrolled();
    const enrolledCampaign = _.first(_.sortBy(campaignProgression, [function(x) { return x.enrolmentStart; }]))

    const eligibleCampaigns = await accountApiClient.campaignEligible();
    const eligibleCampaign = _.first(eligibleCampaigns)

    try {
      return dispatch(
        receiveCampaignStateActionCreator(
          {enrolledCampaignDto: enrolledCampaign, eligibleCampaignDto: eligibleCampaign} as CampaignState
        )
      );
    } catch (err) {
      throw err;
    }
  };
};

export type CampaignProgressStateAction = ReceiveCampaignStateAction | Action;
