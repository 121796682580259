import React from 'react';
import {SvgCalendarIcon} from '@mfb/lego';
import classnames from 'classnames';
import {NavLink} from 'react-router-dom';
import {calendarPath} from '../../../account-settings/components/Nav/paths';
import {COLOR_CODE} from '../../../shared/constants';

interface Props {
  className?: string;
}

export const DeliveryCalendar: React.FC<Props> = ({className}) => (
  // only shows when viewport is larger than lg
  <NavLink
    data-category="delivery"
    data-action="view-delivery-calendar"
    data-test="view-delivery-calendar"
    data-label=""
    to={calendarPath}
    className={classnames(
      'mfb-Link--unstyled d-none d-lg-block align-items-center track-Button-viewCalendar',
      className
    )}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyItems: 'center',
        alignItems: 'flex-end',
      }}
    >
      <SvgCalendarIcon
        color={COLOR_CODE.DARK_BROWN}
        className="pb-1"
        style={{alignSelf: 'center'}}
      />
      <span
        style={{color: COLOR_CODE.DARK_BROWN}}
        className="w-100 pl-2 text-nowrap"
      >
        Delivery Calendar
      </span>
    </div>
  </NavLink>
);
