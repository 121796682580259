import React from 'react';
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker';
import {CustomHeader} from './CustomHeader';

const LOCALE = 'enGB';
const DATE_FORMAT = 'EEE dd MMM, yyyy';
const MAX_DATE = new Date(2022, 1, 28);

interface Props {
  startDate: Date;
  endDate: Date;
  minDate: Date;
  disabled?: boolean;
  filterDate?(date: Date): boolean;
  startDateOptions?: Partial<ReactDatePickerProps>;
  endDateOptions?: Partial<ReactDatePickerProps>;
  onStartDateChange(date: Date): void;
  onEndDateChange(date: Date): void;
}

export const DateRangePicker: React.FC<Props> = props => {
  const {
    startDate,
    endDate,
    minDate,
    disabled,
    filterDate,
    onStartDateChange,
    onEndDateChange,
  } = props;

  const handleEndChange = async (date: Date) => {
    onEndDateChange(date);
  };

  const handleStartChange = async (date: Date) => {
    onStartDateChange(date ? date : undefined);
    //if this puts the end date in the past, set end date to start date
    if (endDate && date > endDate) {
      await handleEndChange(date);
    }
  };

  return (
    <>
      <div className="d-flex flex-wrap">
        <div className="d-inline mt-4 mr-4">
          <span className="d-block mb-2">From</span>
          <ReactDatePicker
            renderCustomHeader={CustomHeader}
            filterDate={filterDate}
            dateFormat={DATE_FORMAT}
            locale={LOCALE}
            className="form-control"
            selected={startDate}
            minDate={minDate}
            maxDate={MAX_DATE}
            selectsStart
            onChange={handleStartChange}
            disabled={disabled}
          />
        </div>

        <div className="d-inline mt-4">
          <span className="d-block mb-2">To</span>
          <ReactDatePicker
            renderCustomHeader={CustomHeader}
            filterDate={filterDate}
            dateFormat={DATE_FORMAT}
            locale={LOCALE}
            className="form-control"
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate || minDate}
            maxDate={MAX_DATE}
            onChange={handleEndChange}
            selectsEnd
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};
