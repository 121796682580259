import createUserManager from '../../../shared/UserManager';

declare let dataLayer: Array<any>;

export const sendErrorPageEvent = async (): Promise<void> => {
  const userMgr = await createUserManager();
  const customerNumber = await userMgr.getCustomerNumber();

  dataLayer.push({
    event: 'holiday-address-error-page',
    user_id: customerNumber,
  });
};

const postCodeRegex = /\d{4}$/;

export const sendInvalidAddressEvent = async (
  address: string
): Promise<void> => {
  const userMgr = await createUserManager();
  const customerNumber = await userMgr.getCustomerNumber();

  dataLayer.push({
    event: 'holiday-address-invalid-address',
    postCode: postCodeRegex.exec(address)[0],
    user_id: customerNumber,
  });
};
