import React from 'react';
import Icon, {IconProps} from './Icon';
import classNames from 'classnames';

class AccountSettingsIcon extends Icon {
  static defaultProps: Partial<IconProps> = {
    height: 13,
    seasonal: true,
    title: 'Account Settings',
    width: 13,
  };

  renderIcon() {
    const {color, seasonal, title} = this.props;

    return (
      <g>
        <title>{title}</title>
        <path
          className={classNames({seasonal})}
          d="M6.5 8.134c2.166 0 6.5 1.065 6.5 3.231V13H0v-1.635c0-2.167 4.334-3.23 6.5-3.23zm0-1.634a3.228 3.228 0 0 1-3.231-3.231C3.269 1.482 4.713 0 6.499 0 8.287 0 9.73 1.482 9.73 3.269a3.228 3.228 0 0 1-3.23 3.23z"
          fillRule="nonzero"
          fill={color}
        />
      </g>
    );
  }
}

export default AccountSettingsIcon;
