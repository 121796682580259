import {Action} from 'redux';
export const FETCH_BEGIN = 'shared/FETCH_BEGIN';
export interface FetchBeginAction extends Action {
  type: 'shared/FETCH_BEGIN';
}
type FetchBeginFunc = () => FetchBeginAction;
export const fetchStart: FetchBeginFunc = () => ({
  type: FETCH_BEGIN,
});

export const FETCH_END = 'shared/FETCH_END';
export interface FetchEndAction extends Action {
  type: 'shared/FETCH_END';
}
type FetchEndFunc = () => FetchEndAction;
export const fetchEnd: FetchEndFunc = () => ({
  type: FETCH_END,
});

export const FETCH_ERROR = 'shared/FETCH_ERROR';
export interface FetchErrorAction extends Action {
  type: 'shared/FETCH_ERROR';
}
type FetchErrorFunc = () => FetchErrorAction;
export const fetchError: FetchErrorFunc = () => ({
  type: FETCH_ERROR,
});
