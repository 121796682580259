import {isEmpty} from 'lodash';
import React from 'react';
import {
  deliveriesPath,
  rootPath,
} from '../../account-settings/components/Nav/paths';
import {CustomerClient} from '../CoreClient';
import { globalSettings as settings } from 'config';
import createUserManager from '../UserManager';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CustomerRedirect = () => {
  React.useEffect(() => {
    (async () => {
      const userManager = await createUserManager();
      const queryParams = new URLSearchParams(window.location.search);
      const queryParamCustomerNumber = queryParams.get('customerNumber');
      const customerClient = new CustomerClient(settings.bffHost);
      const customerNumber = await customerClient.getCustomerNumber();
      if (customerNumber === queryParamCustomerNumber) {
        window.location.href = deliveriesPath;
      } else if (isEmpty(customerNumber)) {
        window.location.href = rootPath;
      } else {
        await userManager.handleCustomerRedirectLogout();
      }
    })();
  }, []);

  return null;
};
