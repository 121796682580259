import { accountApiClient } from 'app/shared/api';
import { asUtcString, getDifferenceInDays } from 'app/shared/helper';
import { firstOrDefault, isOrderValidForActions } from 'app/shared/helper';
import { globalSettings as settings } from 'config';
import { format } from 'date-fns';
import { formatToNzTimeZone } from 'lib/date/dateFormat';
import { SetStateAction } from 'react';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { SessionStorageKey, writeToSessionStorage } from 'redux/sessionStorage';
import {
  FiltersResponse,
  IOrderWithRecipesAndExtras,
} from '@mfb/account-api-client';
import {
  CartBag,
  CartMetaExtra,
  CartMetaRecipe,
  PlanYourWeek as CookbookPlanYourWeek,
  CookbookStrategyContext,
  ExtraQuantity,
  Filter,
  PYWStep,
  PlanYourWeekMode,
} from '@mfb/cookbook';
import {
  AnalyticParams,
  GLOBAL_SCOPES,
  LegoScopesProvider,
  RecipeSelectionMode,
  useCustomerMessageContext,
} from '@mfb/lego';
import { AlertStatus } from '@mfb/lego';
import { navman } from '../../navigator';
import { ReceiveTrackingAction } from '../../redux/actions/sharedActions/trackingAction';
import {
  AppState,
  CustomerMessageBulkGroupState,
  OrdersPageState,
  TrackingState,
} from '../../redux/state';
import { AccountCookbook } from '../shared/AccountCookbook';
import {
  AccountFeatures,
  AccountUpdatePlanYourWeekRequestBody,
  AvailableExtrasResponse,
  AvailableFrequency,
  BenefitDto,
  ChoiceSelectionsRequestBody,
  DeliveryDateResponse as DeliveryDateResponseLegacy,
  ExtraClient,
  ExtrasItemRequestBody,
  Frequency,
  Frequency2,
  OrderWithRecipesAndExtras,
  RecipeSelectionMode2,
  SubscriptionClient,
} from '../shared/CoreClient';
import Spinner from '../shared/components/Spinner';
import {
  AlertToastContextProps,
  getFormattedTimeSlot,
  useAlertToastContext,
  useEditDeliveryDetailsService,
} from '../shared/hooks';
import { showBadToast, showGoodToast } from '../toast/ToastMessages';
import { handlePlanYourWeekCustomerMessage } from './PlanYourWeekCustomerMessage';
import { getQueryParams } from './PlanYourWeekQueryParams';
import {
  addExtrasFromQueryParam,
  addExtrasPromoExtras as addPromoExtras,
  addRecipesFromQueryParam,
  replaceSelectedExtrasWithSessionStorage,
  replaceSelectedRecipesWithSessionStorage,
} from './PlanYourWeekSelectionService';
import { useAddressPreview } from './useAddressPreview';

type Recipe = CartMetaRecipe;
type Extra = CartMetaExtra;

interface StoreProps {
  sku: string;
  subscriptionNumber: string;
  weekStarting: Date;
  frequency: AvailableFrequency;
  primaryProduct: CartBag;
  selectedExtras: Array<Extra>;
  selectedRecipes: Array<Recipe> | undefined;
  availableRecipes: Array<Recipe> | undefined;
  deliveryDate: DeliveryDateResponseLegacy;
  nextAvailableDeliveryDate: Date;
  isSkipped: boolean;
  promo?: BenefitDto;
  promoCodeQueryParam?: string;
  appliedPromoCode?: string;
  enableFlexUp: boolean;
  enableFlexDown: boolean;
  baseSubscriptionNumberOfNights?: number;
  customerMessageGroupState: Array<CustomerMessageBulkGroupState>;
  trackingState: TrackingState;
}

interface ComponentProps {
  step?: PYWStep;
  scrollTo?: string;
  shouldOpenScrollTargetModal?: boolean;
  extraSkusToAdd?: Array<ExtraQuantity>;
  targetWeek: string;
  subNumber: string;
  features?: AccountFeatures;
  order?: OrderWithRecipesAndExtras;
  fetchDeliveries?: () => Promise<void>;
  fetchTracking?: () => Promise<ReceiveTrackingAction>;
  trackingState?: TrackingState;
  defaultNumberOfNights?: number;
}

const savePlanYourWeekChanges = async (
  sku: string,
  subscriptionNumber: string,
  weekStarting: Date,
  recipes: Array<Recipe>,
  extras: Array<Extra>,
  promoCode?: string,
  updateToast?: (value: SetStateAction<AlertToastContextProps>) => void
) => {
  const startWeek = new Date(weekStarting);
  const recipeSelections: Array<ChoiceSelectionsRequestBody> = recipes.map(
    (r) => ({
      categoryCode: r.categoryCode,
      quantity: r.quantity,
    })
  );

  const extraSelections: Array<ExtrasItemRequestBody> = extras.map((e) => ({
    sku: e.sku,
    quantity: e.quantity,
    frequency: firstOrDefault([e.frequency], Frequency.Weekly).valueOf(),
    deliveryWeek: new Date(weekStarting),
    addOnToRecipeNumbers: e.addOnToRecipeNumbers,
  }));

  const request: AccountUpdatePlanYourWeekRequestBody = {
    sku: sku,
    extras: extraSelections,
    selections: recipeSelections,
    promoCode: promoCode,
  };

  const client = new SubscriptionClient(settings.bffHost);
  const response = await client.subscriptionPlanYourWeekUpdate(
    subscriptionNumber,
    formatToNzTimeZone(startWeek),
    request
  );

  if (response.succeeded) {
    updateToast({
      status: AlertStatus.success,
      isOpen: true,
      text: 'Your delivery has been saved 🎉',
    });
    navman.yourAccount();
  } else {
    updateToast({
      status: AlertStatus.error,
      isOpen: true,
      text: 'Something went wrong. Please try again.',
    });
  }
};

export type PlanYourWeekProps = ComponentProps & StoreProps;

export const PlanYourWeekPageUnconnected: React.FC<PlanYourWeekProps> = (
  props
) => {
  const [isLoadingExtras, setIsLoadingExtras] = React.useState(true);
  const [isLoadingFilters, setIsLoadingFilters] = React.useState(true);

  const { updateToast } = useAlertToastContext();

  const [availableExtrasResponse, setAvailableExtrasResponse] =
    React.useState<AvailableExtrasResponse>(null);
  const [filtersResponse, setFiltersResponse] =
    React.useState<FiltersResponse>(null);
  const overrideExits = React.useRef<{
    meals: () => void;
    kitchen: () => void;
  }>({
    meals: undefined,
    kitchen: undefined,
  });
  const customerMessageContext = useCustomerMessageContext();

  const { addressPreviewState, fetchAddressPreview } = useAddressPreview(
    props.order as unknown as IOrderWithRecipesAndExtras
  );

  const { displayEditDelivery } = useEditDeliveryDetailsService({
    currentOrder: props.order,
    deliveryWeek: props.order?.deliveryDate?.weekStarting?.toString(),
    tracking: props.trackingState,
    fetchTracking: props.fetchTracking,
    postSuccessAction: async () => await fetchAddressPreview(true),
  });

  //key used to read from session storage and get selection data
  const sessionStorageSelectionKey = {
    storage: SessionStorageKey.PLAN_YOUR_WEEK_SELECTION,
  };

  const validPromoCodeQueryParam =
    props.promoCodeQueryParam && props.promo ? props.promoCodeQueryParam : null;

  useEffect(() => {
    const extraClient = new ExtraClient(settings.bffHost);
    extraClient
      .getAvailableExtrasBySku(
        props.sku,
        format(new Date(props.deliveryDate.weekStarting), 'yyyy-MM-dd'),
        props.appliedPromoCode || validPromoCodeQueryParam
      )
      .then(setAvailableExtrasResponse)
      .then(() => setIsLoadingExtras(false));
    accountApiClient
      .recipesFiltered(props.sku, new Date(props.weekStarting))
      .then(setFiltersResponse)
      .then(() => setIsLoadingFilters(false));
  }, [props.sku]);

  const availableExtras: Array<Extra> = React.useMemo(() => {
    const extrasResponse =
      availableExtrasResponse &&
      availableExtrasResponse.extras.map((e) => ({
        ...e,
        name: e.name || '',
        price: e.totalPrice,
        quantity: 0,
        frequency: firstOrDefault(
          e.availableFrequencies,
          Frequency2.Weekly
        ).valueOf(),
        isPromotional: false,
      }));
    return extrasResponse;
  }, [availableExtrasResponse]);

  const unableToEditRecipes =
    props.primaryProduct.recipeSelectionMode === RecipeSelectionMode.None;

  const scopeData: AnalyticParams = {
    bag_sku: props.primaryProduct.sku,
    bag_name: props.primaryProduct.name,
    delivery_week: formatToNzTimeZone(props.weekStarting),
    subscription_number: props.subscriptionNumber,
  };

  const trackingMetaData = props.trackingState?.subscriptions?.find(
    (c) => c.subscriptionNumber === props.subscriptionNumber
  )?.metadata;

  if (!isLoadingExtras && !isLoadingFilters && availableExtras) {
    // add recipes from query params & session storage
    replaceSelectedRecipesWithSessionStorage(
      sessionStorageSelectionKey,
      props.availableRecipes,
      props.selectedRecipes
    );
    addRecipesFromQueryParam(props.availableRecipes, props.selectedRecipes);

    //add extras from query params & session storage
    // TODO: Due to array mutation we need to extract the promos first, session will overwrite.
    const promoExtras = props.selectedExtras.filter(extra => extra.isPromotional);
    replaceSelectedExtrasWithSessionStorage(
      sessionStorageSelectionKey,
      availableExtras,
      props.selectedExtras
    );
    addExtrasFromQueryParam(availableExtras, props.selectedExtras);
    addPromoExtras(promoExtras, props.selectedExtras);
  }

  const getTrackingMetaData = (): AnalyticParams => {
    try {
      const { order, trackingState } = props;
      const metadata = trackingState.subscriptions?.find(
        (c) => c.subscriptionNumber === props.subscriptionNumber
      )?.metadata;

      return {
        promo_group_code: props.appliedPromoCode ?? undefined,
        promo_code: props.promoCodeQueryParam ?? undefined,
        delivery_week: asUtcString(order.deliveryDate?.weekStarting),
        days_until_customer_delivery: getDifferenceInDays(
          order.deliveryDate?.actualDeliveryDate
        ),
        days_until_delivery_lock: getDifferenceInDays(
          order.orderState?.canSkip?.effectiveUntilActual ??
            order.orderState?.canUnskip.effectiveUntilActual
        ),
        ...metadata,
      };
    } catch (e) {
      console.error('PlanYourWeekPage', 'buildBaseTrackingData()', e);
      return {};
    }
  };

  const getPlanYourWeekMode = (): PlanYourWeekMode => {
    const canOnlyAddExtras =
      isOrderValidForActions(props.order, ['canAddExtras']) &&
      !isOrderValidForActions(props.order, ['canChangeMeals']);

    if (canOnlyAddExtras) {
      return PlanYourWeekMode.Extras;
    }

    return PlanYourWeekMode.Default;
  };

  const onEditDeliveryDetails = useCallback(async () => {
    await displayEditDelivery();
  }, [displayEditDelivery]);

  return (
    <CookbookStrategyContext.Provider value={new AccountCookbook()}>
      {isLoadingExtras || isLoadingFilters ? (
        <Spinner />
      ) : (
        <LegoScopesProvider
          scopeData={scopeData}
          targetScope={GLOBAL_SCOPES.PYW}
          replaceScope
        >
          <CookbookPlanYourWeek
            mode={getPlanYourWeekMode()}
            subscriptionNumber={props.subscriptionNumber}
            primaryProduct={props.primaryProduct}
            selectedRecipes={unableToEditRecipes ? [] : props.selectedRecipes}
            availableRecipes={unableToEditRecipes ? [] : props.availableRecipes}
            selectedExtras={props.selectedExtras}
            availableExtras={availableExtras}
            weekStarting={props.weekStarting}
            actualDeliveryDate={new Date(props.deliveryDate.actualDeliveryDate)}
            deliveryTimeRange={`${props.deliveryDate.actualDeliveryStartTime}-${props.deliveryDate.actualDeliveryEndTime}`}
            nextDeliveryDate={props.nextAvailableDeliveryDate}
            productCollections={
              availableExtrasResponse &&
              availableExtrasResponse.extraProductGroups
            }
            isSkipped={props.isSkipped}
            onMountOptions={{
              step: props.step,
              scrollTarget: props.scrollTo
                ? {
                    id: props.scrollTo,
                    openModal: props.shouldOpenScrollTargetModal,
                  }
                : undefined,
              promoCodeQueryParam: props.promoCodeQueryParam,
              appliedPromoCode: props.appliedPromoCode,
            }}
            disableRecipeSelection={unableToEditRecipes}
            enableFlexUp={props.enableFlexUp}
            enableFlexDown={props.enableFlexDown}
            saveButtonTextOverride={'Save Delivery'}
            baseSubscriptionNumberOfNights={
              props.baseSubscriptionNumberOfNights
            }
            // eslint-disable-next-line @typescript-eslint/require-await
            onSaveAsync={async (sku, recipes, extras) =>
              savePlanYourWeekChanges(
                sku,
                props.subscriptionNumber,
                props.deliveryDate.weekStarting,
                props.primaryProduct.recipeSelectionMode ==
                  RecipeSelectionMode.None
                  ? []
                  : recipes,
                extras,
                validPromoCodeQueryParam,
                updateToast
              )
            }
            onCancel={navman.yourAccount}
            onLogoClick={navman.yourAccount}
            recipeFilters={
              filtersResponse &&
              filtersResponse.recipes.map((f) => {
                return {
                  ...f,
                  //We can allow certain filters to be active on entering into PYW here.
                  applied: false,
                } as Filter;
              })
            }
            extraFilters={
              filtersResponse &&
              filtersResponse.products.map((f) => {
                return {
                  ...f,
                  applied: false,
                } as Filter;
              })
            }
            onSelection={(r, e) =>
              //save selection data to session storage
              writeToSessionStorage(sessionStorageSelectionKey, {
                recipes: r,
                extras: e,
              })
            }
            onStepChange={(newStep) => {
              handlePlanYourWeekCustomerMessage(
                newStep,
                customerMessageContext,
                props.customerMessageGroupState,
                overrideExits,
                props.subscriptionNumber,
                props.deliveryDate,
                props.sku,
                trackingMetaData
              );
            }}
            overrideExits={overrideExits}
            defaultNumberOfNights={props.defaultNumberOfNights}
            addressPreview={{
              heading: addressPreviewState.timeslot,
              body: addressPreviewState.address,
              onEdit: onEditDeliveryDetails,
              isLoading: addressPreviewState.isLoading,
            }}
            trackingMetaData={getTrackingMetaData()}
            openCmpOnLoad={() => {
              handlePlanYourWeekCustomerMessage(
                props.step || PYWStep.meals,
                customerMessageContext,
                props.customerMessageGroupState,
                overrideExits,
                props.subscriptionNumber,
                props.deliveryDate,
                props.sku,
                trackingMetaData
              );
            }}
          />
        </LegoScopesProvider>
      )}
    </CookbookStrategyContext.Provider>
  );
};

export const mapStateToProps = (
  appState: AppState<OrdersPageState>,
  ownProps?: ComponentProps
): PlanYourWeekProps => {
  const queryParams = getQueryParams();
  const { orders, promo, subscriptions } = appState.pageState;
  const { enableFlexUp, enableFlexDown } = appState.features;

  const nextAvailableDeliveryDate = new Date(
    appState.pageState.nextAvailableDeliveryDate
  );

  const currentDelivery = orders.find(
    ({ deliveryDate, subscriptionNumber }) => {
      const weekStarting = formatToNzTimeZone(deliveryDate.weekStarting, 'W');

      return (
        weekStarting == ownProps.targetWeek &&
        subscriptionNumber == ownProps.subNumber
      );
    }
  );

  const primaryLine = currentDelivery.deliveryLines.find(
    (dl) => dl.deliveryLineType === 'PRODUCT'
  );

  const hasSingleSelectionMode =
    primaryLine.recipeSelectionMode === RecipeSelectionMode2.Single;

  const appliedPromoGroupCode =
    currentDelivery.pricing.appliedDiscount &&
    currentDelivery.pricing.appliedDiscount.hasDiscountApplied
      ? currentDelivery.pricing.appliedDiscount.promoGroupCode
      : null;

  const subscription =
    subscriptions &&
    subscriptions.find((s) => s.subscriptionNumber == ownProps.subNumber);

  return {
    features: appState.features,
    sku: primaryLine.sku,
    subscriptionNumber: ownProps.subNumber,
    deliveryDate: currentDelivery.deliveryDate,
    weekStarting: new Date(ownProps.targetWeek.substring(1)),
    selectedExtras: currentDelivery.selectedExtras.map((e) => ({
      ...e,
      sku: e.sku || '',
      name: e.lineDescription || '',
      frequency: e.extraFrequency.valueOf(),
    })),
    selectedRecipes: currentDelivery.selectedRecipes.map((r) => ({
      ...r,
      maximumQuantity: r.maximumQuantity
        ? r.maximumQuantity
        : hasSingleSelectionMode
          ? 1
          : undefined,
    })),
    availableRecipes: currentDelivery.availableRecipes.map((r) => ({
      ...r,
      maximumQuantity: r.maximumQuantity
        ? r.maximumQuantity
        : hasSingleSelectionMode
          ? 1
          : undefined,
    })),
    primaryProduct: {
      ...primaryLine,
      sku: (primaryLine.isFlexed ? primaryLine.baseSku : primaryLine.sku) || '',
      recipeSelectionMode: primaryLine.recipeSelectionMode.valueOf(),
      name: primaryLine.lineDescription,
      requiredSelectionQuantity: primaryLine.requiredSelectionQuantity,
    },
    frequency: currentDelivery.availableFrequency,
    isSkipped: currentDelivery.isSkipped,
    promo: promo,
    promoCodeQueryParam: queryParams.promoCode,
    appliedPromoCode: appliedPromoGroupCode,
    nextAvailableDeliveryDate,
    step: queryParams.step,
    scrollTo: queryParams.scrollTo,
    shouldOpenScrollTargetModal: queryParams.shouldOpenScrollTargetModal,
    extraSkusToAdd: queryParams.extras,
    targetWeek: ownProps.targetWeek,
    subNumber: ownProps.subNumber,
    enableFlexUp: enableFlexUp,
    enableFlexDown: enableFlexDown,
    baseSubscriptionNumberOfNights:
      subscription && subscription.primarySkuNumberOfNights,
    customerMessageGroupState: appState.customerMessageBulkGroupState,
    trackingState: appState.tracking,
    defaultNumberOfNights: appState.subscriptions?.find(
      (c) => c.subscriptionNumber === currentDelivery.subscriptionNumber
    )?.primarySkuNumberOfNights,
  };
};

export const PlanYourWeekPage = connect(mapStateToProps)(
  PlanYourWeekPageUnconnected
);
