/* eslint-disable react/no-deprecated */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import Wormhole from 'react-wormhole-hoc';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import LinkButton from '../LinkButton';
import LeftChevronIcon from '../../svgs/LeftChevronIcon';
import Button from '../Button';
import isStickyElementStuck from '../../isStickyElementStuck';
import TrackingProps from '../../TrackingProps';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;

export const stickyHeaderWormhole = new Wormhole({});

export interface StickyHeaderProps {
  hiddenUp?: Breakpoint;
  backLabel?: string;
  backClassName?: string;
  backDisabled?: boolean;
  backSubmitting?: boolean;
  backTracking?: TrackingProps;
  actionLabel?: string;
  actionClassName?: string;
  actionDisabled?: boolean;
  actionSubmitting?: boolean;
  actionTracking?: TrackingProps;
  onBack?(): void;
  onAction?(): void;
}

interface State {
  props: StickyHeaderProps;
  stuck: boolean;
}

class MobileStickyHeader extends React.PureComponent<{}, State> {
  navRef?: HTMLElement = null;
  state = {
    props: stickyHeaderWormhole.get(),
    stuck: false,
  };

  componentDidMount() {
    this.checkStuck();
  }

  componentWillMount() {
    stickyHeaderWormhole.on('change', this.handleChange);
    window.addEventListener('scroll', this.checkStuck);
    window.addEventListener('resize', this.checkStuck);
  }

  componentWillUnmount() {
    stickyHeaderWormhole.off('change', this.handleChange);
    window.removeEventListener('scroll', this.checkStuck);
    window.removeEventListener('resize', this.checkStuck);
  }

  handleChange = (props: StickyHeaderProps) => {
    if (!isEqual(props, this.state.props)) {
      this.setState({ props });
    }
  };

  checkStuck = () => {
    this.setState({
      stuck: this.navRef != null && isStickyElementStuck(this.navRef),
    });
  };

  setNavRef = (ref: HTMLElement) => {
    this.navRef = ref;
  };

  render() {
    const { props, stuck } = this.state;
    const {
      backLabel = 'Back',
      backClassName,
      backDisabled,
      backSubmitting,
      actionLabel = 'Button',
      actionClassName,
      actionDisabled,
      actionSubmitting,
      onBack,
      onAction,
      hiddenUp,
      backTracking,
      actionTracking,
    } = props;

    if (onBack == null && onAction == null) {
      return null;
    }

    const isSubmitting = actionSubmitting || backSubmitting;
    const cn = classnames(
      'container-fluid mfb-u-sticky-top py-3 fs-u-bg-white',
      {
        'mfb-u-sticky-top-stuck': stuck,
        [`d-${hiddenUp}-none`]: hiddenUp != null,
      }
    );

    return (
      <nav ref={this.setNavRef} className={cn}>
        <div className="container">
          <div className="px-0 col-12 d-flex justify-content-between align-items-center">
            {onBack != null ? (
              <LinkButton
                className={classnames(
                  'nav-link p-0 d-flex align-items-center',
                  backClassName
                )}
                onClick={onBack}
                disabled={backDisabled || isSubmitting}
                submitting={backSubmitting}
                tracking={backTracking}
              >
                <LeftChevronIcon
                  width="12"
                  height="12"
                  className="mr-2 mr-sm-3"
                />
                {backLabel}
              </LinkButton>
            ) : (
              <span>
                {/* Empty element so that justify-content-between still pushes the action to the right */}
              </span>
            )}
            {onAction != null ? (
              <Button
                className={actionClassName}
                onClick={onAction}
                disabled={actionDisabled || isSubmitting}
                submitting={actionSubmitting}
                tracking={actionTracking}
                inline
                primary
              >
                {actionLabel}
              </Button>
            ) : (
              <Button
                key="don't animate my visibility"
                className="invisible"
                onClick={() => null}
                disabled
                inline
              >
                &nbsp;
                {/* Empty invisible button so that the header doesn't shrink when there's no button */}
              </Button>
            )}
          </div>
        </div>
      </nav>
    );
  }
}

export default MobileStickyHeader;
