/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import routeHistory from '../../../routerHistory';
import {deliveriesPath} from '../../account-settings/components/Nav/paths';
import {CampaignGroupDto} from '../../shared/CoreClient';
import { navman } from 'navigator';

interface Props {
  campaignGroupResponse: CampaignGroupDto;
}

const NoActiveSubscription: React.FunctionComponent<Props> = ({
  campaignGroupResponse,
}) => {
  const {groupTitle} = campaignGroupResponse;

  const onClick = () =>
    navman.reactivateSubscription();

  return (
    <div className="mx-md-4 text-center">
      <h3 className="my-4 w-100">Great, let's get you sorted!</h3>
      <p className="mb-4 mb-sm-5 w-100">
        To join the {groupTitle}, get started by choosing your perfect bag.
      </p>
      <button onClick={onClick} className="btn btn-primary mb-5">
        Choose Bag
      </button>
    </div>
  );
};

export default NoActiveSubscription;
