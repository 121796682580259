import {getQueryStringParams} from './getQueryString';
import createUserManager from './UserManager';
import {CampaignGroupDto, CampaignParticipationStatus} from './CoreClient';
import {flatten} from 'lodash';

declare let dataLayer: Array<any>;

interface CommonTrackingInfo {
  campaignCode: string;
  campaignGroupTitle: string;
  customerNumber: string;
  grouping: string;
  promotion: string;
  campaignCodes: Array<string>;
  campaignNames: Array<string>;
}
const getCommonTrackingInfo = async (
  campaignGroupResponse: CampaignGroupDto
): Promise<CommonTrackingInfo> => {
  const campaignCode = getQueryStringParams().campaignCode;

  const userMgr = await createUserManager();
  const customerNumber = await userMgr.getCustomerNumber();

  const isGroupedCampaign =
    campaignGroupResponse &&
    campaignGroupResponse.campaigns &&
    campaignGroupResponse.campaigns.length > 1;
  const grouping = isGroupedCampaign ? 'grouped' : 'single';
  const promotion =
    campaignGroupResponse && campaignGroupResponse.isPromoted
      ? 'promoted'
      : 'restricted';
  const campaignGroupTitle =
    campaignGroupResponse &&
    campaignGroupResponse.campaigns &&
    campaignGroupResponse.groupTitle;

  const campaignCodes =
    campaignGroupResponse &&
    campaignGroupResponse.campaigns &&
    campaignGroupResponse.campaigns.map(c => c.campaignCode);

  const campaignNames =
    campaignGroupResponse &&
    campaignGroupResponse.campaigns &&
    campaignGroupResponse.campaigns.map(c => c.campaignName);

  return {
    campaignCode,
    campaignGroupTitle,
    customerNumber,
    grouping,
    promotion,
    campaignCodes,
    campaignNames,
  };
};

export enum CampaignSubscriptionEvent {
  ADD_SUBSCRIPTION = 'start subscription page',
  EDIT_SUBSCRIPTION = 'change subscription page',
}

enum ParticipationStatus {
  NONE = 'none',
  ELIGIBLE = 'eligible',
  INELIGIBLE = 'ineligible',
  NOT_STARTED = 'campaign enrolment not started',
  ENDED = 'campaign enrolment finished',
}

export const trackAddEditSubForCampaignEvent = async (
  step: CampaignSubscriptionEvent
) => {
  const campaignCode = getQueryStringParams().campaignCode;

  const userMgr = await createUserManager();
  const customerNumber = await userMgr.getCustomerNumber();

  switch (step) {
    case CampaignSubscriptionEvent.ADD_SUBSCRIPTION:
      dataLayer.push({
        event: 'promotion_start_subscription_page',
        campaign_code_passed: campaignCode,
        campaign_step: CampaignSubscriptionEvent.ADD_SUBSCRIPTION,
        eligible_subscription: ParticipationStatus.NONE,
        user_id: customerNumber,
      });

      return;
    case CampaignSubscriptionEvent.EDIT_SUBSCRIPTION:
      dataLayer.push({
        event: 'promotion_change_subscription_page',
        campaign_code_passed: campaignCode,
        campaign_step: CampaignSubscriptionEvent.EDIT_SUBSCRIPTION,
        eligible_subscription: ParticipationStatus.INELIGIBLE,
        user_id: customerNumber,
      });

      return;
    default:
      break;
  }
};

export const trackCampaignModalLandingEvent = async (
  campaignGroupResponse: CampaignGroupDto
) => {
  const trackingInfo = await getCommonTrackingInfo(campaignGroupResponse);
  const {
    campaignCode,
    campaignGroupTitle,
    customerNumber,
    grouping,
    promotion,
    campaignNames,
    campaignCodes,
  } = trackingInfo;

  switch (campaignGroupResponse.campaignParticipationStatus) {
    case CampaignParticipationStatus.CampaignIsJoinable:
      dataLayer.push({
        event: 'promotion_modal_visible',
        campaign_type: promotion,
        campaign_grouping: grouping,
        campaign_code_passed: campaignCode,
        campaign_title_passed: campaignGroupTitle,
        campaign_name_array: campaignNames,
        campaign_code_array: campaignCodes,
        campaign_step: 'promotion modal visible',
        eligible_subscription: ParticipationStatus.ELIGIBLE,
        user_id: customerNumber,
      });

      return;

    case CampaignParticipationStatus.ProductOptionIsNotWhitelisted:
      dataLayer.push({
        event: 'promotion_error_page',
        campaign_type: promotion,
        campaign_grouping: grouping,
        campaign_code_passed: campaignCode,
        campaign_step: 'promotion error page',
        eligible_subscription: ParticipationStatus.INELIGIBLE,
        user_id: customerNumber,
      });

      return;

    case CampaignParticipationStatus.CustomerDoesNotHaveActiveSubscription:
      dataLayer.push({
        event: 'promotion_error_page',
        campaign_type: promotion,
        campaign_grouping: grouping,
        campaign_code_passed: campaignCode,
        campaign_step: 'promotion error page',
        eligible_subscription: ParticipationStatus.NONE,
        user_id: customerNumber,
      });

      return;

    case CampaignParticipationStatus.CampaignHasNotStarted:
      dataLayer.push({
        event: 'promotion_error_page',
        campaign_type: promotion,
        campaign_grouping: grouping,
        campaign_code_passed: campaignCode,
        campaign_step: 'promotion error page',
        eligible_subscription: ParticipationStatus.NOT_STARTED,
        user_id: customerNumber,
      });

      return;

    case CampaignParticipationStatus.CampaignHasEnded:
      dataLayer.push({
        event: 'promotion_error_page',
        campaign_type: promotion,
        campaign_grouping: grouping,
        campaign_code_passed: campaignCode,
        campaign_step: 'promotion error page',
        eligible_subscription: ParticipationStatus.ENDED,
        user_id: customerNumber,
      });

      return;

    default:
      break;
  }
};

export const trackJoinCampaignEvent = async (
  selectedCampaignCode: string,
  selectedPrizes: Record<number, number>,
  campaignGroupResponse: CampaignGroupDto
) => {
  const trackingInfo = await getCommonTrackingInfo(campaignGroupResponse);
  const {
    campaignCode,
    customerNumber,
    grouping,
    promotion,
    campaignCodes,
  } = trackingInfo;

  const selectedCampaign = campaignGroupResponse.campaigns.find(
    c => c.campaignCode === selectedCampaignCode
  );

  const selectedPrizeNames = flatten(
    selectedCampaign.campaignTiers.map(tier =>
      tier.campaignPrizes
        .filter(
          prize => prize.campaignPrizeId === selectedPrizes[tier.campaignTierId]
        )
        .map(p => p.name)
    )
  ).join('/');

  dataLayer.push({
    event: 'promotion_offer_confirmed',
    campaign_type: promotion,
    campaign_grouping: grouping,
    campaign_code_passed: campaignCode,
    campaign_code_array: campaignCodes,
    campaign_code_selected: selectedCampaignCode,
    campaign_step: 'offer confirmed',
    offers_selected: selectedPrizeNames,
    user_id: customerNumber,
  });
};
