import {
  CampaignPrizeDto,
  CampaignSelection,
  CampaignTierDto,
  Day,
} from '../shared/CoreClient';
import {flatten} from 'lodash';
import formatDate from '../../format/date';

export const convertToCampaignSelections = (
  selectedPrizes: Record<number, number>
): Array<CampaignSelection> => {
  const campaignSelections: Array<CampaignSelection> = [];

  for (const key in selectedPrizes) {
    if (selectedPrizes.hasOwnProperty(key)) {
      campaignSelections.push({
        campaignTierId: Number(key),
        campaignPrizeId: selectedPrizes[key],
      });
    }
  }

  return campaignSelections;
};

export const convertToSelectedPrizes = (
  campaignSelections: Array<CampaignSelection>
): Record<number, number> => {
  const selection: Record<number, number> = {};

  campaignSelections.forEach(element => {
    selection[element.campaignTierId] = element.campaignPrizeId;
  });

  return selection;
};

export const flattenPrizes = (
  tiers: Array<CampaignTierDto>
): Array<CampaignPrizeDto> => flatten(tiers.map(t => t.campaignPrizes));

export const daysRemainingFormatter = (daysRemaining: number): string => {
  const weeks = daysRemaining / 7;
  const isSingleWeek = weeks === 1;
  const isSingleDay = daysRemaining === 1;

  return weeks >= 1
    ? `${isSingleWeek ? "'s" : ' are'}
    ${Math.round(weeks)} week${isSingleWeek ? '' : 's'}`
    : `${isSingleDay ? "'s" : ' are'} ${daysRemaining} day${
        isSingleDay ? '' : 's'
      }`;
};

export const deliveriesRemainingFormatter = (remaning: number): string => {
  if (remaning === 1) {
    return 'delivery';
  }

  return 'deliveries';
};

export const promotionalPeriodFormatter = (start: Day): string =>
  `Deliveries count from ${formatDate(start)}.`;
