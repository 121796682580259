import { addDays, addMilliseconds, differenceInDays, format, parseISO } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { Week } from '@mfb/account-api-client';
import { OrderWithRecipesAndExtras } from './CoreClient';


export function firstOrDefault<T>(array: T[], defaultValue?: T): T {
  return array.length > 0 ? array[0] : defaultValue;
}



export const formatWeekForGAtracking = (date?: Date) => {
  if (!date) {
    return;
  }

  try {
    return `W${format(new Date(date), 'yyyy-MM-dd')}`;
  } catch (ex) {
    console.error(ex);
  }
};

export const stripTimeFromDate = (date: Date) => {
  return utcToZonedTime(date as Date, 'Pacific/Auckland');
};

export const offsetTimeZone = (date: Date) => {
  date = stripTimeFromDate(date);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

export const asUtcString = (date?: Date) => {
  if(!date) return undefined;
  return date.toISOString();
};

export const asIsoNzstString = (date: Date) => {
  return formatInTimeZone(
    date as Date,
    'Pacific/Auckland',
    "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"
  );
};

export const asWDate = (date: Date) => {
  return `W${format(date, 'yyyy-MM-dd')}`;
};

export const asDateFromWDate = (wDate: string) => {
  if (!wDate.startsWith('W')) {
    throw new Error('wDate format is incorrect');
  }

  let utcAdjustedDate = new Date(`${wDate.replace('W', '')}T00:00:00.000Z`);

  let nzstAdjustedDate = addDays(utcAdjustedDate, -1);
  let nzstOffset = getTimezoneOffset('Pacific/Auckland', utcAdjustedDate);

  nzstAdjustedDate = addMilliseconds(nzstAdjustedDate, nzstOffset);
  // Should always return MIDNIGHT NZST - this is to account for +13:00 shift to 1AM.
  nzstAdjustedDate.setHours(0);

  return nzstAdjustedDate;
};

type OrderActionParam =
  | 'canSkip'
  | 'canUnskip'
  | 'canChangeMeals'
  | 'canAddExtras'
  | 'canChangeBag'
  | 'canChangeDeliveryShipping'
  | 'canRateRecipes'
  | 'canRestartSubscription'
  | 'canApplyNewPromo';

export const isOrderValidForActions = (
  order: OrderWithRecipesAndExtras,
  actions: OrderActionParam[]
): boolean => {
  if (!order || !order.orderState) return false;

  let isValid = true;

  actions.forEach((c) => {
    const action = order.orderState[c];
    if (!action.isValid) {
      isValid = false;
    }
  });

  return isValid;
};


export const getDifferenceInDays = (date?: Date) => {
  try {
    const difference = differenceInDays(date, Date.now());
    return isNaN(difference) ? 0: difference;
  } catch (e) {
    console.error(e);
  }
};

export const formatWeekAsUtcString = (week?: string)=>{
  try {
    const parsedDate = parseISO(week.replace('W', ''));
    const utcDate = utcToZonedTime(parsedDate, 'UTC');
    return format(utcDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  }catch (e){
    console.error(e);
  }
}

export class DeliveryWeek extends Week {
  private readonly week: string;
  constructor(week: string) {
    super();
    if(week.startsWith('W')){
      this.week = week;
    }else{
      throw new Error('Invalid delivery week.')
    }

  }

  toJSON(): any {
    return this.week;
  }
}
