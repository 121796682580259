import React from 'react';
import {ResponsiveImageSet} from '../shared/CoreClient';
import {ResponsiveImage} from '@mfb/lego';
import TrackingProps, {
  trackingAction,
  trackingLabel,
  trackingCategory,
} from '../shared/TrackingProps';

export interface SingleMerchSlotProps {
  imageSet?: ResponsiveImageSet;
  hyperlink?: string;
  tracking?: TrackingProps;
}

export const SingleMerchSlot: React.FC<SingleMerchSlotProps> = ({
  imageSet,
  hyperlink,
  tracking,
}: SingleMerchSlotProps) => {
  const {small, medium, large} = imageSet;

  if (!small) {
    console.error(
      'A Merch Slot has been allocated to Account Merchandising, but the Responsive Image Set is missing a small image.'
    );

    return null;
  }

  return (
    <div
      data-action={trackingAction(tracking)}
      data-label={trackingLabel(tracking)}
      data-category={trackingCategory(tracking)}
      className="mt-3 mb-3"
    >
      <a href={hyperlink} target="_blank" rel="noreferrer">
        <ResponsiveImage src={small} mdSrc={medium} lgSrc={large} />
      </a>
    </div>
  );
};
