/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {
  AccountSettingsCustomer,
  AddressSuggestion,
  UpdateCustomerInfoRequest,
} from '../../../shared/CoreClient';
import AddressFinder from '../AddressFinder';
import { default as FormField } from '../FormField';
import classNames from 'classnames';
import FormTextArea from '../FormTextArea';
import MobileStickyHeaderProxy from '../../../shared/components/MobileStickyHeader/MobileStickyHeaderProxy';
import submitForm from '../../../shared/submitForm';
import SubmitOrBack from '../../../shared/components/SubmitOrBack';
import TrackingProps from '../../../shared/TrackingProps';
import { getSubmitStage } from '../../../shared/components/SubmitStage';

interface ExtraFields {
  address?: string;
  confirmPassword?: string;
}

export interface PersonalDetailsForm
  extends UpdateCustomerInfoRequest,
    ExtraFields {}

export type PersonalDetailsErrors = {
  [k in keyof AccountSettingsCustomer | keyof ExtraFields]?: string;
};

interface Props {
  form: Partial<PersonalDetailsForm>;
  errors: Partial<PersonalDetailsErrors>;
  isSubmitting: boolean;
  onChange<K extends keyof PersonalDetailsForm>(
    key: string & K,
    value: PersonalDetailsForm[K]
  ): void;
  onChangeAddress(address: AddressSuggestion): void;
  onSubmit(): void;
  onCancel(): void;
  dashboardV2: boolean;
}

const submitTracking: TrackingProps = {
  category: 'personal-details',
  action: 'click',
  label: 'submit-edit-personal-details',
};

const backTracking: TrackingProps = {
  category: 'personal-details',
  action: 'click',
  label: 'back-edit-personal-details',
};

class EditPersonalDetails extends React.Component<Props, {}> {
  form?: HTMLFormElement;

  handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    this.props.onChange(
      event.currentTarget.name as keyof PersonalDetailsForm,
      event.currentTarget.value
    );
  };

  handleSubmitWithoutForm = (): void => submitForm(this.form);

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    this.props.onSubmit();
  };

  setFormRef = (ref: HTMLFormElement): void => {
    this.form = ref;
  };

  render(): React.ReactNode {
    const { form, errors, isSubmitting, onChangeAddress, onCancel } =
      this.props;

    return (
      <div>
        <MobileStickyHeaderProxy
          hiddenUp="md"
          backLabel="Cancel"
          actionLabel="Save"
          actionSubmitting={isSubmitting}
          actionTracking={submitTracking}
          backTracking={backTracking}
          onBack={this.props.dashboardV2 ? undefined : onCancel}
          onAction={this.handleSubmitWithoutForm}
        />
        <h3 className="mt-2 mb-4" data-test="personal-info-title">
          My Personal Details
        </h3>
        <form onSubmit={this.handleSubmit} ref={this.setFormRef}>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <FormField
                label="First Name"
                onChange={this.handleChange}
                value={form.firstName || ''}
                name="firstName"
                isValid={errors.firstName == null}
                errorMessage={errors.firstName}
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <FormField
                label="Last Name"
                onChange={this.handleChange}
                value={form.lastName || ''}
                name="lastName"
                isValid={errors.lastName == null}
                errorMessage={errors.lastName}
              />
            </div>
          </div>
          <FormField
            label="Email"
            onChange={this.handleChange}
            value={form.email || ''}
            name="email"
            isValid={errors.email == null}
            errorMessage={errors.email}
          />
          <FormField
            label="Mobile Number"
            type="tel"
            onChange={this.handleChange}
            value={form.mobileNumber || ''}
            name="mobileNumber"
            pattern="^[0-9]{9,11}$"
            title="Your mobile number must only contain numeric values and must be between 9-11 characters long."
            isValid={errors.mobileNumber == null}
            errorMessage={errors.mobileNumber}
            placeholder="e.g. 0123456789"
          />
          <SubmitOrBack
            submitStage={getSubmitStage(true, isSubmitting)}
            submitTracking={submitTracking}
            backTracking={backTracking}
            onBack={onCancel}
          />
        </form>
      </div>
    );
  }
}

export default EditPersonalDetails;
