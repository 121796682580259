import {createSelector} from 'reselect';
import {AppState, OrdersPageState} from '../state';
import {
  DeliveryDay,
  DeliveryLineResponse,
  OrderWithRecipesAndExtras,
} from '../../app/shared/CoreClient';

const selectSelectedDay = (state: AppState<OrdersPageState>) =>
  state.ui.selectedDay;
const selectDeliveryDays = (state: AppState<OrdersPageState>) =>
  state.pageState.deliveryDays;
const selectOrders = (state: AppState<OrdersPageState>) =>
  state.pageState.orders;

export const selectCurrentOrder = createSelector(
  selectSelectedDay,
  selectOrders,
  (
    selectedDay,
    orders: OrderWithRecipesAndExtras[] = []
  ): OrderWithRecipesAndExtras | undefined => {
    return selectedDay && orders.find(o => o.orderKey === selectedDay);
  }
);

export const selectCurrentDelivery = createSelector(
  selectSelectedDay,
  selectDeliveryDays,
  (selectedDay, deliveryDays: DeliveryDay[] = []): DeliveryDay | undefined => {
    if (selectedDay && deliveryDays) {
      return deliveryDays.find(o => o.order.orderKey === selectedDay);
    }
    return deliveryDays.find(
      o => o.order.deliveryId == null || o.order.isOrdered || o.order.isLocked
    );
  }
);

export const selectCurrentPrimaryOption = createSelector(
  selectSelectedDay,
  selectCurrentOrder,
  (selectedDay, currentOrder): DeliveryLineResponse | undefined => {
    if (currentOrder) {
      const deliveryLines = currentOrder.deliveryLines;
      return (
        deliveryLines &&
        deliveryLines.find(dl => dl.deliveryLineType === 'PRODUCT')
      );
    }
    return undefined;
  }
);

export const selectCurrentOrderExtras = createSelector(
  selectSelectedDay,
  selectCurrentOrder,
  (selectedDay, currentOrder): DeliveryLineResponse[] | undefined => {
    if (currentOrder) {
      const deliveryLines = currentOrder.deliveryLines;
      return (
        deliveryLines &&
        deliveryLines.filter(dl => dl.deliveryLineType === 'EXTRAS')
      );
    }
    return undefined;
  }
);
