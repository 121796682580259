import { lowerCase } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonStyles } from '@mfb/lego';

interface Props {
  bagDescription: string;
  date: string;
  label?: 'meal' | 'extras';
  onClick(): void;
}

export const PlanYourWeekButton: React.FC<Props> = ({
  bagDescription,
  date,
  label = 'meals',
  onClick,
}) => {
  const { t } = useTranslation();

  const labelToDisplay =
    label === 'extras'
      ? t('deliveries.heading.planYourWeekAction.extras')
      : t('deliveries.heading.planYourWeekAction.meals');

  return (
    <Button
      size="lg"
      className="choose-meals"
      onClick={onClick}
      buttonStyle={ButtonStyles.Primary}
      label={labelToDisplay}
      data-category="delivery"
      data-action="click-change-meals"
      data-label={`${lowerCase(bagDescription)} - ${date}`}
      data-test="plan-your-week-button"
    />
  );
};
