import {Action, Reducer} from 'redux';
import {PageState, defaultPageState, PageStateSources} from '../state';
import {
  ReceivePageStateAction,
  RequestPageStateAction,
  RECEIVE_PAGE_STATE,
  REQUEST_PAGE_STATE,
} from '../actions/sharedActions/pageStateAction';
import {
  ValidationErrorsAction,
  VALIDATION_ERRORS,
} from '../actions/sharedActions/validationErrorsAction';
import {defaultsDeep, isUndefined} from 'lodash';
import { store } from 'index';
import { fetchCustomerProfileThunk } from 'redux/actions/customerProfileActions';
import { fetchTracking } from '../actions/sharedActions/trackingAction';

const defaultState: PageState = defaultPageState;

const reducer: Reducer<PageState> = (
  state: PageState = defaultState,
  action: Action
): PageState => {
  switch (action.type) {
    case VALIDATION_ERRORS:
      return defaultsDeep(
        {},
        (action as ValidationErrorsAction).payload,
        state
      );
    case REQUEST_PAGE_STATE:
      const requestTime = (action as RequestPageStateAction).requestTime;
      store.dispatch(fetchCustomerProfileThunk());
      store.dispatch(fetchTracking())
      return {...state, requestTime};
    case RECEIVE_PAGE_STATE:
      const payload = (action as ReceivePageStateAction).payload;

      /*
        See https://github.com/MyFoodBag/repository/issues/7171 for more insight
        on this check.
       */
      const isTheMoreRecentRequest = payload.requestTime >= state.requestTime || isUndefined(state.requestTime);
      if (isTheMoreRecentRequest && doesPageStateMatchRoute(payload.requestSource) ) {
        return payload;
      }

      return state;
    default:
      return state;
  }
};

/**
 * Ensure that the pageState request matches the URL
 * See https://github.com/MyFoodBag/repository/issues/7171 for why
 *
 * @param requestSource
 * @param urlToCheck
 */
function doesPageStateMatchRoute(requestSource: PageStateSources, urlToCheck = window.location.pathname) {
  return requestSource === "" || window.location.pathname.indexOf(requestSource) > -1;
}

export default reducer;
