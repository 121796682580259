import {
  Colours,
  ContentSlotCustomerMessageResponsiveColumn,
  CustomerMessageV2,
  LegoDeviceBreakpoints,
  RecipeCarousel,
  useAnalyticsTracking,
  useCustomerMessageContext,
  useThemeScreenSizes,
  CustomerMessageGATrackingEvent,
  CustomerMessageGATrackingData,
  useRunOncePerSession,
} from '@mfb/lego';
import { OrderWithRecipesAndExtras } from 'app/shared/CoreClient';
import {
  displayContentSlotMessages,
  getCustomerMessageFromGroup,
} from 'app/shared/CustomerMessageService';
import { TriggerCode } from 'app/shared/constants';
import { useMemo } from 'react';
import { CustomerMessageBulkGroupState, CustomerMessageBulkState } from 'redux/state';
import styled, { keyframes } from 'styled-components';
import { formatWeekForGAtracking } from 'app/shared/helper';

const entryKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const exitKeyframes = keyframes`
  0% {
  height: 180px;
  }
  100% {
    height: 0;
    padding: 0px 0px;
  }
`;

const ExitAnimation = styled.div`
  background-color: ${Colours.SECONDARY_CREAM};
  width: 100%;
  height: 180px;
  animation: ${exitKeyframes} 0.3s ease-in forwards;
`;

const Container = styled.div`
  padding: 32px 0px;
  opacity: 0;
  animation: ${entryKeyframes} 0.4s ease-in forwards;
`;

const CarouselItemContainer = styled.div`
  width: 100%;
  padding: 2px;
`;

export type ContentSlotCustomerMessageProps = {
  order?: OrderWithRecipesAndExtras;
  customerMessageBulkGroupState: Array<CustomerMessageBulkGroupState>;
  onNavigate: (url: string, triggerId?: number) => Promise<any>;
  /**
   * @property determine which message(s) should be displayed at each breakpoint.
   */
  responsiveColumnsConfig?: Partial<
    Record<
      keyof LegoDeviceBreakpoints,
      ContentSlotCustomerMessageResponsiveColumn
    >
  >;
  bagSku?: string;
  className?: string;
};

export const ContentSlotCustomerMessages = ({
  order,
  customerMessageBulkGroupState,
  onNavigate,
  responsiveColumnsConfig: responsiveColumnsConfigBanner = {
    xs: { displayFrom: 0, limit: 3 },
    sm: { displayFrom: 0, limit: 3 },
    md: { displayFrom: 0, limit: 3 },
    lg: { displayFrom: 0, limit: 3 },
    xl: { displayFrom: 0, limit: 3 },
  },
  bagSku,
  className,
}: ContentSlotCustomerMessageProps) => {
  const customerMessageContext = useCustomerMessageContext();
  const { isScreenSize } = useThemeScreenSizes();
  const { trackDataLayerEvent } = useAnalyticsTracking();
  const { runOncePerSession } = useRunOncePerSession();

  const displayContext = {
    customerMessageContext,
    customerMessageBulkGroupState,
    triggerCode: TriggerCode.CONTENT_SLOT,
    subscriptionNumber: order?.subscriptionNumber,
    deliveryDateId: order?.deliveryDate?.deliveryDateId,
    onNavigate,
    gATrackingData: {
      message_journey: TriggerCode.CONTENT_SLOT,
      customer_message_trigger: true,
      week: formatWeekForGAtracking(order?.deliveryDate?.weekStarting),
      bag_sku: bagSku,
    },
  };

  const customerMessageState = useMemo(
    () => {
      //grab state from redux
      const customerMessageState = getCustomerMessageFromGroup(displayContext);
      const displayData = displayContentSlotMessages(displayContext);

      //ga-tracking
      runOncePerSession(() => {
        if (customerMessageState) {
          const trackData: Partial<CustomerMessageGATrackingData> = {
            ...displayContext.gATrackingData,
            promo_content: displayData,
          };
          trackDataLayerEvent(
            CustomerMessageGATrackingEvent.CUSTOMER_MESSAGE_CONTENT_SLOT_PROMO,
            trackData
          );
        }
      }, `${CustomerMessageGATrackingEvent.CUSTOMER_MESSAGE_CONTENT_SLOT_PROMO}-${order?.deliveryDate.weekStarting}`);

      return customerMessageState;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order?.deliveryDate?.deliveryDateId]
  );

  if (!customerMessageState) {
    return <ExitAnimation />;
  }
  return (
    <div className={className}>
      <Container className="container __inner-container">
        {buildBanners(
          customerMessageState,
          isScreenSize.min,
          responsiveColumnsConfigBanner
        )}
      </Container>
    </div>
  );
};

const buildBanners = (
  customerMessageState: CustomerMessageBulkState,
  minBreakpoint: Record<keyof LegoDeviceBreakpoints, boolean>,
  responsiveColumnsConfigBanner?: Partial<
    Record<
      keyof LegoDeviceBreakpoints,
      ContentSlotCustomerMessageResponsiveColumn
    >
  >
) => {
  let carouselItemWidth = '0px';
  let fontSize = '16px';
  let numberOfMessages = customerMessageState.customerMessageResult.customerMessages.length;
  let showCarousel = false;

  //determines wether to show banners in a carousel or not depending on the current screen breakpoint.

  switch (true) {
    case minBreakpoint['xxl']:
    case minBreakpoint['xl']: {
      switch (numberOfMessages) {
        case 1: {
          fontSize = '19px';
          showCarousel = false;
          break;
        }
        case 2: {
          fontSize = '19px';
          showCarousel = false;
          break;
        }
        default: {
          fontSize = '16px';
          showCarousel = true;
          carouselItemWidth = '600px';
          break;
        }
      }
      break;
    }
    case minBreakpoint['lg']:{
      switch (numberOfMessages) {
        case 1:
          showCarousel = false;
          fontSize = '16px';
          break;
        default: {
          //when screen is md, show carousel and set the carousel item width to (x)px.
          showCarousel = true;
          carouselItemWidth = '550px';
          fontSize = '16px';
          break;
        }
      }
      break;
    }
    case minBreakpoint['md']:{
      switch (numberOfMessages) {
        case 1:
          showCarousel = false;
          carouselItemWidth = '500px';
          fontSize = '16px';
          break;
        default: {
          //when screen is md, show carousel and set the carousel item width to (x)px.
          showCarousel = true;
          carouselItemWidth = '500px';
          fontSize = '16px';
          break;
        }
      }
      break;
    }
    case minBreakpoint['sm']: {
      switch (numberOfMessages) {
        case 1:
          showCarousel = false;
          fontSize = '16px';
          break;
        default: {
          //when screen is md, show carousel and set the carousel item width to (x)px.
          showCarousel = true;
          carouselItemWidth = '380px';
          fontSize = '16px';
          break;
        }
      }
      break;
    }
    case minBreakpoint['xs']: {
      switch (numberOfMessages) {
        case 1:
          showCarousel = false;
          fontSize = '16px';
          break;
        default: {
          showCarousel = true;
          carouselItemWidth = '380px';
          fontSize = '16px';
          break;
        }
      }
      break;
    }
  }

  const carousel = (
    <RecipeCarousel
      showEmptySrcImages={false}
      rowCount={1}
      allowVariableHeight={true}
      recipes={getCarouselItems(
        customerMessageState,
        carouselItemWidth,
        fontSize
      )}
    />
  );

  const bannerGroup = (
    <CustomerMessageV2.ContentSlotGroup
      responsiveColumns={responsiveColumnsConfigBanner}
      fontSize={fontSize}
    />
  );

  return showCarousel ? carousel : bannerGroup;
};

const getCarouselItems = (
  customerMessages: CustomerMessageBulkState,
  carouselItemWidth: string,
  fontSize: string
) => {
  return customerMessages.customerMessageResult.customerMessages.map((c, i) => {
    return {
      name: '',
      content: (
        <CarouselItemContainer>
          <CustomerMessageV2.ContentSlot
            id={c.customerMessageId}
            slot={i}
            title={c.title}
            body={c.body}
            key={c.customerMessageId}
            imageUrl={c.imageUrl}
            backgroundColour={c.colourScheme?.backgroundHex}
            footerText={c.termsAndConditions}
            textColour={c.colourScheme?.textHex}
            primaryCta={c.primaryCta}
            fontSize={fontSize}
            dataTestId={c.customerMessageId.toString()}
          />
        </CarouselItemContainer>
      ),
      width: carouselItemWidth,
    };
  });
};
