import {CodeValidationResponse, CostPreviewResponse} from '../../../CoreClient';
import {BagSelectorProductResult} from '@mfb/cookbook';

export const setDiscountedPriceDisplay = (
  setPriceContext,
  costPreviewResponse: CostPreviewResponse,
  newValue: BagSelectorProductResult
) => {
  const {pricing, shipping} = costPreviewResponse;
  const {subTotal, overallTotal} = pricing;
  const shippingPrice = shipping.price;
  setPriceDisplay(setPriceContext, costPreviewResponse, newValue);
  if (subTotal === overallTotal) {
    return;
  } else {
    const priceBeforeDelivery = overallTotal - shippingPrice;
    setPriceContext(prevState => ({
      ...prevState,
      pricePerWeekBeforeDiscount: `$${subTotal.toFixed(2)}`,
      pricePerWeek: `$${priceBeforeDelivery.toFixed(2)}`,
      totalPrice: `$${overallTotal.toFixed(2)}`,
    }));
  }
};

const getPerPlate = (price, newValue: BagSelectorProductResult) => {
  return `$${(price / (newValue.night * newValue.serves)).toFixed(2)}`;
};

export const setPriceDisplay = (
  setPriceContext,
  costPreviewResponse: CostPreviewResponse,
  newValue: BagSelectorProductResult
) => {
  const {pricing, shipping} = costPreviewResponse;
  const {subTotal} = pricing;
  const shippingPrice = shipping.price;
  setPriceContext({
    pricePerWeek: `$${subTotal.toFixed(2)}`,
    pricePerWeekStringFormat: 'Per Week',
    primaryButtonText: 'Continue',
    secondaryButtonHidden: true,
    pricePerPlate: getPerPlate(subTotal, newValue),
    pricePerPlateStringFormat: 'Per Plate',
  });
  if (shippingPrice) {
    const totalPrice = subTotal + shippingPrice;
    setPriceContext(prevState => ({
      ...prevState,
      deliveryPrice: `$${shippingPrice.toFixed(2)}`,
      totalPrice: `$${totalPrice.toFixed(2)}`,
    }));
  }
};

export const validatePromoCode = (
  validatePromoResp: CodeValidationResponse,
  costPreviewRes: CostPreviewResponse,
  sku: string,
  setPriceContext,
  newValue: BagSelectorProductResult
) => {
  //check if promo is only valid for specific product
  if (
    validatePromoResp.validationErrors.length == 0 &&
    validatePromoResp.discount
  ) {
    if (validatePromoResp.discount.validForProducts.length > 0) {
      const discountProduct = validatePromoResp.discount.validForProducts.filter(
        e => e.itemNumber == sku
      )[0];
    }
    //check if expired
    if (new Date(validatePromoResp.discount.redeemableTo) > new Date()) {
      if (costPreviewRes && costPreviewRes.pricing) {
        if (
          costPreviewRes.pricing.appliedDiscount &&
          costPreviewRes.pricing.appliedDiscount.hasDiscountApplied
        ) {
          setDiscountedPriceDisplay(setPriceContext, costPreviewRes, newValue);
          return;
        }
      }
    }
  }
  setPriceDisplay(setPriceContext, costPreviewRes, newValue);
};
