/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {SvgNoRecipesPlaceholderIcon} from '@mfb/lego';

export const NoRecipesBlurb = () => (
  <div className="d-flex justify-content-center w-100 my-4">
    <div className="col-10 col-md-6 col-xl-4 text-center">
      <SvgNoRecipesPlaceholderIcon width="100%" height="70%" />
      <h4>No recipes yet</h4>
      <div className="col-12">
        <p>We're still working on the recipes for this week.</p>
      </div>
    </div>
  </div>
);
