import {CURRENCY_DIGITS} from '../app/shared/constants';
import {isUndefined, isNumber} from 'lodash';

export enum MoneyFormat {
  short,
  long,
}

export const formatMoney = (
  value: number,
  variant: MoneyFormat = MoneyFormat.long
): string => {
  if (!isUndefined(value) && isNumber(value)) {
    if (value > -1) {
      const formattedValue = `$${value.toFixed(CURRENCY_DIGITS)}`;
      if (variant === MoneyFormat.short) {
        const split = formattedValue.split('.');

        if (split[1] === '00') {
          return `${split[0]}`;
        }
      }

      return formattedValue;
    }

    return `($${(value * -1).toFixed(CURRENCY_DIGITS)})`;
  }

  return '';
};
