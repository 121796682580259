import { openZendesk } from 'app/shared/Zendesk';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import routeHistory from 'routerHistory';
import { PreviewMessage, SvgInfoAlt } from '@mfb/lego';
import { navman } from '../../../../navigator';
import {
  AccountFeaturesState,
  AppState,
  CustomerProfileState,
} from '../../../../redux/state';
import { AccountSettings, Frequency } from '../../../shared/CoreClient';
import TrackingProps from '../../../shared/TrackingProps';
import createUserManager from '../../../shared/UserManager';
import MobileStickyHeaderProxy from '../../../shared/components/MobileStickyHeader/MobileStickyHeaderProxy';
import {
  personalDetailsEditPath,
  subscriptionsPath,
  subscriptionsViewPath,
} from '../Nav/paths';
import { HolidayAddressBanner } from './HolidayAddressBanner';
import { getIsUserOnlyHasOneOff } from '../MySubscriptions/View';

interface Props {
  accountSettings: AccountSettings;
  accountFeatures?: AccountFeaturesState;
  customerProfileState?: CustomerProfileState;
}

const editTracking: TrackingProps = {
  category: 'personal-details',
  action: 'click',
  label: 'edit-personal-details',
};

const ViewPersonalDetails: React.FC<Props> = (props) => {
  const { accountSettings, accountFeatures, customerProfileState } = props;
  const { customer } = accountSettings;

  const isButcher = customerProfileState.code === 'BUTCHER';

  const [alertText, setAlertText] = React.useState({
    heading: 'Delivery details have moved to Subscriptions.',
    linkLabel: 'Edit your delivery details',
  });

  React.useEffect(() => {
    if (isButcher) {
      setAlertText({
        heading: 'Need to update your delivery address?',
        linkLabel: 'Chat with us!',
      });
    }
  }, [customerProfileState.code]);

  const handleResetPasswordClick = async () => {
    const userManager = await createUserManager();
    await userManager.PasswordReset(customer.email.value);
  };

  return (
    <div>
      <MobileStickyHeaderProxy
        hiddenUp="md"
        actionLabel="Edit"
        onBack={
          props.accountFeatures.dashboardV2 ? undefined : navman.accountSettings
        }
        actionTracking={editTracking}
        onAction={navman.editPersonalDetails}
      />

      <div className="row">
        <div className="col-md-8 col-sm-12">
          <h3 className="mb-4 mt-2" data-test="personal-info-title">
            My Personal Details
          </h3>
        </div>
        <div className="col-4">
          <Link
            className="btn btn-primary d-none d-md-block float-right"
            data-test="account-info-edit"
            data-category={editTracking.category}
            data-action={editTracking.action}
            data-label={editTracking.label}
            role="button"
            to={personalDetailsEditPath}
          >
            Edit
          </Link>
        </div>
      </div>

      <div className="form-group row mt-2">
        <div className="col-4">
          <strong>Name</strong>
        </div>
        <div className="col-8" data-test="info-name">
          {customer.firstName.value} {customer.lastName.value}
        </div>
      </div>

      <div className="form-group row">
        <div className="col-4">
          <strong>Email</strong>
        </div>
        <div className="col-8" data-test="info-email">
          {customer.email.value}
        </div>
      </div>

      <div className="form-group row">
        <div className="col-4">
          <strong>Mobile Number</strong>
        </div>
        <div className="col-8" data-test="info-mobile">
          {customer.mobileNumber.value}
        </div>
      </div>

      <div className="form-group row">
        <div className="col-4">
          <strong>Password</strong>
        </div>
        <div className="col-8">
          <a
            role="link"
            data-category="personal-details"
            data-action="click"
            data-label="change-password"
            onClick={handleResetPasswordClick}
            href="#"
          >
            Change Password
          </a>
        </div>
      </div>

      <div>
        {!getIsUserOnlyHasOneOff(accountSettings.subscriptions) && (
          <PreviewMessage
            heading={alertText.heading}
            button={{
              label: alertText.linkLabel,
              onClick: () => {
                if (accountSettings.subscriptions.length === 1) {
                  if (isButcher) {
                    openZendesk();
                  } else {
                    const subscriptionNumber =
                      accountSettings.subscriptions[0].subscriptionNumber;

                    routeHistory.history.push(
                      `${subscriptionsViewPath}/${subscriptionNumber}`,
                      { showDetailsInit: true }
                    );
                  }
                } else {
                  routeHistory.history.push(subscriptionsPath);
                }
              },
              isInline: true,
            }}
            headingIcon={<SvgInfoAlt width={20} height={20} />}
            variant={'information'}
          />
        )}
      </div>
      {accountFeatures.enableCustomerAddressHoliday && <HolidayAddressBanner />}
    </div>
  );
};

export const UnconnectedViewPersonalDetails = ViewPersonalDetails;

const mapStateToProps = (state: AppState): Props => {
  return {
    accountSettings: state.pageState as AccountSettings,
    accountFeatures: state.features,
    customerProfileState: state.customerProfileState,
  };
};
export default connect(mapStateToProps)(ViewPersonalDetails);
