import React from 'react';

const PaymentIcon: React.FunctionComponent<
  React.SVGAttributes<SVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 12"
    width="16"
    height="16"
    {...props}
  >
    <path d="M12.62 2.814V1.407H1.38v1.407h11.24zm0 6.967V5.594H1.38v4.187h11.24zm0-9.781C13.408 0 14 .622 14 1.407V9.78c0 .785-.592 1.407-1.38 1.407H1.38c-.788 0-1.38-.622-1.38-1.407V1.407C0 .622.592 0 1.38 0h11.24z" />
  </svg>
);

export default PaymentIcon;
