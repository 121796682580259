import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import defaultAppState, {AppState} from '../redux/state';
import {ComponentState} from 'react';
import messageActionMiddleWare from './middleware/messageActionMiddleWare';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (initialState: AppState = defaultAppState) =>
  createStore(
    rootReducer,
    defaultAppState as ComponentState,
    composeEnhancers(applyMiddleware(thunk, messageActionMiddleWare))
  );

export default configureStore;
