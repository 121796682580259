import {Dispatch} from 'redux';
import {RootState} from '../../reducers/rootReducer';
import {receivePageState} from './pageStateAction';

/** Reverts the page state to the most recently received non-error version of the state */
export const restorePageState = () => (
  dispatch: Dispatch<RootState>,
  getState: () => RootState
) =>
  dispatch(
    receivePageState({
      ...getState().pageStateBackup,
      requestTime: new Date(),
      requestSource: "",
    })
  );
