/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import classNames from 'classnames';
import { globalSettings as settings } from 'config';
import {
  PageProperties,
  mapStateToProps,
  mapDispatchToProps,
} from '../containers/accountSettingsContainer';
import { AccountSettings } from '../../shared/CoreClient';
import { connect, ComponentClass } from 'react-redux';
import { navman } from '../../../navigator';
import MobileStickyHeaderProxy from 'app/shared/components/MobileStickyHeader/MobileStickyHeaderProxy';

interface State {
  voucherCode: string;
  isSuccessfulRedemption: boolean;
  displayAppliedMessage: boolean;
}

class Voucher extends React.Component<PageProperties, State> {
  constructor(props: PageProperties) {
    super(props);

    this.state = {
      voucherCode: '',
      isSuccessfulRedemption: false,
      displayAppliedMessage: false,
    };
  }

  submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      isSuccessfulRedemption: false,
      displayAppliedMessage: false,
    });

    try {
      await this.props.redeemVoucher({
        voucherCode: this.state.voucherCode.trim(),
      });
      this.setState({
        ...this.state,
        isSuccessfulRedemption: true,
        displayAppliedMessage: true,
      });
      setTimeout(() => {
        this.setState({
          ...this.state,
          displayAppliedMessage: false,
        });
      }, 3000);
    } catch (err) {
      /* Validation errors handled in redux */
    }
  };

  handleChange = (event: React.SyntheticEvent<HTMLInputElement>): void => {
    const fieldName = event.currentTarget.name;

    this.setState({
      ...this.state,
      [fieldName]: event.currentTarget.value,
    });
  };

  render() {
    const accountSettings = this.props.pageState as AccountSettings;
    const savingClassNames = classNames('btn btn-primary w-100', {
      'is-saving':
        this.props.ui.isSubmitting != null && this.props.ui.isSubmitting,
    });

    return (
      <div>
        <MobileStickyHeaderProxy
          hiddenUp="md"
          onBack={
            this.props.features.dashboardV2 ? undefined : navman.accountSettings
          }
        />
        <div className="mb-md-5">
          <h3 className="mt-sm-2 mb-3 mb-sm-4" data-test="vouchers-title">
            Redeem My Voucher
          </h3>
          <form onSubmit={this.submitHandler}>
            <div className="row">
              <div className="col-xs-12 col-md-8 mt-0 mt-md-2">
                <input
                  type="text"
                  placeholder="Voucher Code"
                  value={this.state.voucherCode || ''}
                  onChange={this.handleChange}
                  name="voucherCode"
                  id="voucherCode"
                  className="form-control w-100"
                />
              </div>
              <div className="col-xs-12 col-md-4 mt-2">
                <button
                  data-category="voucher"
                  data-action="redeem"
                  type="submit"
                  className={savingClassNames}
                >
                  <span
                    className="mfb-Button--text"
                    data-test="redeem-voucher-button"
                  >
                    {this.state.displayAppliedMessage ? 'Applied' : 'Redeem'}
                  </span>
                  <span className="mfb-Button--status" />
                </button>
              </div>
              {accountSettings.voucherCode != null &&
                !accountSettings.voucherCode.valid && (
                  <div className="col-12 text-danger">
                    {accountSettings.voucherCode.message}
                  </div>
                )}
              {this.state.isSuccessfulRedemption && (
                <div className="col-12 text-success">
                  Great! We'll apply this to your next delivery
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="mt-4 my-md-5">
          <h3
            className="mt-sm-2 mb-3 mb-sm-4"
            data-test="purchase-voucher-banner"
          >
            Purchase A Voucher
          </h3>
          <p>
            Our gift vouchers make it simple and easy to delight friends,
            family, colleagues or someone who just needs a little dinnertime
            inspiration... after all, everyone loves a delicious meal!{' '}
            <a
              href={`${settings.webURL}/my-food-bags`}
              data-category="voucher"
              data-action="open-foodbags-page"
            >
              Choose a Food Bag
            </a>{' '}
            or simply specify a dollar amount, and we'll generate a printable
            voucher with a unique code.
          </p>
          <a
            href={`${settings.webURL}/vouchers`}
            className="btn btn-primary"
            target="_blank"
            rel="noopener noreferrer"
            data-category="voucher"
            data-action="click"
            data-label="buy-vouchers"
          >
            <span className="mfb-Button--text">Buy Vouchers</span>
            <span className="mfb-Button--status" />
          </a>
        </div>
      </div>
    );
  }
}

export const voucher = connect(
  mapStateToProps,
  mapDispatchToProps
)(Voucher) as ComponentClass<any>;
