import styled from 'styled-components';
import React from 'react';
import {
  SvgFacebookV2Icon as FcebookIcon,
  SvgMessengerIcon as MessengerIcon,
  SvgLinkIcon as LinkIcon,
  SvgEnvelopeIcon as EnvelopeIcon,
  SvgWhatsAppIcon as WhatsAppIcon,
} from '@mfb/lego';
import {ReferAFriendMessages} from '../referAFriendMessages';
import Copy from 'copy-to-clipboard';

const StyledRAF2Title = styled.div`
  font-size: 22.5px;
  font-family: Futura PT Bold;

  margin-bottom: ${c => c.theme.spacing.medium};
`;

const StyledRAF2Description = styled.div`
  font-size: 18px;
  white-space: pre-line;
`;

const StyledCounterValue = styled.div`
  font-size: 28px;
  font-family: Futura PT Bold;

  padding-top: ${c => c.theme.spacing.xxlarge};
  padding-bottom: ${c => c.theme.spacing.small};
`;

const StyledCounterSubtext = styled.div`
  font-size: 16px;

  padding-bottom: ${c => c.theme.spacing.xxlarge}; ;
`;

const StyledCounter = styled.div`
  border: solid 1px #d2d5d1;
  border-radius: ${c => c.theme.radii.medium};

  /*TODO: add this colour to lego*/
  background-color: #fdfbf8;

  padding-left: 32px;
  margin-right: ${c => c.theme.spacing.large};

  width: 300px;

  @media (max-width: ${c => c.theme.layout.breakpoints.sm}) {
    margin-bottom: ${c => c.theme.spacing.xxlarge};
    width: 100%;
  }
`;

const StyledCounterContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: ${c => c.theme.spacing.xlarge};

  @media (max-width: ${c => c.theme.layout.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const StyledInvitationTitle = styled.div`
  margin-bottom: ${c => c.theme.spacing.small};

  font-size: 22.5px;
  font-family: Futura PT Bold;
`;

const StyledInvitationSubTitle = styled.div`
  margin-bottom: ${c => c.theme.spacing.xxlarge};

  font-size: ${c => c.theme.typography.fontSize[500]};
`;

const StyledInvitationItemLabel = styled.a`
  padding-left: ${c => c.theme.spacing.small};
  user-select: none;
  cursor: pointer;
`;

const StyledInvitationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: ${c => c.theme.spacing.medium} ${c => c.theme.spacing.none};

  border: 1px solid ${c => c.theme.colors.neutral[900]};
  border-radius: ${c => c.theme.spacing.small};

  transition: color 0.2s ease;
  &:hover {
    border: 1px solid ${c => c.theme.colors.brand[500]};
  }
`;

const StyledInvitationItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${c => c.theme.spacing.large};

  @media (max-width: ${c => c.theme.layout.breakpoints.xl}) {
    margin-bottom: ${c => c.theme.spacing.large};
    flex-direction: column;
  }
`;

const StyledInvitationContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${c => c.theme.spacing.jumbo} ${c => c.theme.spacing.jumbo};
  margin-top: 47px;
  border: solid 1px ${c => c.theme.colors.neutral[300]};
  border-radius: ${c => c.theme.radii.medium};

  box-shadow: ${c => c.theme.shadows.lg};

  @media (max-width: ${c => c.theme.layout.breakpoints.sm}) {
    margin-top: ${c => c.theme.spacing.xxlarge};
  }
`;

const StyledContainer = styled.div`
  @media (max-width: ${c => c.theme.layout.breakpoints.sm}) {
    padding: 0 15px;
  }
`;
const StyledReferralNote = styled.div`
  margin: 34px 0px;
`;

export interface ReferAFriendLayoutProps {
  rafTitle: string;
  rafDescription: string;
  numberOfRedemptions: number;
  dollarsEarnt: number;
  unencodedURL: string;
  shareMessages: ReferAFriendMessages;
  onFacebookShare: (event: React.SyntheticEvent) => void;
  onMessengerShare: (event: React.SyntheticEvent) => void;
}

enum CopyState {
  UnCopied = 'Copy Link',
  Copied = 'Copied!',
}

export const ReferAFriendLayout = ({
  rafTitle,
  rafDescription,
  numberOfRedemptions,
  dollarsEarnt,
  shareMessages,
  unencodedURL,
  onFacebookShare,
  onMessengerShare,
}: ReferAFriendLayoutProps) => {
  const [copiedState, setCopiedState] = React.useState<CopyState>(
    CopyState.UnCopied
  );

  const handleCopyLink = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    Copy(unencodedURL);

    setCopiedState(CopyState.UnCopied ? CopyState.Copied : CopyState.UnCopied);
  };

  return (
    <StyledContainer>
      <StyledRAF2Title data-test="refer-a-friend-title">
        {rafTitle}
      </StyledRAF2Title>
      <StyledRAF2Description
        className="white"
        dangerouslySetInnerHTML={{__html: rafDescription}}
        data-test="refer-a-friend-description"
      />
      <StyledCounterContainer>
        <StyledCounter>
          <StyledCounterValue data-test="refer-a-friend-credits-earned">{`$${dollarsEarnt}`}</StyledCounterValue>
          <StyledCounterSubtext>Credit Earned</StyledCounterSubtext>
        </StyledCounter>
        <StyledCounter>
          <StyledCounterValue data-test="refer-a-friend-number-of-redemptions">
            {numberOfRedemptions}
          </StyledCounterValue>
          <StyledCounterSubtext>Friends Referred</StyledCounterSubtext>
        </StyledCounter>
      </StyledCounterContainer>
      <StyledInvitationContainer>
        <StyledInvitationTitle>Share Invitation Link</StyledInvitationTitle>
        <StyledInvitationSubTitle>
          Share the link to your friends using any options below.
        </StyledInvitationSubTitle>
        <StyledInvitationItemContainer>
          <StyledInvitationItem>
            <LinkIcon />
            <StyledInvitationItemLabel
              data-test="refer-a-friend-share-through-email"
              onClick={handleCopyLink}
            >
              {copiedState}
            </StyledInvitationItemLabel>
          </StyledInvitationItem>
          <StyledInvitationItem>
            <EnvelopeIcon />
            <StyledInvitationItemLabel
              href={`mailto:?Subject=${shareMessages.email.subject}&Body=${shareMessages.email.body}`}
              data-test="refer-a-friend-share-through-email"
            >
              Email
            </StyledInvitationItemLabel>
          </StyledInvitationItem>
          <StyledInvitationItem>
            <FcebookIcon />
            <StyledInvitationItemLabel
              onClick={onFacebookShare}
              data-test="refer-a-friend-share-through-facebook"
            >
              Facebook
            </StyledInvitationItemLabel>
          </StyledInvitationItem>
          <StyledInvitationItem>
            <MessengerIcon />
            <StyledInvitationItemLabel
              onClick={onMessengerShare}
              data-test="refer-a-friend-share-through-messenger"
            >
              Messenger
            </StyledInvitationItemLabel>
          </StyledInvitationItem>
          <StyledInvitationItem>
            <WhatsAppIcon />
            <StyledInvitationItemLabel
              href={`https://api.whatsapp.com/send?text=${shareMessages.whatsApp}`}
              data-test="refer-a-friend-share-through-whatsapp"
            >
              WhatsApp
            </StyledInvitationItemLabel>
          </StyledInvitationItem>
        </StyledInvitationItemContainer>
      </StyledInvitationContainer>
      <StyledReferralNote>
        {`You'll only be able to use one referral credit per delivery.`}
      </StyledReferralNote>
    </StyledContainer>
  );
};
