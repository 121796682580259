import {ExtraClient} from './CoreClient';
import { globalSettings as settings } from 'config';
import {ExtraDetailModel, ProductDetailModel} from '@mfb/cookbook';
import {format} from 'date-fns';
import { accountApiClient } from './api/accountApi';

export const getExtraDetail = async (
  sku?: string,
  week?: Date,
  promoCode?: string,
): Promise<ExtraDetailModel> => {
  const response = await accountApiClient.productsExtrasDetails(sku, new Date(week), promoCode);
  return response as ExtraDetailModel;
};


export const getExtraDetailLegacy = async (
  sku?: string,
  week?: Date,
  promoCode?: string,
): Promise<ProductDetailModel> => {
  const response = await new ExtraClient(settings.bffHost).getExtraDetail(
    sku,
    format(new Date(week), 'yyyy-MM-dd'),
    promoCode
  );

  return response as ProductDetailModel;
};
