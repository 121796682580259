type TrackingCategory =
  | 'delivery'
  | 'extra'
  | 'recipe'
  | 'subscription'
  | 'swap-to-suit'
  | 'voucher'
  | 'merchandising'
  | 'campaign'
  | 'personal-details';

export default interface TrackingProps {
  category: TrackingCategory;
  action: string;
  label?: string;
}

export const trackingCategory = (
  tracking?: TrackingProps,
  defaultValue = ''
): string | null => {
  if (tracking == null || tracking.category == null) {
    return defaultValue;
  }

  return tracking.category;
};

export const trackingAction = (
  tracking?: TrackingProps,
  defaultValue = ''
): string | null => {
  if (tracking == null || tracking.action == null) {
    return defaultValue;
  }

  return tracking.action;
};

export const trackingLabel = (
  tracking?: TrackingProps,
  defaultValue = ''
): string | null => {
  if (tracking == null || tracking.label == null) {
    return defaultValue;
  }

  return tracking.label;
};
