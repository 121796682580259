import React from 'react';
import { ToggleSwitch } from '@mfb/lego';
import styled from 'styled-components';

export interface Props {
  optOutRecipeCards: boolean;
  optOutCallback: (boolean) => void;
}

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const PrintedRecipeCardsToggle: React.FunctionComponent<Props> = (props: Props) => (
    <Container className={"mb-2"}>
      <TextContainer>
        <div>
          <strong>{"Send me printed recipe cards"}</strong>
        </div>
        <div>
          {props.optOutRecipeCards ? "Turn on to receive printed recipe cards" : "Turn off to cut down on paper usage 🌳"}
        </div>
      </TextContainer>
      <ToggleContainer>
        {/* We do a little switcheroo here from opt out to opt in */}
        <ToggleSwitch isChecked={!props.optOutRecipeCards} onChange={(includeRecipeCards) => props.optOutCallback(!includeRecipeCards)}/>
      </ToggleContainer>
    </Container>
  );
