import {PageState} from '../../state';
import {Action} from 'redux';

export const RECEIVE_PAGE_STATE = 'shared/RECEIVE_PAGE_STATE';

export interface ReceivePageStateAction extends Action {
  type: 'shared/RECEIVE_PAGE_STATE';
  payload: PageState;
}

type ReceivePageStateFunc = (payload: PageState) => ReceivePageStateAction;

export const receivePageState: ReceivePageStateFunc = (payload: PageState) => ({
  type: RECEIVE_PAGE_STATE,
  payload,
});

export const REQUEST_PAGE_STATE = 'shared/REQUEST_PAGE_STATE';

export interface RequestPageStateAction extends Action {
  type: 'shared/REQUEST_PAGE_STATE';
  requestTime: Date;
}

type RequestPageStateFunc = (requestTime: Date) => RequestPageStateAction;

export const requestPageState: RequestPageStateFunc = (requestTime: Date) => ({
  type: REQUEST_PAGE_STATE,
  requestTime,
});
