import React from 'react';
import classnames from 'classnames';
import TrackingProps from '../TrackingProps';
import {
  trackingCategory,
  trackingAction,
  trackingLabel,
} from '../TrackingProps';
import {AsyncButton} from '@mfb/lego';
import {SubmitStage} from './SubmitStage';
import {CSSProperties} from 'react';

export interface SubmitProps {
  disabled: boolean;
  label: string | React.ReactNode;
  stage: SubmitStage;
  className?: string;
  isSecondary: boolean;
  tracking?: TrackingProps;
  dataTest?: string;
  onClickAsync?(): Promise<void>;
  onClick?(): void;
  style?: CSSProperties;
}

class Submit extends React.PureComponent<SubmitProps> {
  static defaultProps = {
    stage: SubmitStage.Valid,
    isSecondary: false,
    disabled: false,
  };

  constructor(props: SubmitProps) {
    super(props);
  }

  handleSubmitAsync = async (
    e: React.SyntheticEvent<HTMLElement>
  ): Promise<void> => {
    if (this.props.onClickAsync != null) {
      e.preventDefault();
      await this.props.onClickAsync();
    }
  };

  handleSubmit = (e: React.SyntheticEvent<HTMLElement>): void => {
    if (this.props.onClick != null) {
      e.preventDefault();
      this.props.onClick();
    }
  };

  render(): React.ReactNode {
    const cn = classnames(this.props.className, 'btn', {
      'btn-primary': !this.props.isSecondary,
      'is-saving': this.props.stage === SubmitStage.Saving,
    });

    const tracking = this.props.tracking;

    const disabled =
      this.props.disabled ||
      this.props.stage === SubmitStage.Invalid ||
      this.props.stage === SubmitStage.Saving;

    const isLoading = this.props.stage === SubmitStage.Saving;

    return (
      <AsyncButton
        data-category={trackingCategory(tracking)}
        data-action={trackingAction(tracking)}
        data-label={trackingLabel(tracking)}
        className={cn}
        data-test={this.props.dataTest || 'submit-button'}
        disabled={disabled}
        onClick={this.props.onClick && this.handleSubmit}
        onClickAsync={this.props.onClickAsync && this.handleSubmitAsync}
        isLoading={isLoading}
        style={this.props.style}
      >
        <span className="mfb-Button--text">{this.props.label}</span>
        <span className="mfb-Button--status" />
      </AsyncButton>
    );
  }
}

export default Submit;
