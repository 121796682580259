import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { SkipPayload } from 'redux/thunks/skipActionThunk';
import styled from 'styled-components';
import { FlexPanel, useCustomerMessageContext } from '@mfb/lego';
import date from '../../../../format/date';
import { navman } from '../../../../navigator';
import { selectCurrentOrder } from '../../../../redux/selectors/orderPageSelectors';
import {
  AppState,
  CustomerMessageBulkGroupState,
  CustomerProfileState,
  OrdersPageState,
  TrackingState,
  UiState,
} from '../../../../redux/state';
import {
  DispatcherProps,
  mapDispatchToProps,
} from '../../../account-settings/containers/accountSettingsContainer';
import {
  Day,
  Order,
  OrderWithRecipesAndExtras,
  SubscriptionDTO,
} from '../../../shared/CoreClient';
import {
  CustomerMessageProxyDeepLink,
  cmpNavigateToUrl,
  displayCustomerMessage,
  getCustomerMessageFromGroup,
} from '../../../shared/CustomerMessageService';
import { DisplayCustomerMessageType } from '../../../shared/CustomerMessageService';
import { TriggerCode } from '../../../shared/constants';
import { asUtcString, isOrderValidForActions } from '../../../shared/helper';
import { useEditDeliveryDetailsService } from '../../../shared/hooks/editDeliveryDetails/useEditDeliveryDetailsService';
import { BagInteractionHandlers } from '../DeliveryContainerWithTabs';
import { PlanYourWeekButton } from '../PlanYourWeekButton';
import { EditDelivery } from './editDelivery/EditDelivery';

interface ComponentProps extends DispatcherProps {
  order?: Order;
  currentOrder?: OrderWithRecipesAndExtras;
  day?: Day;
  deliveryDateId?: number;
  bagInteractionHandlers?: BagInteractionHandlers;
  className?: string;
  triggerCodes?: string[];
  bagSku?: string;
  addressModalState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  subscription?: SubscriptionDTO;
  customerMessageBulkGroupState?: Array<CustomerMessageBulkGroupState>;
  uiState?: UiState;
  tracking?: TrackingState;
}

interface StoreProps {
  customer: CustomerProfileState;
  deliveryWeek: string;
  subscriptionNumber: string;
  isDemandLocked: boolean;
}

const ButtonContainer = styled(FlexPanel)`
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  .choose-meals {
    white-space: nowrap;
  }

  .edit-delivery {
    white-space: nowrap;
  }

  @media ${(c) => c.theme.layout.deviceMediaQueries.max.md} {
    margin-top: 24px;
    .choose-meals {
      width: 100%;
    }

    .edit-delivery {
      width: 100%;
    }

    .unskip {
      width: 100%;

      .labelContainer {
        white-space: nowrap;
      }
    }
  }
`;

export type ManagementButtonsProps = ComponentProps & StoreProps;

export const RawBagManagementButtons: React.FC<ManagementButtonsProps> = ({
  order,
  day,
  bagInteractionHandlers,
  deliveryWeek,
  subscriptionNumber,
  deliveryDateId,
  addressModalState,
  subscription,
  customerMessageBulkGroupState,
  bagSku,
  uiState,
  customer,
  currentOrder,
  fetchOrderPageThunk,
  tracking,
}) => {
  const [isSkipDeliveryButtonLoading, setSkipDeliveryButtonLoading] =
    useState<boolean>(false);
  const customerMessageContext = useCustomerMessageContext();
  const canSkip = isOrderValidForActions(currentOrder, ['canSkip']);
  const canSelectMeals = isOrderValidForActions(currentOrder, [
    'canChangeMeals',
  ]);
  
  const canOnlyAddExtras =
    isOrderValidForActions(currentOrder, ['canAddExtras']) &&
    !isOrderValidForActions(currentOrder, ['canChangeMeals']) &&
    currentOrder.availableRecipes.length > 0;

  const { displayEditDelivery } = useEditDeliveryDetailsService({
    currentOrder,
    deliveryWeek,
    tracking,
    postSuccessAction: fetchOrderPageThunk,
  });

  const onSkipCustomerMessageNavigate = async (
    url: string,
    triggerId?: number
  ) => {
    if (url.startsWith(CustomerMessageProxyDeepLink.SKIP)) {
      const payLoad: Partial<SkipPayload> = {
        subscriptionNumber,
        skipWeek: deliveryWeek,
        triggerId,
      };
      bagInteractionHandlers.skip(order, payLoad);
    } else if (
      url.startsWith(CustomerMessageProxyDeepLink.EDIT_DELIVERY_DETAILS)
    ) {
      await displayEditDelivery();
    } else {
      cmpNavigateToUrl(url);
    }
  };

  const skipHandler = React.useCallback(
    async (checkCustomerTrigger?: boolean) => {
      setSkipDeliveryButtonLoading(true);

      const trackingMetadata = tracking?.subscriptions?.find(c=>c.subscriptionNumber === subscriptionNumber)?.metadata;

      if(trackingMetadata){
        trackingMetadata['trigger_code'] = 'skip';
      }

      //cmp - skip
      const displayContext: DisplayCustomerMessageType = {
        customerMessageContext,
        customerMessageBulkGroupState,
        triggerCode: TriggerCode.SKIP,
        subscriptionNumber,
        deliveryDateId,
        onNavigate: onSkipCustomerMessageNavigate,
        gATrackingData: {
          customer_message_trigger: true,
          week: order.week,
          bag_sku: bagSku,
        },
        trackingMetaData: {...trackingMetadata, customer_message_type: 'Skip Prevention', delivery_week: asUtcString(currentOrder?.deliveryDate.weekStarting)}
      };

      const isCustomerMessageAvailable =
        getCustomerMessageFromGroup(displayContext);

      if (checkCustomerTrigger && canSkip && isCustomerMessageAvailable) {
        displayCustomerMessage(displayContext);
        setSkipDeliveryButtonLoading(false);
        return;
      }

      const payLoad: Partial<SkipPayload> = {
        subscriptionNumber,
        skipWeek: deliveryWeek
      };

      await bagInteractionHandlers.skip(order, payLoad);
      setSkipDeliveryButtonLoading(false);
    },
    [order, bagInteractionHandlers]
  );

  const planYourWeekHandler = React.useCallback(() => {
    navman.planYourWeek({ week: deliveryWeek, subNumber: subscriptionNumber });
  }, [order, bagInteractionHandlers]);

  return (
    <ButtonContainer>
      <EditDelivery
        fetchOrderPageThunk={fetchOrderPageThunk}
        subscription={subscription}
        customer={customer}
        currentOrder={currentOrder}
        deliveryWeek={deliveryWeek}
        productOptionId={order.productOptionId}
        uiState={uiState}
        subscriptionId={order.subscriptionId}
        subscriptionNumber={subscriptionNumber}
        deliveryDateId={deliveryDateId}
        bagName={subscription?.primaryOptionName}
        skipContext={{
          isSkipped: order.isSkipped,
          bagDescription: subscription?.primaryOptionName,
          date: date(day),
          isLoading: isSkipDeliveryButtonLoading,
          onClickAsync: async () => skipHandler(true),
        }}
        addressModalState={addressModalState}
        tracking={tracking}
        className="edit-delivery"
      />
      {(canSelectMeals || canOnlyAddExtras) && (
        <PlanYourWeekButton
          bagDescription={order.bagDescription}
          date={date(day)}
          onClick={planYourWeekHandler}
          label={canOnlyAddExtras? 'extras': undefined}
        />
      )}
    </ButtonContainer>
  );
};

const mapStateToProps = (
  appState: AppState<OrdersPageState>,
  ownProps: ComponentProps & DispatcherProps
): ManagementButtonsProps => {
  const { selectedDay } = appState.ui;
  const { deliveryDays, orders } = appState.pageState;
  const { triggerCodes } = appState.features;
  /*
      If a delivery day is selected then we attempt to locate the delivery date.
      Else we preselect the first deliveryDay
   */
  const currentDelivery = selectedDay
    ? deliveryDays.find((d) => d.order.orderKey == selectedDay) ||
      deliveryDays[0]
    : deliveryDays[0];

  const subscriptionId = currentDelivery.order.subscriptionId;
  // old model does not expose the subscriptionNumber but new model does
  const subscriptionNumber = orders.find(
    (o) => o.subscriptionId == subscriptionId
  ).subscriptionNumber;

  const currentOrder = selectCurrentOrder(appState);

  // ──────▄▀▄─────▄▀▄
  // ─────▄█░░▀▀▀▀▀░░█▄        TODO:
  // ─▄▄──█░░░░░░░░░░░█──▄▄    IMPLEMENT SKIP PROMO STUFF PROPERLY WHEN
  // █▄▄█─█░░▀░░┬░░▀░░█─█▄▄█   MAT OR EVA GIVES US TIME TO DO SO 🤢

  // ───▄▀▀▀▄▄▄▄▄▄▄▀▀▀▄───
  // ───█▒▒░░░░░░░░░▒▒█───
  // ────█░░█░░░░░█░░█────
  // ─▄▄──█░░░▀█▀░░░█──▄▄─
  // █░░█─▀▄░░░░░░░▄▀─█░░█  IN THE MEANTIME... ADD MORE ASCII ART

  return {
    ...ownProps,
    customer: appState.customerProfileState,
    deliveryWeek: currentDelivery.order.week,
    subscriptionNumber: subscriptionNumber,
    isDemandLocked: currentOrder && currentOrder.isDemandLocked,
    subscription: appState.subscriptions.find(
      (c) => c.subscriptionNumber === subscriptionNumber
    ),
    customerMessageBulkGroupState: appState.customerMessageBulkGroupState,
    uiState: appState.ui,
    tracking: appState.tracking,
    triggerCodes,
  };
};

export const BagManagementButtons = connect(
  mapStateToProps,
  mapDispatchToProps
)(RawBagManagementButtons);
