import React from 'react';
import Icon, {IconProps} from './Icon';
import classNames from 'classnames';

class Phone extends Icon {
  static defaultProps: Partial<IconProps> = {
    color: '#78a12e',
    seasonal: true,
    title: 'My Food Bag phone',
    height: 20,
    width: 20,
  };

  renderIcon() {
    const {seasonal} = this.props;

    return (
      <g className={classNames({seasonal})}>
        <g
          id="Cancelling"
          transform="translate(-738.000000, -321.000000)"
          fillRule="nonzero"
        >
          <path
            d="M741.61,328.781 C743.063,331.594 745.407,333.937 748.219,335.39 L750.422,333.187 C750.703,332.906 751.125,332.812 751.453,332.953 C752.578,333.328 753.797,333.516 755.016,333.516 C755.579,333.516 756,333.938 756,334.5 L756,338.016 C756,338.579 755.578,339 755.016,339 C745.641,339 738,331.359 738,321.984 C738,321.421 738.422,321 738.984,321 L742.5,321 C743.063,321 743.484,321.422 743.484,321.984 C743.484,323.25 743.672,324.422 744.047,325.547 C744.141,325.875 744.094,326.297 743.813,326.578 L741.61,328.781 Z"
            id="Shape"
          />
        </g>
      </g>
    );
  }
}

export default Phone;
