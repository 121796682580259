import React from 'react';
import {
  OrderAction,
  Time,
  Day,
  AvailableFrequency,
} from '../../shared/CoreClient';
import styled from 'styled-components';
import { AddressChangeModal } from './addressChangeModal/AddressChangeModal';

export enum DeliveryState {
  Ordered,
  Locked,
}

export interface ChristmasDeliveryMessageProps {
  day: Day;
  timeRangeStart: Time;
  timeRangeEnd: Time;
  deliveryAddress: string;
}

export interface DeliveryMessageProps {
  subscriptionId: number;
  deliveryDate: string;
  deliveryId?: number;
  deliveryAddress: string;
  deliveryState: DeliveryState;
  actions: Array<OrderAction>;
  productOptionId: number;
  deliverySlotId: number;
  deliveryInstructions?: string;
  isProductSameAsSubscription: boolean;
  availableFrequency: AvailableFrequency;
  fetchYourAccountState?(): void;
  addressModalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

interface OrderedMessage {
  actions: Array<OrderAction>;
  availableFrequency: AvailableFrequency;
  deliveryAddress?: string;
  deliveryId?: number;
  deliveryDate?: string;
  productOptionId?: number;
  deliverySlotId?: number;
  deliveryInstructions?: string;
  isProductSameAsSubscription: boolean;
  fetchYourAccountState?(): void;
  addressModalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  hasNoMessage?: boolean;
}

export const OrderedMessage = ({
  actions,
  deliveryId,
  deliveryDate,
  deliveryAddress,
  productOptionId,
  deliverySlotId,
  deliveryInstructions,
  isProductSameAsSubscription,
  availableFrequency,
  fetchYourAccountState,
  addressModalState,
  hasNoMessage,
}: OrderedMessage) => {
  const [isAddressModalOpen, setIsAddressModalOpen] = addressModalState;
  const toggleModal = () => setIsAddressModalOpen(!isAddressModalOpen);

  return (
    <>
      {isAddressModalOpen && (
        <AddressChangeModal
          deliveryId={deliveryId}
          availableFrequency={availableFrequency}
          deliveryAddress={deliveryAddress}
          productOptionId={productOptionId}
          deliverySlotId={deliverySlotId}
          deliveryInstructions={deliveryInstructions}
          isProductSameAsSubscription={isProductSameAsSubscription}
          toggleModal={toggleModal}
          fetchYourAccountState={fetchYourAccountState}
        />
      )}
    </>
  );
};

