import React from 'react';

// We should figure out a better way to manage these logos - this component is a copy and paste from the one generated in Lego.
const BargainBoxLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="NewBargainBoxLogo_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 626.4 354.1"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        '.NewBargainBoxLogo_svg__st0{fill:#4d216d}.NewBargainBoxLogo_svg__st1{fill:#f59220}'
      }
    </style>
    <path
      className="NewBargainBoxLogo_svg__st0"
      d="M66.4 101c-6.6 2.9-16 4.4-29.4 4.4-7.4 0-14.9-.5-20.3-2.1-9.8-2.8-10.4-8.7-10.4-15.5V17.5C6.3 8 9.6 4.2 19.4 2.1 24.6 1 31.4.5 37.8.5c29 0 41 9.8 41 26.1 0 19.3-17.3 22.5-17.3 22.5s22.3 2.5 22.3 25.8c0 13.5-7.4 21.8-17.4 26.1zM32.8 41.1h2.4c2.9 0 8.1 0 11.6-2.7 2.1-1.6 3.2-4 3.2-7 0-7.1-6.3-8.6-12-8.6-2.7 0-5.1.3-5.1.3l-.1 18zm0 42.3s2.4.3 5.7.3C46.1 83.7 55 82 55 72.8c0-4.4-2.1-7.8-5.7-9.7-4.3-2.2-9.2-2.2-13.9-2.2h-2.5v22.5h-.1zM152.4 83.9h-35.3l-2.8 7.9C111 101 107 105.1 97 105.1c-9 0-12.3-3.3-12.3-8.4 0-1.9.5-4 1.7-6.8l32.3-76.6C122 5.2 125.8 1 136 1c10.3 0 13.6 4.4 16.8 12.3L184 90.1c1 2.7 1.4 4.6 1.4 6.3 0 5.7-4.4 8.7-13.5 8.7-9.5 0-12.8-3.3-16.5-13.1l-3-8.1zM125 62h19.6l-9.8-26.6L125 62zM221.3 89.4c0 5.1 0 8.6-2.1 11.4-2.1 2.9-5.5 4.3-11.6 4.3-6 0-9.5-1.4-11.6-4.3-2.1-2.8-2.1-6.3-2.1-11.4V18.6c0-5.7 0-12.5 10.1-15.7 4.6-1.4 11.4-2.4 22-2.4 8.7 0 18.4.6 27.2 4.8 10.9 5.1 17.4 14.4 17.4 28 0 12.7-5.7 22.5-16.1 28.3l21.9 29c5.1 6.8 1.9 14.4-11.7 14.4-10.5 0-14.4-4.4-19.3-11.9l-10.9-16.5c-2.9-4.4-5.1-8.7-5.1-8.7h-8.2v21.5h.1zm0-43.2s2.2.2 4.4.2c11.7 0 16.3-4.6 16.3-11.4 0-7.9-6.2-10.5-14.4-10.5-3.5 0-6.3.5-6.3.5v21.2z"
    />
    <path
      className="NewBargainBoxLogo_svg__st0"
      d="M367.9 85.6c0 7.4-1.4 11.4-10.5 15.2-7.1 3-17.6 5.1-27.7 5.1-30.1 0-54.5-17.7-54.5-52.7 0-37.4 27.6-53.2 55.3-53.2 9.5 0 19.8 1.9 27.1 5.7 5.1 2.7 8.4 6.2 8.4 11.7 0 5.9-3.8 14.7-11.7 14.7-3.3 0-6.5-1.4-10.3-3-4.6-1.9-9-3.2-14.4-3.2-13.1 0-25.2 7.6-25.2 27.1 0 19 11.6 27.7 25.3 27.7 5.5 0 9.3-1.4 9.3-1.4V72c0-5.1 0-8.6 2.2-11.4s5.9-4.3 12.2-4.3 10 1.4 12.2 4.3c2.2 2.8 2.2 6.3 2.2 11.4v13.6h.1zM441.2 83.9h-35.3l-2.8 7.9c-3.3 9.2-7.3 13.3-17.3 13.3-9 0-12.3-3.3-12.3-8.4 0-1.9.5-4 1.7-6.8l32.3-76.6C410.8 5.2 414.6 1 424.8 1c10.3 0 13.6 4.4 16.8 12.3l31.2 76.8c1 2.7 1.4 4.6 1.4 6.3 0 5.7-4.4 8.7-13.5 8.7-9.5 0-12.8-3.3-16.5-13.1l-3-8.1zM413.8 62h19.6l-9.8-26.6-9.8 26.6zM513.5 89.4c0 5.1 0 8.6-2.2 11.4-2.2 2.9-5.9 4.3-12.2 4.3s-10-1.4-12.2-4.3c-2.2-2.8-2.2-6.3-2.2-11.4V16.6c0-5.1 0-8.6 2.2-11.4S492.8.9 499.1.9s10 1.4 12.2 4.3c2.2 2.8 2.2 6.3 2.2 11.4v72.8zM551.8 89.4c0 5.1 0 8.6-2.2 11.4-2.1 2.9-5.5 4.3-11.7 4.3s-9.7-1.4-11.7-4.3C524 98 524 94.5 524 89.4V12C524 5.2 528.9.9 539.5.9c10.9 0 15 4.3 19 9.5l35.2 48.1v-42c0-5.1 0-8.6 2.2-11.4C598 2.3 601.4.8 607.6.8s9.7 1.4 11.7 4.3c2.2 2.8 2.2 6.3 2.2 11.4v77.6c0 7.6-4.9 11.4-13.5 11.4-8.7 0-13-3.6-17.1-9l-39.1-51.1v44z"
    />
    <g>
      <path
        className="NewBargainBoxLogo_svg__st1"
        d="M129.7 342.5c-14.3 6.1-34.5 9.6-63.5 9.6-16 0-32.1-1-43.7-4.4C1.3 341.5 0 328.9 0 314.2V162.5c0-20.5 7.2-28.7 28.3-33.1 11.3-2.4 25.9-3.4 39.6-3.4 62.5 0 88.4 21.2 88.4 56.3 0 41.7-37.2 48.5-37.2 48.5s48.1 5.5 48.1 55.7c.1 29-15.9 46.7-37.5 56zM57.4 213.4h5.1c6.1 0 17.4 0 24.9-5.8 4.4-3.4 6.8-8.5 6.8-15 0-15.4-13.7-18.4-25.9-18.4-5.8 0-10.9.7-10.9.7v38.5zm0 91.2s5.1.7 12.3.7c16.4 0 35.5-3.8 35.5-23.6 0-9.6-4.4-16.7-12.3-20.8-9.2-4.8-19.8-4.8-30-4.8h-5.5v48.5zM301.4 124c72.4 0 117.8 48.8 117.8 115S372.4 354.1 300 354.1 182.3 305.2 182.3 239s46.8-115 119.1-115zm-.6 176.5c33.8 0 55.6-22.5 55.6-61.5 0-38.9-21.9-61.5-55.6-61.5-33.8 0-55.7 22.5-55.7 61.5 0 38.9 21.9 61.5 55.7 61.5z"
      />
      <path
        className="NewBargainBoxLogo_svg__st1"
        d="M550.6 152.6c9.9-16 18.4-25.9 44.4-25.9 22.2 0 28.3 7.5 28.3 16.4 0 7.2-3.8 13.3-10.6 22.2l-53.6 70.3 56 74.8c7.9 10.6 11.3 17.4 11.3 23.6 0 10.2-8.9 17.4-30.7 17.4-25.9 0-35.5-9.9-46.1-26.6l-31.4-49.3-31.1 49.8c-10.6 16.7-19.8 25.9-44.4 25.9-22.2 0-28.3-7.5-28.3-16.7 0-6.8 3.4-12.6 10.2-21.9l57-75.8-51.5-69.3c-7.5-10.2-11.3-17.1-11.3-23.6 0-10.2 8.9-17.4 30.7-17.4 25.9 0 35.5 9.9 46.1 26.6l27.3 43.4 27.7-43.9z"
      />
    </g>
  </svg>
);

export default BargainBoxLogo;
