import { mapDispatchToProps } from 'app/account-settings/containers/accountSettingsContainer';
import { connect } from 'react-redux';
import { AppState } from 'redux/state';
import React from 'react';
import { TickerTapeCampaign } from '@mfb/lego';
import { CampaignTheme, EnrolledCampaignDto } from '@mfb/account-api-client';
import { useCampaignTracking } from 'app/shared/components/Tracking/useCampaignTracking';

export interface Props {
  productSku: string,
  productName: string,
  productBrand: string,
  people: number,
  nights: number,
  subscriptionNumber: string,
  subscriptionFrequency: string,
  isPanelOpen: boolean;
  setIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TickerTapeCampaignWrapperUnconnected = (props) => {
  const {trackOnDisplayCampaignDetailsModal} = useCampaignTracking();
  const campaign: EnrolledCampaignDto = props.campaign.enrolledCampaignDto;
  
  if (!campaign || campaign.theme !== CampaignTheme.TickerTape) {
    return;
  }

  const generateProgressStatus = () => {
    if (campaign.displayCelebration && campaign.rewardApplied) {
      return "Redeemed";
    } else if (campaign.displayCelebration) {
      return "Earned";
    } else {
      return "Progress";
    }
  }

  if (props.isPanelOpen) {
    trackOnDisplayCampaignDetailsModal(props.metadata, 
      {
        campaignName: campaign.campaignName,
        campaignButtonText: campaign.campaignButtonText,
        rewardName: campaign.displayPrize.name,
        progressStatus: generateProgressStatus()
      })
  }

  const {target, progress} = campaign.progressionTowardsNextCampaignPrize;

  return (
    <TickerTapeCampaign 
      header={campaign.drawerHeader}
      progressLabel={campaign.progressionText}
      totalSteps={target - 1} 
      progress={progress} 
      displayCelebration={campaign.displayCelebration}
      isPanelOpen={props.isPanelOpen} 
      setIsPanelOpen={props.setIsPanelOpen}
      valueLabel={campaign.displayPrize.name}
      rewardLabel={campaign.drawerBody}
    />
  )
};

const mapStateToProps = (state: AppState, props: Props) => {
  return {
    metadata: state.tracking.subscriptions.find(c=>c.subscriptionNumber === props.subscriptionNumber)?.metadata,
    campaign: state.campaign,
    ...props
  };
};

export const TickerTapeCampaignWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(TickerTapeCampaignWrapperUnconnected);
