import React from 'react';
import {CONTACT_PHONE} from '../../../shared/constants';
import Pencil from '../../../shared/components/Pencil';
import Phone from '../../../shared/components/Phone';

interface Props {
  showEditPrompt: boolean;
  onEditSubscription(): void;
}

const CancelHeader: React.FunctionComponent<Props> = ({
  showEditPrompt,
  onEditSubscription,
}) => {
  const handleEdit = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    onEditSubscription();
  };

  return (
    showEditPrompt && (
      <div className="row mb-4">
        <div className="col-6 col-md-4">
          <p>If you would like a different option you can edit it.</p>
          <a
            href="#"
            onClick={handleEdit}
            className="d-flex align-items-center track-Link-cancellationSurveyEditBag"
          >
            <Pencil className="mr-2" />
            Edit Option
          </a>
        </div>
        <div className="col-6 offset-md-2 col-md-4">
          <p>If you have any questions, please call anytime.</p>
          <a
            href={`tel:${CONTACT_PHONE}`}
            className="d-flex align-items-center"
          >
            <Phone className="mr-2" /> {CONTACT_PHONE}
          </a>
        </div>
      </div>
    )
  );
};

export default CancelHeader;
