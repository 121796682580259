import {ExperimentState, defaultExperimentState} from '../state';
import {Reducer} from 'redux';
import {
  ExperimentAction,
  REQUEST_VARIATION,
  RECEIVE_VARIATION,
} from '../actions/experimentActions';

// It's only 1 character over :(

const reducer: Reducer<ExperimentState> = (
  state = defaultExperimentState,
  action: ExperimentAction
): ExperimentState => {
  switch (action.type) {
    case REQUEST_VARIATION:
      return {...state, variation: undefined, isLoading: true};
    case RECEIVE_VARIATION:
      return {...state, variation: action.variation, isLoading: false};
    default:
      return state;
  }
};

export default reducer;
