import React from 'react';
import Icon, {IconProps} from './Icon';

class MasterCard extends Icon {
  static defaultProps: Partial<IconProps> = {
    color: '#303030',
    height: 117,
    seasonal: false,
    title: 'Visa',
    width: 187,
  };

  renderIcon() {
    return (
      <g fill="none" id="Page-1">
        <g id="Master" transform="translate(-1.000000, 0.000000)">
          <path
            d="M149,1.01146687e-06 C141,1.76644588e-06 56.3007812,-1.60318373e-06 9,1.01146687e-06 C5,1.23257532e-06 1,4.00000101 1,8.00000101 L1,88.000001 C0.999999537,96.000001 5,100.000001 13,100.000001 C57.6232096,100.000001 141,100.000002 149,100.000001 C157,100.000001 161,96.000001 161,88.000001 L161,12.000001 C161,4.00000101 157,1.01146687e-06 149,1.01146687e-06 Z M149,1.01146687e-06"
            fill="#265697"
            id="Rectangle-1"
          />
          <path
            d="M150.545235,50.5 C150.551812,73.7377654 131.919597,92.5792737 108.92953,92.5854469 C85.9389933,92.5911453 67.2987919,73.7591341 67.2926846,50.5213687 L67.2926846,50.5 C67.2865772,27.2627095 85.9187919,8.4202514 108.907919,8.41407821 C131.898456,8.40790503 150.539128,27.2408659 150.545235,50.4786313 L150.545235,50.5 C150.545235,50.5 150.545235,50.4924022 150.545235,50.5 L150.545235,50.5 L150.545235,50.5 Z M150.545235,50.5"
            fill="#DFAC16"
            id="path2268"
          />
          <path
            d="M52.8125503,8.425 C29.9587248,8.57173184 11.4542953,27.3652793 11.4542953,50.5 C11.4542953,73.7249441 30.102953,92.5745251 53.0808054,92.5745251 C63.8650336,92.5745251 73.6951007,88.4204469 81.0920805,81.6118994 C81.0916107,81.6118994 81.0906711,81.6109497 81.0887919,81.61 L81.0977181,81.61 C82.612349,80.2148603 84.0250336,78.7095531 85.323557,77.1045251 L76.795302,77.1045251 C75.6565101,75.7141341 74.6215436,74.2653352 73.6918121,72.7704749 L88.4002685,72.7704749 C89.2947651,71.3235754 90.104698,69.8211173 90.8234899,68.265 L71.2634228,68.265 C70.5967785,66.8204749 70.0179866,65.3431844 69.5237584,63.8445251 L92.5650336,63.8445251 C93.9537584,59.6505587 94.7073154,55.1636034 94.7073154,50.4995251 C94.7073154,47.4072346 94.3756376,44.3923464 93.7479866,41.4895251 L68.3018121,41.4895251 C68.617047,39.9956145 69.0149664,38.5197486 69.4894631,37.0695251 L92.5391946,37.0695251 C92.0238255,35.5262291 91.425302,34.0223464 90.7445638,32.5645251 L71.2610738,32.5645251 C71.9638926,31.0468715 72.7602013,29.5705307 73.6495302,28.145 L88.3471812,28.145 C87.3690604,26.5698883 86.2866443,25.0650559 85.1163758,23.6395251 L76.9216779,23.6395251 C78.1887248,22.1323184 79.583557,20.7129609 81.0977181,19.3895251 C73.7002685,12.5800279 63.8673826,8.425 53.0808054,8.425 C52.9910738,8.425 52.9022819,8.42452514 52.8125503,8.425 C52.8125503,8.425 52.9022819,8.42452514 52.8125503,8.425 L52.8125503,8.425 L52.8125503,8.425 Z M52.8125503,8.425"
            fill="#BF3126"
            id="path1350"
          />
          <path
            d="M68.0495302,61.2123743 L68.6043624,57.4044693 C68.3013423,57.4044693 67.8555034,57.5355307 67.4622819,57.5355307 C65.9194631,57.5355307 65.749396,56.7064246 65.8475839,56.0933799 L67.0939597,48.3237151 L69.4387248,48.3237151 L70.0048322,44.1117039 L67.7939597,44.1117039 L68.2440268,41.4923743 L63.8119463,41.4923743 C63.7142282,41.5911453 61.1956376,56.2239665 61.1956376,58.0056425 C61.1956376,60.6434916 62.6609396,61.8182961 64.7271141,61.7993017 C66.3451007,61.7855307 67.6051007,61.3329888 68.0495302,61.2123743 C68.0495302,61.2123743 67.6051007,61.3329888 68.0495302,61.2123743 L68.0495302,61.2123743 L68.0495302,61.2123743 Z M68.0495302,61.2123743"
            fill="#FFFFFF"
            id="path4157"
          />
          <path
            d="M69.4537584,53.9522346 C69.4537584,60.2830726 73.5879866,61.7874302 77.1100671,61.7874302 C80.3610738,61.7874302 81.7916107,61.0528212 81.7916107,61.0528212 L82.5724161,56.732067 C82.5724161,56.732067 80.099396,57.8332682 77.8659732,57.8332682 C73.1069128,57.8332682 73.9403356,54.246648 73.9403356,54.246648 L82.9473154,54.246648 C82.9473154,54.246648 83.5289262,51.3438268 83.5289262,50.1609497 C83.5289262,47.2092179 82.0753691,43.6126257 77.2091946,43.6126257 C72.7526846,43.6135754 69.4537584,48.4671229 69.4537584,53.9522346 C69.4537584,53.9522346 69.4537584,48.4671229 69.4537584,53.9522346 L69.4537584,53.9522346 L69.4537584,53.9522346 Z M77.2261074,47.6247207 C79.7273154,47.6247207 79.2659732,50.4658101 79.2659732,50.6956425 L74.345302,50.6956425 C74.345302,50.4021788 74.8104027,47.6247207 77.2261074,47.6247207 C77.2261074,47.6247207 74.8104027,47.6247207 77.2261074,47.6247207 L77.2261074,47.6247207 L77.2261074,47.6247207 Z M77.2261074,47.6247207"
            fill="#FFFFFF"
            id="path4155"
          />
          <path
            d="M105.291879,61.2104749 L106.084899,56.3265363 C106.084899,56.3265363 103.910201,57.4286872 102.417651,57.4286872 C99.2737584,57.4286872 98.0128188,55.0021508 98.0128188,52.3951676 C98.0128188,47.106648 100.717919,44.1971788 103.729329,44.1971788 C105.987651,44.1971788 107.800134,45.4788268 107.800134,45.4788268 L108.523624,40.7335475 C108.523624,40.7335475 105.835906,39.6342458 103.532013,39.6342458 C98.4154362,39.6342458 93.4379195,44.1207263 93.4379195,52.546648 C93.4379195,58.1338547 96.1256376,61.8239944 101.414631,61.8239944 C102.91,61.8244693 105.291879,61.2104749 105.291879,61.2104749 L105.291879,61.2104749 L105.291879,61.2104749 L105.291879,61.2104749 Z M105.291879,61.2104749"
            fill="#FFFFFF"
            id="path4151"
          />
          <path
            d="M43.6730872,43.6824302 C40.6339597,43.6824302 38.3042282,44.6696648 38.3042282,44.6696648 L37.6610738,48.5288547 C37.6610738,48.5288547 39.5839597,47.739162 42.490604,47.739162 C44.1410067,47.739162 45.3483893,47.9267318 45.3483893,49.2824581 C45.3483893,50.1058659 45.2004027,50.4097765 45.2004027,50.4097765 C45.2004027,50.4097765 43.8985906,50.2996089 43.2958389,50.2996089 C39.4627517,50.2996089 35.4361074,51.9530726 35.4361074,56.9381564 C35.4361074,60.8662011 38.0777852,61.767486 39.7150336,61.767486 C42.8420134,61.767486 44.1898658,59.7165642 44.261745,59.7103911 L44.1156376,61.4222626 L48.018255,61.4222626 L49.7593289,49.0849162 C49.7593289,43.8510056 45.2422148,43.6824302 43.6730872,43.6824302 L43.6730872,43.6824302 L43.6730872,43.6824302 L43.6730872,43.6824302 Z M44.6225503,53.7276257 C44.7071141,54.4812291 44.1551007,58.0137151 41.4866443,58.0137151 C40.1101342,58.0137151 39.7521477,56.9509777 39.7521477,56.3222626 C39.7521477,55.0961732 40.4112752,53.6241061 43.6589933,53.6241061 C44.4144295,53.6250559 44.4952349,53.7067318 44.6225503,53.7276257 C44.6225503,53.7276257 44.4952349,53.7067318 44.6225503,53.7276257 L44.6225503,53.7276257 L44.6225503,53.7276257 Z M44.6225503,53.7276257"
            fill="#FFFFFF"
            id="path4149"
          />
          <path
            d="M53.9071812,61.6772626 C54.9055034,61.6772626 60.6126174,61.9336872 60.6126174,55.9813128 C60.6126174,50.4164246 55.3297315,51.5157263 55.3297315,49.2800838 C55.3297315,48.1670112 56.1908725,47.8165642 57.764698,47.8165642 C58.3895302,47.8165642 60.7934899,48.0174302 60.7934899,48.0174302 L61.3525503,44.0618436 C61.3525503,44.0623184 59.7965772,43.7099721 57.2634228,43.7099721 C53.984698,43.7099721 50.6566443,45.032933 50.6566443,49.5593017 C50.6566443,54.6882682 56.205906,54.1735196 56.205906,56.3341341 C56.205906,57.7762849 54.6555705,57.8935754 53.4608725,57.8935754 C51.3932886,57.8935754 49.5314765,57.1765363 49.5248993,57.2107263 L48.9338926,61.1264246 C49.0410067,61.1596648 50.1891946,61.6772626 53.9071812,61.6772626 C53.9071812,61.6772626 50.1891946,61.6772626 53.9071812,61.6772626 L53.9071812,61.6772626 L53.9071812,61.6772626 Z M53.9071812,61.6772626"
            fill="#FFFFFF"
            id="path4145"
          />
          <path
            d="M142.004295,40.1328492 L141.048255,46.1289106 C141.048255,46.1289106 139.38,43.7997207 136.768389,43.7997207 C132.70745,43.7997207 129.32255,48.7487151 129.32255,54.4351676 C129.32255,58.105838 131.127987,61.7024302 134.818725,61.7024302 C137.473557,61.7024302 138.945436,59.8314804 138.945436,59.8314804 L138.75047,61.4293855 L143.061342,61.4293855 L146.447181,40.1257263 L142.004295,40.1328492 L142.004295,40.1328492 L142.004295,40.1328492 L142.004295,40.1328492 Z M139.946107,51.8239106 C139.946107,54.1901397 138.787114,57.3508101 136.384094,57.3508101 C134.789128,57.3508101 134.041678,55.9974581 134.041678,53.8734078 C134.041678,50.4002793 135.584966,48.1086034 137.532282,48.1086034 C139.127718,48.1081285 139.946107,49.2159777 139.946107,51.8239106 C139.946107,51.8239106 139.946107,49.2159777 139.946107,51.8239106 L139.946107,51.8239106 L139.946107,51.8239106 Z M139.946107,51.8239106"
            fill="#FFFFFF"
            id="path4139"
          />
          <path
            d="M19.675302,61.447905 L22.3733557,45.3643855 L22.769396,61.447905 L25.8230872,61.447905 L31.519396,45.3643855 L28.9961074,61.447905 L33.5334228,61.447905 L37.0277852,40.1157542 L30.0113423,40.1157542 L25.641745,53.2043296 L25.4143624,40.1157542 L18.9466443,40.1157542 L15.4020134,61.447905 L19.675302,61.447905 L19.675302,61.447905 L19.675302,61.447905 L19.675302,61.447905 Z M19.675302,61.447905"
            fill="#FFFFFF"
            id="path4133"
          />
          <path
            d="M87.9614765,61.4773464 C89.2515436,54.0619274 89.4906711,48.0406983 92.5692617,49.1428492 C93.1081208,46.2727933 93.6281879,45.1625698 94.2163758,43.9478771 C94.2163758,43.9478771 93.940604,43.8894693 93.3618121,43.8894693 C91.3769128,43.8894693 89.906443,46.6303631 89.906443,46.6303631 L90.3015436,44.1131285 L86.1748322,44.1131285 L83.4100671,61.4773464 L87.9614765,61.4773464 L87.9614765,61.4773464 L87.9614765,61.4773464 L87.9614765,61.4773464 Z M87.9614765,61.4773464"
            fill="#FFFFFF"
            id="path4131"
          />
          <path
            d="M115.51855,43.6824302 C112.479423,43.6824302 110.149691,44.6696648 110.149691,44.6696648 L109.507007,48.5288547 C109.507007,48.5288547 111.429893,47.739162 114.336537,47.739162 C115.98694,47.739162 117.193383,47.9267318 117.193383,49.2824581 C117.193383,50.1058659 117.046336,50.4097765 117.046336,50.4097765 C117.046336,50.4097765 115.744523,50.2996089 115.141302,50.2996089 C111.308685,50.2996089 107.28204,51.9530726 107.28204,56.9381564 C107.28204,60.8662011 109.923248,61.767486 111.560497,61.767486 C114.687477,61.767486 116.035329,59.7165642 116.106738,59.7103911 L115.96157,61.4222626 L119.864188,61.4222626 L121.604792,49.0849162 C121.605262,43.8510056 117.087678,43.6824302 115.51855,43.6824302 L115.51855,43.6824302 L115.51855,43.6824302 L115.51855,43.6824302 Z M116.469423,53.7276257 C116.553987,54.4812291 116.001503,58.0137151 113.332107,58.0137151 C111.955597,58.0137151 111.59855,56.9509777 111.59855,56.3222626 C111.59855,55.0961732 112.257678,53.6241061 115.504926,53.6241061 C116.260362,53.6250559 116.340698,53.7067318 116.469423,53.7276257 C116.469423,53.7276257 116.340698,53.7067318 116.469423,53.7276257 L116.469423,53.7276257 L116.469423,53.7276257 Z M116.469423,53.7276257"
            fill="#FFFFFF"
            id="path4149_1_"
          />
          <path
            d="M125.171738,61.4773464 C126.462275,54.0619274 126.701403,48.0406983 129.779054,49.1428492 C130.318852,46.2727933 130.839389,45.1625698 131.427107,43.9478771 C131.427107,43.9478771 131.151336,43.8894693 130.572544,43.8894693 C128.587644,43.8894693 127.117174,46.6303631 127.117174,46.6303631 L127.512275,44.1131285 L123.385564,44.1131285 L120.620799,61.4773464 L125.171738,61.4773464 L125.171738,61.4773464 L125.171738,61.4773464 L125.171738,61.4773464 Z M125.171738,61.4773464"
            fill="#FFFFFF"
            id="path4131_1_"
          />
        </g>
      </g>
    );
  }
}

export default MasterCard;
