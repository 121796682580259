import { accountApiClient } from 'app/shared/api';
import { ProductCollectionCode } from 'app/shared/constants';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'redux/state';
import { InitOptions } from 'i18next';
import { HttpBackendOptions } from 'i18next-http-backend';

export const FETCH_TRANSLATION = 'FETCH_TRANSLATION';
export const INIT_I18N = 'INIT_I18N';

export interface FetchTranslationsAction extends Action {
  type: typeof FETCH_TRANSLATION;
  locale: string;
  namespace: string;
  payload: Record<string, string | {}>;
}

export interface Initi18nAction extends Action {
  type: typeof INIT_I18N;
  payload: InitOptions<HttpBackendOptions>;
}

const translationsActionCreator = (
  locale: string,
  namespace: string,
  translations: Record<string, string | {}>
): FetchTranslationsAction => ({
  type: FETCH_TRANSLATION,
  locale,
  namespace,
  payload: translations,
});

const i18nActionCreator = (
  initOptions: InitOptions<HttpBackendOptions>
): Initi18nAction => ({
  type: INIT_I18N,
  payload: initOptions,
});

const translationCache: Record<string, {}> = {};

export const initI18nThunk =
  (): ThunkAction<Promise<Initi18nAction>, AppState, never> =>
  async (dispatch) => {
    return dispatch(
      i18nActionCreator({
        backend: {
          loadPath: '{{lng}}|{{ns}}',
          request: async (_, url, __, callback) => {
            try {
              const [locale, namespace] = url.split('|');

              const result = await dispatch(
                fetchTranslationsThunk(locale, namespace)
              );
              callback(undefined, { status: 200, data: result.payload ?? {} });
            } catch (err) {
              console.error(err);
              callback(err, { status: 404, data: {} });
            }
          },
        } as HttpBackendOptions,
      })
    );
  };

export const fetchTranslationsThunk =
  (
    namespace: string = 'common',
    productCollectionCode: string = undefined
  ): ThunkAction<Promise<FetchTranslationsAction>, AppState, never> =>
  async (dispatch) => {
    const result = await getCachedTranslationsAction(
      namespace,
      productCollectionCode
    );

    return dispatch(
      translationsActionCreator(result.locale, result.namespace, result.payload)
    );
  };

async function getCachedTranslationsAction(locale: string, namespace: string) {
  locale = (locale ?? 'default').toLocaleLowerCase();

  switch (locale) {
    case ProductCollectionCode.THE_BUTCHER.toLocaleLowerCase():
      locale = 'butcher';
      break;
    case ProductCollectionCode.THE_SUMMER_BUTCHER.toLocaleLowerCase():
      locale = 'summerbbq';
      break;
    default:
      locale = 'default';
      break;
  }

  try {
    if (!translationCache[`${locale}_${namespace}`]) {
      translationCache[`${locale}_${namespace}`] =
        (await accountApiClient.translations(locale, namespace)) as Record<
          string,
          string | {}
        >;
    }
  } catch {
    translationCache[`${locale}_${namespace}`] = {};
  }
  return {
    locale,
    namespace,
    payload: translationCache[`${locale}_${namespace}`],
  };
}
