import { Store } from 'redux';
import { globalSettings as settings } from 'config';
import {
  LegacyOrdersResponse,
  RateRecipeAccountClient,
  RateRecipeRequest,
} from '../shared/CoreClient';
import {
  receivePageState,
  ReceivePageStateAction,
  requestPageState,
} from '../../redux/actions/sharedActions/pageStateAction';
import { rateRecipesPath } from '../account-settings/components/Nav/paths';
import { ActionCreator } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import { AppState, PageState } from '../../redux/state';

export const fetchPageState: ActionCreator<
  ThunkAction<
    Promise<ReceivePageStateAction>,
    Store<LegacyOrdersResponse | AppState<PageState>>,
    null
  >
> = (periodsBack: number) => async (dispatch) => {
  const requestTime = new Date();
  dispatch(requestPageState(requestTime));
  const rateRecipeClient = new RateRecipeAccountClient(settings.bffHost);
  const orderRatings = await rateRecipeClient.myRatings(periodsBack);

  return dispatch(
    receivePageState({
      ...orderRatings,
      requestTime,
      requestSource: rateRecipesPath,
    })
  );
};

export const rateRecipe: ActionCreator<
ThunkAction<
  Promise<ReceivePageStateAction>,
  Store<LegacyOrdersResponse | AppState<PageState>>,
  null
>
> =
  (payload: RateRecipeRequest) =>
  async (dispatch) => {
    const requestTime = new Date();
    const deliveriesClient = new RateRecipeAccountClient(settings.bffHost);

    const orderResponse = await deliveriesClient.rate(payload);

    return dispatch(
      receivePageState({
        ...orderResponse,
        requestTime,
        requestSource: rateRecipesPath,
      })
    );
  };
