import { AccountTheme } from '@mfb/lego';
import { AccountConfig } from './config-loader';
const packageVersion = require('../../package.json').version;

interface RawSettings {
  bffHost: string;
  publicApiKey: string;
  accountHost: string;
  identityAuthority: string;
  identityPostLogoutRedirectUri: string;
  adb2cTenantId: string;
  adb2cTenantName: string;
  adb2cPasswordResetPolicy: string;
  adb2cSigninIS3MobilePolicy: string;
  adb2cSigninPolicy: string;
  adb2cUserFlowSigninPolicy: string;
  adb2cAccountAppAppId: string;
  adb2cUserFlowAccountAppAppId: string;
  adb2cAccountAppScopes: string;
  adb2cPostLogoutRedirectUri: string;
  adb2cUserManager: 'msal' | 'oidc';
  changeAddressOnSubscriptionEnabled: boolean;
  brand: 'My Food Bag' | 'Bargain Box';
  webURL: string;
  accountWebURL: string;
  myfoodbagWebsiteURL: string;
  bargainBoxMainSite: string;
  gtmContainerId: string;
  sentryDsn: string;
  facebookAppId: string;
  applicationInsightsKey: string;
  vwoId: string;
  mixPanelToken: string;
  mixPanelApiHost: string;
  packageName: string;
  packageVersion: string;
  themeColour: string;
  idenityServerEnabled: boolean;
  public_account_v2_uri: string;
}

export interface Settings extends Readonly<RawSettings> {
  readonly isMyFoodBag: boolean;
  readonly isBargainBox: boolean;
  readonly isDevelopment: boolean;
}

const makeSettings = (): Settings => ({
  get isMyFoodBag() {
    return globalSettings.brand === 'My Food Bag';
  },
  get isBargainBox() {
    return globalSettings.brand === 'Bargain Box';
  },
  get isDevelopment() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  },
  bffHost: AccountConfig.BFF_HOST,
  accountHost: AccountConfig.ACCOUNT_HOST,
  publicApiKey: AccountConfig.PUBLIC_API_KEY,
  identityAuthority: AccountConfig.IDENTITY_AUTHORITY,
  identityPostLogoutRedirectUri:
  AccountConfig.IDENTITY_POST_LOGOUT_REDIRECT_URI,
  adb2cTenantId: AccountConfig.ADB2C_TENANT_ID,
  adb2cTenantName: AccountConfig.ADB2C_TENANT_NAME,
  adb2cPasswordResetPolicy: AccountConfig.ADB2C_PASSWORDRESETPOLICY,
  adb2cSigninIS3MobilePolicy: AccountConfig.ADB2C_SIGNINIS3MOBILEPOLICY,
  adb2cSigninPolicy: AccountConfig.ADB2C_SIGNINPOLICY,
  adb2cUserFlowSigninPolicy: AccountConfig.ADB2C_USERFLOW_SIGNINPOLICY,
  adb2cAccountAppAppId: AccountConfig.ADB2C_ACCOUNTAPP_APPID,
  adb2cUserFlowAccountAppAppId: AccountConfig.ADB2C_USERFLOW_ACCOUNTAPP_APPID,
  adb2cAccountAppScopes: AccountConfig.ADB2C_ACCOUNTAPP_SCOPES,
  adb2cPostLogoutRedirectUri: AccountConfig.ADB2C_POST_LOGOUT_REDIRECT_URI,
  adb2cUserManager: AccountConfig.ADB2C_USERMANAGER,
  changeAddressOnSubscriptionEnabled:
    AccountConfig.CHANGE_ADDRESS_ON_SUBSCRIPTION_ENABLED || false,
  brand: AccountConfig.BRAND,
  webURL: AccountConfig.WEB_URL,
  accountWebURL: AccountConfig.ACCOUNT_WEB_URL,
  bargainBoxMainSite: AccountConfig.BARGAINBOX_MAIN_SITE,
  gtmContainerId: AccountConfig.GTM_CONTAINER_ID,
  myfoodbagWebsiteURL: AccountConfig.MYFOODBAG_WEBSITE_URL,
  sentryDsn: AccountConfig.SENTRY_DSN,
  facebookAppId: AccountConfig.FACEBOOK_APP_ID,
  applicationInsightsKey: AccountConfig.APPLICATION_INSIGHTS_KEY,
  vwoId: AccountConfig.VWO_ID,
  mixPanelToken: AccountConfig.MIX_PANEL_TOKEN,
  mixPanelApiHost: AccountConfig.MIX_PANEL_API_HOST,
  packageName: 'account-legacy-swa',
  themeColour: AccountTheme.colors.secondary[900],
  packageVersion: packageVersion,
  idenityServerEnabled: AccountConfig.IDENTITYSERVER_ENABLED.toLowerCase() === 'true',
  public_account_v2_uri: AccountConfig.PUBLIC_ACCOUNT_V2_URI,
});

const globalSettings = makeSettings();

/**
 * Detects if the variable is defined, or contains a variable template string i.e. #{Some.Var}
 * @param value Variable value to check
 * @returns
 */
function isConfigValueDefined(value: string): boolean {
  if (value && value.startsWith('#{') && value.endsWith('}')) {
    return false;
  }

  return Boolean(value);
}

export { globalSettings, isConfigValueDefined };
