import React from 'react';
import {Route, RouteProps, Switch} from 'react-router-dom';
import {payments} from '../Payments';
import EditPersonalDetailsContainer from '../PersonalDetails/EditPersonalDetailsContainer';
import ViewPersonalDetails from '../PersonalDetails/ViewPersonalDetails';
import {orderHistory} from '../OrderHistory';
import {SubscriptionsView} from '../MySubscriptions/View';
import {SubscriptionsAdd} from '../MySubscriptions/Add';
import {subscriptionsCancel} from '../CancelSubscription/CancelContainer';
import {voucher} from '../Voucher';
import {referAFriend} from '../ReferAFriend/ReferAFriend';
import {
    manageAccountPath,
    subscriptionsPath,
    subscriptionsAddPath,
    subscriptionsRestartPath,
    subscriptionsCancelPath,
    subscriptionsViewPath,
    paymentDetailsPath,
    personalDetailsPath,
    personalDetailsEditPath,
    orderHistoryPath,
    vouchersPath,
    referAFriendPath,
    subscriptionsCancelPathV1, subscriptionsViewPathLegacy,
} from './paths';
import {ComponentClass} from 'react';
import {holidaysPath} from './paths';
import {Holidays} from '../Holidays/Holidays';
import {AccountFeaturesState} from '../../../../redux/state';
import {CancelSubscriptionContainer} from '../MySubscriptions/CancelSubscription/CancelSubscriptionContainer';
import {EditSubscriptionContainer} from "../MySubscriptions/EditSubscription/EditSubscriptionContainer";

interface Props {
  features: AccountFeaturesState;
  defaultRender: RouteProps['render'];
}

const AccountSettingsRoutes: React.FunctionComponent<Props> = ({
  features,
  defaultRender,
}) => (
  <Switch>
    <Route exact path={manageAccountPath} render={defaultRender} />
    <Route exact path={subscriptionsPath} component={SubscriptionsView} />
    <Route
      exact
      path={`${subscriptionsAddPath}/:primaryBagId?`}
      component={SubscriptionsAdd}
    />
    <Route
      exact
      path={`${subscriptionsRestartPath}/:primaryBagId`}
      component={SubscriptionsAdd}
    />
    <Route
      exact
      path={`${subscriptionsCancelPath}/:subscriptionId`}
      component={subscriptionsCancel}
    />
    <Route
      exact
      path={`${subscriptionsCancelPathV1}/:subscriptionNumber`}
      component={CancelSubscriptionContainer}
    />
    <Route
      exact
      path={`${subscriptionsViewPath}/:subscriptionNumber/:extras?`}
      component={EditSubscriptionContainer}
    />
    <Route
      exact
      path={`${paymentDetailsPath}/:outcome?`}
      component={payments}
    />
    <Route
      exact
      path={personalDetailsPath}
      component={ViewPersonalDetails as ComponentClass<any>}
    />
    <Route
      exact
      path={personalDetailsEditPath}
      component={EditPersonalDetailsContainer as ComponentClass<any>}
    />
    <Route exact path={orderHistoryPath} component={orderHistory} />
    <Route exact path={vouchersPath} component={voucher} />
    <Route exact path={referAFriendPath} component={referAFriend} />
    {features.enableCustomerAddressHoliday && (
      <Route exact path={holidaysPath} component={Holidays} />
    )}
  </Switch>
);

export default AccountSettingsRoutes;
