import React from 'react';
import {CSSProperties} from 'react';
import {Colours} from "@mfb/lego";

interface ChangeBagProps {
  label?: string;
  bagName: string;
  btnStyle?: CSSProperties;
  btnLabel?: string;
  onClick?: () => void;
}

export const ChangeBag = (props: ChangeBagProps) => {
  const label = props.btnLabel || 'Change my bag';
  return (
    <div
      className="row"
      style={{
        borderRadius: '4px',
        borderColor: Colours.SECONDARY_DARK_CREAM,
        backgroundColor: Colours.SECONDARY_LIGHT_CREAM,
        borderStyle: 'solid',
        borderWidth: '1px',
      }}
    >
      <div
        className="d-flex w-100 justify-content-between py-2 px-3 align-items-center"
      >
        <div style={{maxWidth:'200px'}}>
          <h5>{props.bagName}</h5>
          <small className="d-none d-sm-block">{props.label}</small>
        </div>
        <div>
          <button
            onClick={props.onClick}
            style={props.btnStyle}
            className="btn btn-secondary"
          >
            <span className="d-none d-xl-block">{label}</span>
            <small className="d-block d-xl-none">{label}</small>
          </button>
        </div>
      </div>
    </div>
  );
};
