import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import {
  AddressSuggestionDto,
  ValidateDeliveryAddressDto,
  ValidateDeliveryAddressRequestBody
} from "@mfb/account-api-client";
import {
  DropdownSearchInputRef,
  DropdownSearchItem,
  EditDeliveryFormContextProps,
  EditDeliveryFormInputValues,
} from '@mfb/lego';
import { OrderWithRecipesAndExtras } from '../../CoreClient';
import { accountApiClient } from '../../api';
import { fetchTimeSlots } from './timeslotSelector';
import { FormDefaults } from './useEditDeliveryDetailsService';

export type AddressIdentifier = {
  pafId?: number;
  addressId?: number;
};

export const serializeAddressIdentifier = (
  addressIdentifier: AddressIdentifier
) => {
  return JSON.stringify(addressIdentifier);
};

export const deserializeAddressIdentifier = (id?: string) => {
  try{
    return JSON.parse(id) as AddressIdentifier;
  }catch (e){
    console.error(e);
  }
};

export const validateAddress = async (
  pafId: number,
  formDefaults: MutableRefObject<FormDefaults>
) => {
  try {
    return await accountApiClient.addressDeliveryValidate(
      new ValidateDeliveryAddressRequestBody({
        addressSuggestionId: pafId,
        deliveryId: formDefaults.current.deliveryId,
      })
    );
  } catch (e) {
    console.error(e);
  }
};

const validateHistoricalAddress = async (
  addressSuggestions: AddressSuggestionDto[],
  formDefaults: MutableRefObject<FormDefaults>,
  fetchingHistory?: boolean
) => {
  //This is to obtain the pafId for an already overridden address since only the addressId  is available.
  if (
    fetchingHistory &&
    !formDefaults.current.pafId &&
    formDefaults.current.addressId
  ) {
    try {
      const requests: Promise<ValidateDeliveryAddressDto>[] =
        addressSuggestions.map((c) => validateAddress(c.suggestionId, formDefaults));
      const validateAddressResponses = await Promise.all(requests);

      addressSuggestions.forEach((c, i) => {
        if (validateAddressResponses[i].addressId ===
          formDefaults.current.addressId
        ) {
          formDefaults.current.pafId = c.suggestionId;
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
};

export const fetchAddresses = async (
  dropdownSearchInputRef: MutableRefObject<DropdownSearchInputRef>,
  addressSearchResults: MutableRefObject<AddressSuggestionDto[]>,
  formDefaults: MutableRefObject<FormDefaults>,
  searchTerm?: string,
  fetchingHistory?: boolean
) => {
  try {
    dropdownSearchInputRef.current?.setLoading(true);

    const addressSuggestions =
      await accountApiClient.addressSearchSuggestion(searchTerm);

    addressSearchResults.current = [
      ...addressSearchResults.current,
      ...addressSuggestions,
    ];

    await validateHistoricalAddress(
      addressSuggestions,
      formDefaults,
      fetchingHistory
    );

    const items = addressSuggestions.map((c) => ({
      id: serializeAddressIdentifier({ pafId: c.suggestionId }),
      text: c.fullAddress,
    }));

    dropdownSearchInputRef.current.setSearchResults(items);

    return addressSuggestions;
  } catch (e) {
    console.error(e);
  } finally {
    dropdownSearchInputRef.current?.setLoading(false);
  }
};

export const onAddressSelected = async (
  searchItem: DropdownSearchItem,
  formDefaults: MutableRefObject<FormDefaults>,
  state: [
    EditDeliveryFormContextProps,
    Dispatch<SetStateAction<EditDeliveryFormContextProps>>,
  ],
  formInputValues: MutableRefObject<Partial<EditDeliveryFormInputValues>>,
  currentOrder: OrderWithRecipesAndExtras,
  addressSearchResults: MutableRefObject<AddressSuggestionDto[]>
) => {
  const [, setState] = state;

  const addressIdentifier = deserializeAddressIdentifier(searchItem?.id);

  const delInstructions = addressSearchResults.current.find(
    (c) => c.suggestionId == addressIdentifier?.pafId
  )?.deliveryInstructions;

  if (searchItem) {
    formInputValues.current.deliveryInstructions = delInstructions ?? '';
    formInputValues.current.address = searchItem;

    const {isValidAddress} = await fetchTimeSlots({
      state,
      formDefaults,
      formInputValues,
      currentOrder,
      pafId: addressIdentifier.pafId,
    });

    setState((c) => ({
      ...c,
      deliveryInstructions: {
        ...c.deliveryInstructions,
        value: delInstructions ?? '',
      },
    }));

    return isValidAddress;
  }

  return false;
};
