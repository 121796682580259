import {combineReducers} from 'redux';
import uiReducer from './uiReducer';
import pageStateReducer from './pageStateReducer';
import pageStateBackupReducer from './pageStateBackupReducer';
import experimentStateReducer from './experimentReducer';
import {
  UiState,
  PageState,
  ExperimentState,
  BagState,
  CustomerProfileState,
  CustomerMessageGroupState,
  TrackingState,
  CampaignState,
} from '../state';
import {AccountFeatures, SubscriptionDTO} from '../../app/shared/CoreClient';
import featuresReducer from './featuresReducer';
import bagStateReducer from './bagStateReducer';
import customerProfileReducer from './customerProfileReducer';
import CustomerMessageBulkReducer from './customerMessageBulkReducer';
import subscriptionsReducer from './subscriptionsReducer';
import productGroupMarketingReducer from './productGroupMarketingReducer';
import translationsReducer from './translationsReducer';
import campaignProgressReducer from './campaignProgressReducer';
import trackingReducer from './trackingReducer';

const rootReducer = combineReducers({
  ui: uiReducer,
  pageState: pageStateReducer,
  pageStateBackup: pageStateBackupReducer,
  experimentState: experimentStateReducer,
  features: featuresReducer,
  bagState: bagStateReducer,
  customerProfileState: customerProfileReducer,
  subscriptions: subscriptionsReducer,
  customerMessageBulkGroupState: CustomerMessageBulkReducer,
  productGroupMarketing: productGroupMarketingReducer,
  translations: translationsReducer,
  campaign: campaignProgressReducer,
  tracking: trackingReducer,
});

export default rootReducer;

// Ideally this would be calculated from the return type of `rootReducer`, but it's not yet possible
// https://github.com/Microsoft/TypeScript/issues/6606
// UPDATE (joel - 2020-06-16): Now possible if we update to a newer version of TypeScript
export interface RootState {
  ui: UiState;
  pageState: PageState;
  pageStateBackup: PageState;
  experimentState: ExperimentState;
  features: AccountFeatures;
  bagState: BagState;
  customerProfileState: CustomerProfileState;
  subscriptions: SubscriptionDTO[];
  customerMessageGroupState: Array<CustomerMessageGroupState>;
  campaign: CampaignState;
  tracking: TrackingState;
}
