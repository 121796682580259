import { TrackingState } from '../state';
import { SubscriptionTrackingMetadata } from '@mfb/account-api-client';
import createUserManager from '../../app/shared/UserManager';

type Fallback = {
  'Customer Number': string
}
export const selectSubscriptionTracking =  (state: TrackingState) => async (subscriptionNumber: string): Promise<Pick<SubscriptionTrackingMetadata, 'metadata'> | Fallback> => {
  const subscriptionTracking = state?.subscriptions?.find(c => c.subscriptionNumber === subscriptionNumber)?.metadata;

  const userMgr = await createUserManager();
  const customerNumber = await userMgr.getCustomerNumber();

  if (!subscriptionTracking) {
    return { 'Customer Number': customerNumber ?? 'unknown' };
  }
  return subscriptionTracking;
};
