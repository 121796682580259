import React from 'react';

export enum DeviceOS {
  android = 1,
  ios = 2,
  other = 3,
}

const ANDROID_REGEX = /android/i;
const IOS_REGEX = /iPad|iPhone|iPod/;

export const useGetDeviceOS = (): DeviceOS => {
  const [operatingSystem, setOperatingSystem] = React.useState<DeviceOS>();
  if (operatingSystem) {
    return operatingSystem;
  }

  const ua = navigator.userAgent;

  if (ANDROID_REGEX.test(ua)) {
    setOperatingSystem(DeviceOS.android);

    return DeviceOS.android;
  } else if (
    IOS_REGEX.test(ua) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    setOperatingSystem(DeviceOS.ios);

    return DeviceOS.ios;
  }
};
