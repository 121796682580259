import { globalSettings, isConfigValueDefined } from 'config';

const loadSentryTag = () => {
  try {
    if (!isConfigValueDefined(globalSettings.sentryDsn)) return;

    (function () {
      if (typeof Raven !== 'undefined') {
        // eslint-disable-next-line no-undef
        Raven.config(globalSettings.sentryDsn).install();
      }
    })();
  } catch (e) {
    console.error(e);
  }
};

export default loadSentryTag;
