/* eslint-disable @typescript-eslint/ban-types */
import {
  BagSelector,
  BagSelectorProductGroup,
  BagSelectorProductResult,
  BagSelectorStateService,
  ProductPriceDisplayContext,
  RecipeCarouselContext,
  RecipeDetailModelLegacy,
  RecipeModel,
  SkuSelectorContext,
} from '@mfb/cookbook';
import { CookbookStrategyContext } from '@mfb/cookbook';
import React from 'react';
import { useMemo, useRef } from 'react';
import { AccountCookbook } from '../AccountCookbook';
import {
  AccountMerchSection,
  AccountSettingClient,
  CodeValidationResponse,
  CostPreviewResponse,
  Frequency,
  ISubscriptionClient,
  ProductGroupResponse,
  ProductSummaryResponse,
  RecipesClient,
  SubscriptionClient,
  SubscriptionDTO,
  SubscriptionStatus,
} from '../CoreClient';
import { globalSettings as settings } from 'config';
import {
  Brand,
  Colours,
  RadioButtons,
  RecipeCarouselItemVariants,
} from '@mfb/lego';
import { connect } from 'react-redux';
import { BrandName, BrandProductOptions } from '../constants';
import styled from 'styled-components';
import { navman } from '../../../navigator';
import { ReactivateSubscriptionPhase2Props } from './ReactivateSubscriptionPhase2';
import {
  ReactivateSubscriptionEvent,
  trackReactivateSubscription,
  trackReactivateSubscriptionWithDefaultProduct,
  trackReactivateSubscriptionWithSku,
} from '../reactivateSubscriptionTracker';
import { format as dateFormat } from 'date-fns';
import { MerchandisingSlots } from '../../merchandising/MerchandisingSlots';
import { getQueryStringParams } from '../getQueryString';
import { useBrandFromBrandId } from '../hooks';
import { accountApiClient } from 'app/shared/api';
import { CostPreviewRequestBody as AccountCostPreviewRequestBody } from '@mfb/account-api-client';
import { AppState } from 'redux/state';
import {
  PageProperties,
  mapDispatchToProps,
  mapStateToProps,
} from 'app/account-settings/containers/accountSettingsContainer';
import { selectUiComponentAttributes } from 'redux/selectors/uiComponentSelector';
import { UiComponentName } from 'redux/actions/sharedActions/updateUiComponent';
import { firstOrDefault } from '../helper';
import { hasBaseActiveSubscription } from '../Subscriptions';
import { useReactivationTracking } from './Tracking/useReactivateFormTracking';

interface CachedCostPreview {
  input: { sku: string; promo: string; nights: number; people: number };
  cachedPreview: CostPreviewResponse;
}

interface CachedPromoValidation {
  input: { sku: string; promo: string };
  codeValidationResponse: CodeValidationResponse;
}

interface CachedRecipeCarousel {
  input: { sku: string; name: string };
  data: RecipeModel[];
}

interface ReactivateSubscriptionProps extends AppState, PageProperties {}

export const ReactivateSubscriptionUnconnected: React.FC<
  ReactivateSubscriptionProps
> = ({
  ui,
  setIsValidPromo,
  setValidationMessages,
  fetchProductGroupMarketing,
  tracking,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [productsGroupResponse, setProductsGroupResponse] = React.useState<
    ProductGroupResponse[]
  >([]);

  const cachedCosts = React.useRef<Array<CachedCostPreview>>([]);
  const cachedPromoValidations = React.useRef<Array<CachedPromoValidation>>([]);
  const cachedRecipeCarousels = React.useRef<Array<CachedRecipeCarousel>>([]);

  const [bagSelectorProductGroups, setBagSelectorProductGroups] =
    React.useState<BagSelectorProductGroup[]>([]);
  const [allProductOptions, setProductOptions] = React.useState<
    Array<ProductSummaryResponse>
  >([]);
  const [externalProductState, setSelectedProductResponse] =
    React.useState<string>('');
  const [priceContext, setPriceContext] =
    React.useState<ProductPriceDisplayContext>({ primaryButtonDisabled: true });
  const [selectedProduct, setSelectedProduct] =
    React.useState<ProductSummaryResponse>();
  const [promoExpired, setPromoExpired] = React.useState<boolean>(false);
  const mapProductOptions = React.useCallback(
    (
      _productGroupResponse: Array<ProductGroupResponse>
    ): ProductSummaryResponse[] => {
      return _productGroupResponse.flatMap((pg) => pg.productOptions || []);
    },
    []
  );

  const {trackOnCompleteProductSelection} = useReactivationTracking();

  const campaignCode = getQueryStringParams().campaignCode;
  const selectedOptions = useRef<ReactivateSubscriptionPhase2Props>();
  const scrollPositionY = useRef<number>();
  const scrollBrandPositionX = useRef<number>();
  const updateRecipeCarousel = useRef<boolean>(true);

  const getPromoCode = (): string => {
    const queryParams = new URLSearchParams(window.location.search);
    const promoCode = queryParams.get('promoCode');
    return promoCode;
  };

  const getBrandFromQueryParam = (): string => {
    const queryParams = new URLSearchParams(window.location.search);
    const brand = queryParams.get('Brand');
    return brand;
  };

  const getSelectedBag = () => {
    if (!selectedOptions.current) {
      return undefined;
    }
    return selectedOptions.current.availableBags.find(
      (r) => r.itemNumber === selectedOptions.current.sku
    );
  };

  const selectedBag = getSelectedBag();
  const selectedBrandName = useBrandFromBrandId(selectedBag?.brand);

  const getSkuFromUrl = (): string => {
    const queryParams = new URLSearchParams(window.location.search);
    const brand = queryParams.get('sku') || '';
    return brand;
  };

  const filterByCanPurchaseProductCollection = (
    productGroupResponse: ProductGroupResponse[]
  ) => {
    return productGroupResponse.filter((c) => {
      const settings = firstOrDefault(c.productOptions)?.settings;
      if (settings) {
        return !selectUiComponentAttributes(
          [
            //product collection level
            {
              name: UiComponentName.REACTIVATE_BAG_SELECTOR,
              productCollectionCode: settings.productCollectionCode,
            },
          ],
          ui.uiComponents
        ).isHidden;
      }
    });
  };

  const bagsToHideByDefault = (response: ProductGroupResponse[]) => {
    response = filterByCanPurchaseProductCollection(response);
    return response.filter(
      (bag) =>
        bag.name !== BrandName.MyClassic && bag.name !== BrandName.MyFamily
      //bag.name !== BrandName.ReadyMadeMeals
    );
  };

  const scrollToYPosition = (offset: number) => {
    const options: ScrollToOptions = {
      top: offset,
      left: 0,
    };
    window.scrollTo(options);
  };

  const filterByBrand = (response: ProductGroupResponse[]) => {
    //filter bags depending on brand query-parameter
    const brandName = getBrandFromQueryParam() || '';

    switch (brandName.toLowerCase()) {
      case Brand.BB.toLowerCase():
        return response.filter((bag) => bag.name === BrandName.BargainBox);
      case Brand.RMM.toLowerCase():
        return response.filter((bag) => bag.name === BrandName.ReadyMadeMeals);
      case Brand.FS.toLowerCase():
        return response.filter(
          (bag) => bag.name === BrandName.FreshStartChoice
        );
      case Brand.MFB.toLowerCase():
        return response.filter(
          (bag) =>
            bag.name !== BrandName.BargainBox &&
            //bag.name !== BrandName.ReadyMadeMeals &&
            bag.name !== BrandName.FreshStartChoice
        );
      default:
        return response;
    }
  };

  const getCachedPreviews = async (
    sku: string,
    nights: number,
    people: number,
    promo: string
  ) => {
    setPriceContext((prevState) => ({
      ...prevState,
      primaryButtonDisabled: true,
    }));
    if (cachedCosts.current.length > 0) {
      const cached = cachedCosts.current.find(
        (x) =>
          x.input.sku === sku &&
          x.input.nights === nights &&
          x.input.people === people
      );

      if (cached) {
        setPriceContext((prevState) => ({
          ...prevState,
          primaryButtonDisabled: false,
        }));
        return cached.cachedPreview;
      }
    }
    const preview = await getCostPreview(sku);
    cachedCosts.current.push({
      input: { sku, nights, people, promo },
      cachedPreview: preview,
    });
    setPriceContext((prevState) => ({
      ...prevState,
      primaryButtonDisabled: false,
    }));
    return preview;
  };

  const getCachedPromoValidations = async (
    subClient: ISubscriptionClient,
    sku: string,
    promo: string
  ) => {
    if (cachedPromoValidations.current.length > 0) {
      //find cached cost preview with current inputs
      const cached = cachedPromoValidations.current.find(
        (x) => x.input.sku === sku && x.input.promo === promo
      );

      if (cached) {
        return cached.codeValidationResponse;
      }
    }
    const codeValidationResponse = await subClient.validatePromoCode(
      sku,
      promo
    );
    cachedPromoValidations.current.push({
      input: { sku, promo },
      codeValidationResponse: codeValidationResponse,
    });
    return codeValidationResponse;
  };

  const handleContinue = async (): Promise<any> => {
    const costPreviewResponse = await getCostPreview(
      selectedOptions.current.sku
    );
    //how much discount the user will save on applying discount
    const totalDiscount =
      selectedProduct.totalPrice - costPreviewResponse.pricing.overallTotal;
    const promoCode = getPromoCode() || '';

    await trackReactivateSubscription(
      ReactivateSubscriptionEvent.REACTIVATION_SUBSCRIPTION_STEP_2,
      selectedProduct,
      selectedBrandName,
      selectedOptions.current.referAFriendCode,
      promoCode,
      promoExpired ? selectedProduct.totalPrice : Number(totalDiscount)
    );

    trackOnCompleteProductSelection({selectedProduct, selectedBrandName})

    navman.continueReactivation({
      ...selectedOptions.current,
      bagName: externalProductState,
      referAFriendCode: selectedOptions.current.referAFriendCode,
      frequency: 1,
      bagId: selectedBag.bagId,
      brandName: selectedBrandName,
      productPriceDisplayContext: {
        ...priceContext,
        secondaryButtonHidden: true,
        primaryButtonHidden: true,
      },
      selectedProduct
    });
  };

  const [carouselContext, setCarouselContext] =
    React.useState<RecipeCarouselContext>({
      carouselItemVariant: RecipeCarouselItemVariants.RecipeCard,
      disableDetails: false,
      showDateSelector: false,
      recipeCollections: [],
    });

  const [promoImageBanner, setPromoImageBanner] =
    React.useState<AccountMerchSection>();

  const setDiscountedPriceDisplay = (price, overAllTotal, deliveryPrice) => {
    setPriceDisplay(overAllTotal, deliveryPrice);
    if (price === overAllTotal) {
      return;
    } else {
      const priceBeforeDelivery = overAllTotal - deliveryPrice;
      setPriceContext((prevState) => ({
        ...prevState,
        pricePerWeekBeforeDiscount: `$${price.toFixed(2)}`,
        pricePerWeek: `$${priceBeforeDelivery.toFixed(2)}`,
        totalPrice: `$${overAllTotal.toFixed(2)}`,
      }));
    }
  };

  const setPriceDisplay = (price, deliveryPrice) => {
    setPriceContext({
      pricePerWeek: `$${price.toFixed(2)}`,
      pricePerWeekStringFormat: 'Per Week',
      primaryButtonText: 'Continue',
      secondaryButtonHidden: true,
    });
    if (deliveryPrice) {
      const totalPrice = price + deliveryPrice;
      setPriceContext((prevState) => ({
        ...prevState,
        deliveryPrice: `$${deliveryPrice.toFixed(2)}`,
        totalPrice: `$${totalPrice.toFixed(2)}`,
      }));
    }
  };

  const validatePromo = (
    validatePromoResp: CodeValidationResponse,
    costPreviewRes: CostPreviewResponse
  ) => {
    //check if promo is only valid for specific product
    if (
      validatePromoResp.validationErrors.length == 0 &&
      validatePromoResp.discount
    ) {
      if (validatePromoResp.discount.validForProducts.length > 0) {
        const discountProduct =
          validatePromoResp.discount.validForProducts.filter(
            (e) => e.itemNumber == selectedOptions.current.sku
          )[0];

        //promo code is not available for selected product
        if (!discountProduct) {
          setPromoImageBanner(undefined);
          setIsValidPromo(false);
          return;
        }
      }
      //check if expired
      if (new Date(validatePromoResp.discount.redeemableTo) > new Date()) {
        if (costPreviewRes && costPreviewRes.pricing) {
          if (
            costPreviewRes.pricing.appliedDiscount &&
            costPreviewRes.pricing.appliedDiscount.hasDiscountApplied
          ) {
            setDiscountedPriceDisplay(
              costPreviewRes.pricing.subTotal,
              costPreviewRes.pricing.overallTotal,
              costPreviewRes.shipping.subTotal
            );
            setPromoImageBanner(displayPromoImageBanner(validatePromoResp));
            setIsValidPromo(true);
            return;
          }
        }
      }
    }
    setPromoImageBanner(undefined);
    setIsValidPromo(false);
    setPriceDisplay(
      costPreviewRes.pricing.subTotal,
      costPreviewRes.shipping.subTotal
    );
  };

  const mapForBagSelector = React.useCallback(
    (
      productGroupResponse: Array<ProductGroupResponse>
    ): BagSelectorProductGroup[] => {
      return productGroupResponse.reduce((final, curr) => {
        return [
          ...final,
          {
            productGroupName: curr.name,
            products: [
              ...(final[curr.name] || []),
              ...curr.productOptions.map((x) => ({
                sku: x.sku,
                night: x.nights,
                serves: x.people,
                recipes: [],
              })),
            ],
          },
        ];
      }, []);
    },
    []
  );

  const displayPromoImageBanner = (
    codeValidationResponse: CodeValidationResponse
  ): AccountMerchSection => {
    const { bannerUrlSmall, bannerUrlMedium, bannerUrlLarge } =
      codeValidationResponse.discount;
    if (!bannerUrlSmall || !bannerUrlMedium || !bannerUrlLarge) {
      return undefined;
    }
    return {
      imageSet: {
        small: codeValidationResponse.discount.bannerUrlSmall,
        medium: codeValidationResponse.discount.bannerUrlMedium,
        large: codeValidationResponse.discount.bannerUrlLarge,
      },
      slotName2: null,
      hyperlink2: null,
      imageSet2: null,
      slotType: 0,
    };
  };

  React.useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const upcomingWeek = await new SubscriptionClient(
          settings.bffHost
        ).upcomingWeek();
        const subscriptionClient = new SubscriptionClient(settings.bffHost);

        // Redirect the user to the deliveries tab, if the user has active subscriptions
        const subscriptions = await subscriptionClient.getSubscriptions();
        if (hasBaseActiveSubscription(subscriptions)) 
        {
          if(campaignCode) {
            navman.campaignModal(campaignCode);
          } else {
            navman.yourAccount();
          }
        }

        const { customer, availableBags } = await new AccountSettingClient(
          settings.bffHost
        ).getPageModel();

        selectedOptions.current = {
          ...selectedOptions.current,
          referAFriendCode: customer.referAFriendCode,
          availableBags: availableBags,
          upcomingWeek: upcomingWeek,
          fullAddress: customer.address.value,
          deliveryInstructions: customer.deliveryInstructions.value,
        };

        const response = (await fetchProductGroupMarketing()).payload;

        let filteredProductGroupResponse = bagsToHideByDefault(response);
        filteredProductGroupResponse = filterByBrand(
          filteredProductGroupResponse
        );

        let validSkuProduct: ProductGroupResponse = null;
        const skuParam = getSkuFromUrl();

        filteredProductGroupResponse.map((c) =>
          c.productOptions.map((u) => {
            if (u.sku.startsWith(skuParam)) {
              validSkuProduct = c;
            }
          })
        );

        if (skuParam && validSkuProduct && skuParam.length > 0) {
          await cacheRecipeCarousels(
            skuParam,
            validSkuProduct.name,
            true,
            null
          );
        }

        filteredProductGroupResponse.map((c, __idx) => {
          const isInit =
            skuParam && validSkuProduct && skuParam.length > 0
              ? false
              : __idx === 0;

          c.productOptions.map((y, idx) => {
            if (y && idx === 0) {
              cacheRecipeCarousels(y.sku, y.name, isInit, null);
            }
          });
        });

        const bagProductGroups = mapForBagSelector(
          filteredProductGroupResponse
        );
        const productOptions = mapProductOptions(filteredProductGroupResponse);

        setProductsGroupResponse(filteredProductGroupResponse);
        setBagSelectorProductGroups(bagProductGroups);
        setProductOptions(productOptions);

        //Selected Bag on load
        if (skuParam && validSkuProduct && skuParam.length > 0) {
          setSelectedProductResponse(validSkuProduct.name);
        } else {
          setSelectedProductResponse(filteredProductGroupResponse[0].name);
        }
      } catch (err) {
        //don't want to break the page if this this can't be loaded
        console.error('Error', err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [mapProductOptions, mapForBagSelector]);

  const skuSelectorContext: SkuSelectorContext = {
    skuSelectorService: new BagSelectorStateService(bagSelectorProductGroups, []),
    nightsLabel:
      externalProductState === 'MADE' ? 'Number of Meals?' : 'How Many Nights?',
    servingsLabel: 'Number of People?',
    feedLabel: `{feed} {serve} {adult}`,
    productOverride: externalProductState,
    hideProductSelector: true,
    isReadyMadeMeal: externalProductState === 'MADE' ? true : false,
    hideDisabled: {
      nights: true,
      servings: false,
    },
  };

  const getBlurb = () => {
    return productsGroupResponse.filter((product) => {
      if (externalProductState == product.name) {
        selectedOptions.current = {
          ...selectedOptions.current,
          blurb: product.blurb,
        };
        return true;
      }
      return false;
    })[0].blurb;
  };

  const getCostPreview = async (sku: string) => {
    const promoCode = getPromoCode() || '';
    const request = new AccountCostPreviewRequestBody({
      sku: sku,
      promoCode: promoCode,
      selections: [],
      extras: [],
      baseSku: null,
    });
    try {
      const res = await accountApiClient.subscriptionsCostCalculate(
        selectedOptions.current.upcomingWeek,
        request
      );
      return res;
    } catch (e) {
      return null;
    }
  };

  const onResult = React.useCallback(
    async (newValue: BagSelectorProductResult | undefined) => {
      const subscriptionClient = new SubscriptionClient(settings.bffHost);
      const promoCode = getPromoCode() || '';
      const skuFromQueryParam = getSkuFromUrl() || '';
      scrollPositionY.current = window.pageYOffset;
      setPriceContext((prevState) => ({
        ...prevState,
        primaryButtonDisabled: true,
      }));

      selectedOptions.current = {
        ...selectedOptions.current,
        sku: newValue.sku,
        promoCode: promoCode,
      };

      setPriceContext((prevState) => ({ ...prevState, isLoading: true }));

      if (updateRecipeCarousel.current) {
        updateRecipeCarousel.current = false;
      }

      const SelectedProduct = allProductOptions.find(
        (product) => product.sku === newValue.sku
      );
      if (!SelectedProduct) {
        return;
      }
      setSelectedProduct(SelectedProduct);
      const sku = newValue.sku;

      const costPreviewResponse = await getCachedPreviews(
        sku,
        newValue.night,
        newValue.serves,
        promoCode
      );
      //how much discount the user will save on applying discount
      const totalDiscount =
        SelectedProduct.totalPrice - costPreviewResponse.pricing.overallTotal;
      if (promoCode) {
        const promoCodeResponse = await getCachedPromoValidations(
          subscriptionClient,
          newValue.sku,
          promoCode
        );
        validatePromo(promoCodeResponse, costPreviewResponse);
        setValidationMessages(promoCodeResponse.validationErrors);
      } else {
        setPriceDisplay(
          costPreviewResponse.pricing.subTotal,
          costPreviewResponse.shipping.subTotal
        );
      }
      setPriceContext((prevState) => ({
        ...prevState,
        primaryButtonDisabled: false,
      }));
      scrollToYPosition(scrollPositionY.current);

      if (skuFromQueryParam) {
        trackReactivateSubscriptionWithSku(
          selectedProduct,
          selectedOptions.current.referAFriendCode,
          promoCode
        );
      } else {
        trackReactivateSubscriptionWithDefaultProduct(
          SelectedProduct,
          selectedBrandName,
          selectedOptions.current.referAFriendCode,
          promoCode,
          promoExpired ? SelectedProduct.totalPrice : Number(totalDiscount)
        );
      }
    },

    [productsGroupResponse, bagSelectorProductGroups, allProductOptions]
  );

  const brandsScrollPositionX = (e) => {
    const brandsElement = document.getElementById('brands-scroll');
    const offset = brandsElement.scrollLeft - brandsElement.clientLeft;
    scrollBrandPositionX.current = offset;
  };

  const ScrollableDiv = styled.div`
    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
  `;
  const cacheRecipeCarousels = async (
    sku: string,
    name: string,
    init: boolean,
    promoCode: string
  ) => {
    const deliveriesClient = new SubscriptionClient(settings.bffHost);

    const response = await deliveriesClient.getProductDetailsForSkuAndWeek(
      sku,
      dateFormat(selectedOptions.current.upcomingWeek, 'yyyy-MM-dd'),
      promoCode
    );

    const recipes: RecipeModel[] = [];

    response.recipes.map((r, idx) => {
      const recipe: RecipeModel = {
        src: r.imageUrl,
        name: r.title,
        id: r.recipeId,
        rnumber: r.recipeNumber,
        rversion: r.version,
        partition: r.recipePartition,
        rid: idx,
      };
      recipes.push(recipe);
    });

    cachedRecipeCarousels.current.push({
      input: { sku: sku, name },
      data: recipes,
    });

    init &&
      setCarouselContext({
        ...carouselContext,
        recipeCollections: [
          {
            day: '0',
            id: '100',
            recipes: recipes,
          },
        ],
      });
  };

  const getRecipeDetails = async (
    recipeNumber: string,
    recipeVersion: string,
    partition: string,
    recipeId: number
  ): Promise<RecipeDetailModelLegacy> => {
    const client = new RecipesClient(settings.bffHost);
    const response = await client.loadRecipeDetails(
      recipeNumber,
      recipeVersion,
      partition,
      selectedOptions.current.upcomingWeek,
      recipeId
    );

    return response as RecipeDetailModelLegacy;
  };

  const scrollBrands = () => {
    const brandsElement = document.getElementById('brands-scroll');
    const offset = brandsElement.scrollLeft - brandsElement.clientLeft;

    if (
      scrollBrandPositionX.current &&
      scrollBrandPositionX.current != offset
    ) {
      const scrollBehaviour: ScrollToOptions = {
        left: scrollBrandPositionX.current,
        top: 0,
      };
      brandsElement.scrollTo(scrollBehaviour);
    }
  };

  const getCachedRecipeCarousel = (productSelected: string) => {
    const r = productSelected.split(' ');
    let _productSelected = `${r[0]} ${r[1]}`;

    if (productSelected.startsWith(BrandName.ReadyMadeMeals)) {
      _productSelected = BrandProductOptions.ReadyMadeMealsFourMeals;
    }
    cachedRecipeCarousels.current.map((c) => {
      //string manipulation in order match the correct brand with the right recipes.
      if (c.input.name.split('(')[0].includes(_productSelected)) {
        setCarouselContext({
          ...carouselContext,
          recipeCollections: [
            {
              day: '0',
              id: '100',
              recipes: c.data,
            },
          ],
        });
      }
    });
  };

  const memoBrands = useMemo(() => {
    return (
      <ScrollableDiv
        id="brands-scroll"
        onScroll={brandsScrollPositionX}
        onLoad={scrollBrands}
        className="d-flex flex-nowrap row mb-1 mx-0"
      >
        <RadioButtons
          options={
            productsGroupResponse &&
            productsGroupResponse.map((productGroup) => ({
              value: `${productGroup.name}`,
              labelBottom: productGroup.name,
              element: (
                <img
                  width={'32px'}
                  height={'32px'}
                  src={productGroup.iconSvgUrl || productGroup.iconUrl}
                  alt={productGroup.name}
                />
              ),
              isDisabled: false,
            }))
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          overrideContainerStyle={{ borderRadius: '15px', width: '120px' }}
          enableResponseLabelSizing={true}
          selected={externalProductState}
          strikethroughDisabledOptions={true}
          selectedColor="secondary"
          unselectedColor="none"
          noWrap={true}
          onChange={(productSelected: string) => {
            setSelectedProductResponse(productSelected);
            updateRecipeCarousel.current = true;
            getCachedRecipeCarousel(productSelected);
          }}
        />
      </ScrollableDiv>
    );
  }, [externalProductState]);

  return (
    <CookbookStrategyContext.Provider value={new AccountCookbook()}>
      {isLoading ? (
        <div className="mt-5 d-flex flex-column flex-fill">
          <div
            className="spinner-border align-self-center"
            style={{
              color: Colours.PRIMARY_GREEN_BRAND_1,
              width: '60px',
              height: '60px',
            }}
          />
        </div>
      ) : (
        <div className="container">
          <h1 className="mt-4 display-4 mb-4 d-none d-sm-block">
            {"Welcome back, let's get you started"}
          </h1>
          <h1 className="mt-4 display-4 mb-4 d-block d-sm-none">
            {"Let's get you started"}
          </h1>
          <div className="mb-3 mb-lg-1 pb-1 pb-lg-1">
            {memoBrands}
            <hr className="ml-0 py-0 d-none d-sm-block" />
            <div>
              <div className={'mb-lg-0 d-block mb-5'}>
                <BagSelector
                  title={externalProductState}
                  overrideMargin={true}
                  blurb={getBlurb()}
                  className="ml-0 py-2"
                  showProductPriceDisplay={true}
                  skuSelectorContext={{
                    ...skuSelectorContext,
                    skuOverride: getSkuFromUrl(),
                  }}
                  productPriceDisplayContext={priceContext}
                  recipeCarouselContext={carouselContext}
                  recipeCarouselOnRecipeFetch={getRecipeDetails}
                  skuSelectorOnResult={onResult}
                  productPricePrimaryOnClickAsync={() => handleContinue()}
                />
              </div>
            </div>
            <div
              className={`container p-md-0 ${
                updateRecipeCarousel.current ? 'd-none' : 'd-block'
              }`}
            >
              <MerchandisingSlots {...promoImageBanner} />
            </div>
          </div>
        </div>
      )}
    </CookbookStrategyContext.Provider>
  );
};

export const ReactivateSubscription = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactivateSubscriptionUnconnected);
