import { globalSettings } from 'config';

const loadVersionTag = () => {
  try {
    window.appVersion = {
      packageName: globalSettings.packageName,
      packageVersion: globalSettings.packageVersion,
    };
  } catch (e) {
    console.error(e);
  }
};

export default loadVersionTag;
