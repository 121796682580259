import React from 'react';
import Icon, {IconProps} from './Icon';
import classNames from 'classnames';

class DeliveriesIcon extends Icon {
  static defaultProps: IconProps = {
    height: 13,
    title: 'Deliveries',
    width: 18,
    color: '#78a12e',
  };

  renderIcon() {
    const {color, seasonal, title} = this.props;

    return (
      <g>
        <title>{title}</title>
        <path
          className={classNames({seasonal})}
          d="M13.902 11.784c.69 0 1.225-.532 1.225-1.217 0-.684-.535-1.216-1.225-1.216-.69 0-1.226.532-1.226 1.216 0 .685.536 1.217 1.226 1.217zm1.225-7.298h-2.029V6.5h3.638l-1.609-2.014zM4.098 11.784c.69 0 1.226-.532 1.226-1.217 0-.684-.536-1.216-1.226-1.216s-1.225.532-1.225 1.216c0 .685.535 1.217 1.225 1.217zm11.451-8.515L18 6.5v4.067h-1.647c0 1.331-1.11 2.433-2.451 2.433-1.34 0-2.451-1.102-2.451-2.433H6.549c0 1.331-1.11 2.433-2.451 2.433-1.34 0-2.45-1.102-2.45-2.433H0V1.635C0 .76.766 0 1.647 0h11.451v3.269h2.451z"
          fillRule="nonzero"
          fill={color}
        />
      </g>
    );
  }
}

export default DeliveriesIcon;
