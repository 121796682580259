import {
  convertToSelectedPrizes,
} from '../campaignUtils';
import {navman} from '../../../navigator';
import {PerformJoinCampaign} from '../../deliveries/index';
import {trackJoinCampaignEvent} from '../../shared/campaignTracker';
import { store } from 'index';
import { fetchCampaignState } from 'redux/actions/sharedActions/campaignProgressAction';
import { accountApiClient } from 'app/shared/api';
import { CampaignGroupDto, CampaignSelection, GroupedCampaignDto, JoinCampaignCommandBody } from '@mfb/account-api-client';
import styled from 'styled-components';
import { AlertStatus, Button, SvgGiftColoured, SvgSubscriptionPlanGift, useThemeScreenSizes } from '@mfb/lego';
import { useAlertToastContext } from 'app/shared/hooks';
import { connect } from 'react-redux';
import { mapDispatchToProps } from 'app/account-settings/containers/accountSettingsContainer';
import { AppState } from 'redux/state';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${c => c.theme.spacing.xxlarge};
  max-width: 540px;
`

const StyledHeader = styled.div`
  font-size: ${c => c.theme.typography.fontSize[600]};
  font-weight: ${c => c.theme.typography.fontWeight[900]};
  line-height: 130%;
  @media ${(c) => c.theme.layout.deviceMediaQueries.min.md} {
    padding-right: 20px;
  }
`

const StyledImage = styled.img`
  border-radius: ${c => c.theme.radii.medium}
`;

const StyledBody = styled.div`
  line-height: 150%;
  font-size: ${c => c.theme.typography.fontSize[400]}
`

const ButtonContainer = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: flex-end;
 color: ${c => c.theme.colors.neutral[100]};
`

const sendJoinCampaignRequest = async (
  campaignCode: string,
  selectedPrizes: Record<number, number>
) => {
  try {
    const request = new JoinCampaignCommandBody(
      {
        campaignSelections: convertToCampaignSelections(selectedPrizes), 
        campaignCode: campaignCode,
         isAutoEnrolled: false
      }
      );
      
    const response = await accountApiClient.campaignJoin(request)

    return response;
  } catch (err) {
    console.error(err);
  }
};

interface Props {
  campaignGroupResponse: CampaignGroupDto;
  onSubmitCallback: PerformJoinCampaign;
}

const UnconnectedTickerTapeCampaignSignUpForm = (props) => {
  const {campaigns} = props.campaignGroupResponse;

  const selectedCampaign: GroupedCampaignDto = campaigns[0];

  const selectedPrizes = convertToSelectedPrizes(selectedCampaign.prizeSelections);
  const {updateToast} = useAlertToastContext();

  const {isScreenSize} = useThemeScreenSizes();
  
  const onSubmitAsync = async () => {
    const response = await sendJoinCampaignRequest(selectedCampaign.campaignCode, selectedPrizes);
    const {isValid, errorMessages} = response;

    trackJoinCampaignEvent(selectedCampaign.campaignCode, selectedPrizes, props.campaignGroupResponse);

    if (isValid) {
      store.dispatch(fetchCampaignState());
      updateToast({
        status: AlertStatus.success,
        isOpen: true,
        text: <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <SvgGiftColoured style={{height: '16px', width: '16px', marginRight: '5px'}}/>
            {`Great! You've joined ${selectedCampaign.campaignName}!`}
          </div>
      });
      navman.yourAccount();
      props.onSubmitCallback();
    }

    if (errorMessages && errorMessages.length) {
      const errorString = errorMessages.join(', ');
      
      updateToast({
        status: AlertStatus.error,
        isOpen: true,
        text: errorString
      });
    }
  };

  return (
    <Container>
      <StyledHeader>{props.campaignGroupResponse.groupTitle}</StyledHeader>
      <StyledImage src={
        isScreenSize.min['sm'] ? 
        selectedCampaign.imageUrlLarge : 
        selectedCampaign.imageUrlMedium} 
      />
      <StyledBody>{props.campaignGroupResponse.groupDescription}</StyledBody>
      <ButtonContainer>
        <Button
          size="lg"
          variant={isScreenSize.max['sm'] ? 'full' : 'default'} 
          onClick={() => {onSubmitAsync()}} 
          label={`Join ${selectedCampaign.campaignName}`}
          leftIcon={<SvgSubscriptionPlanGift/>}
        />
      </ButtonContainer>
    </Container>
  );
};

const mapStateToProps = (state: AppState, props: Props) => {
  return {
    campaign: state.campaign,
    ...props
  };
};

export const TickerTapeCampaignSignUpForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedTickerTapeCampaignSignUpForm);


const convertToCampaignSelections = (
  selectedPrizes: Record<number, number>
): Array<CampaignSelection> => {
  const campaignSelections: Array<CampaignSelection> = [];

  for (const key in selectedPrizes) {
    if (selectedPrizes.hasOwnProperty(key)) {
      campaignSelections.push(new CampaignSelection({
        campaignTierId: Number(key),
        campaignPrizeId: selectedPrizes[key],
      }));
    }
  }

  return campaignSelections;
};