import React from 'react';
import connectAccountSettings, {
  AccountSettingsProps,
} from '../../connectors/connectAccountSettings';
import {
  AccountSettingsCustomer,
  AddressSuggestion,
} from '../../../shared/CoreClient';
import {navman} from '../../../../navigator';
import {mapValues, omit, some} from 'lodash';
import EditPersonalDetails, {
  PersonalDetailsErrors,
  PersonalDetailsForm,
} from './EditPersonalDetails';
import validateMobileNumber from './mobile-validation';
import {showChangesSavedToast} from '../../../toast/ToastMessages';
import {connect} from 'react-redux';
import {
  mapDispatchToProps,
  DispatcherProps,
} from '../../containers/accountSettingsContainer';
import {AccountFeaturesState, PageState} from '../../../../redux/state';
import {getIsIOSWebview} from '../../../shared/getIsIOSWebview';

interface State {
  form: Partial<PersonalDetailsForm>;
  errors: Partial<PersonalDetailsErrors>;
  touchedFields: {
    [k in keyof PersonalDetailsForm]: true;
  };
  isSubmitting: boolean;
}

interface Props {
  features: AccountFeaturesState;
}

const extractValues = (customer: AccountSettingsCustomer) => {
  const values = mapValues(customer, (field: any) => field && field.value);

  return omit(values, 'referAFriendCode');
};
const extractErrors = (customer: AccountSettingsCustomer) =>
  mapValues(customer, (field: any) =>
    field && !field.valid ? field.message : null
  );

class EditPersonalDetailsContainer extends React.Component<
  AccountSettingsProps & DispatcherProps & Props,
  State
> {
  constructor(props: AccountSettingsProps & DispatcherProps & Props) {
    super(props);
    this.state = {
      form: {
        ...extractValues(this.props.accountSettings.customer),
        pafId: null,
        confirmPassword: null,
      },
      errors: extractErrors(this.props.accountSettings.customer),
      touchedFields: {},
      isSubmitting: false,
    };
  }

  handleChangeField = async <K extends keyof PersonalDetailsForm>(
    key: string & K,
    value: PersonalDetailsForm[K]
  ) => {
    this.setState(state => ({
      form: {
        ...state.form,
        [key]: value,
      },
      touchedFields: {
        ...state.touchedFields,
        [key]: true,
      },
      errors: omit(state.errors, key),
    }));
  };

  handleChangeAddress = async (address: AddressSuggestion) => {
    await this.handleChangeField('address', address.fullAddress);
    await this.handleChangeField('pafId', address.pafId);

    // validate address
    let error: string | null = null;
    try {
      await this.props.accountSettingsApi.validateSuggestedAddress({
        pafId: address.pafId,
      });
    } catch (err) {
      if (err && err.result && err.result.customer) {
        error = extractErrors(err.result.customer).address;
      }
    }

    this.setState(state => ({
      errors: {...state.errors, address: error},
    }));
  };

  handleSubmit = async () => {
    const {form} = this.state;

    if (some(this.validate(true))) {
      // TODO: Change FormField to use setCustomValidation so that browsers indicate off-screen errors
      return;
    }

    try {
      const payload = omit(form, 'confirmPassword', 'address');
      if (!payload.password) {
        // If customer has started typing a password then changed their mind and cleared it, don't send it to the server
        // so that it doesn't get validated.
        payload.existingPassword = null;
        payload.password = null;
      }

      this.setState({isSubmitting: true});
      const accountSettings = await this.props.accountSettingsApi.submitAccountInfo(
        payload
      );
      (accountSettings as PageState).requestTime = new Date();
      this.props.setAccountSettingsState(accountSettings);

      if (navman.routeToApp()) {
      } else {
        navman.viewPersonalDetails();
      }
      showChangesSavedToast();
    } catch (err) {
      this.setState({isSubmitting: false});
      if (err && err.result && err.result.customer) {
        this.setState({errors: extractErrors(err.result.customer)});
      }
    }
  };

  handleCancel = async () => {
    navman.viewPersonalDetails();
  };

  validate = (isSubmitting: boolean): PersonalDetailsErrors => {
    const {form, touchedFields} = this.state;
    const errors: PersonalDetailsErrors = {};

    if (form.password && !form.existingPassword) {
      errors.existingPassword =
        'Your existing password is required if changing password.';
    }
    const mobileValidationResult = validateMobileNumber(form.mobileNumber);
    if (mobileValidationResult) {
      errors.mobileNumber = mobileValidationResult;
    }

    if (
      (isSubmitting || touchedFields.confirmPassword) &&
      (form.password || form.confirmPassword) &&
      form.password !== form.confirmPassword
    ) {
      errors.confirmPassword = 'Passwords do not match.';
    }

    return errors;
  };

  render() {
    const {errors, form, isSubmitting} = this.state;
    const allErrors = {...errors, ...this.validate(false)};

    return (
      <EditPersonalDetails
        form={form}
        errors={allErrors}
        isSubmitting={isSubmitting}
        onChange={this.handleChangeField}
        onChangeAddress={this.handleChangeAddress}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        dashboardV2={this.props.features.dashboardV2}
      />
    );
  }
}

export const mapStateToProps = state => state;
export const UnconnectedEditPersonalDetailsContainer = EditPersonalDetailsContainer;
export type UnconnectedEditPersonalDetailsContainer = EditPersonalDetailsContainer;
export const ConnectedToReduxEditPersonalDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedEditPersonalDetailsContainer);
export default connectAccountSettings(
  ConnectedToReduxEditPersonalDetailsContainer
);
