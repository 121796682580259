import { firstOrDefault } from 'app/shared/helper';
import { globalSettings as settings } from 'config';
import { format as dateFormat, format } from 'date-fns';
import React from 'react';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { UiComponentName } from 'redux/actions/sharedActions/updateUiComponent';
import { selectUiComponentAttributes } from 'redux/selectors/uiComponentSelector';
import {
  BrandSelector,
  ProductGroupI,
  RecipeDetailModelLegacy,
} from '@mfb/cookbook';
import { Colours, PreviewMessage, ProductSummaryResponse, SvgInfoAlt } from '@mfb/lego';
import { AppState, UiState } from '../../../../../redux/state';
import {
  AccountSettings,
  AccountSubscription,
  BagDisplay,
  CostPreviewRequestBody,
  OrderAccountClient,
  ProductGroupResponse,
  RecipesClient,
  SubscriptionClient,
} from '../../../../shared/CoreClient';
import {
  BagSelectorContainer,
  IBagSelectorContainerSelectedOptions,
} from '../../../../shared/components/BagSelector/BagSelectorContainer';
import {
  getPromoCodeQueryParam,
  getSkuQueryParam,
} from '../../../../shared/components/BagSelector/common/QueryParam';
import { getCampaignQueryParam } from '../../../../shared/components/BagSelector/common/QueryParam';
import {
  PageProperties,
  mapDispatchToProps,
} from '../../../containers/accountSettingsContainer';
import { RouteParams } from '../View';
import {
  EditSubscriptionDetails,
  IEditSubscriptionDetails,
} from './EditSubscriptionDetails';
import { AccountValidateProductChangeRequestBody } from '@mfb/account-api-client';
import { accountApiClient } from 'app/shared/api';
import styled from 'styled-components';
import { getQueryStringParams } from '../../../../shared/getQueryString';

const MADE = 'MADE';
export interface IEditSubscriptionContainer
  extends AppState,
    PageProperties,
    RouteComponentProps<RouteParams> {
  accountSettings: AccountSettings;
  subscription: AccountSubscription;
  bagSelection?: BagDisplay[];
  uiState?: UiState;
}

interface CustomizedLocationState {
  showDetailsInit?: boolean;
}
  
export const EditSubscriptionContainerUnconnected = ({
  accountSettings,
  subscription,
  bagSelection,
  uiState,
  customerProfileState,
  fetchProductGroupMarketing,
  location,
}: IEditSubscriptionContainer) => {
  const {showEditSubscriptionDetails} = getQueryStringParams();

  const locationState = location?.state as CustomizedLocationState;
  const [isLoading, setLoading] = useState<boolean>(true);
  const [showDetails, setShowDetails] = useState<boolean>(
    locationState?.showDetailsInit || showEditSubscriptionDetails
  );
  const [showBrands, setShowBrand] = useState<boolean>(false);
  const [night, setNight] = useState(0);
  const [serves, setServes] = useState(0);
  const [invalidExtras, setInvalidExtras] = useState<string[]>([]);
  const [isFetchingInvalidExtras, setIsFetchingInvalidExtras] = useState<boolean>(false);
  const [currentProductOptionSku, setCurrentProductOptionSku] = useState<string>('');

  const getCostPreview = async (sku: string) => {
    const promoCode = getPromoCodeQueryParam() || '';
    const request: CostPreviewRequestBody = {
      sku: sku,
      promoCode: promoCode,
      selections: [],
      extras: [],
      baseSku: null,
    };
    try {
      const res = await new OrderAccountClient(
        settings.bffHost
      ).getSubscriptionCostPreview(requestsState.upcomingWeek, request);
      return res;
    } catch (e) {
      return null;
    }
  };

  const getProductDetailsForSkuAndWeek = async (
    sku: string,
    promoCode: string
  ) => {
    return await new SubscriptionClient(
      settings.bffHost
    ).getProductDetailsForSkuAndWeek(
      sku,
      dateFormat(requestsState.upcomingWeek, 'yyyy-MM-dd'),
      promoCode
    );
  };

  const InlinePreviewContainer = styled.div`
    .__preview-message {
      .__heading {
        font-weight: normal;
      }
    }
  `;

  const InlinePreviewSpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
  `;

  /* Set up the default details for the case that user is 
      redirected into the edit details section
  */
  const details = useRef<IEditSubscriptionDetails>({
    bag: {
      name: subscription.primaryBagName,
      sku: subscription.productOptionSku,
    },
    subscriptionNumber: subscription.subscriptionNumber,
    getCostPreview: getCostPreview,
    accountSettings: accountSettings,
    lastSubscription: subscription,
    getProductDetailsForSkuAndWeek: getProductDetailsForSkuAndWeek,
    availableBags: accountSettings.availableBags,
    sku: accountSettings.availableBags.find(
      (b) => b.bagId === subscription.primaryBagId
    ).itemNumber,
    fullAddress: accountSettings.customer.address.value,
    deliveryInstructions: accountSettings.customer.deliveryInstructions.value,
    promoCode: getPromoCodeQueryParam() || '',
  });

  useEffect(() => {
    details.current = {
      ...details.current,
      night: night,
      serves: serves,
    };
  }, [night, serves]);

  const [requestsState, setRequestsState] = useState<{
    upcomingWeek: Date;
    accountSettings: AccountSettings;
    productGroups: ProductGroupResponse[];
  }>(undefined);

  const [selectedBrand, setSelectedBrand] = useState<{
    name: string;
    sku: string;
    skuOverride?: string;
  }>({
    name: subscription.primaryBagName,
    sku: accountSettings.availableBags.find(
      (b) => b.bagId === subscription.primaryBagId
    ).itemNumber,
  });

  const init = async () => {
    try {
      const subscriptionClient = new SubscriptionClient(settings.bffHost);
      const upcomingWeekResult = await subscriptionClient.upcomingWeek();

      let productGroupResult = (await fetchProductGroupMarketing()).payload;

      productGroupResult = filterByCanEditProductCollection(productGroupResult);
      productGroupResult = filterSkuByCampaign(productGroupResult);

      setRequestsState({
        upcomingWeek: upcomingWeekResult,
        productGroups: productGroupResult,
        accountSettings: accountSettings,
      });

      setInitialSelectedBag(productGroupResult);

      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const filterByCanEditProductCollection = (
    productGroupResponse: ProductGroupResponse[]
  ) => {
    return productGroupResponse.filter((c) => {
      const settings = firstOrDefault(c.productOptions)?.settings;
      if (settings) {
        return !selectUiComponentAttributes(
          [
            //product collection level
            {
              name: UiComponentName.EDIT_BAG_SELECTOR,
              productCollectionCode: settings.productCollectionCode,
            },
          ],
          uiState.uiComponents
        ).isHidden;
      }
    });
  };

  useEffect(() => {
    async function getInvalidExtras() {
      if (selectedBrand.sku) {
        setIsFetchingInvalidExtras(true);
        var requestBody = new AccountValidateProductChangeRequestBody();
        requestBody.newSku = selectedBrand.sku;

        const response = await accountApiClient.subscriptionsProductValidate(
          subscription.subscriptionNumber,
          requestBody
        );

        var invalidExtras = response.incompatibleExtras.map((e) => e.productName);

        setInvalidExtras(invalidExtras);
        setIsFetchingInvalidExtras(false);
      }
    }

    if (selectedBrand && selectedBrand.sku && subscription && subscription.subscriptionNumber) {
      getInvalidExtras();
    }
  }, [selectedBrand, subscription]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** For weekly flex alerts */
  const [isSubChanged, setIsSubChanged] = useState(false);
  const hasFlexedAddress = customerProfileState.subscriptions.map(s => s.hasFlexedDeliveryAddress).includes(true)
  const defaultProductOption = useRef<ProductSummaryResponse>();


  const onBrandClick = (identifier: string) => {
    setSelectedBrand({
      name: identifier.split(',')[0].trim(),
      sku: identifier.split(',')[1].trim(),
    });
  };

  const getRecipeDetails = async (
    recipeNumber: string,
    recipeVersion: string,
    partition: string,
    recipeId: number
  ): Promise<RecipeDetailModelLegacy> => {
    const client = new RecipesClient(settings.bffHost);
    const response = await client.loadRecipeDetails(
      recipeNumber,
      recipeVersion,
      partition,
      requestsState.upcomingWeek,
      recipeId
    );

    return response as RecipeDetailModelLegacy;
  };

  const getValidatePromoCode = async (sku: string, promoCode: string) => {
    const client = new SubscriptionClient(settings.bffHost);
    return await client.validatePromoCode(sku, promoCode);
  };

  const getValidCampaign = () => {
    const campaign = accountSettings.enrollableCampaigns.find((c) =>
      c.campaignCodes.find((u) => u === getCampaignQueryParam())
    );

    if (campaign && campaign.skuWhitelist && campaign.skuWhitelist.length > 0) {
      return campaign;
    }
  };

  const filterSkuByCampaign = (productGroupResult: ProductGroupResponse[]) => {
    const campaign = getValidCampaign();

    if (!campaign) {
      return productGroupResult;
    }

    /*@
      With campaigns, we want to display the disabled state for nights and hide the disabled state for people. 
      We are filtering out made here, so we don't show the nights all the way through to 12.
    */
    productGroupResult = productGroupResult.filter((c) => c.name !== MADE);

    const bags = bagSelection.filter((c) =>
      campaign.skuWhitelist.includes(c.sku)
    );

    return productGroupResult.map((c) => {
      const isValidBrandForCampaign = c.productOptions.find((o) =>
        bags.find((p) => p.itemNumber === o.sku)
      );

      const validCampaignSkuCollection = c.productOptions.filter((o) =>
        bags.find((p) => p.itemNumber === o.sku)
      );

      if (isValidBrandForCampaign) {
        return {
          ...c,
          productOptions: validCampaignSkuCollection,
        };
      }
      return c;
    });
  };

  const mapProductGroup = () => {
    const productGroup: ProductGroupI[] = [];
    const campaign = getValidCampaign();

    const setDisabledForNonCampaignWhitelistBrands = (
      brand: ProductGroupResponse
    ) => {
      if (!campaign) {
        return false;
      }

      const whiteListBagCollection = bagSelection.filter((c) =>
        campaign.skuWhitelist.includes(c.sku)
      );

      const bagsForCampaign = brand.productOptions.filter((c) =>
        whiteListBagCollection.find((u) => u.itemNumber === c.sku)
      );

      return !bagsForCampaign.some((c) =>
        brand.productOptions.some((u) => u.sku === c.sku)
      );
    };

    requestsState.productGroups.map((c) => {
      const sku = c.productOptions[0] ? c.productOptions[0].sku : undefined;
      if (!sku) return;

      productGroup.push({
        name: c.name,
        iconUrl: c.iconUrl,
        iconSvgUrl: c.iconSvgUrl,
        productOptions: [{ sku: sku }],
        isDisabled: setDisabledForNonCampaignWhitelistBrands(c),
      });
    });
    return productGroup;
  };

  const fieldStyles: CSSProperties = {
    borderRadius: '10px',
    borderWidth: '1px',
    borderColor: Colours.SECONDARY_CREAM,
  };

  const setInitialSelectedBag = (productGroup: ProductGroupResponse[]) => {
    const sku = getSkuQueryParam();
    const campaign = getValidCampaign();

    //handle campaign
    if (campaign) {
      const whitelistedBags = bagSelection.filter((c) =>
        campaign.skuWhitelist.includes(c.sku)
      );

      const bagForCampaign = productGroup.find((c) =>
        c.productOptions.find((u) => u.sku === whitelistedBags[0].itemNumber)
      );

      const isValidSkuParamForCampaign = whitelistedBags.find(
        (c) => c.sku === sku
      );

      if (isValidSkuParamForCampaign) {
        const bagForSku = productGroup.find((c) =>
          c.productOptions.find((u) => u.sku === sku)
        );
        if (bagForSku) {
          setSelectedBrand({
            name: bagForSku.name,
            sku: bagForSku.productOptions[0].sku,
            skuOverride: sku,
          });
          return;
        }
      }

      if (bagForCampaign) {
        setSelectedBrand({
          name: bagForCampaign.name,
          sku: bagForCampaign.productOptions[0].sku,
          skuOverride: whitelistedBags[0].itemNumber,
        });
        return;
      }
    }

    //handle sku param
    if (sku) {
      const bagForSku = productGroup.find((c) =>
        c.productOptions.find((u) => u.sku === sku)
      );
      if (bagForSku) {
        setSelectedBrand({
          name: bagForSku.name,
          sku: bagForSku.productOptions[0].sku,
          skuOverride: sku,
        });
        return;
      }
    }

    //handle default
    const bag = accountSettings.availableBags.find(
      (c) => c.bagId === subscription.primaryBagId
    );

    const findProductGroup = productGroup.find((u) =>
      u.productOptions.find((c) => c.sku === bag.itemNumber)
    );

    const findProductOption = findProductGroup.productOptions.find(
      (c) => c.sku === bag.itemNumber
    );

    defaultProductOption.current = findProductOption;
    setNight(findProductOption.nights);
    setServes(findProductOption.people);

    if (findProductGroup) {
      setSelectedBrand({
        name: findProductGroup.name,
        sku: findProductGroup.productOptions[0].sku,
        skuOverride: findProductOption.sku,
      });
      return;
    }
    setSelectedBrand({
      name: productGroup[0].name,
      sku: productGroup[0].productOptions[0].sku,
    });
  };

  const getBrandToFocusOnInit = () => {
    let slideNumber = undefined;
    requestsState.productGroups.find((c, index) => {
      //we only care about productOptions[0] because nights/people has no effect on the recipe images
      if (c.productOptions[0].sku === selectedBrand.sku) {
        slideNumber = index;
      }
    });
    // -2 so we can see additional 2 slides to the left
    return slideNumber - 2;
  };

  const brandsMemo = useMemo(() => {
    if (isLoading || !showBrands) return <></>;
    return (
      <BrandSelector
        showHr={true}
        onClick={onBrandClick}
        itemToFocusOnInit={getBrandToFocusOnInit()}
        title={'Change your bag'}
        productGroup={mapProductGroup()}
        selectedBrand={
          selectedBrand && `${selectedBrand.name}, ${selectedBrand.sku}`
        }
      />
    );
  }, [isLoading, showBrands]);

  const inlinePreviewContainerMemo = useMemo(() => {
    if (isLoading || !selectedBrand || !showBrands ||
      currentProductOptionSku === defaultProductOption.current?.sku) return <></>;
    return (
      isFetchingInvalidExtras ? (
        <InlinePreviewSpinnerContainer>
          <div
            className="spinner-border align-self-center"
            style={{
              color: Colours.PRIMARY_GREEN_BRAND_1,
              width: '16px',
              height: '16px',
            }}
          />
        </InlinePreviewSpinnerContainer>
      ) : (
        <InlinePreviewContainer>
        <PreviewMessage
          className="__preview-message"
          headingIcon={<SvgInfoAlt />}
          variant="information"
          heading={`Deliveries from ${format(requestsState.upcomingWeek, 'EEE dd MMM')} will be updated to ${selectedBrand.name}.`}
          expandable={
            invalidExtras.length > 0
              ? {
                  title: 'Things you should know',
                  content: (
                    <div>
                      <p>Your Extras will not be carried over:</p>
                      <ul>
                        {invalidExtras.map((e, i) => (
                          <li key={i}>{e}</li>
                        ))}
                      </ul>
                    </div>
                  )
                }
              : undefined
          }
        />
      </InlinePreviewContainer>
      )
    );
  }, [isLoading, isFetchingInvalidExtras, details.current.sku, currentProductOptionSku]);

  const onContinue = async (
    selectedOpts: IBagSelectorContainerSelectedOptions
  ) => {
    details.current = {
      ...details.current,
      ...selectedOpts,
    };
    setShowDetails(true);
    if (details?.current?.sku && defaultProductOption?.current?.sku) {
      if (details?.current?.sku !== defaultProductOption?.current?.sku) {
        setIsSubChanged(true);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex flex-column flex-fill mt-5">
        <div
          className="spinner-border align-self-center"
          style={{
            color: Colours.PRIMARY_GREEN_BRAND_1,
            width: '72px',
            height: '72px',
          }}
        />
      </div>
    );
  } else
    return (
      <div>
        <div className={`mt-md-0 mt-3 ${showDetails ? 'd-none' : 'd-block'}`}>
          {brandsMemo}
          {inlinePreviewContainerMemo}
          <BagSelectorContainer
            onLoad={() => {
              setSelectedBrand({ ...selectedBrand });
              setShowBrand(true);
            }}
            upcomingWeek={requestsState.upcomingWeek}
            accountSettings={requestsState.accountSettings}
            productGroupResponse={requestsState.productGroups}
            externalProductState={selectedBrand}
            getCostPreview={getCostPreview}
            getProductDetailsForSkuAndWeek={getProductDetailsForSkuAndWeek}
            getRecipeDetails={getRecipeDetails}
            getValidatePromoCode={getValidatePromoCode}
            showLargeLoadSpinner={true}
            hideDisabled={{
              nights: getValidCampaign() === undefined,
              servings: getValidCampaign() !== undefined,
            }}
            onContinue={onContinue}
            onSkuChange={(sku: string) => {
              setCurrentProductOptionSku(sku);
            }}
          />
        </div>
        {showDetails && (
          <div className={`mt-md-0 mt-3`}>
            <EditSubscriptionDetails
              {...details.current}
              subscriptionNumber={subscription.subscriptionNumber}
              getCostPreview={getCostPreview}
              fieldStyles={fieldStyles}
              onChangeBag={() => {
                setShowDetails(false);
              }}
              accountSettings={requestsState.accountSettings}
              lastSubscription={subscription}
              availableCampaigns={getValidCampaign()}
              campaignCode={getCampaignQueryParam()}
              getProductDetailsForSkuAndWeek={getProductDetailsForSkuAndWeek}
              hasFlexedAddress={hasFlexedAddress}
              upcomingWeek={requestsState.upcomingWeek}
              originalDeliverySlotId={Number(subscription.deliverySlotId.value)}
              originalDeliverySlotDescription={subscription.deliverySlotDescription}
              isSubscriptionChanged={isSubChanged}
            />
          </div>
        )}
      </div>
    );
};

const mapStateToProps = (
  state: AppState,
  ownProps: IEditSubscriptionContainer
) => {
  const subscriptionNumber = ownProps.match.params.subscriptionNumber;
  const subscriptionId = Number(ownProps.match.params.subscriptionId);

  const subscription = state.pageState['subscriptions'].find(
    (s) =>
      s.subscriptionNumber === subscriptionNumber || s.id === subscriptionId
  );

  return {
    ...state,
    ...ownProps,
    subscription: subscription,
    accountSettings: state.pageState,
    bagSelection: state.pageState.bagSelection,
    uiState: state.ui,
  };
};

export const EditSubscriptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSubscriptionContainerUnconnected);
