import React from 'react';
import { matchPath } from 'react-router';
import { Location } from 'history';
import { ManageAccountNavigationMenu, MenuItemProps } from '@mfb/lego';
import styled from 'styled-components';

const isPathActive = (path: string, location: Location) => {
  // TODO: need to handle default
  if (path && location) {
    const result = matchPath(location.pathname, { path });

    return result != null;
  }

  return false;
};

const StyledNav = styled.nav`
  padding: 0 ${(p) => p.theme.spacing.xsmall};
`;

interface Props {
  location: Location;
  menuItems: Array<MenuItemProps>;
}

const Nav: React.FunctionComponent<Props> = ({ location, menuItems }) => {
  // Search for the active menu item
  menuItems.forEach((item) => {
    item.isActive = isPathActive(item.path, location);
  });

  return (
    <StyledNav>
      <ManageAccountNavigationMenu menuItems={menuItems} />
    </StyledNav>
  );
};

export default Nav;
