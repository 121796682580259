import { TrackingState } from '../../state';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../state';
import { Action } from 'redux';
import createUserManager from '../../../app/shared/UserManager';
import { accountApiClient } from '../../../app/shared/api';

export const RECEIVE_TRACKING = 'RECEIVE_TRACKING';

export interface ReceiveTrackingAction extends Action {
  type: typeof RECEIVE_TRACKING;
  payload: TrackingState;
}

const receiveTrackingActionCreator = (
  tracking: TrackingState,
): ReceiveTrackingAction => ({
  type: RECEIVE_TRACKING,
  payload: tracking,
});

export const fetchTracking = (): ThunkAction<Promise<ReceiveTrackingAction>, AppState, never> => {
  return async (dispatch) => {
    try {
      const response = await accountApiClient.trackingMe();

      const trackingState: TrackingState = {
        subscriptions: [...response],
      };

      return dispatch(
        receiveTrackingActionCreator(
          trackingState,
        ),
      );
    } catch (e) {
      console.error(e);
    }
  };
};


export type TrackingStateAction = ReceiveTrackingAction | Action;
