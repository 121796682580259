import { RecipeItem, RecipeAddOn } from '@mfb/cookbook';
import { CarouselItemModel } from '@mfb/lego';
import React from 'react';
import {
  DeliveryStatus,
  RecipeResponse,
  RecipeSelectionMode,
} from '../../shared/CoreClient';

const PADDING_SIZES = { small: '100%' };

interface Props {
  recipe: RecipeResponse;
  recipeSelectionMode?: RecipeSelectionMode;
  maxHeight?: number;
  className?: string;
  deliveryStatus?: DeliveryStatus;
}

export const mapRecipesToRecipeItem = (props: Props): CarouselItemModel => {
  const {
    recipe,
    recipeSelectionMode,
    maxHeight,
    className,
    deliveryStatus,
  } = props;

  const key =
    recipe.recipeNumber + recipe.recipeVersion + recipe.recipePartition;

  const selectedExtra: RecipeAddOn =
    recipe.recipeAddOns 
      ? recipe.recipeAddOns[0]
      : undefined ;

  return {
    key: `ckey-${key}`,
    component: (
      <RecipeItem
        key={key}
        deliveryStatus={deliveryStatus}
        selectedRecipeExtra={selectedExtra}
        recipe={recipe}
        showQuantityIndicator={Boolean(
          recipeSelectionMode === RecipeSelectionMode.Multiple &&
            recipe.quantity
        )}
        hideQuantitySelector={true}
        paddingSizes={PADDING_SIZES}
        className="h-100"
        cardClassName={className}
        cardHeight={maxHeight}
      />
    ),
  };
};
