/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { AccountSettings } from '../../../shared/CoreClient';
import MobileStickyHeaderProxy from '../../../shared/components/MobileStickyHeader/MobileStickyHeaderProxy';
import { navman } from '../../../../navigator';
import { connect } from 'react-redux';
import { globalSettings as settings } from 'config';
import { ReferAFriendLayout } from './ReferAFriendLayout';
import { AccountFeaturesState, AppState } from '../../../../redux/state';
import referAFriendMessages from '../referAFriendMessages';

export interface ReferAFriendProps {
  accountSettings: AccountSettings;
  features: AccountFeaturesState;
}

export const ReferAFriendUnconnected: React.FunctionComponent<
  ReferAFriendProps
> = ({ accountSettings, features }: ReferAFriendProps) => {
  const {
    rafTitle,
    rafDescriptionTemplate,
    rafCode,
    customerFirstName,
    rafFriendPercentOffDisplay,
    dollarsEarnt,
    numberOfRedemptions,
  } = accountSettings.myDeals ?? {};
  const encodedCode = encodeURIComponent(`?raf=${rafCode}`);

  // the mailto and whatsapp parser accepts encoded URLs whereas the Facebook SDK accepts the unencoded version
  const encodedURL = `${settings.webURL}/refer${encodedCode}`;
  const unencodedURL = `${settings.webURL}/refer?raf=${rafCode}`;
  const rafUrl = `${settings.webURL}/terms#refer-a-friend`;

  const rafDescription = rafDescriptionTemplate.replace(
    '[[termsAndConditions]]',
    `<a href=${rafUrl}>Terms apply.</a>`
  );

  const shareMessages = referAFriendMessages(
    customerFirstName,
    rafCode,
    rafFriendPercentOffDisplay,
    encodedURL
  );

  const onFacebookShare = (event: React.SyntheticEvent) => {
    event.preventDefault();

    FB.ui({
      method: 'share',
      display: 'popup',
      href: unencodedURL,
      quote: shareMessages.facebook,
    });
  };

  const onMessengerShare = (event: React.SyntheticEvent) => {
    event.preventDefault();

    FB.ui({
      method: 'send',
      link: `${settings.myfoodbagWebsiteURL}/refer?raf=${rafCode}&name=${customerFirstName}`,
    });
  };

  return (
    <React.Fragment>
      <MobileStickyHeaderProxy
        hiddenUp="md"
        onBack={features.dashboardV2 ? undefined : navman.accountSettings}
      />
      <ReferAFriendLayout
        rafTitle={rafTitle}
        rafDescription={rafDescription}
        shareMessages={shareMessages}
        unencodedURL={unencodedURL}
        onFacebookShare={onFacebookShare}
        onMessengerShare={onMessengerShare}
        dollarsEarnt={dollarsEarnt}
        numberOfRedemptions={numberOfRedemptions}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (
  state: AppState<AccountSettings>
): ReferAFriendProps => {
  const accountSettings = state.pageState as AccountSettings;
  const features = state.features;

  return {
    accountSettings,
    features,
  };
};

export const referAFriend = connect<ReferAFriendProps, null, {}>(
  mapStateToProps
)(ReferAFriendUnconnected) as any;
