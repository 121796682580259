import React from 'react';
import { navman } from '../../../../navigator';
import MobileStickyHeaderProxy from '../../../shared/components/MobileStickyHeader/MobileStickyHeaderProxy';
import {
  CHANGE_HOLIDAY_ADDRESS,
  SKIP_HOLIDAY_DELIVERIES,
  HolidayActionSelector,
} from './HolidayActionSelector';

import {
  HELP_REQUEST_URL,
  DELIVERY_HOLIDAY_URL,
} from '../../../shared/constants';
import { HolidayAddress } from './HolidayAddress';
import { connect } from 'react-redux';
import { AppState } from '../../../../redux/state';
import {
  AccountSettings,
  AccountSubscription,
  Frequency,
  GetCustomerAddressHolidayInformationResponse,
} from '../../../shared/CoreClient';
import { getQueryParams } from './HolidaysQueryParams';
import { HolidaySkip } from './HolidaySkip';
import { TermsAndConditions } from './TermsAndConditions';
import { HolidayClient } from '../../../shared/CoreClient';
import { globalSettings as settings } from 'config';
import { showBadToast } from '../../../toast/ToastMessages';
import { isEmpty } from 'lodash';
import { HolidayAddressReadOnly } from './HolidayAddressReadOnly';
import Spinner from '../../../shared/components/Spinner';
import { sendErrorPageEvent } from './holidaysTracker';

interface Props {
  existingCustomerAddressHoliday: GetCustomerAddressHolidayInformationResponse;
  subscriptions: Array<AccountSubscription>;
}

const UnconnectedHolidays: React.FC<Props> = ({
  existingCustomerAddressHoliday,
  subscriptions,
}) => {
  const [selectedHolidayOption, setSelectedHolidayOption] = React.useState(
    CHANGE_HOLIDAY_ADDRESS
  );

  React.useEffect(() => {
    const queryParams = getQueryParams();

    if (queryParams.changeAddress) {
      setSelectedHolidayOption(CHANGE_HOLIDAY_ADDRESS);
    } else if (queryParams.skipDeliveries) {
      setSelectedHolidayOption(SKIP_HOLIDAY_DELIVERIES);
    }
  }, []);

  const recurringSubs = subscriptions.filter(
    (s) => s.primaryFrequency.value !== Frequency.OneOff
  );

  if (!isEmpty(existingCustomerAddressHoliday)) {
    return <HolidayAddressReadOnly {...existingCustomerAddressHoliday} />;
  }

  if (recurringSubs.length !== 1 && isEmpty(existingCustomerAddressHoliday)) {
    sendErrorPageEvent();
    return (
      <div className="mb-5 pb-5">
        <h3 className="mt-2">
          This page is only available to customers with a single active
          subscription
        </h3>
        <p>
          We are unable to action this request for accounts with no active
          subscriptions, or more than one subscription. If you’d still like to
          take My Food Bag with you, review your{' '}
          <a
            href="#"
            data-category="holiday"
            data-action="review-subscriptions"
            onClick={navman.subscriptions}
          >
            subscriptions{' '}
          </a>
          or get in touch with our{' '}
          <a
            data-category="holiday"
            data-action="contact-customer-love"
            href={HELP_REQUEST_URL}
            target="_blank"
            rel="noreferrer"
          >
            Customer Love team.{' '}
          </a>
          We’d love to get dinner deliciously sorted for you, wherever you are!
        </p>
      </div>
    );
  }

  const subscription = recurringSubs[0];
  const subProductOptionId = subscription.primaryBagId;

  return (
    <>
      <div className="mb-5 pb-5">
        <h3 className="mt-2">Get dinner deliciously sorted this summer 🏖</h3>
        <p>
          Don’t stress about dinner these summer holidays. Take My Food Bag with
          you and get deliciously sorted! Tell us where you’re heading and for
          how long and let us take care of the rest. Unfortunately not all areas
          are available over the Christmas and New Year Public Holiday periods,
          so please check your emails for confirmation of your order over these
          weekends.{' '}
          <a
            data-category="holiday"
            data-action="check-delivery-holiday-areas"
            href={DELIVERY_HOLIDAY_URL}
            target="_blank"
            rel="noreferrer"
          >
            Click here{' '}
          </a>
          to review the areas that will be impacted and please check your emails
          for confirmation of your order over these weekends.
        </p>
        <TermsAndConditions className="d-block d-md-none mt-3" />
        <hr className="my-5" />
        <HolidayActionSelector
          selectedOption={selectedHolidayOption}
          setSelectedOption={setSelectedHolidayOption}
        />
        {selectedHolidayOption === CHANGE_HOLIDAY_ADDRESS && (
          <HolidayAddress subProductOptionId={subProductOptionId} />
        )}
        {selectedHolidayOption === SKIP_HOLIDAY_DELIVERIES && <HolidaySkip />}
        {!selectedHolidayOption && (
          <button
            className="btn btn-secondary rounded-lg mt-5 px-5"
            onClick={navman.yourAccount}
          >
            Cancel
          </button>
        )}
        <TermsAndConditions className="d-none d-md-block mt-5" />
      </div>
    </>
  );
};

const HolidaysLoadingWrapper: React.FC<AppState> = (appState) => {
  const [existingCustomerAddressHoliday, setExistingCustomerAddressHoliday] =
    React.useState<GetCustomerAddressHolidayInformationResponse>(null);

  React.useEffect(() => {
    try {
      new HolidayClient(settings.bffHost)
        .getCustomerAddressHolidayInformation()
        .then((r) => setExistingCustomerAddressHoliday(r || {}));
    } catch (e) {
      showBadToast(
        `Something went wrong while trying to load your holiday information.`
      );
    }
  }, []);

  const accountSettings = appState.pageState as AccountSettings;
  const subscriptions = accountSettings.subscriptions;

  if (existingCustomerAddressHoliday === null) {
    return <Spinner />;
  }

  return (
    <>
      <MobileStickyHeaderProxy
        hiddenUp="md"
        onBack={
          appState.features.dashboardV2 ? undefined : navman.accountSettings
        }
      />
      <UnconnectedHolidays
        existingCustomerAddressHoliday={existingCustomerAddressHoliday}
        subscriptions={subscriptions}
      />
    </>
  );
};

const mapStateToProps = (state: AppState): AppState => state;

export const Holidays = connect(mapStateToProps)(HolidaysLoadingWrapper);
