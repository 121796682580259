import { ThunkAction } from 'redux-thunk';
import { globalSettings as settings } from 'config';
import { Action } from 'redux';
import { SubscriptionClient, SubscriptionDTO } from 'app/shared/CoreClient';
import { AppState } from 'redux/state';

export const RECEIVE_SUBSCRIPTIONS = 'RECEIVE_SUBSCRIPTIONS';

export interface ReceiveSubscriptionsAction extends Action {
  type: typeof RECEIVE_SUBSCRIPTIONS;
  payload: SubscriptionDTO[];
}

const receiveSubscriptionsActionCreator = (
  subscription: SubscriptionDTO[]
): ReceiveSubscriptionsAction => ({
  type: RECEIVE_SUBSCRIPTIONS,
  payload: subscription,
});

export const fetchSubscriptions = (): ThunkAction<
  Promise<ReceiveSubscriptionsAction>,
  AppState,
  never
> => {
  return async (dispatch) => {
    const client = new SubscriptionClient(settings.bffHost);
    const subscriptions = await client.getSubscriptions();

    try {
      return dispatch(
        receiveSubscriptionsActionCreator(
            subscriptions as SubscriptionDTO[],
        )
      );
    } catch (err) {
      throw err;
    }
  };

};

export type SubscriptionsStateAction = ReceiveSubscriptionsAction | Action;
