import Oidc from 'oidc-client';
import {each, map, replace} from 'lodash';
import {Client} from 'raven';

/*
 * Logger for oidc-client
 */
export const logger = Object.assign({}, console, {
  info: (message: string) => {
    const Raven: Client = (window as any).Raven;

    if (Raven != null) {
      Raven.captureMessage(message, {level: 'info'});
    } else {
      console.info(message);
    }
  },

  warn: (message: string) => {
    const Raven: Client = (window as any).Raven;

    if (Raven != null) {
      Raven.captureMessage(message, {level: 'warning'});
    } else {
      console.warn(message);
    }
  },

  error: (err: Error) => {
    const Raven: Client = (window as any).Raven;

    if (Raven != null) {
      Raven.captureException(err);
    } else {
      console.error(err);
    }
  },
});

Oidc.Log.logger = logger;
Oidc.Log.level = Oidc.Log.WARN;

/*
 * Dictionary of parameters
 */
export interface ParamDictionary {
  [name: string]: string;
}

/*
 * splits search parameters into a dictionary
 */
export const splitFromSearchParameters = (
  searchParams: string
): ParamDictionary => {
  const params = {};

  if (searchParams && searchParams.indexOf('=') > -1) {
    each(searchParams.split('&'), value => {
      const parts = value.split('=');
      const key = decodeURIComponent(replace(parts[0], /\+/g, ' '));
      params[key] = decodeURIComponent(replace(parts[1], /\+/g, ' '));
    });
  }

  return params;
};

/*
 * joins a dictionary into search parameters
 */
export const joinToSearchParameters = (params: ParamDictionary): string => {
  const parts = map(
    params,
    (value, name) => `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
  );

  return parts.join('&');
};

/*
 * extract parameters from callback url
 */
export const extractParametersFromCallbackUrl = (): ParamDictionary => {
  // get url
  const url = window.location.href;

  // the search parameters are passed after a # not ?
  let params: ParamDictionary = {};
  const hashPos = url.lastIndexOf('#');
  if (hashPos > -1) {
    params = splitFromSearchParameters(url.substr(hashPos + 1));
  }

  return params;
};
