import {UserExperimentClient} from '../../app/shared/CoreClient';
import { globalSettings as settings } from 'config';
import {ThunkAction} from 'redux-thunk';
export const REQUEST_VARIATION = 'REQUEST_VARIATION';
interface RequestAction {
  type: typeof REQUEST_VARIATION;
}
const requestVariation = (): RequestAction => ({
  type: REQUEST_VARIATION,
});

export const RECEIVE_VARIATION = 'RECEIVE_VARIATION';
interface ReceiveAction {
  type: typeof RECEIVE_VARIATION;
  variation: string;
}
const receiveVariation = (variation: string): ReceiveAction => ({
  type: RECEIVE_VARIATION,
  variation,
});

export const fetchVariation = (): ThunkAction<
  Promise<ReceiveAction>,
  null,
  null
> => async dispatch => {
  dispatch(requestVariation());

  const client = new UserExperimentClient(settings.bffHost);
  const experimentGroup = await client.getExperimentGroup();

  return dispatch(receiveVariation(experimentGroup));
};

export type ExperimentAction = RequestAction | ReceiveAction;
