import Button from './Button';
import {useEffect, useState} from 'react';
import { Frequency2 } from '../CoreClient';
import { frequencyLabelLookup } from '../FrequencyHelper';

export type TFrequencyInfo = {
  availableFrequency: Frequency2[];
  defaultFrequency: Frequency2;
}

export interface IFrequencyChanger {
  frequencyInfo: TFrequencyInfo;
  onFrequencyChange: (frequency : Frequency2) => void;
}

export const FrequencyChanger = ({ frequencyInfo, onFrequencyChange }: IFrequencyChanger) => {
  const { availableFrequency, defaultFrequency } = frequencyInfo;
  const [selectedFrequency, setSelectedFrequency] = useState<Frequency2>();

  useEffect(() => {
    setSelectedFrequency(defaultFrequency);
    onFrequencyChange(defaultFrequency);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFrequency]);

  const setFrequency = (frequency: number) => {
    setSelectedFrequency(frequency);
    onFrequencyChange(frequency);
  };

  return (
    <div className="d-flex flex-column">
      <span className="mb-2">{'Delivery Frequency'}</span>
      <div className="col-12 col-lg-6 p-0">
        {availableFrequency.map((frequency) => (
          <Button
            key={frequency}
            className={`${selectedFrequency === frequency ? 'btn-primary' : 'btn-secondary'} mr-4`}
            style={{ height: '43px', lineHeight: '16px' }}
            onClick={() => setFrequency(frequency)}
          >
            {frequencyLabelLookup[frequency]}
          </Button>
        ))}
      </div>
    </div>
  );
};
