const readDiscountCode = () => {
  const params = new URLSearchParams(window.location.search);

  let code = localStorage.getItem('mfb_discount') || '';
  if (params) {
    params.forEach((value, key) => {
      if (key === 'pr') {
        code = value;
      }
    });
  }

  return code;
};

export default readDiscountCode;
