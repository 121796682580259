/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import {DeliverySlot, Day, DeliveryClient} from '../../shared/CoreClient';
import { globalSettings as settings } from 'config';
import {first, isUndefined} from 'lodash';
import {DayOfWeek, SubscriptionClient} from '../../shared/CoreClient';
import {CSSProperties} from 'react';
import { firstOrDefault } from '../../shared/helper';

interface State {
  options: Array<DeliverySlotOption>;
  isFetchingSlots: boolean;
  selectedDeliverySlotId?: number;
  hasUpdatedDeliverySlot: boolean;
  selectedNextVirtualDeliveryDay?: Day;
}

export interface DeliverySlotSelectProps {
  bagId: number;
  deliveryId?: number;
  pafId?: number;
  selectedDeliverySlotId?: number;
  errorMessage?: string;
  noSlotMessage?: string;
  onChange(
    deliveryId: number,
    nextVirtualdeliveryDay: Day,
    dayOfWeek: DayOfWeek,
    nextVirtualDeliveryDateId?: number,
    label?: string,
  ): void;
  onDefaultOption?: (slot:DeliverySlotOption)=> void
  label?: string;
  style?: CSSProperties;
}

interface DeliverySlotOption extends Select.Option {
  nextVirtualDeliveryDay?: Day;
  nextVirtualDeliveryDateId?: number;
  dayOfWeek?: DayOfWeek;
}

export default class DeliverySlotSelect extends React.PureComponent<
  DeliverySlotSelectProps,
  State
> {
  subscriptionApiClient: SubscriptionClient;
  deliveryApiClient: DeliveryClient;

  constructor(props: DeliverySlotSelectProps) {
    super(props);

    this.subscriptionApiClient = new SubscriptionClient(settings.bffHost);
    this.deliveryApiClient = new DeliveryClient(settings.bffHost);

    this.state = {
      options: [],
      isFetchingSlots: false,
      selectedDeliverySlotId: props.selectedDeliverySlotId,
      selectedNextVirtualDeliveryDay: undefined,
      hasUpdatedDeliverySlot: false,
    };
  }

  fetchSlots = async (): Promise<Array<DeliverySlotOption>> => {
    this.setState({isFetchingSlots: true});
    if (isUndefined(this.props.bagId) || this.props.bagId === null) {
      return [];
    }

    let dates = [];
    if (this.props.deliveryId) {
      dates = await this.deliveryApiClient.deliverySlots(
        this.props.deliveryId,
        this.props.pafId || null
      );
    } else {
      dates = await this.subscriptionApiClient.deliverySlots(
        this.props.bagId,
        this.props.pafId || null
      );
    }

    this.setState({isFetchingSlots: false});

    const options = dates.map((d: DeliverySlot) => ({
      value: d.id,
      label: d.description,
      nextVirtualDeliveryDay: d.nextVirtualDeliveryDay,
      dayOfWeek: d.dayOfWeek,
      nextVirtualDeliveryDateId: d.nextVirtualDeliveryDateId,
    }));

    if (!dates.length) {
      this.props.onChange(0, null, null, 0);
    }

    if(this.props.onDefaultOption){
      this.props.onDefaultOption(firstOrDefault(options))
    }

    return options;
  };

  async componentDidMount() {
    const options = await this.fetchSlots();
    this.tryGetSameSlotOnBagChange(options);
  }

  async componentDidUpdate(
    prevProps: DeliverySlotSelectProps,
    prevState: State
  ) {
    if (
      this.props.bagId !== prevProps.bagId ||
      this.props.pafId !== prevProps.pafId
    ) {
      const options = await this.fetchSlots();
      this.tryGetSameSlotOnBagChange(options);
    }
  }

  handleChange = (option: DeliverySlotOption | Array<DeliverySlotOption>) => {
    const opt = option as DeliverySlotOption;
    this.setState({selectedDeliverySlotId: opt.value as number});
    this.props.onChange(
      opt.value as number,
      opt.nextVirtualDeliveryDay,
      opt.dayOfWeek,
      opt.nextVirtualDeliveryDateId,
      opt.label
    );
  };

  tryGetSameSlotOnBagChange = (options: Array<DeliverySlotOption>) => {
    const {selectedDeliverySlotId} = this.props;

    const hasOptions = options.length;
    let nextSelectedSlotId = hasOptions
      ? (first(options).value as number)
      : selectedDeliverySlotId;
    let hasUpdatedDeliverySlot = false;
    let nextVirtualDeliveryDay = hasOptions
      ? first(options).nextVirtualDeliveryDay
      : null;
    const NextVirtualDeliveryDateId = hasOptions
      ? first(options).nextVirtualDeliveryDateId
      : null;
    let dayOfWeek = hasOptions ? first(options).dayOfWeek : null;

    if (options.length) {
      if (options.some(d => d.value === selectedDeliverySlotId)) {
        // same slot found in selection, retaining selection
        nextSelectedSlotId = selectedDeliverySlotId;
        hasUpdatedDeliverySlot = false;

        const selectedOption = options.find(
          d => d.value === selectedDeliverySlotId
        );
        nextVirtualDeliveryDay = selectedOption.nextVirtualDeliveryDay;
        dayOfWeek = selectedOption.dayOfWeek;

        this.props.onChange(
          nextSelectedSlotId,
          nextVirtualDeliveryDay,
          dayOfWeek,
          NextVirtualDeliveryDateId
        );
      } else {
        // defaulting to the first so letting the parent know of the change
        this.props.onChange(
          nextSelectedSlotId,
          nextVirtualDeliveryDay,
          dayOfWeek,
          NextVirtualDeliveryDateId
        );
        hasUpdatedDeliverySlot = selectedDeliverySlotId != null ? true : false;
      }
    }

    this.setState({
      options,
      selectedDeliverySlotId: nextSelectedSlotId,
      hasUpdatedDeliverySlot,
      selectedNextVirtualDeliveryDay: nextVirtualDeliveryDay,
    });
  };

  render() {
    const {errorMessage, noSlotMessage} = this.props;
    const {
      options,
      selectedDeliverySlotId,
      hasUpdatedDeliverySlot,
    } = this.state;

    const hasOptions = options.length > 0;

    return (
      <div
        className={classNames('form-group', {
          'has-danger': hasUpdatedDeliverySlot,
        })}
      >
        <label htmlFor="deliverySlotId">
          {this.props.label || 'Delivery Time'}
        </label>
        <React.Fragment>
          <Select
            placeholder="Select your prefered delivery time..."
            className="test-choose-delivery-slot"
            clearable={false}
            required={true}
            searchable={false}
            options={options}
            value={selectedDeliverySlotId}
            style={this.props.style}
            name="deliverySlotId"
            onChange={this.handleChange}
            isLoading={this.state.isFetchingSlots}
            disabled={this.state.isFetchingSlots}
          />
          {hasUpdatedDeliverySlot && (
            <div className="test-delivery-slot-warning">
              <p className="text-warning pt-2">
                We've updated your delivery time to the next available time
              </p>
            </div>
          )}
          {this.props.errorMessage && (
            <div className="mt-2 mb-2">
              <span className="text-danger form-control-feedback">
                {errorMessage}
              </span>
            </div>
          )}
        </React.Fragment>

        {!hasOptions && !this.state.isFetchingSlots && (
          <div className="mt-2 mb-2">
            <span className="form-control-feedback test-no-slots text-danger">
              {noSlotMessage ||
                "We're sorry, no delivery times are available for the selected product"}
            </span>
          </div>
        )}
      </div>
    );
  }
}
