import { format } from 'date-fns';
import React, { useRef } from 'react';
import {
  AddressSuggestionDto,
  DeliveryDateResponse,
  UpdateDeliveryShippingRequestBody,
  ValidateAddressCommand,
  SingleOrderWithRecipesAndExtras, IOrderWithRecipesAndExtras,
} from '@mfb/account-api-client';
import {
  DropdownSearchInputRef, EditDeliveryDetailsFormTrackingEvent,
  EditDeliveryFormContextProps,
  SvgCheckCircleFill,
  SvgClockAlt, useAnalyticsTracking,
  useEditDeliveryContext,
} from '@mfb/lego';
import { AlertStatus } from '@mfb/lego';
import { OrdersPageState, TrackingState } from '../../../../redux/state';
import { OrderWithRecipesAndExtras, SubscriptionDTO } from '../../CoreClient';
import { accountApiClient } from '../../api';
import { asDateFromWDate, asIsoNzstString, offsetTimeZone } from '../../helper';
import {
  AddressIdentifier,
  deserializeAddressIdentifier,
  fetchAddresses,
  onAddressSelected,
  serializeAddressIdentifier,
} from './addressFinder';
import {
  alertToastMessage,
  useAlertToastContext,
} from './context/AlertToastContext';
import { timeSlotChangedWarningMessage } from './feedbackMessages';
import {
  TimeSlotIdentifier,
  deserializeTimeslotIdentifier,
  fetchTimeSlots,
  isSelectedTimeSlotSameAsDefault,
  onTimeSlotSelected,
  serializeTimeslotIdentifier,
} from './timeslotSelector';
import { initTrackingData } from './tracking';
import { ReceiveTrackingAction } from '../../../../redux/actions/sharedActions/trackingAction';
import { readFromSessionStorage, writeToSessionStorage } from '../../../../redux/sessionStorage';
import { mapSculleryOrderWithRecipesAndExtrasToCore } from '../../sculleryToCoreResponseMapper';

export type FormDefaults = AddressIdentifier &
  TimeSlotIdentifier & {
  historicalAddresses?: AddressSuggestionDto[];
  fetchedTimeslots?: DeliveryDateResponse[];
  deliveryId?: number;
  deliveryInstructions?: string;
};

export type EditDeliveryDetailsServiceProps = {
  currentOrder: OrderWithRecipesAndExtras,
  deliveryWeek: string,
  tracking?: TrackingState,
  fetchTracking?: () => Promise<ReceiveTrackingAction>
  /*
  * @function is called on successful submit.
  */
  postSuccessAction?: () => Promise<any>,
}

const getLatestOrderResponseFromSession=(orderKey?: string)=>{
  if(!orderKey) return ;

  try{
    const orderJsonStr = readFromSessionStorage({orderKey: orderKey, type: 'addressReFetch'});
    if(orderJsonStr){
      const order = SingleOrderWithRecipesAndExtras.fromJS({orderWithRecipesAndExtras: orderJsonStr})
      return  mapSculleryOrderWithRecipesAndExtrasToCore(order.orderWithRecipesAndExtras);
    }
  }catch (e){
    console.error(e);
  }
}

export const useEditDeliveryDetailsService = ({currentOrder, deliveryWeek, tracking, fetchTracking, postSuccessAction}: EditDeliveryDetailsServiceProps) => {
  currentOrder = getLatestOrderResponseFromSession(currentOrder?.orderKey) ?? currentOrder;

  const { state, formInputValues, trackingData } = useEditDeliveryContext();
  const { trackDataLayerEvent } = useAnalyticsTracking();
  const { updateToast } = useAlertToastContext();


  const [_state, setState] = state;
  const dropdownSearchInputRef = useRef<DropdownSearchInputRef>(null);
  const addressSearchResults = useRef<AddressSuggestionDto[]>([]);
  const formDefaults = useRef<FormDefaults>({
    addressId: -1,
    pafId: -1,
    timeSlotId: -1,
    deliveryDateId: -1,
    historicalAddresses: [],
  });

  const getFormDefaults = () => {
    //overridden defaults
    const address = currentOrder.deliveryAddress;
    const timeslot = currentOrder.overrideDeliveryDate ?? currentOrder.deliveryDate;

    formInputValues.current = {
      address: {
        id: serializeAddressIdentifier({ addressId: address.addressId }),
        text: `${address.street}, ${address.suburb}, ${address.city}, ${address.postcode}`,
      },
      timeslot: {
        id: serializeTimeslotIdentifier({
          deliveryDateId: timeslot.deliveryDateId,
        }),
        text: '',
      },
      deliveryInstructions: address.deliveryInstructions,
    };

    _state.deliveryInstructions.value =
      formInputValues.current.deliveryInstructions;
    formDefaults.current = {
      ...deserializeAddressIdentifier(formInputValues.current.address?.id),
      ...deserializeTimeslotIdentifier(formInputValues.current.timeslot?.id),
      deliveryId: currentOrder.deliveryId,
      fetchedTimeslots: [],
      deliveryInstructions: address.deliveryInstructions,
    };
  };

  const getFormattedDate = () => {
    try {
      const date = new Date(currentOrder.deliveryDate.actualDeliveryDate);
      return format(date, 'EEE dd MMM');
    } catch (e) {
      console.error(e);
    }
  };

  const initialiseForm = async () => {
    getFormDefaults();
    const date = getFormattedDate();

    const context: EditDeliveryFormContextProps = {
      variant: 'default',
      id: '',
      isPanelOpen: false,
      header: `Update your delivery for ${date} `,
      subheader: (
        <>
          <div>
            {'Change your delivery address, date or time for '}
            <b>{date}.</b>
          </div>
          <div>
            {
              'This change will only apply for this week.'
            }
          </div>
        </>
      ),
      address: {
        label: 'Delivery address',
        ref: dropdownSearchInputRef,
        debounceDuration: 500,
        items: [],
        placeholder: 'Enter your address here...',
        onChange: (searchTerm) =>
          fetchAddresses(
            dropdownSearchInputRef,
            addressSearchResults,
            formDefaults,
            searchTerm,
          ),
        onItemSelected: async (o) => {
          return await onAddressSelected(
            o,
            formDefaults,
            state,
            formInputValues,
            currentOrder,
            addressSearchResults,
          );
        },
      },
      addressPreviewMessage: formInputValues.current.address
        ? {
          body: formInputValues.current.address?.text,
          isAlert: true,
          topRightSlot: {
            icon: (
              <SvgCheckCircleFill width={24} height={24} role={'button'} />
            ),
          },
        }
        : undefined,
      timeSlot: {
        items: [],
        onItemSelected: (o) => onTimeSlotSelected(o, formInputValues, formDefaults, state),
        label: `Date and time`,
      },
      deliveryInstructions: {
        label: 'Delivery instructions',
        placeholder: '',
        maxLength: 100,
        value: _state.deliveryInstructions.value,
        onChange: (c) => {
          setState((u) => ({
            ...u,
            deliveryInstructions: {
              ...u.deliveryInstructions,
              value: c.target?.value,
            },
          }));
          formInputValues.current.deliveryInstructions = c.target?.value;
        },
      },
      primaryButton: {
        label: 'Update details',
        onClick: async () => await onSubmit(),
      },
      secondaryButton: { label: 'Cancel' },
    };

    if(!tracking && fetchTracking){
      tracking = (await fetchTracking()).payload as TrackingState;
    }

    trackingData.current = await initTrackingData(currentOrder, tracking);
    setState(context);
    enableSubmit();
  };

  const enableSubmit = () => {
    if (
      !!formInputValues.current.address ||
      !!formInputValues.current.timeslot
    ) {
      setState((c) => ({
        ...c,
        primaryButton: { ...c.primaryButton, disabled: false },
      }));
    } else {
      setState((c) => ({
        ...c,
        primaryButton: { ...c.primaryButton, disabled: true },
      }));
    }
  };

  const onSubmit = async () => {
    try {
      const delWeek = offsetTimeZone(
        new Date(deliveryWeek.replace('W', '')),
      );
      setState((p) => ({
        ...p,
        primaryButton: { ...p.primaryButton, isLoading: true },
      }));

      const addressIdentifier = deserializeAddressIdentifier(
        formInputValues.current.address.id,
      );

      if (!addressIdentifier.addressId) {
        addressIdentifier.addressId = (
          await accountApiClient.addressValidate(
            new ValidateAddressCommand({
              addressSuggestionId: addressIdentifier.pafId,
            }),
          )
        ).addressId;
      }

      const payload = new UpdateDeliveryShippingRequestBody({
        subscriptionNumber: currentOrder.subscriptionNumber,
        deliveryId: formDefaults.current.deliveryId,
        addressId: addressIdentifier.addressId,
        //@todo this is to be changes to wDate
        deliveryWeek: delWeek,
        deliveryDateId: deserializeTimeslotIdentifier(
          formInputValues.current.timeslot.id,
        ).deliveryDateId,
        deliveryInstructions: formInputValues.current.deliveryInstructions,
      });

      const result = await accountApiClient.salesOrderShipping(payload);
      if (result.success) {
        updateToast({
          status: AlertStatus.success,
          isOpen: true,
          text: (
            <div>
              {alertToastMessage.success} <strong>{getFormattedDate()}</strong>
            </div>
          ),
        });
      }
      if (!result.success) {
        updateToast({
          status: AlertStatus.error,
          isOpen: true,
          text: alertToastMessage.error,
        });
        throw new Error(result?.errorMessage);
      }
      setState((p) => ({ ...p, isPanelOpen: false }));
      await postSuccessAction();
    } catch (e) {
      console.error(e);
      //Re-fetch timeslots in the case of failure was caused by the timeslot not being available anymore.
      const addressIdentifier = deserializeAddressIdentifier(
        formInputValues.current.address?.id,
      );
      await fetchTimeSlots({
        state,
        formDefaults,
        formInputValues,
        currentOrder,
        pafId: addressIdentifier.pafId,
        addressId: addressIdentifier.addressId,
      });
      if (!isSelectedTimeSlotSameAsDefault(formDefaults, formInputValues)) {
        setState((p) => ({
          ...p,
          timeSlot: { ...p.timeSlot, variant: 'warning' },
          timeslotPreviewMessage: timeSlotChangedWarningMessage(
            formInputValues.current?.timeslot?.text,
            formDefaults,
            state,
          ),
        }));
      }
    } finally {
      setState((p) => ({
        ...p,
        primaryButton: { ...p.primaryButton, isLoading: false },
      }));
    }
  };

  const displayForm = async () => {
    try {
      await initialiseForm();
      const historicalAddresses = await fetchAddresses(
        dropdownSearchInputRef,
        addressSearchResults,
        formDefaults,
        '',
        true,
      );
      formDefaults.current.historicalAddresses = historicalAddresses;

      const addressIdentifier = deserializeAddressIdentifier(
        formInputValues.current.address.id,
      );

      await fetchTimeSlots({
        state,
        formDefaults,
        formInputValues,
        currentOrder,
        pafId: addressIdentifier.pafId,
        addressId: addressIdentifier.addressId,
      });

      setState((p) => ({
        ...p,
        address: {
          ...p.address,
          defaultItems: historicalAddresses?.map((c) => {
            return {
              id: serializeAddressIdentifier({ pafId: c.suggestionId }),
              text: c.fullAddress,
              icon: <SvgClockAlt width={22} height={22} />,
            };
          }),
        },
        isPanelOpen: true,
      }));

      trackDataLayerEvent(EditDeliveryDetailsFormTrackingEvent.FORM_VIEWED, trackingData.current);
    } catch (e) {
      console.error(e);
      //@todo show error alert
    }
  };

  return {
    displayEditDelivery: displayForm,
  };
};
