type Other = 'Other';
export const otherReason: Other = 'Other';

interface Option {
  label: string;
  value: string;
}

const arrayToOptions = (arr: Array<string>): Array<Option> =>
  arr.map(item => ({label: item, value: item}));

const cancellationReasons: Array<string> = [
  'Issues with payment or offers',
  "I don't want a delivery every week",
  "Recipes don't meet my expectations or dietary needs",
  'I was just trialling the service',
  'Trying another brand or competitor',
  "Size of the box or number of meals doesn't work for me",
  "There's too much packaging",
  "It's out of my budget",
  'Issues with my deliveries or ingredients',
  "It's too difficult managing my account",
  'Just taking a break',
]
  // randomly sort the array, otherReason should always be at the end of the list.
  .sort(() => Math.random() - 0.5)
  .concat(otherReason);

export default cancellationReasons;
export const primaryCancellationReasons: Array<Option> = arrayToOptions(
  cancellationReasons
);
