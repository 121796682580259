import React from 'react';
import { CircularProgressIndicator } from '@mfb/lego';
import { EnrolledCampaignDto } from '@mfb/account-api-client';

interface Props {
  campaignProgression: EnrolledCampaignDto;
  variant?: 'default' | 'ringWhite';
}

const size = 68;

const sizeRingWhite = 40;

export const CampaignProgressIndicator: React.FC<Props> = ({
  campaignProgression,
  variant = 'default',
}) => {
  const { progressionTowardsNextCampaignPrize } = campaignProgression;

  if (progressionTowardsNextCampaignPrize.isProgressIndicatorHidden) {
    return null;
  }

  return (
    <CircularProgressIndicator
      variant={variant}
      size={variant === 'default' ? size : sizeRingWhite}
      delta={progressionTowardsNextCampaignPrize.delta}
      totalSteps={
        progressionTowardsNextCampaignPrize.target
      }
      progress={progressionTowardsNextCampaignPrize.progress}
      segments={progressionTowardsNextCampaignPrize.target}
    />
  );
};
