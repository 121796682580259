import React from 'react';
import {COLOR_CODE} from '../constants';
import Icon, {IconProps} from './Icon';

class AlertDiamondIcon extends Icon {
  static defaultProps: Partial<IconProps> = {
    color: COLOR_CODE.DARK_YELLOW,
    height: 14.5,
    seasonal: false,
    title: '',
    width: 14.5,
  };

  renderIcon() {
    const {color} = this.props;

    return (
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.615234 7.85608C-0.205078 7.11633 -0.205078 5.91711 0.615234 5.17737L5.74146 0.55481C6.56177 -0.184937 7.89185 -0.184937 8.71216 0.55481L13.8384 5.17737C14.6587 5.91711 14.6587 7.11633 13.8384 7.85608L8.71216 12.4786C7.89185 13.2184 6.56177 13.2184 5.74146 12.4786L0.615234 7.85608ZM6.12988 2.21545H8.12988V8.21545H6.12988V2.21545ZM8.12988 9.21545H6.12988V11.2155H8.12988V9.21545Z"
        fill={color}
      />
    );
  }
}

export default AlertDiamondIcon;
