import React from 'react';
import {
  CarouselItemModel,
  CardGridDisplay,
  NumberColumnsPerBreakpoint,
} from '@mfb/lego';

interface Props {
  orderItems?: Array<CarouselItemModel>;
  className?: string;
  cardGridDisplayColumns?: NumberColumnsPerBreakpoint;
}

/*
Use this component to render recipe(s) in a grid of RecipeCarouselItem(s).
*/
export const OrderItemGrid: React.FC<Props> = ({
  orderItems,
  className,
  cardGridDisplayColumns,
}) => (
  <>
    <CardGridDisplay columns={cardGridDisplayColumns}>
      {orderItems.map(({ component, key }) => {
        if (component) {
          return <div key={key}>{component}</div>;
        }
      })}
    </CardGridDisplay>
  </>
);
