import React from 'react';
import {flatMap} from 'lodash';
import {
  DeliveryRatingDay,
  DeliveryRatings,
  RecipeRating,
} from '../shared/CoreClient';
import ChevronButton from '../shared/components/ChevronButton';
import {Orientation} from '../shared/components/ChevronButton';
import {RateFunc} from './RateRecipesShell';
import ResponsiveDate from '../../format/responsive-date';
import {RecipeWithRating} from './RecipeWithRating';

interface RateRecipeProps {
  count: number;
  deliveryWeeksBack: number;
  days: Array<DeliveryRatingDay>;
  performRating: RateFunc;
  forward(e: React.MouseEvent<HTMLDivElement>): void;
  back(e: React.MouseEvent<HTMLDivElement>): void;
}

interface DeliveryRating {
  bagDescription: string;
  day: string;
  deliveryWeek: Date;
  recipes: Array<RecipeRating>;
}

const toDeliveryRatings = (d: DeliveryRatingDay) =>
  d.deliveryRatings.map(
    (o: DeliveryRatings): DeliveryRating => ({
      bagDescription: o.bagDescription,
      day: d.day as string,
      deliveryWeek: d.dateTime,
      recipes: o.recipeRatings,
    })
  );

export const RateRecipes: React.FC<RateRecipeProps> = ({
  count,
  deliveryWeeksBack,
  days,
  performRating,
  forward,
  back,
}) =>
  days && days.length > 0 ? (
    <div>
      {flatMap(days, toDeliveryRatings).map(
        (deliveryRating: DeliveryRating) => (
          <div
            key={`${deliveryRating.bagDescription}-${deliveryRating.day}`}
            className="pb-4"
          >
            <div className="py-2">
              <div className="d-flex justify-content-center">
                <ChevronButton
                  disabled={deliveryWeeksBack === count - 1}
                  className="pt-1 px-2"
                  orientation={Orientation.LEFT}
                  onClick={back}
                />
                <div>
                  <h3 className="my-0">
                    <ResponsiveDate date={deliveryRating.day} />
                  </h3>
                </div>
                <ChevronButton
                  disabled={deliveryWeeksBack === 0}
                  className="pt-1 px-2"
                  orientation={Orientation.RIGHT}
                  onClick={forward}
                />
              </div>
              <div className="text-muted mfb-DeliveryItem--subtitle text-center">
                {deliveryRating.bagDescription}
              </div>
            </div>
            <div className="row justify-content-center">
              {deliveryRating.recipes.map(recipe => (
                <div key={recipe.id} className="col-12 col-md-4 pb-4">
                  <RecipeWithRating
                    {...{
                      recipe,
                      deliveryWeeksBack,
                      performRating,
                      deliveryWeek: deliveryRating.deliveryWeek,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  ) : (
    <div className="container">
      <p>Sorry, you have no recipes available to rate.</p>
    </div>
  );
