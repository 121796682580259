import React from 'react';
import classnames from 'classnames';

interface Props {
  className: string;
}

export const TermsAndConditions: React.FC<Props> = ({className}) => (
  <p className={classnames('text-light', className)}>
    T’s &amp; C’s apply: Due to public holidays, some areas will not be able to
    receive deliveries on the weekends of 26 December 2021 and 2 January 2022.
    Please note, the above changes are only applicable to an ongoing
    subscription and will not be applied to any specialty one-off
    &apos;Christmas Bag&apos; deliveries.
  </p>
);
