import React from 'react';
import Icon, {IconProps} from './Icon';
import classNames from 'classnames';

class RateRecipesIcon extends Icon {
  static defaultProps: Partial<IconProps> = {
    height: 13, // was 14
    title: 'Rate Recipes',
    width: 12,
  };

  renderIcon() {
    const {color, seasonal, title} = this.props;

    return (
      <g>
        <title>{title}</title>
        <path
          className={classNames({seasonal})}
          d="M8.656 2.793C8.656 1.544 10.156 0 12 0v14h-1.656V8.413H8.656v-5.62zM5.344 4.896V0h1.312v4.896c0 1.479-1.094 2.695-2.5 2.794V14H2.5V7.69C1.094 7.59 0 6.375 0 4.896V0h1.344v4.896h1.313V0H4v4.896h1.344-.001z"
          fillRule="nonzero"
          fill={color}
        />
      </g>
    );
  }
}

export default RateRecipesIcon;
