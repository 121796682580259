import React from 'react';
import Modal from 'react-modal';
import Submit from './Submit';
import classNames from 'classnames';

interface Action {
  text: string;
  className?: string;
  handler(): void;
}

interface Props {
  title: string;
  children?: React.ReactNode;
  actions: Array<Action>;
  onCancel(): void;
}

export const Dialog: React.FunctionComponent<Props> = ({
  title,
  actions,
  onCancel,
  children,
}: Props) => (
  <Modal
    isOpen={true}
    contentLabel="confirm"
    shouldCloseOnOverlayClick={true}
    className="mfb-Modal"
    overlayClassName="mfb-ModalOverlay"
    onRequestClose={onCancel}
  >
    <span onClick={onCancel}>
      <button type="button" className="close p-0" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </span>
    <h4 className="h4">{title}</h4>
    <hr />
    {children}
    <div className="row">
      <div className="px-2 w-100">
        {actions.map((action, i) => (
          <Submit
            key={i}
            label={action.text}
            className={classNames('btn mfb-Button m-2', action.className)}
            isSecondary={true}
            onClick={() => action.handler()}
          />
        ))}
      </div>
    </div>
  </Modal>
);

export default Dialog;
