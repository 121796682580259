import {Dispatch, Store} from 'redux';
import { globalSettings as settings } from 'config';
import {
  AccountSettingClient,
  AccountSettings,
  SwaggerException,
} from '../../../app/shared/CoreClient';
import {
  requestPageState,
  receivePageState,
} from '../sharedActions/pageStateAction';
import {fetchStart, fetchEnd, fetchError} from '../sharedActions/saveAction';
import HttpStatus from 'http-status-codes';
import {validationErrors} from '../sharedActions/validationErrorsAction';
import {manageAccountPath} from "../../../app/account-settings/components/Nav/paths";
import {PageState} from "../../state";

export const fetchAccountSettingsState = () => async (
  dispatch: Dispatch<Store<AccountSettings>>
) => {
  const requestTime = new Date();
  dispatch(requestPageState(requestTime));
  const accountSettingsClient = new AccountSettingClient(settings.bffHost);
  const accountSettings = await accountSettingsClient.getPageModel();

  return dispatch(receivePageState({
    ...accountSettings,
    requestTime,
    requestSource: manageAccountPath
  }));
};

export const tryPerformAccountSettingsRequest = (
  fetchFunc: () => Promise<AccountSettings>
) => async (dispatch: Dispatch<Store<AccountSettings>>) => {
  dispatch(fetchStart());
  try {
    const requestTime = new Date();
    const accountSettings = await fetchFunc();

    dispatch(fetchEnd());

    return dispatch(receivePageState({
      ...accountSettings,
      requestTime,
      requestSource: manageAccountPath
    }));
  } catch (err) {
    dispatch(fetchError()); // Should we be doing this?!?
    throw err;
  }
};

export const tryPerformAccountSettingsRequestWithErrors = (
  fetchFunc: () => Promise<AccountSettings>
) => async (dispatch: Dispatch<Store<AccountSettings>>) => {
  dispatch(fetchStart());
  try {
    const requestTime = new Date();
    const accountSettings = await fetchFunc();

    dispatch(fetchEnd());

    return dispatch(receivePageState({
      ...accountSettings,
      requestTime,
      requestSource: manageAccountPath
    }));
  } catch (err) {
    /*
          The server will return the validation errors in the result property.
          The swagger client will throw an exception which we need to catch and deal with.
          We need to send this back to the component so then the errors can be displayed.
        */
    const error = err as SwaggerException;

    if (error.status !== HttpStatus.BAD_REQUEST) {
      dispatch(fetchError()); // Should we be doing this?!?

      throw err;
    }
    dispatch(fetchEnd());

    const newState = error.result as AccountSettings;
    dispatch(validationErrors({
      ...newState,
      requestTime: new Date,
      requestSource: manageAccountPath
    } as PageState));

    throw err;
  }
};
