import React from 'react';
import AlertMessage from './app/shared/components/AlertMessage';
import {MessageVariant} from './app/shared/CoreClient';
import {navman} from './navigator';

interface Props {
  message: string;
}

const ErrorAlert: React.FunctionComponent<Props> = ({message}) => (
  <div className="container-fluid">
    <AlertMessage
      variant={MessageVariant.Danger}
      title="Unrecoverable error"
      text={message}
      button={{label: 'Reload application', onClick: () => navman.reload()}}
    />
  </div>
);

export default ErrorAlert;
