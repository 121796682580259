/* eslint-disable @typescript-eslint/ban-types */
import React, { SetStateAction } from 'react';
import { DayPicker } from 'react-dates';
import classnames from 'classnames';
import stickybits from 'stickybits';
import { throttle } from 'lodash';
import {
  CalendarClient,
  CalendarEntry,
  CalendarResponse,
  OrderAccountClient,
  OrderAction,
  SubscriptionDTO,
  SubscriptionStatus,
  Week as WeekLegacy,
  WeekState,
} from '../CoreClient';
import moment from 'moment';
import { globalSettings as settings } from 'config';
import Spinner from './Spinner';
import { navman } from '../../../navigator';
import Tooltip from './Tooltip';
import { COLOR_CODE, CONTACT_PHONE, TriggerCode } from '../constants';
import { ConfirmationModal } from './ConfirmationModal';
import { SkipAction } from '../../deliveries/components/SkipAction';
import { showSkippedToast, showUnSkippedToast } from '../../toast/ToastMessages';
import styled from 'styled-components';
import { mapDispatchToProps } from '../../account-settings/containers/accountSettingsContainer';
import { connect } from 'react-redux';
import { AppState, CustomerMessageBulkGroupState, OrdersPageState, TrackingState } from 'redux/state';
import { LoadingIndicator } from '@mfb/lego/dist/src/recipeCardv2/LoadingIndicator';
import { CustomerMessageAction, useCustomerMessageContext } from '@mfb/lego';
import {
  cmpNavigateToUrl,
  CustomerMessageProxyDeepLink,
  displayCustomerMessage,
  DisplayCustomerMessageType,
  getCustomerMessageFromGroup,
} from '../CustomerMessageService';
import { store } from 'index';
import { fetchCustomerMessageBulk } from 'redux/actions/sharedActions/CustomerMessageBulkAction';
import { accountApiClient } from '../api';
import { SkipDeliveryRequest, UnSkipDeliveryRequest, Week } from '@mfb/account-api-client';
import { DeliveryWeek } from '../helper';
import { parse } from 'date-fns';
import { formatWeekAsUtcString } from '../helper';

const DEFAULT_DAY_SIZE = 80;
const DECIMAL_RADIX = 10;
const NUMBER_OF_MONTHS = 4;
const DAY_SIZE_DIVIDER = 7;
const GUTTER_WIDTH = 15;

type CalendarState = CalendarResponse;

type CalendarSkipCmpWrapperProps = Omit<DisplayCustomerMessageType, "customerMessageContext"> & {
  onNavigate: (url: string, triggerId?: number) => Promise<any>;
  defaultAction: ()=>Promise<any>,
  setCalendarState: React.Dispatch<SetStateAction<any>>;
};

export const CalendarSkipCmpWrapper = (props: CalendarSkipCmpWrapperProps & {trackingState?: TrackingState, deliveryWeek?: string}) => {
  const customerMessageContext = useCustomerMessageContext();
  const [cmpState,] = customerMessageContext.state;
  const isPanelOpen = cmpState.isPanelOpen;

  const isMessageDisplayed = React.useRef<boolean>(false);

  React.useEffect(()=>{
    if(!isPanelOpen && isMessageDisplayed.current){
      props.setCalendarState((prev) => ({
        ...prev,
        loadingWeek: null,
        showSkipPreventionModal: false,
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isPanelOpen]);

  React.useEffect(()=>{
    (async () => {
      try{
        //get cmp from redux state.
        let customerMessage = getCustomerMessageFromGroup(props);

        if(!customerMessage){
          //we might not have the far-future CMP in the Redux state from the initial page load, so we need to fetch it.
          const bulkCustomerMessageResult = (await store.dispatch(
            fetchCustomerMessageBulk(
              props.subscriptionNumber,
              [props.triggerCode],
              props.deliveryDateId ? [props.deliveryDateId] : undefined
            ))).payload;

          customerMessage = bulkCustomerMessageResult.find(
            (c) => c.triggerCode === props.triggerCode
            && c.deliveryDateId === props.deliveryDateId
            && c.subscriptionNumber === props.subscriptionNumber
          );
        }

        const trackingMetadata = props.trackingState?.subscriptions?.find(c=> c.subscriptionNumber === props.subscriptionNumber)?.metadata;

        if(trackingMetadata){
          trackingMetadata.trigger_code = 'skip';
        }

        displayCustomerMessage({
          ...props, 
          customerMessageContext, 
          customerMessageBulkGroupState: 
          [{
            triggerCode: props.triggerCode, 
            group: [customerMessage],
          }],
          onNavigate: props.onNavigate,
          hideTriggersWithProxyDeepLink: [CustomerMessageProxyDeepLink.EDIT_DELIVERY_DETAILS],
          trackingMetaData: {...trackingMetadata, customer_message_type: 'Skip Prevention', delivery_week: formatWeekAsUtcString(props.deliveryWeek)}
        });
        
        //no cmp - execute default action  
        if(customerMessage?.customerMessageResult?.customerMessages?.length === 0 && customerMessage?.customerMessageResult?.triggers?.customerMessageTriggers.length === 0){
          return await props.defaultAction();
        }

        isMessageDisplayed.current = true;
        }catch(e){
          console.error(e);
        }finally{
          props.setCalendarState((prev) => ({
            ...prev,
            loadingWeek: null,
            showSkipPreventionModal: false,
          }));
        }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return null;
};

interface State {
  calendarState: CalendarState;
  confirmingSkipDeliveryDay?: any;
  showCalendarIsDisabledModal: boolean;
  showSkipPreventionModal: boolean;
  loadingWeek?: moment.Moment[];
}

declare let dataLayer: Array<any>; // Global array for GTM tracking.

const BannerDiv = styled.div`
  top: 40px;
  background: ${COLOR_CODE.WHITE};
  min-height: 80px;
`;

interface CalendarProps {
  skipPreventionContext: {
    subscriptions: SubscriptionDTO[];
    triggerCodes: string[];
    deliveryDateId?: number;
    deliveryWeek?: string;
  };
  customerMessageBulkGroupState: Array<CustomerMessageBulkGroupState>;
  trackingState: TrackingState
}

export class CalendarUnconnected extends React.Component<CalendarProps, State> {
  state: State = {
    calendarState: undefined,
    confirmingSkipDeliveryDay: undefined,
    showCalendarIsDisabledModal: false,
    showSkipPreventionModal: false,
    loadingWeek: undefined,
  };

  constructor(props: CalendarProps) {
    super(props);

    this.setState = this.setState.bind(this);
  }

  async componentDidMount() {
    const calendarClient = new CalendarClient(settings.bffHost);

    const cal = await calendarClient.calendar();
    this.setState({
      calendarState: cal,
      showCalendarIsDisabledModal: !cal.calendarEnabled,
    });

    this.updateDaySize = throttle(this.updateDaySize, 30);
    window.addEventListener('resize', this.updateDaySize);

    // Workaround: IE 11 doesn't support position: sticky, so stickybits is used here instead
    stickybits('.mfb-CalendarKey');
    stickybits('.DayPicker__week-headers', { stickyBitStickyOffset: 110 }); // Offset to put below the key
    this.updateDaySize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDaySize);
  }

  updateDaySize = () => this.forceUpdate();

  closeCalendarDisabledModal = async () => {
    this.setState({ showCalendarIsDisabledModal: false });
  };

  onClickConfirmSkipDelivery = (day: any) => {
    const state = this.state;
    this.setState({
      ...state,
      confirmingSkipDeliveryDay: day,
    });
  };

  handleConfirmSkip = async () => {
    const state = this.state;
    const day = state.confirmingSkipDeliveryDay;
    this.onDayClick(day);

    this.setState({
      ...state,
      confirmingSkipDeliveryDay: undefined,
    });
  };

  handleCancelSkip = () => {
    this.setState({
      confirmingSkipDeliveryDay: undefined,
    });
  };

  handleSkipPrevention = async (
    deliveryWeek: string,
    subscriptionNumber: string,
    onSkip: () => Promise<any>,
    url?: string,
    triggerId?: string
  ) => {
    if (url) {
      //url exists, check if url prefix is equal to skip-delivery
      if (url.startsWith(CustomerMessageAction.SKIP)) {
        try {
          const triggerIdNumber = Number(triggerId);
          await accountApiClient.subscriptionsSkip(
            subscriptionNumber,  
            new SkipDeliveryRequest(
              {week: new DeliveryWeek(deliveryWeek), triggerId: triggerIdNumber, isSkippedFromCalendar: true}
            ));
          await onSkip();
        } catch (e) {
          console.error('handleSkipPrevention failure', e.message);
        }
      } else if (url !== CustomerMessageAction.CLOSE) {
        cmpNavigateToUrl(url)
      }
    }
  };

  /*
   * This is used to apply a skip or unskip to ALL subscriptions
   * for that week.
   * Given the day that the user clicked. This will loop though
   * the calendarEntries untill it finds the week relating to the
   * day in question. If the state of this week is ALL (skipped) or
   * Partial (mixed) then the unskip action will be called to the server.
   * Otherwise the skip action is sent to the server.
   */

  handleSkipUnSkip = async (
    week: WeekLegacy,
    action: SkipAction,
    productNames: Array<string>,
    triggerId?: number,
  ) => {
    try {
      const subscriptions = this.props.skipPreventionContext?.subscriptions ?? [];
      for(const subscription of subscriptions){

        if(action === SkipAction.Skip){
          await accountApiClient.subscriptionsSkip(subscription.subscriptionNumber,  new SkipDeliveryRequest({week: new DeliveryWeek(week), triggerId, isSkippedFromCalendar: true}));
        } else{
          await accountApiClient.subscriptionsUnSkip(subscription.subscriptionNumber, new UnSkipDeliveryRequest({week: new DeliveryWeek(week), isSkippedFromCalendar: true}));
        }
      }
      const calendarClient = new CalendarClient(settings.bffHost);
      const calendar = await calendarClient.calendar();
      this.setState({ ...this.state, calendarState: calendar });
      action === SkipAction.Skip ? showSkippedToast(): showUnSkippedToast();
      productNames.forEach((name) =>
        dataLayer.push({
          event: 'Calendar Skip/Unskip',
          calendarSkipType: `${action === SkipAction.Skip ? 'skip' : 'unskip'}`,
          calendarSkipLabel: `${name} - ${week}`,
        })
      );
    }catch (e) {
      console.log(e);
    }
  };

  getWeekDays = (day) => {
    const weekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const dayIndex = weekdays.indexOf(day.format('dddd'));
    const startDate = day.clone().subtract(dayIndex, 'days');
    return Array.from({ length: 7 }, (_, i) =>
      startDate.clone().add(i, 'days')
    );
  };

  hasSingleActiveSub(){
    const subscriptions = this.props.skipPreventionContext.subscriptions;
    if(!subscriptions) {return;}

   const activeSubscriptions = subscriptions.filter(c=>c.status === SubscriptionStatus.Active);

   return activeSubscriptions?.length === 1;
  }

  getFirstActiveSub(){
    const subscriptions = this.props.skipPreventionContext.subscriptions;
    if(!subscriptions) { return; }
    return subscriptions?.find(c=>c.status === SubscriptionStatus.Active);
  }

  onDayClick = async (day: any) => {
    if (this.state.loadingWeek) {
      return;
    }
    const state = this.state;
    const calendarEntries = state.calendarState.calendarEntries;
    const showConfirmSkipModal = state.confirmingSkipDeliveryDay;

    const calendarEntry = calendarEntries.find((ce) =>
      this.getMomentForWeek(ce.week.toString()).isSame(day, 'week')
    );

    if (calendarEntry) {
      this.setState((prev) => ({ ...prev, loadingWeek: this.getWeekDays(day) }));
    }

    if (calendarEntry != null) {
      const canUnSkip = calendarEntry.actions.includes(OrderAction.Unskip);
      const canSkip = calendarEntry.actions.includes(OrderAction.Skip);

      if (!canSkip && !canUnSkip) {
        return;
      }

      if (canUnSkip) {
        if (calendarEntry.state === WeekState.None) {
          //CMP skip prevention (only valid for single sub)
          if (this.hasSingleActiveSub()) {
            //subscription to skip
            const singleSubscription =
              this.props.skipPreventionContext.subscriptions[0];

            //map subscription sku with delivery id
            const deliveriesForSubscription =
              calendarEntry.deliveriesForSubscriptions.find(
                (c) => c.subscriptionSku === singleSubscription.primaryOptionSku
              );

            this.props.skipPreventionContext.deliveryDateId =
              deliveriesForSubscription?.deliveryDateId ?? calendarEntry?.deliveriesForSubscriptions[0].deliveryDateId;
            this.props.skipPreventionContext.deliveryWeek = calendarEntry.week;

            this.setState((prev) => ({
              ...prev,
              showSkipPreventionModal: true,
            }));

            return;
          }
          await this.handleSkipUnSkip(
            calendarEntry.week,
            SkipAction.Skip,
            calendarEntry.productNames
          );
        } else {
          await this.handleSkipUnSkip(
            calendarEntry.week,
            SkipAction.UnSkip,
            calendarEntry.productNames
          );
        }

        this.setState((prev) => ({ ...prev, loadingWeek: null }));
      } else if (!showConfirmSkipModal) {
        this.onClickConfirmSkipDelivery(day);
      } else if (showConfirmSkipModal) {
        this.handleSkipUnSkip(
          calendarEntry.week,
          SkipAction.Skip,
          calendarEntry.productNames
        );
      }
    }
  };

  getMomentForWeek = (weekString: string): moment.Moment => {
    const dateString = weekString.replace('W', '');

    return moment(dateString);
  };

  /*
   * This is used to apply classes relating to skips/unskips
   * Its not very efficient at the moment but it will do the following
   *  - for each of the calendar entries on the server, we will
   *    loop though and find the week that coresponds to the
   *    day we're rendering
   *  - We then check the WeekState enum to determine what
   *    class to apply. WeekState.All means ALL subscriptions
   *    for that week have been skipped. WeekState.Partial means
   *    AT LEAST ONE skip exists for that week. WeekState.None, our
   *    default, means there are NO skips for the week.
   */
  getSkipAndUnSkipClasses = (d: moment.Moment): Array<string> => {
    const classesToApply = [];
    const day = moment(d);
    const calendarEntries = this.state.calendarState.calendarEntries;

    const calendarEntry = calendarEntries.find((ce) =>
      this.getMomentForWeek(ce.week.toString()).isSame(day, 'week')
    );
    if (calendarEntry) {
      if (calendarEntry.state === WeekState.All) {
        classesToApply.push('is-skipped');
        classesToApply.push('track-Week-unskip');
      } else if (calendarEntry.state === WeekState.Partial) {
        classesToApply.push('is-partial-skipped');
        classesToApply.push('track-Week-unskip');
      } else {
        classesToApply.push('track-Week-skip');
      }
      classesToApply.push('can-skip');
    }

    return classesToApply;
  };

  renderDay = (d: moment.Moment) => {
    const day = moment(d);
    const dayNumber = day.format('D');
    const cn = this.getDayClasses(day);

    const StyledDayDisplay = styled.span<{
      inWeek: boolean;
      isWednesDay: boolean;
    }>`
      opacity: ${(c)=>c.inWeek ? 0: 1};
      display: ${(c) => (c.inWeek && isWednesDay ? 'none' : 'block')};
    `;

    const inWeek =
      this.state.loadingWeek && this.state.loadingWeek.find((c) => c.isSame(d));

    const isWednesDay = d.format('dddd') === 'Wednesday';

    return (
      <div>
        <div className={cn}>
          <StyledDayDisplay
            isWednesDay={isWednesDay}
            inWeek={inWeek ? true : false}
          >
            {dayNumber}
          </StyledDayDisplay>
          {isWednesDay && inWeek && <LoadingIndicator />}
        </div>
      </div>
    );
  };

  /*
   * This is used to apply classes to get the context for day it's rendering
   * It will find the following:
   *  - If its the start of week
   *  - If its the current day
   *  - If its the middle of the week
   *  - If its the end of the week
   * Since the above is purely date related, it makes a call in to getClassesToApply
   * to apply skips, partial, unskips etc.
   */
  getDayClasses = (day: moment.Moment) => {
    const isCurrentDay = day.isSame(moment(), 'day');
    const classesToApplyToDay = this.getSkipAndUnSkipClasses(day);

    const currentDay = parseInt(day.format('D'), DECIMAL_RADIX);
    const startOfWeek = parseInt(
      day.startOf('week').format('D'),
      DECIMAL_RADIX
    );
    const endOfWeek = parseInt(day.endOf('week').format('D'), DECIMAL_RADIX);

    return classnames(
      'mfb-CalendarDay pt-3 pb-3 mt-2 mb-2',
      classesToApplyToDay,
      {
        'is-current-day': isCurrentDay,
      },
      {
        'is-start-week': startOfWeek === currentDay,
      },
      {
        'is-end-week': endOfWeek === currentDay,
      },
      {
        'is-middle-week':
          startOfWeek !== currentDay && endOfWeek !== currentDay,
      }
    );
  };

  render() {
    const uncappedDaySize = Math.floor(
      (document.body.clientWidth - 2 * GUTTER_WIDTH) / DAY_SIZE_DIVIDER
    );
    const daySize = Math.min(DEFAULT_DAY_SIZE, uncappedDaySize);
    const calendarEntries =
      this.state.calendarState && this.state.calendarState.calendarEntries;
    const showConfirmSkipModal = this.state.confirmingSkipDeliveryDay;
    const hasPartials =
      calendarEntries &&
      calendarEntries.some(
        (entry: CalendarEntry) => entry.state === WeekState.Partial
      );

    return this.state && calendarEntries ? (
      <div className="fs-u-mb-40 fs-u-pb-40 pt-4 container-fluid-lg-down">
        <div className="mfb-CalendarKey d-flex justify-content-around align-items-center">
          <div className="mfb-CalendarKey--spacer" />
          {this.state.showCalendarIsDisabledModal && (
            <ConfirmationModal
              title="Delivery Calendar Unavailable"
              text={`
                  Unfortunately the Delivery Calendar is not available right now.
                  To see details about your upcoming deliveries and to skip or unskip,
                  please use the delivery week tabs.
                `}
              submitButtonLabel="Go to your account"
              cancelButtonLabel="Close"
              onConfirm={navman.yourAccount}
              onCancel={this.closeCalendarDisabledModal}
            />
          )}
          {showConfirmSkipModal && (
            <ConfirmationModal
              text={`Are you sure you wish to skip, you won't be able to unskip due to high demand`}
              title="Skip Delivery"
              onConfirm={this.handleConfirmSkip}
              onCancel={this.handleCancelSkip}
            />
          )}
          <ul className="pt-1 pl-1 pr-1 mb-0">
            <li>
              <div className="mfb-CalendarKey--item mfb-CalendarKey--item--none" />
              <span>Delivery</span>
            </li>
            <li>
              <div className="mfb-CalendarKey--item mfb-CalendarKey--item--all" />
              <span>Skipped</span>
            </li>
            {hasPartials && (
              <li>
                <div className="mfb-CalendarKey--item mfb-CalendarKey--item--partial" />
                <Tooltip
                  displayStar={true}
                  text="This means you have both skipped and unskipped deliveries for that week"
                >
                  <span>Varied</span>
                </Tooltip>
              </li>
            )}
          </ul>
          <span onClick={navman.yourAccount}>
            <button
              type="button"
              className="mfb-Calendar--close close p-2"
              data-test="calendar-close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </span>
        </div>
        <BannerDiv className="d-flex align-items-center justify-content-center text-center sticky-top mt-4 mb-4">
          <span
            style={{ backgroundColor: COLOR_CODE.BARELY_THERE_BEIGE }}
            className="p-3"
          >
            {' '}
            You can skip or unskip by clicking a delivery in the below calendar.
          </span>
        </BannerDiv>
        <div className="mfb-Calendar" data-test="calendar">
          <DayPicker
            renderDay={(d: moment.Moment) => this.renderDay(d)}
            onDayClick={this.onDayClick}
            orientation="verticalScrollable"
            numberOfMonths={NUMBER_OF_MONTHS}
            hideKeyboardShortcutsPanel={true}
            daySize={daySize}
          />
        </div>
        <div className="mt-2 text-muted text-center">
          If you want to skip more than 3 months of deliveries, please contact
          our Customer Love Team on {CONTACT_PHONE}
        </div>
        {/* skip cmp */}
        {this.state.showSkipPreventionModal && 
        <CalendarSkipCmpWrapper 
          triggerCode={TriggerCode.SKIP}
          trackingState={this.props.trackingState}
          deliveryWeek={this.props.skipPreventionContext.deliveryWeek}
          subscriptionNumber={this.getFirstActiveSub()?.subscriptionNumber}
          deliveryDateId={this.props.skipPreventionContext.deliveryDateId} 
          customerMessageBulkGroupState={this.props.customerMessageBulkGroupState} 
          defaultAction={async()=>{
            await this.handleSkipUnSkip(
              this.props.skipPreventionContext.deliveryWeek,
              SkipAction.Skip,
              []
            )
          }}
          setCalendarState={this.setState}
          onNavigate={async (url: string, triggerId?: number)=>{
            if (url.startsWith(CustomerMessageProxyDeepLink.SKIP)) {
              await this.handleSkipUnSkip(
                this.props.skipPreventionContext.deliveryWeek,
                SkipAction.Skip,
                [],
                triggerId
              );
            } else {
              navman.relativePath(url);
            }
          }}
          gATrackingData={{
            customer_message_trigger: true,
            week: this.props.skipPreventionContext.deliveryWeek,
            bag_sku: this.getFirstActiveSub()?.primaryOptionSku,
          }}
        />}
      </div>
    ) : (
      <Spinner />
    );
  }
}

const mapStateToProps = (state: AppState<OrdersPageState>): CalendarProps => {
  return {
    skipPreventionContext: {
      triggerCodes: state.features.triggerCodes,
      subscriptions: state.subscriptions,
    },
    customerMessageBulkGroupState: state.customerMessageBulkGroupState,
    trackingState: state.tracking
  };
};

export const Calendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarUnconnected);
