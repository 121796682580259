import React from 'react';
import {COLOR_CODE} from '../../../../shared/constants';
import classnames from 'classnames';

interface TileIconProps {
  icon: React.ReactNode;
  bgFill: string;
}

interface Props {
  title: string;
  description: React.ReactNode;
  iconProps: TileIconProps;
  isFirst?: boolean;
  isLast?: boolean;
}

export const Tile: React.FC<Props> = props => {
  const {title, description, iconProps, isFirst, isLast} = props;

  return (
    <div
      className={classnames(
        'd-flex align-items-center justify-content-start',
        'overflow-hidden',
        'pl-2'
      )}
      style={{color: COLOR_CODE.DARK_BROWN}}
    >
      <div
        className={classnames(
          'h-100 d-flex align-items-center justify-content-center',
          'position-relative',
          'ml-3 mr-5'
        )}
      >
        <svg className="d-flex position-absolute" style={{height: '500px'}}>
          <line
            x1="50%"
            y1={isFirst ? '50%' : '0'}
            x2="50%"
            y2={isLast ? '50%' : '100%'}
            stroke={COLOR_CODE.DARK_BROWN}
            strokeDasharray="2,3"
          />
        </svg>
        <div className="d-flex position-absolute" style={{zIndex: 0}}>
          <div
            className={classnames(
              'd-flex align-items-center justify-content-center',
              'rounded-circle',
              'p-2'
            )}
            style={{backgroundColor: iconProps.bgFill}}
          >
            {iconProps.icon}
          </div>
        </div>
      </div>

      <div
        className={classnames(
          'd-flex flex-column align-items-start',
          'position-relative',
          'py-3'
        )}
      >
        <div className={classnames('bg-white rounded-lg', 'py-3 px-4')}>
          <h4>{title}</h4>
          <div>{description}</div>
        </div>
      </div>
    </div>
  );
};
